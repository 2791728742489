import { Component, Inject, OnInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DeviceDetectorService } from 'ngx-device-detector';


interface ViewDialogData {
	data: any;
	imageUrl: string;
	images: any;
	selectedImageId: string;
	id: string;
}

@Component({
	selector: 'app-view-image-dialog-modal',
	templateUrl: './custom-view-image-dialog.component.html',
	styleUrls: ['./custom-view-image-dialog.component.scss']
})
export class ModalViewImageDialogComponent implements OnInit {
	public imageUrl: string = undefined;
	public images: any = [];
	private selectedImageId: string = undefined;
	public isMobile: boolean = false;
	public isTablet: boolean = false;

	constructor(public dialogRef: MatDialogRef<ModalViewImageDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData, private deviceService: DeviceDetectorService) {
		//console.log('ViewDialogData', data);
		this.imageUrl = data.imageUrl;
		this.images = data.images;
		this.selectedImageId = data.selectedImageId;
		this.isMobile = this.deviceService.isMobile();
		this.isTablet = this.deviceService.isTablet();
	}

	ngOnInit() {

	}

	closeModal(): void {
		this.dialogRef.close();
	}

	moveNext() {
		let image;
		let idx = this.images.findIndex(i => i.id === this.selectedImageId);
		if (idx === this.images.length - 1)
			image = this.images[0];
		else
			image = this.images[idx + 1];

		this.imageUrl = image.url;
		this.selectedImageId = image.id;
	}

	movePrevious() {
		let image;
		let idx = this.images.findIndex(i => i.id === this.selectedImageId);
		if (idx === 0)
			image = this.images[this.images.length - 1];
		else
			image = this.images[idx - 1];

		this.imageUrl = image.url;
		this.selectedImageId = image.id;
	}

	@HostListener('window:keyup', ['$event'])
	keyEvent(event: KeyboardEvent) {
		if (this.images.length && event.code === 'ArrowRight') {
			this.moveNext();
		}
		else if (this.images.length && event.code === 'ArrowLeft') {
			this.movePrevious();
		}
	}
}
