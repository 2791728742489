export class SchedulerTasks {
  public static tasksName: any = {
    'start-session': {
      displayName: 'Start Session',
      isHost: false,
      tooltip: 'Start Session',
      content: {
        name: 'start-session',
        uniqueId: '',
        uid: '',
        active: true,
        note: '',
        action: '',
        metadata: {
          id: '',
          name: ''
        }
      }
    },
    'start-lead-camera': {
      displayName: 'Main Camera',
      isHost: false,
      tooltip: 'Primary Camera',
      content: {
        name: 'start-lead-camera',
        uniqueId: '',
        uid: '',
        active: true,
        note: '',
        action: '',
        metadata: {
          id: '',
          name: ''
        },
        overlay: {
          type: 'text', // image
          content: '',
          align: 'right',
          speed: 5,
          isMarquee: false,
          running: false,
          motion: 'static', // marque
          trigger: 'manual', // schedule
          startTime: 0, // seconds,
          duration: null, // minutes,
          updatedAt: undefined,
        }
      }
    },
    'start-guest-camera': {
      displayName: 'Camera',
      isHost: false,
      tooltip: 'Secondary Camera',
      content: {
        name: 'start-guest-camera',
        uniqueId: '',
        uid: '',
        active: true,
        note: '',
        action: '',
        metadata: {
          id: '',
          name: ''
        },
        overlay: {
          type: 'text', // image
          content: '',
          align: 'right',
          speed: 5,
          isMarquee: false,
          running: false,
          motion: 'static', // marque
          trigger: 'manual', // Schedule
          startTime: 0, // seconds,
          duration: null, // minutes,
          updatedAt: undefined,
        }
      }
    },
    'push-tile': {
      displayName: 'Push Tile',
      isHost: false,
      tooltip: 'Push Tile',
      content: {
        name: 'push-tile',
        uniqueId: '',
        uid: '',
        active: true,
        note: '',
        action: '',
        metadata: {
          id: '',
          name: ''
        }
      }
    },
    'share-screen': {
      displayName: 'Share Screen',
      isHost: false,
      tooltip: 'Share Screen',
      content: {
        name: 'share-screen',
        uniqueId: '',
        uid: '',
        active: true,
        note: '',
        action: '',
      }
    },
    // 'share-video': {
    //   displayName: 'Share Video',
    //   isHost: false,
    //   tooltip: '',
    //   content: {
    //     name: 'share-video',
    //     uniqueId: '',
    //     uid: '',
    //     active: true,
    //     note: '',
    //     action: '',
    //     url: '',
    //     type: '',
    //     startMin: 0,
    //     startSec: 0
    //   }
    // },
    'rtmp-pull': {
      displayName: 'RTMP Pull',
      isHost: false,
      tooltip: 'Play an RTMP link',
      content: {
        name: 'rtmp-pull',
        uniqueId: '',
        uid: '',
        active: true,
        note: '',
        action: '',
        rtmpUrl: '',
        hlsOutput: '',
        channelId: '',
        streamKey: ''
      }
    },
    'make-announcement': {
      displayName: 'Announcement',
      isHost: false,
      tooltip: 'Announcement',
      content: {
        name: 'make-announcement',
        uniqueId: '',
        uid: '',
        active: true,
        note: '',
        action: '',
        data: ''
      }
    },
    'breakout': {
      displayName: 'Breakout Sessions',
      isHost: true,
      tooltip: '',
      content: {
        name: 'breakout',
        uniqueId: '',
        uid: '',
        active: true,
        note: '',
        action: '',
      }
    },
    'end-session': {
      displayName: 'End Session',
      isHost: false,
      tooltip: 'End Session',
      content: {
        name: 'end-session',
        uniqueId: '',
        uid: '',
        active: true,
        note: '',
        action: '',
      }
    },
    'generate-rtmp': {
      displayName: 'RTMP Push',
      isHost: false,
      tooltip: 'Generate an RTMP link to stream to it',
      content: {
        name: 'generate-rtmp',
        uniqueId: '',
        uid: '',
        active: true,
        note: '',
        hlsOutput: '',
        action: ''
      }
    },
    'update-editor': {
      displayName: 'Editor',
      isHost: false,
      tooltip: 'Editor',
      content: {
        name: 'update-editor',
        uniqueId: '',
        uid: '',
        active: true,
        note: '',
        action: '',
        data: ''
      }
    },
    'update-picture': {
      displayName: 'Picture',
      isHost: false,
      tooltip: 'Picture',
      content: {
        name: 'update-picture',
        uniqueId: '',
        uid: '',
        active: true,
        note: '',
        action: '',
        image: '',
        thumbImage: '',
        metadata: {
          type: 'link',
          link: '',
          linkDisplay: ''
        }
      }
    },
    'update-iframe': {
      displayName: 'Push iFrame',
      isHost: false,
      tooltip: 'Push iFrame',
      content: {
        name: 'update-iframe',
        uniqueId: '',
        uid: '',
        active: true,
        note: '',
        action: '',
        metadata: {
          type: 'link',
          link: '',
          linkDisplay: ''
        }
      }
    },
    'update-defaultvideo': {
      displayName: 'Streaming Media',
      isHost: false,
      tooltip: 'Streaming Media',
      content: {
        name: 'update-defaultvideo',
        uniqueId: '',
        uid: '',
        active: true,
        note: '',
        action: '',
        autoStart: false,
        autoRepeat: false,
        synchost: false,
        loop: false,
        isPlaying: false,
        isUsedByShortcut: false,
        type: '',
        frame: 0,
        url: '',
        urlId: '',
        clickThruUrl: '',
        hlsUrl: '',
        startMin: 0,
        startSec: 0,
      }
    },
    'select-session': {
      displayName: 'Go to Next Session',
      isHost: false,
      tooltip: 'Go to Next Session',
      content: {
        name: 'select-session',
        uniqueId: '',
        uid: '',
        active: true,
        note: '',
        action: '',
        metadata: {
          id: '',
          name: ''
        }
      }
    },
    'update-panel': {
      displayName: 'Panel Cameras',
      isHost: false,
      tooltip: 'Panel Cameras',
      content: {
        name: 'update-panel',
        uniqueId: '',
        uid: '',
        active: true,
        note: '',
        action: '',
        metadata: [],
        groups: []
      }
    }
  };
  public static tasksNameKeys: string[] = Object.keys(SchedulerTasks.tasksName);
}
