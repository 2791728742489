export class DefaultSettings {
  public static componentsType: any = {
    desktop: ['empty-click', 'screenshare', 'camera', 'audio', 'guest-camera', 'audienceView', 'panelView', 'defaultvideo', 'imagegallery', 'videolibrary', 'presenter-camera', 'billboard', 'editor', 'picture', 'tile', 'picturewall', 'iframe', 'quuNowPlaying', 'quuSongs'],
    'desktop-offline': ['empty-click', 'defaultvideo', 'billboard', 'editor', 'picture', 'tile', 'picturewall', 'imagegallery', 'videolibrary', 'audio', 'iframe', 'quuNowPlaying', 'quuSongs'],
    'desktop-2': ['empty-click', 'defaultvideo', 'billboard', 'editor', 'picture', 'tile', 'picturewall', 'imagegallery', 'videolibrary', 'audio'],
    'desktop-3': ['empty-click', 'defaultvideo', 'billboard', 'editor', 'picture', 'tile', 'picturewall', 'imagegallery', 'videolibrary', 'audio'],
    'desktop-4': ['empty-click', 'defaultvideo', 'billboard', 'editor', 'picture', 'tile', 'picturewall', 'imagegallery', 'videolibrary', 'audio'],
    'desktop-hls': ['presenter-camera']
  };
  public static optionDesktopSettingsStatus: any = { enableScreenShareButton: true, enableFullScreen: true, enableCaptureScreen: true, enableRaiseHand: true, enableChat: true, enableAskQuestions: true, enableBreakout: true, numberOfAttendees: true, hasTwoPipCamera: false, enableExpressions: true };

  public static hlsComponentsList: any = ['screenshare', 'guest-camera', 'presenter-camera'];
  public static cloudliveComponentsList: any = ['empty-click', 'audio', 'defaultvideo', 'imagegallery', 'videolibrary', 'billboard', 'editor', 'picture', 'tile', 'picturewall', 'iframe', 'quuNowPlaying', 'quuSongs', 'cloudlive'];
  public static componentsMobile: any = ['empty-click', 'screenshare', 'camera', 'audio', 'guest-camera', 'audienceView', 'panelView', 'defaultvideo', 'imagegallery', 'videolibrary', 'presenter-camera', 'billboard', 'editor', 'picture', 'tile', 'picturewall', 'iframe', 'quuNowPlaying', 'quuSongs'];
  public static componentsRoomType: any = {
    'room-offline': ['empty-click', 'audio', 'defaultvideo', 'imagegallery', 'videolibrary', 'presenter-camera', 'billboard', 'editor', 'picture', 'tile', 'iframe', 'quuNowPlaying', 'quuSongs']
  };
  public static peopleType: any = {
    lead: 'Leads', // Speaker
    guest: 'Guests',  // Moderator
    attendee: 'Specific Attendees', // Attendee
    moderator: 'Moderators', // Producer
    interpreter: 'Interpreters' // Interpreter
  }
  public static peopleSingleType: any = {
    lead: 'Lead', // Speaker
    guest: 'Guest',  // Moderator
    attendee: 'Specific Attendee', // Attendee
    moderator: 'Moderator', // Producer
    interpreter: 'Interpreter' // Interpreter
  }
  public static viewPortModes: any = {
    desktop: { name: 'Desktop', icon: 'computer' },
    // tablet: { name: 'Tablet', icon: 'tablet_mac' },
    mobile: { name: 'Mobile', icon: 'smartphone' },
  }
  public static viewPortModesKeys: string[] = Object.keys(DefaultSettings.viewPortModes);
  public static desktopNames: any = {
    desktop: 'Front Wall',
    'desktop-offline': 'Front Wall',
    'desktop-hls': 'Front Wall',
    'desktop-2': 'Right Wall',
    'desktop-3': 'Back Wall',
    'desktop-4': 'Left Wall'
  }
  public static desktopNamesKeys: string[] = Object.keys(DefaultSettings.desktopNames);
  public static peopleTypeKeys: string[] = Object.keys(DefaultSettings.peopleType);
  public static interpreterType: any = {
    en: 'English',
    ar: 'Arabic',
    fr: 'French',
    es: 'Spanish'
  }
  public static interpreterTypeKeys: string[] = Object.keys(DefaultSettings.interpreterType);
  public static targetType: any = {
    link: 'URL',
    page: 'Page',
    room: 'Room',
    tile: 'Tile',
    imagegallery: 'Image Gallery',
    medialibrary: 'Media Library',
    communicate: 'Communicate',
    integratediframe: 'Integrated iFrame'
  }

  public static SubscriptionUpgradeMessage = 'Upgrade your subscription to get the full features.';
  public static SubscriptionCloudliveMessage = 'You don\'t have a valid CloudLive subscription. Update your subscription plan and add CloudLive to be able to use this feature.';
  public static SubscriptionExhibitMessage = 'You did not purchase any Exhibit Booths. Update your subscription plan and add Exhibit Booths to be able to use this feature.';
  public static SubscriptionExhibitUpgradeMessage = 'You have used all of your available Exhibit Booths. Update your subscription plan to purchase more Exhibit Booths.';
  public static roomNewList: any[] = [
    { value: 'create', secondValue: 'room-create', displayName: 'Add Room', typeName: 'Room', disabled: false, disabledLabel: DefaultSettings.SubscriptionUpgradeMessage },
    { value: 'create-room-exhibit', secondValue: 'room-create-room-exhibit', displayName: 'Add Exhibit Booth', typeName: 'Exhibit Booth', disabled: false, disabledLabel: DefaultSettings.SubscriptionExhibitMessage }];
  public static targetTypeKeys: string[] = Object.keys(DefaultSettings.targetType);
  public static meetingMode: string[] = ['meeting', 'largemeeting'];
  public static cameraComponents: string[] = ['screenshare', 'camera', 'guest-camera', 'presenter-camera', 'panelView', 'audienceView'];
  public static restrictedComponents: string[] = ['screenshare', 'camera', 'guest-camera', 'presenter-camera', 'panelView', 'audienceView'];
  public static pipcameraComponents: string[] = ['screenshare', 'guest-camera', 'presenter-camera'];
  public static camerasCountComponents: string[] = ['screenshare', 'camera', 'guest-camera', 'presenter-camera'];
  public static emptyComponents: string[] = ['empty-click'];
  public static advancedComponents: string[] = ['picturewall'];
  public static videoComponents: string[] = ['defaultvideo'];
  // public static videoComponentTypes: any[] = [{ value: 'youtube', displayName: 'YouTube' }, { value: 'vimeo', displayName: 'Vimeo' }, { value: 'rtmp', displayName: 'RTMP Stream' }, { value: 'hls', displayName: 'HLS Stream' }];
  public static videoComponentTypes: any[] = [
    { value: 'youtube', displayName: 'YouTube' },
    { value: 'vimeo', displayName: 'Vimeo' },
    { value: 'facebook', displayName: 'Facebook' },
    { value: 'hls', displayName: 'HLS Stream' }
  ];
  public static tileComponents: string[] = ['tile', 'questionnaire', 'inquiry', 'linkDescription'];
  public static pageList: any[] = [
    { name: 'Schedule', value: 'agenda' },
    { name: 'Exhibitors', value: 'sponsors' },
    { name: 'Resources', value: 'resources' },
    { name: 'Posters', value: 'posters' },
    { name: 'Directory', value: 'book' }
  ];
  public static thumbnailsStatuses: any[] = [
    { value: 1, name: 'Never hide thumbnails' },
    { value: 2, name: 'Hide thumbnails on Room level' },
    { value: 3, name: 'Hide thumbnails on Session level' },
    { value: 4, name: 'Always hide thumbnails' },
  ];
  public static slideshowSettings: any = {
    thumbnailsStatus: 1,
    mainImage: 85,
    thumbnails: 15
  };
  public static defaultSetting = {
    version: 3,
    desktop: {
      groups: [],
      shortcuts: {},
      columns: [
        {
          components: [
            // {
            //   active: true,
            //   h: 14,
            //   name: "presenter-camera",
            //   viewPublicChat: true,
            //   metadata: {
            //     id: '',
            //     name: ''
            //   },
            //   w: 25,
            //   x: 75,
            //   y: 4,
            //   z: 1
            // },
            // {
            //   active: true,
            //   enableAllowAllSpeak: false,
            //   h: 14,
            //   name: "camera",
            //   w: 25,
            //   x: 0,
            //   y: 4,
            //   z: 1
            // },
            // {
            //   active: true,
            //   h: 22,
            //   message: "",
            //   name: "screenshare",
            //   w: 40,
            //   x: 30,
            //   y: 4,
            //   z: 1
            // }
          ]
        }
      ],
      optionSettings: {
        enableScreenShareButton: false,
        enableFullScreen: false,
        enableCaptureScreen: false,
        enableRaiseHand: false,
        enableChat: false,
        enableAskQuestions: false,
        enableBreakout: false,
        numberOfAttendees: 0
      }
    },
    'desktop-offline': {
      columns: [
        {
          components: []
        }
      ],
    },
    'desktop-2': {
      columns: [
        {
          components: []
        }
      ],
    },
    'desktop-3': {
      columns: [
        {
          components: []
        }
      ],
    },
    'desktop-4': {
      columns: [
        {
          components: []
        }
      ],
    },
    'desktop-hls': {
      columns: [
        {
          components: [
            {
              active: true,
              layout: "bestFit", //bestFit, pip, verticalPresentation, horizontalPresentation
              h: 22,
              name: "presenter-camera",
              metadata: {
                id: '',
                name: ''
              },
              w: 40,
              x: 30,
              y: 1,
              z: 1
            }
          ]
        }
      ],
      optionSettings: {
        // enableFullScreen: false,
        // enableChat: false,
        // enableAskQuestions: false,
        // addScreenShare: false
      }
    }
  };
  public static defaultRoomSetting = {
    version: 1,
    'green-room': { enable: false, isTrainingMode: false, currentSessionId: undefined, invitees: [], defaultSessionAudio: 10 },
    'room-offline': {
      columns: [
        {
          components: [
          ]
        }
      ]
    }
  };
  public static streamingResolutions = {
    audienceView: { width: 320, height: 240 },
    screen: { width: 1280, height: 720 },
    camera: { width: 640, height: 480 },
    hls: '1280x720',
    oneOnOneChat: { width: 320, height: 240 }
  };
  public static hlsDurationRange = {
    min: 10,
    max: 600,
    extendRatio: 1.5,
    default: 60,
    extendBy: 30
  };
  public static oneOnOneChatDurations = {
    ringing: 12000,
    drawer: 9000,
    callTime: 2
  }
  public static spotlight = {
    shuffleInterval: 60000,
    maxMembers: 10,
    camerasPerRow: 2
  };
  public static retrySubscribing = 5000;
  public static intervalInSecondsConnectionDisconnected = 5000;
  public static checkSessionActiveInterval = 10000;
  public static audioBlockedTimer = 3000;
  public static hostInactiveSessionAutoStartTimer = 3000;
  public static tokBoxStatsNetwork = {
    interval: 2000,
    timeout: 120000
  }
}
