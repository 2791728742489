import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ChatService {

  forceHideChat: boolean = false;
  isGlobalChatShowing: boolean = false;
  userFlags: any = {};
  chatPrivatelyWith: any = undefined;
  chatPrivatelyWithSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  isOpenGlobalChat: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  unreadChats: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  dndChat: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  isTextChat: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  isVideoChat: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  toggleCommunicate: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }
}