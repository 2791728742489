import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LayoutUtilsService } from '../../../services';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { weekdayEN } from '../locale/weekdaysEN';
import { DatePipe } from '@angular/common';
import { formatDate } from '@angular/common';
import { Moment } from 'moment';
import * as moment from 'moment';
import { ConnectableObservable } from 'rxjs';
declare var rrule: any;

interface ViewDialogData {
	title: string;
	data: any;
	fields: any;
	overWrite: any;
	startdate: any;
	enddate: any;
	dataList: any[];
	confirmData: any;
	startWeekDay: number;
}

class MyDialogErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const isSubmitted = form && form.submitted;
		return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
	}
}

@Component({
	selector: 'app-recurrence-dialog-modal',
	templateUrl: './recurrence-dialog.component.html',
	styleUrls: ['./recurrence-dialog.component.scss']
})
export class RecurrenceDialogComponent implements OnInit {
	public errorMessage: string = '';
	// public ruleTextOriginal: string = '';
	public startWeekDay: number = 1;
	public maxCount: number = 1000;
	public ruleText: string = '';
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public weekOfDay: string = '';
	public byweekday: any[] = [];
	public esMatcher = new MyDialogErrorStateMatcher();
	public enddate = moment(new Date().toISOString()).utc();
	public metaFieldSetting = undefined;
	public returnData: any = {
		repeats: "daily",
		interval: 1,
		end: "after",
		count: 1
	};
	public currentMinDate = moment().toDate();
	public currentMaxDate = moment().add(2, 'years').toDate();
	// public currentTzOffset = moment().utcOffset();
	constructor(private cd: ChangeDetectorRef, private translate: TranslateService, private layoutUtilsService: LayoutUtilsService, public dialog: MatDialog,
		public dialogRef: MatDialogRef<RecurrenceDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		// console.log('RecurrenceDialogComponent', data);
		// https://www.textmagic.com/free-tools/rrule-generator
	}

	ngOnInit() {
		if (this.data.hasOwnProperty('startWeekDay')) {
			this.startWeekDay = this.data.startWeekDay;
		}
		if (this.data.fields) {
			this.metaFieldSetting = this.buildMetaSetting(this.data, parent = undefined);
		}
		if (this.data.enddate) {
			this.enddate = moment(this.data.enddate).utc();
		}
		if (this.data.data.hasOwnProperty('_id')) {
			this.returnData = JSON.parse(JSON.stringify(this.data.data));
		} else {
			if (this.data.data.hasOwnProperty('startdate')) {
				this.returnData = JSON.parse(JSON.stringify(this.data.data));
			}
		}
		this.returnData['startdate'] = this.data.startdate;
		// console.log('metaFieldSetting', this.metaFieldSetting);
		// console.log('this.returnData', this.returnData);
		let originalStartDate = moment(this.returnData['startdate']);
		this.weekOfDay = originalStartDate.format('dd').toUpperCase();
		if (this.returnData.byweekday) {
			if (!this.returnData.byweekday.includes(this.weekOfDay)) {
				this.returnData.byweekday.push(this.weekOfDay);
			}
		}

		// console.log('this.weekOfDay', this.weekOfDay);
		// console.log('this.returnData.byweekday', this.returnData.byweekday);
		if (!this.returnData.hasOwnProperty('tzid')) {
			this.returnData['tzid'] = this.getUserTimeZone();
		}
		if (this.returnData.hasOwnProperty('until') && this.returnData['until']) {
			this.returnData['untilView'] = moment(this.returnData['until']).format('YYYY-MM-DD');;
		}
		this.setRuleText();
		// this.ruleTextOriginal = JSON.parse(JSON.stringify(this.ruleText));
	}

	closeModal(data): void {
		if (data) {
			if (data.totalRep > this.maxCount) {
				this.layoutUtilsService.showNotification(this.translate.instant('You can only create events with up to 1000 repetitions. Please change the recurrence to continue.'), this.translate.instant('Dismiss'));
				return;
			}
			if (data.repeats !== 'yearly') {
				if (!data.interval || (data.interval && (data.interval === '' || data.interval === undefined || data.interval === null))) {
					this.layoutUtilsService.showNotification(this.translate.instant('Fill in the Every field'), this.translate.instant('Dismiss'));
					return;
				}
			}
			if (data.repeats === 'weekly' && (!data.byweekday || (data.byweekday && data.byweekday.length === 0))) {
				this.layoutUtilsService.showNotification(this.translate.instant('Select a week day'), this.translate.instant('Dismiss'));
				return;
			}

			if (data.end === 'after' && (!data.byweekday || (data.byweekday && data.byweekday.length === 0))) {
				if (data.repeats === 'weekly' && (!data.byweekday || (data.byweekday && data.byweekday.length === 0))) {
					this.layoutUtilsService.showNotification(this.translate.instant('Select a week day'), this.translate.instant('Dismiss'));
					return;
				}
			}
			if (data.end === 'after') {
				if (!data.count || (data.count && (data.count === '' || data.count === undefined || data.count === null))) {
					this.layoutUtilsService.showNotification(this.translate.instant('Fill in the Count field'), this.translate.instant('Dismiss'));
					return;
				}
			}
			if (data.end === 'date') {
				if (!data.until || (data.until && (data.until === '' || data.until === undefined || data.until === null))) {
					this.layoutUtilsService.showNotification(this.translate.instant('Fill in the Until field'), this.translate.instant('Dismiss'));
					return;
				}
				let momentUntil = moment(data["until"]).utc();
				if (momentUntil.diff(this.enddate, 'day') < 0) {
					this.layoutUtilsService.showNotification(this.translate.instant('Fill in the Until field with a date larger than the event end date'), this.translate.instant('Dismiss'));
					return;
				}
			}
			this.dialogRef.close(data);
			// if(data.hasOwnProperty('_id') && this.ruleTextOriginal !== this.ruleText){
			// 	this.confirmChange(data);
			// }else{
			// 	this.dialogRef.close({data:data});
			// }
		} else {
			this.dialogRef.close(data);
		}
	}
	// confirmChange(data) {
	// 	const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
	// 		disableClose: true,
	// 		data: {
	// 		title: this.translate.instant('Attention'),
	// 		data: '',
	// 		description: this.translate.instant('This will will delete all future occurences of this event. Are you sure you want to proceed?'),
	// 		cancelbtn: this.translate.instant('Cancel'),
	// 		confirmbtn: this.translate.instant('Proceed'),
	// 		}
	// 	});
	// 	dialogRef.afterClosed().subscribe(result => {
	// 		if (result) {
	// 			this.dialogRef.close({data: data, overWrite: '1'});
	// 		} else {
	// 			// this.dialogRef.close(undefined);
	// 		}
	// 	});
	// }
	onChangeAttribute(e) {
		this.setRuleText();
	}
	setRuleText() {
		try {
			let ruleData = this.getRecurrenceDatas(this.returnData);
			this.ruleText = ruleData.humantext;
			this.returnData['tzid'] = ruleData.tzid;
			this.returnData['humantext'] = ruleData.humantext;
			this.returnData['localdate'] = ruleData['localdate'];
			this.returnData['slocaldate'] = ruleData['slocaldate'];
			this.returnData['enddate'] = ruleData['enddate'];
			this.returnData['totalRep'] = ruleData['totalRep'];
		} catch (e) {
			// error
			console.log('setRuleText', e);
		}
		this.cd.detectChanges();
	}
	public setUntilAttribute(val) {
		// console.log('setDateAttribute', val.toISOString());
		try {
			// this.returnData['until'] = val.toISOString();
			let untilTime = moment(val.toISOString());
			let untilTimeView = untilTime.clone();
			if (untilTime.diff(moment(), 'years', true) > 2) {
				untilTime = moment().add(2, 'years');
				untilTimeView = untilTime.clone();
			} else if (untilTime.diff(moment(), 'days', true) < 0) {
				untilTime = moment();
				untilTimeView = untilTime.clone();
			}
			this.returnData['until'] = untilTime.set({ hours: 23, minutes: 59 }).format('YYYY-MM-DDTHH:mm');
			this.returnData['untilView'] = untilTimeView.format('YYYY-MM-DD');
		} catch (e) {
			// error
			//console.log('setDateAttribute', e);
		}
		this.setRuleText();
	}
	onRepeatsChange(data): void {
		// console.log('onRepeatsChange', data);
		delete this.returnData['monthlyType'];
		delete this.returnData['bymonth'];
		delete this.returnData['bymonthday'];
		delete this.returnData['bysetpos'];
		delete this.returnData['byday'];
		delete this.returnData['byweekday'];
		delete this.returnData['yearlyType'];
		if (data === 'monthly') {
			this.returnData['bymonthday'] = this.metaFieldSetting['bymonthday'].enum[0].value;
			this.returnData['monthlyType'] = this.metaFieldSetting['monthlyType'].enum[0].value;
		} else if (data === 'yearly') {
			this.returnData['bymonth'] = this.metaFieldSetting['bymonth'].enum[0].value;
			this.returnData['bymonthday'] = this.metaFieldSetting['bymonthday'].enum[0].value;
			this.returnData['yearlyType'] = this.metaFieldSetting['yearlyType'].enum[0].value;
		} else if (data === 'weekly') {
			this.returnData['byweekday'] = [this.weekOfDay];
			this.byweekday = JSON.parse(JSON.stringify(this.returnData['byweekday']));;
		}
		this.setRuleText();
	}
	onMonthlyTypeChange(data): void {
		if (data === 'dayofmonth') {
			this.returnData['bymonthday'] = this.metaFieldSetting['bymonthday'].enum[0].value;
			delete this.returnData['bysetpos'];
			delete this.returnData['byday'];
		} else if (data === 'weekdayofmonth') {
			this.returnData['bysetpos'] = this.metaFieldSetting['bysetpos'].enum[0].value;
			this.returnData['byday'] = this.metaFieldSetting['byday'].enum[0].value;
			delete this.returnData['bymonthday'];
		}
		this.setRuleText();
	}
	onYearlyTypeChange(data): void {
		if (data === 'dayofmonth') {
			this.returnData['bymonth'] = this.metaFieldSetting['bymonth'].enum[0].value;
			this.returnData['bymonthday'] = this.metaFieldSetting['bymonthday'].enum[0].value;
			delete this.returnData['bysetpos'];
			delete this.returnData['byday'];
		} else if (data === 'weekdayofmonth') {
			this.returnData['bymonth'] = this.metaFieldSetting['bymonth'].enum[0].value;
			this.returnData['bysetpos'] = this.metaFieldSetting['bysetpos'].enum[0].value;
			this.returnData['byday'] = this.metaFieldSetting['byday'].enum[0].value;
			delete this.returnData['bymonthday'];
		}
		this.setRuleText();
	}
	onEndChange(data): void {
		if (data === 'after') {
			this.returnData['count'] = 1;
			delete this.returnData['until'];
			delete this.returnData['untilView'];
		} else if (data === 'date') {
			this.returnData['untilView'] = moment(new Date().toISOString()).format('YYYY-MM-DD');
			this.returnData['until'] = moment(new Date().toISOString()).set({ hours: 23, minutes: 59 }).format('YYYY-MM-DDTHH:mm');
			delete this.returnData['count'];
		}
		this.setRuleText();
	}
	onByweekdayChange(value): void {
		if (value.length === 0) {
			this.returnData['byweekday'] = JSON.parse(JSON.stringify(this.byweekday));
		} else {
			this.byweekday = JSON.parse(JSON.stringify(value));
		}
		this.setRuleText();
	}
	onEveryChange(value): void {
		if (this.returnData.interval > 999) {
			this.returnData.interval = 999;
		}
		this.setRuleText();
	}
	onIntervalChange(value): void {
		this.setRuleText();
	}
	onCountChange(value): void {
		if (this.returnData.count > this.maxCount) {
			this.returnData.count = this.maxCount;
		}
		this.setRuleText();
	}
	buildMetaSetting(data, parent = undefined) {
		let dataObject = {};
		// let tabObject = [];
		for (let col of data.fields) {
			if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
				if (col.name === 'byweekday' || col.name === 'byday') {
					let weekdayList = weekdayEN.map((itm) => {
						let itmNew = { id: itm.id, displayName: itm.medName, value: itm.value };
						return itmNew;
					})
					col.enum = this.sortByStartNumber(weekdayList, this.startWeekDay);
				}
				if (parent) {
					col['inputName'] = parent + col['name'];
				}
				dataObject[col.name] = col;
			} else if (col.type === 'object') {
				dataObject[col.name] = this.buildMetaSetting(col);
			}
			else if (col.type === 'table') {
				dataObject[col.name] = col;
			}
		}
		return dataObject;
	}
	getRecurrenceDatas(recurObj) {
		let response = { humantext: '', all: undefined, tzid: this.getUserTimeZone() };
		if (!recurObj) {
			return response;
		} else {
			let pattern = "";
			let patternView = "";
			let count = 0;
			switch (recurObj["repeats"]) {
				case "minutely":
					pattern = "FREQ=MINUTELY;INTERVAL=" + recurObj["interval"];
					break;
				case "hourly":
					pattern = "FREQ=HOURLY;INTERVAL=" + recurObj["interval"];
					break;
				case "daily":
					pattern = "FREQ=DAILY;INTERVAL=" + recurObj["interval"];
					break;
				case "weekly":
					pattern = "FREQ=WEEKLY;INTERVAL=" + recurObj["interval"] + ";BYDAY=" + recurObj["byweekday"].toString();
					break;
				case "monthly":
					pattern = "FREQ=MONTHLY;INTERVAL=" + recurObj["interval"];
					if (recurObj["monthlyType"] && recurObj["monthlyType"] == "dayofmonth") {
						pattern += ";BYMONTHDAY=" + recurObj["bymonthday"].toString();
					} else {
						let bysetposSign = '+';
						if (recurObj["bysetpos"] < 0) {
							bysetposSign = '';
						}
						pattern += ";BYSETPOS=1;BYDAY=" + bysetposSign + recurObj["bysetpos"] + recurObj["byday"].toString();
					}
					break;
				case "yearly":
					pattern = "FREQ=YEARLY;"
					if (recurObj["yearlyType"] && recurObj["yearlyType"] == "dayofmonth") {
						pattern += "BYMONTH=" + recurObj["bymonth"] + ";BYMONTHDAY=" + recurObj["bymonthday"].toString();
					} else {
						let bysetposSign = '+';
						if (recurObj["bysetpos"] < 0) {
							bysetposSign = '';
						}
						pattern += "BYDAY=" + bysetposSign + recurObj["bysetpos"] + recurObj["byday"] + ";BYSETPOS=1;BYMONTH=" + recurObj["bymonth"].toString();
					}
					break;
				default:
					pattern = "";
			}
			patternView = pattern;
			if (recurObj["end"] && recurObj["end"] == "after") {
				pattern += ";COUNT=" + recurObj["count"];
				patternView += ";COUNT=" + recurObj["count"];
				count = recurObj["count"];
			}
			if (recurObj["tzid"]) {
				pattern += ";TZID=" + recurObj["tzid"];
			} else {
				pattern += ";TZID=" + this.getUserTimeZone();
			}
			if (recurObj["end"] && recurObj["end"] == "date") {
				pattern += ";UNTIL=" + moment(recurObj["until"]).format('YYYYMMDDTHHmmss');
				patternView += ";UNTIL=" + moment(recurObj["until"]).format('YYYYMMDDTHHmmss');
			}

			// console.log('pattern', pattern);
			let options = rrule.RRule.parseString(pattern);
			let optionsView = rrule.RRule.parseString(patternView);
			let startdate = new Date(recurObj["startdate"]);
			let enddate = new Date(recurObj["enddate"]);

			const utcStartDate = new Date(startdate.getTime() - startdate.getTimezoneOffset() * 60000);
			const utcMoment = moment.utc(recurObj["startdate"]);
			const localMoment = utcMoment.local();
			const formattedLocalTime = localMoment.format('YYYY-MM-DD hh:mm A');
			options.dtstart = utcStartDate;
			// options.dtstart = moment(recurObj['startdate']).utc().toDate();
			optionsView.dtstart = utcStartDate;

			if (count && count != 0) {
				options.count = count;
				optionsView.count = count;
			} else {
				// options.until = new Date(recurObj["until"]);
				// options.until = moment(recurObj['until']).utc().toDate();
				// optionsView.until = moment(recurObj['until']).toDate();
			}
			// if (recurObj["tzid"]) {
			// 	options.tzid = recurObj["tzid"];
			// } else {
			// 	options.tzid = this.getUserTimeZone();
			// }
			var rule = new rrule.RRule(options);
			var ruleView = new rrule.RRule(optionsView);
			// return rule.toText();
			var all = rule.all();
			response["totalRep"] = all.length;
			response["tzid"] = recurObj["tzid"];
			response["localdate"] = utcStartDate;
			response["slocaldate"] = formattedLocalTime;
			response["humantext"] = ruleView.toText();
			response["enddate"] = enddate;

			if (response["totalRep"] > this.maxCount) { // we added this here for better performance
				return response;
			}
			let allData = all.map((occurrence: Date) => {
				occurrence = new Date(occurrence.getTime() + occurrence.getTimezoneOffset() * 60000);
				// console.log('occurrence', occurrence.toUTCString());
				return occurrence;
			});
			// console.log(rule.toString());
			// console.log(allData);
			if (allData.length > 0) {
				response["enddate"] = allData[allData.length - 1];
			}

		}
		return response;
	}
	sortByStartNumber(dataArray, startNumber) {
		const sortedArray = [...dataArray];
		// Find the index of the startNumber in the id property
		const startIndex = sortedArray.findIndex(obj => obj.id === startNumber);
		// Rearrange the array so that the startNumber is at the beginning
		const adjustedArray = [...sortedArray.slice(startIndex), ...sortedArray.slice(0, startIndex)];
		return adjustedArray;
	}
	getUserTimeZone() {
		// Create a new date object with the current date and time
		const now = new Date();

		// Get the time zone from the date object using the Intl.DateTimeFormat API
		const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

		return timeZone;
	}
}
