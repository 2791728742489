<mat-menu [overlapTrigger]="false" [xPosition]="xPosition" [yPosition]="yPosition" [hasBackdrop]="false" [class]="_getClass()">
  <div class="container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()" tabindex="-1">
    <div class="title">
      <div class="header">{{ header }}</div>

      <a *ngIf="showCloseButton" (click)="$event.preventDefault(); trigger.close(false)" class="close" href=""></a>
    </div>

    <ng-content></ng-content>

    <ng-container *ngIf="templateRef && trigger">
      <ng-container *ngTemplateOutlet="templateRef; context: {$implicit: data}"></ng-container>
    </ng-container>

    <div *ngIf="showButton" class="buttons">
      <div class="sideNote">{{ sideNoteText }}</div>
      <button *ngIf="buttonPreviousText" (click)="trigger.goBack()" type="button" mat-stroked-button>{{ buttonPreviousText }}</button>
      <button (click)="trigger.close(true)" type="button" mat-stroked-button>{{ buttonText }}</button>
    </div>
  </div>
</mat-menu>
