import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../../shared/services';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { isValidHttpUrl } from '../../../../shared/helpers';
import { DefaultSettings } from '../';

interface ViewDialogData {
	title: string;
	data: any;
	guestsList: any[];
	leadList: any[]
	confirmData: any;
}

@Component({
	selector: 'app-panelists-update-dialog-modal',
	templateUrl: './panelists-update-dialog.component.html',
	styleUrls: ['./panelists-update-dialog.component.scss']
})
export class PanelistsUpdateDialogComponent implements OnInit {
	public errorMessage: string = '';
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public hasShape: boolean = false;
	public returnData: any = { metadata: [] };
	public shareVideoPlaceHolder: string = '';
	public containsLead = false;
	public guestsList = [];
	public guestsObjectList = {};
	public leadList = [];
	public leadObjectList = {};
	public speakerObjectList = {};
	public speakerFilter: string[] = [];
	constructor(
		private requestService: RequestService, private translate: TranslateService,
		private layoutUtilsService: LayoutUtilsService, public dialog: MatDialog,
		public dialogRef: MatDialogRef<PanelistsUpdateDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		//console.log('ViewDialogData', data);
		if (data) {
			if (data.data) {
				this.returnData = data.data;
			}
			this.speakerObjectList = {};
			if (data.leadList) {
				this.leadList = data.leadList;
				for (let itm of this.leadList) {
					this.leadObjectList[itm._id] = itm;
					this.speakerObjectList[itm._id] = itm;
				}
			}
			if (data.guestsList) {
				this.guestsList = data.guestsList;
				for (let itm of this.guestsList) {
					this.guestsObjectList[itm._id] = itm;
					this.speakerObjectList[itm._id] = itm;
				}
			}
			this.speakerFilter = this.returnData.metadata.map((itm) => itm.id);
			this.analyzeData(undefined);
		}
	}

	ngOnInit() {
	}
	onKey(value) {
		this.selectSearch(value);
		console.log('onKey', value);
	}
	selectSearch(value: string = '') {
		let filter = value.toLowerCase();
		let leadList = JSON.parse(JSON.stringify(this.data.leadList));
		let guestsList = JSON.parse(JSON.stringify(this.data.guestsList));
		if (value !== '') {
			leadList = leadList.filter((user) => user.name.toLowerCase().search(filter) > -1);
			guestsList = guestsList.filter((user) => user.name.toLowerCase().search(filter) > -1);
		}

		this.leadList = leadList;
		this.guestsList = guestsList;

		this.analyzeData(undefined);
	}
	public analyzeData(e) {
		console.log(e);
		setTimeout(() => {
			if (this.speakerFilter && this.speakerFilter.length > 0) {
				for (let itm of this.speakerFilter) {
					if (this.leadObjectList.hasOwnProperty(itm)) {
						this.containsLead = true;
						break;
					} else {
						this.containsLead = false;
					}
				}
			} else {
				this.containsLead = false;
			}
		}, 200);
	}
	closeModal(data): void {
		if (data) {
			this.returnData.metadata = this.speakerFilter.map((itm) => {
				let newItm = { id: itm, name: this.speakerObjectList[itm].name, type: this.speakerObjectList[itm].type };
				return newItm;
			});
			// console.log(this.returnData);
			this.dialogRef.close(this.returnData);
		} else {
			this.dialogRef.close(data);
		}
		// this.dialogRef.close(data);
	}
}
