<div class="col-xl-12 pictureDialog" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content>
      <mat-card style="height: 100%;padding: 16px 10px 3px;min-height: 300px">
				<div fxLayout="row" fxLayout.lt-md="column" fxFlex  >
	        <div fxFlex>
						<app-custom-select #customselect [value]="returnData.id" [itemName]="'metadata'" [dataType]="'gallery'" [apiTarget]="'searchObject'" [filters]="filterData" [placeholder]="'Image Gallery'" (onSelectReturn)="selectItemCheck($event)" [termfields]="['name']"></app-custom-select>
					</div>
	        <div fxFlex="5" *ngIf="isAdmin">
						<button mat-icon-button (click)="editImageGalleries(returnData.id)" *ngIf="returnData.id && returnData.id !== ''" style="margin:20% auto"><mat-icon>edit</mat-icon></button>
					</div>
				</div>
				<!-- <div class="example-full-width">
					<mat-icon *ngIf="!returnData['autoPlay']" (click)="returnData['autoPlay']=true" title="{{'Auto Play' | translate}}" style="height: 30px;vertical-align: middle;cursor:pointer">check_box_outline_blank</mat-icon>
					<mat-icon *ngIf="returnData['autoPlay']" (click)="returnData['autoPlay']=false" title="{{'Auto Play' | translate}}" style="height: 30px;vertical-align: middle;cursor:pointer">check_box</mat-icon>
					<label style="width: 13vw" for="autoPlay"> {{'Auto Play' | translate}}</label>
				</div> -->
      </mat-card>
	</div>
	<div mat-dialog-actions>
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
			<button mat-raised-button (click)="closeModal(returnData)">{{'Confirm' | translate}}</button>
			<button mat-raised-button (click)="goToImageGalleries()" *ngIf="isAdmin">{{'Go to Image Galleries' | translate}}</button>
	</div>
	<br/>
</div>
