import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { TokBoxService } from "src/app/shared";

declare var FB;

@Component({
    selector: 'app-facebook-media',
    templateUrl: './facebook-media.component.html',
    styleUrls: ['./facebook-media.component.scss']
})

export class FacebookComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor(private opentokService: TokBoxService, private changeDetectorRef: ChangeDetectorRef) { }

    private subscriptions: Subscription[] = [];

    public video: any = undefined;

    @Input() uuid: string = undefined;
    @Input() zIndex: string = undefined;

    ngOnInit() {
        this.subscriptions.push(this.opentokService.startFacebook.subscribe(video => {
            if (video !== undefined && video.uid === this.uuid) {
                this.startFacebook(video);
            }
        }));

        this.subscriptions.push(this.opentokService.stopFacebook.subscribe(video => {
            if (video !== undefined && video.uid === this.uuid) {
                this.stopFacebook();
            }
        }));
    }

    ngAfterViewInit(): void {
        this.opentokService.facebookLoaded.next(true);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    startFacebook(video) {
        if (typeof (FB) != 'undefined'
            && FB) {
            if (!document.getElementById('fb-' + video.uid)) {
                this.video = video;
                this.detectChanges();

                let element = document.createElement('div');
                element.setAttribute('class', 'fb-video');
                element.setAttribute('data-show-text', 'false');
                element.setAttribute('data-width', 'auto');
                element.setAttribute('id', 'fb-' + video.uid);
                element.setAttribute('data-href', video.url);
                // if (video.autoStart) {
                //     element.setAttribute('data-autoplay', 'true');
                // }
                document.getElementById('video-fb-' + video.uid).append(element);

                FB.XFBML.parse(document.getElementById('video-fb-' + video.uid));

                FB.Event.subscribe('xfbml.ready', (msg) => {
                    if (msg.type === 'video') {
                        document.querySelector('#' + msg.id + ' iframe')['style'].visibility = '';

                        video.player = msg.instance;
                        video.player.seek(video.startAt);
                        video.player.setVolume(this.opentokService.preferredVolume / 100);
                        video.isPlaying = true;
                        if (video.loop)
                            video.player.subscribe('finishedPlaying', (e) => {
                                video.player.seek(0);
                                video.player.play();
                            });

                        if (video.autoStart) {
                            video.player.setVolume(0);
                            video.player.play();
                        }

                        this.video = video;
                    }
                });
                this.opentokService.layoutVideoComponents.next({ uuid: video.uid, value: this.video });
                this.detectChanges();
            }
        }
        else {
            setTimeout(() => { this.startFacebook(video); }, 2000);
        }
    }

    stopFacebook() {
        if (this.video) {
            this.video.isPlaying = false;
            try {
                this.video.player.destroy();
            }
            catch (e) { }

            if (document.getElementById('video-fb-' + this.video.uid))
                document.getElementById('video-fb-' + this.video.uid).innerHTML = '';

            delete this.video.player;
            this.opentokService.layoutVideoComponents.next({ uuid: this.video.uid, value: this.video });
        }
    }

    private detectChanges() {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }
}