import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { LayoutUtilsService, TokBoxService } from "src/app/shared";
import { RequestService } from "src/app/shared/services/request.service";
import * as moment from 'moment';
import { QuuSongDialogComponent } from "../quu-song-dialog/quu-song-dialog.component";
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import * as Ably from 'ably';
import { environment } from "src/environments/environment";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-quu-playlist',
    templateUrl: './playlist.component.html',
    styleUrls: ['./playlist.component.scss']
})

export class QuuPlaylistComponent implements OnInit, OnDestroy {

    @Input() numberOfSongs: number = 1;
    @Input() stationId: string = undefined;
    @Input() callLetters: string = undefined;
    @Input() groupId: string = undefined;
    @Input() uuid: string = undefined;

    public playlist: any[] = [];
    public selectedSong: any = undefined;

    private dialog: any = undefined;
    private ably: any = undefined;

    constructor(private requestService: RequestService, private layoutUtilsService: LayoutUtilsService, private tokbox: TokBoxService, private matDialog: MatDialog, private translate: TranslateService) { }

    ngOnInit() {
        this.ably = new Ably.Realtime({ key: environment.quu.ablyKey });
        this.ably.channels.get('quu-' + this.groupId).subscribe('nowplayingchanged-' + this.stationId, (message) => {
            this.getPlayList();
        });

        this.getPlayList();
    }

    ngOnDestroy() {
        this.ably.channels.get('quu-' + this.groupId).unsubscribe('nowplayingchanged-' + this.stationId);
        this.ably.channels.get('quu-' + this.groupId).detach();
    }

    private getPlayList() {
        this.requestService.getQuuPlaylist(this.callLetters, this.numberOfSongs).subscribe((data: any) => {
            if (data?.result?.length) {
                this.playlist = data.result;
                // this.playlist.push({
                //     logo: "https://bit.ly/3Grzurp",
                //     title: "Laser Joint Centers",
                //     playlistid: 12910,
                //     type: 'COM'
                // });
                this.playlist.forEach(song => {
                    if (song.logo) {
                        if (song.logo.substr(0, 2) === '//') {
                            song.logo = window.location.protocol + song.logo;
                        }
                    }
                    else {
                        song.logo = './assets/images/ad-none.gif';
                    }
                    if (song.start)
                        song.time = moment(song.start).utc().format('LT');
                });
            }
        },
            (error) => {
                console.log('error', error);
                this.layoutUtilsService.showNotification('Playlist not available', 'Dismiss');
            });
    }

    openDialog(song) {
        if (this.dialog)
            this.dialog.close();

        if (song) {
            if (song.type.toLowerCase() === 'com') {
                const dialogConfig = new MatDialogConfig();
                dialogConfig.data = {
                    id: this.uuid,
                    title: song.title,
                    quuId: song.playlistid,
                    type: song.type,
                    stationLetters: this.callLetters
                }
                dialogConfig.disableClose = false;
                dialogConfig.width = '50vw';
                this.dialog = this.matDialog.open(QuuSongDialogComponent, dialogConfig);
            }
            else {
                let youtubeId = undefined;
                if (song.videolink) {
                    youtubeId = song.videolink.slice(song.videolink.indexOf('/v/') + 3, song.videolink.indexOf('&'));
                }

                if (youtubeId) {
                    const dialogConfig = new MatDialogConfig();
                    dialogConfig.data = {
                        id: this.uuid,
                        title: song.title,
                        quuId: youtubeId,
                        type: song.type
                    }
                    dialogConfig.disableClose = false;

                    this.dialog = this.matDialog.open(QuuSongDialogComponent, dialogConfig);
                    this.selectedSong = {
                        controls: true,
                        autoStart: true,
                        loop: false,
                        urlId: youtubeId,
                        type: 'youtube',
                        isPlaying: true,
                        start: true,
                        uid: this.uuid
                    };

                    this.dialog.afterOpened().subscribe(() => this.tokbox.startYoutube.next(this.selectedSong));
                    this.dialog.afterClosed().subscribe(() => {
                        this.tokbox.stopYoutube.next(this.selectedSong);
                    });
                }
                else {
                    this.layoutUtilsService.showNotificationSnack(this.translate.instant('Sorry, no video available for this song...'), this.translate.instant('OK'));
                }
            }
        }
    }
}