import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SchedulerService {

  toggleSession: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  speakerCamera: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  guestCamera: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  pushTile: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  shareScreen: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  shareVideo: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  makeAnnouncement: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  startBreakout: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  changeSession: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  generateRtmp: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  callBackScheduler: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  rtmpPull: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  findAndUpdateTasks: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  updateEditor: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  updatePanel: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  updatePicture: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  updateVideo: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  refreshSession: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  togglePlaceHolder: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  updateIframe: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  addTask: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  pushCameraOverlay: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  constructor() { }

  resetValues() {
    this.toggleSession.next(undefined);
    this.speakerCamera.next(undefined);
    this.guestCamera.next(undefined);
    this.pushTile.next(undefined);
    this.shareScreen.next(undefined);
    this.shareVideo.next(undefined);
    this.makeAnnouncement.next(undefined);
    this.startBreakout.next(undefined);
    this.changeSession.next(undefined);
    this.generateRtmp.next(undefined);
    this.callBackScheduler.next(undefined);
    this.rtmpPull.next(undefined);
    this.findAndUpdateTasks.next(undefined);
    this.updateEditor.next(undefined);
    this.updatePanel.next(undefined);
    this.updatePicture.next(undefined);
    this.updateVideo.next(undefined);
    this.refreshSession.next(undefined);
    // this.schedulerTasks.next(undefined);
  }
}