import { Component, Input, EventEmitter, Output, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { RequestService, LayoutUtilsService, PageScopeService, StoreService, RoomSessionService, LoaderService } from '../../../../services';
import { ModalUserViewDialogComponent } from 'src/app/shared/components/custom-user-view-dialog/custom-user-view-dialog.component';
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { MatDialog } from '@angular/material/dialog';
import { ICalendar, CalendarOptions, GoogleCalendar, YahooCalendar, OutlookCalendar } from 'datebook';
import { Moment } from 'moment';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'agenda-dialog-table',
  templateUrl: './agenda-table.component.html',
  styleUrls: ['./agenda-table.component.scss']
})
export class AgendaDialogTableComponent {
  public subscriptions: any[] = <any>[];
  public isMobile: boolean = false;
  serverHostUrl = this.requestService.serverHostUrl
  @Input() type: string = '';
  @Input() showMore: boolean = false;
  @Input() loading: boolean = false;
  // @Input() isLive: boolean = false;
  @Input() displayedColumns: string[] = ['favorite', 'startsAt', 'active', 'name', 'specificusers', 'room', 'calender'];

  public orderBy: string = 'startsAt';
  public orderDir = 'asc';
  private _dataSource: any[];
  private dataSourceKeysValue: any = {};
  private dataSourceKeys: any = [];

  @Input()
  set dataSource(values: any[]) {
    // if(this.isLive){
    //   values = values.filter((item) => item.active);
    // }
    this._dataSource = values;
    this.dataSourceKeys = [];
    this.dataSourceKeysValue = {};
    let dataSourceKeysValue = {};
    if (values && values.length > 0) {
      for (let itm of values) {
        let startD: Moment = moment.utc(itm.startsAt).local();
        let startDString: string = startD.format('dddd - MMM D, yyyy');
        if (!dataSourceKeysValue.hasOwnProperty(startDString)) {
          dataSourceKeysValue[startDString] = [itm];
        } else {
          dataSourceKeysValue[startDString].push(itm);
        }
      }
    }
    this.dataSourceKeys = Object.keys(dataSourceKeysValue);
    this.dataSourceKeysValue = dataSourceKeysValue;

    for (let dy of this.dataSourceKeys) {
      this.dataSourceKeysValue[dy] = this.dataSourceKeysValue[dy].sort((a, b) => {
        if (this.orderBy === 'room') {
          return a[this.orderBy].name.toLowerCase().localeCompare(b[this.orderBy].name.toLowerCase());
        } else if (this.orderBy === 'specificusers') {
          return a[this.orderBy].toString().toLowerCase().localeCompare(b[this.orderBy].toString().toLowerCase());
        } else {
          return a[this.orderBy].localeCompare(b[this.orderBy]);
        }
      });
      if (this.orderDir === 'desc') {
        this.dataSourceKeysValue[dy] = this.dataSourceKeysValue[dy].reverse();
      }
    }
  }
  get dataSource() {
    return this._dataSource;
  }

  @Output() toggleAction = new EventEmitter<any>();
  constructor(private loaderService: LoaderService, private changeDetectorRefs: ChangeDetectorRef, public dialog: MatDialog, private roomSessionService: RoomSessionService, private translate: TranslateService, private requestService: RequestService, public deviceService: DeviceDetectorService) {
    if (this.deviceService.isMobile()) {
      this.isMobile = true;
    }
  }
  ngOnInit() {
    // console.log(this.dataSource);
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
  addtoMyCalender(e, record, type = "download", trigger) {
    if (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
    }
    trigger.closeMenu();
    // console.log('record', record);
    let config: CalendarOptions = {
      title: record.name,
      location: 'Stellar - ' + record.room.name,
      // description: '<a href="' + this.serverHostUrl + '/#/rooms/' + record.room._id + '/sessions/' + record._id + '">Link</a>',
      description: this.serverHostUrl + '/#/rooms/' + record.room._id + '/sessions/' + record._id,
      start: new Date(record.startsAt),
      end: new Date(record.endAt),
      // an event that recurs every two weeks:
      // recurrence: {
      //   frequency: 'WEEKLY',
      //   interval: 2
      // }
    }
    if (type === 'download') {
      const icalendar = new ICalendar(config);
      icalendar.download();
    } else if (type === 'google') {
      config.description = '<a href="' + this.serverHostUrl + '/#/rooms/' + record.room._id + '/sessions/' + record._id + '">' + this.serverHostUrl + '/#/rooms/' + record.room._id + '/sessions/' + record._id + '</a>';
      const googleCalendar = new GoogleCalendar(config);
      window.open(googleCalendar.render(), '_blank');
    } else if (type === 'yahoo') {
      const yahooCalendar = new YahooCalendar(config);
      window.open(yahooCalendar.render(), '_blank');
    } else if (type === 'outlook') {
      // config.start = this.convertUTCDateToLocalDate(config.start);
      // config.end = this.convertUTCDateToLocalDate(config.end);
      config.start = config.start;
      config.end = config.end;
      const outlookCalendar = new OutlookCalendar(config);
      let url = outlookCalendar.render();
      window.open(url, '_blank');
    }
  }
  convertUTCDateToLocalDate(date) {
    let newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    let offset = date.getTimezoneOffset() / 60;
    let hours = date.getHours();

    newDate.setHours(hours - offset);
    return newDate;
  }
  applySort(e) {
    this.orderBy = e.active;
    this.orderDir = e.direction;
    this.dataSource = JSON.parse(JSON.stringify(this.dataSource));
  }
  cancelThru(e) {
    if (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
      e.stopPropagation();
    }
  }
  showResources(e, resources, element) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    // let availableResources = resources.filter((item) => {}); // incase we need to filter the resources later by time
    // let resourceIds = resources.map((item) => item._id);
    this.toggleActionCall({ action: 'showResources', resources: resources, session: element });
  }
  viewData(e, currentSession) {
    if (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
    }
    this.roomSessionService.openSessionById(currentSession._id, (session) => {
      if (session) {
        if (this.type === 'past') {
          this.roomSessionService.openSessionRecord(session);
        } else {
          if (!session.active && session.chainedtosession && session.chainedtosession.active) {
            this.roomSessionService.openSessionById(session.chainedtosession._id, (chainedtosession) => {
              if (chainedtosession) {
                this.roomSessionService.openSession(chainedtosession.room, chainedtosession);
              }
            });
          } else {
            this.roomSessionService.openSession(session.room, session);
          }
        }
      }
    });
  }
  convertToList(str) {
    let listArray: string[] = str.split('\n');
    let dataReturn = '';
    if (listArray.length > 1) {
      dataReturn = '<ul class="descriptionList">'
      for (let lst of listArray) {
        dataReturn = dataReturn + '<li>' + lst + '</li>'
      }
      dataReturn = dataReturn + '</ul>'
    } else if (listArray.length === 1) {
      dataReturn = '<ul class="descriptionListNoDot">'
      for (let lst of listArray) {
        dataReturn = dataReturn + '<li>' + lst + '</li>'
      }
      dataReturn = dataReturn + '</ul>'
    }
    return dataReturn;
  }
  toggleActionCall(action) {
    this.toggleAction.emit(action);
  }
  setFavorite(e, status, id) {
    if (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
    }
    this.toggleActionCall({ action: 'setFavorite', status: status, id: id });
  }

  viewProfile(e, user) {
    if (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
    }
    const dialogRef = this.dialog.open(ModalUserViewDialogComponent, {
      width: '800px',
      data: {
        dataType: 'resource/user',
        title: user.name + ' ' + this.translate.instant('Profile'),
        onlyView: true,
        data: { _id: user._id },
        modalSetting: undefined
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log('result', result);
      }
    });
  }
}
