
export const hoursLabel = [
  { value: '00', displayName: '12 AM' },
  { value: '01', displayName: '1 AM' },
  { value: '02', displayName: '2 AM' },
  { value: '03', displayName: '3 AM' },
  { value: '04', displayName: '4 AM' },
  { value: '05', displayName: '5 AM' },
  { value: '06', displayName: '6 AM' },
  { value: '07', displayName: '7 AM' },
  { value: '08', displayName: '8 AM' },
  { value: '09', displayName: '9 AM' },
  { value: '10', displayName: '10 AM' },
  { value: '11', displayName: '11 AM' },
  { value: '12', displayName: '12 PM' },
  { value: '13', displayName: '1 PM' },
  { value: '14', displayName: '2 PM' },
  { value: '15', displayName: '3 PM' },
  { value: '16', displayName: '4 PM' },
  { value: '17', displayName: '5 PM' },
  { value: '18', displayName: '6 PM' },
  { value: '19', displayName: '7 PM' },
  { value: '20', displayName: '8 PM' },
  { value: '21', displayName: '9 PM' },
  { value: '22', displayName: '10 PM' },
  { value: '23', displayName: '11 PM' }
];