import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../shared/services';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-orientation-screen',
  templateUrl: './orientation-screen.component.html',
  styleUrls: ['./orientation-screen.component.scss']
})
export class OrientationScreenComponent implements OnInit {
  public subscriptions: any[] = <any>[];
  public screenOrientation: string = 'unknown';
  public isMobile: boolean = false;
  public isTablet: boolean = false;
  public showLoader: boolean = false;

  constructor(private loaderService: LoaderService, private deviceService: DeviceDetectorService) {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
  }

  ngOnInit() {
    this.changeOrientation();
    window.addEventListener('orientationchange', (e) => {
      setTimeout(() => {
        this.changeOrientation();
      }, 300);
    }, false);
    this.subscriptions.push(
      this.loaderService.status.subscribe((val: boolean) => {
        this.showLoader = val;
      })
    );
  }
  changeOrientation() {
    switch (window.orientation) {
      case 0:
        this.screenOrientation = 'portrait';
        break;
      case 90:
        this.screenOrientation = 'landscape';
        break;
      case 180:
        this.screenOrientation = 'portrait';
        break;
      case -90:
        this.screenOrientation = 'landscape';
        break;
      default:
        this.screenOrientation = 'unknown';
    }
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
}
