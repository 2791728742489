<div class="col-xl-12 image-gallery-container-dialog" *ngIf="data" style="overflow: hidden;">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content style="overflow:visible">
		<mat-card [class.isNotMobile]="!isMobile">
			<div [id]="data.libraryId" class="innerDiv">
				<app-image-gallery [galleryId]="data.galleryId">
				</app-image-gallery>
			</div>
		</mat-card>
	</div>
	<div mat-dialog-actions>
		<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
	</div>
	<br />
</div>