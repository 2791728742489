import { Component, Inject, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { LayoutUtilsService, LoaderService } from '../../../shared/services';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
	selector: 'app-iframe-url',
	templateUrl: './iframe-url.component.html',
	styleUrls: ['./iframe-url.component.scss']
})
export class IframeUrlComponent implements OnInit {
	private subscriptions: Subscription[] = [];
	public selectedUser: any;
	public errorMessage: string = '';
	public loading: boolean = false;
	// public iframe_html: SafeResourceUrl = this.transform('/#/empty');
	public iframe_html: SafeResourceUrl = undefined;
	public iframeHeight: string = '100%';
	public _iframeParentHeight: string = '100%';
	@Input() forEdit: boolean = false;
	@Input()
	set iframeParentHeight(iframeParentHeight: string) {
		if (iframeParentHeight != undefined) {
			this._iframeParentHeight = iframeParentHeight;
			if (iframeParentHeight.indexOf('vh') == -1 && iframeParentHeight.indexOf('vw') == -1)
				this._iframeParentHeight = this._iframeParentHeight + 'vh';
		}
	}
	@Input() iframeParentWidth: string = '100%';
	public _iframeLink = '';
	@Input()
	set iframeLink(iframeLink: any) {
		this._iframeLink = iframeLink;
		if (iframeLink) {
			this.loadData(iframeLink);
		} else {
			this.loading = false;
			//this.iframe_html = this.transform('/#/empty');
		}
	}
	get iframeLink(): any {
		return this._iframeLink;
	}
	@ViewChild('iframeMain') iframeMain: ElementRef;
	@Input() iframePadding: string = '0';
	@Input() zIndex: string = '-1';
	@Input() iframeId: string = 'iframeMain';
	@Input() disablePointer: boolean = false;

	constructor(private sanitizer: DomSanitizer,
		private layoutUtilsService: LayoutUtilsService, private loaderService: LoaderService) {
	}

	ngOnInit() {
	}
	public loadData(url) {
		this.iframe_html = this.transform(url);
		this.loading = true;
	}
	onload(ev: Event) {
		// this.resizeIframe();
		setTimeout(() => {
			this.loading = false;
		}, 200);
	}
	public pageY(elem) {
		return elem.offsetParent ? (elem.offsetTop + this.pageY(elem.offsetParent)) : elem.offsetTop;
	}
	public resizeIframe() {
		let height = document.documentElement.clientHeight;
		height -= this.pageY(this.iframeMain.nativeElement) + 20;
		height = (height < 0) ? 0 : height;
		this.iframeHeight = height + 'px';
		if (height < 400) {
			this.iframeHeight = '400px';
		}
	}
	transform(url) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
