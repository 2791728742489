import { Injectable } from '@angular/core';
import { SpeedTestService } from 'ng-speed-test';

@Injectable()
export class SpeedCheckService {
    constructor(
        private speedTestService: SpeedTestService
    ) { }

    getSpeed() {
        this.speedTestService.getMbps().subscribe(
            (speed) => {
                console.log('Your speed is ' + speed);
            }
        );
    }

    getMbps(callBack: any) {
        this.speedTestService.getMbps({
            iterations: 2,
            file: {
                path: 'https://stellar-frontend-media.s3.amazonaws.com/conference/default_download.jpg',
                size: 9578336,
                shouldBustCache: true
            },
            retryDelay: 1500,
        }).subscribe(
            (speed) => {
                // console.log('Your speed is ' + speed);
                callBack(speed);
            });
    }
}