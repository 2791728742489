<div class="col-xl-12" *ngIf="selectedEvent">
	<h1 mat-dialog-title style="margin: 0px;">{{title ? title : selectedEvent.name}}</h1>
	<div mat-dialog-content>
		<div class="userInfo">
			<mat-card>
				<mat-card-content>
					<ng-template #simpleText>
						<span class="eventType">
							<app-enum-view [enumValue]="selectedEvent.eventType"
								[enumList]="metaFieldSetting['eventType'].enum"></app-enum-view>
						</span>
						<mat-list>
							<mat-list-item>{{selectedEvent.description}}</mat-list-item>
							<mat-list-item>
								<span>{{metaFieldSetting['startdate'].displayName | translate}}:</span>
								{{selectedEvent.startdate | date: dateFormat +' hh:mm aa'}}
							</mat-list-item>
							<mat-list-item>
								<span>{{metaFieldSetting['enddate'].displayName | translate}}:</span>
								{{selectedEvent.enddate | date: dateFormat +' hh:mm aa'}}
							</mat-list-item>
						</mat-list>
					</ng-template>
					<ng-container [ngTemplateOutlet]="contentTemplate || simpleText"
						[ngTemplateOutletContext]="{ contentTemplateData: selectedEvent }">
					</ng-container>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
	<div mat-dialog-actions>
		<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
		<button *ngIf="canEdit" mat-raised-button (click)="closeModal('edit')">{{'Edit' | translate}}</button>
		<button *ngIf="canDelete" mat-raised-button (click)="closeModal('delete')">{{'Delete' | translate}}</button>
		<button *ngIf="canCreate" mat-raised-button (click)="closeModal('create')">{{'Create Event' |
			translate}}</button>
		<button *ngIf="canOpen && selectedEvent.meetingType !== '2'" mat-raised-button
			(click)="closeModal('open')">{{'Open Event' | translate}}</button>
		<button *ngFor="let itm of extraButtons" mat-raised-button (click)="closeModal(itm.action)" title="{{itm.title |
			translate}}">{{itm.name |
			translate}}</button>
	</div>
	<br />
</div>