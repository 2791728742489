import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TokBoxService, RequestService, LayoutUtilsService, LoaderService, SchedulerService } from '../../../../shared/services';
import { urlSafeBase64Encoding } from '../../../../shared/helpers';
import { DefaultSettings } from '../';
import { isValidHttpUrl, matchYoutubeUrl, matchFacebookUrl } from '../../../../shared/helpers';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { AblyService } from 'src/app/shared/services/ably.service';

interface ViewDialogData {
	title: string;
	confirmData: any;
	data: any;
	session: any;
	availableMediaComponents: any[];
	isShortcut: boolean;
	isDesigner: boolean;
	isDesignerOffline: boolean;
	source: string;
	currentUserId: string;
}

@Component({
	selector: 'app-party-stream-dialog-modal',
	templateUrl: './party-stream-dialog.component.html',
	styleUrls: ['./party-stream-dialog.component.scss']
})
export class PartyStreamDialogComponent implements OnInit {
	public errorMessage: string = '';
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public videoComponentTypes: any[] = DefaultSettings.videoComponentTypes;
	public shareVideoPlaceHolder: string = '';
	public returnData: any = { autoStart: false, synchost: false, loop: false, autoRepeat: false, isPlaying: false, isUsedByShortcut: false, controls: false, type: 'youtube', url: '', urlId: '', clickThruUrl: '', hlsUrl: '' };
	constructor(public opentokService: TokBoxService,
		private requestService: RequestService, private translate: TranslateService,
		private layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<PartyStreamDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData, private schedulerService: SchedulerService, private ablyService: AblyService) {
		// console.log('ViewDialogData', data);
	}

	ngOnInit() {
		// console.log('this.data.data', this.data.data);
		if (this.data.data) {
			this.returnData = this.data.data;
			if (this.returnData['uid'] && this.data.availableMediaComponents) {
				let selectedId = this.data.availableMediaComponents.filter((itm) => this.returnData['uid'] === itm.uid);
				if (selectedId.length === 0) {
					this.returnData['isPlaying'] = false;
				}
			}
			if (!this.returnData.clickThruUrl) {
				this.returnData['clickThruUrl'] = ''; // cleaning
			}
			if (!this.returnData.startMin) {
				this.returnData['startMin'] = 0; // cleaning
			}
			if (!this.returnData.startSec) {
				this.returnData['startSec'] = 0; // cleaning
			}
			if (!this.returnData.urlId) {
				this.returnData['urlId'] = ''; // cleaning
			}
		}
		this.changeVideoInputPlaceholder();
	}
	isValidYoutubeUrl(url) {
		let p = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
		if (url.match(p)) {
			return url.match(p)[1];
		}
		return false;
	}
	extractYoutubeUrlId(url) {
		try {
			let i, r, rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
			r = url.match(rx);
			if (r.length > 0) {
				return r[1];
			} else {
				return '';
			}
		} catch (e) {
			console.log(e);
			return '';
		}
	}

	isValidVimeoUrl(url) {
		if (/^(?:https?:\/\/)(?:vimeo.com|player.vimeo.com\/video)\//.test(url)) {
			return true;
		} else {
			return false;
		};
	}
	// isValidVimeoUrl(url) {
	// 	if (/^(?:https?:\/\/)(?:vimeo.com|player.vimeo.com\/video)\/\d+(?=\b|\/)$/.test(url)) {
	// 		return true;
	// 	} else {
	// 		return false;
	// 	};
	// }
	// isValidVimeoUrl(url) {
	// 	// let p = /^http:\/\/(?:www\.)?youtube.com\/embed\/[A-z0-9]/;
	// 	let p = /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*/;
	// 	if (url.match(p)) {
	// 		return url.match(p)[1];
	// 	}
	// 	return false;
	// }
	extractVimeoUrlId(url) {
		try {
			var regExp = /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*/;

			var match = url.match(regExp);

			if (match.length > 1) {
				return match[match.length - 1];
			} else {
				return '';
			}
		} catch (e) {
			console.log(e);
			return '';
		}
	}
	extractFacebookUrlId(url) {
		try {
			var regExp = /(\w+)\/?$/;

			var match = url.match(regExp);
			if (match.length > 1) {
				return match[match.length - 1];
			} else {
				return '';
			}
		} catch (e) {
			console.log(e);
			return '';
		}
	}

	stopPlayingMedia(data): void {
		let currentSettings = JSON.parse(JSON.stringify(this.data.session['settings']));
		if (currentSettings.hasOwnProperty('desktop')) {
			let settingObject = JSON.parse(JSON.stringify(currentSettings['desktop']));
			settingObject['shortcuts']['mediaShortcut']['isPlaying'] = false;
			for (let col of settingObject['columns']) {
				for (let cmp of col['components']) {
					if (cmp.name === 'defaultvideo' && cmp.uid === data.uid) {
						cmp.isPlaying = false;
						cmp.autoStart = false;
					}
				}
			}
			currentSettings['desktop'] = JSON.parse(JSON.stringify(settingObject));
		}
		let dataToSave = { _id: this.data.session._id, name: this.data.session['name'], settings: currentSettings };
		this.requestService.saveData('session', dataToSave, (dataReturn, error) => {
			if (error) {
				this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
			}
			if (dataReturn) {
				this.syncWithTeamMembers(dataReturn.results); // this. not working becuase we need to pass OT.Session
				data['isPlaying'] = false;
				let task = {
					controls: data.controls,
					autoStart: true,
					loop: data.loop,
					clickThruUrl: data.clickThruUrl,
					url: data.url,
					type: data.type,
					uid: data.uid,
					urlId: data.urlId,
					start: false,
					startMin: data.startMin,
					startSec: data.startSec
				};

				this.schedulerService.updateVideo.next({ task: task, start: false, isShortcut: true });
			}
		});
	}
	public syncWithTeamMembers(session) {
		if (session)
			this.opentokService.sendSignal('syncTaskManager', '1', session);
		else if (this.data.session.streamMode === 'cloudlive') {
			this.ablyService.sendSessionSignal(this.data.session._id, 'syncTaskManager', this.data.currentUserId);
		}
	}
	closeModal(data, action: string = 'confirm'): void {
		if (data) {
			// if(!data.url || data.url === ''){
			// 	this.layoutUtilsService.showNotification(this.translate.instant('Fill in the Video Url link'), this.translate.instant('Dismiss'));
			// 	return;
			// }
			if (data.clickThruUrl !== '' && !isValidHttpUrl(data.clickThruUrl)) {
				this.layoutUtilsService.showNotification(this.translate.instant('Fill in the correct Click-through URL link format'), this.translate.instant('Dismiss'));
				return;
			}
			if (!data.url || data.url === '') {
				// this.layoutUtilsService.showNotification(this.translate.instant('Fill in the URL link'), this.translate.instant('Dismiss'));
				// return;
			} else {
				if (!isValidHttpUrl(data.url)) {
					this.layoutUtilsService.showNotification(this.translate.instant('Fill in the correct URL link format'), this.translate.instant('Dismiss'));
					return;
				} else if (data.type === 'vimeo' && !this.isValidVimeoUrl(data.url)) {
					this.layoutUtilsService.showNotificationSnack(this.translate.instant('You should use a Vimeo link when selecting Vimeo in the dropdown list.'), this.translate.instant('Dismiss'));
					return;
				}
				else if (data.type === 'youtube' && !matchYoutubeUrl(data.url)) {
					this.layoutUtilsService.showNotificationSnack(this.translate.instant('You should use a YouTube link when selecting Youtube in the dropdown list.'), this.translate.instant('Dismiss'));
					return;
				}
				else if (data.type === 'facebook' && !matchFacebookUrl(data.url)) {
					this.layoutUtilsService.showNotificationSnack(this.translate.instant('You should use a Facebook link when selecting Facebook in the dropdown list.'), this.translate.instant('Dismiss'));
					return;
				}
				// else if (data.type === 'vimeo' && data.url.indexOf('vimeo') == -1) {
				// 	this.layoutUtilsService.showNotificationSnack(this.translate.instant('You should use a Vimeo link when selecting Vimeo in the dropdown list.'), this.translate.instant('Dismiss'));
				// 	return;
				// }
				else if ((data.url.indexOf('youtube') > -1 || data.url.indexOf('youtu.be') > -1) && data.type != 'youtube') {
					this.layoutUtilsService.showNotificationSnack(this.translate.instant('You should select YouTube from the dropdown first.'), this.translate.instant('Dismiss'));
					return;
				}
				else if (data.url.indexOf('vimeo') != -1 && data.type != 'vimeo') {
					this.layoutUtilsService.showNotificationSnack(this.translate.instant('You should select Vimeo from the dropdown first.'), this.translate.instant('Dismiss'));
					return;
				}
				else if (data.url.indexOf('facebook.com') != -1 && data.type != 'facebook') {
					this.layoutUtilsService.showNotificationSnack(this.translate.instant('You should select Facebook from the dropdown first.'), this.translate.instant('Dismiss'));
					return;
				}
				else if (data.url.indexOf('rtmp') != -1 && data.type != 'rtmp') {
					this.layoutUtilsService.showNotificationSnack(this.translate.instant('You should select RTMP from the dropdown first.'), this.translate.instant('Dismiss'));
					return;
				}
				else if (data.type === 'rtmp' && data.url.indexOf('rtmp') == -1) {
					this.layoutUtilsService.showNotificationSnack(this.translate.instant('You should use a RTMP link when selecting RTMP in the dropdown list.'), this.translate.instant('Dismiss'));
					return;
				}

				if (data.type === 'youtube') {
					let urlId = this.extractYoutubeUrlId(data.url);
					if (urlId !== '') {
						this.returnData['urlId'] = urlId;
						data['urlId'] = urlId;
					} else {
						this.layoutUtilsService.showNotificationSnack(this.translate.instant('You should use a YouTube link when selecting Youtube in the dropdown list.'), this.translate.instant('Dismiss'));
						return;
					}
				}
				if (data.type === 'vimeo') {
					let urlId = this.extractVimeoUrlId(data.url);
					if (urlId !== '') {
						this.returnData['urlId'] = urlId;
						data['urlId'] = urlId;
					} else {
						this.layoutUtilsService.showNotificationSnack(this.translate.instant('You should select Vimeo from the dropdown first.'), this.translate.instant('Dismiss'));
						return;
					}
				}
				if (data.type === 'facebook') {
					let urlId = this.extractFacebookUrlId(data.url);
					if (urlId !== '') {
						this.returnData['urlId'] = urlId;
						data['urlId'] = urlId;
					} else {
						this.layoutUtilsService.showNotificationSnack(this.translate.instant('You should select Facebook from the dropdown first.'), this.translate.instant('Dismiss'));
						return;
					}
				}
			}
		}
		if (this.data.isShortcut && action !== 'confirm') {
			if (!data.url || data.url === '') {
				this.layoutUtilsService.showNotification(this.translate.instant('Fill in the URL link'), this.translate.instant('Dismiss'));
				return;
			}
			if (!data.uid || data.uid === '') {
				this.layoutUtilsService.showNotification(this.translate.instant('Select Media Streaming'), this.translate.instant('Dismiss'));
				return;
			} else {
				let selectedId = this.data.availableMediaComponents.filter((itm) => data.uid === itm.uid);
				if (selectedId.length === 0) {
					this.layoutUtilsService.showNotification(this.translate.instant('Select Media Streaming'), this.translate.instant('Dismiss'));
					return;
				}
			}
			if (action === 'play') {
				let schedulerTasks = [];
				if (this.data.session['scheduler'] && this.data.session['scheduler'].hasOwnProperty('tasks')) {
					schedulerTasks = JSON.parse(JSON.stringify(this.data.session['scheduler']['tasks']));
				}

				let playingTask = schedulerTasks.find(i => i.name === 'update-defaultvideo' && i.uid === data.uid && i.action === 'play');
				if (playingTask) {
					this.layoutUtilsService.showNotification(this.translate.instant('You already have media playing in this component, please stop it then try again'), this.translate.instant('Dismiss'));
					return;
				}
				data['isPlaying'] = true;
				data['autoStart'] = true;
			} else {
				data['isPlaying'] = false;
				data['autoStart'] = false;
			}
			data['isUsedByShortcut'] = true;
			this.dialogRef.close({ data: data, action: action });
		} else {
			this.dialogRef.close({ data: data, action: action });
		}
	}
	public setType(val) {
		this.returnData['type'] = val;
		this.returnData['url'] = '';
		this.returnData['urlId'] = '';
		this.returnData['hlsUrl'] = '';
		this.changeVideoInputPlaceholder();
		if (val === 'hls') {
			this.returnData['startMin'] = 0;
			this.returnData['startSec'] = 0;
		}
		if (val !== 'vimeo') {
			this.returnData['controls'] = false;
		}
	}
	/**
  * editAttributeBoolean function
  */
	editAttributeBoolean($event, id, value) {
		if ($event) {
			$event.preventDefault();
			$event.stopPropagation();
		}
		if (value === '') {
			this.returnData['autoStart'] = false;
			this.returnData['loop'] = false;
			this.returnData['isUsedByShortcut'] = false;
			this.returnData['synchost'] = false;
			this.returnData['controls'] = false;
		}
		if (id === 'autoStart' && value === true) {
			this.returnData['synchost'] = false;
			this.returnData['controls'] = false;
		}
		if (id === 'synchost') {
			if (value === true) {
				this.returnData['autoStart'] = false;
				this.returnData['controls'] = true;
			} else {
				this.returnData['controls'] = false;
			}
		}
		this.returnData[id] = value;
		this.changeVideoInputPlaceholder();
	}
	/**
  * editAttribute function
  */
	editAttribute($event, id, value) {
		if ($event) {
			$event.preventDefault();
			$event.stopPropagation();
		}
		if (value === '') {
			this.returnData['autoStart'] = false;
			this.returnData['loop'] = false;
			this.returnData['isUsedByShortcut'] = false;
		}
		this.returnData[id] = value.trim();
		this.changeVideoInputPlaceholder();
	}
	/**
	* editSelectAttribute function
	*/
	editSelectAttribute(id, value) {
		this.returnData[id] = value;
		this.changeVideoInputPlaceholder();
	}
	/**
  * editNumericAttribute function
  */
	editNumericAttribute($event, id, value, min, max) {
		if ($event) {
			$event.preventDefault();
			$event.stopPropagation();
		}
		let newVal = Number(value);
		if (min !== undefined && min > newVal) {
			newVal = min;
		}
		if (max !== undefined && max < newVal) {
			newVal = max;
		}
		this.returnData[id] = newVal;
		this.changeVideoInputPlaceholder();
	}
	changeVideoInputPlaceholder() {
		if (this.returnData['type'] === 'youtube') {
			this.shareVideoPlaceHolder = 'Example:\nhttps://www.youtube.com/embed/0000000\nhttps://www.youtube.com/watch?v=0000000\nhttps://youtu.be/0000000';
		} else if (this.returnData['type'] === 'vimeo') {
			this.shareVideoPlaceHolder = 'Example:\nhttps://vimeo.com/00000000\nhttps://player.vimeo.com/video/00000000\nhttps://vimeo.com/showcase/00000000/embed\nhttps://vimeo.com/showcase/00000000';
		} else if (this.returnData['type'] === 'facebook') {
			this.shareVideoPlaceHolder = 'Example:\nhttps://www.facebook.com/facebook/videos/00000000';
			// else if (this.returnData['type'] === 'rtmp')
			//   this.shareVideoPlaceHolder = 'rtmp://.../.../...';
		} else {
			this.shareVideoPlaceHolder = '';
		}
	}
	isComponentExist() {
		let selectedId = this.data.availableMediaComponents.filter((itm) => this.returnData['uid'] === itm.uid);
		if (selectedId.length === 0) {
			return false;
		}
		return this.returnData['isPlaying'];
	}
}

