<div class="mainTableAgendaSpeaker" [class.mainTableAgendaSpeakerMobile]="isMobile">
  <ng-container *ngIf="fieldsDetails && dataSource && dataSource.length > 0">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="name">
        <!-- <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th> -->
        <td mat-cell *matCellDef="let element; let index = index" class="title">
          <div class="userImage" [ngStyle]="{'background-color': element.pictureLink ? '' : '#f2f2f2'}">
            <img *ngIf="element.pictureLink" [src]="element.pictureLink" loading="lazy"
              style="max-width: 150px; max-height: 150px;" />
          </div>
          <div class="body defaultTableFont">
            <span class="name"><span
                *ngIf="fieldsDetails['title'] && fieldsDetails['title'].visible && element.hasOwnProperty('title') && element['title'] !== ''">
                <app-enum-view [enumValue]="element['title']" [enumList]="fieldsDetails['title'].enum"></app-enum-view>
                &nbsp;
              </span>{{element.firstName}}&nbsp;{{element.lastName}}
              <span class="email"
                *ngIf="fieldsDetails['email'] && fieldsDetails['email'].visible && element.isEmailAddress"> -
                {{element.email}}</span>
            </span>
            <div class="small-details"
              *ngIf="fieldsDetails['website'] && fieldsDetails['website'].visible && element.website && element.website !== ''"
              title="{{element.website}}"><span>Website: </span> <a href="{{element.website}}" target="_blank"
                (click)="$event.stopImmediatePropagation()">{{element.website| truncate : ['255','...']}}</a></div>
            <div class="small-details"
              *ngIf="fieldsDetails['linkedIn'] && fieldsDetails['linkedIn'].visible && element.linkedIn && element.linkedIn !== ''"
              title="{{element.website}}"><span>LinkedIn: </span> <a href="{{element.linkedIn}}" target="_blank"
                (click)="$event.stopImmediatePropagation()">{{element.linkedIn | truncate : ['255','...']}}</a></div>
            <span class="details"
              *ngIf="fieldsDetails['details'] && fieldsDetails['details'].visible && element.details">{{element.details}}</span>
          </div>
        </td>
      </ng-container>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;let index=index;" style="cursor: pointer"
        title="{{'See sessions' | translate}}"
        (click)="toggleActionCall({action: 'addSpeakerFilter', status: 'add', id: row._id})"></tr>
    </table>
  </ng-container>
  <!-- <mat-progress-spinner [diameter]="30" *ngIf="loading" class="mat-spinner-color" color="warn"
      mode="indeterminate">
    </mat-progress-spinner> -->
  <div class="mat-table__message noDataFound"><span *ngIf="!loading && dataSource && dataSource.length === 0">{{ 'There
      are no items to be displayed' | translate }}</span>
  </div>
</div>