import { Directive, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appRestrictInput]'
})
export class RestrictInputDirective {
    @Input('appRestrictInput') restrictedCharacters: string;

    @HostListener('keydown', ['$event'])
    onKeyDown(event: KeyboardEvent): void {
        if (this.restrictedCharacters && this.restrictedCharacters.includes(event.key)) {
            event.preventDefault();
        }
    }
}