export enum ClickType {
    BILLBOARD = 'billboard',
    COMMUNICATE = 'communicate',
    CHAT = 'chat',
    AUDIOVIDEOSETTINGS = 'media-settings',
    ASKQUESTION = 'ask-question',
    MYAGENDA = 'my-agenda',
    SIGNIN = 'signin',
    SIGNUP = 'signup'
}
