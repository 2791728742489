import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { urlSafeBase64Encoding, isValidHttpUrl, guid, getFileName, matchYoutubeUrl } from '../../../shared/helpers';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ModalAudioGalleryDialogComponent } from '../../../shared/components/custom-audio-gallery-dialog/custom-audio-gallery-dialog.component';
import { ConfirmInputEntityDialogComponent } from '../../../shared/components/modals';
import { ErrorStateMatcher } from '@angular/material/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { Moment } from 'moment';
import * as moment from 'moment';

class MyDialogErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

interface DialogData {
  dataType: string;
  dataTypeTitle: string;
  templateSettings: any;
  templateDisabled: boolean;
  title: string;
  data: any;
  modalSetting: any;
  confirmData: any;
}

@Component({
  selector: 'app-video-dialog-modal',
  templateUrl: './custom-video-dialog.component.html',
  styleUrls: ['./custom-video-dialog.component.scss']
})
export class ModalVideoDialogComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public errorMessage: string = '';
  private organization: any = undefined;
  public selectedUser: any;
  public loading: boolean = false;
  public esMatcher = new MyDialogErrorStateMatcher();
  public isSubmitted: boolean = true;
  public metaFieldSetting = undefined;
  public videoListTemp: any[] = [];
  public videoList: any[] = [];
  public originalData = undefined;
  private defaultSettings: any = { videoList: [] };
  public videosType: any = {
    audio: 'Audio',
    video: 'Custom Video',
    youtubeVid: 'YouTube Video',
    youtubePlst: 'YouTube Playlist',
    vimeoVid: 'Vimeo Video',
    vimeoPlst: 'Vimeo Playlist'
  }
  public videosTypeKeys: string[] = Object.keys(this.videosType);
  constructor(private translate: TranslateService, public dialog: MatDialog,
    private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService, private changeDetectorRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ModalVideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    //console.log('DialogData', data);
  }
  ngOnInit() {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.buildSetting();
        }
      })
    );
    this.subscriptions.push(
      this.requestService.pageOrganization.subscribe((data) => {
        if (data) {
          this.organization = data;
        }
      })
    );
  }
  closeModal(data): void {
    this.dialogRef.close(data);
  }
  addVideo(): void {
    this.videoList.unshift({ id: '', name: '', type: '', url: '', thumbnail: '', linked: false });
  }
  addAudioFromGallery(): void {
    const dialogRef = this.dialog.open(ModalAudioGalleryDialogComponent, {
      width: '1600px',
      disableClose: false,
      autoFocus: false,
      data: {
        title: this.translate.instant('Select Audio files or upload new ones'),
        galleryType: 'audiogallery',
        multipleSelection: true,
        data: [],
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (Array.isArray(result)) {
          for (let itm of result) {
            let fileName = getFileName(itm.url, false);
            this.videoList.unshift({ id: guid(), name: fileName, type: 'audio', url: itm.url, thumbnail: '', linked: false });
          }
        } else {
          let fileName = getFileName(result, false);
          this.videoList.unshift({ id: guid(), name: fileName, type: 'audio', url: result, thumbnail: '', linked: false });
        }

      }
    });
  }
  removeVideo(idx): void {
    this.videoList.splice(idx, 1);
  }
  public setType(index, val) {
    let selectedData = JSON.parse(JSON.stringify(this.videoList));
    let idx = 0;
    for (let dt of selectedData) {
      if (idx === index) {
        selectedData[idx]['url'] = '';
        selectedData[idx]['type'] = val;
      }
      idx++;
    }
    this.videoList = JSON.parse(JSON.stringify(selectedData));
  }
  private buildSetting() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.data.modalSetting.fields = [];

      let orgId = undefined;
      let lang = undefined;
      if (this.data.dataType === 'resource/user') {
        orgId = this.requestService.orgId;
        lang = this.requestService.lang;
      }
      this.requestService.getMetaData(this.data.dataType, undefined, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          let newFields = data.results.fields;
          this.metaFieldSetting = this.buildMetaSetting(data.results, parent = undefined);
          let idx = 0;
          if (this.data.modalSetting.hasOwnProperty('customSettings')) {
            for (let fld of newFields) {
              if (this.data.modalSetting.customSettings.hasOwnProperty(fld.name)) {
                newFields[idx]['visible'] = this.data.modalSetting.customSettings[fld.name].visible;
              }
              idx++;
            }
          }
          this.data.modalSetting.fields = newFields;
          if (this.data.data.hasOwnProperty('_id')) {
            this.loadData();
          } else {
            this.data.data = this.getEmptyObject();
          }
        } else {
          console.log(this.translate.instant('Something is Wrong'));
        }
      }, orgId, lang);
    }
  }
  buildMetaSetting(data, parent = undefined) {
    let dataObject = {};
    // let tabObject = [];
    for (let col of data.fields) {
      if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
        if (parent) {
          col['inputName'] = parent + col['name'];
        }
        dataObject[col.name] = col;
      } else if (col.type === 'object') {
        dataObject[col.name] = this.buildMetaSetting(col);
      }
      else if (col.type === 'table') {
        dataObject[col.name] = col;
      }
    }
    return dataObject;
  }
  public loadData() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let dataId = this.data.data['_id'];
      if (this.data.modalSetting.hasOwnProperty('useOrgId')) {
        dataId = dataId + '/' + this.requestService.orgId;
      }
      this.requestService.getSingleData(this.data.dataType, dataId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          this.data.data = this.getCleanDataObject(data.results);
          this.originalData = JSON.parse(JSON.stringify(this.data.data));
          this.videoList = this.data.data['settings']['videoList'];
        }
        this.loading = false;
      });
    }
  }
  private getCleanDataObject(currentObject) {
    let newObj: any = currentObject;
    for (let col of this.data.modalSetting.fields) {
      if (col.type === 'datetime' && newObj.hasOwnProperty(col.name)) {
        newObj[col.name] = moment.utc(newObj[col.name]).local().format('YYYY-MM-DDTHH:mm');
      } else if (col.type === 'tags' && !newObj.hasOwnProperty(col.name)) {
        newObj[col.name] = [];
      } else if (col.type === 'boolean' && col.name === 'isPasswordRequired' && newObj['isSuperAdmin']) {
        newObj[col.name] = true;
      }
      if (newObj[col.name] === null || newObj[col.name] === undefined) {
        if (col.type === 'reference') {
        } else if (col.type === 'boolean') {
          newObj[col.name] = false;
        } else if (col.type === 'color') {
          newObj[col.name] = '#ffffff';
        } else if (col.type === 'picturearray') {
          newObj[col.name] = [];
        } else if (col.type === 'maparray' || col.type === 'tags') {
          newObj[col.name] = [];
        } else if (col.type === 'datetime') {
          newObj[col.name] = moment.utc().format('YYYY-MM-DDTHH:mm');
        } else {
          newObj[col.name] = '';
        }
      }
      if (col.name === 'settings') {
        if (!newObj[col.name] || (newObj[col.name] && !newObj[col.name]['videoList'])) {
          newObj[col.name] = JSON.parse(JSON.stringify(this.defaultSettings));
        }
      }
    }
    return newObj;
  }

  public setAttribute(id, val) {
    this.data.data[id] = val;
  }
  public setAttributeBoolean(id, val) {
    this.data.data[id] = JSON.parse(val);
  }
  public setReferenceAttribute(parentId, id, val) {
    this.data.data[parentId][id] = val;
  }
  public setMultipleReferenceAttribute(id, val) {
    this.data.data[id] = val;
  }
  private getCleanObject(data) {
    let newObj: any = { _id: data._id };
    for (let col of this.data.modalSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== 'action') {
        if (col.dataType === 'password') {
          newObj[col.name] = urlSafeBase64Encoding(data[col.name]);
        } else if (col.type === 'reference') {
          if (col.reference.kind === 'multiple') {
            if (data[col.name] && data[col.name].length > 0)
              newObj[col.name] = data[col.name];
          } else {
            if (data[col.name] !== '')
              newObj[col.name] = data[col.name];
          }
        } else if (col.type === 'datetime') {
          newObj[col.name] = moment(data[col.name]).utc().format('YYYY-MM-DDTHH:mm');
        } else {
          newObj[col.name] = data[col.name];
        }
        // if (this.data.modalSetting.hasOwnProperty('customSettings')) {
        //   if (this.data.modalSetting.customSettings.hasOwnProperty(col.name)) {
        //     newObj[col.name] = this.data.modalSetting.customSettings[col.name].value;
        //   }
        // }
      }
    }

    return newObj;
  }
  private validateObject(data: any) {
    for (let col of this.data.modalSetting.fields) {
      if ((!col.nullable && !col.generated) && col.type !== 'action' && col.name !== 'privacyterm' && col.visible) {
        if (col.type === 'reference') {
          if (col.reference.kind === 'multiple') {
            // console.log('col.name', col.name, data[col.name] );
            if (col.name === 'resources') {
              if (data && data[col.name] && data[col.name].length === 0) {
                return false;
              }
              if (data[col.name][0]['_id'] === '') {
                return false;
              }
            } else {
              if (data && data[col.name] && data[col.name].length === 0) {
                return false;
              }
            }
          } else {
            if (data && data[col.name] && data[col.name]['_id'] === '') {
              // console.log('col.name', col.name, data[col.name] );
              return false;
            }
          }
        } else if (col.type === 'tags') {
          if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        } else if (col.type === 'picturearray') {
          if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        } else if (col.type === 'email') {
          if (data && (data[col.name].length === 0 || data[col.name] === undefined || !this.isEmailValid(data[col.name]))) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        } else if (col.type === 'url') {
          if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        } else {
          if (data && (data[col.name] === '' || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        }
      }
    }
    return true;
  }
  isValidYoutubeUrl(url) {
    let p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/))(?!videoseries)((\w|-){11})(?:\S+)?$/;
    // let p = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/))(?:[\w\-])+$/;
    if (url.match(p)) {
      return url.match(p)[1];
    }
    return false;
  }
  isValidYoutubePlayListUrl(url) {
    let p = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|playlist\?|watch\?+)(?:&v=)?)((?:\w|-){11})?(?:&?\??list=(\S+))+$/;
    if (url.match(p)) {
      return url.match(p)[1];
    }
    return false;
  }
  extractYoutubeUrlId(url) {
    try {
      let i, r, rx = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
      r = url.match(rx);
      if (r.length > 0) {
        return r[1];
      } else {
        return '';
      }
    } catch (e) {
      console.log(e);
      return '';
    }
  }
  isValidVimeoUrl(url) {
    if (/^(?:https?:\/\/)(?:vimeo.com|player.vimeo.com\/video)\//.test(url)) {
      return true;
    } else {
      return false;
    };
  }
  // isValidVimeoUrl(url) {
  // 	// let p = /^http:\/\/(?:www\.)?youtube.com\/embed\/[A-z0-9]/;
  // 	let p = /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*/;
  // 	if (url.match(p)) {
  // 		return url.match(p)[1];
  // 	}
  // 	return false;
  // }
  extractVimeoUrlId(url) {
    try {
      var regExp = /(https?:\/\/)?(www\.)?(player\.)?vimeo\.com\/?(showcase\/)*([0-9))([a-z]*\/)*([0-9]{6,11})[?]?.*/;

      var match = url.match(regExp);

      if (match.length > 1) {
        return match[match.length - 1];
      } else {
        return '';
      }
    } catch (e) {
      console.log(e);
      return '';
    }
  }
  isValidVimeoPlaylistUrl(url) {
    if (/^(?:https?:\/\/)vimeo.com\/showcase\/(embed\/)*\d*\/?(embed)*\d*$/.test(url)) {
      return true;
    } else {
      return false;
    };
  }
  isValidAudioUrl(url) {
    if (url.toLowerCase().indexOf('.mp3') > -1 || url.toLowerCase().indexOf('.m4a') > -1 || url.toLowerCase().indexOf('.wav') > -1 || url.toLowerCase().indexOf('.aac') > -1) {
      return true;
    } else {
      return false;
    };
  }
  isValidVideoUrl(url) {
    if (url.toLowerCase().indexOf('.mp4') > -1 || url.toLowerCase().indexOf('.ogg') > -1) {
      return true;
    } else {
      return false;
    };
  }
  private validateLogic(data) {
    for (let col of this.data.modalSetting.fields) {
      if (col.name === 'settings') {
        if (data && data[col.name] && data[col.name]['videoList'] && data[col.name]['videoList'].length > 0) {
          for (let vid of data[col.name]['videoList']) {
            if (vid.name.trim() === '') {
              return this.translate.instant('Fill in the title');
            } else if (vid.type === '') {
              return this.translate.instant('Select Type for media');
            } else if (vid.url.trim() === '' || (vid.url && !isValidHttpUrl(vid.url))) {
              return this.translate.instant('Fill in the correct URL link format');
            } else if (vid.type === 'youtubeVid' && !matchYoutubeUrl(vid.url)) {
              return this.translate.instant('You should use a YouTube embed link when selecting YouTube in the dropdown list.');
            } else if (vid.type === 'vimeoVid' && !this.isValidVimeoUrl(vid.url)) {
              return this.translate.instant('You should use a Vimeo link when selecting Vimeo in the dropdown list.');
            } else if (vid.type === 'youtubePlst' && !this.isValidYoutubePlayListUrl(vid.url)) {
              return this.translate.instant('You should use a YouTube playlist link when selecting YouTube playlist in the dropdown list.');
            } else if (vid.type === 'vimeoPlst' && !this.isValidVimeoPlaylistUrl(vid.url)) {
              return this.translate.instant('You should use a Vimeo playlist link when selecting Vimeo playlist in the dropdown list.');
            }
            else if (vid.type === 'audio' && !this.isValidAudioUrl(vid.url)) {
              return this.translate.instant('You should use an mp3, m4a, wav, aac link format when selecting audio in the dropdown list.');
            }
            else if (vid.type === 'video' && !this.isValidVideoUrl(vid.url)) {
              return this.translate.instant('You should use an mp4 or ogg link format when selecting Custom Video in the dropdown list.');
            }
          }
        }
      }
      if (!col.generated && col.type !== 'action' && col.name !== 'privacyterm' && col.visible) {
        if (col.hasOwnProperty('charlimit')) {
          if (data && data[col.name] && data[col.name].length > col.charlimit) {
            return this.translate.instant(col.displayName + ' should have a maximum of ' + col.charlimit + ' characters');
          }
        } else {
          if (col.type === 'textarea') {
            if (data && data[col.name] && data[col.name].length > 1000) {
              return this.translate.instant(col.displayName + ' should have a maximum of 1000 characters');
            }
          }
        }
        if (col.type === 'url') {
          if (data && data[col.name] && data[col.name].length > 0) {
            if (!isValidHttpUrl(data[col.name])) {
              return this.translate.instant('Fill in the correct URL link format');
            }
          }
        }
      }
      if (((!col.nullable || (col.nullable && col.type === 'boolean')) && !col.generated) && col.type !== 'action' && col.name !== 'privacyterm' && col.visible) {
        if (col.hasOwnProperty('validation')) {
          for (let vald of col.validation) {
            if (vald.operator === 'lt') {
              if (col.type === 'datetime') {
                // console.log('data[vald.target]', data[vald.target]);
                // console.log('moment(data[col.name]', data[col.name]);
                // console.log('minutes', moment(data[vald.target]).diff(moment(data[col.name]), 'minutes'));
                if (!(data[col.name] && data[vald.target] && moment(data[vald.target]).diff(moment(data[col.name]), 'minutes') > 0)) {
                  return col.displayName + ' should be less than ' + this.metaFieldSetting[vald.target]['displayName'];
                }
              } else {
                if (!(data[col.name] && data[vald.target] && data[col.name] < data[vald.target])) {
                  return col.displayName + ' should be less than ' + this.metaFieldSetting[vald.target]['displayName'];
                }
              }
            } else if (vald.operator === 'gt') {
              if (col.type === 'datetime') {
                if (!(data[col.name] && data[vald.target] && moment(data[col.name]).diff(moment(data[vald.target]), 'minutes') > 0)) {
                  return col.displayName + ' should be greater than ' + this.metaFieldSetting[vald.target]['displayName'];
                }
              } else {
                if (!(data[col.name] && data[vald.target] && data[col.name] < data[vald.target])) {
                  return col.displayName + ' should be greater than ' + this.metaFieldSetting[vald.target]['displayName'];
                }
              }
            } else if (vald.operator === 'eq') {
              if (data[col.name] !== data[vald.target]) {
                if (vald.hasOwnProperty('value')) {
                  if (data[col.name] !== vald.value) {
                    return col.displayName + ' should be equal to ' + this.metaFieldSetting[vald.target]['displayName'];
                  }
                } else {
                  return col.displayName + ' should be equal to ' + this.metaFieldSetting[vald.target]['displayName'];
                }
              }
            } else if (vald.operator === 'url') {
              if (!this.isUrlValid(data[col.name])) {
                return col.displayName + ' should url format.';
              }
            }
          }
        }
      }
    }
    return undefined;
  }
  public saveData(type) {
    // console.log('saveData', this.data.data);
    // console.log('this.data.modalSetting', this.data.modalSetting);
    // console.log('getCleanObject', this.getCleanObject(this.data.data));
    if (!this.loading) {
      this.videoList = this.videoList.map((vid) => {
        vid.name = vid.name.trim();
        vid.url = vid.url.trim();
        return vid;
      });
      this.data.data['settings']['videoList'] = JSON.parse(JSON.stringify(this.videoList));
      // console.log(this.data.data['settings']['videoList']);

      let validateLogic = this.validateLogic(this.data.data);
      if (!validateLogic) {
        if (this.validateObject(this.data.data)) {
          try {
            for (let vid of this.videoList) {
              if (vid.type === 'youtubePlst') {
                let regPlaylist = /[?&]list=([^#\&\?]+)/;
                let match = vid.url.match(regPlaylist);
                vid['id'] = match[1];
              } else if (vid.type === 'audio' || vid.type === 'video') {
                vid['id'] = guid();
              } else {
                if (vid.type === 'vimeoVid') {
                  vid['id'] = this.extractVimeoUrlId(vid.url);
                  let thumb = 'https://vumbnail.com/' + vid['id'] + '.jpg';
                  vid['thumbnail'] = thumb;
                } else if (vid.type === 'youtubeVid') {
                  vid['id'] = this.extractYoutubeUrlId(vid.url);
                  let thumb = 'http://i3.ytimg.com/vi/' + vid['id'] + '/hqdefault.jpg';
                  vid['thumbnail'] = thumb;
                }
              }
            }
            this.data.data['settings']['videoList'] = JSON.parse(JSON.stringify(this.videoList));
            this.loading = true;
            this.errorMessage = '';
            this.requestService.saveData(this.data.dataType, this.getCleanObject(this.data.data), (data, error) => {
              if (error) {
                this.errorMessage = error;
                this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
              }
              if (data) {
                if (type === 'create') {
                  this.layoutUtilsService.showNotification(this.data.dataTypeTitle + ' ' + this.translate.instant('created Successfully'), this.translate.instant('Dismiss'));
                } else if (type === 'edit') {
                  this.layoutUtilsService.showNotification(this.data.dataTypeTitle + ' ' + this.translate.instant('edited Successfully'), this.translate.instant('Dismiss'));
                }
                this.closeModal({ action: 'refresh', data: data });
              }
              this.loading = false;
            });
          } catch (e) {
            this.layoutUtilsService.showNotification(this.translate.instant('Something is wrong contact the administrator'), this.translate.instant('Dismiss'));
          }
        } else {
          this.layoutUtilsService.showNotification(this.translate.instant('You need to set all mandatory fields'), this.translate.instant('Dismiss'));
        }
      } else {
        this.layoutUtilsService.showNotification(this.translate.instant(validateLogic), this.translate.instant('Dismiss'));
      }
    }
  }
  private getEmptyObject() {
    let newObj = {};
    for (let col of this.data.modalSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== 'action') {
        if (col.type === 'reference') {
          if (col.reference.kind === 'multiple') {
            if (col.name === 'resources') {
              newObj[col.name] = [{ _id: '', name: '' }];
            } else {
              newObj[col.name] = [];
            }
          } else {
            newObj[col.name] = { _id: '', name: '' };
          }
        } else if (col.type === 'boolean') {
          newObj[col.name] = false;
        } else if (col.type === 'color') {
          newObj[col.name] = '#ffffff';
        } else if (col.type === 'picturearray') {
          newObj[col.name] = [];
        } else if (col.type === 'maparray' || col.type === 'tags') {
          newObj[col.name] = [];
        } else if (col.type === 'datetime') {
          newObj[col.name] = moment.utc().format('YYYY-MM-DDTHH:mm');
        } else if (col.type === 'json') {
          newObj[col.name] = JSON.parse(JSON.stringify(this.defaultSettings));
        } else {
          newObj[col.name] = '';
        }
        if (this.data.modalSetting.hasOwnProperty('customSettings')) {
          if (this.data.modalSetting.customSettings.hasOwnProperty(col.name)) {
            newObj[col.name] = this.data.modalSetting.customSettings[col.name].value;
          }
        }
      }
    }
    return newObj;
  }
  private isUrlValid(url) {
    var re = /^((http[s]?):\/)+\/?([^:\/\s]?)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/;
    return re.test(String(url).toLowerCase());
  }
  private isEmailValid(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }
  getEndId(url) {
    let pos = url.lastIndexOf('/');
    let id = pos === -1 ? url : url.substr(pos + 1, url.length - 1);
    return id;
  }
  // insertYoutubeVideo(vid, youtubeAlbumId = undefined){
  //   let id = this.getEndId(vid.link);
  //   let thumb = 'http://i3.ytimg.com/vi/' + id + '/hqdefault.jpg';
  //   this.insertVideo(id, vid.name, vid.link, 'youtubeVid', thumb, youtubeAlbumId);
  // }
  insertVimeoVideo(vid, vimeoAlbumId = undefined) {
    let id = this.getEndId(vid.link);
    let thumb = 'https://vumbnail.com/' + id + '.jpg';
    this.insertVideo(id, vid.name, vid.link, 'vimeoVid', thumb, vimeoAlbumId);
  }
  insertVideo(id: string, name: string, url: string, type: string, thumbnail: string, albumId: string = undefined) {
    let videoList = JSON.parse(JSON.stringify(this.videoList));
    for (let vid of videoList) {
      if (vid.url === url) {
        return;
      }
    }
    this.videoListTemp.push({ id: id, name: name, type: type, url: url, thumbnail: thumbnail, playlistId: albumId, linked: true });
  }
  importVimeoPlayList(vimeoAlbumId, page = 1) {
    this.videoListTemp = [];
    this.importVimeoPlayListAPi(vimeoAlbumId, page);
  }
  importVimeoPlayListAPi(vimeoAlbumId, page = 1) {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getVimeoVideos(vimeoAlbumId, page)
        .subscribe(
          (results: any) => {
            // console.log('results', results);
            this.loading = false;
            if (results) {
              if (results.data) {
                let dataReturned = results.data;
                if (dataReturned.data && dataReturned.data.length > 0) {
                  for (let vid of dataReturned.data) {
                    this.insertVimeoVideo(vid, vimeoAlbumId);
                  }
                }
                if (dataReturned.paging && dataReturned.paging.next) {
                  this.importVimeoPlayListAPi(vimeoAlbumId, page + 1);
                } else {
                  this.videoList = this.videoListTemp.concat(this.videoList);
                  this.videoListTemp = [];
                }
                // console.log('this.videoList', this.videoList);
              } else if (results.error) {
                this.layoutUtilsService.showNotification(this.translate.instant('Not a correct Vimeo ID'), this.translate.instant('Dismiss'));
              }
            }
          },
          error => {
            this.loading = false;
            this.layoutUtilsService.showNotification(this.translate.instant('Not a correct Vimeo ID'), this.translate.instant('Dismiss'));
          }
        );
    }
  }
  // importVimeoVideo(vimeoUrl) {
  //   if (!this.loading) {
  //     this.loading = true;
  //     this.errorMessage = '';
  //     this.requestService.getVimeoVideo(vimeoUrl)
  // 			.subscribe(
  // 				(results: any) => {
  //           this.loading = false;
  //           if(results){
  //             if(results.data){
  //               let dataReturned = results.data;
  //               this.insertVimeoVideo(dataReturned);
  //             }else if (results.error){
  //               this.layoutUtilsService.showNotification( this.translate.instant('Not a correct vimeo url'), this.translate.instant('Dismiss'));
  //             }
  //           }
  // 				},
  // 				error => {
  //           this.loading = false;
  // 					this.layoutUtilsService.showNotification( this.translate.instant('Not a correct vimeo url'), this.translate.instant('Dismiss'));
  // 				}
  // 			);
  //   }
  // }
  // insertVideoModal() {
  //   const dialogRef = this.dialog.open(ModalInsertVideoDialogComponent, {
  //     width: '800px',
  //     disableClose: false,
  //     data: {
  //       title: this.translate.instant('Insert Vimeo Playlist Url'),
  //       data: this.videoList,
  //     }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result !== undefined) {
  //       this.videoList = JSON.parse(JSON.stringify(result));
  //       // let id = this.getEndId(result);
  //       // this.importVimeoPlayList(result); // '8289478'
  //     }
  //   });
  // };
  insertVimeoPlaylist() {
    const dialogRef = this.dialog.open(ConfirmInputEntityDialogComponent, {
      width: '800px',
      disableClose: false,
      data: {
        title: this.translate.instant('Insert Vimeo Playlist ID'),
        data: '',
        placeholder: this.translate.instant('Vimeo playlist ID'),
        hint: 'ex: 204007020',
        validation: { nullable: false, regex: '^[A-Za-z0-9-]*$', regexMessage: this.translate.instant('Not a correct Vimeo ID') },
        cancelbtn: this.translate.instant('Close'),
        confirmbtn: this.translate.instant('Confirm')
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        // let id = this.getEndId(result);
        this.importVimeoPlayList(result);
      }
    });
  };
}
