import { Component, OnInit, OnDestroy, ChangeDetectorRef, Input } from "@angular/core";
import { LayoutUtilsService, TokBoxService } from "src/app/shared";
import { RequestService } from "src/app/shared/services/request.service";
import * as Ably from 'ably';
import { environment } from "src/environments/environment";

@Component({
    selector: 'app-quu-now-playing',
    templateUrl: './now-playing.component.html',
    styleUrls: ['./now-playing.component.scss']
})

export class QuuNowPlayingComponent implements OnInit, OnDestroy {

    @Input() callLetters: string = undefined;
    @Input() stationId: string = undefined;
    @Input() groupId: string = undefined;
    @Input() playingNowLink: string = undefined;
    @Input() uuid: string = undefined;

    public streamLink: string = undefined;
    public songDetails: any = {};
    public isAudioPlaying: boolean = false;
    public isAudioLoaded: boolean = false;

    private ably: any = undefined;


    constructor(private requestService: RequestService, private changeDetectorRef: ChangeDetectorRef, private layoutUtilsService: LayoutUtilsService, private tokBox: TokBoxService) { }

    ngOnInit() {
        this.ably = new Ably.Realtime({ key: environment.quu.ablyKey });
        this.ably.channels.get('quu-' + this.groupId).subscribe('nowplayingchanged-' + this.stationId, (message) => {
            this.getNowPlaying();
        });

        this.requestService.getPLS(this.playingNowLink).subscribe((data: any) => {
            if (data?.length)
                this.streamLink = data[0].uri;
            this.getNowPlaying();
        }, (error) => {
            console.error('error', error);
            this.layoutUtilsService.showNotification('PLS not available', 'Dismiss');
        });
    }

    ngOnDestroy() {
        this.ably.channels.get('quu-' + this.groupId).unsubscribe('nowplayingchanged-' + this.stationId);
        this.ably.channels.get('quu-' + this.groupId).detach();
    }

    detectChanges() {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    togglePlay() {
        if (!this.isAudioPlaying)
            this.isAudioLoaded = false;

        this.isAudioPlaying = !this.isAudioPlaying;
        this.tokBox.startAudio.next({ uuid: this.uuid, play: this.isAudioPlaying });
    }

    isLoaded(bool) {
        this.isAudioLoaded = bool;
    }

    private getNowPlaying() {
        this.requestService.getQuuNowPlaying(this.callLetters).subscribe((data: any) => {
            if (data?.result && data.result.nowplaying.npid !== '34') {
                switch (data.result.nowplaying.type.toLowerCase()) {
                    case 'xxx':
                        this.requestService.getQuuStationDetails(this.callLetters).subscribe((station: any) => {
                            if (station.result) {
                                if (station.result.LogoImage) {
                                    if (station.result.LogoImage.substr(0, 2) === '//') {
                                        this.songDetails.image = window.location.protocol + station.result.LogoImage;
                                    }
                                    else {
                                        this.songDetails.image = station.result.LogoImage;
                                    }
                                }
                                this.songDetails.title = station.result.Name;
                                this.songDetails.subTitle = station.result.Frequency;
                                this.songDetails.type = 'xxx';
                            }
                        });
                        break;
                    case 'com':
                        this.requestService.getQuuAdDetails(this.callLetters, data.result.nowplaying.npid).subscribe((ad: any) => {
                            if (ad) {
                                this.songDetails.title = ad.title;
                                this.songDetails.subTitle = ad.tagLine;
                                this.songDetails.image = ad.logo;
                                this.songDetails.type = 'com';
                                this.songDetails.link = ad.website;
                            }
                        });
                        break;
                    default:
                        this.songDetails = data?.result?.nowplaying;
                        if (this.songDetails?.logo) {
                            if (data.result.nowplaying.logo.substr(0, 2) === '//') {
                                this.songDetails.image = window.location.protocol + this.songDetails.logo;
                            }
                            else {
                                this.songDetails.image = this.songDetails.logo;
                            }
                        }
                        else {
                            this.songDetails.image = './assets/images/ad-none.gif';
                        }
                        this.songDetails.subTitle = this.songDetails.artist;
                        break;
                }
                this.detectChanges();
            }
        }, (error) => {
            console.error('error', error);
            this.layoutUtilsService.showNotification('Station details not available', 'Dismiss');
        });
    }
}