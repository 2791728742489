import { Component, Inject, OnInit, ChangeDetectorRef, ElementRef, ViewChild, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';
import { fromEvent, BehaviorSubject, merge, Subscription, Observable, Observer } from 'rxjs';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { urlSafeBase64Encoding } from '../../../shared/helpers';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { environment } from '../../../../environments/environment';
import { MultipleModalDialogComponent } from '../custom-multiple-upload-dialog/custom-multiple-upload-dialog.component';

interface ViewDialogData {
	title: string;
	data: any;
	galleryType: string;
	targetId: string;
	noSelection: boolean;
	multipleSelection: boolean;
	addCropTile: boolean;
	maxHeight: number;
	maxWidth: number;
	limitNumberOfImages: number;
	allowedExtensions: string[];
	allowedAcceptExtensions: string;
}

@Component({
	selector: 'app-gallery-dialog-modal',
	templateUrl: './custom-gallery-dialog.component.html',
	styleUrls: ['./custom-gallery-dialog.component.scss']
})
export class ModalGalleryDialogComponent implements OnInit {
	private subscriptions: Subscription[] = [];
	public selectedUser: any;
	public errorMessage: string = '';
	public passingThru: boolean = false;
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public noSelection: boolean = false;
	public addCropTile: boolean = false;
	public multipleSelection: boolean = false;
	public multipleSelected: any = {};
	public multipleSelectedObject: any = {};
	public newImage: boolean = false;
	public production: boolean = environment.production;
	public dataType: string = 'img/resource';
	public dataTypeFolder: string = 'folder/resource';
	public dataTypeDisplay: string = this.translate.instant('Image');
	public dataTypeDisplayFolder: string = this.translate.instant('Folder');
	public dataList: any[] = [];
	public dataListFolder: any[] = [];
	public imageSelected: any = undefined;
	public imageUrlPath: any = undefined;
	public selectedRatio: number = 1;
	public folderText: string = '';
	public folderSelected: string = '';
	public tabSelected: number = 0;
	public galleryType: string = 'tileart';
	public targetId: string;
	public searchVal: string = '';
	public imageChangedEvent: any = '';
	public croppedImage: any = '';
	public imageExtension: string = 'png';
	public allowedExtensions: string[] = ['jpeg', 'jpg', 'png', 'gif'];
	public allowedAcceptExtensions: string = 'image/jpg,image/jpeg,image/png,image/gif';
	public maxHeight: number = 5000;
	public maxWidth: number = 5000;
	public limitNumberOfImages: number = 0;

	public paginatorTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	pageSize = 10;
	pageNumber = 1;

	orderDir = 'desc';
	orderBy = 'createdAt'; // uid

	// @ViewChild('searchInput') searchInput: ElementRef;
	@ViewChild('imageInput') private imageInput: ElementRef;
	@ViewChild(MatPaginator) paginator: MatPaginator;
	@ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
	constructor(private zone: NgZone, private translate: TranslateService,
		private requestService: RequestService,
		private cdr: ChangeDetectorRef, private loaderService: LoaderService,
		private layoutUtilsService: LayoutUtilsService, public dialog: MatDialog,
		public dialogRef: MatDialogRef<ModalGalleryDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		// console.log('ModalGalleryDialogComponent', data);
		this.maxHeight = data.maxHeight || 5000;
		this.maxWidth = data.maxWidth || 5000;
		this.noSelection = data.noSelection || false;
		this.addCropTile = data.addCropTile || false;
		this.multipleSelection = data.multipleSelection || false;
		this.limitNumberOfImages = data.limitNumberOfImages || 0;
		this.allowedExtensions = data.allowedExtensions || this.allowedExtensions;
		this.allowedAcceptExtensions = data.allowedAcceptExtensions || this.allowedAcceptExtensions;
	}

	ngOnInit() {
		this.subscriptions.push(
			this.requestService.currentUserSubject.subscribe((data) => {
				if (data) {
					this.selectedUser = data;
					this.targetId = data._id;
					if (this.data.hasOwnProperty('galleryType'))
						this.galleryType = this.data.galleryType;
					if (this.data.hasOwnProperty('targetId'))
						this.targetId = this.data.targetId;
					this.loadFolderData();
				}
			})
		);
	}
	ngAfterViewInit() {
		// const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
		// 	debounceTime(150),
		// 	distinctUntilChanged(),
		// 	tap(() => {
		// 		this.paginatorTotal.next(0);
		// 		this.loadData();
		// 	})
		// )
		// .subscribe();
		// this.subscriptions.push(searchSubscription);
		const paginatorSubscriptions = merge(this.paginator.page).pipe(
			tap(() => {
				this.getTableVariables();
				this.loadData();
			})
		)
			.subscribe();
		this.subscriptions.push(paginatorSubscriptions);
		// this.loadData();
	}

	public getTableVariables() {
		// this.orderBy = this.sort.active || 'uid';
		// this.orderDir = this.sort.direction || 'asc';
		this.pageNumber = this.paginator.pageIndex + 1;
		this.pageSize = this.paginator.pageSize;
	}
	public rationChanged(event) {
		this.selectedRatio = Number(event.value);
	}
	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}
	public loadData() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			let termConfiguration = '';
			let filters = {}
			filters['$and'] = [{ "type": { "$eq": this.galleryType } }];
			if (this.folderSelected) {
				filters['$and'].push({ "folder": { "$eq": this.folderSelected } })
			}

			let filterObj = { perpage: this.pageSize, page: this.pageNumber, orderBy: this.orderBy, orderDir: this.orderDir, term: termConfiguration, filter: filters };
			//, fieldKeys:["title", "art", "isWeight", "Procedure", "hsrRuleEngine", "smart", "notification"] // try decrease fields names
			this.requestService.getDataListByListByOrgByAny(this.targetId, this.dataType, filterObj, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
				}
				if (data) {
					//console.log('dataList', data);
					let dataList = data.results;
					let timestamp = new Date().getTime();
					this.dataList = dataList.map((itm) => {
						if (itm['thumbnail']) {
							itm['thumbnail'] = itm['thumbnail'] + '?t=' + timestamp;
						}
						return itm;
					}
					);
				}
				this.paginatorTotal.next(data.pagination.total);
				this.loading = false;
			});
		}
	}
	public delete(e, id: any) {
		if (e) {
			e.stopImmediatePropagation();
			e.preventDefault();
			// e.stopPropagation();
		}
		if (!this.loading) {
			const _title: string = this.translate.instant('Deleting') + ' ' + this.dataTypeDisplay;
			const _description: string = this.translate.instant('Are you sure you want to permanently delete this') + ' ' + this.dataTypeDisplay + '?';
			const _waitDesciption: string = this.translate.instant('Deleting') + '...';

			const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
			dialogRef.afterClosed().subscribe(res => {
				if (!res) {
					return;
				}
				this.realDelete(id);
			});
		}
	}
	public realDelete(id: any) {
		const _deleteMessage = this.dataTypeDisplay + ' ' + this.translate.instant('Deleted Successfully') + '.';
		// this.layoutUtilsService.showNotification(_deleteMessage,  this.translate.instant('Dismiss'));
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			// if(this.production){
			// 	this.requestService.deleteImageDataByOrgByAny(this.targetId, this.dataType, id, (data, error) => {
			// 		if (error) {
			// 			this.errorMessage = error;
			// 			this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
			// 		}
			// 		this.loading = false;
			// 		if (data) {
			// 			this.layoutUtilsService.showNotification(_deleteMessage, this.translate.instant('Dismiss'));
			// 			this.imageSelected = undefined;
			// 			this.imageUrlPath = undefined;
			// 			// this.pageNumber = 1;
			// 			this.loadData();
			// 		}
			// 	});
			// }else{
			this.requestService.deleteSingleDataByApi(this.dataType, id, 'removeimage', (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
				}
				this.loading = false;
				if (data) {
					this.layoutUtilsService.showNotification(_deleteMessage, this.translate.instant('Dismiss'));
					this.imageSelected = undefined;
					this.imageUrlPath = undefined;
					// this.pageNumber = 1;
					this.loadData();
				}
			});
			// }
		}
	}
	public setFolderSelected(val) {
		this.passingThru = true;
		this.folderSelected = val;
		this.loadData();
	}
	public setImageSelected(val) {
		this.imageSelected = val;
		this.getBase64ImageFromURL(val.imageUrl).subscribe(base64data => {
			// this is the image as dataUrl
			this.imageUrlPath = 'data:image/' + this.imageExtension + ';base64,' + base64data;
		},
			err => {
				// this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + ' ' + this.translate.instant('The image is not accessible'), this.translate.instant('Dismiss'));
			}
		);
		// this.getBase64Image(val.url, (blobData)=> {
		// 	this.imageUrlPath = blobData;
		// })
	}
	// termConfiguration(): any {
	// 	const searchText: string = this.searchInput.nativeElement.value;
	// 	return searchText;
	// }
	closeModal(data): void {
		this.zone.run(() => {
			this.dialogRef.close(data);
		});
	}
	goToCrop(e, val): void {
		if (e) {
			e.stopImmediatePropagation();
			e.preventDefault();
			// e.stopPropagation();
		}
		this.zone.run(() => {
			//console.log(this.imageUrlPath);
			// this.tabSelected = 1;
			this.loaderService.display(true);
			this.imageSelected = val;
			this.getBase64ImageFromURL(val.imageUrl).subscribe(base64data => {
				// this is the image as dataUrl
				this.imageUrlPath = 'data:image/jpg;base64,' + base64data;
				this.tabSelected = 1;
				this.loaderService.display(false);
			},
				err => {
					this.loaderService.display(false);
					// this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + ' ' + this.translate.instant('The image is not accessible'), this.translate.instant('Dismiss'));
				}
			);
		});
	}
	onBrowseFiles(target: any): void {
		// this.readFiles(target.files);
		// if (target.files.length > 1) {
		const dialogRef = this.dialog.open(MultipleModalDialogComponent, {
			width: '100vw',
			disableClose: false,
			autoFocus: false,
			data: {
				title: this.translate.instant('Multiple') + ' ' + this.translate.instant('Images') + ' ' + this.translate.instant('Upload'),
				path: 'img/resource/upload/' + this.requestService.orgId + '/' + this.targetId,
				maxHeight: this.maxHeight,
				maxWidth: this.maxWidth,
				type: this.galleryType,
				target: target,
				isEncoded: 'false',
				folder: this.folderSelected,
				allowedExtensions: this.allowedExtensions,
				allowedAcceptExtensions: this.allowedAcceptExtensions,
				isImageUpload: true,
				addName: false,
				limitNumberOfImages: this.limitNumberOfImages,
				alreadyUploadedImagesLength: this.dataList.length
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (this.imageInput) {
				this.imageInput.nativeElement.value = "";
			}
			if (result) {
				this.pageNumber = 1;
				this.tabSelected = 0;
				this.setImageSelected(result[result.length - 1].uploaded);
				this.loadData();
			}
		});
		// } else {
		// 	this.readFiles(target.files);
		// }
	}
	/**
	 *  @param files: list of browsed files
	 *  @param index: iterator over browsed images
	 *
	 *  read files browsed by user
	 */
	readFiles(files, index = 0): void {
		let reader = new FileReader();
		if (index in files) {
			let currentFile = { error: false, text: files[index].name, id: files[index].id, originalFile: files[index], source_url: null, extension: '' };
			let fileExt = files[index].name.split('.').pop();
			currentFile.extension = fileExt.toLowerCase();
			this.imageExtension = fileExt.toLowerCase();
			const max_size = 5000000;
			const max_height = this.maxHeight;
			const max_width = this.maxWidth;
			if (files[index].size > max_size) {
				this.layoutUtilsService.showNotification(this.translate.instant('Maximum size allowed is') + ' ' + max_size / 1000000 + 'MB', 'Dismiss');
			} else if (this.allowedExtensions.indexOf(fileExt.toLowerCase()) === -1) {
				currentFile.error = true;
				this.layoutUtilsService.showNotification(this.translate.instant('The file type is not allowed'), 'Dismiss');
			} else {
				this.readFile(files[index], reader, (event) => {
					this.loaderService.display(true);
					var image = new Image();
					this.readImage(event, image, (imgresult) => {
						if (imgresult.width <= this.maxWidth && imgresult.height <= this.maxHeight) {
							this.continueUpload(currentFile);
						} else {
							this.loaderService.display(false);
							this.layoutUtilsService.showNotification(this.translate.instant('The image dimensions are too large.'), 'Dismiss');
						}
					});
				});
			}
		} else {
			this.cdr.detectChanges();
		}
	}
	readFile(file, reader, callback): void {
		reader.onload = () => {
			callback(reader.result);
		}
		reader.readAsDataURL(file);
	}
	readImage(file, image, callback): void {
		image.onload = () => {
			callback(image);
		}
		image.src = file;
	}
	continueUpload(currentFile, selectedImageId: string = undefined) {
		this.loaderService.display(true);
		this.requestService.onUploadFilesByAny(this.targetId, currentFile, this.folderSelected, this.galleryType, 'false', selectedImageId)
			.subscribe(
				(results: any) => {
					//console.log('results', results);
					this.loaderService.display(false);
					if (results['status']) {
						currentFile.source_url = results;
						this.pageNumber = 1;
						this.tabSelected = 0;
						this.setImageSelected(results.results);
						this.loadData();
						this.layoutUtilsService.showNotification(this.dataTypeDisplay + ' ' + this.translate.instant('Successfully Uploaded'), this.translate.instant('Dismiss'));
					} else {
						currentFile.error = true;
						this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + results['message'], this.translate.instant('Dismiss'));
					}
					// this.myInputVariable.nativeElement.value = "";
					this.cdr.detectChanges();
					// this.currentFile = currentFile;
				},
				error => {
					//console.log('Error uploading file.', error);
					currentFile.error = true;
					// this.currentFile = currentFile;
					this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + ' ' + this.translate.instant('Error uploading file.'), this.translate.instant('Dismiss'));
					// this.myInputVariable.nativeElement.value = "";
					this.cdr.detectChanges();
					this.loaderService.display(false);
				}
			);
	}
	public loadFolderData() {
		this.requestService.getDataListByOrgByGetByAny(this.targetId, this.dataTypeFolder, (data, error) => {
			if (error) {
				//console.log(error);
			}
			if (data) {
				//console.log('dataListFolder', data);
				this.dataListFolder = data.results;
				this.loadData();
			}
		});
	}
	public changeText(e) {
		//console.log('changeText', e);
		if (e.length === 0) {
			this.loadData();
		}
	}
	public onBlurMethod() {
		setTimeout(() => {
			if (!this.passingThru) {
				this.createIt()
			} else {
				this.passingThru = false;
			}
		}, 500);
	}
	public createIt() {
		if (!this.dataListFolder.includes(this.folderSelected) && this.folderSelected !== '') {
			if (!this.loading) {
				const _title: string = 'Create ' + this.dataTypeDisplayFolder;
				const _description: string = this.translate.instant('Are you sure you want to create this') + ' ' + this.dataTypeDisplayFolder + '?';
				let alertSetting = {
					overlayClickToClose: false,
					showCloseButton: false,
					confirmText: this.translate.instant('Confirm'),
					declineText: this.translate.instant('Close'),
				};
				const dialogRef = this.layoutUtilsService.alertActionElement(_title, _description, alertSetting);
				dialogRef.afterClosed().subscribe(res => {
					if (res.action === 'confirmText') {
						this.createNewFolder()
					}
				});
			}
		}
	}
	public createNewFolder() {
		if (!this.dataListFolder.includes(this.folderSelected) && this.folderSelected !== '') {
			if (!this.loading) {
				this.loading = true;
				this.errorMessage = '';
				let objData = {
					organizationId: this.requestService.orgId,
					name: this.folderSelected
				};
				this.requestService.saveData(this.dataTypeFolder, objData, (data, error) => {
					if (error) {
						this.errorMessage = error;
						this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
					}
					this.loading = false;
					if (data) {
						this.loadFolderData();
					}
				});
			}
		}
	}
	// fileChangeEvent(event: any): void {
	//     this.imageChangedEvent = event;
	// }
	dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, { type: mime });
	}
	cropIt() {
		// if(this.production){
		// 	this.cropItOld();
		// 	return;
		// }
		let croppedImage = this.imageCropper.crop();
		if (!this.loading && this.imageSelected && croppedImage) {
			this.imageSelected['originalFile'] = this.dataURLtoFile(croppedImage.base64, this.imageSelected.text + '.' + this.imageExtension);
			if (this.newImage) {
				this.continueUpload(this.imageSelected);
			} else {
				this.continueUpload(this.imageSelected, this.imageSelected._id);
			}
		}
	}
	getObjectKeysLength(obj) {
		return Object.keys(obj).length;
	}
	cropItOld() {
		let croppedImage = this.imageCropper.crop();
		// console.log('CropIt', this.imageCropper.crop());
		if (!this.loading && this.imageSelected && croppedImage) {
			this.loading = true;
			this.errorMessage = '';
			let objData = {
				src: this.imageSelected.imageUrl,
				x: croppedImage.cropperPosition.x1 + '',
				y: croppedImage.cropperPosition.y1 + '',
				w: croppedImage.width + '',
				h: croppedImage.height + '',
				folder: this.folderSelected,
				uploadedImage: true
			};
			this.requestService.cropImageByOrgByAny(this.targetId, objData, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
				}
				this.loading = false;
				if (data) {
					if (this.noSelection) {
						this.tabSelected = 0;
						this.pageNumber = 1;
						this.loadData();
					} else {
						this.closeModal(data.results.imageUrl);
					}
				}
			});
		}

	}
	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
	}
	imageHasLoaded(e) {
		//console.log('imageHasLoaded', e);
	}
	imageLoaded() {
		// show cropper
	}
	cropperReady() {
		// cropper ready
	}
	loadImageFailed() {
		// show message
	}
	// public getBase64Image(imgUrl, callback) {
	//
	//     var img = new Image();
	//
	//     // onload fires when the image is fully loadded, and has width and height
	//
	//     img.onload = function(){
	//
	//       var canvas = document.createElement("canvas");
	//       canvas.width = img.width;
	//       canvas.height = img.height;
	//       var ctx = canvas.getContext("2d");
	//       ctx.drawImage(img, 0, 0);
	//       var dataURL = canvas.toDataURL("image/png"),
	//           dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
	//
	//       callback(dataURL); // the base64 string
	//
	//     };
	//
	//     // set attributes and src
	//     img.setAttribute('crossOrigin', 'anonymous'); //
	//     img.src = imgUrl;
	//
	// }

	getBase64ImageFromURL(url: string) {
		return Observable.create((observer: Observer<string>) => {
			// create an image object
			let img = new Image();
			img.crossOrigin = 'Anonymous';
			img.src = url + '?t=' + new Date().getTime();
			if (!img.complete) {
				// This will call another method that will create image from url
				img.onload = () => {
					observer.next(this.getBase64Image(img));
					observer.complete();
				};
				img.onerror = (err) => {
					observer.error(err);
				};
			} else {
				observer.next(this.getBase64Image(img));
				observer.complete();
			}
		});
	}
	getBase64Image(img: HTMLImageElement) {
		// We create a HTML canvas object that will create a 2d image
		var canvas = document.createElement("canvas");
		canvas.width = img.width;
		canvas.height = img.height;
		var ctx = canvas.getContext("2d");
		// This will draw image
		ctx.drawImage(img, 0, 0);
		// Convert the drawn image to Data URL
		var dataURL = canvas.toDataURL("image/" + this.imageExtension, 1);
		return dataURL.replace(/^data:image\/(png|jpg|jpeg|gif);base64,/, "");
	}
	setMultipleSelected(event, id, itm) {
		if (event.checked) {
			this.multipleSelected[id] = true;
			this.multipleSelectedObject[id] = itm;
		} else {
			if (this.multipleSelected.hasOwnProperty(id)) {
				delete this.multipleSelected[id];
			}
			if (this.multipleSelectedObject.hasOwnProperty(id)) {
				delete this.multipleSelectedObject[id];
			}
		}
		console.log('this.multipleSelectedObject', this.multipleSelectedObject);
	}
	multiSelect() {
		let imagesList = [];
		let multipleSelectedObjectKey = Object.keys(this.multipleSelectedObject);
		for (let itmId of multipleSelectedObjectKey) {
			imagesList.push(this.multipleSelectedObject[itmId]);
		}
		this.closeModal(imagesList);

	}
	singleSelect(imageSelected) {
		let imagesList = [];
		imagesList.push(imageSelected);
		this.closeModal(imagesList);

	}
}
