import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, Route } from '@angular/router';
import { FeaturesPermissionService } from '../services/featuresPermission.service';
import { RequestService } from '../services/request.service';
@Injectable()
export class AuthGroupsGuard implements CanActivate {
  public groupParent = undefined;
  public isGroupParent = false;
  public isSuperAdmin = false;
  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('currentUser') && localStorage.getItem('o') && localStorage.getItem('org')) {
      let currentUser = JSON.parse(localStorage.getItem('currentUser'));
      let organizationId = JSON.parse(localStorage.getItem('o'));
      let organization = JSON.parse(localStorage.getItem('org'));
      if (organization['parent'] && organization['parent']['_id']) {
        this.groupParent = organization['parent']['_id'];
        this.isGroupParent = organization['parent']['_id'] === organizationId;
        this.isSuperAdmin = currentUser.isSuperAdmin;
      }
    }
    return this.hasGroupPermissions(route, state);
  }
  private hasGroupPermissions(route: ActivatedRouteSnapshot | Route, state?: RouterStateSnapshot) {
    const groupPermissions = !!route && route.data ? route.data.groupPermissions : {};
    const redirectTo = groupPermissions['redirectTo'];
    if (this.groupParent && this.groupParent !== '') {
      if ((groupPermissions['isGroupParent'] && this.isGroupParent) || (groupPermissions['isSuperAdmin'] && this.isSuperAdmin)) {
        return true;
      }
      this.router.navigate([redirectTo]);
      return false;
    } else {
      if (groupPermissions['onlyGroupParent']) {
        this.router.navigate([redirectTo]);
        return false;
      }
    }
    return true;
  }
}
