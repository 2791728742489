<div class="col-xl-12 galleryPopup">
	<div mat-dialog-title class="dialog-title">
		<div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
			<div fxFlex class="filterName">
				{{data.title}}
			</div>
			<!-- <div fxFlex style="line-height: 75px;">
				<mat-form-field class="example-full-width">
				  <input [(ngModel)]="folderSelected" matInput type="text" placeholder="Select/Add Folder" aria-label="Number" [matAutocomplete]="auto" (focusout)="onBlurMethod()" (change)="changeText($event.target.value)">
				  <mat-autocomplete class="autocomplete" #auto="matAutocomplete" (optionSelected)="setFolderSelected($event.option.value)" >
				      <mat-option *ngFor="let option of dataListFolder" [value]="option" title="{{option}}">{{option}}</mat-option>
				    </mat-autocomplete>
				</mat-form-field>
			</div> -->
		</div>
		<!-- <h2>{{data.title}}</h2> -->
		<!-- <mat-form-field >
				<input matInput #searchInput placeholder="{{'Search' | translate}}" [value]="searchVal">
		</mat-form-field> -->
	</div>
	<div mat-dialog-content class="dialog-content">
		<mat-tab-group [selectedIndex]="tabSelected" (selectedIndexChange)="tabSelected = $event">
			<mat-tab label="Images">
				<mat-card class="itemView" [class.selected]="itm.imageUrl === imageSelected?.imageUrl"
					*ngFor="let itm of dataList; let index=index" (click)="setImageSelected(itm)">
					<mat-card-header>
						<!-- <mat-card-title>{{itm.title}}</mat-card-title> -->
						<!-- <input value="true" (change)="setMultipleSelected($event, itm._id, itm)" [checked]="multipleSelected[itm._id]" [(ngModel)]="multipleSelected[itm._id]" class="checkit" title="Check to multiselect" type="checkbox"> -->
						<mat-checkbox [hidden]="!multipleSelection" class="checkit" title="Check to multiselect"
							[checked]="multipleSelected[itm._id]" (click)="$event.stopPropagation()"
							(change)="setMultipleSelected($event, itm._id, itm)">
						</mat-checkbox>
						<em class="material-icons cropit" (click)="goToCrop($event, itm)">crop</em>
						<!-- <em class="material-icons deleteit" *ngIf="production" (click)="delete($event, itm.imageUrl)">delete_forever</em> -->
						<em class="material-icons deleteit" (click)="delete($event, itm._id)">delete_forever</em>
					</mat-card-header>
					<img *ngIf="itm.thumbnail && itm.thumbnail !== ''" loading="lazy" mat-card-image
						src="{{itm.thumbnail}}">
					<img *ngIf="!(itm.thumbnail && itm.thumbnail !== '')" loading="lazy" mat-card-image
						src="{{itm.imageUrl}}">
					<div class="itemData">
						<div class="dimentions">
							{{itm.dimentions}}
						</div>
						<div class="size">
							{{itm.size}}
						</div>
					</div>
				</mat-card>
			</mat-tab>
			<mat-tab #tabCrop label="Crop" [disabled]="!imageUrlPath || !imageSelected ">
				<image-cropper *ngIf="imageUrlPath" style="background-color: rgb(221 221 221 / 0.20);"
					[imageBase64]="imageUrlPath" [maintainAspectRatio]="!(selectedRatio === 0)"
					[aspectRatio]="selectedRatio" [format]="imageExtension" [autoCrop]="false"
					(imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()"
					(loadImageFailed)="loadImageFailed()">
				</image-cropper>
				<div class="mb-20" fxFlex fxLayout="row" fxLayout.lt-md="column">
					<div fxFlex style="text-align: left;" *ngIf="!production">
						<mat-checkbox class="example-margin" name="overwrite" [(ngModel)]="newImage">{{'Crop as a new
							image' | translate}}</mat-checkbox>
						<div>Note that transparency will be lost after cropping an image</div>
						<div>Cropping a gif image will cause it to lose motion and result in a still image</div>
					</div>
					<div fxFlex style="text-align: right;">
						<mat-radio-group aria-label="Select Ratio" [value]="selectedRatio"
							(change)="rationChanged($event)">
							<mat-radio-button *ngIf="addCropTile" [value]="1 / 0.4">Crop for Tile</mat-radio-button>
							<mat-radio-button [value]="1">Square Crop</mat-radio-button>
							<mat-radio-button [value]="0">Free crop</mat-radio-button>
						</mat-radio-group>
					</div>
				</div>
			</mat-tab>
		</mat-tab-group>

	</div>
	<div mat-dialog-actions class="dialog-actions">
		<mat-paginator [pageSize]="pageSize" [length]="paginatorTotal | async" [showFirstLastButtons]="true">
		</mat-paginator>
		<mat-divider></mat-divider>
		<div style="width: 100%;margin-top:10px;">
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
			<input #imageInput [hidden]="true" type="file" name="upfileLogo" accept="{{allowedAcceptExtensions}}"
				multiple="true" id="image_selector" (change)="onBrowseFiles($event.target)">
			<button mat-raised-button (click)="imageInput.click()"
				*ngIf="dataList.length >= limitNumberOfImages && limitNumberOfImages !== 0" disabled="true"
				[title]="'You can upload up to (max) background images. Please remove some of the previously uploaded images then try again.' | translate: { max: limitNumberOfImages}">{{'Upload'
				|
				translate}}</button>
			<button mat-raised-button (click)="imageInput.click()"
				*ngIf="!(dataList.length >= limitNumberOfImages && limitNumberOfImages !== 0)">{{'Upload'
				|
				translate}}</button>
			<!-- <button mat-raised-button (click)="goToCrop()" [hidden]="!imageUrlPath || !imageSelected || tabSelected === 1">{{'Crop' | translate}}</button> -->
			<button mat-raised-button (click)="cropIt()" [hidden]="tabSelected === 0">{{'Confirm Crop' |
				translate}}</button>
			<button *ngIf="!noSelection" mat-raised-button (click)="singleSelect(imageSelected)"
				[hidden]="!imageSelected || getObjectKeysLength(multipleSelected) > 0">{{'Select without Crop' |
				translate}}</button>
			<button *ngIf="multipleSelection" mat-raised-button (click)="multiSelect()"
				[hidden]="getObjectKeysLength(multipleSelected) == 0">{{'Select checked images' | translate}}</button>
		</div>
	</div>
	<br />
</div>