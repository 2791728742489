<div class="col-xl-12 galleryPopup">
	<div mat-dialog-title class="dialog-title">
		<div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
			<div fxFlex class="filterName">
				{{data.title}}
			</div>
		</div>
		<!-- <h2>{{data.title}}</h2> -->
		<!-- <mat-form-field >
				<input matInput #searchInput placeholder="{{'Search' | translate}}" [value]="searchVal">
		</mat-form-field> -->
	</div>
	<div mat-dialog-content class="dialog-content">
		<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
			<div fxFlex="15" style="border-right: 1px solid #e0e0e0;">
				<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
					<div fxFlex>
						<button mat-raised-button (click)="imageInput.click()">+ {{'Upload Image' | translate}}</button>
					</div>
				</div>
				<mat-list>
					<mat-list-item class="mat-list-item" [class.selectedItem]="menuSelected === 'siteImages'">My Images
					</mat-list-item>
				</mat-list>
				<mat-divider style="margin:10px 0px;" *ngIf="handleBgColor"></mat-divider>
				<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" *ngIf="handleBgColor">
					<div fxFlex="50" style="align-items: center;display: flex;font-size: 16px;">
						Select BG Color
					</div>
					<div fxFlex="50" style="align-items: center;display: flex;">
						<input style="width: 50%;" type="color" name="bgcolor" [(value)]="bgBackgroundColor"
							placeholder="{{'Select BG Color' | translate}}"
							(change)="bgBackgroundColor = $event.target.value" title="Select Background">
					</div>
				</div>
			</div>
			<div fxFlex="85" style="position: relative;">
				<mat-form-field *ngIf="tabSelected === 0" style="position: absolute;right: 0px;top: 4px;z-index: 2;">
					<input matInput #searchInputImage placeholder="Search" [value]="searchValImage">
				</mat-form-field>
				<mat-tab-group [selectedIndex]="tabSelected" (selectedIndexChange)="tabSelected = $event">
					<mat-tab label="Images">
						<mat-card class="itemView" [class.selected]="itm.imageUrl === imageSelected?.imageUrl"
							*ngFor="let itm of dataList; let index=index" (click)="setImageSelected(itm)">
							<mat-card-header>
								<!-- <mat-card-title>{{itm.title}}</mat-card-title> -->
								<em class="material-icons cropit" (click)="goToCrop($event, itm)">crop</em>
								<!-- <em class="material-icons deleteit" *ngIf="production" (click)="delete($event, itm.imageUrl)">delete_forever</em> -->
								<em class="material-icons deleteit"
									(click)="delete($event, itm._id)">delete_forever</em>
							</mat-card-header>
							<img *ngIf="itm.thumbnail && itm.thumbnail !== ''" loading="lazy" mat-card-image
								src="{{itm.thumbnail}}">
							<img *ngIf="!(itm.thumbnail && itm.thumbnail !== '')" loading="lazy" mat-card-image
								src="{{itm.imageUrl}}">
							<div class="itemData">
								<div class="dimentions">
									{{itm.dimentions}}
								</div>
								<div class="size">
									{{itm.size}}
								</div>
							</div>
						</mat-card>

					</mat-tab>
					<mat-tab #tabCrop label="Crop" [disabled]="!imageUrlPath || !imageSelected ">
						<image-cropper *ngIf="imageUrlPath" style="background-color: rgb(221 221 221 / 0.20);"
							[imageBase64]="imageUrlPath" [maintainAspectRatio]="!(selectedRatio === 0)"
							[aspectRatio]="selectedRatio" [format]="imageExtension" [autoCrop]="false"
							(imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()"
							(cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
						<div class="mb-20" fxFlex fxLayout="row" fxLayout.lt-md="column">
							<div fxFlex style="text-align: left;">
								<mat-checkbox class="example-margin" name="overwrite" [(ngModel)]="newImage">{{'Crop as
									a new image' | translate}}</mat-checkbox>
								<div>Note that transparency will be lost after cropping an image</div>
								<div>Cropping a gif image will cause it to lose motion and result in a still image</div>
							</div>
							<div fxFlex style="text-align: right;">
								<mat-radio-group aria-label="Select Ratio" [value]="selectedRatio"
									(change)="rationChanged($event)">
									<!-- <mat-radio-button [value]="1 / 0.4">Crop for Tile</mat-radio-button>
								<mat-radio-button [value]="1">Square Crop</mat-radio-button> -->
									<mat-radio-button [value]="5 / 2.4">Crop for Background</mat-radio-button>
									<mat-radio-button [value]="0">Free crop</mat-radio-button>
								</mat-radio-group>
							</div>
						</div>
					</mat-tab>
				</mat-tab-group>
				<mat-paginator [hidden]="tabSelected===1" [pageSize]="pageSize" [length]="paginatorTotal | async"
					[showFirstLastButtons]="true"></mat-paginator>
			</div>
		</div>
	</div>
	<div mat-dialog-actions class="dialog-actions">
		<mat-divider></mat-divider>
		<div style="width: 100%;margin-top:10px;">

			<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
				<div fxFlex>
					<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' |
						translate}}</button>
					<input #imageInput [hidden]="true" type="file" name="upfileLogo"
						accept="{{allowedAcceptExtensions}}" multiple="true" id="image_selector"
						(change)="onBrowseFiles($event.target)">
				</div>
				<div fxFlex style="text-align: right;">
					<ng-container *ngIf="tabSelected === 0">
						<button mat-raised-button (click)="clear('')" *ngIf="noImage">{{'No Image' |
							translate}}</button>
						<button mat-raised-button (click)="clear('')" *ngIf="handleBgColor">{{'Clear BG' |
							translate}}</button>
						<button mat-raised-button (click)="withoutcropIt('')" *ngIf="handleBgColor">{{'Apply BG Color' |
							translate}}</button>
						<span [hidden]="!imageSelected || !noImage" style="margin: 0px 25px;">or</span>
						<button *ngIf="!notBg" mat-raised-button (click)="withoutcropIt(imageSelected.imageUrl)"
							[hidden]="!imageSelected">{{'Apply BG Image' | translate}}</button>
						<button *ngIf="notBg" mat-raised-button (click)="withoutcropIt(imageSelected.imageUrl)"
							[hidden]="!imageSelected">{{'Apply Image' | translate}}</button>
					</ng-container>
					<ng-container *ngIf="tabSelected === 1">
						<button mat-raised-button (click)="cropIt()">{{'Confirm Crop' | translate}}</button>
					</ng-container>
				</div>
			</div>
			<!-- <button mat-raised-button (click)="goToCrop()" [hidden]="!imageUrlPath || !imageSelected || tabSelected === 1">{{'Crop' | translate}}</button> -->
		</div>
	</div>
	<br />
</div>