// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { scheduled } from 'rxjs';
import { LayoutUtilsService } from 'src/app/shared/services/utils/layout-utils.service';
import { ModalGalleryDialogComponent } from '../../custom-gallery-dialog/custom-gallery-dialog.component';
import { SchedulerTasks } from '../../layout-components/scheduler-tasks';
declare var CKEDITOR;

@Component({
	selector: 'kt-confirm-camera-entity-dialog',
	templateUrl: './confirm-camera-entity-dialog.component.html',
	styleUrls: ['../modal.scss', './confirm-camera-entity-dialog.component.scss']
})
export class ConfirmCameraEntityDialogComponent implements OnInit {
	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
	 * @param overlay: any
	 * @param taskName: string
	 * @param taskAction: string
	 */
	public tasksName: any = SchedulerTasks.tasksName;
	public hasChanged: boolean = false;
	editorConfig = undefined;
	widget_Buttons_map = ['dial', 'email'];
	constructor(private layoutUtilsService: LayoutUtilsService, private translate: TranslateService, public dialog: MatDialog,
		public dialogRef: MatDialogRef<ConfirmCameraEntityDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		if (!data.hasOwnProperty('overlay')) {
			let content = this.tasksName[data.taskName].content;
			let defaultData = content.overlay;
			data.overlay = defaultData;
		} else {
			if (!data.overlay.hasOwnProperty('motion')) {
				data.overlay['motion'] = 'static';
			}
		}
		if (data.hasOwnProperty('config')) {
			this.editorConfig = data.config;
		} else {
			this.editorConfig = {
				toolbar: [
					// { name: 'document', items: ['Source', '-'] },
					{ name: 'clipboard', items: ['Undo', 'Redo', '-'] },
					{ name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', '-'] },
					{ name: 'links', items: ['Link', 'Unlink', '-'] },
					{ name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize', '-'] },
					{ name: 'colors', items: ['TextColor', 'BGColor'] },
					{ name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl'] },
				],
				height: '310px',
				shiftEnterMode: CKEDITOR.ENTER_DIV,
				enterMode: CKEDITOR.ENTER_DIV,
				removePlugins: 'magicline',
				linkShowAdvancedTab: false,
				linkShowTargetTab: false,
				resize_enabled: false,
				versionCheck: false
			};
		}
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
	}

	/**
	 * Close dialog with false result
	 */
	onNoClick(): void {
		this.dialogRef.close();
	}

	/**
	 * Close dialog with true result
	 */
	onYesClick(): void {
		// this.data.overlay['updatedAt'] = new Date();
		// if (this.data && this.data.overlay && this.data.overlay.trigger === 'schedule' && (this.data.overlay.startTime == 0)) {
		// 	this.layoutUtilsService.showNotification(this.translate.instant('Start Time should be greater than 0'), this.translate.instant('Dismiss'));
		// 	return;
		// }
		if (this.data && this.data.overlay && this.data.overlay.trigger === 'schedule' && (this.data.overlay.duration == 0 && this.data.overlay.duration != null)) {
			this.layoutUtilsService.showNotification(this.translate.instant('Duration should be greater than 0 or empty'), this.translate.instant('Dismiss'));
			return;
		}
		if (this.hasChanged) {
			this.hasChanged = false;
			this.dialogRef.close(this.data.overlay);
		} else {
			this.dialogRef.close();
		}
	}

	public onChange(id, value) {
		if (id === 'type') {
			this.data.overlay['content'] = '';
			if (value === 'image') {
				this.data.overlay['motion'] = 'static';
			}
		}
		if (id === 'trigger') {
			this.data.overlay['startTime'] = 0;
			this.data.overlay['duration'] = null;
			this.data.overlay['running'] = false;
		}
		this.data.overlay[id] = value;
		this.hasChanged = true;
	}

	/**
  * editNumericAttribute function
  */
	editNumericAttribute($event, id, value, min, max, nullable = false) {
		if ($event) {
			$event.preventDefault();
			$event.stopPropagation();
		}
		let newVal = Number(value);
		if (nullable && value == '') {
			newVal = null;
		} else {
			if (min !== undefined && min > newVal) {
				newVal = min;
			}
			if (max !== undefined && max < newVal) {
				newVal = max;
			}
		}
		this.data.overlay[id] = newVal;
		this.hasChanged = true;
	}
	openImageLibrary(): void {
		const dialogRef = this.dialog.open(ModalGalleryDialogComponent, {
			width: '1600px',
			disableClose: false,
			autoFocus: false,
			data: {
				title: this.translate.instant('Select Image'),
				galleryType: 'imagegallery',
				multipleSelection: false,
				data: [],
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				if (Array.isArray(result)) {
					for (let itm of result) {
						this.data.overlay['content'] = itm.thumbnail;
					}
				} else {
					this.data.overlay['content'] = result;
				}
				this.hasChanged = true;
			}
		});
	}
}
