import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { interval } from 'rxjs/internal/observable/interval';
import { LayoutUtilsService, RequestService } from 'src/app/shared';

interface ViewDialogData {
	title: string;
	id: string;
	quuId: string;
	type: string;
	stationLetters: string;
}

@Component({
	selector: 'app-quu-song-dialog-modal',
	templateUrl: './quu-song-dialog.component.html',
	styleUrls: ['./quu-song-dialog.component.scss']
})
export class QuuSongDialogComponent implements OnInit, OnDestroy {

	public loading: boolean = false;
	public showCoupon: boolean = false;
	public adDetails: any = {};
	public emailFormControl = new FormControl('', [Validators.required, Validators.email]);
	public selectedBannerIndex: number = 0;
	public banner: any = [];

	private bannerSubscription: any = undefined;

	constructor(public dialogRef: MatDialogRef<QuuSongDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData, private requestService: RequestService, private layoutUtilsService: LayoutUtilsService, private translate: TranslateService) {

		if (data.type.toLowerCase() === 'com') {
			this.loading = true;
			this.requestService.getQuuAdDetails(data.stationLetters, data.quuId).subscribe((data: any) => {
				this.loading = false;
				if (data?.result?.ad) {
					this.adDetails = data.result.ad;
					if (this.adDetails.logo.substr(0, 2) === '//') {
						this.adDetails.logo = window.location.protocol + this.adDetails.logo;
					}
					// this.adDetails.audio = 'https://filesamples.com/samples/audio/aac/sample4.aac';
					// this.adDetails.video = 'https://www.youtube.com/embed/dO490AKvT8s';
					// this.adDetails.map = 'https://www.google.com/maps/place/205+Lonsdale+Ave,+North+Vancouver,+BC+V7M+2E9,+Canada/@49.312938,-123.077874,16z/data=!4m5!3m4!1s0x5486704c6bc60b05:0xee9968e5c48e4cc7!8m2!3d49.3129375!4d-123.0778736?hl=en-US';
					// this.adDetails.coupon = "//quuit.com/ImageServer/campaign_images/17307/coupon/APP-COUPON-DayAndNite-Feb2022.jpg";
					this.adDetails.placeholder = {};

					if (this.adDetails.video) {
						this.adDetails.placeholder.source = this.adDetails.video;
						this.adDetails.placeholder.type = 'video';
					}
					else if (this.adDetails.audio) {
						this.adDetails.placeholder.source = this.adDetails.audio;
						this.adDetails.placeholder.type = 'audio';
					}
					else {
						this.adDetails.placeholder = this.adDetails.images[0];
						this.adDetails.placeholder.type = 'image';
					}
				}
			});

			this.requestService.getQuuBanner(data.stationLetters).subscribe((data: any) => {
				if (data?.result?.advertisers?.length) {
					data.result.advertisers.forEach(image => {
						this.banner.push({ image: image.largebanner, title: image.title, website: image.website });
					});

					this.bannerSubscription = interval(1000 * 5).subscribe(() => {
						++this.selectedBannerIndex
						if (this.selectedBannerIndex === this.banner.length)
							this.selectedBannerIndex = 0;
					});
				}
			});
		}
	}

	ngOnInit() { }

	ngOnDestroy() {
		this.bannerSubscription?.unsubscribe();
	}

	closeModal(): void {
		this.showCoupon = false;
		this.bannerSubscription?.unsubscribe();
		this.dialogRef.close();
	}

	openWebsite(website) {
		window.open(website, '_blank');
	}

	showItem(src, type) {
		this.adDetails.placeholder.source = src;
		this.adDetails.placeholder.type = type;
	}

	toggleCoupon() {
		this.showCoupon = !this.showCoupon;
	}

	printCoupon() {
		let printContent = document.getElementById('couponImage').innerHTML;
		let winPrint = window.open('', '', 'left=0,top=0,toolbar=0,scrollbars=yes,status=0');
		winPrint.document.write(printContent);
		winPrint.document.close();
		winPrint.focus();
		winPrint.print();
		winPrint.close();
	}

	emailCoupon() {
		if (this.emailFormControl.value) {
			this.requestService.emailQuuCoupon(this.data.stationLetters, this.emailFormControl.value, this.data.quuId).subscribe((data: any) => {
				if (data.status)
					this.layoutUtilsService.showNotificationSnack('Email Sent.', this.translate.instant('Dismiss'));
			});
		}
	}
}
