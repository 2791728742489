export class LayoutComponents {
  public static componentsName: any = {
    audio: {
      displayName: 'Audio Player',
      fixed: true,
      rules: {
        minSize: { w: 14, h: 2 },
        maxSize: { w: 100, h: 2 },
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'audio',
        uid: '',
        active: true,
        metadata: {
          src: '',
          autoPlay: false,
          loop: false,
          volume: 0.5
        },
        x: 0,
        y: 0,
        w: 5,
        h: 2,
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    videolibrary: {
      displayName: 'Media Library',
      fixed: true,
      rules: {
        minSize: { w: 21, h: 9 },
        maxSize: { w: 100, h: 100 },
        ratioSize: { w: 7, h: 3 },
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'videolibrary',
        uid: '',
        active: true,
        metadata: {
          id: '',
          name: '',
          autoPlay: false,
          loop: false,
          expanded: false,
          volume: 0.5
        },
        x: 10,
        y: 0,
        w: 21,
        h: 9,
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    imagegallery: {
      displayName: 'Image Gallery',
      fixed: true,
      rules: {
        minSize: { w: 21, h: 9 },
        maxSize: { w: 100, h: 100 },
        // ratioSize: {w: 7, h: 4},
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'imagegallery',
        uid: '',
        active: true,
        metadata: {
          id: '',
          name: '',
          autoPlay: false
        },
        x: 10,
        y: 0,
        w: 21,
        h: 9,
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    screenshare: {
      displayName: 'Main Camera/Screen Share',
      fixed: false,
      rules: {
        minSize: { w: 10, h: 5.6 },
        maxSize: { w: 100, h: 100 },
        ratioSize: { w: 16, h: 9 },
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: ['presenter-camera']
      },
      content: {
        name: 'screenshare',
        uid: '',
        active: true,
        message: '',
        x: 0,
        y: 0,
        w: 16,
        h: 19,
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    camera: {
      displayName: 'Attendee Camera',
      fixed: false,
      rules: {
        minSize: { w: 10, h: 5.6 },
        maxSize: { w: 100, h: 100 },
        ratioSize: { w: 16, h: 9 },
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'camera',
        uid: '',
        active: true,
        enableAllowAllSpeak: false,
        x: 0,
        y: 0,
        w: 16,
        h: 19,
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    'guest-camera': { // Secondary Camera
      displayName: 'Camera',
      fixed: true,
      rules: {
        minSize: { w: 10, h: 5.6 },
        maxSize: { w: 100, h: 100 },
        ratioSize: { w: 16, h: 9 },
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'guest-camera',
        uid: '',
        active: true,
        viewPublicChat: true,
        metadata: {
          id: '',
          name: ''
        },
        x: 0,
        y: 0,
        w: 16,
        h: 19,
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    attendees: {
      displayName: 'Attendees',
      fixed: false,
      rules: {
        minSize: { w: 5, h: 1 },
        maxSize: { w: 100, h: 100 },
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'attendees',
        uid: '',
        active: true,
        expanded: false,
        backgroundColor: '#ffffff',
        x: 0,
        y: 0,
        w: 10,
        h: 10,
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    defaultvideo: {
      displayName: 'Streaming Media',
      fixed: true,
      rules: {
        minSize: { w: 10, h: 5.6 },
        maxSize: { w: 100, h: 100 },
        ratioSize: { w: 16, h: 9 },
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'defaultvideo',
        uid: '',
        active: true,
        notFixedRatio: false,
        autoStart: false,
        synchost: false,
        autoRepeat: false,
        loop: false,
        isPlaying: false,
        isUsedByShortcut: false,
        disableAttendees: false,
        type: 'youtube',
        url: '',
        urlId: '',
        clickThruUrl: '',
        hlsUrl: '',
        startMin: 0,
        startSec: 0,
        x: 0,
        y: 0,
        w: 16,
        h: 19,
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    'presenter-camera': { // primary camera
      displayName: 'Main Camera',
      hlsdisplayName: 'Cameras',
      fixed: false,
      rules: {
        minSize: { w: 10, h: 5.6 },
        maxSize: { w: 100, h: 100 },
        ratioSize: { w: 16, h: 9 },
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'presenter-camera',
        uid: '',
        active: true,
        viewPublicChat: true,
        metadata: {
          id: '',
          name: ''
        },
        x: 0,
        y: 0,
        w: 16,
        h: 19,
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    billboard: {
      displayName: 'Billboard',
      fixed: true,
      rules: {
        minSize: { w: 5, h: 1 },
        maxSize: { w: 100, h: 100 },
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'billboard',
        uid: '',
        metadata: {
          id: '',
          name: ''
        },
        active: true,
        x: 0,
        y: 0,
        w: 10,
        h: 10,
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    editor: {
      displayName: 'Editor',
      fixed: true,
      rules: {
        minSize: { w: 5, h: 1 },
        maxSize: { w: 100, h: 100 },
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'editor',
        uid: '',
        data: '',
        active: true,
        x: 0,
        y: 0,
        w: 10,
        h: 10,
        z: 1,
        bgcolor: '',
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    picture: {
      displayName: 'Picture',
      fixed: true,
      rules: {
        minSize: { w: 5, h: 1 },
        maxSize: { w: 100, h: 100 },
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'picture',
        uid: '',
        naturalRatioSize: undefined,
        image: '',
        title: '',
        fixed: false,
        notFixedRatio: false,
        metadata: {
          type: 'link',
          link: '',
          linkDisplay: '',
          autoPlay: false,
          loop: false,
          volume: 0.5
        },
        active: true,
        x: 0,
        y: 0,
        w: 10,
        h: 10,
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    iframe: {
      displayName: 'iFrame',
      fixed: true,
      rules: {
        minSize: { w: 5, h: 1 },
        maxSize: { w: 100, h: 100 },
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'iframe',
        uid: '',
        fixed: false,
        metadata: {
          type: 'link',
          link: '',
          linkDisplay: ''
        },
        active: true,
        x: 0,
        y: 0,
        w: 10,
        h: 10,
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    threeModel: {
      displayName: '3D Model',
      fixed: true,
      rules: {
        minSize: { w: 5, h: 1 },
        maxSize: { w: 100, h: 100 },
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'threeModel',
        uid: '',
        title: '',
        fixed: false,
        metadata: {
          type: 'link',
          link: '',
          linkDisplay: '',
          cx: 25,
          cy: 25,
          cz: 25,
          x: 0,
          y: 20,
          z: 0,
          scale: 5,
          resourcePath: '',
          resourceName: '',
          resourceNameAnimation: '',
          resourceAnimation: '',
        },
        active: true,
        x: 0,
        y: 0,
        w: 10,
        h: 10,
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    tile: {
      displayName: 'Tile',
      fixed: true,
      rules: {
        minSize: { w: 5, h: 1 },
        maxSize: { w: 100, h: 100 },
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'tile',
        uid: '',
        metadata: {
          id: '',
          name: '',
          categoryId: ''
        },
        active: true,
        x: 0,
        y: 0,
        w: 10,
        h: 10,
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    picturewall: {
      displayName: 'Picture Wall',
      fixed: false,
      rules: {
        minSize: { w: 5, h: 1 },
        maxSize: { w: 100, h: 100 },
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'picturewall',
        uid: '',
        active: true,
        timer: '',
        moderated: false,
        gutterSize: '5px',
        bgcolor: '#ffffff',
        opacity: 0,
        spacing: '5px',
        gridSizeX: 3,
        gridSizeY: 3,
        x: 0,
        y: 0,
        w: 10,
        h: 10,
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    'empty-click': {
      displayName: 'Clickable Area',
      fixed: true,
      rules: {
        minSize: { w: 5, h: 1 },
        maxSize: { w: 100, h: 100 },
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'empty-click',
        uid: '',
        svg: [],
        shape: 'square',
        image: '',
        title: '',
        metadata: {
          type: 'link',
          link: '',
          linkDisplay: '',
          autoPlay: false,
          loop: false,
          volume: 0.5
        },
        active: true,
        x: 0,
        y: 0,
        w: 10,
        h: 10,
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    questionnaire: {
      displayName: 'Questionnaire',
      fixed: true,
      rules: {
        minSize: { w: 5, h: 1 },
        maxSize: { w: 100, h: 100 },
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'questionnaire',
        uid: '',
        metadata: {
          id: '',
          name: ''
        },
        active: true,
        x: 0,
        y: 0,
        w: 10,
        h: 10,
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    linkDescription: {
      displayName: 'Link with Description',
      fixed: true,
      rules: {
        minSize: { w: 5, h: 1 },
        maxSize: { w: 100, h: 100 },
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'linkDescription',
        uid: '',
        metadata: {
          id: '',
          name: ''
        },
        active: true,
        x: 0,
        y: 0,
        w: 10,
        h: 10,
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    inquiry: {
      displayName: 'Inquiry',
      fixed: true,
      rules: {
        minSize: { w: 5, h: 1 },
        maxSize: { w: 100, h: 100 },
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'inquiry',
        uid: '',
        metadata: {
          id: '',
          name: ''
        },
        active: true,
        x: 0,
        y: 0,
        w: 10,
        h: 10,
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    audienceView: {
      displayName: 'Audience View',
      fixed: false,
      rules: {
        minSize: { w: 5, h: 1 },
        maxSize: { w: 100, h: 100 },
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'audienceView',
        uid: '',
        active: true,
        gridSizeX: 3,
        gridSizeY: 2,
        groups: [],
        x: 0,
        y: 0,
        w: 16,
        h: 19,
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    panelView: {
      displayName: 'Panel Cameras',
      fixed: false,
      rules: {
        minSize: { w: 5, h: 1 },
        maxSize: { w: 100, h: 100 },
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'panelView',
        uid: '',
        active: true,
        totalCameras: 3,
        camerasPerRow: 2,
        metadata: [],
        groups: [],
        x: 0,
        y: 0,
        w: 10,
        h: 6, // make it 10 later
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    quuNowPlaying: {
      displayName: 'Quu Radio - Now Playing',
      fixed: true,
      rules: {
        minSize: { w: 5, h: 1 },
        maxSize: { w: 100, h: 100 },
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'quuNowPlaying',
        uid: '',
        active: true,
        metadata: {
          callerId: '',
          stationId: '',
          plsUrl: ''
        },
        x: 0,
        y: 0,
        w: 10,
        h: 10,
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    quuSongs: {
      displayName: 'Quu Radio - Songs',
      fixed: true,
      rules: {
        minSize: { w: 5, h: 1 },
        maxSize: { w: 100, h: 100 },
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'quuSongs',
        uid: '',
        active: true,
        metadata: {
          callerId: '',
          stationId: '',
          songsNo: 0
        },
        x: 0,
        y: 0,
        w: 10,
        h: 10,
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    },
    cloudlive: {
      displayName: 'Cloud Live',
      fixed: false,
      rules: {
        minSize: { w: 51, h: 22 },
        maxSize: { w: 100, h: 100 },
        ratioSize: undefined,
        resizeEdges: { bottom: true, right: true, top: false, left: false },
        requireCmp: []
      },
      content: {
        name: 'cloudlive',
        uid: '',
        active: true,
        type: 'meeting',
        metadata: {
          id: null,
        },
        x: 30,
        y: 1,
        w: 51,
        h: 22,
        z: 1,
        t: 100,
        rx: 0,
        ry: 0,
        rz: 0
      }
    }
  };
  public static componentsNameKeys: string[] = Object.keys(LayoutComponents.componentsName);
}
