import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from "@ngx-translate/core";
import { LoaderService } from "./loader.service";
import { RequestService } from './request.service';
import { UserActivityService } from './userActivity.service';
import { LayoutUtilsService } from "./utils/layout-utils.service";
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../environments/environment';
import { Moment } from 'moment';
import * as moment from 'moment';
import { DefaultSettings } from '../components/layout-components/defaultSettings';

@Injectable()
export class RoomSessionService {
  public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public loading: boolean = false;
  public dataList: any[] = [];
  public orgType = environment.orgType;
  public camerasCountComponents: string[] = DefaultSettings.camerasCountComponents;
  public changeVolume: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  masterModeratorId: string = undefined;

  orderDir = 'asc';
  orderBy = 'name'; // uid
  orderByList = [];
  constructor(private translate: TranslateService, private layoutUtilsService: LayoutUtilsService,
    private requestService: RequestService, protected router: Router,
    protected activatedRoute: ActivatedRoute, private userActivityService: UserActivityService,
    private loaderService: LoaderService, public dialog: MatDialog, public zone: NgZone) {
  }
  canAccessRoom(roomData) {
    let selectedUser = this.requestService.currentUserSubject.getValue();
    if (!roomData || !roomData.organizationId || (roomData.organizationId && !this.requestService.checkListContains(selectedUser.organizationId, roomData.organizationId._id))) {
      this.layoutUtilsService.showNotification('You have no access to this ' + this.orgType, 'Dismiss');
      this.router.navigate(['/rooms/list'], { relativeTo: this.activatedRoute });
      return false;
    } else {
      return true;
    }
  }
  openRoom(roomData, callback: (dataResponse: any | undefined) => void, breakIt = true, preview: boolean = false) {
    let roomId = roomData._id;
    let currentRoomUserRole = this.requestService.getUserRoomRole(roomData);
    let targetUrl = '/rooms/' + roomId + '/sessions';
    if (preview) {
      targetUrl = '/rooms/' + roomId + '/preview';
    };
    if (roomData.emptyRoom || (roomData.helpdesk && (currentRoomUserRole === 'owner' || currentRoomUserRole === 'admin' || currentRoomUserRole === 'edit'))) {
      this.router.navigateByUrl('/loading', { skipLocationChange: true }).then(() =>
        this.router.navigate([targetUrl]));
    } else {
      // if (roomData.main) {
      this.getSessions(roomData, (sessionsList) => {
        if (roomData.showbg || roomData.main) {
          this.router.navigateByUrl('/loading', { skipLocationChange: true }).then(() =>
            this.router.navigate([targetUrl]));
        } else {
          this.layoutUtilsService.showNotification(this.translate.instant('You have no access to this ') + this.translate.instant('room'), this.translate.instant('Dismiss'));
          callback(sessionsList);
        }
      }, breakIt);
      // } else {
      //   this.layoutUtilsService.showNotification(this.translate.instant('You have no access to this ') + this.translate.instant('room'), this.translate.instant('Dismiss'));
      // }
    }
    callback(undefined);
  }
  getSessionsList(roomData, callback: (dataResponse: any | undefined) => void, breakIt = true) {
    let roomId = roomData._id;
    let filters = {
      "$and": [{ "room._id": { "$eq": roomId } }]
    };
    this.requestService.getDataList('session', {
      fieldKeys: ['_id', 'name', 'users', 'active', 'opentoall', 'startsAt', 'endAt', 'url', 'recordSource', 'recordAvailable', 'showbg', 'allowAttendeeByTime'], orderDir: 'asc', orderBy: 'startsAt', filter: filters
    }, (data, error) => {
      if (error) {
        this.layoutUtilsService.showNotification('No ' + this.translate.instant('session') + ' available', 'Dismiss');
      }
      if (data) {
        callback(data.results);
      } else {
        callback(undefined);
      }
    });
  }

  canAccessSessionByRole(sessionData) {
    if (sessionData.openbyroles && sessionData.openbyroles.length > 0) {
      let selectedUser = this.requestService.currentUserSubject.getValue();
      let selectedOrg = this.requestService.pageOrganization.getValue();
      let hasRolesOrgRequired = (selectedOrg.roleCount && selectedOrg.roleCount > 0);
      if (hasRolesOrgRequired && selectedUser.role && selectedUser.role.length > 0) {
        let roleUserHave = sessionData.openbyroles.filter((itm) => itm._id === selectedUser.role[0]._id);
        if (roleUserHave.length > 0) {
          return true;
        }
      }
    }
    return false;
  }
  getSessions(roomData, callback: (dataResponse: any | undefined) => void, breakIt = true) {
    let roomId = roomData._id;
    let filters = {
      "$and": [{ "room._id": { "$eq": roomId } }]
    };
    this.requestService.getDataList('session', {
      fieldKeys: ['_id', 'name', 'users', 'active', 'opentoall', 'openbyroles', 'startsAt', 'endAt', 'url', 'recordSource', 'recordAvailable', 'showbg', 'allowAttendeeByTime'], orderDir: 'asc', orderBy: 'startsAt', filter: filters
    }, (data, error) => {
      if (error) {
        this.layoutUtilsService.showNotification('No ' + this.translate.instant('session') + ' available', 'Dismiss');
      }
      if (data) {
        let sessionsList = data.results;
        if (sessionsList && sessionsList.length > 0) {
          let currentSession = undefined;
          let currentDate: Moment = moment.utc();
          if (sessionsList.length > 1) {
            let activeSessions = sessionsList.filter((itm) => itm.active);
            if (activeSessions.length > 0) {
              let comingSessions = [];
              if (activeSessions.length === 1) {
                comingSessions = activeSessions;
              } else {
                comingSessions = activeSessions.filter((lec) => {
                  let startD: Moment = moment.utc(lec.startsAt).local();
                  let endD: Moment = moment.utc(lec.endAt).local();
                  if ((currentDate.diff(startD, 'minutes') >= 0 && endD.diff(currentDate, 'minutes') >= 0) || startD.diff(currentDate, 'minutes') > 0) {
                    return true;
                  } else return false;
                });
              }
              for (let lec of comingSessions) {
                // let masterModeratorId = this.requestService.getSessionHost(lec);
                let currentSessionUserRole = this.requestService.getUserSessionRole(lec);
                // if(masterModeratorId || roomData.main){
                if (lec.opentoall || currentSessionUserRole !== 'anonymous' || this.canAccessSessionByRole(lec)) {
                  currentSession = lec;
                  break;
                }
                // }
              }
            }
            if (!currentSession) {
              let comingSessions = sessionsList.filter((lec) => {
                let startD: Moment = moment.utc(lec.startsAt).local();
                let endD: Moment = moment.utc(lec.endAt).local();
                if ((currentDate.diff(startD, 'minutes') >= 0 && endD.diff(currentDate, 'minutes') >= 0) || startD.diff(currentDate, 'minutes') > 0) {
                  return true;
                } else return false;
              });
              for (let lec of comingSessions) {
                // let masterModeratorId = this.requestService.getSessionHost(lec);
                let currentSessionUserRole = this.requestService.getUserSessionRole(lec);
                // if(masterModeratorId || roomData.main){
                if (lec.opentoall || currentSessionUserRole !== 'anonymous' || this.canAccessSessionByRole(lec)) {
                  currentSession = lec;
                  break;
                }
                // }
              }
            }
            if (!currentSession) {
              // loop over all if nothing selected
              for (let lec of sessionsList) {
                // let masterModeratorId = this.requestService.getSessionHost(lec);
                let currentSessionUserRole = this.requestService.getUserSessionRole(lec);
                // if(masterModeratorId || roomData.main){
                if (lec.opentoall || currentSessionUserRole !== 'anonymous' || this.canAccessSessionByRole(lec)) {
                  currentSession = lec;
                  break;
                }
                // }
              }
            }
            if (!currentSession) {
              currentSession = sessionsList[0];
            }
          } else {
            currentSession = sessionsList[0];
          }
          if (currentSession) {
            let currentSessionUserRole = this.requestService.getUserSessionRole(currentSession);
            // let leads = this.requestService.getSessionRoleByType(currentSession, 'lead');
            // if (!roomData.main && leads.length == 0) {
            //   if(breakIt){
            //     this.layoutUtilsService.showNotification('A ' + this.translate.instant('lead') + ' needs to be assigned', this.translate.instant('Dismiss'));
            //     callback(undefined);
            //     return;
            //   }
            // } else {
            let masterModeratorId = this.requestService.getSessionHost(currentSession);
            if (!roomData.main && !masterModeratorId) {
              if (breakIt) {
                this.layoutUtilsService.showNotification(this.translate.instant('A host needs to be assigned'), this.translate.instant('Dismiss'));
                callback(undefined);
                return;
              }
            } else {
              if (currentSession.opentoall || currentSessionUserRole !== 'anonymous' || this.canAccessSessionByRole(currentSession)) {
                let startD: Moment = moment.utc(currentSession.startsAt).local();
                let endD: Moment = moment.utc(currentSession.endAt).local();
                let isSessionAtAnyTime = this.requestService.getIsSessionAtAnyTime(currentSession);
                if (!(currentSessionUserRole === 'moderator' || currentSessionUserRole === 'guest' || currentSessionUserRole === 'lead' || currentSessionUserRole === 'interpreter') && !currentSession.showbg && !currentSession.active
                ) {
                  if (breakIt) {
                    this.layoutUtilsService.showNotification(this.translate.instant('Session') + this.translate.instant(' is not active at this time.'), this.translate.instant('Dismiss'));
                    callback(undefined);
                    return;
                  }
                } else if ((currentSessionUserRole === 'anonymous' || currentSessionUserRole === 'attendee') && currentSession.active && currentSession.allowAttendeeByTime && !isSessionAtAnyTime && (startD.diff(currentDate, 'seconds') > 0 || currentDate.diff(endD, 'seconds') > 0)) {
                  if (breakIt) {
                    if (startD.diff(currentDate, 'seconds') > 0) {
                      this.layoutUtilsService.showNotification(this.translate.instant('The session will start shortly - Please come back at the session start time'), this.translate.instant('Dismiss'));
                    } else if (currentDate.diff(endD, 'seconds') > 0) {
                      this.layoutUtilsService.showNotification(this.translate.instant('This session already ended.'), this.translate.instant('Dismiss'));
                    }
                    callback(undefined);
                    return;
                  }
                } else {
                  if (!currentSession.active && currentSession.recordAvailable && currentSession.recordSource && currentSession.recordSource.trim() !== '') {
                    // window.location.href = '/#/rooms/' + roomId + '/session/' + currentSession._id + '/record';
                    window.open(currentSession.recordSource.trim(), '_blank');
                  } else if (currentSession.url && currentSession.url.trim() !== '') {
                    window.open(currentSession.url.trim(), '_blank');
                  } else {
                    this.zone.run(() => {
                      this.router.navigate(['/rooms', roomId, 'sessions', currentSession._id], { skipLocationChange: false }).then((e) => {
                        if (e) {
                          this.userActivityService.insertLog('Window', 'reload', 'Force Reload after session navigate');
                          window.location.reload();
                        }
                      }
                      );
                    });
                    // this.router.navigateByUrl('/loading', { skipLocationChange: true }).then(
                    //   () => {
                    //     this.router.navigate(['/rooms', roomId, 'sessions', currentSession._id], { relativeTo: this.activatedRoute });
                    //     window.location.reload();
                    //   }
                    // );
                  }
                  // callback(undefined); this should not be enabled as we will get loops
                  return;
                }
              } else {
                if (breakIt) {
                  this.layoutUtilsService.showNotification('This ' + this.translate.instant('session') + ' is limited to specific attendees.', 'Dismiss');
                  callback(undefined);
                  return;
                }
              }
            }
            // }
          } else {
            if (breakIt) {
              this.router.navigate(['/rooms/' + roomId + '/sessions'], { relativeTo: this.activatedRoute });
              callback(undefined);
              return;
            }
          }
        }
        callback(sessionsList);
      } else {
        callback(undefined);
      }
    });
  }
  public openSession(roomData, session, urlParams = undefined, callback: (dataResponse: any | undefined) => void | undefined = undefined, preview = false) {
    let leads = this.requestService.getSessionRoleByType(session, 'lead');
    let currentSessionUserRole = this.requestService.getUserSessionRole(session);
    // if (!roomData.main && leads.length == 0) {
    //   this.layoutUtilsService.showNotification('A ' + this.translate.instant('lead') + ' needs to be assigned', this.translate.instant('Dismiss'));
    //   if(callback){
    //     callback(false);
    //   }
    // } else {
    let hosts = this.requestService.getSessionHost(session);
    if (!roomData.main && !hosts) {
      this.layoutUtilsService.showNotification(this.translate.instant('A host needs to be assigned'), this.translate.instant('Dismiss'));
      if (callback) {
        callback(false);
      }
    } else {
      if (session.opentoall || currentSessionUserRole !== 'anonymous') {
        let currentDate: Moment = moment.utc();
        let startD: Moment = moment.utc(session.startsAt).local();
        let endD: Moment = moment.utc(session.endAt).local();
        let isSessionAtAnyTime = this.requestService.getIsSessionAtAnyTime(session);
        if (!(currentSessionUserRole === 'moderator' || currentSessionUserRole === 'guest' || currentSessionUserRole === 'lead' || currentSessionUserRole === 'interpreter') && !session.showbg && !session.active
        ) {
          this.layoutUtilsService.showNotification(this.translate.instant('Session') + this.translate.instant(' is not active at this time.'), this.translate.instant('Dismiss'));
          if (callback) {
            callback(false);
          }
        } else if ((currentSessionUserRole === 'anonymous' || currentSessionUserRole === 'attendee') && session.active && session.allowAttendeeByTime && !isSessionAtAnyTime && (startD.diff(currentDate, 'seconds') > 0 || currentDate.diff(endD, 'seconds') > 0) && !session.showbg) {
          if (startD.diff(currentDate, 'seconds') > 0) {
            this.layoutUtilsService.showNotification(this.translate.instant('The session will start shortly - Please come back at the session start time'), this.translate.instant('Dismiss'));
          } else if (currentDate.diff(endD, 'seconds') > 0) {
            this.layoutUtilsService.showNotification(this.translate.instant('This session already ended.'), this.translate.instant('Dismiss'));
          }
          if (callback) {
            callback(false);
          }
        } else {
          if (!preview && !session.active && session.recordAvailable && session.recordSource && session.recordSource.trim() !== '') {
            // window.location.href = '/#/rooms/' + roomData._id + '/session/' + session._id + '/record';
            window.open(session.recordSource.trim(), '_blank');
          } else if (!preview && session.url && session.url.trim() !== '') {
            window.open(session.url.trim(), '_blank');
          } else {
            this.zone.run(() => {
              this.router.navigate(['/rooms', roomData._id, 'sessions', session._id], { skipLocationChange: false }).then((e) => {
                if (e) {
                  this.userActivityService.insertLog('Window', 'reload', 'Force Reload after session navigate');
                  window.location.reload();
                }
              }
              );
            });
            // this.router.navigateByUrl('/loading', { skipLocationChange: true }).then(
            //   () => {
            //     this.router.navigate(['/rooms', roomData._id, 'sessions', session._id], { queryParams: urlParams, relativeTo: this.activatedRoute });
            //     window.location.reload();
            //   }
            // );
          }
        }
      } else {
        this.layoutUtilsService.showNotification('This ' + this.translate.instant('session') + ' is limited to specific attendees.', 'Dismiss');
        if (callback) {
          callback(false);
        }
      }
    }
    // }
  }
  public openSessionRecord(currentSession) {
    if (currentSession.recordAvailable && currentSession.recordSource && currentSession.recordSource.trim() !== '') {
      let roomId = currentSession.room._id;
      let currentSessionUserRole = this.requestService.getUserSessionRole(currentSession);

      if (currentSession.opentoall || currentSessionUserRole !== 'anonymous') {
        // if((currentSessionUserRole === 'attendee' || currentSessionUserRole === 'anonymous') && !currentSession.showbg && !currentSession.active){
        //   this.layoutUtilsService.showNotification(this.translate.instant('Session') + this.translate.instant(' has not started yet. Check back soon.'), this.translate.instant('Dismiss'));
        // }else{
        //   window.location.href = '/#/rooms/' + roomId + '/session/' + currentSession._id + '/record';
        // }
        // new tab
        // window.location.href = '/#/rooms/' + roomId + '/session/' + currentSession._id + '/record';
        window.open(currentSession.recordSource.trim(), '_blank');
      } else {
        this.layoutUtilsService.showNotification('This ' + this.translate.instant('session') + ' is limited to specific attendees.', 'Dismiss');
      }
    } else {
      this.openSession(currentSession.room, currentSession);
    }
  }
  public openSessionById(sessionId, callback: (dataResponse: any | undefined) => void) {
    if (!this.loading) {
      this.loading = true;
      this.requestService.getSingleData('session', sessionId, (data, error) => {
        if (error) {
          this.layoutUtilsService.showNotification('Error: ' + error, 'Dismiss');
        }
        this.loading = false;
        if (data) {
          let currentSession = data.results;
          callback(currentSession);
        } else {
          callback(undefined);
        }
      });
    }
  }
  public getUserSessions(userId, callback: (dataResponse: any | undefined) => void) {
    if (!this.loading) {
      this.loading = true;
      let filters: any = {
        "$and": [{ 'organizationId._id': { '$eq': this.requestService.orgId } },
        { "users._id": { "$eq": userId } }]
      };
      let filterObj = { page: 1, term: '', orderDir: 'asc', orderBy: 'startsAt', fieldKeys: ['_id', 'name', 'users', 'active', 'opentoall', 'startsAt', 'endAt', 'url', 'showbg', 'allowAttendeeByTime'], filter: filters };
      this.requestService.getDataList('session',
        filterObj, (data, error) => {
          //console.log(data);
          if (error) {
            this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
          }
          this.loading = false;
          if (data) {
            let dataList = data.results || [];
            callback(dataList);
          } else {
            callback(undefined);
          }
        });
    }
  }
  getUserRoomEvent(roomData, selectedUser, callback: (dataResponse: any | undefined) => void) {
    let roomId = roomData._id;
    // let startFilter = moment.utc().subtract(1, 'months');
    let startFilter = moment.utc().subtract(1, 'hours');// we might need to remove this
    let endFilter = moment.utc().add(24, 'hours');
    let filters = {
      "$and": [
        { 'foreign_key': { '$eq': this.requestService.orgId } },
        { 'project': { '$eq': 'stellar-' + this.orgType } },
        { 'eventType': { '$ne': 'blueprint' } },
        { "room._id": { "$eq": roomId } },
        { 'startdate': { '$gte': startFilter.format('YYYY-MM-DDTHH:mm') } },
        { 'enddate': { '$lt': endFilter.format('YYYY-MM-DDTHH:mm') } }
      ]
    };
    // add filter by users dunno if i still need this
    this.requestService.getDataList('schedule/event', {
      fieldKeys: ['_id', 'name', 'active', 'opentoall', 'attendees', 'startdate', 'enddate', 'blueprint', 'description', 'enableChat', 'eventType', 'foreign_key', 'isRecurrence', 'layout', 'location', 'meetingType', 'meetingurl', 'parent_key', 'place', 'project', 'room', 'settingType'], orderDir: 'desc', orderBy: 'startdate', filter: filters
    }, (data, error) => {
      if (error) {
        this.layoutUtilsService.showNotification('No ' + this.translate.instant('Event') + ' available', 'Dismiss');
      }
      if (data) {
        let eventsList = data.results;
        if (eventsList && eventsList.length > 0) {
          let currentEvent = undefined;

          for (let eventData of eventsList) {
            if (eventData.opentoall) {
              currentEvent = eventData;
              break;
            } else {
              if (eventData.attendees && eventData.attendees.length > 0) {
                for (let att of eventData.attendees) {
                  if (att.type === 'user' && att._id === selectedUser._id) {
                    currentEvent = eventData;
                    break;
                  } else if (att.type === 'role' && att._id === selectedUser.role[0]?._id) {
                    currentEvent = eventData;
                    break;
                  }
                }
              }
            }
          }
          if (currentEvent) {
            this.zone.run(() => {
              this.router.navigate(['/rooms', roomData._id, 'event', currentEvent._id], { skipLocationChange: false }).then((e) => {
                if (e) {
                  this.userActivityService.insertLog('Window', 'reload', 'Force Reload after event navigate');
                  window.location.reload();
                }
              }
              );
            });
          } else {
            callback(false);
          }
        } else {
          callback(false);
        }
      } else {
        callback(false);
      }
    });
  }
  public getEventById(eventId, callback: (dataResponse: any | undefined) => void) {
    if (!this.loading) {
      this.loading = true;
      this.requestService.getSingleData('schedule/event', eventId, (data, error) => {
        if (error) {
          this.layoutUtilsService.showNotification('Error: ' + error, 'Dismiss');
        }
        this.loading = false;
        if (data) {
          let currentEvent = data.results;
          callback(currentEvent);
        } else {
          callback(undefined);
        }
      });
    }
  }
  calculateCameraTotal(settings): number {
    let currentSettings = JSON.parse(JSON.stringify(settings['desktop']));
    let specialNumber = 5000;
    let componentsCopy = JSON.parse(JSON.stringify(currentSettings['columns'][0]['components']));
    let cameras = componentsCopy.filter((cmp) => this.camerasCountComponents.includes(cmp.name) && cmp.active);
    let panelList = componentsCopy.filter((cmp) => cmp.name === 'panelView' && cmp.active);
    if (panelList.length > 0) {
      specialNumber = 4000;
    } else {
      if (cameras.length > 0 && cameras.length < 11) {
        specialNumber = 5000;
      } else if (cameras.length >= 11 && cameras.length < 21) {
        specialNumber = 4000;
      } else if (cameras.length >= 21 && cameras.length < 31) {
        specialNumber = 3000;
      } else if (cameras.length >= 31 && cameras.length < 36) {
        specialNumber = 2500;
      } else if (cameras.length >= 36 && cameras.length < 46) {
        specialNumber = 2000;
      } else if (cameras.length >= 46 && cameras.length < 51) {
        specialNumber = 1800;
      } else if (cameras.length >= 51 && cameras.length < 56) {
        specialNumber = 1600;
      } else if (cameras.length >= 56 && cameras.length < 61) {
        specialNumber = 1500;
      } else if (cameras.length >= 61 && cameras.length < 66) {
        specialNumber = 1400;
      } else if (cameras.length >= 66 && cameras.length < 71) {
        specialNumber = 1300;
      } else if (cameras.length >= 71 && cameras.length < 76) {
        specialNumber = 1200;
      } else if (cameras.length >= 76 && cameras.length < 91) {
        specialNumber = 1000;
      } else if (cameras.length >= 91 && cameras.length < 101) {
        specialNumber = 900;
      } else if (cameras.length >= 101 && cameras.length < 126) {
        specialNumber = 700;
      } else if (cameras.length >= 126 && cameras.length < 151) {
        specialNumber = 600;
      } else if (cameras.length >= 151 && cameras.length < 201) {
        specialNumber = 500;
      } else if (cameras.length >= 201) {
        specialNumber = 400;
      }
    }
    let maximumAttendees = specialNumber;
    return Math.floor(maximumAttendees);
  }
}
