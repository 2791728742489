import { Component, OnInit, Input, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { RequestService, LayoutUtilsService, PageScopeService } from '../../../shared/services';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-custom-tile-view',
  templateUrl: './custom-tile-view.component.html',
  styleUrls: ['./custom-tile-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomTileViewComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public esMatcher = new MyErrorStateMatcher();
  public selectedUser: any;
  public isAdmin: boolean = false;
  public isSuperAdmin: boolean = false;
  public errorMessage: string = '';
  public dataType: string = 'tile';
  public orgType = environment.orgType;
  public loading: boolean = false;
  public originalTableSetting: any = undefined;
  public metaFieldSetting: any = undefined;
  public tableDataTemp: any = undefined;
  public tileStructureData: any = undefined;
  public confirmData: any = undefined;
  public dataWidgets: string[] = ['questionnaire', 'inquiry'];
  public inqueryRedirect = undefined
  @Input() tileId: string = undefined;
  @Input() sessionId: string = undefined;
  @Input() readonly: boolean = true;
  @Output() returnAction = new EventEmitter<any>();
  @Output() returnTitle = new EventEmitter<string>();
  constructor(private sanitized: DomSanitizer, private translate: TranslateService,
    private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService) {
  }
  ngOnInit() {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.isAdmin = this.requestService.isUserRoleAdmin();
          this.isSuperAdmin = this.requestService.isUserRoleSuperAdmin();
          this.buildSetting();
        }
      })
    );
  }
  private buildSetting() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let orgId = undefined;
      let lang = undefined;
      this.requestService.getMetaData(this.dataType, undefined, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.originalTableSetting = data.results;
          this.metaFieldSetting = this.buildMetaSetting(data.results);
          if (this.tileId) {
            this.loadData();
          }
        }
      }, orgId, lang);
    }
  }
  public loadData() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getSingleData(this.dataType, this.tileId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          this.tableDataTemp = Object.assign({}, data.results);
          this.tileStructureData = Object.assign({}, data.results);
          console.log('this.tileStructureData ', this.tileStructureData);
          this.confirmData = this.getEmptyStructure(data.results);
          this.returnTitle.emit(this.tileStructureData.title);
          console.log('this.confirmData ', this.confirmData);
        }
        this.loading = false;
      });
    }
  }
  getEmptyStructure(tile) {
    let confirmData = [];
    if (tile.hasOwnProperty('blocksData')) {
      for (let tl of tile.blocksData) {
        let blockAnswer = {
          "tileId": this.tileId,
          "sessionId": this.sessionId,
          "title": tile.title,
          "tileBlockId": tl._id,
          "userId": this.selectedUser._id,
          "appId": this.requestService.appId,
          "orgId": this.requestService.orgId,
          "locationId": this.requestService.locId,
          "type": tl.type,
          "data": undefined,
          "version": tl.version,
          "blockData": tl.data,
          "category": tile.category,
        };
        if (tl.type === 'linkdescription' && tl.data.openLink) {
          this.inqueryRedirect = tl.data.link;
          this.returnAction.emit({ action: 'hideSubmit' });
        }
        if (tl.type === 'questionnaire') {
          let blockQuestionLevel1 = { question: tl.data['questionText'], answers: [], email: [], answered: undefined };
          if (tl.data.hasOwnProperty('options')) {
            let idx = 0;
            for (let opt of tl.data.options) {
              if (opt.hasOwnProperty('alert')) {
                blockQuestionLevel1['email'].push(opt.alert);
              }
              let blockAnswerLevel1 = { answer: idx, option: opt.option, confirmation: "", subAnswers: [] };
              if (opt.hasOwnProperty('subQuestions')) {
                for (let questionSub of opt.subQuestions) {
                  let blockQuestionLevel2 = { question: questionSub['questionText'], type: questionSub.type, subAnswers: [], answered: undefined };
                  if (questionSub.hasOwnProperty('options')) {
                    let idxSub = 0;
                    for (let optSub of questionSub.options) {
                      let blockAnswerLevel2 = { subAnswer: idxSub, subOption: optSub.option, confirmation: "", optionAnswers: [] };
                      if (optSub.hasOwnProperty('subQuestions')) {
                        for (let questionSubSub of optSub.subQuestions) {
                          let blockQuestionLevel3 = { question: questionSubSub['questionText'], type: questionSubSub.type, subAnswers: [], answered: undefined };
                          if (questionSubSub.hasOwnProperty('options')) {
                            let idxSubSub = 0;
                            for (let optSubSub of questionSubSub.options) {
                              let blockAnswerLevel3 = { subAnswer: idxSubSub, subOption: optSubSub.option, confirmation: "" };
                              blockQuestionLevel3['subAnswers'].push(blockAnswerLevel3);
                              idxSubSub++;
                            }
                          }
                          blockAnswerLevel2['optionAnswers'].push(blockQuestionLevel3);
                        }
                      }
                      blockQuestionLevel2['subAnswers'].push(blockAnswerLevel2);
                      idxSub++;
                    }
                  }
                  blockAnswerLevel1['subAnswers'].push(blockQuestionLevel2);
                }
              }
              blockQuestionLevel1['answers'].push(blockAnswerLevel1);
              idx++;
            }
          }

          // blockAnswer['data'] = {
          //         "question" : "How are you",
          //         "answers" : [
          //             {
          //                 "answer" : 0,
          //                 "option" : "Good",
          //                 "confirmation" : "",
          //                 "subAnswers" : [
          //                     {
          //                         "question" : "which good are you",
          //                         "type" : "questions",
          //                         "alert" : "",
          //                         "subAnswers" : [
          //                             {
          //                                 "subAnswer" : 0,
          //                                 "subOption" : "Fantastic",
          //                                 "confirmation" : "",
          //                                 "optionAnswers" : [
          //                                     {
          //                                         "question" : "Choose the level",
          //                                         "type" : "questions",
          //                                         "alert" : "",
          //                                         "subAnswers" : [
          //                                             {
          //                                                 "subAnswer" : 0,
          //                                                 "confirmation" : "",
          //                                                 "subOption" : "5"
          //                                             }
          //                                         ]
          //                                     }
          //                                 ]
          //                             }
          //                         ]
          //                     }
          //                 ]
          //             }
          //         ],
          //         "email" : [
          //             "amerghalayini@interactivelife.com",
          //             "singetak+1@gmail.com"
          //         ]
          //     };

          blockAnswer['data'] = blockQuestionLevel1;
        } else if (tl.type === 'inquiry') {
          let blockAnswerData = { 'inquiryText': "" };
          blockAnswer['data'] = blockAnswerData;
        }
        confirmData.push(blockAnswer);
      }
    }
    return confirmData;
  }
  buildMetaSetting(data, parent = undefined) {
    let dataObject = {};
    let tabObject = [];
    for (let col of data.fields) {
      if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
        if (parent) {
          col['inputName'] = parent + col['name'];
        }
        dataObject[col.name] = col;
      }
    }
    return dataObject;
  }
  setLayer1(val) {
    console.log('setLayer1', val);
  }
  submitData() {
    if (this.sessionId) {
      console.log('Session Not Available');
    }
    let confirmData: any = JSON.parse(JSON.stringify(this.confirmData));
    confirmData = confirmData.filter((itm) => this.dataWidgets.includes(itm.type));
    console.log('confirmData', confirmData);
  }
}
