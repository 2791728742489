import { Component, Inject, NgZone, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs/internal/Subscription';
import { FeaturesPermissionService } from '../../services/featuresPermission.service';

interface ViewDialogData {
	title: string;
	data: any;
}
@Component({
	selector: 'app-custom-scheduler-dialog-modal',
	templateUrl: './custom-scheduler-dialog.component.html',
	styleUrls: ['./custom-scheduler-dialog.component.scss']
})
export class ModalSchedulerDialogComponent {
	private subscriptions: Subscription[] = [];
	public errorMessage: string = '';
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public dateFormat: string = 'dd-MM-yyyy';

	constructor(protected dialogRef: MatDialogRef<ModalSchedulerDialogComponent>, protected featuresPermissionService: FeaturesPermissionService,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		// console.log('ModalSchedulerDialogComponent', data);
	}
	ngOnInit() {
		this.ngOnInitCall();
	}
	ngOnInitCall() {
		this.subscriptions.push(
			this.featuresPermissionService.currentSettingsSubject.subscribe((currentSettings) => {
				if (currentSettings) {
					if (currentSettings.web && currentSettings.web.global) {
						if (currentSettings.web.global.hasOwnProperty('dateFormat')) {
							this.dateFormat = currentSettings.web.global.dateFormat;
						}
					}
				}
			})
		);
	}
	closeModal(data): void {
		this.dialogRef.close(data);
	}
}
