<div fxLayout="row" [id]="uuid" class="now-playing">
    <div fxFlex="80px" fxGrow="1" fxLayoutAlign="center start" class="image">
        <img [src]="songDetails.image" *ngIf="songDetails?.image && songDetails?.type !== 'com'" />
        <a href="songDetails?.website" target="_blank" *ngIf="songDetails?.image && songDetails?.type === 'com'">
            <img [src]="songDetails.image" />
        </a>
    </div>
    <div fxFlex class="song-details">
        <div *ngIf="songDetails?.title" class="title">{{songDetails.title}}</div>
        <div *ngIf="songDetails?.subTitle" class="artist">{{songDetails.subTitle}}</div>
        <div class="loader" *ngIf="!isAudioLoaded">
            <div class="dot-pulse"></div>
        </div>
        <mat-icon (click)="togglePlay()" *ngIf="isAudioLoaded && !isAudioPlaying">play_circle_filled</mat-icon>
        <mat-icon (click)="togglePlay()" *ngIf="isAudioLoaded && isAudioPlaying">pause_circle_filled</mat-icon>
    </div>
    <app-audio [hidden]="true" [isAutoPlay]="false" [isOnLoop]="false" [id]="uuid" [src]="streamLink"
        (isAudioPlaying)="isAudioPlaying" (isAudioLoaded)="isLoaded($event)" *ngIf="streamLink">
    </app-audio>
</div>