import { environment } from '../environments/environment';
export class MenuConfig {
  public defaults: any = {
    aside: {
      self: {},
      items: [
        // {
        //   title: "Dashboard",
        //   root: true,
        //   icon: "dashboard",
        //   page: "/dashboard",
        //   bullet: "dot"
        // },
        {
          title: "My Rooms",
          root: true,
          icon: "room_preferences",
          page: "/rooms/directory",
          bullet: "dot"
        },
        {
          title: "My Library",
          root: true,
          icon: "widgets",
          page: "/tiles",
          bullet: "dot",
          hideInMobile: true,
          permission: [environment.customKeys.roleAdmin, environment.customKeys.roleEdit],
          groupParent: {
            isSuperAdmin: true
          }
        },
        // {
        //   title: "StellarConnect",
        //   root: true,
        //   icon: "recent_actors",
        //   page: "/book",
        //   bullet: "dot",
        //   permission: [environment.customKeys.roleAdmin, environment.customKeys.roleEdit],
        // },
        {
          title: "Events Calendar",
          root: true,
          icon: "calendar_month",
          page: "/events-calendar",
          bullet: "dot",
          // fieldDisableBoolean: 'disableScheduler',
          fieldEnableBoolean: 'enableScheduler',
          permission: [environment.customKeys.roleAdmin, environment.customKeys.roleEdit]
        },
        {
          title: 'Broadcast',
          root: true,
          icon: "campaign",
          page: '/broadcast',
          bullet: "dot",
          permission: [environment.customKeys.roleAdmin, environment.customKeys.roleEdit]
        },
        {
          title: 'Admin',
          root: true,
          bullet: 'dot',
          icon: 'add',
          target: 'admin',
          hideInMobile: true,
          permission: [environment.customKeys.roleAdmin],
          submenu: [
            {
              title: 'Organizations',
              page: '/admin/organizations',
              showInGroupParent: true,
              groupParent: {
                isGroupParent: true
              }
            },
            {
              title: 'Users',
              page: '/admin/users',
            },
            {
              title: 'Rooms',
              page: '/admin/rooms',
              groupParent: {
                isSuperAdmin: true
              }
            },
            // {
            //   title: 'Template Layouts',
            //   page: '/admin/layouts'
            // },
            {
              title: 'Design Hub',
              page: '/rooms/settings',
              groupParent: {
                isSuperAdmin: true
              }
            },
            {
              title: 'Billboards',
              page: '/admin/billboards',
              groupParent: {
                isSuperAdmin: true
              }
            },
            // {
            //   title: 'Map Area',
            //   page: '/admin/maparea'
            // },
            {
              title: 'Resources',
              page: '/admin/resources',
              groupParent: {
                isSuperAdmin: true
              }
            },
            {
              title: 'Posters',
              page: '/admin/posters',
              groupParent: {
                isSuperAdmin: true
              }
            },
            {
              title: 'Media Library',
              page: '/admin/medialibrary',
              groupParent: {
                isSuperAdmin: true
              }
            },
            {
              title: 'Image Gallery',
              page: '/admin/imagegalleries',
              groupParent: {
                isSuperAdmin: true
              }
            },
            {
              title: 'Insights',
              page: '/admin/insights'
            },
            {
              title: 'Configurations',
              page: '/admin/configurations',
              groupParent: {
                isSuperAdmin: true
              }
            },
          ]
        }
      ]
    },
    rside: {
      self: {},
      items: [
      ]
    }
  };

  public get configs(): any {
    return this.defaults;
  }
}
