import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface ViewDialogData {
	title: string;
	data: any;
	hasAdd: boolean;
	addNote: string;
	hideClear: boolean;
	confirmData: any;
}

@Component({
	selector: 'app-tile-select-dialog-modal',
	templateUrl: './tile-select-dialog.component.html',
	styleUrls: ['./tile-select-dialog.component.scss']
})
export class TileSelectDialogComponent implements OnInit {
	public addNote: string = undefined;
	public returnData: any = { categoryId: '', _id: '', title: '' };
	constructor(public dialogRef: MatDialogRef<TileSelectDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		//console.log('ViewDialogData', data);
		if (data.data) {
			this.returnData = data.data;
		}
		if (data.hasOwnProperty('addNote') && data['addNote']) {
			this.addNote = data['addNote'];
		}
	}

	ngOnInit() {
	}
	selectItemCheck(data) {
		let returnData = { categoryId: data.categoryId, _id: data._id, title: data.title };
		this.dialogRef.close(returnData);
	}
	closeModal(data): void {
		this.dialogRef.close(data);
	}
}
