import { Component, Input, EventEmitter, Output, ViewChild, ChangeDetectorRef, ElementRef } from '@angular/core';
import { RequestService, LayoutUtilsService, PageScopeService, StoreService, RoomSessionService, LoaderService } from '../../../../services';
import { ModalUserViewDialogComponent } from 'src/app/shared/components/custom-user-view-dialog/custom-user-view-dialog.component';
import { TranslateService } from "@ngx-translate/core";
import { environment } from "src/environments/environment";
import { MatDialog } from '@angular/material/dialog';
import { ICalendar, CalendarOptions, GoogleCalendar, YahooCalendar, OutlookCalendar } from 'datebook';
import { Moment } from 'moment';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';


@Component({
  selector: 'agenda-dialog-speakers-table',
  templateUrl: './agenda-speakers-table.component.html',
  styleUrls: ['./agenda-speakers-table.component.scss']
})
export class AgendaDialogSpeakersTableComponent {
  public subscriptions: any[] = <any>[];
  public isMobile: boolean = false;
  @Input() loading: boolean = false;
  // @Input() isLive: boolean = false;
  @Input() displayedColumns: string[] = ['name'];

  @Input() fieldsDetails: any = undefined;
  @Input() speakerFilter: any[] = [];
  @Input() dataSource: any[] = [];

  @Output() toggleAction = new EventEmitter<any>();
  constructor(private loaderService: LoaderService, private changeDetectorRefs: ChangeDetectorRef, public dialog: MatDialog, private roomSessionService: RoomSessionService, private translate: TranslateService, public deviceService: DeviceDetectorService) {
    if (this.deviceService.isMobile()) {
      this.isMobile = true;
    }
  }
  ngOnInit() {
    // console.log(this.dataSource);
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
  cancelThru(e) {
    if (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
      e.stopPropagation();
    }
  }
  toggleActionCall(action) {
    this.toggleAction.emit(action);
  }
}
