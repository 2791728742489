import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../../shared/services';
import { urlSafeBase64Encoding } from '../../../../shared/helpers';
import { TranslateService } from '@ngx-translate/core';

interface ViewDialogData {
	title: string;
	confirmData: any;
	data:any;
}

@Component({
	selector: 'app-picture-wall-dialog-modal',
	templateUrl: './picture-wall-dialog.component.html',
	styleUrls: ['./picture-wall-dialog.component.scss']
})
export class PictureWallDialogComponent implements OnInit {
	public errorMessage: string = '';
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public returnData: any = {timer: '', moderated: true, gridSizeX: 3, gridSizeY: 3, bgcolor: '#ffffff', opacity: 0};
	constructor(
		private requestService: RequestService, private translate: TranslateService,
		private layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<PictureWallDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
	  // console.log('ViewDialogData', data);
	}

	ngOnInit() {
		if(this.data.data){
			if(this.data.data['bgcolor']){
				this.data.data['bgcolor'] = this.rgba2hex(this.data.data['bgcolor']);
			}
			this.returnData = this.data.data;
		}
	}
	closeModal(data): void {
		if(data){
			data['bgcolor'] = this.convertHexToRGBA(data['bgcolor'], 100 * parseFloat(data['opacity']));
		}
		this.dialogRef.close(data);
	}
	/**
  * editAttribute function
  */
  editAttribute($event, id, value) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.returnData[id] = value;
  }
	/**
  * editNumericAttribute function
  */
  editNumericAttribute($event, id, value, min, max) {
    if($event){
      $event.preventDefault();
      $event.stopPropagation();
    }
    let newVal = Number(value);
    if(min !== undefined && min > newVal){
      newVal = min;
    }
    if(max !== undefined && max < newVal){
      newVal = max;
    }
    this.returnData[id] = newVal;
  }
	/**
	 * ECMA2016 / ES6
	 */
	public convertHexToRGBA(hexCode, opacity){
	    let hex = hexCode.replace('#', '');

	    if (hex.length === 3) {
	        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`;
	    }

	    const r = parseInt(hex.substring(0, 2), 16);
	    const g = parseInt(hex.substring(2, 4), 16);
	    const b = parseInt(hex.substring(4, 6), 16);

	    return `rgba(${r},${g},${b},${opacity / 100})`;
	}
	public rgba2hex(orig) {
	  var a, isPercent,
	    rgb = orig.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
	    alpha = (rgb && rgb[4] || "").trim(),
	    hex = rgb ?
	    (rgb[1] | 1 << 8).toString(16).slice(1) +
	    (rgb[2] | 1 << 8).toString(16).slice(1) +
	    (rgb[3] | 1 << 8).toString(16).slice(1) : orig;

	  // if (alpha !== "") {
	  //   a = alpha;
	  // } else {
	  //   a = '01';
	  // }
	  // // multiply before convert to HEX
	  // a = ((a * 255) | 1 << 8).toString(16).slice(1)
	  // hex = hex + a;

	  return '#' + hex;
	}
}
