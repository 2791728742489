import { Component, Inject, OnInit, ViewChild, ElementRef, Input, ChangeDetectorRef, EventEmitter, Output } from '@angular/core';
import { fromEvent, BehaviorSubject, merge, Subscription, interval } from 'rxjs';
import { RequestService, LayoutUtilsService, LoaderService, TokBoxService } from '../../../shared/services';
import { DomSanitizer, SafeResourceUrl, SafeUrl } from '@angular/platform-browser';
import { urlSafeBase64Encoding } from '../../../shared/helpers';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { SimpleAlertEntityDialogComponent } from '../modals/simple-entity-dialog/simple-entity-dialog.component';

@Component({
	selector: 'app-tile-answers-percentage',
	templateUrl: './tile-answers-percentage.component.html',
	styleUrls: ['./tile-answers-percentage.component.scss'],
	// animations: [
	// 	trigger('showDialog', [
	// 		state('in', style({
	// 			opacity: 0,
	// 			top: '0%'
	// 		})),
	// 		state('out', style({
	// 			opacity: 0.98,
	// 			top: '7.5%'
	// 		})),
	// 		// transition('in => out', animate('500ms ease-in-out')),
	// 		transition('in => out', animate('1500ms ease-in-out'))
	// 	])
	// ]
})
export class TileAnswersPercentageComponent implements OnInit {
	// @ViewChild('answersDraggable') answersDraggable: ElementRef;

	private subscriptions: Subscription[] = [];
	public selectedUser: any;
	public errorMessage: string = '';
	public loading: boolean = false;
	private repeatIntervalSeconds: number = environment.repeatValidateSeconds;
	public selectedOrganization: string = "-1";
	public dataList: any[] = [];
	// public dataListView: any = {};
	// public dataListViewKeys: any[] = [];
	@Input() lectureId: string = undefined;
	public _selectedData: any = undefined;
	// animationState: string = 'in';

	// @Input() hideToggle: boolean = undefined;
	// @Input() expanded: boolean = false;
	// @Input() disableDrag: boolean = false;
	// @Input()
	// set hide(hide: boolean) {
	// 	this._hide = hide;
	// 	if (!hide) {
	// 		this.animationState = 'out';
	// 		this.maximize();
	// 	}
	// 	else
	// 		this.animationState = 'in';
	// }
	@Input()
	set selectedData(selectedData: any) {
		this._selectedData = selectedData;
		if (this.lectureId && selectedData && selectedData.hasOwnProperty('_id')) {
			this.loadData();
		} else {
			this.dataList = [];
		}
	}
	get selectedData(): any {
		return this._selectedData;
	}
	_leftPosition: any;
	_zIndex: number = 0;
	_zIndexTemp: number = 0;
	_hide: boolean = false;

	@Output() hideDialog = new EventEmitter<boolean>(undefined);

	constructor(public dialog: MatDialog,
		private requestService: RequestService, private translate: TranslateService, private sanitizer: DomSanitizer,
		private layoutUtilsService: LayoutUtilsService, private loaderService: LoaderService, private ref: ChangeDetectorRef, private opentokService: TokBoxService) {
	}

	ngOnInit() {
		this.subscriptions.push(
			this.requestService.currentUserSubject.subscribe((data) => {
				if (data) {
					this.selectedUser = data;
				}
			})
		);
		this.subscriptions.push(interval(1000 * this.repeatIntervalSeconds).subscribe((x) => {
			// console.log('TileAnswersComponent', this.lectureId, this.selectedData);
			if (this.lectureId && this.selectedData && this.selectedData.hasOwnProperty('_id')) {
				this.loadData();
			} else {
				this.dataList = [];
				// this.dataListView = {};
				// this.dataListViewKeys = [];
			}
		}));

		// this.subscriptions.push(this.opentokService.zIndexDraggable.subscribe(value => {
		// 	if (value != undefined) {
		// 		this._zIndexTemp = value;
		// 	}
		// }));
	}
	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}
	loadData() {
		if (!this.loading && this.lectureId && this.selectedData && this.selectedData.hasOwnProperty('_id')) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.getAnswersTilePercentage(this.lectureId, this.selectedData._id, (data, error) => {
				if (error) {
					this.errorMessage = error;
				}
				if (data) {
					this.dataList = data.results;
					// this.loadDataView(data.results);
				} else {
					this.dataList = [];
					// this.dataListView = {};
					// this.dataListViewKeys = [];
				}
				this.loading = false;
				this.ref.detectChanges();
			});
		}
	}
	selectRandomPick(q, a) {
		let dataObject = {
			tileBlockId: q.tileBlockId,
			question: q.question,
			answer: a.value
		}
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.getAnswersRadonPick(this.lectureId, this.selectedData._id, dataObject, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
				}
				if (data) {
					if (data.results) {
						this.showPopup(data.results);
					}
				}
				this.loading = false;
				this.ref.detectChanges();
			});
		}
	}
	showPopup(user) {
		console.log(user);
		let description = user.firstName + ' ' + user.lastName;
		if (user.gender) {
			description = description + ' (' + user.gender.substr(0, 1).toUpperCase() + user.gender.substr(1) + ')';
		}
		let description2 = 'Email: ' + user.email;
		const dialogRef = this.dialog.open(SimpleAlertEntityDialogComponent, {
			width: '600px',
			disableClose: true,
			data: {
				title: 'Congratulations',
				description: description,
				description2: description2,
			}
		});
		// dialogRef.afterClosed().subscribe(result => {
		//   // console.log('afterClosed', result);
		// });
	}
	// loadDataView(dataList) {
	// 	let dataListView = {};
	// 	for(let itm of dataList){
	// 		if(!dataListView.hasOwnProperty(itm['question'])){
	// 			dataListView[itm['question']] = [];
	// 		}
	// 		dataListView[itm['question']].push(itm);
	// 	}
	// 	this.dataListView = dataListView;
	// 	this.dataListViewKeys = Object.keys(this.dataListView);
	// }

	// dragged(bool: boolean) {
	// 	if (bool) {
	// 		this._zIndex = this._zIndexTemp + 1;
	// 		this.opentokService.zIndexDraggable.next(this._zIndex);
	// 	}
	// 	// this.opentokService.dragged(bool, this.parentDiv, this.renderer);
	// }

	// maximize() {
	// 	this.dragged(true);
	// 	this.detectChanges();
	// 	this._leftPosition = (document.body.offsetWidth / 2) - (this.answersDraggable.nativeElement.offsetWidth / 2);
	// }

	// closeDialog() {
	// 	this.animationState = 'in';
	// 	this.hideDialog.emit(true);
	// }

	detectChanges() {
		if (!this.ref['destroyed']) {
			this.ref.detectChanges();
		}
	}
}
