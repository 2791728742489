import { Component, OnInit, ViewChild, ElementRef, Input, OnDestroy, Output, EventEmitter, ChangeDetectorRef, Renderer2, AfterViewInit } from "@angular/core";
import { LectureTilesListComponent } from "../lecture-tiles-list/lecture-tiles-list.component";
import { TokBoxService, RequestService, LayoutUtilsService } from "../../services";
import { Subscription } from "rxjs";
import { MatTabGroup } from "@angular/material/tabs";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { TranslateService } from "@ngx-translate/core";
import { AblyService } from "../../services/ably.service";
import { SchedulerService } from "../../services/scheduler.service";
import { CdkDrag } from "@angular/cdk/drag-drop";

@Component({
    selector: 'app-tile-questions-answers',
    templateUrl: './tile-questions-answers.component.html',
    styleUrls: ['./tile-questions-answers.component.scss'],
    animations: [
        trigger('showDialog', [
            state('in', style({
                opacity: 0,
                top: '0%'
            })),
            state('out', style({
                opacity: 0.98,
                top: '7.5%'
            })),
            // transition('in => out', animate('500ms ease-in-out')),
            transition('in => out', animate('1500ms ease-in-out'))
        ])
    ]
})
export class TileQuestionsAnswersComponent implements OnInit, OnDestroy, AfterViewInit {

    @ViewChild('lectureTilesList') lectureTilesList: LectureTilesListComponent;
    @ViewChild('answersDraggable') answersDraggable: ElementRef;
    @ViewChild('tabGroup') tabGroup: MatTabGroup;
    @ViewChild(CdkDrag) cdkDrag: CdkDrag;

    @Input() session: OT.Session;
    @Input() sessionData: any = undefined;
    @Input() hideToggle: boolean = undefined;
    @Input() expanded: boolean = false;
    @Input() disableDrag: boolean = false;
    @Input() hasCategories: boolean = false;
    @Input() pageSize: number = 10;
    @Input() hideTileList: boolean = false;
    @Input() advancedView: boolean = false;
    selectedUser: string = undefined;
    @Input()
    set selectedData(selectedData: any) {
        if (selectedData) {
            this._selectedData = selectedData;
            this.tabGroup.selectedIndex = 0;
            this.iframeLink = this.requestService.getTileLink(this.sessionData._id, selectedData._id, true);
        }
    }
    get selectedData(): any {
        return this._selectedData;
    }
    @Input()
    set hide(hide: boolean) {
        this._hide = hide;
        if (!hide) {
            this.animationState = 'out';
            this.maximize();
        }
        else {
            this.animationState = 'in';
            if (this.cdkDrag)
                this.cdkDrag._dragRef.reset();
            this.hideTileList = false;
        }
    }

    @Output() hideDialog = new EventEmitter<boolean>(undefined);

    private subscriptions: Subscription[] = [];
    _leftPosition: any;
    _zIndex: number = 0;
    _zIndexTemp: number = 0;
    _hide: boolean = false;
    iframeLink: any = undefined;
    _selectedData: any = undefined;
    animationState: string = 'in';
    leads: any = [];
    guests: any = [];
    interpreters: any = [];

    constructor(private opentokService: TokBoxService, private ref: ChangeDetectorRef, private requestService: RequestService, private renderer: Renderer2, private layoutUtilsService: LayoutUtilsService, private translate: TranslateService, private ablyService: AblyService, private schedulerService: SchedulerService) { }

    ngOnInit() {
        this.subscriptions.push(this.opentokService.zIndexDraggable.subscribe(value => {
            if (value != undefined) {
                this._zIndexTemp = value;
            }
        }));
        this.subscriptions.push(this.schedulerService.pushTile.subscribe(value => {
            if (value != undefined) {
                if (value.start) {
                    this.pushedTileItem(value.task.metadata.id, value.index, value.task);
                }
                else {
                    this.unPushItem(value.task.metadata.id);
                }
            }
        }));
        this.subscriptions.push(this.opentokService.userJoinedSession.subscribe((data) => {
            if (data) {
                if (data.joined) {
                    if (data.user.stellarRole === 'lead' && !this.leads.find(i => i.user.userId === data.user.userId)) {
                        this.leads.push(data);
                    }
                    else if (data.user.stellarRole === 'guest' && !this.guests.find(i => i.user.userId === data.user.userId)) {
                        this.guests.push(data);
                    }
                    else if (data.user.stellarRole === 'interpreter' && !this.interpreters.find(i => i.user.userId === data.user.userId)) {
                        this.interpreters.push(data);
                    }
                }
                else {
                    if (data.user.stellarRole === 'lead') {
                        this.leads = this.leads.filter(i => i.user.userId !== data.user.userId);
                    }
                    else if (data.user.stellarRole === 'guest') {
                        this.guests = this.guests.filter(i => i.user.userId !== data.user.userId);
                    }
                    else if (data.user.stellarRole === 'interpreter') {
                        this.interpreters = this.interpreters.filter(i => i.user.userId !== data.user.userId);
                    }
                }
            }
        }));
        this.subscriptions.push(
            this.requestService.currentUserSubject.subscribe((data) => {
                if (data) {
                    this.selectedUser = data;
                }
            })
        );
    }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    dragged(bool: boolean) {
        if (bool) {
            this._zIndex = this._zIndexTemp + 1;
            this.opentokService.zIndexDraggable.next(this._zIndex);
        }
        // this.opentokService.dragged(bool, this.parentDiv, this.renderer);
    }

    maximize() {
        this.dragged(true);
        this.detectChanges();
        this._leftPosition = (document.body.offsetWidth / 2) - (this.answersDraggable.nativeElement.offsetWidth / 2);
        this.tabGroup.realignInkBar();
    }

    closeDialog() {
        this.animationState = 'in';
        if (this.cdkDrag)
            this.cdkDrag._dragRef.reset();
        this.hideDialog.emit(true);
    }

    detectChanges() {
        if (!this.ref['destroyed']) {
            this.ref.detectChanges();
        }
    }

    unPushItem(id: string) {
        this.iframeLink = undefined;
        if (this.sessionData.streamMode === 'hls' || this.sessionData.streamMode === 'cloudlive') {
            let message = { senderId: this.selectedUser['_id'], tileId: id };
            this.ablyService.sendSessionSignal(this.sessionData._id, 'unPushTile', message);
        }
        else
            this.opentokService.sendSignal('unPushTile', id, this.session);
    }

    selectItemCheck(data) {
        if (!this.selectedData || (this.selectedData && this.selectedData._id !== data._id)) {
            this.selectedData = data;
            this.iframeLink = this.requestService.getTileLink(this.sessionData._id, this.selectedData._id, true);
            // this.tabGroup.selectedIndex = 1;
        }
    }

    pushedTileItem(data, schedulerIndex?: number, schedulerTask?: any) {
        this.leads = this.leads.filter(i => i.user.userId !== this.selectedUser['_id']);
        this.guests = this.guests.filter(i => i.user.userId !== this.selectedUser['_id']);
        this.interpreters = this.interpreters.filter(i => i.user.userId !== this.selectedUser['_id']);
        let dialog = this.opentokService.pushedTileItem(data, this.leads, this.guests, this.interpreters);
        if (dialog) {
            dialog.afterClosed().subscribe(res => {
                if (res) {
                    if (res.action === 'declineText') {
                        this.lectureTilesList.activateTile(data, () => {
                            this.layoutUtilsService.showNotification(this.translate.instant('Pushed Successfully'), this.translate.instant('Dismiss'));
                            if (this.sessionData.streamMode === 'hls' || this.sessionData.streamMode === 'cloudlive') {
                                let message = { senderId: this.selectedUser['_id'], tileId: data };
                                this.ablyService.sendSessionSignal(this.sessionData._id, 'activatedTile', message);
                            }
                            else
                                this.opentokService.sendSignal('activatedTile', data, this.session);
                        });
                    } else if (res.action === 'confirmText') {
                        this.lectureTilesList.activateTile(data, () => {
                            this.layoutUtilsService.showNotification(this.translate.instant('Pushed Successfully'), this.translate.instant('Dismiss'));
                            if (this.sessionData.streamMode === 'hls' || this.sessionData.streamMode === 'cloudlive') {
                                let message = { senderId: this.selectedUser['_id'], tileId: data };
                                this.ablyService.sendSessionSignal(this.sessionData._id, 'activatedTileForAttendees', message);
                            }
                            else
                                this.opentokService.sendSignal('activatedTileForAttendees', data, this.session);
                        });
                    }
                }
                else {
                    if (schedulerTask && schedulerIndex != undefined) {
                        this.schedulerService.findAndUpdateTasks.next({ name: 'push-tile', action: '', uniqueId: schedulerTask.uniqueId });
                        // this.schedulerService.callBackScheduler.next({ task: schedulerTask, index: schedulerIndex, sendSignal: false });
                    }
                }
            });
        };
    }

    ngAfterViewInit(): void {
        this.opentokService.tileQuestionsAnswersLoaded.next(true);
    }
}