<div class="mainPanelsBody" #mainBoundary>
  <div class="container-panel-body">
    <!-- <div style="right: 0px;position: absolute;">{{mainBoundary.offsetWidth}} / {{mainBoundary.offsetHeight}}</div> -->
    <div style="display:flex; flex-wrap: wrap;" fxGrow="0" fxLayoutAlign="center" [id]="'panel-' + uuid">
      <div [class.panel-flex]="editor" *ngFor="let row of camerasList"
        [ngStyle]="{'height': getRowHeightItem(mainBoundary.offsetWidth, mainBoundary.offsetHeight), 'width': getRowWidthItem(mainBoundary.offsetWidth, mainBoundary.offsetHeight) , 'margin-bottom': marginButtom+'px', 'margin-right': marginButtom+'px'}">
        <app-camera-play *ngIf="!editor" (permitCamera)="controlAudioVideo($event, 'camera')" [session]="session"
          (permitAudio)="controlAudioVideo($event, 'audio')" [disableIcons]="!isProducer || !row.joined"
          [iconSize]="'1vw'" [labelSize]="'0.35vw'" [showAudioVideoLabels]="false" [prefixId]="'camera-panel-'"
          [id]="row.userId" [firstTime]="false" [name]="(row.joined || isProducer) ? row.name : undefined"
          [uuid]="row.uuid" [isSpeaking]="row.isSpeaking" [showAudioStatus]="showAudioStatus">
        </app-camera-play>
      </div>
    </div>
  </div>
</div>