import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../../shared/services';
import { LayoutComponents, DefaultSettings, CommonFunctions } from '../';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { TileSelectDialogComponent } from '../../../../shared/components/tile-select-dialog/tile-select-dialog.component';
import { ModalTileCreateDialogComponent } from '../../../../shared/components/tile-create-dialog/tile-create-dialog.component';
import { BillboardSelectDialogComponent } from '../../../../shared/components/billboard-select-dialog/billboard-select-dialog.component';
// import { ConfirmSelectEntityDialogComponent } from '../../../../shared/components/modals';
import { ModalAdvancedGalleryDialogComponent } from '../../../../shared/components/custom-advanced-gallery-dialog/custom-advanced-gallery-dialog.component';
import { GuestEditDialogComponent } from '../guest-edit-dialog/guest-edit-dialog.component';
import { AudioEditDialogComponent } from '../audio-edit-dialog/audio-edit-dialog.component';
import { VideoPlaylistDialogComponent } from '../video-playlist-dialog/video-playlist-dialog.component';
import { ImageGalleryDialogComponent } from '../image-gallery-dialog/image-gallery-dialog.component';
import { ModalDialogComponent } from '../../../../shared/components/custom-dialog/custom-dialog.component';
import { PictureDialogComponent } from '../picture-dialog/picture-dialog.component';
import { PartyStreamDialogComponent } from '../party-stream-dialog/party-stream-dialog.component';
import { roundDecimal, guid, calculateRatio } from '../../../../shared/helpers';
import { ModalGalleryDialogComponent } from '../../../../shared/components/custom-gallery-dialog/custom-gallery-dialog.component';
import { PictureWallDialogComponent } from '../picture-wall-dialog/picture-wall-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { IframeComponent } from '../iframe/iframe.component';
import { QuuRadioDialogComponent } from '../quu-radio-dialog/quu-radio-dialog.component';
import { I } from '@angular/cdk/keycodes';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
declare var CKEDITOR;

@Component({
  selector: 'app-screen-layout',
  templateUrl: './screen-layout.component.html',
  styleUrls: ['./screen-layout.component.scss']
})
export class ScreenLayoutComponent implements OnInit {
  public subscriptions: any[] = <any>[];
  public defaultSetting = DefaultSettings.defaultSetting;
  public errorMessage: string = '';
  public loading: boolean = false;
  public enableEditor: boolean = false;
  public enableGrid: boolean = false;
  public enableAudienceGrid: boolean = false;
  public enablePanelGrid: boolean = false;
  public dragPolyEntered: boolean = false;
  public weareresizing: boolean = false;
  public cameraPanelSettings: any = { totalCameras: 3, camerasPerRow: 2 };

  public componentsType: any = DefaultSettings.componentsType;
  public cameraComponents: string[] = DefaultSettings.cameraComponents;
  public tileComponents: string[] = DefaultSettings.tileComponents;
  public componentsName: any = LayoutComponents.componentsName;
  public componentsNameKeys: any = LayoutComponents.componentsNameKeys;
  public videoComponentTypes: any[] = DefaultSettings.videoComponentTypes;
  public imageLoading: any = {};

  public resizingObjectX: any = {};
  public resizingObjectY: any = {};

  public camerasList = [
    { id: 'camera-1', name: 'Camera 1' },
    { id: 'camera-2', name: 'Camera 2' },
    { id: 'camera-3', name: 'Camera 3' }
  ];
  public selectedColumn = undefined;
  public selectedComponent = undefined;
  public liveXY: any = { name: '', x: 0, y: 0, c: 0, m: 0, w: 0, h: 0, z: 1, bgcolor: '', t: 100, rx: 0, ry: 0, rz: 0 };
  private selectedUser: any = undefined;
  public _settingObject: any = undefined;

  editorConfig: any = {
    toolbar: [
      { name: 'document', items: ['Source', '-', 'Undo', 'Redo', '-', 'Bold', 'Italic', 'Underline', 'Strike', '-', 'Link', 'Unlink', 'TextColor', 'BGColor', '-', 'Styles', 'Format', 'Font', 'FontSize', '-', 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl'] }
    ],
    height: '33vh',
    shiftEnterMode: CKEDITOR.ENTER_DIV,
    enterMode: CKEDITOR.ENTER_DIV,
    removePlugins: 'magicline',
    linkShowAdvancedTab: false,
    linkShowTargetTab: false,
    resize_enabled: false,
    versionCheck: false
  };


  @Input()
  set settingObject(settingObject: any) {
    if (settingObject) {
      if (this._settingObject) {
        this.updateData(settingObject);
        // this._settingObject = {...this._settingObject, ...settingObject};
      } else {
        this._settingObject = settingObject;
      }
    } else {
      this._settingObject = settingObject;
    }
  }
  get settingObject(): any {
    return this._settingObject;
  }

  @Input() bgColor: string = 'white';
  @Input() pictureLink: string = '';
  @Input() type: string = '';
  @Input() viewPortMode: string = 'desktop';
  @Input() data: any = undefined;
  @Input() hasTwoPipCamera: boolean = false;
  @Input() guestsTotal: number = 0;
  @Output() setSelectComponent = new EventEmitter<any>();
  @Output() setUpdateLayout = new EventEmitter<any>();
  constructor(private layoutUtilsService: LayoutUtilsService, private requestService: RequestService, private translate: TranslateService, public renderer: Renderer2,
    public dialog: MatDialog, private router: Router, private activatedRoute: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef, private loaderService: LoaderService, private deviceService: DeviceDetectorService) { }

  ngOnInit() {
    this.subscriptions.push(this.requestService.currentUserSubject.subscribe((data) => {
      if (data) {
        this.selectedUser = data;
      }
    }));
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  // dragDropped($event, target) {
  //   let position = $event.source.getFreeDragPosition();
  //   console.log('dragDropped $event', $event);
  //   console.log('dragDroppedposition', position);
  // }
  hasComponent(type) {
    for (let cmp of this.settingObject['columns'][0]['components']) {
      if (cmp.name === type) {
        return true
      }
    }
    return false;
  }
  updateData(settingObject) {
    // console.log('settingObject', settingObject);
    let idx = 0;
    for (let cmp of settingObject.columns[0]['components']) {
      if (this._settingObject.columns[0]['components'][idx]) {
        let settingObjectKeys = Object.keys(cmp);
        for (let key of settingObjectKeys) {
          if (this._settingObject.columns[0]['components'][idx][key] !== cmp[key]) {
            this._settingObject.columns[0]['components'][idx][key] = cmp[key];
          }
        }
      } else {
        this._settingObject.columns[0]['components'].push(cmp);
      }
      idx++;
    }
    if (settingObject.hasOwnProperty('optionSettings')) {
      this._settingObject.optionSettings = settingObject.optionSettings;
    }
  }
  dragMoved($event, mainBoundary, target) {
    // console.log('dragMoved. ',$event);
    let position = $event.source.getFreeDragPosition();
    this.liveXY['x'] = roundDecimal((position.x * 100) / mainBoundary.offsetWidth, 2);
    this.liveXY['y'] = roundDecimal((position.y * 100) / mainBoundary.offsetWidth, 2);
    this.liveXY['c'] = roundDecimal(((position.x * 100) / mainBoundary.offsetWidth) + (target.w / 2), 2);
    this.liveXY['m'] = roundDecimal(((position.y * 100) / mainBoundary.offsetWidth) + (target.h / 2), 2);
    this.setSelectComponent.emit({ selectedColumn: this.selectedColumn, selectedComponent: this.selectedComponent, liveXY: this.liveXY });
  }
  applyResizing($event, mainBoundary, target) {
    this.weareresizing = true;
    this.liveXY['w'] = roundDecimal(($event.rectangle.width * 100) / mainBoundary.offsetWidth, 2);
    this.liveXY['h'] = roundDecimal(($event.rectangle.height * 100) / mainBoundary.offsetWidth, 2);
    this.setSelectComponent.emit({ selectedColumn: this.selectedColumn, selectedComponent: this.selectedComponent, liveXY: this.liveXY });
  }
  dragStarted($event, index, subIndex) {

    this.dragPolyEntered = true;
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject['columns'][index]['components'][subIndex]));
    this.selectedColumn = index;
    this.selectedComponent = subIndex;
    this.liveXY = { name: currentSettings['name'], x: Number(currentSettings['x']), y: Number(currentSettings['y']), c: roundDecimal(Number(currentSettings['x']) + (Number(currentSettings['w']) / 2), 2), m: roundDecimal(Number(currentSettings['y']) + (Number(currentSettings['h']) / 2), 2), w: Number(currentSettings['w']), h: Number(currentSettings['h']), z: Number(currentSettings['z']), bgcolor: currentSettings['bgcolor'], t: Number(currentSettings['t']), rx: Number(currentSettings['rx']), ry: Number(currentSettings['ry']), rz: Number(currentSettings['rz']) };
    this.setSelectComponent.emit({ selectedColumn: index, selectedComponent: subIndex, liveXY: this.liveXY });
  }
  dragEntered($event, index, subIndex) {
    this.selectedColumn = index;
    this.selectedComponent = subIndex;
  }
  selectComponent($event, index, subIndex) {
    if (subIndex !== this.selectedComponent) {
      this.enableEditor = false;
      this.enableGrid = false;
      this.enableAudienceGrid = false;
      this.enablePanelGrid = false;
    }
    if (subIndex !== undefined) {
      let currentSettings = JSON.parse(JSON.stringify(this.settingObject['columns'][index]['components'][subIndex]));
      if (!(currentSettings['name'] === 'empty-click' && this.enableEditor)) {
        if ($event) {
          $event.preventDefault();
          $event.stopPropagation();
        }
        this.selectedColumn = index;
        this.selectedComponent = subIndex;
        this.liveXY = { name: currentSettings['name'], x: Number(currentSettings['x']), y: Number(currentSettings['y']), c: roundDecimal(Number(currentSettings['x']) + (Number(currentSettings['w']) / 2), 2), m: roundDecimal(Number(currentSettings['y']) + (Number(currentSettings['h']) / 2), 2), w: Number(currentSettings['w']), h: Number(currentSettings['h']), z: Number(currentSettings['z']), bgcolor: currentSettings['bgcolor'], t: Number(currentSettings['t']), rx: Number(currentSettings['rx']), ry: Number(currentSettings['ry']), rz: Number(currentSettings['rz']) };
        this.setSelectComponent.emit({ selectedColumn: index, selectedComponent: subIndex, liveXY: this.liveXY });
      }
    } else {
      this.selectedColumn = index;
      this.selectedComponent = subIndex;
      this.liveXY = { name: '', x: 0, y: 0, c: 0, m: 0, w: 0, h: 0, z: 1, bgcolor: '', t: 100, rx: 0, ry: 0, rz: 0 };
      this.setSelectComponent.emit({ selectedColumn: index, selectedComponent: subIndex, liveXY: this.liveXY });
    }
  }
  dragEnd($event, mainBoundary, target, index, subIndex) {
    let position = $event.source.getFreeDragPosition();
    target['x'] = roundDecimal((position.x * 100) / mainBoundary.offsetWidth, 2);
    target['y'] = roundDecimal((position.y * 100) / mainBoundary.offsetWidth, 2);
    // this.selectedColumn = undefined;
    // this.selectedComponent = undefined;
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    currentSettings['columns'][index]['components'][subIndex]['x'] = Number(target['x']);
    currentSettings['columns'][index]['components'][subIndex]['y'] = Number(target['y']);
    this.setUpdateLayout.emit({ currentSettings: currentSettings });
  }
  onResizeEnd($event, mainBoundary, target, index, subIndex) {
    let oldW = target.w;
    let oldH = target.h;
    let newW = roundDecimal(($event.rectangle.width * 100) / mainBoundary.offsetWidth, 2);
    let newH = roundDecimal(($event.rectangle.height * 100) / mainBoundary.offsetWidth, 2);
    if (this.weareresizing) {
      this.weareresizing = false;
      target.w = newW;
      target.h = newH;
      this.liveXY['w'] = Number(target.w);
      this.liveXY['h'] = Number(target.h);
      let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
      currentSettings['columns'][index]['components'][subIndex]['w'] = Number(target['w']);
      currentSettings['columns'][index]['components'][subIndex]['h'] = Number(target['h']);
      this.setUpdateLayout.emit({ currentSettings: currentSettings });
    }
  }
  getHeight(h, w, ratioSize, notFixedRatio = false, naturalRatioSize = undefined) {
    // console.log('notFixedRatio', notFixedRatio);
    if (naturalRatioSize && !notFixedRatio) {
      return roundDecimal((Number(w) * naturalRatioSize.h) / naturalRatioSize.w, 2) + 'vw';
    } else if (ratioSize && !notFixedRatio) {
      return roundDecimal((Number(w) * ratioSize.h) / ratioSize.w, 2) + 'vw';
    } else {
      return h + 'vw';
    }
  }
  getMobileHeight(name, h, w, ratioSize, notFixedRatio = false) {
    // console.log('notFixedRatio', notFixedRatio);
    if (this.deviceService.isMobile()) {
      w = window.screen.width;
    } else {
      w = 480;
    }
    ratioSize = { w: 16, h: 9 };
    if (name === 'picturewall') {
      return '100%';
    } else {
      if (ratioSize && !notFixedRatio) {
        return roundDecimal((Number(w) * ratioSize.h) / ratioSize.w, 2) + 'px';
      } else {
        // below will be fixed ince the mobile have its own sizes
        return roundDecimal((Number(w) * ratioSize.h) / ratioSize.w, 2) + 'px';
      }
    }

  }
  getRatio(ratioSize, notFixedRatio = false, naturalRatioSize = undefined) {
    if (naturalRatioSize && !notFixedRatio) {
      //this part should only be applied to the designer
      return naturalRatioSize;
    }
    if (ratioSize && !notFixedRatio) {
      return ratioSize;
    } else {
      return undefined;
    }
  }
  getZ(z, index, subIndex) {
    if (this.selectedColumn === index && this.selectedComponent === subIndex) {
      return 800;
    } else {
      return z;
    }
  }
  getX(x, mainBoundary, idx) {
    let currentValue = Math.round((x * mainBoundary.offsetWidth) / 100);
    if (!this.resizingObjectX[idx]) {
      this.resizingObjectX[idx] = currentValue;
    }
    return this.resizingObjectX[idx];
  }
  onResize(e) {
    this.resizingObjectX = {};
    this.resizingObjectY = {};
  }

  getY(y, mainBoundary, idx) {
    let currentValue = Math.round((y * mainBoundary.offsetWidth) / 100);
    if (!this.resizingObjectY[idx]) {
      this.resizingObjectY[idx] = currentValue;
    }
    return this.resizingObjectY[idx];
  }
  getGridBorder(total, idx) {
    return total % idx == 0 ? '0px' : '5px';
  }
  getGridTemplateColumns(gridSizeX) {
    if (Number(gridSizeX) <= 1) {
      return '';
    } else {
      return 'repeat(' + gridSizeX + ', minmax(auto, 0.5fr))';
    }
  }
  getTileLink(id) {
    return this.requestService.getTileLink(undefined, id, true);
  }
  changeRowAllowAllSpeak($event, value, index, subIndex) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    currentSettings['columns'][index]['components'][subIndex]['enableAllowAllSpeak'] = value;
    this.setUpdateLayout.emit({ currentSettings: currentSettings });
  }
  removeCmp($event, index, subIndex) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    let attachedCmpRule = this.checkRequireCmpValidate(index, subIndex);
    if (attachedCmpRule) {
      let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
      let cmpType = currentSettings['columns'][index]['components'][subIndex]['name'];
      this.layoutUtilsService.showNotification(this.translate.instant('You cannot remove the ') + this.componentsName[cmpType].displayName + this.translate.instant(' component when you have a ') + this.componentsName[attachedCmpRule].displayName + this.translate.instant(' component'), this.translate.instant('Dismiss'));
    } else {
      let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
      // let currentCmp = currentSettings['columns'][index]['components'][subIndex];
      // if (currentCmp.name === 'presenter-camera' && this.data.data['streamMode'] !== 'cloudlive' && this.data.data['streamMode'] !== 'meeting') {
      //   let componentsCopy = JSON.parse(JSON.stringify(currentSettings['columns'][index]['components']));
      //   let componentsList = componentsCopy.filter((cmp) => cmp.name === 'presenter-camera' && cmp.active);
      //   if (componentsList.length === 1) {
      //     let isAdvancedSpeaker = this.data.data.users.filter((user) => user.type === 'lead' && user.host);
      //     if (isAdvancedSpeaker.length > 0) {
      //       this.layoutUtilsService.showNotification(this.translate.instant('A ' + this.componentsName['presenter-camera'].displayName + ' should be available when there is only one speaker and no producers in the session'), this.translate.instant('Dismiss'));
      //       return false;
      //     }
      //   }
      // }
      this.settingObject['columns'][index]['components'].splice(subIndex, 1);
      currentSettings = JSON.parse(JSON.stringify(this.settingObject));
      this.onResize(undefined);// we call this to reset positions
      // currentSettings['columns'][index]['components'].splice(subIndex, 1);
      this.setUpdateLayout.emit({ currentSettings: currentSettings });
    }
  }

  checkRequireCmpValidate(index, subIndex) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    let cmpType = currentSettings['columns'][index]['components'][subIndex]['name'];
    let cmpAttachAvailable = currentSettings['columns'][0]['components'].filter((cmp) => {
      if (this.componentsName[cmp.name].rules['requireCmp'].includes(cmpType)) {
        return true;
      }
      return false;
    }
    );
    if (cmpAttachAvailable.length > 0) {
      let currentSingleSettings = JSON.parse(JSON.stringify(this.settingObject));
      let cmpAvailable = currentSingleSettings['columns'][0]['components'].filter((cmp) => cmp.name === cmpType);
      if (cmpAvailable.length === 1) {
        return cmpAttachAvailable[0].name;
      }
    }
    return undefined;
  }

  editTile($event, index, subIndex) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    let hasContent = false;
    if (currentSettings['columns'][index]['components'][subIndex]['metadata']['id']) {
      hasContent = true;
    }

    let addNote = undefined;
    if (this.type === 'room-offline') {
      addNote = 'Note: Tiles with questionnaires can only be submitted when placed on the Session level.'
    }
    const dialogRef = this.dialog.open(TileSelectDialogComponent, {
      width: '600px',
      autoFocus: false,
      data: {
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Tile'),
        data: { _id: currentSettings['columns'][index]['components'][subIndex]['metadata']['id'], title: currentSettings['columns'][index]['components'][subIndex]['metadata']['name'], categoryId: currentSettings['columns'][index]['components'][subIndex]['metadata']['categoryId'] },
        hideClear: !hasContent,
        addNote: addNote
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('TileSelectDialogComponent', result);
        if (result.hasOwnProperty('_id')) {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = result['_id'];
          currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = result['title'];
          currentSettings['columns'][index]['components'][subIndex]['metadata']['categoryId'] = result['categoryId'];
          this.setUpdateLayout.emit({ currentSettings: currentSettings });
        } else if (result.hasOwnProperty('new')) {
          this.createTile(index, subIndex);
        } else {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = '';
          currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = '';
          currentSettings['columns'][index]['components'][subIndex]['metadata']['categoryId'] = '';
          this.setUpdateLayout.emit({ currentSettings: currentSettings });
        }
      }
    });
  }
  editAudio($event, index, subIndex) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    const dialogRef = this.dialog.open(AudioEditDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Edit') + ' ' + this.translate.instant('Audio') + ' ' + this.translate.instant('Settings'),
        data: currentSettings['columns'][index]['components'][subIndex]['metadata']
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        currentSettings['columns'][index]['components'][subIndex]['metadata'] = result;
        this.setUpdateLayout.emit({ currentSettings: currentSettings });
      }
    });
  }
  editVideoLibrary($event, index, subIndex) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    const dialogRef = this.dialog.open(VideoPlaylistDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Edit') + ' ' + this.translate.instant('Media Library') + ' ' + this.translate.instant('Settings'),
        data: currentSettings['columns'][index]['components'][subIndex]['metadata']
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        currentSettings['columns'][index]['components'][subIndex]['metadata'] = result;
        this.setUpdateLayout.emit({ currentSettings: currentSettings });
      }
    });
  }
  editImageGallery($event, index, subIndex) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    const dialogRef = this.dialog.open(ImageGalleryDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Edit') + ' ' + this.translate.instant('Image Gallery') + ' ' + this.translate.instant('Settings'),
        data: currentSettings['columns'][index]['components'][subIndex]['metadata']
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        currentSettings['columns'][index]['components'][subIndex]['metadata'] = result;
        // this.imageLoading[currentSettings['columns'][index]['components'][subIndex]['uid']] = true;
        this.setUpdateLayout.emit({ currentSettings: currentSettings });
      }
    });
  }
  /**
  * Create Tile popup
  */
  createTile(index, subIndex) {
    let filterData: any = {
      '$and': [
        { 'organizationId._id': { '$eq': this.requestService.orgId } }
      ]
    };
    const dialogRef = this.dialog.open(ModalTileCreateDialogComponent, {
      width: '100vw',
      data: {
        title: this.translate.instant('Create') + ' ' + this.translate.instant('Tile'),
        data: {}
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
        currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = result['_id'];
        currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = result['name'];
        this.setUpdateLayout.emit({ currentSettings: currentSettings });
      }
    });
  }
  editBillboard($event, index, subIndex) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    const dialogRef = this.dialog.open(BillboardSelectDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Billboard'),
        data: currentSettings['columns'][index]['components'][subIndex],
        hasAdd: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log('result', result);
        if (result.hasOwnProperty('_id')) {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = result['_id'];
          if (result.hasOwnProperty('text')) {
            currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = result['text'];
          } else {
            currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = result['title'];
          }
          this.setUpdateLayout.emit({ currentSettings: currentSettings });
        } else if (result.hasOwnProperty('new')) {
          this.createBillboard(index, subIndex);
        } else {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = '';
          currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = '';
          this.setUpdateLayout.emit({ currentSettings: currentSettings });
        }
      }
    });
  }
  /**
  * Create Billboard popup
  */
  createBillboard(index, subIndex) {
    let filterData: any = {
      '$and': [
        { 'organizationId._id': { '$eq': this.requestService.orgId } }
      ]
    };
    const dialogRef = this.dialog.open(ModalDialogComponent, {
      width: '100vw',
      data: {
        dataType: 'banner',
        dataTypeTitle: this.translate.instant('Billboard'),
        title: this.translate.instant('Create') + ' ' + this.translate.instant('Billboard'),
        data: {},
        modalSetting: this.getBillboardSetting({}, this.getBillboardCustomFilter())
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.confirmBillboardCreate(result.data, index, subIndex);
      }
    });
  }
  private getBillboardSetting(data, filters) {
    let tableSetting = data;
    tableSetting['target'] = 'parent';
    if (filters)
      tableSetting['filters'] = filters;
    tableSetting['customSettings'] = {
      organizationId: {
        visible: false,
        value: { _id: this.requestService.orgId, name: '' }
      }
    };
    return tableSetting;
  }
  private getBillboardCustomFilter() {
    let filters = {};
    filters['$and'] = [{ "organizationId._id": { "$eq": this.requestService.orgId } }];
    return filters;
  }
  public confirmBillboardCreate(data, index, subIndex) {
    if (!this.loading) {
      this.loading = true;
      //  this.loaderService.display(true);
      this.errorMessage = '';
      this.requestService.saveData('banner', data, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          let result = data.results;
          if (result.hasOwnProperty('_id')) {
            let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
            currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = result['_id'];
            currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = result['name'];
            this.setUpdateLayout.emit({ currentSettings: currentSettings });
            this.layoutUtilsService.showNotification(this.translate.instant('Billboard') + ' ' + this.translate.instant('created Successfully'), this.translate.instant('Dismiss'));
          }
        }
      });
    }
  }
  moveIndex($event, index, subIndex, isDownward = false) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    let temp = JSON.parse(JSON.stringify(this.settingObject['columns'][index]['components'][subIndex]));
    let nextIndex;
    if (isDownward) {
      nextIndex = subIndex + 1;
    } else {
      nextIndex = subIndex - 1;
    }
    let temp2 = JSON.parse(JSON.stringify(this.settingObject['columns'][index]['components'][nextIndex]));
    this.settingObject['columns'][index]['components'][subIndex] = temp2;
    this.settingObject['columns'][index]['components'][nextIndex] = temp;
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    this.setUpdateLayout.emit({ currentSettings: currentSettings });
  }
  editPicturewall($event, index, subIndex) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    const dialogRef = this.dialog.open(PictureWallDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Edit') + ' ' + this.translate.instant('Picture Wall') + ' ' + this.translate.instant('Settings'),
        data: currentSettings['columns'][index]['components'][subIndex],
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log('result', result);
        currentSettings['columns'][index]['components'][subIndex]['gridSizeX'] = result['gridSizeX'];
        currentSettings['columns'][index]['components'][subIndex]['gridSizeY'] = result['gridSizeY'];
        currentSettings['columns'][index]['components'][subIndex]['timer'] = result['timer'];
        currentSettings['columns'][index]['components'][subIndex]['moderated'] = result['moderated'];
        currentSettings['columns'][index]['components'][subIndex]['bgcolor'] = result['bgcolor'];
        // currentSettings['columns'][index]['components'][subIndex]['opacity'] = result['opacity'];
        this.setUpdateLayout.emit({ currentSettings: currentSettings });
      }
    });
  }
  editDefaultvideo($event, index, subIndex) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    const dialogRef = this.dialog.open(PartyStreamDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Edit') + ' ' + this.translate.instant('Media') + ' ' + this.translate.instant('Settings'),
        data: currentSettings['columns'][index]['components'][subIndex],
        source: 'designer',
        currentUserId: this.selectedUser._id,
        isDesigner: true,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        //console.log('result', result);
        currentSettings['columns'][index]['components'][subIndex]['autoStart'] = result.data['autoStart'];
        currentSettings['columns'][index]['components'][subIndex]['loop'] = result.data['loop'];
        currentSettings['columns'][index]['components'][subIndex]['controls'] = result.data['controls'];
        currentSettings['columns'][index]['components'][subIndex]['type'] = result.data['type'];
        currentSettings['columns'][index]['components'][subIndex]['url'] = result.data['url'];
        currentSettings['columns'][index]['components'][subIndex]['clickThruUrl'] = result.data['clickThruUrl'];
        currentSettings['columns'][index]['components'][subIndex]['hlsUrl'] = result.data['hlsUrl'];
        currentSettings['columns'][index]['components'][subIndex]['startMin'] = result.data.startMin;
        currentSettings['columns'][index]['components'][subIndex]['startSec'] = result.data.startSec;
        currentSettings['columns'][index]['components'][subIndex]['autoRepeat'] = result.data['autoRepeat'];
        this.setUpdateLayout.emit({ currentSettings: currentSettings });
      }
    });
  }
  editEmptySetting($event, index, subIndex) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    const dialogRef = this.dialog.open(PictureDialogComponent, {
      width: '600px',
      disableClose: false,
      autoFocus: false,
      data: {
        hasShape: true,
        isEmptyClick: true,
        title: this.translate.instant('Edit Clickable Areas Settings'),
        data: { type: currentSettings['columns'][index]['components'][subIndex]['metadata']['type'], link: currentSettings['columns'][index]['components'][subIndex]['metadata']['link'], linkDisplay: currentSettings['columns'][index]['components'][subIndex]['metadata']['linkDisplay'], title: currentSettings['columns'][index]['components'][subIndex]['title'], shape: currentSettings['columns'][index]['components'][subIndex]['shape'], autoPlay: currentSettings['columns'][index]['components'][subIndex]['metadata']['autoPlay'], loop: currentSettings['columns'][index]['components'][subIndex]['metadata']['loop'], volume: currentSettings['columns'][index]['components'][subIndex]['metadata']['volume'] },
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        currentSettings['columns'][index]['components'][subIndex]['metadata']['type'] = result.type;
        currentSettings['columns'][index]['components'][subIndex]['metadata']['link'] = result.link;
        currentSettings['columns'][index]['components'][subIndex]['metadata']['linkDisplay'] = result.linkDisplay;
        currentSettings['columns'][index]['components'][subIndex]['title'] = result.title;
        currentSettings['columns'][index]['components'][subIndex]['shape'] = result.shape;
        currentSettings['columns'][index]['components'][subIndex]['metadata']['autoPlay'] = result.autoPlay;
        currentSettings['columns'][index]['components'][subIndex]['metadata']['loop'] = result.loop;
        currentSettings['columns'][index]['components'][subIndex]['metadata']['volume'] = result.volume;
        if (currentSettings['columns'][index]['components'][subIndex]['shape'] !== result.shape) {
          currentSettings['columns'][index]['components'][subIndex]['svg'] = [];
          if (result.shape === 'poly') {
            currentSettings['columns'][index]['components'][subIndex]['svg'] = [{ x: 1.46, y: 0.99 }, { x: 0.63, y: 3.18 }, { x: 3.07, y: 4.06 }, { x: 4.48, y: 1.98 }];
          }
        }
        this.setUpdateLayout.emit({ currentSettings: currentSettings });
      }
    });
  }
  editPictureSetting($event, index, subIndex) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    const dialogRef = this.dialog.open(PictureDialogComponent, {
      width: '600px',
      disableClose: false,
      autoFocus: false,
      data: {
        hasFixed: true,
        title: this.translate.instant('Edit') + ' Picture ' + this.translate.instant('Settings'),
        data: { type: currentSettings['columns'][index]['components'][subIndex]['metadata']['type'], link: currentSettings['columns'][index]['components'][subIndex]['metadata']['link'], linkDisplay: currentSettings['columns'][index]['components'][subIndex]['metadata']['linkDisplay'], title: currentSettings['columns'][index]['components'][subIndex]['title'], fixed: currentSettings['columns'][index]['components'][subIndex]['fixed'], notFixedRatio: currentSettings['columns'][index]['components'][subIndex]['notFixedRatio'], autoPlay: currentSettings['columns'][index]['components'][subIndex]['metadata']['autoPlay'], loop: currentSettings['columns'][index]['components'][subIndex]['metadata']['loop'], volume: currentSettings['columns'][index]['components'][subIndex]['metadata']['volume'] },
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        currentSettings['columns'][index]['components'][subIndex]['metadata']['type'] = result.type;
        currentSettings['columns'][index]['components'][subIndex]['metadata']['link'] = result.link;
        currentSettings['columns'][index]['components'][subIndex]['metadata']['linkDisplay'] = result.linkDisplay;
        currentSettings['columns'][index]['components'][subIndex]['metadata']['autoPlay'] = result.autoPlay;
        currentSettings['columns'][index]['components'][subIndex]['metadata']['loop'] = result.loop;
        currentSettings['columns'][index]['components'][subIndex]['metadata']['volume'] = result.volume;
        currentSettings['columns'][index]['components'][subIndex]['title'] = result.title;
        currentSettings['columns'][index]['components'][subIndex]['fixed'] = result.fixed;
        currentSettings['columns'][index]['components'][subIndex]['notFixedRatio'] = result.notFixedRatio;
        this.setUpdateLayout.emit({ currentSettings: currentSettings });
      }
    });
  }
  editQuuNowPlaying($event, index, subIndex) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    const dialogRef = this.dialog.open(QuuRadioDialogComponent, {
      width: '600px',
      disableClose: false,
      autoFocus: false,
      data: {
        hasSongsNo: false,
        title: this.translate.instant('Edit') + ' Quu Radio - Now Playing ' + this.translate.instant('Settings'),
        data: { callerId: currentSettings['columns'][index]['components'][subIndex]['metadata']['callerId'], stationId: currentSettings['columns'][index]['components'][subIndex]['metadata']['stationId'], plsUrl: currentSettings['columns'][index]['components'][subIndex]['metadata']['plsUrl'] },
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        currentSettings['columns'][index]['components'][subIndex]['metadata']['callerId'] = result.callerId;
        currentSettings['columns'][index]['components'][subIndex]['metadata']['stationId'] = result.stationId;
        currentSettings['columns'][index]['components'][subIndex]['metadata']['plsUrl'] = result.plsUrl;
        this.setUpdateLayout.emit({ currentSettings: currentSettings });
      }
    });
  }
  editQuuSongs($event, index, subIndex) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    const dialogRef = this.dialog.open(QuuRadioDialogComponent, {
      width: '600px',
      disableClose: false,
      autoFocus: false,
      data: {
        hasSongsNo: true,
        title: this.translate.instant('Edit') + ' Quu Radio - Songs ' + this.translate.instant('Settings'),
        data: { callerId: currentSettings['columns'][index]['components'][subIndex]['metadata']['callerId'], stationId: currentSettings['columns'][index]['components'][subIndex]['metadata']['stationId'], songsNo: currentSettings['columns'][index]['components'][subIndex]['metadata']['songsNo'] },
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        currentSettings['columns'][index]['components'][subIndex]['metadata']['callerId'] = result.callerId;
        currentSettings['columns'][index]['components'][subIndex]['metadata']['stationId'] = result.stationId;
        currentSettings['columns'][index]['components'][subIndex]['metadata']['songsNo'] = result.songsNo;
        this.setUpdateLayout.emit({ currentSettings: currentSettings });
      }
    });
  }
  editIframeSetting($event, index, subIndex) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    const dialogRef = this.dialog.open(IframeComponent, {
      width: '25vw',
      disableClose: false,
      autoFocus: false,
      data: {
        title: this.translate.instant('Insert') + ' ' + this.translate.instant('Iframe') + ' ' + this.translate.instant('URL'),
        data: { link: currentSettings['columns'][index]['components'][subIndex]['metadata']['link'] },
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        currentSettings['columns'][index]['components'][subIndex]['metadata']['link'] = result.data.link;
        this.setUpdateLayout.emit({ currentSettings: currentSettings });
      }
    });
  }
  editImage($event, index, subIndex) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    const dialogRef = this.dialog.open(ModalAdvancedGalleryDialogComponent, {
      width: '100vw',
      disableClose: false,
      autoFocus: false,
      data: {
        handleBgColor: false,
        noImage: true,
        notBg: true,
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Image'),
        data: { bgBackgroundLink: currentSettings['columns'][index]['components'][subIndex]['image'] },
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log('result', result);
        // this.imageLoading[currentSettings['columns'][index]['components'][subIndex]['uid']] = true;
        currentSettings['columns'][index]['components'][subIndex]['image'] = result.bgBackgroundLink;
        this.setUpdateLayout.emit({ currentSettings: currentSettings });
      }
    });
  }
  editPictureWallImages($event) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    const dialogRef = this.dialog.open(ModalGalleryDialogComponent, {
      width: '1600px',
      disableClose: false,
      autoFocus: false,
      data: {
        title: this.translate.instant('Edit Picture Images Uploaded'),
        galleryType: 'picturewallart',
        targetId: this.data.data._id,
        noSelection: true,
        data: [],
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // do nothing
      }
    });
  }

  getSelectedUsers(cameraType: string[]) {
    let alreadySelected = [];
    let settingObject = JSON.parse(JSON.stringify(this.settingObject));
    for (let col of settingObject['columns']) {
      for (let comp of col['components']) {
        if (cameraType.includes(comp.name) && comp.metadata.hasOwnProperty('id') && comp.metadata.id !== '') {
          alreadySelected.push(comp.metadata.id);
        }
      }
    }
    return alreadySelected;
  }
  /**
  * Edit Guest function
  */
  editcamera($event, index, subIndex) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    let alreadySelected = this.getSelectedUsers(['guest-camera', 'presenter-camera']);
    let userssList = [];
    if (this.data.data.hasOwnProperty('users')) {
      for (let dt of this.data.data.users) {
        if ((dt.type === 'guest' || dt.type === 'lead') && (!alreadySelected.includes(dt._id) || currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] === dt._id)) {
          userssList.push({ _id: dt._id, text: dt.name });
        }
      }
    }
    // console.log('guestsList', guestsList);
    const dialogRef = this.dialog.open(GuestEditDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Edit') + ' ' + this.translate.instant('Camera'),
        data: { viewPublicChat: currentSettings['columns'][index]['components'][subIndex]['viewPublicChat'], id: currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] },
        dataList: userssList
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // console.log('result', result);
        if (result.hasOwnProperty('id') && result.id) {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = result['id'];
          for (let itm of userssList) {
            if (itm._id === result['id']) {
              currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = itm['text'];
              break;
            }
          }
        } else {
          currentSettings['columns'][index]['components'][subIndex]['metadata']['id'] = '';
          currentSettings['columns'][index]['components'][subIndex]['metadata']['name'] = '';
        }
        currentSettings['columns'][index]['components'][subIndex]['viewPublicChat'] = result['viewPublicChat'];
        this.setUpdateLayout.emit({ currentSettings: currentSettings });
      }
    });
  }
  /**
  * Active / deActivate function
  */
  editActive($event, index, subIndex, value) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }

    let attachedCmpRule = undefined;
    if (!value) {
      attachedCmpRule = this.checkRequireCmpValidate(index, subIndex);
    }
    if (attachedCmpRule) {
      let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
      let cmpType = currentSettings['columns'][index]['components'][subIndex]['name'];
      this.layoutUtilsService.showNotification(this.translate.instant('You cannot hide the ') + this.componentsName[cmpType].displayName + this.translate.instant(' component when you have a ') + this.componentsName[attachedCmpRule].displayName + this.translate.instant(' component'), this.translate.instant('Dismiss'));
    } else {
      let currentSettings = JSON.parse(JSON.stringify(this.settingObject));

      // let currentCmp = currentSettings['columns'][index]['components'][subIndex];
      // if (currentCmp.name === 'presenter-camera' && this.data.data['streamMode'] !== 'cloudlive' && this.data.data['streamMode'] !== 'meeting' && !value) {
      //   let componentsCopy = JSON.parse(JSON.stringify(currentSettings['columns'][index]['components']));
      //   let componentsList = componentsCopy.filter((cmp) => cmp.name === 'presenter-camera' && cmp.active);
      //   if (componentsList.length === 1) {
      //     let isAdvancedSpeaker = this.data.data.users.filter((user) => user.type === 'lead' && user.host);
      //     if (isAdvancedSpeaker.length > 0) {
      //       this.layoutUtilsService.showNotification(this.translate.instant('A ' + this.componentsName['presenter-camera'].displayName + ' should be available when there is only one speaker and no producers in the session'), this.translate.instant('Dismiss'));
      //       return false;
      //     }
      //   }
      // }

      currentSettings['columns'][index]['components'][subIndex]['active'] = value;
      this.setUpdateLayout.emit({ currentSettings: currentSettings });
    }
  }
  /**
  * getShapePoint function
  */
  getShapePoint(mainBoundary, index, subIndex) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    let points = '';
    if (currentSettings['columns'][index]['components'][subIndex].hasOwnProperty('svg')) {
      for (let pnt of currentSettings['columns'][index]['components'][subIndex]['svg']) {
        points = points + this.getPolyX(pnt.x, mainBoundary, index, subIndex) + ',' + this.getPolyY(pnt.y, mainBoundary, index, subIndex) + ' ';
      }
    }
    return points;
  }
  /**
  * getCircleR function
  */
  getPositiveValue(x1, x2) {
    let r = 0;
    if (x1 > x2) {
      r = x1 - x2;
    } else if (x1 > x2) {
      r = x2 - x1;
    }
    return r;
  }
  getPolyX(x, mainBoundary, index, subIndex) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    // return Math.round((x * ((currentSettings['columns'][index]['components'][subIndex].w* mainBoundary.offsetWidth)/100))/100);
    return Math.round((x * mainBoundary.offsetWidth) / 100);
  }
  getPolyY(y, mainBoundary, index, subIndex) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    // return Math.round((y * ((currentSettings['columns'][index]['components'][subIndex].w* mainBoundary.offsetWidth)/100))/100);
    return Math.round((y * mainBoundary.offsetWidth) / 100);
  }
  /**
  * editEmptyShape function
  */
  editEmptyShape($event, index, subIndex) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    this.enableEditor = !this.enableEditor;
  }
  /**
  * addPoint function
  */
  addPoint($event, mainBoundary, index, subIndex) {
    if (this.enableEditor) {
      if ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      }
      if (!this.dragPolyEntered) {
        let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
        if (!currentSettings['columns'][index]['components'][subIndex].hasOwnProperty('svg')) {
          currentSettings['columns'][index]['components'][subIndex]['svg'] = [];
        }
        let newX = roundDecimal(($event.offsetX * 100) / mainBoundary.offsetWidth, 2);
        let newY = roundDecimal(($event.offsetY * 100) / mainBoundary.offsetWidth, 2);

        currentSettings['columns'][index]['components'][subIndex]['svg'].push({ x: newX, y: newY });
        this.setUpdateLayout.emit({ currentSettings: currentSettings });
      }
      this.dragPolyEntered = false;
    }
  }
  /**
  * onCircleClick function
  */
  onCircleClick($event, index, subIndex, circleindex) {
    if (this.enableEditor) {
      if ($event) {
        $event.preventDefault();
        $event.stopPropagation();
      }
      if (this.settingObject['columns'][index]['components'][subIndex].hasOwnProperty('svg')) {
        this.settingObject['columns'][index]['components'][subIndex]['svg'].splice(circleindex, 1);
        let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
        this.setUpdateLayout.emit({ currentSettings: currentSettings });
      }
    }
  }
  /**
  * editAttribute function
  */
  editAttribute($event, index, subIndex, id, value) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    currentSettings['columns'][index]['components'][subIndex][id] = value;
    this.setUpdateLayout.emit({ currentSettings: currentSettings });
  }
  /**
  * editNumericAttribute function
  */
  editNumericAttribute($event, index, subIndex, id, value, min, max, maxId = undefined) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    let newVal = Number(value);
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    if (min !== undefined && min > newVal) {
      newVal = min;
    }
    if (max !== undefined && max < newVal) {
      newVal = max;
    }

    if (maxId) {
      let maxIdValue = Number(currentSettings['columns'][index]['components'][subIndex][maxId]);
      if (maxIdValue * Number(newVal) > max) {
        newVal = Math.floor(max / maxIdValue);
      }
    }

    currentSettings['columns'][index]['components'][subIndex][id] = Number(newVal);
    this.setUpdateLayout.emit({ currentSettings: currentSettings });
    this.changeDetectorRef.detectChanges();
  }
  /**
  * editNumericAttribute function
  */
  editMetaNumericAttribute($event, index, subIndex, id, value, min, max, maxId = undefined) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    let newVal = Number(value);
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    if (min !== undefined && min > newVal) {
      newVal = min;
    }
    if (max !== undefined && max < newVal) {
      newVal = max;
    }

    if (maxId) {
      let maxIdValue = Number(currentSettings['columns'][index]['components'][subIndex][maxId]);
      if (maxIdValue * Number(newVal) > max) {
        newVal = Math.floor(max / maxIdValue);
      }
    }

    currentSettings['columns'][index]['components'][subIndex]['metadata'][id] = Number(newVal);
    this.setUpdateLayout.emit({ currentSettings: currentSettings });
    this.changeDetectorRef.detectChanges();
  }
  /**
  * editGridAttribute function
  */
  editGridAttribute($event, index, subIndex, id, value, min, max, gridSizeCouple) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    let newVal = Number(value);
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    if (min !== undefined && min > newVal) {
      newVal = min;
    }
    if (max !== undefined && max < newVal) {
      newVal = max;
    }
    if (newVal * gridSizeCouple > max) {
      newVal = 1;
    }
    currentSettings['columns'][index]['components'][subIndex][id] = newVal + '';
    this.setUpdateLayout.emit({ currentSettings: currentSettings });
    this.changeDetectorRef.detectChanges();
  }

  /**
  * editEditor function
  */
  editEditor(index, subIndex, id, value) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    currentSettings['columns'][index]['components'][subIndex][id] = value;
    // console.log(currentSettings['columns'][index]['components'][subIndex]);
    // this.settingObject['columns'][index]['components'][subIndex]['active'] = value;
    this.setUpdateLayout.emit({ currentSettings: currentSettings });
  }
  cancelThru($event) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
  }
  numberReturn(length) {
    return new Array(length);
  }
  dragCircleSvg($event, index, subIndex, circleindex) {
    //console.log($event);
  }
  leaveCircleSvg($event, index, subIndex, circleindex) {
    //console.log($event);
  }
  grabCircleSvg($event, index, subIndex, circleindex) {
    console.log($event);
  }
  dropCircleSvg($event, index, subIndex, circleindex) {
    console.log($event);
  }
  dragPolyStarted($event, mainBoundary, index, subIndex, circleindex) {
    // console.log('dragPolyStarted', $event);
    // let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    // console.log('dragPolyStarted',currentSettings['columns'][index]['components'][subIndex]['svg'][circleindex]);
    // if(this.enableEditor){
    //   if($event){
    //     $event.preventDefault();
    //     $event.stopPropagation();
    //   }
    //   let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    //   let newX = roundDecimal(($event.offsetX * 100) / mainBoundary.offsetWidth, 2);
    //   let newY = roundDecimal(($event.offsetY * 100) / mainBoundary.offsetWidth, 2);
    //
    //   currentSettings['columns'][index]['components'][subIndex]['svg'][circleindex]['x'] = newX;
    //   currentSettings['columns'][index]['components'][subIndex]['svg'][circleindex]['y'] = newY;
    //   this.setUpdateLayout.emit({currentSettings: currentSettings});
    // }
  }
  dragPolyEnd($event, mainBoundary, target, index, subIndex, circleindex) {
    let position = $event.source.getFreeDragPosition();
    target['svg'][circleindex]['x'] = target['svg'][circleindex]['x'] + roundDecimal((position.x * 100) / mainBoundary.offsetWidth, 2);
    target['svg'][circleindex]['y'] = target['svg'][circleindex]['y'] + roundDecimal((position.y * 100) / mainBoundary.offsetWidth, 2);
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    currentSettings['columns'][index]['components'][subIndex]['svg'][circleindex]['x'] = Number(target['svg'][circleindex]['x']);
    currentSettings['columns'][index]['components'][subIndex]['svg'][circleindex]['y'] = Number(target['svg'][circleindex]['y']);
    this.setUpdateLayout.emit({ currentSettings: currentSettings });
  }
  dragPolyMoved($event, mainBoundary, target, index, subIndex, circleindex) {
    // console.log('dragMoved. ',$event);
    // let position = $event.source.getFreeDragPosition();
    // console.log('dragPolyMoved ',position);
    // this.liveXY['x'] = roundDecimal((position.x * 100) / mainBoundary.offsetWidth, 2);
    // this.liveXY['y'] = roundDecimal((position.y * 100) / mainBoundary.offsetWidth, 2);
    // this.liveXY['c'] = roundDecimal(((position.x * 100) / mainBoundary.offsetWidth) + (target.w/2), 2);
    // this.liveXY['m'] = roundDecimal(((position.y * 100) / mainBoundary.offsetWidth) + (target.h/2), 2);
    // this.setSelectComponent.emit({selectedColumn: this.selectedColumn, selectedComponent: this.selectedComponent, liveXY: this.liveXY});
  }
  getCmpIndex(index, subIndex, name) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    let idx = 0;
    let cmpIdx = 0;
    for (let cmp of currentSettings['columns'][index]['components']) {
      if (cmp.name === name) {
        cmpIdx++;
      }
      if (idx === subIndex) {
        return cmpIdx;
      }
      idx++;
    }
    return subIndex;
  }

  getComponentIndex(name, index, subIndex) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    return CommonFunctions.getComponentIndex(currentSettings['columns'][index]['components'], 'name', name, subIndex);
  }
  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.settingObject['columns'][0]['components'], event.previousIndex, event.currentIndex);
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    this.setUpdateLayout.emit({ currentSettings: currentSettings });
  }
  public bgImageLoaded(e, index, subIndex) {
    //this part should only be applied to the designer
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    let ratioSize = undefined;
    if (e) {
      let ratio: any[] = calculateRatio(e.target.naturalWidth, e.target.naturalHeight);
      if (ratio.length == 2) {
        ratioSize = { w: ratio[0], h: ratio[1] };
      }
    }
    let naturalRatioSize = currentSettings['columns'][index]['components'][subIndex]['naturalRatioSize'];
    // if (this.imageLoading.hasOwnProperty(currentSettings['columns'][index]['components'][subIndex]['uid']) && this.imageLoading[currentSettings['columns'][index]['components'][subIndex]['uid']] === true) {
    // this.imageLoading[currentSettings['columns'][index]['components'][subIndex]['uid']] === false;
    if (!naturalRatioSize || (naturalRatioSize && (naturalRatioSize.w !== ratioSize.w || naturalRatioSize.h !== ratioSize.h))) {
      currentSettings['columns'][index]['components'][subIndex]['naturalRatioSize'] = ratioSize;
      this.setUpdateLayout.emit({ currentSettings: currentSettings });
    }
    // }
  }
  public bgImageLoadedError(index, subIndex) {
    //this part should only be applied to the designer
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    currentSettings['columns'][index]['components'][subIndex]['naturalRatioSize'] = undefined;
    this.setUpdateLayout.emit({ currentSettings: currentSettings });
  }
  getAttendeesTotal(total): number {
    let specialNumber = 15000;
    if (total > 0 && total < 3) {
      specialNumber = 15000;
    } else if (total >= 3 && total < 4) {
      specialNumber = 13000;
    } else if (total >= 4 && total < 5) {
      specialNumber = 11000;
    } else if (total >= 5 && total < 6) {
      specialNumber = 8000;
    } else if (total >= 6 && total < 7) {
      specialNumber = 6800;
    } else if (total >= 7 && total < 8) {
      specialNumber = 5700;
    } else if (total >= 8 && total < 9) {
      specialNumber = 5000;
    } else if (total >= 9 && total < 10) {
      specialNumber = 4300;
    } else if (total >= 10 && total < 11) {
      specialNumber = 4000;
    }
    return Math.floor(specialNumber);
  }

  getTransform(uuid, rx, ry, rz) {
    let element = document.getElementById(uuid + '-holder');
    rx = rx | 0;
    ry = ry | 0;
    rz = rz | 0;
    if (element) {
      if (element.style.transform.indexOf('rotateX') !== -1) {
        element.style.transform = element.style.transform.substring(0, element.style.transform.indexOf('rotateX') - 1) + ' rotateX(' + rx + 'deg)' + ' rotateY(' + ry + 'deg)' + ' rotateZ(' + rz + 'deg)';
      }
      else {
        element.style.transform = element.style.transform + ' rotateX(' + rx + 'deg)' + ' rotateY(' + ry + 'deg)' + ' rotateZ(' + rz + 'deg)';
      }
    }
  }

  detectChanges() {
    if (!this.changeDetectorRef['destroyed']) {
      this.changeDetectorRef.detectChanges();
    }
  }
}
