import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService } from "src/app/shared";

interface ViewDialogData {
  title: string;
  data: any;
}

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.scss']
})

export class IframeComponent implements OnInit, OnDestroy {

  public url: FormControl = undefined;

  constructor(public dialogRef: MatDialogRef<IframeComponent>, @Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
    // this.url = new FormControl(data.data.link, [Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]);
    this.url = new FormControl(data.data.link, [Validators.required, Validators.pattern('(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w #.-]*/?')]);
    // this.url = new FormControl(data.data.link, [Validators.required, Validators.pattern('(http(s)?://)([\\w .-]+[^#?\\s]+)*(#)?([/\\w .-]+[^#?\\s]+)*(\\?)*([/\\w .-]+[^#?\\s]+)?(#)?([/\\w .-]+[^#?\\s]+)?')]);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  close() {
    if (this.url.value && !(this.url.value.substr(0, 7) == 'http://' || this.url.value.substr(0, 8) == 'https://'))
      this.url.setValue('http://' + this.url.value);

    this.data['data'].link = this.url.value;
    this.dialogRef.close(this.data);
  }

  markTouched() {
    this.url.markAsTouched();
    this.url.updateValueAndValidity();
  }
}
