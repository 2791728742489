import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../../shared/services';
import { ErrorStateMatcher } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { isValidHttpsUrl } from 'src/app/shared/helpers';
class MyDialogErrorStateMatcher implements ErrorStateMatcher {
	isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
		const isSubmitted = form && form.submitted;
		return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
	}
}
interface ViewDialogData {
	title: string;
	data: any;
	confirmData: any;
	hasSongsNo: boolean;
}

@Component({
	selector: 'app-quu-radio-dialog-modal',
	templateUrl: './quu-radio-dialog.component.html',
	styleUrls: ['./quu-radio-dialog.component.scss']
})
export class QuuRadioDialogComponent implements OnInit {
	public errorMessage: string = '';
	public loading: boolean = false;
	public hasSongsNo: boolean = false;
	public songsNoLimit: number = 15; // this number is provided by the backend
	public returnData: any = { callerId: '', stationId: '', groupId: '', stationName: '', stationIcon: '', songsNo: 0, plsUrl: '' };

	public esMatcher = new MyDialogErrorStateMatcher();
	constructor(private http: HttpClient,
		private requestService: RequestService, private translate: TranslateService, private loaderService: LoaderService,
		private layoutUtilsService: LayoutUtilsService, public dialog: MatDialog,
		public dialogRef: MatDialogRef<QuuRadioDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		// console.log('ViewDialogData', data);
		if (data) {
			this.hasSongsNo = data.hasSongsNo || false;
			if (data.data) {
				this.returnData = data.data;
				this.returnData['stationName'] = '';
				this.returnData['stationIcon'] = '';
				this.returnData['stationId'] = '';
				this.returnData['groupId'] = '';
				if (this.returnData && this.returnData.callerId !== '') {
					this.fetchStationData(this.returnData.callerId);
				} else {
					this.returnData['callerId'] = '';
				}
			}
		}
	}

	ngOnInit() {
	}
	editAttribute($event, id, value) {
		if ($event) {
			$event.preventDefault();
			$event.stopPropagation();
		}
		this.returnData[id] = value;
	}
	closeModal(data): void {
		if (data) {
			if (data.hasOwnProperty('callerId') && data.callerId === '') {
				this.layoutUtilsService.showNotification(this.translate.instant('Please enter a valid Quu Station Call Letters'), this.translate.instant('Dismiss'));
				return;
			}
			if (!this.hasSongsNo && (!data.hasOwnProperty('plsUrl') || data['plsUrl'] === '' || data['plsUrl'] === undefined)) {
				this.layoutUtilsService.showNotification(this.translate.instant('Please enter a valid URL link'), this.translate.instant('Dismiss'));
				return;
			}
			if (!this.hasSongsNo && (data.plsUrl && data.plsUrl !== '')) {
				if (!isValidHttpsUrl(data.plsUrl)) {
					// if (data.plsUrl.indexOf('.pls') === -1) {
					// 	this.layoutUtilsService.showNotification(this.translate.instant('Fill in the correct https URL link format with a .pls extension'), this.translate.instant('Dismiss'));
					// } else {
					this.layoutUtilsService.showNotification(this.translate.instant('Fill in the correct https URL link format'), this.translate.instant('Dismiss'));
					// }
					return;
				}
			}
			if (!data.hasOwnProperty('stationName') || data['stationName'] === '' || data['stationName'] === undefined) {
				this.layoutUtilsService.showNotification(this.translate.instant('Please enter a valid Quu Station Call Letters'), this.translate.instant('Dismiss'));
				return;
			}
			if (!this.hasSongsNo && data.hasOwnProperty('songsNo') && data.songsNo > 0) {
				this.layoutUtilsService.showNotification(this.translate.instant('Please enter a valid number of songs'), this.translate.instant('Dismiss'));
				return;
			}
			if (this.hasSongsNo && data.hasOwnProperty('songsNo') && (data.songsNo <= 0 || data.songsNo > this.songsNoLimit)) {
				this.layoutUtilsService.showNotification(this.translate.instant('Please enter a valid number of songs'), this.translate.instant('Dismiss'));
				return;
			}

			delete data['stationName'];
			delete data['stationIcon'];
			if (this.hasSongsNo) {
				delete data['plsUrl'];
			}
		}
		this.dialogRef.close(data);
	}
	fetchStationData(id) {
		if (id !== '' && this.returnData['stationName'] === '') {
			this.requestService.onGetStationDataByCallLetter(this.returnData.callerId).subscribe((data: any) => {
				if (data.result && data.result.hasOwnProperty('Name')) {
					this.returnData['stationName'] = data.result.Name;
					this.returnData['stationIcon'] = data.result.LogoImage;
					this.returnData['stationId'] = data.result.Id;
					this.returnData['groupId'] = data.result.GroupId;
				} else {
					this.returnData['stationName'] = '';
					this.returnData['stationIcon'] = '';
					this.returnData['stationId'] = '';
					this.returnData['groupId'] = '';
					this.returnData['callerId'] = '';
					this.layoutUtilsService.showNotification('Station Call Letters not available', 'Dismiss');
				}
			},
				(error) => {
					console.log('error', error);
					this.returnData['stationName'] = '';
					this.returnData['stationIcon'] = '';
					this.returnData['stationId'] = '';
					this.returnData['groupId'] = '';
					this.returnData['callerId'] = '';
					this.layoutUtilsService.showNotification('Station Call Letters not available', 'Dismiss');
				});
		}
	}
}
