import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../../shared/services';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { isValidHttpUrl } from '../../../../shared/helpers';

interface ViewDialogData {
	title: string;
	data: any;
	dataList: any[];
	confirmData: any;
}

@Component({
	selector: 'app-guest-edit-dialog-modal',
	templateUrl: './guest-edit-dialog.component.html',
	styleUrls: ['./guest-edit-dialog.component.scss']
})
export class GuestEditDialogComponent implements OnInit {
	public errorMessage: string = '';
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public returnData: any = { viewPublicChat: false, id: undefined};
	constructor(
		private requestService: RequestService, private translate: TranslateService,
		private layoutUtilsService: LayoutUtilsService, public dialog: MatDialog,
		public dialogRef: MatDialogRef<GuestEditDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		console.log('ViewDialogData', data);
		if (data) {

		}
	}

	ngOnInit() {
		if(this.data.data){
			this.returnData = this.data.data;
		}
	}

	closeModal(data): void {
		this.dialogRef.close(data);
	}
}
