import { Component, Inject, OnInit, ChangeDetectorRef, ElementRef, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService } from '../../../shared/services';
import { DefaultSettings } from '../../../shared/components/layout-components';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AblyService } from 'src/app/shared/services/ably.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmEntityDialogComponent } from '../modals/confirm-entity-dialog/confirm-entity-dialog.component';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';



interface DialogData {
  targetDataType: string;
  targetDataTypeDisplay: string;
  dataType: string;
  title: string;
  data: any;
  filters: any;
  useOrg: boolean;
  showHost: boolean;
  type: string;
  columnData: string;
  confirmData: any;
}


@Component({
  selector: 'session-assign-dialog',
  templateUrl: './session-assign-dialog.component.html',
  styleUrls: ['./session-assign-dialog.component.scss']
})
export class SessionAssignDialogComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public apiCallSubscription: Subscription = undefined;
  public errorMessage: string = '';
  public loading: boolean = false;
  public hasFormErrors: boolean = false;
  public pagination: boolean = true;
  public canSearch: boolean = true;
  public optionData: any = [];
  public selectedData: any = [];
  public excludeList: any[];
  public searchVal: string = '';
  public tableDataTotal: number = 0;
  public interpreterType: any = DefaultSettings.interpreterType;
  public interpreterTypeKeys: any = DefaultSettings.interpreterTypeKeys;

  /* pagination Info */
  public paginatorTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  pageSize = 5;
  pageNumber = 1;
  orderDir = 'asc';
  orderBy = 'name';
  searchText = '';
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private requestService: RequestService, private router: Router,
    private activatedRoute: ActivatedRoute, private ablyService: AblyService,
    private layoutUtilsService: LayoutUtilsService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<SessionAssignDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private translate: TranslateService) {
    //console.log('DialogData', data);
  }

  ngOnInit() {
    let dataList = [];
    let excludeList = [];
    if (this.data.data.hasOwnProperty('users')) {
      for (let dt of this.data.data.users) {
        if (dt.type === this.data.columnData) {
          let hst = false;
          if (dt.host) {
            hst = dt.host;
          }
          let allowStarQuestions = false;
          if (dt.allowStarQuestions) {
            allowStarQuestions = dt.allowStarQuestions;
          }
          let allowPushQuestions = false;
          if (dt.allowPushQuestions) {
            allowPushQuestions = dt.allowPushQuestions;
          }
          let language = dt.language;
          if (!language || language === '') {
            language = 'en';
          }
          dataList.push({ _id: dt._id, text: dt.name, host: hst, allowStarQuestions: allowStarQuestions, allowPushQuestions: allowPushQuestions, language: language, email: dt.email });
        } else {
          excludeList.push(dt._id);
        }
      }
    }

    this.selectedData = dataList;
    this.excludeList = excludeList;
    this.loadDataSearch();
  }
  ngAfterViewInit() {
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      // tslint:disable-next-line:max-line-length
      debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
      distinctUntilChanged(), // This operator will eliminate duplicate values
      tap(() => {
        try {
          let filterByText = this.termConfiguration();
          if (filterByText.length >= 3 || filterByText.length == 0) {
            this.paginator.pageIndex = 0;
            this.loadDataSearch();
          }
        } catch (e) { }
      })
    )
      .subscribe();
    this.subscriptions.push(searchSubscription);
    const paginatorSubscriptions = merge(this.paginator.page).pipe(
      tap(() => {
        this.getTableVariables();
        this.loadDataSearch();
      })
    )
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);
  }

  public setHost(index, val) {
    let selectedData = JSON.parse(JSON.stringify(this.selectedData));
    let idx = 0;
    for (let dt of selectedData) {
      if (idx === index) {
        selectedData[idx]['host'] = val;
      } else {
        selectedData[idx]['host'] = false;
      }
      idx++;
    }
    this.selectedData = selectedData;
  }
  public setLanguage(index, val) {
    let selectedData = JSON.parse(JSON.stringify(this.selectedData));
    let idx = 0;
    for (let dt of selectedData) {
      if (idx === index) {
        selectedData[idx]['language'] = val;
      }
      idx++;
    }
    this.selectedData = selectedData;
  }
  public getTableVariables() {
    this.pageNumber = this.paginator.pageIndex + 1;
    this.pageSize = this.paginator.pageSize;
  }
  public loadData() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getSingleData(this.data.dataType, this.data.data['_id'], (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error: ' + error, 'Dismiss');
        }
        if (data) {
          this.data.data = data.results;
          if (data.results.hasOwnProperty(this.data.columnData)) {
            let dataReturned: any[] = data.results[this.data.columnData];
            // this.selectedData = dataReturned.filter((item) => {
            // 	return !this.requestService.checkListContains(this.optionData, item._id);
            // });
            this.selectedData = dataReturned;
          }
        }
        this.loading = false;
        this.loadDataSearch();
      });
    }
  }
  public loadDataSearch() {
    if (this.loading && this.apiCallSubscription) {
      this.apiCallSubscription.unsubscribe();
      this.loading = false;
    }
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let termConfiguration = this.termConfiguration();
      let filterConfiguration = undefined;
      if (this.data.filters) {
        filterConfiguration = JSON.parse(JSON.stringify(this.data.filters));
      }

      if (this.data.useOrg || this.data.type) {
        if (!filterConfiguration) {
          filterConfiguration = { '$and': [] };
        }
        if (this.data.useOrg)
          filterConfiguration['$and'].push({ 'organizationId': { '$eq': this.requestService.orgId } });
        if (this.data.type)
          filterConfiguration['$and'].push({ 'type': { '$eq': this.data.type } });
      }
      let excludeList = JSON.parse(JSON.stringify(this.excludeList));
      for (let dt of this.selectedData) {
        excludeList.push(dt._id);
      }
      this.apiCallSubscription = this.requestService.getDataListSummary(this.data.targetDataType, { page: this.pageNumber, orderDir: this.orderDir, orderBy: this.orderBy, term: termConfiguration, perpage: this.pageSize, filter: filterConfiguration, exclude: excludeList }, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error: ' + error, 'Dismiss');
        }
        if (data) {
          let dataReturned: any[] = data.results;
          let idx = 0;
          for (let dt of dataReturned) {
            dataReturned[idx]['host'] = false;
            dataReturned[idx]['allowStarQuestions'] = false;
            dataReturned[idx]['allowPushQuestions'] = false;
            dataReturned[idx]['language'] = 'en';
            idx++;
          }
          //console.log('dataReturned', dataReturned);
          if (data.pagination) {
            this.tableDataTotal = data.pagination.total;
            this.paginatorTotal.next(data.pagination.total);
          }
          this.optionData = dataReturned;
          // .filter((item) => {
          // 	return !this.requestService.checkListContains(this.selectedData, item._id);
          // });
        } else {
          this.optionData = [];
        }
        this.loading = false;
      });
    }
  }
  public setAttribute(id, val) {
    this.data.data[id] = val;
  }
  closeModal(): void {
    this.dialogRef.close();
  }
  validateData(): boolean {
    // if(this.data.data.streamMode === 'rtc' && this.data.data.maximumAttendees > 0 ){
    //   let limit = this.data.data.maximumAttendees - this.excludeList.length;
    //   if(limit < 0){
    //     limit = 0;
    //   }
    //   if (this.selectedData.length > limit) {
    //       this.layoutUtilsService.showNotification('There are more attendees assigned to this session than the maximum number allowed. Either select RTC to HLS, or reduce the number of assigned attendees', 'Dismiss');
    //       // this.layoutUtilsService.showNotification('You cannot select more than ' + this.data.limit + ' ' + this.data.targetDataTypeDisplay, 'Dismiss');
    //       return false;
    //   }
    // }
    if (this.data.data.streamMode === 'rtc' && this.data.data.maximumAttendees > 0 && this.data.columnData === 'attendee') {
      let limit = this.data.data.maximumAttendees;
      if (this.selectedData.length > limit) {
        this.layoutUtilsService.showNotification('There are more attendees assigned to this session than the maximum number allowed. Either select RTC to HLS, or reduce the number of assigned attendees', 'Dismiss');
        // this.layoutUtilsService.showNotification('You cannot select more than ' + this.data.limit + ' ' + this.data.targetDataTypeDisplay, 'Dismiss');
        return false;
      }
    }
    if (this.data.columnData === 'lead') {
      let moderators = this.getUsersByType('moderator');
      let selectedHosts = this.selectedData.filter((itm) => {
        if (itm.host) {
          return true;
        }
        return false;
      });
      if (moderators.length === 0) {
        if (selectedHosts.length === 0) {
          this.layoutUtilsService.showNotification(this.translate.instant('You need to assign a host'), 'Dismiss');
          return false;
        }
        if (this.selectedData.length > 1) {
          this.layoutUtilsService.showNotification('You cannot assign more than one ' + this.translate.instant(this.data.columnData) + ' if you have no ' + this.translate.instant('moderator') + ' assigned', 'Dismiss');
          return false;
        }
      }
      if (moderators.length > 0 && selectedHosts.length > 0) {
        this.layoutUtilsService.showNotification(this.translate.instant('You cannot assign a ') + this.translate.instant(this.data.columnData) + ' host when you already have a producer', 'Dismiss');
        return false;
      }
    }
    if (this.data.columnData === 'interpreter') {
      // let selectedLang = [];
      if (this.selectedData.length > 5) {
        this.layoutUtilsService.showNotification(this.translate.instant('You cannot assign more than five interpreters'), 'Dismiss');
        return false;
      }
      for (let dt of this.selectedData) {
        if (dt.language === undefined || dt.language === '') {
          this.layoutUtilsService.showNotification(this.translate.instant('You need to set the language for all interpreters'), 'Dismiss');
          return false;
        }
        // else {
        //   if (selectedLang.includes(dt.language)) {
        //     this.layoutUtilsService.showNotification(this.translate.instant('You need to set each language for only one interpreter'), 'Dismiss');
        //     return false;
        //   } else {
        //     selectedLang.push(dt.language);
        //   }
        // }
      }
    }
    if (this.data.columnData === 'moderator') {
      let selectedHosts = this.selectedData.filter((itm) => {
        if (itm.host) {
          return true;
        }
        return false;
      });
      if (selectedHosts.length === 0) {
        this.layoutUtilsService.showNotification(this.translate.instant('You need to assign a host'), 'Dismiss');
        return false;
      }
      let leads = this.getUsersByType('lead');
      if (this.selectedData.length === 0 && leads.length > 1) {
        this.layoutUtilsService.showNotification('You should assign at least one ' + this.translate.instant(this.data.columnData) + ' when there are multiple ' + this.translate.instant('leads'), 'Dismiss');
        return false;
      }
    }
    return true;
  }
  getUsersByType(type) {
    let dataList = [];
    if (this.data.data.hasOwnProperty('users')) {
      for (let dt of this.data.data.users) {
        if (dt.type === type) {
          dataList.push(dt);
        }
      }
    }
    return dataList;
  }
  saveData(): void {
    if (!this.loading) {
      if (this.validateData()) {

        const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
          disableClose: true,
          data: {
            title: this.translate.instant('Save Layout'),
            data: '',
            description: this.translate.instant('This will save the layout and refresh the pages of everyone in the session. Are you sure you want to proceed?'),
            cancelbtn: this.translate.instant('Cancel'),
            confirmbtn: this.translate.instant('Proceed'),
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.loading = true;
            this.errorMessage = '';
            let dataList = [];
            for (let dt of this.selectedData) {
              dataList.push({ _id: dt._id, name: dt.text, type: this.data.columnData, host: dt.host, allowStarQuestions: dt.allowStarQuestions, allowPushQuestions: dt.allowPushQuestions, language: dt.language });
            }
            let obj = { users: dataList };
            this.requestService.assignUsersToSession(this.data.data._id, this.data.columnData, obj, (data, error) => {
              if (error) {
                this.errorMessage = error;
                this.layoutUtilsService.showNotification('Error: ' + error, 'Dismiss');
              }
              this.loading = false;
              if (data) {
                // this.dialogRef.close(this.selectedData);
                this.ablyService.sendSessionSignal(this.data.data._id, 'forceRefresh', 'true');
                // if(this.data.columnData === 'interpreter'){
                //   for(let intr of data.results.users){
                //     this.requestService.getOpenTokSessionKeyForTranslation(intr._id, this.data.data._id,
                //       (data, error) => {
                //         //do nothing
                //       }
                //     );
                //   }
                // }
                this.dialogRef.close(data.results);

                // setTimeout(() => {
                //   this.custom_metadata_table.refresh();
                // }, 300);
              } else {
                this.layoutUtilsService.showNotification('Something is Wrong', 'Dismiss');
              }
            });
          }
        });


      }
    }
  }
  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }
  /** FILTRATION */
  termConfiguration(): any {
    if (this.searchInput) {
      const searchText: string = this.searchInput.nativeElement.value;
      return searchText;
    }
    return '';
  }
  goToUserPage(): void {
    let currentUserRole = this.requestService.getUserRole();
    if (currentUserRole === 'admin') {
      this.dialogRef.close();
      this.router.navigate(['/admin/users'], { relativeTo: this.activatedRoute });
    } else {
      this.layoutUtilsService.showNotification(this.translate.instant('You do not have admin rights to manage users please contact the event admin'), 'Dismiss');
    }
  }
}
