<div class="col-xl-12">
    <div mat-dialog-title>
        <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
            <div fxFlex class="filterName">
                {{data.title}}
            </div>
        </div>
    </div>
    <div mat-dialog-content>
        <mat-card style="min-height: 100px;">
            <mat-form-field style="width: 100%;">
                <input (focus)="markTouched()" matInput placeholder="{{ 'URL' | translate }}" [formControl]="url">
                <mat-hint>{{'Some website hosts do not allow embedding their links within another page and might display
                    an error message.' | translate}}</mat-hint>
                <mat-error *ngIf="url.hasError('pattern')">
                    {{'URL Pattern Invalid' | translate}}
                </mat-error>
            </mat-form-field>
        </mat-card>
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button (click)="close()" [disabled]="url.hasError('pattern')">{{'Confirm' |
            translate}}</button>
    </div>
    <br>
</div>