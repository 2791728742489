<div class="col-xl-12" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content>
		<div>
      <mat-card style="height: 100%;padding: 16px 10px 3px;min-height: 463px">
				<!-- <app-tiles-list #tileList (selectItem)="selectItemCheck($event)" [hasCategories]="true" [canDelete]="false"></app-tiles-list> -->
				<app-custom-select [value]="data.data.metadata.id" [itemName]="'metadata'" [dataType]="'banner'" [apiTarget]="'searchObject'" [filters]="filterData" [placeholder]="'Billboard'" (onSelectReturn)="selectItemCheck($event)" [termfields]="['name']"></app-custom-select>
      </mat-card>
    </div>
	</div>
	<div mat-dialog-actions>
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
			<button mat-raised-button *ngIf="data.hasAdd" (click)="closeModal({new: true})">{{'Add New Billboard' | translate}}</button>
			<!-- <button mat-raised-button (click)="closeModal({clear: true})">{{'Clear' | translate}}</button> -->
	</div>
	<br/>
</div>
