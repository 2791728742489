import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { Subscription } from "rxjs";
import { RequestService, RoomSessionService, TokBoxService } from "src/app/shared";

@Component({
    selector: 'app-youtube-media',
    templateUrl: './youtube-media.component.html',
    styleUrls: ['./youtube-media.component.scss']
})

export class YoutubeComponent implements OnInit, OnDestroy, AfterViewInit {

    constructor(private renderer: Renderer2, private requestService: RequestService, private opentokService: TokBoxService, private changeDetectorRef: ChangeDetectorRef, private roomSessionService: RoomSessionService) { }

    private subscriptions: Subscription[] = [];

    public video: any = undefined;

    @Input() uuid: string = undefined;
    @Input() zIndex: string = undefined;

    ngOnInit() {
        this.subscriptions.push(this.opentokService.startYoutube.subscribe(video => {
            if (video !== undefined && video.uid === this.uuid) {
                this.startYoutube(video);
            }
        }));

        this.subscriptions.push(this.opentokService.stopYoutube.subscribe(video => {
            if (video !== undefined && video.uid === this.uuid) {
                this.stopYoutube();
            }
        }));

        this.subscriptions.push(this.opentokService.playPauseYoutube.subscribe(video => {
            if (video !== undefined) {
                if (video.play)
                    this.playYoutube(video.uuid);
                else
                    this.pauseYoutube(video.uuid);
            }
        }));

        this.subscriptions.push(this.roomSessionService.changeVolume.subscribe(volume => {
            if (this.video?.player && typeof this.video.player.setVolume === 'function')
                this.video.player.setVolume(volume);
        }));
    }

    ngAfterViewInit(): void {
        this.opentokService.youtubeLoaded.next(true);
    }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    startYoutubePlayer(video) {
        try {
            let element = document.createElement('div');
            this.renderer.setProperty(element, 'id', 'youtube-' + video.uid);
            if (document.getElementById('video-youtube-' + video.uid))
                this.renderer.appendChild(document.getElementById('video-youtube-' + video.uid), element);

            // this.video.element = video;

            video.player = new window['YT'].Player(element, {
                width: '100%',
                height: '100%',
                videoId: video.urlId,
                playerVars: {
                    origin: this.requestService.serverHostUrl,
                    start: video.startAt,
                    enablejsapi: 1,
                    mute: video.autoStart
                },
                events: {
                    'onStateChange': (obj) => {
                        switch (obj.data) {
                            case window['YT'].PlayerState.PLAYING:
                                video.isPlaying = true;
                                break;
                            case window['YT'].PlayerState.PAUSED:
                                video.isPlaying = false;
                                break;
                            case window['YT'].PlayerState.ENDED:
                                if (video.loop && video.player) {
                                    video.player.seekTo(0);
                                    video.player.playVideo();
                                }
                                break;
                        };
                    },
                    'onReady': (e) => {
                        if (video.autoStart && typeof video.player.setVolume === 'function') {
                            video.player.setVolume(this.opentokService.preferredVolume);
                            e.target.playVideo();
                            // e.target.unMute();
                        }
                        if (!video.autoStart)
                            video.player.unMute();
                        this.detectChanges();
                    },
                    'onError': (e) => {
                        // console.log('ee', e)
                    }
                }
            });

            this.video = video;
            this.opentokService.layoutVideoComponents.next({ uuid: video.uid, value: this.video });
        }
        catch (e) { console.error(e) }
    }

    startYoutube(video) {
        if (!(this.video?.isPlaying && video.url === this.video.url)) {
            try {
                if (!window['YT']) {
                    window['YT'].ready(() => {
                        this.startYoutubePlayer(video);
                    });
                }
                else {
                    this.startYoutubePlayer(video);
                }
            }
            catch (e) { console.error(e) }
        }
    }

    stopYoutube() {
        if (this.video) {
            this.video.isPlaying = false;
            try {
                this.video.player.destroy();
            }
            catch (e) { }

            if (document.getElementById('video-youtube-' + this.video.uid))
                document.getElementById('video-youtube-' + this.video.uid).innerHTML = '';

            delete this.video.player;
            this.opentokService.layoutVideoComponents.next({ uuid: this.video.uid, value: this.video });
        }
    }

    playYoutube(uuid) {
        try {
            if (uuid === this.uuid && this.video?.player)
                this.video.player.playVideo();
        }
        catch (e) { console.error(e) }
    }

    pauseYoutube(uuid) {
        try {
            if (uuid === this.uuid && this.video?.player)
                this.video.player.pauseVideo();
        }
        catch (e) { console.error(e) }
    }

    private detectChanges() {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }
}