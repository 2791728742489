import { Injectable, NgZone } from '@angular/core';
import { LayoutUtilsService } from './utils/layout-utils.service';
import { TranslateService } from '@ngx-translate/core';
// import * as RecordRTC from 'recordrtc';

declare var RecordRTC_Extension;

@Injectable()
export class RecorderService {

    private recorder: any;
    recordingStatus: boolean = false;

    constructor(private zone: NgZone, private layoutUtilsService: LayoutUtilsService, private translate: TranslateService) { }

    startRecording() {
        if (typeof RecordRTC_Extension === 'undefined') {
            let alertSetting = {};
            alertSetting['overlayClickToClose'] = false;
            alertSetting['showCancelButton'] = false;
            alertSetting['declineText'] = 'Install';

            this.zone.run(() => {
                const dialog = this.layoutUtilsService.alertActionElement('', this.translate.instant('To use this feature you need to install a Chrome extension.'), alertSetting, 'fit-content');
                dialog.afterClosed().subscribe((res) => {
                    if (res != undefined) {
                        window.open('https://chrome.google.com/webstore/detail/ndcljioonkecdnaaihodjgiliohngojp');
                    }
                    // window.location.reload();
                });
            });
            // alert('RecordRTC chrome extension is either disabled or not installed.');
        }
        else {
            this.recorder = new RecordRTC_Extension();

            this.recorder.startRecording({
                enableTabCaptureAPI: true
            });

            this.recordingStatus = true;
        }
    }

    stopRecording() {
        this.recordingStatus = false;
        this.recorder.stopRecording((blob) => {
            console.log(blob.size, blob);
            // RecordRTC.invokeSaveAsDialog(blob, 'session recording.webm');
            // this.recorder.save('video.webm');
        });
    }

    getRecordingStatus() {
        return this.recordingStatus;
    }
}