import { Injectable, ElementRef, Renderer2, NgZone, ChangeDetectorRef } from '@angular/core';

import * as OT from '@opentok/client';
import { environment } from '../../../environments/environment';
import { LayoutUtilsService } from './utils/layout-utils.service';

import { BehaviorSubject, combineLatest, timer } from 'rxjs';
import { RequestService } from './request.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmEntityDialogComponent } from '../components/modals/confirm-entity-dialog/confirm-entity-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NotificationComponent } from '../components/notification/notification.component';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { DefaultSettings } from '../components/layout-components/defaultSettings';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter, map } from 'rxjs/operators';
import * as moment from 'moment';
import { EventTrack } from './models/event-tracking.model';
import { PageType } from './enums/pageType';
import { EventType } from './enums/eventType';

declare global {
  interface Window { dataLayer: any[]; }
}

@Injectable()
export class TokBoxService {

  userMap: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  userMapDelete: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  zIndexDraggable: BehaviorSubject<number> = new BehaviorSubject<number>(5);
  isOpenBackstage: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  openBackstageOnLoad: boolean = false;
  isAudioVideoBusy: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  isSpotLightBusy: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  userJoinedSession: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  attendeeJoinedSession: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  unmuteDialog: any = undefined;
  connectionHealth: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  currentUserFlags: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  controlThisStream: BehaviorSubject<OT.Stream> = new BehaviorSubject<OT.Stream>(undefined);
  controlGuestJoined: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  muteAll: boolean = false;
  highlightNotifications: BehaviorSubject<any> = new BehaviorSubject<boolean>(undefined);
  highlightQuestions: BehaviorSubject<any> = new BehaviorSubject<boolean>(undefined);
  controlHLS: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  broadcastDelay: number = 20 * 1000; // delay from 15 to 20 seconds for broadcast to be ready
  breakoutGroups: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  byPassCheckBreakout: boolean = false;
  isViewAsAttendeeOn: boolean = false;
  forceHideAttendeeChat: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  isFullScreenModeOn: boolean = false;
  // userJoinedPanel: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  backStageRoomData: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  backStageAssignedSessionUsers: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  backStageInvokeMedia: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  backStageMediaFlags: any = { audio: false, camera: false };
  preferredVolume: number = 100;
  connectedPanelSessions: any = [];
  guestActivity: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  spotLightMembers: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  videoOn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  showPauseDialog: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  isInSession: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  wasHandRaised: boolean = false;
  layoutVideoComponents: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  startYoutube: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  stopYoutube: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  playPauseYoutube: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  playPauseVimeo: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  setVimeoTime: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  startVimeo: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  stopVimeo: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  startHls: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  stopHls: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  playHls: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  startAudio: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  pauseDialog: any = undefined;
  sessionConnected: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  startFacebook: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  stopFacebook: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  incomingExpression: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  layoutCamsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(new Map());
  youtubeLoaded: BehaviorSubject<boolean> = new BehaviorSubject<any>(undefined);
  facebookLoaded: BehaviorSubject<boolean> = new BehaviorSubject<any>(undefined);
  vimeoLoaded: BehaviorSubject<boolean> = new BehaviorSubject<any>(undefined);
  hlsLoaded: BehaviorSubject<boolean> = new BehaviorSubject<any>(undefined);
  tileQuestionsAnswersLoaded: BehaviorSubject<boolean> = new BehaviorSubject<any>(undefined);
  floatingControlLoaded: BehaviorSubject<boolean> = new BehaviorSubject<any>(undefined);
  // allComponentsLoaded = combineLatest([this.youtubeLoaded.asObservable(), this.facebookLoaded.asObservable(), this.vimeoLoaded.asObservable(), this.hlsLoaded.asObservable(), this.tileQuestionsAnswersLoaded.asObservable(), this.floatingControlLoaded.asObservable()]).pipe(map(componentsLoaded => componentsLoaded.every(loaded => loaded)), filter(loaded => loaded === true));
  audioVideoSettingsChanged: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  playAfterPauseMedia: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  playAfterPauseMediaLibrary: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  openChatWith: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);

  private logStartDateTime: any = undefined;

  constructor(private layoutService: LayoutUtilsService, private requestService: RequestService, private dialog: MatDialog, private translate: TranslateService, private zone: NgZone, private router: Router, public _bottomSheet: MatBottomSheet, private deviceService: DeviceDetectorService) { }

  getOT(): any {
    if (environment.environment === 'prod')
      OT.setLogLevel(0);
    return OT;
  }

  initSession(sessionId: string) {
    //session is a room
    //console.log(sessionId)
    if (environment.tokBox.apiKey) {
      let session = this.getOT().initSession(environment.tokBox.apiKey, sessionId);
      return Promise.resolve(session);
    }
  }

  connect(token: string, session: OT.Session) {
    //console.log(token);
    return new Promise((resolve, reject) => {
      session.connect(token, (error) => {
        if (error) {
          console.log(error, session)
          this.handleError(error);
          //reject(error);
        } else {
          resolve(session);
        }
      });
    });
  }

  public handleError(errorCode: Object) {
    //console.log(errorCode);
    switch (errorCode['name']) {
      case 'OT_AUTHENTICATION_ERROR':
        if (errorCode['code'] == 1004) {
          this.layoutService.showNotificationSnack('Authentication error. Refresh the page.', 'Dismiss');
        }
        else {
          this.layoutService.showNotificationSnack('Authentication error.', 'Dismiss');
        }
        break;
      case 'OT_SCREEN_SHARING_NOT_SUPPORTED':
        this.layoutService.showNotificationSnack('Unable to share screen.', 'Dismiss');
        break;
      case 'OT_CREATE_PEER_CONNECTION_FAILED':
        this.layoutService.showNotificationSnack('Client unable to join session.', 'Dismiss');
        break;
      case 'OT_SCREEN_SHARING_NOT_SUPPORTED':
        this.layoutService.showNotificationSnack('Screen sharing is not supported.', 'Dismiss');
        break;
      case 'OT_SCREEN_SHARING_EXTENSION_NOT_INSTALLED':
      case 'OT_SCREEN_SHARING_EXTENSION_NOT_REGISTERED':
        this.layoutService.showNotificationSnack('Screen sharing requires a type extension, but it is not installed.', 'Dismiss');
        break;
      case 'OT_USER_MEDIA_ACCESS_DENIED':
        this.layoutService.showNotificationSnack('Allow access and try publishing again.', 'Dismiss');
        break;
      case 'OT_NO_DEVICES_FOUND':
        this.layoutService.showNotificationSnack('You do not have a microphone attached to your computer.', 'Dismiss');
        break;
      case 'OT_NOT_CONNECTED':
        this.layoutService.showNotificationSnack('Session is not connected yet.', 'Dismiss');
        break;
      case 'OT_SOCKET_CLOSE_TIMEOUT':
      case 'OT_UNEXPECTED_SERVER_RESPONSE':
        this.layoutService.showNotificationSnack('No connection. Reloading the page.', 'Dismiss');
        setTimeout(() => window.location.reload(), 5000);
        break;
      case 'OT_HARDWARE_UNAVAILABLE':
        this.layoutService.showNotificationSnack('Audio/Video hardware is not available.', 'Dismiss');
        break;
      case 'OT_STREAM_DESTROYED':
        break;
      default:
        this.handleErrorByCode(errorCode);
        break;
    }
  }

  public handleErrorByCode(errorCode: Object) {
    switch (errorCode['code'] + '') {
      case '1013':
        this.layoutService.showNotificationSnack('Blocked by firewall', 'Dismiss');
        break;
      case '1004':
        this.layoutService.showNotificationSnack('Authentication error, Token is invalid. ', 'Dismiss');

        break;
      case '1005':
        this.layoutService.showNotificationSnack('Authentication error, Session is invalid.', 'Dismiss');
        break;
      case '1006':
        this.layoutService.showNotificationSnack('Connection failed, check if you have internet connection.', 'Dismiss');
        break;
      case '1010':
        this.layoutService.showNotificationSnack('Cannot publish: the client is not connected to the session.', 'Dismiss');
        break;
      case '1500':
        this.layoutService.showNotificationSnack('Unable to Publish.', 'Dismiss');
        break;
      case '1501':
        this.layoutService.showNotificationSnack('Slow internet connection.', 'Dismiss');
        break;
      case '1510':
        this.layoutService.showNotificationSnack('Unable to send message.', 'Dismiss');
        break;
      case '1004':
        this.layoutService.showNotificationSnack('Authentication error. Check token expiry.', 'Dismiss');
        break;
      default:
        // this.layoutService.showNotificationSnack('Slow connection.', 'Dismiss');
        break;
    }
  }

  public sendText(message: string, session: OT.Session) {
    if (message && message.trim() != '' && session) {
      session.signal(
        {
          data: message,
          type: 'textMessage'
        },
        function (error) {
          if (error) {
            //console.log("signal error ("
            // + error.name
            //   + "): " + error.message);
          } else {
            //message = '';
            //console.log("signal sent.");
          }
        }
      );
    }
  }

  public hashCode(str) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  // public intToRGB(i) {
  //   var c = (i & 0x00FFFFFF)
  //     .toString(16)
  //     .toUpperCase();

  //   return "00000".substring(0, 6 - c.length) + c;
  // }

  public colorLuminance(hex, lum) {

    // validate hex string
    hex = String(hex).replace(/[^0-9a-f]/gi, '');
    if (hex.length < 6) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    lum = lum || 0;

    // convert to decimal and change luminosity
    var rgb = "#", c, i;
    for (i = 0; i < 3; i++) {
      c = parseInt(hex.substr(i * 2, 2), 16);
      c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
      rgb += ("00" + c).substr(c.length);
    }

    return rgb;
  }

  public dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/jpeg' });
    return blob;
  }

  public sendSignal(type: string, message: string, currentSession: OT.Session, recepientConnection?: OT.Connection) {
    if (message && message.trim() != '' && currentSession && currentSession['isConnected']()) {
      let signalData;
      if (recepientConnection) {
        signalData = {
          to: recepientConnection,
          data: message,
          type: type
        }
      }
      else {
        signalData = {
          data: message,
          type: type
        }
      }
      return new Promise((resolve, reject) => currentSession.signal(signalData, (error) => {
        if (error) {
          reject();
        } else {
          resolve(true);
        }
      }));
    }
  }


  public unPublishScreenAudioAndCamera(session: OT.Session, screenPublisher: OT.Publisher, mediaPublisher?: OT.Publisher) {
    if (session) {
      if (screenPublisher) {
        session.unpublish(screenPublisher);
      }
      if (mediaPublisher) {
        session.unpublish(mediaPublisher);
      }
    }
  }

  public unPublishPublisher(session: OT.Session, publisher: OT.Publisher): any {
    if (session) {
      if (publisher) {
        session.unpublish(publisher);
      }
    }
    return null;
  }

  public disconnectSession(session: OT.Session) {
    try {
      if (session) {
        session.off();
        session.disconnect();
      }
    }
    catch (e) {

    }
  }

  public unSubscribeScreenAudioAndCamera(subscribers: Array<OT.Subscriber>, session: OT.Session) {
    if (session) {
      for (let streamId in subscribers) {
        //console.log('unsubscribing now', streamId, subscribers[streamId])
        if (subscribers[streamId]['session'] && subscribers[streamId]['session'].hasOwnProperty('id') && session.sessionId === subscribers[streamId]['session'].id) {
          session.unsubscribe(subscribers[streamId]);
          subscribers[streamId].off();
        }
      }
    }
  }

  public unSubscribe(subscriber: OT.Subscriber, session: OT.Session) {
    try {
      if (subscriber && subscriber.stream) {
        session.unsubscribe(subscriber);
        subscriber.off();
      }
    }
    catch (e) {
      // console.log('while unsubscribing', subscriber)
    }
  }

  speakerDetection(subscriber, startTalking, stopTalking) {
    let activity = null;
    if (subscriber)
      subscriber.on('audioLevelUpdated', (event) => {
        let now = Date.now();
        if (event.audioLevel > 0.05) {
          // console.log('event.audioLevel', event.audioLevel)
          if (!activity) {
            activity = { timestamp: now, talking: false };
          } else if (activity.talking) {
            activity.timestamp = now;
          } else if (now - activity.timestamp > 500) {
            // detected audio activity for more than 0.5s
            // for the first time.
            activity.talking = true;
            if (typeof (startTalking) === 'function') {
              startTalking(event);
            }
          }
        } else if (activity && now - activity.timestamp > 3000) {
          // detected low audio activity for more than 3s
          if (activity.talking) {
            if (typeof (stopTalking) === 'function') {
              stopTalking(event);
            }
          }
          activity = null;
        }
      });
  };

  dynamicSort(property) {
    var sortOrder = 1;

    if (property[0] === "-") {
      sortOrder = -1;
      property = property.substr(1);
    }

    return (a, b) => {
      if (sortOrder == -1) {
        return b[property].localeCompare(a[property]);
      } else {
        return a[property].localeCompare(b[property]);
      }
    }
  }

  deleteEntryFromMap(map: Map<any, any>, toDelete: string, renderer: Renderer2) {
    this.removeOverlayName(toDelete, renderer);

    Object.keys(map).forEach((key) => {
      if (key == toDelete)
        delete map[key];
    });
  }

  removeOverlayName(toDelete: string, renderer: Renderer2) {
    document.querySelectorAll('[data-id*="' + toDelete + '"').forEach(element => {
      renderer.removeChild(document.body, element);
    });
  }

  switchDisplay(user: object, element: any, renderer: Renderer2) {
    element.querySelectorAll('[data-type="media"]').forEach(element => {
      renderer.setStyle(element, 'display', 'none');
    });
    element.querySelectorAll('[data-id^="name-"]').forEach(element => {
      renderer.setStyle(element, 'display', 'none');
    });

    if (user['streams']['media']) {
      let elem = element.querySelector('[data-id="' + user['streams']['media']['streamId'] + '"]');
      if (elem)
        renderer.removeStyle(elem, 'display');
      if (user['streams']['media'].hasVideo) {
        elem = element.querySelector('[data-id="name-' + user['streams']['media']['streamId'] + '-corner"]');
        if (elem)
          renderer.removeStyle(elem, 'display');
      }
      else {
        elem = element.querySelector('[data-id="name-' + user['streams']['media']['streamId'] + '"]');
        if (elem)
          renderer.removeStyle(elem, 'display');
      }
    }
  }

  getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  generateRandomId(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  private streamChangedUpdateUI(event: any, renderer: Renderer2, userObj: any) {
    let stream: OT.Stream = event.stream;

    if (event.changedProperty == 'hasVideo') {
      if (event.newValue) {
        let element = document.querySelector('[data-id="name-' + stream.streamId + '"]');
        if (element)
          renderer.setStyle(element, 'display', 'none');
        element = document.querySelector('[data-id="name-' + stream.streamId + '-corner"]');
        if (element)
          renderer.removeAttribute(element, 'style');
      }
      else {
        let element = document.querySelector('[data-id="name-' + stream.streamId + '-corner"]');
        if (element)
          renderer.setStyle(element, 'display', 'none');
        element = document.querySelector('[data-id="name-' + stream.streamId + '"]');
        if (element)
          renderer.removeAttribute(element, 'style');
      }

      return event.stream.hasAudio;

    }
    else if (event.changedProperty == 'hasAudio' && userObj) {
      return event.stream.hasAudio;
    }
  }

  streamChanged(event: any, renderer: Renderer2, attendeeMap: any) {
    let data = JSON.parse(event.stream.connection.data);
    let userObj = attendeeMap.get(data['userId']);

    if (userObj) {
      userObj['audioOnOff'] = event.stream.hasAudio;
      // userObj['audioOnOff'] = this.streamChangedUpdateUI(event, renderer, userObj);

      attendeeMap.set(data['userId'], userObj);
      this.userMap.next(userObj);
    }
  }

  // streamChangedinAudienceView(event: any, renderer: Renderer2, attendeeMap: any) {
  //   let data = JSON.parse(event.stream.connection.data);

  //   this.streamChangedUpdateUI(event, renderer, attendeeMap.get(data['userId']));
  // }

  subscribeToMedia(stream: OT.Stream, container: any, session: OT.Session, renderer: Renderer2, retryPeriod: number, cameraResolution, insertMode: any, callBack?: () => void, subscribetoCamera?: boolean, firstUser?: boolean, hideOverlay?: boolean, errorCallBack?: (error) => void, subscribeToAudio?: boolean, testNetwork?: boolean, preferredVolume: any = this.preferredVolume): OT.Subscriber {
    //console.log('camera stream', stream);
    let data = JSON.parse(stream.connection.data);
    // renderer.setProperty(container.nativeElement, 'innerHTML', '');

    let element = renderer.createElement('div');
    renderer.setStyle(element, 'height', '100%');
    renderer.setStyle(element, 'width', '100%');
    renderer.setAttribute(element, 'data-type', 'media');
    renderer.setAttribute(element, 'data-id', stream.streamId);
    renderer.addClass(element, 'media-container');
    //console.log('first user', firstUser)
    if (!firstUser) {
      renderer.setStyle(element, 'display', 'none');
    }

    let overlayElement, nameElement;
    if (!hideOverlay) {
      // overlayElement = renderer.createElement('div');
      // renderer.setAttribute(overlayElement, 'class', 'name-overlay-container');
      // renderer.setAttribute(overlayElement, 'data-id', 'name-' + stream.streamId);
      // nameElement = renderer.createElement('div');
      // renderer.setProperty(nameElement, 'innerHTML', data['name']);
      // if (stream.hasVideo || !firstUser) {
      //   renderer.setStyle(overlayElement, 'display', 'none');
      // }
      // overlayElement.append(nameElement);
      // container.prepend(overlayElement);

      overlayElement = renderer.createElement('div');
      renderer.setAttribute(overlayElement, 'class', 'name-overlay-container-corner');
      renderer.setAttribute(overlayElement, 'data-id', 'name-' + stream.streamId + '-corner');
      nameElement = renderer.createElement('div');
      renderer.setProperty(nameElement, 'innerHTML', data['name']);

      if (!firstUser) {
        renderer.setStyle(overlayElement, 'display', 'none');
      }
      overlayElement.append(nameElement);
      container.prepend(overlayElement);
    }

    container.append(element);

    let userImage = '';
    if (data.hasOwnProperty('userImage') && data['userImage']) {
      userImage = data['userImage'];
    }
    else {
      userImage = this.requestService.serverHostUrl + '/assets/images/defaultattendee.png';
    }

    // let preferredVolume = this.preferredVolume ? this.preferredVolume : 100;

    if (subscribeToAudio == undefined)
      subscribeToAudio = true;

    if (testNetwork == undefined)
      testNetwork = false;

    let timer;
    let subscriber: OT.Subscriber;
    let properties: OT.SubscriberProperties = { width: '100%', height: '100%', insertMode: insertMode, style: { backgroundImageURI: userImage, nameDisplayMode: 'off', buttonDisplayMode: 'off', audioLevelDisplayMode: 'off', audioBlockedDisplayMode: 'off', videoDisabledDisplayMode: 'off' }, subscribeToAudio: subscribeToAudio, subscribeToVideo: subscribetoCamera, testNetwork: testNetwork };

    if (subscribeToAudio)
      properties.audioVolume = preferredVolume;

    subscriber = session.subscribe(stream, element, properties, (error) => {
      //preferredResolution: this.cameraResolution
      if (error) {
        if (errorCallBack && error && error.hasOwnProperty('name') && error.name === 'OT_STREAM_LIMIT_EXCEEDED')
          errorCallBack(error);
        if (overlayElement)
          renderer.removeChild(document.body, overlayElement);
        if (nameElement)
          renderer.removeChild(document.body, nameElement);
        this.handleError(error);
        if (error['code'] == 1501) {
          // setTimeout(() => window.location.reload(), 2000);
          timer = setTimeout(() => this.subscribeToMedia(stream, container, session, renderer, retryPeriod, cameraResolution, insertMode), retryPeriod);
        }
      }
      else {
        // let audioCtx = new AudioContext();
        // audioCtx.resume();
        clearTimeout(timer);
        if (callBack) {
          callBack();
        }
      }
    });
    if (subscriber) {
      // subscriber.restrictFrameRate(true);
      subscriber.on("videoDisabled", (event) => {
        // console.log('video is disabled', event);
        if (event.reason === 'quality')
          this.connectionHealth.next(0);
        // this.videoDisabled();
      });
      subscriber.on("videoDisableWarning", (event) => {
        // console.log('video warning');
        this.connectionHealth.next(1);
        // this.videoWarning(subscriber, { width: 320, height: 240 });
      });
      subscriber.on("videoDisableWarningLifted", (event) => {
        // console.log("video warning lifted");
        this.connectionHealth.next(2);
      });
      subscriber.on("videoEnabled", (event) => {
        // console.log("video enabled");
        if (event.reason === 'quality')
          this.connectionHealth.next(2);
      });
    }
    return subscriber;
  }

  showBottomDialog(data: string) {
    this.zone.run(() => {
      if (typeof data == 'string')
        this._bottomSheet.open(NotificationComponent, { data: { title: 'Announcement', content: data, closeButton: { showCloseButton: true, label: 'Close' } }, disableClose: true });
      else {
        let link = '';
        if (data['link']) {
          link = '/rooms/' + data['link']['roomId'] + '/sessions/' + data['link']['sessionId'];
        }
        this._bottomSheet.open(NotificationComponent, { data: { title: 'Announcement', content: data['message'], closeButton: { showCloseButton: true, label: 'Close' }, secondButton: { label: 'Teleport Me', link: link } }, disableClose: true });
      }
    });
    // if (typeof data == 'string')
    //   this.showNotificationDialog(data, '');
    // else {
    //   let link = '';
    //   if (data['link']) {
    //     link = '/#/rooms/' + data['link']['roomId'] + '/sessions/' + data['link']['sessionId'];
    //   }
    //   this.showNotificationDialog(data['message'], link);
    // }
  }

  showNotificationDialog(message: string, link: string) {
    this.zone.run(() => {
      let dataObj = {
        title: 'Announcement',
        data: '',
        description: message,
        cancelbtn: this.translate.instant('Close')
      };
      if (link) {
        dataObj['confirmbtn'] = this.translate.instant('Teleport me');
      }
      const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
        disableClose: true,
        data: dataObj,
        width: '40vw'
      });
      if (link)
        dialogRef.afterClosed().subscribe(result => {
          if (result !== undefined) {
            this.router.navigateByUrl('/loading', { skipLocationChange: true }).then(() =>
              this.router.navigate([link]));
          }
        });
    });
  }

  switchToNextUser(container: ElementRef, attendeeMap: any, renderer: Renderer2, subscribers: any, currentUserId: string): string {
    //only if attendee
    // let userRole = this.requestService.getSessionRoleByUser(this.sessionData, userId);
    let switchToThisUser = null;
    let switchToThisUserId = null;
    attendeeMap.forEach((attendee: object, id: string) => {
      if (attendee['streams']['media'] && !switchToThisUser && (attendee['role'] === 'attendee' || attendee['role'] === 'anonymous')) {
        switchToThisUser = attendee;
        switchToThisUserId = id;
      }
    });
    let speakingNow = undefined;
    if (switchToThisUser) {
      if (switchToThisUser['streams']['media']) { // if who is speaking has a camera switched on
        // obj.subscribeToMedia(switchToThisUser['streams']['media'], this.publisherCamera_component.publisherCameraDiv, true, () => {
        speakingNow = switchToThisUserId;
        if (attendeeMap.has(speakingNow)) {
          let userTurnOn = attendeeMap.get(speakingNow);
          if (userTurnOn && speakingNow != currentUserId && subscribers[userTurnOn['streams']['media']['streamId']])
            subscribers[userTurnOn['streams']['media']['streamId']].subscribeToVideo(true);
          // this.switchDisplay(userTurnOn, container.nativeElement, renderer);
        }
        // });
      }
      else {
        speakingNow = switchToThisUserId;
      }
    }
    else {
      speakingNow = undefined;
    }

    return speakingNow;
  }

  switchToNextGuest(container: ElementRef, attendeeMap: any, renderer: Renderer2, subscribers: any, currentUserId: string): string {
    //only if attendee
    // let userRole = this.requestService.getSessionRoleByUser(this.sessionData, userId);
    let switchToThisUser = null;
    let switchToThisUserId = null;
    attendeeMap.forEach((attendee: object, id: string) => {
      if (attendee['streams']['media'] && !switchToThisUser && attendee['role'] === 'guest') {
        switchToThisUser = attendee;
        switchToThisUserId = id;
      }
    });
    let speakingNow = undefined;
    if (switchToThisUser) {
      if (switchToThisUser['streams']['media']) { // if who is speaking has a camera switched on
        // obj.subscribeToMedia(switchToThisUser['streams']['media'], this.publisherCamera_component.publisherCameraDiv, true, () => {
        speakingNow = switchToThisUserId;
        if (attendeeMap.has(speakingNow)) {
          let userTurnOn = attendeeMap.get(speakingNow);
          if (userTurnOn && speakingNow != currentUserId && subscribers[userTurnOn['streams']['media']['streamId']])
            subscribers[userTurnOn['streams']['media']['streamId']].subscribeToVideo(true);
          // this.switchDisplay(userTurnOn, container.nativeElement, renderer);
        }
        // });
      }
      else {
        speakingNow = switchToThisUserId;
      }
    }
    else {
      speakingNow = undefined;
    }

    return speakingNow;
  }

  extractYoutubeVideoId(videoUrl: string): string {
    let id;
    if (videoUrl) {
      let url = videoUrl.replace(/(>|<)/gi, '').split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
      if (url[2] !== undefined) {
        id = url[2].split(/[^0-9a-z_\-]/i);
        id = id[0];
      }
      else {
        id = url;
      }
    }
    return id;
  }

  extractVimeoVideoId(videoUrl: string): string {
    let regExp = /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i;

    if (videoUrl) {
      let match = videoUrl.match(regExp);

      if (match.length >= 2) {
        return match[1];
      }
      else {
        return null;
      }
    }
    return null;
  }

  cleanTime(player) {
    if (player)
      return Math.round(player.getCurrentTime())
    return null;
  }

  time_convert(num) {
    let sec_num = parseInt(num, 10);
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - (hours * 3600)) / 60);
    let seconds = sec_num - (hours * 3600) - (minutes * 60);

    let secondsSt, minutesSt, hoursSt;
    if (hours < 10) {
      hoursSt = "0" + hours;
    }
    else
      hoursSt = hours;
    if (minutes < 10) {
      minutesSt = "0" + minutes;
    }
    else
      minutesSt = minutes;
    if (seconds < 10) {
      secondsSt = "0" + seconds;
    }
    else
      secondsSt = seconds;
    // debugger;
    return hoursSt + 'h' + minutesSt + 'm' + secondsSt + 's';
  }

  formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  videoWarning(subscriber: OT.Subscriber, preferredResolution) {
    // subscriber.setPreferredResolution(preferredResolution);
    this.layoutService.showNotificationSnack(this.translate.instant('Low internet connection detected - the video may be disabled'), this.translate.instant('Dismiss'));
  }

  videoDisabled() {
    this.layoutService.showNotificationSnack(this.translate.instant('The video was disabled due to a bad internet connection'), this.translate.instant('Dismiss'));
  }

  // videoWarningLifted(){
  //   this.layoutService.showNotificationSnack(this.translate.instant('Due to low internet bandwidth, the video will be disabled'), this.translate.instant('Dismiss'));
  // }
  // showUnBlockAudioDialog() {
  //   if (!this.unmuteDialog) {
  //     this.zone.run(() => {
  //       let alertSetting = {};
  //       alertSetting['icon'] = 'campaign';
  //       alertSetting['overlayClickToClose'] = false;
  //       alertSetting['showCloseButton'] = false;
  //       alertSetting['confirmText'] = 'OK';
  //       alertSetting['buttonColor'] = 'primary';

  //       this.unmuteDialog = this.layoutService.alertActionElement('', this.translate.instant("Please unmute your computer audio or your browser tab"), alertSetting);
  //       this.unmuteDialog.afterClosed().subscribe(res => {
  //         if (res) {
  //           OT.unblockAudio();
  //           this.unmuteDialog = undefined;
  //         }
  //       });
  //     });
  //   }
  // }

  getCounter(tick) {
    return timer(0, tick);
  }

  getVideoCallCounter(tick) {
    return timer(0, tick);
  }

  setLayoutSettings(sessionData, isSessionActive, relativeToWidth?: number, userRole?: string) {
    let layoutSettings = undefined;
    // let questionsElement: any = { disable: true, backgroundColor: undefined, height: undefined };
    // let chatElement: any = { disable: true, backgroundColor: undefined, height: undefined };
    // let hasCamera = false;
    // let guestNames: any = [];

    if (sessionData && sessionData.settings.hasOwnProperty('version') && sessionData.settings['version'] === DefaultSettings.defaultSetting.version) {
      if (!isSessionActive && sessionData.settings.hasOwnProperty('desktop-offline'))
        layoutSettings = sessionData.settings['desktop-offline'];
      else if (sessionData.streamMode === 'hls') {
        if ((userRole === 'lead' || userRole === 'guest' || userRole === 'moderator') && sessionData.settings.hasOwnProperty('desktop') && sessionData.settings.desktop) {
          layoutSettings = sessionData.settings['desktop'];
        }
        else {
          if (sessionData.settings.hasOwnProperty('desktop-hls') && sessionData.settings['desktop-hls'])
            layoutSettings = sessionData.settings['desktop-hls'];
        }
      }
      else if (sessionData.settings.hasOwnProperty('desktop') && sessionData.settings.desktop)
        layoutSettings = sessionData.settings.desktop;

      if (layoutSettings) {
        // if (layoutSettings.hasOwnProperty('optionSettings')) {
        //   // if (layoutSettings.optionSettings.hasOwnProperty('enableAskQuestions') && layoutSettings.optionSettings.enableAskQuestions) {
        //   //   questionsElement.disable = false;
        //   // }
        //   // if (layoutSettings.optionSettings.hasOwnProperty('enableChat') && layoutSettings.optionSettings.enableChat) {
        //   //   chatElement.disable = false;
        //   // }
        // }

        if (layoutSettings.hasOwnProperty('columns')) {
          layoutSettings['columns'].forEach(element => {
            element.components.forEach(obj => {
              if (relativeToWidth) {
                obj.w = obj.w * relativeToWidth / document.body.offsetWidth;
              }
              // else if (obj.name === 'defaultvideo' && obj.hasOwnProperty('autoStart') && obj.autoStart && obj.active) {
              //   if (obj.url.indexOf('?') == -1)
              //     obj.url += '?';
              //   obj.url = obj.url + '&autoplay=1&mute=1';
              // }
            });
          });
        }
      }
    }
    return { columns: layoutSettings['columns'], groups: layoutSettings.groups, optionSettings: layoutSettings.optionSettings, shortcuts: layoutSettings.shortcuts };
  }

  // toggleMuteAll(mute: boolean, showHandOnMute: boolean = true, attendeeMap: any, selectedUser: any, session: OT.Session, changeDetectorRef: ChangeDetectorRef, sendSignal: boolean = true, exclude?: any) {
  //   attendeeMap.forEach((attendee: object, id: string) => {
  //     if (id != selectedUser['_id']) {
  //       if (showHandOnMute)
  //         attendee['requestToShareVideo'] = false;
  //       attendee['requestToSpeak'] = mute;
  //       attendeeMap.set(id, attendee);
  //     }
  //   });
  //   if (sendSignal)
  //     this.sendSignal('disableSpeak', JSON.stringify({ enable: mute + '', exclude: exclude }), session);
  //   this.autoToggleMuteAll(attendeeMap, selectedUser);
  //   this.detectChanges(changeDetectorRef);

  // }

  private detectChanges(changeDetectorRef) {
    if (!changeDetectorRef['destroyed']) {
      changeDetectorRef.detectChanges();
    }
  }

  // autoToggleMuteAll(attendeeMap: any, selectedUser: any) {
  //   let findIfAnyOneMuted = false;
  //   attendeeMap.forEach((value: object, key: string) => {
  //     if (key != selectedUser['_id'] && value['requestToSpeak'] && (value['role'] === 'attendee' || value['role'] === 'anonymous')) {
  //       findIfAnyOneMuted = true;
  //     }
  //   });
  //   if (findIfAnyOneMuted) {
  //     this.muteAll = true;
  //   }
  //   else {
  //     this.muteAll = false;
  //   }
  // }

  disableAttendeePresentationPermission(presenterId: string, attendeeMap: any, session: OT.Session) {
    let presenter = attendeeMap.get(presenterId);
    if (presenter?.streams?.screen) {
      session.forceUnpublish(presenter.streams.screen, (error) => {
        if (!error) {
          presenter['presenting'] = false;
          presenter['requestToPresent'] = false;
          attendeeMap.set(presenterId, presenter);
          this.userMap.next(presenter);
        }
      });
    }
    // let attendeeId = attendee['attendeeId'];
    // let bool = attendee['bool'];
    // let currentMap = attendeeMap.get(attendeeId);
    // if (!bool) {
    //   currentMap['presenting'] = false;
    //   currentMap['requestToPresent'] = false;
    //   attendeeMap.set(attendeeId, currentMap);

    //   this.sendSignal('requestStopPresenting', '1', session, currentMap['connection']);
    // }
  }

  // permitUserSpeak(muteObj: object, attendeeMap: any, session: OT.Session, allowOnlyOneToSpeak: boolean, selectedUser: any, changeDetectorRef: ChangeDetectorRef) {
  //   let userId = muteObj['studentId'];
  //   let mute = muteObj['mute'];
  //   let currentAttendee = attendeeMap.get(userId);
  //   if (currentAttendee) {
  //     //console.log('sending signal', currentAttendee)
  //     if (!mute) {
  //       this.sendSignal('requestShareVideoResponse', '0', session, currentAttendee['connection'])
  //     }

  //     if (mute)
  //       attendeeMap.forEach((value: any, key: string) => {
  //         if (value['requestToSpeak'] && (value['role'] === 'attendee' || value['role'] === 'anonymous')) {
  //           session.forceUnpublish(value.streams.media, (error) => {

  //           });
  //         }
  //       });

  //     // else if (allowOnlyOneToSpeak)
  //     //   this.toggleMuteAll(false, true, attendeeMap, selectedUser, session, changeDetectorRef, true, userId);

  //     this.sendSignal('disableSpeak', JSON.stringify({ enable: mute + '' }), session, currentAttendee['connection']).then(() => {
  //       currentAttendee['requestToSpeak'] = mute;
  //       currentAttendee['requestToShareVideo'] = false;
  //       attendeeMap.set(userId, currentAttendee);

  //       this.autoToggleMuteAll(attendeeMap, selectedUser);
  //       this.detectChanges(changeDetectorRef);
  //     });
  //   }
  // }

  attendeeMuted(muteObj: object, attendeeMap: any, selectedUser: any, session: OT.Session, allowOnlyOneToSpeak: boolean, changeDetectorRef: ChangeDetectorRef, callBack?: any) {
    let userId = muteObj['attendeeId'];
    let mute = muteObj['mute'];
    let currentAttendee = attendeeMap.get(userId);

    // if (mute && allowOnlyOneToSpeak)
    //   this.toggleMuteAll(false, true, attendeeMap, selectedUser, session, changeDetectorRef, true, userId);

    this.sendSignal('muteAttendee', JSON.stringify({ mute: mute, attendeeId: userId }), session).then(() => {
      // currentAttendee['audioOnOff'] = mute;
      if (mute)
        currentAttendee['requestToShareVideo'] = false;
      currentAttendee['requestToSpeak'] = mute;
      attendeeMap.set(userId, currentAttendee);
      this.userMap.next(currentAttendee);

      // this.autoToggleMuteAll(attendeeMap, selectedUser);
      this.detectChanges(changeDetectorRef);

      if (callBack)
        callBack();
    });
  }

  respondToPresentationRequest(attendee: object, attendeeMap: any, session: OT.Session, selectedUser: any, presenterId: string, allowOnlyOneToSpeak: boolean, changeDetectorRef: ChangeDetectorRef) {
    let attendeeId = attendee['attendeeId'];
    let bool = attendee['bool'];
    let currentAttendee = attendeeMap.get(attendeeId);
    if (bool) {
      // if (allowOnlyOneToSpeak)
      //   this.toggleMuteAll(false, false, attendeeMap, selectedUser, session, changeDetectorRef, true, attendeeId);

      if (presenterId && presenterId != selectedUser['_id']) {
        this.disableAttendeePresentationPermission(presenterId, attendeeMap, session);
      }

      currentAttendee['requestToPresent'] = false;
      currentAttendee['requestToShareVideo'] = false;
      currentAttendee['requestToSpeak'] = true;
      this.sendSignal('requestPresenterRoleResponse', '1', session, currentAttendee['connection']);
    }
    else {
      this.sendSignal('requestPresenterRoleResponse', '0', session, currentAttendee['connection']);
      currentAttendee['requestToPresent'] = false;
      currentAttendee['presenting'] = false;
    }
    attendeeMap.set(attendeeId, currentAttendee);
    this.userMap.next(currentAttendee);
    this.detectChanges(changeDetectorRef);
  }

  requestPresenterRoleSignal(event: any, attendeeMap: any, changeDetectorRef: ChangeDetectorRef) {
    let data = JSON.parse(event['from']['data']);
    if (event['data'] == '1') {
      if (attendeeMap.has(data['userId'])) {
        let currentAttendee = attendeeMap.get(data['userId']);
        currentAttendee['requestToPresent'] = true;
        currentAttendee['requestToShareVideo'] = false;
        attendeeMap.set(data['userId'], currentAttendee);
        this.userMap.next(currentAttendee);
      }
    }
    else {
      if (attendeeMap.has(data['userId'])) {
        let currentAttendee = attendeeMap.get(data['userId']);
        currentAttendee['requestToPresent'] = false;
        attendeeMap.set(data['userId'], currentAttendee);
        this.userMap.next(currentAttendee);
      }
    }
    this.detectChanges(changeDetectorRef);
  }

  requestPermissionToShareVideoSignal(event: any, attendeeMap: any, changeDetectorRef: ChangeDetectorRef) {
    let data = JSON.parse(event['from']['data']);
    if (event['data'] == '1') {
      if (attendeeMap.has(data['userId'])) {
        let currentAttendee = attendeeMap.get(data['userId']);
        currentAttendee['requestToShareVideo'] = true;
        // currentAttendee['requestToSpeak'] = false;
        currentAttendee['requestToPresent'] = false;
        attendeeMap.set(data['userId'], currentAttendee);
        this.userMap.next(currentAttendee);
      }
    }
    else {
      if (attendeeMap.has(data['userId'])) {
        let currentAttendee = attendeeMap.get(data['userId']);
        currentAttendee['requestToShareVideo'] = false;
        currentAttendee['requestToSpeak'] = false;
        attendeeMap.set(data['userId'], currentAttendee);
        this.userMap.next(currentAttendee);
      }
    }
    this.detectChanges(changeDetectorRef);
  }

  pushedTileItem(data, leads: any, guests: any, interpreters: any): any {
    if (data) {
      let alertSetting = {};
      alertSetting['overlayClickToClose'] = false;
      alertSetting['showCancelButton'] = true;
      alertSetting['confirmText'] = 'Push to attendees only';

      if (leads.length > 0 || guests.length > 0) {
        alertSetting['declineText'] = 'Push to';
        if (leads.length > 0)
          alertSetting['declineText'] += ' ' + this.translate.instant('lead') + ' /';
        if (guests.length > 0)
          alertSetting['declineText'] += ' ' + this.translate.instant('guest') + ' /';
        if (interpreters.length > 0)
          alertSetting['declineText'] += ' ' + this.translate.instant('interpreter') + ' /';
        alertSetting['declineText'] += ' ' + this.translate.instant('attendees');
      }

      const dialogRef = this.layoutService.alertActionElement('', '', alertSetting, '410px');
      return dialogRef;
    }
    return undefined;
  }

  getUserLocationDetails(data: any): any {
    // debugger;
    // let addresses = data.address_components;
    let userLocation: any = { nm1: undefined, cc1: undefined, nm2: undefined, cc2: undefined, cc: undefined, c: undefined };
    data.forEach(element => {
      element.address_components.forEach(address => {
        let elementTypes = address.types;
        if (elementTypes.findIndex(i => i == 'administrative_area_level_1') != -1) {
          userLocation.nm1 = address.long_name;
          userLocation.cc1 = element.geometry.location;
        }
        else if (elementTypes.findIndex(i => i == 'administrative_area_level_2') != -1) {
          userLocation.nm2 = address.long_name;
          userLocation.cc2 = element.geometry.location;
        }
        else if (elementTypes.findIndex(i => i == 'country') != -1) {
          userLocation.c = { nm: address.long_name, pi: element.place_id, default: element.geometry.location };
          // userLocation.c = { nm: address.long_name, b: element.geometry.bounds, pi: element.place_id };
        }
      });
    });

    return userLocation;
  }

  // sendSignalToBreakoutAttendees(message: string = 'startBreakout', groups: any, session: OT.Session) {
  //   groups.forEach(element => {
  //     element.attendees.forEach(attendee => {
  //       if (attendee.connection && attendee.online) {
  //         this.sendSignal(message, element.id, session, attendee.connection);
  //       }
  //     });
  //     // let findGroup = groups.find(i => i.attendees.find(j => j.id === element.id));
  //     // if (findGroup && element.connection)
  //     //   this.sendSignal(message, findGroup.id, session, element.connection);
  //   });
  // }

  // sendBreakoutSignalThroughServer(breakoutIds: [], message: string, value: string) {
  //   this.requestService.sendSignal({ breakoutIds: breakoutIds, payload: JSON.stringify({ type: message, data: value }) }, (data, error) => {
  //     if (error)
  //       console.log(error);
  //   });
  // }

  sendSignalThroughServer(sessionIds: [], message: string, value: string, callBack?: any) {
    this.requestService.sendSignal({ sessionIds: sessionIds, payload: JSON.stringify({ type: message, data: value }) }, (data, error) => {
      if (error)
        console.log(error);
      if (data && callBack)
        callBack();
    });
    // this.requestService.sendSignal({ breakoutIds: breakoutIds, payload: JSON.stringify({ type: message, data: value }) }, (data, error) => {
    //   if (error)
    //     console.log(error);
    // });
  }

  getHeight(h, w, ratioSize) {
    if (ratioSize) {
      return ((w * ratioSize.h) / ratioSize.w) + 'vw';
    } else {
      return h + 'vw';
    }
  }

  isSubscribeToAudio(selectedTranslation: any, user: any): boolean {
    if (selectedTranslation) {
      let subscribeToAudio = true;
      if (user && user.language && selectedTranslation.language !== user.language && selectedTranslation.language.toLowerCase() !== 'off' && selectedTranslation.isAudioOn) {
        subscribeToAudio = false;
      }
      return subscribeToAudio;
    }
    return true;
  }

  logSessionActivityOnGA(joined: boolean, userId: string, sessionId: string, roomId: string) {
    try {
      window.dataLayer = window.dataLayer || [];
      // window.dataLayer.push({
      //   userId: userId,
      //   orgId: this.requestService.orgId
      // });
      if (joined)
        window.dataLayer.push({
          joined_activityTime: new Date().toString(),
          event: 'Joined_live_session'
        });
      else
        window.dataLayer.push({
          left_activityTime: new Date().toString(),
          event: 'Left_session',
        });
    }
    catch (e) { }
  }

  promptPauseDialog(sessionData: any, roomData: any) {
    let layoutVideoComponents = new Map();
    let layoutAudioComponents = new Map();
    let mediaLibraries = new Map();

    if (sessionData) {
      let sessionLayoutSettings = this.setLayoutSettings(sessionData, sessionData.active);

      sessionLayoutSettings['columns'][0].components.filter(i => i.name === 'audio' && i.active).forEach((element, index) => {
        layoutAudioComponents.set(element.uid, element);
      });

      sessionLayoutSettings['columns'][0].components.filter(i => i.name === 'defaultvideo' && i.active).forEach((element, index) => {
        layoutVideoComponents.set(element.uid, {
          videoLabel: 'Video ' + (index + 1),
          element: element
        });
      });

      sessionLayoutSettings['columns'][0].components.filter(i => i.name === 'videolibrary' && i.active).forEach((element, index) => {
        mediaLibraries.set(element.uid, element);
      });
    }

    roomData['columns'][0].components.filter(i => i.name === 'audio' && i.active).forEach((element, index) => {
      layoutAudioComponents.set(element.uid, element);
    });

    roomData['columns'][0].components.filter(i => i.name === 'defaultvideo' && i.active).forEach((element, index) => {
      layoutVideoComponents.set(element.uid, {
        videoLabel: 'Video ' + (index + 1),
        element: element
      });
    });

    roomData['columns'][0].components.filter(i => i.name === 'videolibrary' && i.active).forEach((element, index) => {
      mediaLibraries.set(element.uid, element);
    });

    // console.log('settings', layoutAudioComponents, layoutVideoComponents, mediaLibraries)

    if (!((this.deviceService.isTablet() || this.deviceService.isMobile()) && this.deviceService.getDeviceInfo().browser == 'Safari')) {
      this.zone.run(() => {
        if (!this.pauseDialog) {
          let startTimer = 0;
          let startTimerInterval = setInterval(() => {
            startTimer++;
          }, 1000);

          this.pauseDialog = this.layoutService.alertActionElement('', this.translate.instant('Please unmute your computer audio if it is muted and click here to proceed.'), {
            overlayClickToClose: false,
            showCancelButton: false,
            declineText: this.translate.instant('Proceed')
          }, 'fit-content');
          this.pauseDialog.afterClosed().subscribe((res) => {
            clearInterval(startTimerInterval);
            layoutVideoComponents?.forEach((video, key) => {
              if (video.element.type === 'vimeo' && video.element.url) {
                this.playAfterPauseMedia.next({ uuid: video.element.uid, play: true });
              }
              else if (video.element.type === 'hls' && video.element.url) {
                this.playAfterPauseMedia.next({ uuid: video.element.uid, play: true });
              }
            });

            layoutAudioComponents?.forEach((element) => {
              if (element && element.metadata.autoPlay) {
                let audioPlayer: any = document.getElementById('player-' + element.uid);
                if (audioPlayer)
                  audioPlayer.play();
              }
            });

            mediaLibraries?.forEach((video, key) => {
              this.playAfterPauseMediaLibrary.next({ uuid: video.uid });
            });

            if (OT)
              OT.unblockAudio();

            try {
              if (document.querySelector('#hls-session-video video')) {
                let video: any = document.querySelector('#hls-session-video video');
                video.play();
              }
            }
            catch (e) { }

            this.pauseDialog = undefined;
          });
        }
      });
    }
  }

  setVolume(volume: number, subscribers: any, layoutVideoComponents: any) {
    if (volume !== undefined) {
      this.preferredVolume = volume;

      if (subscribers) {
        let subscriberKeys = Object.keys(subscribers);
        if (subscriberKeys.length)
          subscriberKeys.forEach(key => {
            subscribers[key].setAudioVolume(volume);
          });
      }

      document.querySelectorAll('audio').forEach(audio => {
        audio.volume = volume / 100;
      });

      document.querySelectorAll('video').forEach(video => {
        video.volume = volume / 100;
      });

      try {
        document.querySelectorAll('iframe').forEach(iframe => {
          if (iframe.contentWindow.document.querySelector('video'))
            iframe.contentWindow.document.querySelector('video').volume = volume / 100;

          if (iframe.contentWindow.document.querySelector('audio'))
            iframe.contentWindow.document.querySelector('audio').volume = volume / 100;
        });
      }
      catch (e) { }

      // if (mediaLibraries)
      //   mediaLibraries.map(library => {
      //     library.overRideVolume = true;

      //     if (library.selectedVideo && library.player && typeof library.player.setVolume === 'function') {
      //       if (library.selectedVideo.type === 'youtubeVid' || library.selectedVideo.type === 'youtubePlst') {
      //         library.player.setVolume(volume);
      //       }
      //       else if (library.selectedVideo.type === 'vimeoVid' || library.selectedVideo.type === 'vimeoPlst') {
      //         library.player.setVolume(volume / 100);
      //       }
      //       else if (library.selectedVideo.type === 'audio') {
      //         library.player.setVolume(volume / 100);
      //       }
      //     }
      //   });

      if (layoutVideoComponents)
        layoutVideoComponents.forEach((video, key) => {
          if (video.element.player) {
            if (video.element.type === 'facebook')
              video.element.player.setVolume(volume / 100);
          }
        });
    }
  }

  logTime() {
    this.logStartDateTime = moment();
  }

  logTimeOnES(roomId, sessionId) {
    let duration = Math.round(moment.duration(moment().diff(this.logStartDateTime)).asMinutes() * 100) / 100;
    this.requestService.logTrackEvent(new EventTrack(EventType.SESSIONDURATION, duration, roomId, sessionId, undefined, undefined), (res, err) => { });
  }
}