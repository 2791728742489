import { Component, OnInit, OnDestroy, Input, ViewChild, EventEmitter, Output, ElementRef, ChangeDetectorRef } from "@angular/core";
import { LayoutUtilsService, RequestService, TokBoxService } from "src/app/shared";
import { Subscription } from "rxjs";
import { trigger, state, style, transition, animate } from "@angular/animations";
import { TranslateService } from "@ngx-translate/core";
import { Moment } from "moment";
import * as moment from "moment";
import { CdkDrag } from "@angular/cdk/drag-drop";

@Component({
    selector: 'app-resources',
    templateUrl: './resources.component.html',
    styleUrls: ['./resources.component.scss'],
    animations: [
        trigger('showDialog', [
            state('in', style({
                opacity: 0,
                top: '0%'
            })),
            state('out', style({
                opacity: 0.98,
                top: '{{top}}'
            }), { params: { top: '7.5%' } }),
            transition('in => out', animate('1500ms ease-in-out'))
        ])
    ]
})

export class SessionResourcesComponent implements OnInit, OnDestroy {

    @Input() isMobile: boolean = false;
    @Input()
    set resources(resources: any) {
        if (resources) {
            this._resources = resources;
            this._resourceIds = [];
            this.resources.map(i => this._resourceIds.push(i._id));
            this.loadData();
        }
    }
    get resources() {
        return this._resources;
    }
    @Input() sessionData: any = undefined;
    @Input() disableDrag: boolean = false;
    @Input()
    set hide(hide: boolean) {
        this._hide = hide;
        if (!hide) {
            this.animationState = 'out';
            this.maximize();
        }
        else {
            this.animationState = 'in';
            if (this.cdkDrag)
                this.cdkDrag._dragRef.reset();
        }
    }

    @Output() hideDialog = new EventEmitter<boolean>(undefined);

    @ViewChild('resourcesDraggable') resourcesDraggable: ElementRef;
    @ViewChild(CdkDrag) cdkDrag: CdkDrag;

    _resources: any = [];
    _zIndex: number = 0;
    _zIndexTemp: number = 0;
    _hide: boolean = false;
    _leftPosition: any;
    private subscriptions: Subscription[] = [];
    animationState: string = 'in';
    loading: boolean = false;
    dataList: any[] = [];
    displayedColumns: string[] = ['icon', 'name'];
    _resourceIds: any[] = [];

    constructor(private opentokService: TokBoxService, private changeDetectorRef: ChangeDetectorRef, private translate: TranslateService, private requestService: RequestService, private layoutUtilsService: LayoutUtilsService) { }

    ngOnInit() {
        this.subscriptions.push(this.opentokService.zIndexDraggable.subscribe(value => {
            if (value != undefined) {
                this._zIndexTemp = value;
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    maximize() {
        // this.loadData();
        this.dragged();
        this.detectChanges();

        this._leftPosition = (document.body.offsetWidth / 2) - (this.resourcesDraggable.nativeElement.offsetWidth / 2);
    }

    closeDialog() {
        this.animationState = 'in';
        if (this.cdkDrag)
            this.cdkDrag._dragRef.reset();
        this.hideDialog.emit(true);
    }

    private detectChanges() {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    dragged() {
        this._zIndex = this._zIndexTemp + 1;
        this.opentokService.zIndexDraggable.next(this._zIndex);
    }

    loadData() {
        this.loading = true;
        let filters = {
            "$and": [
                { 'organizationId._id': { '$eq': this.requestService.orgId } },
                { '_id': { '$in': this._resourceIds } }
            ]
        };
        let filterObj = { fieldKeys: ['name', 'icon', 'type', 'url'], filter: filters };
        this.requestService.getDataList('docs',
            filterObj, (data, error) => {
                if (error) {
                    this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
                }
                if (data) {
                    this.dataList = data.results;
                }
                this.loading = false;
            });
    }

    viewData(doc) {
        let resource = this.resources.find(i => i._id === doc._id);
        if (resource) {
            if (!resource.availability) {
                if (doc.hasOwnProperty('url') && doc.url && doc.url !== '') {
                    window.open(doc.url, '_blank');
                }
            }
            else {
                let currentDate: Moment = moment.utc();
                let startD: Moment = moment.utc(this.sessionData.startsAt).local();
                let endD: Moment = moment.utc(this.sessionData.endAt).local();
                if (!((currentDate.diff(startD, 'minutes') >= 0 && endD.diff(currentDate, 'minutes') >= 0) || startD.diff(currentDate, 'minutes') > 0)) {
                    if (doc.hasOwnProperty('url') && doc.url && doc.url !== '') {
                        window.open(doc.url, '_blank');
                    }
                }
                else {
                    this.layoutUtilsService.alertActionElement('', this.translate.instant('The resources to this session are not available yet'), {
                        overlayClickToClose: false,
                        showCancelButton: false,
                        declineText: this.translate.instant('OK'),
                        messageIsTemplate: false
                    });
                }
            }
        }
    }
}