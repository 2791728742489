<div class="col-xl-12" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content >
      <mat-card>
				<!-- <div class="fieldInput">
						<mat-form-field>
							<input matInput type="number" name="timer" [(ngModel)]="returnData['timer']" placeholder="{{'Time delay' | translate}}" >
						</mat-form-field>
				</div> -->
				<div class="fieldInput">
					<mat-form-field style="width: 100%;">
							<input matInput type="number" min="0" max="10" placeholder="{{ 'Number of Rows' | translate }}" [(ngModel)]="returnData['gridSizeY']" name="gridSizeY" (change)="editNumericAttribute($event, 'gridSizeY', $event.target.value, 1, 10)">
					</mat-form-field>
				</div>
				<div class="fieldInput">
					<mat-form-field style="width: 100%;">
							<input matInput type="number" min="0" max="10" placeholder="{{ 'Number of Columns' | translate }}" [(ngModel)]="returnData['gridSizeX']" name="gridSizeX" (change)="editNumericAttribute($event, 'gridSizeX', $event.target.value, 1, 10)">
					</mat-form-field>
				</div>
				<div class="fieldInput">
						<mat-form-field style="width: 100%;">
							<input matInput type="text" name="spacing" [(ngModel)]="returnData['spacing']" placeholder="{{'Spacing (vw,%,px)' | translate}}" >
						</mat-form-field>
				</div>
				<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" >
					<div fxFlex >
						<div class="fieldInput">
							<mat-form-field style="width: 100%;">
									<input matInput type="color" name="bgcolor" [(ngModel)]="returnData['bgcolor']" name="bgcolor" style="width: 25px" (change)="editAttribute($event, 'bgcolor', $event.target.value)" matTooltip="{{'Select Background Color' | translate}}" matTooltipClass="tooltip-red">
							</mat-form-field>
						</div>
					</div>
					<div fxFlex >
						<div class="fieldInput">
							<label style="color: rgba(0,0,0,.54);font-size: 0.8rem;" >{{ 'Opacity' | translate }}</label>
							<mat-slider style="width: 100%;margin-left: -8px;margin-top: 6.5px;" min="0" max="1" step="0.1" color="primary" [(ngModel)]="returnData['opacity']" [thumbLabel]="true" matTooltip="{{'Opacity' | translate}}"></mat-slider>
							<!-- <mat-form-field style="width: 100%;">
									<input matInput type="number" min="0" max="1" placeholder="{{ 'Opacity' | translate }}"  [(ngModel)]="returnData['opacity']" name="opacity" (change)="editNumericAttribute($event, 'opacity', $event.target.value, 0, 1)">
							</mat-form-field> -->
						</div>
					</div>
				</div>
				<!-- <div class="fieldInput">
					<mat-icon *ngIf="!returnData['moderated']" (click)="returnData['moderated'] = true" title="{{'All attendees can speak at the same time' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
					<mat-icon *ngIf="returnData['moderated']" (click)="returnData['moderated'] = false" title="{{'All attendees can speak at the same time' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
					<label style="width: 13vw;">{{'Moderated' | translate}}</label>
				</div> -->
      </mat-card>
	</div>
	<div mat-dialog-actions>
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
			<button mat-raised-button (click)="closeModal(returnData)">{{'Confirm' | translate}}</button>
	</div>
	<br/>
</div>
