export class ConfDefaultSettings {
  public static defaultSetting = {
    version: 1,
    disableSupportBot: false,
    web: {
      topBar: {
        disableResource: false,
        disablePoster: false,
        disableExhibitor: false,
        disableAgenda: false,
        disableDirectory: false,
        disableHelpDesk: false,
        disableCommunicate: false,
        disableVideoCommunicate: false
      },
      login: {
        positionH: 'top',
        positionV: 'right',
        removeLogoImage: false,
        removeBGImage: false
      },
      page: {
        enableScheduler: false
      },
      global: {
        disablePasswordChange: false,
        disableEventBlueprints: false,
        disableShowUserEmail: false,
        disableShowUserPhone: false,
        dateFormat: 'dd-MM-yyyy'
      }
    },
    mobile: {
      topBar: {
        disableResource: false,
        disablePoster: false,
        disableExhibitor: false,
        disableAgenda: false,
        disableDirectory: false,
        disableHelpDesk: false,
        disableCommunicate: false,
        disableVideoCommunicate: false
      },
      login: {
        positionH: 'center',
        positionV: 'top',
        removeLogoImage: false,
        removeBGImage: false
      },
      page: {
        enableScheduler: false
      },
      global: {
        disablePasswordChange: false,
        disableEventBlueprints: false,
        disableShowUserEmail: false,
        disableShowUserPhone: false,
        dateFormat: 'dd-MM-yyyy'
      }
    }
  }
  public static defaultFeatures = {
    room: {

    },
    session: {

    }
  }
  public static hiddenFields = ['_id', 'name', 'pictureLink', 'isSuperAdmin', 'appId', 'locationId', 'organizationId', 'resources', 'role', 'isPasswordRequired', 'hideFromAgenda'];
  public static dateFormatList = ['dd-MM-yyyy', 'MM-dd-yyyy'];
  public static hiddenFieldsEnd = ['chat_dnd', 'isVideoChat', 'isTextChat', 'isEmailAddress', 'isPhoneNumber'];
  public static disabledFields = ['_id', 'name', 'pictureLink', 'appId', 'locationId', 'organizationId', 'resources', 'role', 'isPasswordRequired', 'hideFromAgenda', 'email', 'chat_dnd', 'isVideoChat', 'isTextChat', 'isEmailAddress', 'isPhoneNumber', 'listedInTheDirectory', 'privacyterm'];
  public static defaultFieldTypes = {
    'string': 'Input Text',
    'textarea': 'Text Area',
    'number': 'Number',
    'url': 'URL',
    'email': 'Email',
    'enum': 'Dropdown',
    // 'tags' : 'Multiple Select (Tags)',
    'boolean': 'Yes/No',
    'datetime': 'Date/Time',
    'phone': 'Phone',
    'date': 'Date',
    // 'reference' : 'Select (Database Table)',
    'textview': 'Paragraph'
  }
  public static defaultFieldTypesKeys: string[] = Object.keys(ConfDefaultSettings.defaultFieldTypes);
  public static defaultFieldObject = {
    "superadmin": false,
    "admin": true,
    "alias": "",
    "parent": "",
    "displayName": "",
    "creatable": true,
    "editable": true,
    "generated": false,
    "localizable": false,
    "registration": true,
    "name": "",
    "nullable": true,
    "orderable": true,
    "readable": true,
    "searchable": true,
    "type": "string",
    "unique": false,
    "versionable": false,
    "versioned": false,
    "validation": [],
    "charlimit": 100,
    "visible": true,
    "immutable": false,
    "value": "",
    "info": ""
  }
}
