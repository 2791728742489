export class ShortcutClicks {
  public static clicksName: any = {
    'mediaShortcut': {
      displayName: 'Media',
      content: {
        name: 'mediaShortcut',
        uniqueId: '',
        uid: '',
        active: true,
        note: '',
        action: '',
        autoStart: false,
        loop: false,
        isPlaying: false,
        isUsedByShortcut: true,
        type: '',
        frame: 0,
        url: '',
        urlId: '',
        clickThruUrl: '',
        hlsUrl: '',
        startMin: 0,
        startSec: 0,
      }
    }
  };
  public static clicksNameKeys: string[] = Object.keys(ShortcutClicks.clicksName);
}
