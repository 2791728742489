<div class="kt-portlet" [ngStyle]="{ 'width': data.width ? data.width : ''}">
	<div class="kt-portlet__head kt-portlet__head__custom">
		<div class="kt-portlet__head-label">
			<h3 class="kt-portlet__head-title">{{data.title}}</h3>
			<span class="kt-portlet__head-icon kt-hide">
				<em class="la la-gear"></em>
			</span>
		</div>
	</div>
	<div class="kt-form">
		<div class="kt-portlet__body">
			<div class="form-group kt-form__group row">
				<div class="col-lg-12 dialogBox">
					<mat-form-field style="width:100%">
						<mat-label>{{data.placeholder | translate}}</mat-label>
						<mat-select (selectionChange)="onChange($event)" [(value)]="data.data">
							<mat-option>{{data.placeholder | translate}}</mat-option>
							<mat-option *ngFor="let item of data.dataList" [value]="item._id">
								<span *ngIf="item.text">{{item.text}}</span>
								<span *ngIf="item.name">{{item.name}}</span>
							</mat-option>
						</mat-select>
					</mat-form-field>
				</div>
			</div>
		</div>
		<div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right">
			<div class="kt-form__actions kt-form__actions--sm">
				<br />
				<button mat-raised-button (click)="onNoClick()" color="danger">{{data.cancelbtn}}</button>&nbsp;
				<button mat-raised-button (click)="onYesClick()" cdkFocusInitial>{{data.confirmbtn}}</button>
			</div>
		</div>
	</div>
</div>