<div class="col-xl-12 pictureDialog" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content >
      <mat-card >
				<mat-form-field class="example-full-width">
					<mat-label>{{'Select' | translate}} {{'Lead' | translate}} / {{'Guest' | translate}}</mat-label>
					<mat-select (selectionChange)="data.data['id'] = $event.value;" [(value)]="data.data['id']">
						<mat-option>{{'Select' | translate}} {{'Lead' | translate}} / {{'Guest' | translate}}</mat-option>
						<mat-option *ngFor="let item of data.dataList" [value]="item._id">
							<span *ngIf="item.text">{{item.text}}</span>
							<span *ngIf="item.name">{{item.name}}</span>
						</mat-option>
					</mat-select>
				</mat-form-field>
				<div class="example-full-width">
					<mat-icon *ngIf="!data.data['viewPublicChat']" (click)="data.data['viewPublicChat']=true" title="{{'View public chat' | translate}}" style="height: 30px;vertical-align: middle;cursor:pointer">check_box_outline_blank</mat-icon>
					<mat-icon *ngIf="data.data['viewPublicChat']" (click)="data.data['viewPublicChat']=false" title="{{'View public chat' | translate}}" style="height: 30px;vertical-align: middle;cursor:pointer">check_box</mat-icon>
					<label style="width: 13vw" for="viewPublicChat"> {{'View public chat' | translate}}</label>
				</div>
				<!-- <div class="example-full-width">
					<mat-icon *ngIf="!data.data['allowStarQuestions']" (click)="data.data['allowStarQuestions']=true" title="{{'Allow to Star Questions' | translate}}" style="height: 30px;vertical-align: middle;cursor:pointer">check_box_outline_blank</mat-icon>
					<mat-icon *ngIf="data.data['allowStarQuestions']" (click)="data.data['allowStarQuestions']=false" title="{{'Allow to Star Questions' | translate}}" style="height: 30px;vertical-align: middle;cursor:pointer">check_box</mat-icon>
					<label style="width: 13vw" for="allowStarQuestions"> {{'Allow to Star Questions' | translate}}</label>
				</div>
				<div class="example-full-width">
					<mat-icon *ngIf="!data.data['allowPushQuestions']" (click)="data.data['allowPushQuestions']=true" title="{{'Allow to Push Questions' | translate}}" style="height: 30px;vertical-align: middle;cursor:pointer">check_box_outline_blank</mat-icon>
					<mat-icon *ngIf="data.data['allowPushQuestions']" (click)="data.data['allowPushQuestions']=false" title="{{'Allow to Push Questions' | translate}}" style="height: 30px;vertical-align: middle;cursor:pointer">check_box</mat-icon>
					<label style="width: 13vw" for="allowPushQuestions"> {{'Allow to Push Questions' | translate}}</label>
				</div> -->
      </mat-card>
	</div>
	<div mat-dialog-actions>
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
			<button mat-raised-button (click)="closeModal(returnData)">{{'Confirm' | translate}}</button>
	</div>
	<br/>
</div>
