import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { RequestService, SubheaderService } from '../../shared/services';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  private selectedUser: any;
  public errorType: string = '404';
  public errorTypeString: string = 'Not Found';
  constructor(private router: Router, private requestService: RequestService,
    public dialog: MatDialog,
    private subheaderService: SubheaderService,
    private activatedRoute: ActivatedRoute, private translate: TranslateService) { }

  ngOnInit() {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((val: any) => {
        this.selectedUser = val;
      })
    );
    this.subscriptions.push(
      this.activatedRoute.params.subscribe(params => {
        console.log(params);
        if (params.hasOwnProperty('type')) {
          if (params['type'] === '404.1') {
            this.errorType = 'notfound';
          } else {
            this.errorType = params['type'];
            if (this.errorType === '401') {
              this.errorTypeString = 'UNAUTHORIZED';
            }
          }
        }
        // this.subheaderService.setTitle(this.translate.instant('Error'));
        // this.subheaderService.setBreadcrumbs([
        //   { title: this.translate.instant('Error'), page: `/error` },
        // ]);
      })
    );

  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
  goToLogin() {
    this.requestService.logout(true, false, true);
  }
}
