<div class="col-xl-12 pictureDialog" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content>
		<mat-card style="min-height: 90px;">
			<!-- <div class="example-full-width">
					<mat-form-field type="text" style="width: 100%;">
							<input matInput placeholder="{{ 'Title' | translate }}" [(ngModel)]="returnData['title']">
					</mat-form-field>
				</div> -->
			<mat-form-field class="example-full-width" style="margin-bottom: 20px;">
				<input name="callerId" placeholder="{{ 'Station Call Letters' | translate }}" matInput type="text"
					required [(ngModel)]="returnData['callerId']" [errorStateMatcher]="esMatcher"
					(input)="returnData['stationName'] = '';returnData['stationIcon'] = '';returnData['stationId'] = '';returnData['groupId'] = ''"
					(blur)="fetchStationData($event.target.value)">
				<mat-icon
					*ngIf="returnData['callerId'] && returnData['callerId'].length > 0 && returnData['stationName'] === ''"
					class="syncCss" title="{{'Fetch Station' | translate}}">sync</mat-icon>
				<mat-hint *ngIf="returnData.hasOwnProperty('stationName')" align="start" style="font-size: 18px">
					<img style="height: 35px;float: left;"
						*ngIf="returnData['stationIcon'] && returnData['stationIcon'] !== ''"
						src="{{returnData['stationIcon']}}">
					<span style="float: left;line-height: 35px;">{{returnData['stationName']}}</span>
				</mat-hint>
				<mat-error align="end"
					*ngIf="returnData['callerId'] === null ||returnData['callerId'] === undefined || returnData['callerId'] === ''">
					{{'Please enter a valid Quu Call Letter' | translate}} is required.
				</mat-error>
			</mat-form-field>
			<mat-form-field class="example-full-width" *ngIf="!hasSongsNo">
				<input name="plsUrl" required placeholder="{{ 'URL' | translate }}" matInput type="url"
					[(ngModel)]="returnData['plsUrl']" [errorStateMatcher]="esMatcher">
				<mat-error align="end"
					*ngIf="returnData['plsUrl'] === null ||returnData['plsUrl'] === undefined || returnData['plsUrl'] === ''">
					{{'Please enter a valid URL link' | translate}} is required.
				</mat-error>
			</mat-form-field>
			<mat-form-field class="example-full-width" *ngIf="hasSongsNo">
				<input name="songsno" required placeholder="{{ 'Number of displayed songs' | translate }}" matInput
					onkeypress="return event.charCode >= 48 && event.charCode <= 57" type="number" min="0"
					max="{{songsNoLimit}}" [(ngModel)]="returnData['songsNo']" [errorStateMatcher]="esMatcher">
				<mat-hint align="start">{{songsNoLimit}} Maximum Songs</mat-hint>
				<mat-error align="end"
					*ngIf="returnData['songsNo'] === undefined || returnData['songsNo'] === '' || returnData['songsNo'] < 0 || returnData['songsNo'] > songsNoLimit">
					{{'Please enter a valid number of songs' | translate}} is required.
				</mat-error>
			</mat-form-field>
			<!-- <mat-form-field class="example-full-width" *ngIf="returnData['type'] !== 'none'">
					<input name="link" matInput
					 [readonly]="returnData['type'] !== 'link' && returnData['type'] !== 'page'" [(ngModel)]="returnData['link']">
					<mat-icon matSuffix *ngIf="returnData['type'] !== 'link' && returnData['type'] !== 'page'" style="cursor:pointer" (click)="selectPictureLinkType($event, returnData['type'])">mode_edit</mat-icon>
				</mat-form-field> -->
		</mat-card>
	</div>
	<div mat-dialog-actions>
		<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
		<button mat-raised-button
			[disabled]="!returnData.hasOwnProperty('stationName') || returnData['stationName'] === '' || returnData['stationName'] === undefined"
			(click)="closeModal(returnData)">{{'Confirm' | translate}}</button>
	</div>
	<br />
</div>