import { Component, Inject, OnInit, ChangeDetectorRef, ElementRef, ViewChild, NgZone, Input, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Subscription, Observable, Observer } from 'rxjs';
import { LoaderService } from "../../../shared/services/loader.service";
import { RequestService } from '../../../shared/services/request.service';
import { LayoutUtilsService } from "../../../shared/services/utils/layout-utils.service";
import { urlSafeBase64Encoding } from '../../../shared/helpers';
import { ImageCroppedEvent, ImageCropperComponent } from 'ngx-image-cropper';
import { environment } from '../../../../environments/environment';
import { Optional } from '@angular/core';

interface ViewDialogData {
	dataType: string;
	dataTypeTitle: string;
	title: string;
	variable: string;
	maxHeight: number;
	maxWidth: number;
	targetApi: string;
	targetDeleteApi: string;
	data: any;
	modalSetting: any;
	confirmData: any;
}

@Component({
	selector: 'app-custom-file-crop-upload-dialog-modal',
	templateUrl: './custom-file-crop-upload-dialog.component.html',
	styleUrls: ['./custom-file-crop-upload-dialog.component.scss']
})
export class FileModalCropDialogComponent implements OnInit {
	private subscriptions: Subscription[] = [];
	public orgType = environment.orgType;
	public originalImage = undefined;
	public selectedUser: any;
	public errorMessage: string = '';
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public isSubmitted: boolean = true;
	public imageExtension: string = 'png';
	public allowedExtensions: string[] = ['jpeg', 'jpg', 'png', 'gif'];
	public allowedAcceptExtensions: string = 'image/jpg,image/jpeg,image/png,image/gif';
	public pictureLink: string = '';
	public pictureBgColor: string = '';
	public maxHeight: number = 5000;
	public maxWidth: number = 5000;
	public tabSelected: number = 0;
	public imageSelected: any = undefined;
	public imageUrlPath: any = undefined;
	public croppedImage: any = '';
	public selectedRatio: number = 0;

	@ViewChild(ImageCropperComponent) imageCropper: ImageCropperComponent;
	constructor(private zone: NgZone, private translate: TranslateService,
		private requestService: RequestService,
		private cdr: ChangeDetectorRef, private loaderService: LoaderService,
		private layoutUtilsService: LayoutUtilsService,
		@Optional() public dialogRef: MatDialogRef<FileModalCropDialogComponent>,
		@Optional() @Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		// console.log('ModalGalleryDialogComponent', data);
		if (data) {
			this.maxHeight = data.maxHeight || 5000;
			this.maxWidth = data.maxWidth || 5000;
		}
	}

	ngOnInit() {
		this.subscriptions.push(
			this.requestService.currentUserSubject.subscribe((data) => {
				if (data) {
					this.selectedUser = data;
					this.loadData();
				}
			})
		);
	}
	/**
	 * On Destroy
	 */
	ngOnDestroy() {
		this.subscriptions.forEach(el => el.unsubscribe());
	}
	public loadData() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			let dataId = this.data.data['_id'];
			this.requestService.getSingleData(this.data.dataType, dataId, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
				}
				if (data) {
					this.data.data = data.results;
					this.pictureLink = this.data.data[this.data.variable];
				}
				this.loading = false;
			});
		}
	}
	public getBase64ImageFromFile(imageUrl) {
		this.getBase64ImageFromURL(imageUrl).subscribe(base64data => {
			// this is the image as dataUrl
			this.imageUrlPath = 'data:image/' + this.imageExtension + ';base64,' + base64data;
		},
			err => {
				// this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + ' ' + this.translate.instant('The image is not accessible'), this.translate.instant('Dismiss'));
			}
		);
	}
	closeModal(data): void {
		let returnData = { pictureLink: this.pictureLink };
		if (data) {
			this.dialogRef.close(data);
		} else {
			this.dialogRef.close(returnData);
		}
	}
	goToCrop(): void {
		this.zone.run(() => {
			// console.log(this.imageUrlPath);
			this.tabSelected = 1;
		});
	}
	getFilename(url) {
		if (url) {
			var m = url.toString().match(/.*\/(.+?)\./);
			if (m && m.length > 1) {
				return m[1];
			}
		}
		return "";
	}
	onBrowseFiles(target: any): void {
		this.readFiles(target.files);
	}
	/**
	 *  @param files: list of browsed files
	 *  @param index: iterator over browsed images
	 *
	 *  read files browsed by user
	 */
	readFiles(files, index = 0): void {
		let reader = new FileReader();
		if (index in files) {
			let currentFile = { error: false, text: files[index].name.split('.')[0], id: files[index].id, originalFile: files[index], source_url: null, extension: '' };
			let fileExt = files[index].name.split('.').pop();
			currentFile.extension = fileExt.toLowerCase();
			this.imageExtension = fileExt.toLowerCase();
			const max_size = 5000000;
			const max_height = this.maxHeight;
			const max_width = this.maxWidth;
			if (files[index].size > max_size) {
				this.layoutUtilsService.showNotification(this.translate.instant('Maximum size allowed is') + ' ' + max_size / 1000000 + 'MB', this.translate.instant('Dismiss'));
			} else if (this.allowedExtensions.indexOf(fileExt.toLowerCase()) === -1) {
				currentFile.error = true;
				this.layoutUtilsService.showNotification(this.translate.instant('The file type is not allowed'), this.translate.instant('Dismiss'));
			} else {
				this.readFile(files[index], reader, (event) => {
					this.loaderService.display(true);
					var image = new Image();
					this.readImage(event, image, (imgresult) => {
						// console.log('readImage', imgresult);
						var canvas = document.createElement("canvas");
						var context = canvas.getContext("2d");

						var ratio = Math.min(max_width / imgresult.width, max_height / imgresult.height);
						if (imgresult.width < imgresult.width * ratio && imgresult.height < imgresult.height * ratio) {
							canvas.width = imgresult.width;
							canvas.height = imgresult.height;
						} else {
							canvas.width = imgresult.width * ratio;
							canvas.height = imgresult.height * ratio;
						}
						context.drawImage(image,
							0,
							0,
							image.width,
							image.height,
							0,
							0,
							canvas.width,
							canvas.height
						);
						// console.log(canvas.width, canvas.height);
						// console.log(image.width, max_width, image.height, max_height);
						if (canvas.width <= max_width && canvas.height <= max_height) {
							currentFile['originalFile'] = this.dataURLtoFile(canvas.toDataURL("image/" + this.imageExtension, 1), currentFile.text + '.' + this.imageExtension);
							this.imageUrlPath = canvas.toDataURL("image/" + this.imageExtension, 1);
							this.imageSelected = currentFile;
							this.pictureLink = this.imageUrlPath;
							this.loaderService.display(false);
						} else {
							this.layoutUtilsService.showNotification(this.translate.instant('The image is not well formatted'), this.translate.instant('Dismiss'));
							this.loaderService.display(false);
						}
						// this.continueUpload(currentFile);
					});
				});
			}
		} else {
			this.cdr.detectChanges();
		}
	}
	dataURLtoFile(dataurl, filename) {
		var arr = dataurl.split(','),
			mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]),
			n = bstr.length,
			u8arr = new Uint8Array(n);

		while (n--) {
			u8arr[n] = bstr.charCodeAt(n);
		}

		return new File([u8arr], filename, { type: mime });
	}
	readFile(file, reader, callback): void {
		reader.onload = () => {
			callback(reader.result);
		}
		reader.readAsDataURL(file);
	}
	readImage(file, image, callback): void {
		image.onload = () => {
			callback(image);
		}
		image.src = file;
	}
	continueUpload(currentFile) {
		if (!this.loading && currentFile && currentFile.originalFile) {
			this.loaderService.display(true);
			this.loading = true;
			this.errorMessage = '';
			this.requestService.onUploadFilesByPath(this.data.dataType + '/' + this.data.targetApi + '/upload/' + this.data.data._id, currentFile)
				.subscribe(
					(results: any) => {
						// console.log('currentFile', currentFile);
						this.loaderService.display(false);
						if (results['status']) {
							currentFile.source_url = results.results.pictureLink;
							this.pictureLink = results.results.pictureLink;
							this.layoutUtilsService.showNotification('Image ' + this.translate.instant('Successfully Uploaded'), this.translate.instant('Dismiss'));
							this.loading = false;
							this.closeModal(results.results.pictureLink);
						} else {
							this.loading = false;
							currentFile.error = true;
							this.layoutUtilsService.showNotification('Error: ' + results['message'], 'Dismiss');
						}
						this.cdr.detectChanges();
					},
					(error: any) => {
						// console.log('Error uploading file.', error);
						this.loaderService.display(false);
						this.loading = false;
						currentFile.error = true;
						this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + ' ' + this.translate.instant('Error uploading file'), this.translate.instant('Dismiss'));
						this.cdr.detectChanges();
					}
				);
		} else {
			this.closeModal(undefined);
		}
	}
	// fileChangeEvent(event: any): void {
	//     this.imageChangedEvent = event;
	// }
	cropIt() {
		let croppedImage = this.imageCropper.crop();
		if (!this.loading && this.imageSelected && croppedImage) {
			this.imageSelected['originalFile'] = this.dataURLtoFile(croppedImage.base64, this.imageSelected.text + '.' + this.imageExtension);
			this.continueUpload(this.imageSelected);
		}
	}
	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
	}
	imageHasLoaded(e) {
		//  console.log('imageHasLoaded', e);
	}
	imageLoaded() {
		// show cropper
	}
	cropperReady() {
		// cropper ready
	}
	loadImageFailed() {
		// show message
	}
	getBase64ImageFromURL(url: string) {
		return Observable.create((observer: Observer<string>) => {
			// create an image object
			let img = new Image();
			img.crossOrigin = 'Anonymous';
			img.src = url + '?t=' + new Date().getTime();
			if (!img.complete) {
				// This will call another method that will create image from url
				img.onload = () => {
					observer.next(this.getBase64Image(img));
					observer.complete();
				};
				img.onerror = (err) => {
					observer.error(err);
				};
			} else {
				observer.next(this.getBase64Image(img));
				observer.complete();
			}
		});
	}
	getBase64Image(img: HTMLImageElement) {
		// We create a HTML canvas object that will create a 2d image
		var canvas = document.createElement("canvas");
		canvas.width = img.width;
		canvas.height = img.height;
		var ctx = canvas.getContext("2d");
		// This will draw image
		ctx.drawImage(img, 0, 0);
		// Convert the drawn image to Data URL
		var dataURL = canvas.toDataURL("image/" + this.imageExtension, 1);
		return dataURL.replace(/^data:image\/(png|jpg|jpeg|gif);base64,/, "");
	}
	public deleteFile(targetDeleteApi: string) {
		const _deleteMessage = 'Image ' + this.translate.instant('Cleared Successfully') + '.';
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.deleteSingleDataByApi(this.data.dataType, this.data.data._id, targetDeleteApi, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
				}
				this.loading = false;
				if (data) {
					this.layoutUtilsService.showNotification(_deleteMessage, this.translate.instant('Dismiss'));
					this.loadData();
				}
			});
		}
	}
}
