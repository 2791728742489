<div #audioPlayer [hidden]="true">
    <audio [id]="mediaPlayerUID + 'player-' + id" controls="controls" [style.width.vw]="width" [volume]="volume"
        controlsList="nodownload" [autoplay]="isAutoPlay" [loop]="isOnLoop">
        <source [src]="src" [type]="type" (error)="audioHasError()" />
    </audio>
</div>

<div class="custom-player" *ngIf="!hasError" fxLayout="row" fxLayoutGap="10px">
    <mat-icon fxFlex fxGrow="0" class="play" *ngIf="isPlaying" (click)="togglePlay(false)">pause</mat-icon>
    <mat-icon fxFlex fxGrow="0" class="play" *ngIf="!isPlaying" (click)="togglePlay(true)">play_arrow</mat-icon>
    <div class="time">{{currentTimeAsString}} / {{durationAsString}}</div>
    <mat-slider fxFlex (valueChange)="seek($event)" [value]="currentTime" [max]="duration" [min]="0">
        <input matSliderThumb>
    </mat-slider>
    <mat-icon class="volume" fxGrow="0" fxFlex *ngIf="volume == 0" (click)="flipVolume()">volume_mute</mat-icon>
    <mat-icon class="volume" fxGrow="0" fxFlex *ngIf="volume > 0.5" (click)="flipVolume()">volume_up</mat-icon>
    <mat-icon class="volume" fxGrow="0" fxFlex *ngIf="volume <= 0.5 && volume > 0" (click)="flipVolume()">volume_down
    </mat-icon>
    <mat-slider fxFlex fxGrow="0" class="volume-slider" [value]="volume" [step]="0.1" [min]="0" [max]="1"
        (valueChange)="changeVolume($event)">
        <input matSliderThumb>
    </mat-slider>
</div>
<div class="custom-player" *ngIf="hasError">
    <span class="error">{{'Audio file is not available' | translate}}</span>
</div>