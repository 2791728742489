<div [id]="data.id" class="view-image-dialog">
  <div>
    <mat-icon *ngIf="images && images.length" class="previous" [title]="'Previous' | translate"
      (click)="movePrevious()">
      arrow_back
    </mat-icon>
    <mat-icon *ngIf="images && images.length" class="next" [title]="'Next' | translate" (click)="moveNext()">
      arrow_forward
    </mat-icon>
    <img class="popImage" [ngStyle]="{'max-height': isMobile || isTablet ? '55vh' : '80vh'}" *ngIf="imageUrl"
      src="{{imageUrl}}">
  </div>
</div>