<div class='ili-panel cc_ques_panel'>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex>
      <p class='text_header_content'>{{'This widget allows you to add one or multiple questions, with one or more
        possible replies, with the choice of showing results in the app in real time. It can be used for Polls, Surveys,
        Votes, Requests to volunteer, etc.' | translate}}</p>
    </div>
    <div fxFlex>
      <app-custom-select-create-autocomplete [dataType]="'category'" [dataTypeDisplay]="'Category'"
        [placeholder]="'Select / Add Category'" [useOrg]="true" [canDelete]="false"
        (onSelectReturn)="block.data.category = $event._id" [type]="'tileblock'" [onBlur]="true"
        [value]="block.data.category"></app-custom-select-create-autocomplete>
      <!-- <mat-form-field>
        <input [style.visibility]="'hidden'" matInput name="category" [(ngModel)]="block.data.category">
        <mat-label>Category</mat-label>
        <mat-select ng-model="category" [required]="true" (selectionChange)="block.data.category = $event.value" [value]="block.data.category">
          <mat-option value="-1" >Select Category</mat-option>
          <mat-option *ngFor="let itm of tileCategories" [value]="itm._id" >{{itm.name}}</mat-option>
        </mat-select>
      </mat-form-field> -->
    </div>
  </div>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex>
      <mat-form-field class="inputInside">
        <span matPrefix>* &nbsp;</span>
        <mat-checkbox style="width: 98%;" value="true" [checked]="block.data.mandatory"
          [(ngModel)]="block.data.mandatory" (change)="onChange($event)" title='Field is mandatory'>
          <input matInput type="text" [(ngModel)]="block.data.questionText" class="form-control"
            placeholder="{{'Type question or text here' | translate}}">
        </mat-checkbox>
      </mat-form-field>
    </div>
  </div>
  <div class="decription-survey">{{'User can select' | translate}}</div>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex>
      <mat-radio-group aria-label="Select an option" [(ngModel)]="block.data.questionType"
        (change)="controlChange($event.value)">
        <mat-radio-button value="single">{{'One Answer' | translate}}</mat-radio-button>
        <mat-radio-button value="multiple">{{'Multiple Answers' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div fxFlex *ngIf="block.data.questionType === 'single'">
      <mat-radio-group aria-label="Select an option" [(ngModel)]="block.data.inputControlType"
        (change)="controlChange($event.value)">
        <mat-radio-button value="radio">{{'Radio Button' | translate}}</mat-radio-button>
        <mat-radio-button value="dropdown">{{'Dropdown' | translate}}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex>
      <mat-checkbox value="true" [checked]="block.data.showInApp" [(ngModel)]="block.data.showInApp"
        (change)="onChange($event)">{{'Show results in app' | translate}}</mat-checkbox>
    </div>
    <div fxFlex>
      <mat-checkbox value="true" [checked]="block.data.isNote" [(ngModel)]="block.data.isNote"
        (change)="onChange($event)">{{'Take Notes' | translate}}</mat-checkbox>
    </div>
  </div>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex>
      {{'In app possible replies:' | translate}}
    </div>
    <div fxFlex style="text-align: right;">
      <button mat-raised-button color="primary" (click)="addOption($event)"
        class="btn-info btn-xs btn-option-box">{{'Add Option' | translate}}</button>
    </div>
  </div>
  <div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
    <div fxFlex>
      <div *ngFor="let opt of block.data.options; let i = index; trackBy:trackByIndex" class="row cc-options-main-row">
        <div class="cc-options-row">
          <mat-form-field class="example-full-width">
            <span matPrefix>{{i + 1}}.</span>
            <input matInput [(ngModel)]="block.data.options[i].option" placeholder="{{'Type option here' | translate}}"
              (change)="onChange($event)">
            <button mat-icon-button matSuffix [class.containsData]="block.data.options[i].confirmation"
              (click)="editConfirm($event, i)" [attr.aria-label]="'Add Confirmation'" title="Add Confirmation">
              <mat-icon>check_circle</mat-icon>
            </button>
            <button mat-icon-button matSuffix [class.containsData]="block.data.options[i].alert"
              (click)="editAlert($event, i)" [attr.aria-label]="'Add Alert'" title="{{'Add Alert' | translate}}">
              <mat-icon>notification_important</mat-icon>
            </button>
            <button mat-icon-button matSuffix [class.containsData]="block.data.options[i].popup"
              (click)="editPopup($event, i)" [attr.aria-label]="'Pop Up'" title="{{'Pop Up' | translate}}">
              <mat-icon>web_asset</mat-icon>
            </button>
            <button mat-icon-button matSuffix (click)="addSubOption($event, i, 'questions')"
              [attr.aria-label]="'Add Question'" title="{{'Add Question' | translate}}">
              <img title="Add Question" class="cc-delete-quest-option" src="/assets/img/add_sub_questionnaire.png">
            </button>
            <button mat-icon-button matSuffix (click)="addSubOption($event, i, 'description')"
              [attr.aria-label]="'Add Textbox'" title="{{'Add Textbox' | translate}}">
              <img title="Add Textbox" class="cc-delete-quest-option" src="/assets/img/add_sub_entry.png">
            </button>
            <button mat-icon-button matSuffix (click)="removeOption($event, i)" [attr.aria-label]="'Remove option'"
              title="{{'Remove option' | translate}}">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div *ngFor="let subOpt of opt?.subQuestions; let subIndex = index; trackBy:trackByIndex">
          <questionnaire-sub-option
            *ngIf="!utils.isEmptyObject(subOpt) && subOpt.hasOwnProperty('type') && subOpt['type'] === 'questions'"
            [questionWidth]="499" [optionWidth]="486" [isLevel]="true" (removeSubLevel)="deleteLevel($event)"
            (changeSubLevel)="updateLevel($event)" [levelIndex]="1" [currentIndex]="subIndex" [parentIndex]="i"
            [subOption]="subOpt">
          </questionnaire-sub-option>
          <description-sub-option
            *ngIf="!utils.isEmptyObject(subOpt) && subOpt.hasOwnProperty('type') && subOpt['type'] === 'description'"
            [selectWidth]="428" [descriptionWidth]="494" (removeSubLevel)="deleteLevel($event)"
            (changeSubLevel)="updateLevel($event)" [levelIndex]="1" [currentIndex]="subIndex" [parentIndex]="i"
            [subOption]="subOpt">
          </description-sub-option>
        </div>
      </div>
    </div>
  </div>
</div>