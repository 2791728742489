import { ErrorHandler, Inject, Injectable, InjectionToken, Injector } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import * as Rollbar from 'rollbar';
import { VERSION } from 'src/version';
import { RequestService } from './request.service';
// import { execSync } from 'child_process';

let rollbarConfig = {
  accessToken: environment.rollbar.apiKey,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: environment.environment === 'local' ? false : true,
  payload: {
    environment: environment.environment,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: VERSION.git,
        guess_uncaught_frames: true,
      }
    }
  }
};

@Injectable()
export class LoggerService {
  public apm: any;
  public errorObject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  log(error) {
    if (!environment.production)
      // console.log('My Error:', error);
      this.errorObject.next(error);
  }

  consoleLog(...args: any[]) {
    if (environment.environment !== 'prod') {
      console.log(args);
    }
  }
}

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}

// export function codeVersion() {
//   return execSync("git rev-parse --short HEAD").toString().trim();
// }

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  public showError: boolean = true;
  constructor(private injector: Injector, private logger: LoggerService, @Inject(RollbarService) private rollbar: Rollbar, private requestService: RequestService) {
    super();
  }

  handleError(error) {
    let router = this.injector.get(Router);
    // router.navigate(['/error/400']);
    //console.log('error', error);
    // if (this.logger.apm)
    //   this.logger.apm.captureError(error);
    let userDetails = this.requestService.currentUser;
    if (userDetails)
      this.rollbar.configure({
        payload: {
          person: {
            id: userDetails._id,
            email: userDetails.email,
            name: userDetails.name
          }
        }
      });

    this.rollbar.error(error.originalError || error);
    if (error instanceof HttpErrorResponse) {
      //Backend returns unsuccessful response codes such as 404, 500 etc.
      console.error('Backend returned status code: ', error.status);
      console.error('Response body:', error.message);
      // router.navigate(['/error/400']);
    } else {
      //A client-side or network error occurred.
      this.logger.log(error.message);
    }
    if (this.showError) {
      this.showError = false;
      super.handleError(error);
    }
  }
}
