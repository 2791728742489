import { EventEmitter, OnDestroy } from "@angular/core";
import { Component, OnInit, Input, AfterViewInit, Output } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { Subscription } from "rxjs";
import { TokBoxService } from "src/app/shared";

@Component({
    selector: 'app-video',
    templateUrl: './video.component.html',
    styleUrls: ['./video.component.scss']
})

export class VideoComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() isAutoPlay: boolean = false;
    @Input() src: string = undefined;
    @Input() isOnLoop: boolean = false;
    @Input() volume: number = 0.1;
    @Input() width: number = 300;
    @Input() height: number = 3;
    @Input() id: string = undefined;
    @Input() mediaPlayerUID: string = undefined;

    @Output() isVideoPlaying = new EventEmitter<boolean>(false);
    @Output() isVideoEnded = new EventEmitter<boolean>(false);

    type: string = 'video/mp4';
    isWebkit: boolean = false;

    private subscriptions: Subscription[] = [];

    constructor(private opentokService: TokBoxService, private deviceService: DeviceDetectorService) { }

    ngOnInit() {
        if (this.src) {
            if (this.src.toLowerCase().indexOf('.ogg') !== -1)
                this.type = 'video/ogg';
        }

        // console.log(this.deviceService.getDeviceInfo().browser)
        this.isWebkit = this.deviceService.getDeviceInfo().browser.toLowerCase().indexOf('edge') !== -1 || this.deviceService.getDeviceInfo().browser.toLowerCase().indexOf('chrome') !== -1;

        this.subscriptions.push(this.opentokService.playAfterPauseMedia.subscribe((video) => {
            if (video && this.isAutoPlay && video.uuid === this.id) {
                let element: any = document.getElementById(this.mediaPlayerUID + 'player-' + this.id);
                if (element)
                    element.play();
            }
        }));
    }

    ngAfterViewInit() {
        let element: any = document.getElementById(this.mediaPlayerUID + 'player-' + this.id);
        if (this.isAutoPlay && element) {
            element.play().then(_ => {

            }).catch(error => {
                this.opentokService.showPauseDialog.next(true);
            });
        }

        if (element) {
            element.onpause = () => {
                if (!element.ended)
                    this.isVideoPlaying.emit(false);
            }

            element.onplaying = () => {
                this.isVideoPlaying.emit(true);
            }

            element.onended = () => {
                if (element.currentTime >= element.duration)
                    this.isVideoEnded.emit(true);
            }
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }
}