export const environment = {
  production: false,
  lockSite: false,
  underConstruction: false,
  serverTileUrl: 'https://staging.studio.stellar.online/',
  serverUrl: 'https://staging.api.stellar.online/api/',
  serverUrlNoSlash: 'https://staging.api.stellar.online/api',
  casUrl: 'https://staging.api.stellar.online/cas/',
  productId: '1ec4a3a3-6223-486c-a264-0a778895b295',
  vimeoUrl: 'https://player.vimeo.com/video/',
  serverHostUrl: 'https://stellar.conference.interactivelife.me',
  serverRecordHostUrl: 'https://record.stellar.conference.interactivelife.me',
  paymentGateway: 'https://staging-accounts.interactivelife.me',
  serverredirectUrl: 'https://www.interactivelife.com/',
  registrationUrl: 'https://stellar-registration.interactivelife.me',
  s3ImagesBucketUrl: 'https://stellar-images-bucket-staging.s3.amazonaws.com',
  s3BucketUrl: 'https://stellar-frontend-media.s3.amazonaws.com/',
  serverQuuUrl: 'https://quuit.com/Quu/mobile',
  integratedProduct: false,
  s3BucketName: 'YOUR-Bucket-Name-ID',
  s3AccessKeyId: 'YOUR-ACCESS-KEY-ID',
  s3SecretAccessKey: 'YOUR-SECRET-ACCESS-KEY',
  s3Region: 'YOUR-REGION',
  aliveAppIntervalMinutes: 30, // if you keep app inactive
  aliveCheckAppIntervalMinutes: 60, // if you keep app inactive pop a message
  repeatValidateSeconds: 90,
  hasListingDirectory: true,
  identityName: 'Conference',
  serverName: 'Stellar Conference',
  orgType: 'conference',
  orgSubType: 'conference',
  subServerName: 'Stellar Conference',
  profileImageURL: '',
  enableTranslation: false,
  environment: 'staging',
  customKeys: {
    roleAdmin: '5eb137f1d3123a407c7c97cc',// Admin
    roleView: '5ee76348d3123a0ef0d8cb63',// Viewer
    roleEdit: '5ee761c8d3123a0ef0d8cb61',// Editor
  },
  customKeysView: {
    Admin: 'Admin',
    Attendee: 'View',
    Speaker: 'Edit',
  },
  tokBox: {
    apiKey: '46643372'
  },
  ipStack: {
    apiKey: '4e73b76fa09e6cfe386cf11e94c11e90'
  },
  vimeo: {
    server: 'https://vimeo.com/',
    apiKey: '34bae0171d63f19491dafd82da572571'
  },
  rollbar: {
    apiKey: '69d9ac6aeb74442895c3187802c7584c'
  },
  trackingUrl: 'https://staging.track.stellar.online',
  quu: {
    ablyKey: '1M_Img.BBTlpQ:nUhp6CSKY3_sf_UTP4uDzBfRPv4Wf9Pe8qz8EDWGOv4'
  },
  facebook: {
    appId: 537967897617144
  }
};
