import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ChangeDetectorRef } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-panel-layout-component',
  templateUrl: './panel-layout-component.component.html',
  styleUrls: ['./panel-layout-component.component.scss']
})
export class PanelLayoutComponent implements OnInit {
  public subscriptions: any[] = <any>[];
  public marginButtom: number = 5;
  public layoutData: any = [];
  @Input() session: OT.Session = undefined;
  @Input() useMaxPerRow: boolean = false;
  @Input() isProducer: boolean = false;
  @Input() uuid: string = undefined;
  @Input('attendeeMap') attendeeMap: Map<string, object>;
  @Input() editor: boolean = true;
  @Input() showAudioStatus: boolean = false;
  public _camerasList: any[] = [];
  @Input()
  set camerasList(camerasList: any[]) {
    this._camerasList = camerasList;
  }
  get camerasList(): any[] {
    return this._camerasList;
  }
  public _cameraSettings: any = { totalCameras: 3, camerasPerRow: undefined };
  @Input() totalCameras: number = this._cameraSettings.totalCameras;
  @Input() camerasPerRow: number = undefined;
  @Input()
  set cameraSettings(cameraSettings: any) {
    this.totalCameras = cameraSettings.totalCameras;
    if (cameraSettings.hasOwnProperty('camerasPerRow')) {
      this.camerasPerRow = cameraSettings.camerasPerRow;
    }
    let camerasList = [];
    for (let i = 0; i < cameraSettings.totalCameras; i++) {
      camerasList.push({ id: 'camera-' + i, name: 'Camera ' + i });
    }
    this._camerasList = camerasList;
  }
  get cameraSettings(): any {
    return this._cameraSettings;
  }
  @Input() panelSessions: any[] = [];

  @Output() updateLayout = new EventEmitter<any>();
  @Output() controlUser = new EventEmitter<any>();
  constructor(public renderer: Renderer2, private deviceService: DeviceDetectorService) {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.marginButtom = 1;
    }
  }

  ngOnInit() {
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  getBestFit(containerWidth, containerHeight, numRects, aspectRatio, margin, colsperRow) {
    let best = { area: 0, cols: 0, rows: 0, width: 0, height: 0 };
    if (colsperRow) {
      let totalRows = Math.ceil(numRects / colsperRow);
      let width = (containerWidth / colsperRow) - (this.marginButtom * (colsperRow - 1)) - 1;
      let height = ((9 * (containerWidth) / colsperRow) / 16);
      const area = width * height;
      best = { area, width, height, rows: totalRows, cols: colsperRow };
    } else {
      // For each combination of rows + cols that can fit
      // the number of rectangles, place them and see the area.
      for (let cols = numRects; cols > 0; cols--) {
        const rows = Math.ceil(numRects / cols);
        const hScale = containerWidth / (cols * aspectRatio);
        const vScale = containerHeight / rows;
        let width;
        let height;

        // Determine which axis is the constraint.
        if (hScale <= vScale) {
          width = (containerWidth / cols) - (margin * cols);
          height = width / aspectRatio;
        } else {
          height = (containerHeight / rows) - (margin * rows);
          width = height * aspectRatio;
        }
        const area = width * height;
        if (area > best.area) {
          best = { area, width, height, rows, cols };
        }
      }
    }
    return best;
  }
  getRowHeightItem(offsetWidth, offsetHeight) {
    let data = this.getBestFit(offsetWidth, offsetHeight, this.totalCameras, 16 / 9, this.marginButtom, this.camerasPerRow);
    return Math.floor(data.height) + 'px';

  }
  getRowWidthItem(offsetWidth, offsetHeight) {
    let data = this.getBestFit(offsetWidth, offsetHeight, this.totalCameras, 16 / 9, this.marginButtom, this.camerasPerRow);
    return Math.floor(data.width) + 'px';
  }
  getHCameraRatio(w) {
    return (9 * w) / 16;
  }
  getWCameraRatio(h) {
    return (16 * h) / 9;
  }

  controlAudioVideo(event: any, type: string) {
    this.controlUser.emit({ event, type });
  }
}
