<div class="col-xl-12">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content>
		<div class="userInfo select-page-body" *ngIf="metaFieldSetting">
			<mat-card>
				<mat-card-content>
					<div fxLayout="row" fxLaout.lt-sm="column" fxFlex>
						<div fxFlex>
							<div fxLayout="row" fxLaout.lt-sm="column" fxFlex fxLayoutGap="0.5vw">
								<div fxFlex="30">
									<mat-form-field style="width:100%">
										<input matInput [required]="!metaFieldSetting['name'].nullable"
											name="{{metaFieldSetting['name'].name}}"
											ng-model="metaFieldSetting['name'].name" [(ngModel)]="data.data['name']"
											[required]="!metaFieldSetting['name'].nullable"
											placeholder="{{metaFieldSetting['name'].displayName | translate}}"
											[errorStateMatcher]="esMatcher">
										<mat-error
											*ngIf="!metaFieldSetting['name'].nullable && (data.data[metaFieldSetting['name'].name] === undefined || data.data[metaFieldSetting['name'].name] === '')">
											{{metaFieldSetting['name'].displayName | translate}} is required.
										</mat-error>
									</mat-form-field>
								</div>
								<div fxFlex="20" fxGrow="0">
									<mat-form-field>
										<mat-select [required]="!metaFieldSetting['defaultlayout'].nullable"
											name="{{metaFieldSetting['defaultlayout'].name}}"
											placeholder="{{metaFieldSetting['defaultlayout'].displayName | translate}}"
											[(value)]="data.data['defaultlayout']">
											<mat-option *ngFor="let layout of ['grid','slideshow']" [value]="layout">
												{{layout | translate}}
											</mat-option>
										</mat-select>
									</mat-form-field>

									<!-- <button class="slideshowsettings" mat-icon-button
										[matMenuTriggerFor]="slideshowsettings" color="primary"
										title="{{'Slideshow Settings' | translate}}">
										<mat-icon>settings</mat-icon>
									</button> -->
								</div>
								<div fxFlex="50" style="text-align: right;text-align: -webkit-right;"
									fxLayoutAlign="end center">
									<span *ngIf="selected.value === 0">
										<button (click)="openImageLibrary()" mat-button color="primary"
											title="{{'Image Library' | translate}}" style="margin-right: 10px;">
											{{'Image Library' | translate}}
										</button>
										<button (click)="addImages()" mat-button color="primary"
											title="{{'Add Image' | translate}}">
											{{'Add Image' | translate}}
										</button>
									</span>
								</div>
							</div>
							<mat-tab-group animationDuration="0ms" dynamicHeight [selectedIndex]="selected.value"
								(selectedIndexChange)="selected.setValue($event)">
								<mat-tab label="Image List">
									<div fxLayout="row" fxLaout.lt-sm="column" fxFlex style="margin-top: 10px;">
										<div fxFlex>
											<!-- <div fxLayout="row" fxLaout.lt-sm="column" fxFlex
												style="margin-bottom:5px;">
												<div fxFlex>
													<h3 style="color: #a0a0a0;font-size: 13px;">Image List</h3>
												</div>
												<div fxFlex fxLayoutAlign="end center" style="text-align: right;text-align: -webkit-right;">
													<button (click)="openImageLibrary()" mat-button color="primary"
														title="{{'Image Library' | translate}}"
														style="margin-right: 10px;">
														{{'Image Library' | translate}}
													</button>
													<button (click)="addImages()" mat-button color="primary"
														title="{{'Add Image' | translate}}">
														{{'Add Image' | translate}}
													</button>
												</div>
											</div> -->
											<div fxLayout="row" fxLaout.lt-sm="column" fxFlex>
												<div fxFlex class="theDataSelected">
													<div fxLayout="row" fxLaout.lt-sm="column" fxFlex>
														<div fxFlex>
															<div cdkDropList #doneList="cdkDropList"
																[cdkDropListData]="imageList" class="example-list"
																(cdkDropListDropped)="drop($event)">
																<div class="example-box-selected"
																	style="border-bottom: solid 1px #ccc;cursor: default;"
																	*ngFor="let img of imageList;let index = index"
																	cdkDrag>
																	<div fxLayout="row" fxLaout.lt-sm="column" fxFlex
																		fxLayoutGap="10px">
																		<div fxFlex="3">
																			<div class="example-handle" cdkDragHandle
																				style="cursor: move; margin: 50% auto;color:#242852">
																				<svg width="24px" fill="currentColor"
																					viewBox="0 0 24 24">
																					<path
																						d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z">
																					</path>
																					<path d="M0 0h24v24H0z" fill="none">
																					</path>
																				</svg>
																			</div>
																		</div>
																		<div fxFlex="10" style="text-align: center;">
																			<img *ngIf="img.thumbnail && img.thumbnail !== ''"
																				loading="lazy" src="{{img.thumbnail}}"
																				style="    width: auto;
																				max-width: 100%;max-height: 90px;" />
																			<img *ngIf="!(img.thumbnail && img.thumbnail !== '')"
																				loading="lazy" src="{{img.url}}" style="    width: auto;
																				max-width: 100%;max-height: 90px;" />
																		</div>
																		<div fxFlex>
																			<mat-form-field style="width:100%">
																				<input matInput [required]="true"
																					name="name" ng-model="name"
																					[(ngModel)]="img.name"
																					placeholder="{{'Title' | translate}}"
																					[errorStateMatcher]="esMatcher"
																					maxlength="100">
																				<mat-hint align="end">{{img.name.length
																					||
																					0}}/100</mat-hint>
																				<mat-error
																					*ngIf="img.name === undefined || img.name === ''">
																					{{'Title' | translate}} is required.
																				</mat-error>
																			</mat-form-field>
																		</div>
																		<div fxFlex>
																			<mat-form-field style="width:100%">
																				<input matInput type="text"
																					[required]="true" name="url"
																					ng-model="url" [(ngModel)]="img.url"
																					placeholder="{{'URL' | translate}}"
																					[errorStateMatcher]="esMatcher"
																					pattern="(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})">
																				<mat-hint align="end">{{'URL format' |
																					translate}}</mat-hint>
																				<mat-error
																					*ngIf="img.url === undefined ||img.url === ''">
																					{{'URL' | translate}} is required.
																				</mat-error>
																			</mat-form-field>
																		</div>
																		<div fxFlex="5" fxLayoutAlign="end center"
																			style="text-align: right;">
																			<button (click)="removeImage(index)"
																				mat-icon-button color="primary"
																				title="{{'Remove Image' | translate}}">
																				<mat-icon>delete</mat-icon>
																			</button>
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</mat-tab>
								<mat-tab label="Slideshow Settings">
									<div fxLayout="row" fxLaout.lt-sm="column" fxFlex style="margin-top: 10px;">
										<div fxFlex="50">
											<mat-form-field style="width:100%">
												<mat-select name="thumbnailsStatus"
													placeholder="{{'Thumbnails Status' | translate}}"
													[(value)]="slideshowsettings['thumbnailsStatus']">
													<mat-option *ngFor="let status of thumbnailsStatuses"
														[value]="status.value">
														{{status.name | translate}}
													</mat-option>
												</mat-select>
											</mat-form-field>
											<mat-form-field style="width:100%" class="inputClass">
												<input matInput type="number" [required]="true" name="name"
													ng-model="mainImage" [(ngModel)]="slideshowsettings['mainImage']"
													placeholder="{{'Main Image %' | translate}}"
													[errorStateMatcher]="esMatcher" min="0" max="100" pattern="\d+"
													(input)="filterNumber($event.target.value, 'mainImage')"
													[disabled]="slideshowsettings['thumbnailsStatus'] === 4">
												<mat-icon class="suffixCss" matSuffix>percent</mat-icon>
												<mat-icon class="infoCss" matSuffix
													title="Percentage that the main image occupies in the Image Gallery component">
													info</mat-icon>
												<!-- <mat-hint align="end">{{img.name.length ||
													0}}/100</mat-hint> -->
												<mat-error
													*ngIf="slideshowsettings['mainImage'] === undefined || slideshowsettings['mainImage'] === ''">
													{{'Main Image' | translate}} is required.
												</mat-error>
											</mat-form-field>
											<mat-form-field style="width:100%" class="inputClass">
												<input matInput type="number" [required]="true" name="name"
													ng-model="thumbnails" [(ngModel)]="slideshowsettings['thumbnails']"
													placeholder="{{'Thumbnails %' | translate}}"
													[errorStateMatcher]="esMatcher" min="0" max="100" pattern="\d+"
													(input)="filterNumber($event.target.value, 'thumbnails')"
													[disabled]="slideshowsettings['thumbnailsStatus'] === 4">
												<mat-icon class="suffixCss" matSuffix>percent</mat-icon>
												<mat-icon class="infoCss" matSuffix
													title="Percentage that the thumbnail images occupy in the Image Gallery component">
													info</mat-icon>
												<!-- <mat-hint align="end">{{img.name.length ||
													0}}/100</mat-hint> -->
												<mat-error
													*ngIf="slideshowsettings['thumbnails'] === undefined || slideshowsettings['thumbnails'] === ''">
													{{'Thumbnails' | translate}} is required.
												</mat-error>
											</mat-form-field>
											<div class="totalError"
												*ngIf="slideshowsettings['mainImage'] === undefined || slideshowsettings['mainImage'] === '' ||slideshowsettings['thumbnails'] === undefined || slideshowsettings['thumbnails'] === '' || slideshowsettings['mainImage'] + slideshowsettings['thumbnails'] !== 100 ">
												Please make sure the main image and thumbnail percentages add up to 100
											</div>
										</div>
										<div fxFlex></div>
									</div>
								</mat-tab>
							</mat-tab-group>

						</div>
					</div>
				</mat-card-content>
			</mat-card>
		</div>
	</div>
	<div mat-dialog-actions>
		<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
		<button *ngIf="!data.data._id" mat-raised-button (click)="saveData('create')" [disabled]="loading">{{'Create' |
			translate}}</button>
		<button *ngIf="data.data._id" mat-raised-button (click)="saveData('edit')" [disabled]="loading">{{'Save' |
			translate}}</button>
	</div>
	<br />
</div>