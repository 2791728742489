<div class="col-xl-12 media-library-container-dialog" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content style="overflow:visible">
		<mat-card [class.isNotMobile]="!isMobile">
			<div [id]="data.libraryId + '-box'" style="height: 100%;" class="innerDiv">
				<app-media-library [id]="data.mediaId" [width]="data.width" [libraryId]="data.libraryId"
					[loop]="data.loop" [volume]="data.volume" [autoplay]="data.autoPlay" [menuState]="'in'"
					[disableExpand]="true" [selectorId]="data.libraryId + '-box'">
				</app-media-library>
			</div>
		</mat-card>
	</div>
	<div mat-dialog-actions>
		<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
	</div>
	<br />
</div>