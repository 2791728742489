import { ChangeDetectorRef, EventEmitter, OnDestroy } from "@angular/core";
import { Component, OnInit, Input, AfterViewInit, Output } from "@angular/core";
import * as moment from "moment";
import { DeviceDetectorService } from "ngx-device-detector";
import { Subscription } from "rxjs/internal/Subscription";
import { TokBoxService } from "src/app/shared";

@Component({
    selector: 'app-audio',
    templateUrl: './audio.component.html',
    styleUrls: ['./audio.component.scss']
})

export class AudioComponent implements OnInit, AfterViewInit, OnDestroy {

    @Input() isAutoPlay: boolean = false;
    @Input() src: string = undefined;
    @Input() isOnLoop: boolean = false;
    @Input() volume: number = 1;
    @Input() width: number = 300;
    @Input() height: number = 3;
    @Input() id: string = undefined;
    @Input() mediaPlayerUID: string = '';

    @Output() isAudioPlaying = new EventEmitter<boolean>(false);
    @Output() isAudioEnded = new EventEmitter<boolean>(false);
    @Output() isAudioLoaded = new EventEmitter<boolean>(false);

    type: string = 'audio/mp4';
    isWebkit: boolean = false;
    // isSafari: boolean = false;
    audioPlayer: any = undefined;
    durationAsString: any = '0.00';
    duration: any = 0;
    currentTimeAsString: any = '0.00';
    currentTime: any = 0;
    isPlaying: boolean = false;
    // isIOS: boolean = false;
    hasError: boolean = false;
    // isMobile: boolean = false;

    private subscriptions: Subscription[] = [];

    constructor(private opentokService: TokBoxService, private deviceService: DeviceDetectorService, private changeDetectorRef: ChangeDetectorRef) {
        // this.isIOS = this.deviceService.os.toLowerCase() == 'ios' ? true : false;
        // this.isMobile = this.deviceService.isMobile() || this.deviceService.isTablet();
    }

    ngOnInit() {
        if (this.src) {
            if (this.src.toLowerCase().indexOf('.mp3') !== -1)
                this.type = 'audio/mpeg'
            else if (this.src.toLowerCase().indexOf('.wav') !== -1)
                this.type = 'audio/wav';
            else if (this.src.toLowerCase().indexOf('.ogg') !== -1)
                this.type = 'audio/ogg';
            else if (this.src.toLowerCase().indexOf('.aac') !== -1)
                this.type = 'audio/aac';
        }

        this.isWebkit = this.deviceService.getDeviceInfo().browser.toLowerCase().indexOf('edge') !== -1 || this.deviceService.getDeviceInfo().browser.toLowerCase().indexOf('chrome') !== -1;
        // this.isSafari = this.deviceService.getDeviceInfo().browser.toLowerCase().indexOf('safari') !== -1;

        this.subscriptions.push(this.opentokService.startAudio.subscribe(audio => {
            if (audio !== undefined && audio.uuid === this.id && this.audioPlayer) {
                if (audio.play) {
                    this.audioPlayer.load();
                    this.audioPlayer.play();
                }
                else {
                    this.audioPlayer.pause();
                }
            }
        }));

        this.subscriptions.push(this.opentokService.playAfterPauseMedia.subscribe((audio) => {
            if (audio && this.audioPlayer && this.isAutoPlay && audio.uuid === this.id) {
                this.audioPlayer.play();
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    ngAfterViewInit() {
        this.audioPlayer = document.getElementById(this.mediaPlayerUID + 'player-' + this.id);
        if (this.isAutoPlay && this.audioPlayer) {
            this.audioPlayer.play().then(_ => {

            }).catch((error: string) => {
                // console.log('audio', error)
                if (error.toString().toLowerCase().indexOf("didn't interact") != -1)
                    this.opentokService.showPauseDialog.next(true);
            });

            this.audioPlayer.addEventListener('error', (e) => {
                this.hasError = true;
                // console.log('list', e)
                // this.detectChanges();
                // console.log('eee', e)
            }, true);
        }

        // this.audioPlayer.onstalled = () => {
        //     // this.hasError = true;
        //     console.log('staled')
        // }

        this.audioPlayer.onabort = () => {
            this.hasError = true;
        }

        this.audioPlayer.onpause = () => {
            if (!this.audioPlayer.ended) {
                this.isAudioPlaying.emit(false);
            }
            this.isPlaying = false;
        }

        this.audioPlayer.onloadeddata = () => {
            this.isAudioLoaded.emit(true);
            this.setDuration(this.audioPlayer.duration);
        }

        this.audioPlayer.onplaying = () => {
            this.isAudioPlaying.emit(true);
            this.isPlaying = true;
        }

        this.audioPlayer.ontimeupdate = () => {
            this.setCurrentTime(this.audioPlayer.currentTime);
            if (this.currentTime > this.duration) {
                this.setDuration(this.currentTime);
            }
        }

        this.audioPlayer.onended = () => {
            this.isAudioEnded.emit(true);
        }
    }

    private setDuration(time) {
        this.duration = time;
        this.durationAsString = moment.utc(Math.round(this.duration) * 1000).format('mm:ss');
    }

    private setCurrentTime(time) {
        this.currentTime = time;
        this.currentTimeAsString = moment.utc(time * 1000).format('mm:ss');
    }

    changeVolume(volume) {
        this.volume = volume;
    }

    flipVolume() {
        if (this.volume === 0)
            this.volume = 1;
        else
            this.volume = 0;
    }

    seek(dragTime) {
        this.audioPlayer.currentTime = dragTime;
        this.setCurrentTime(dragTime);
    }

    togglePlay(doPlay) {
        if (doPlay) {
            this.audioPlayer.play();
        }
        else {
            this.audioPlayer.pause();
        }
    }

    detectChanges() {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    audioHasError() {
        this.hasError = true;
        // console.log('method')
    }
}