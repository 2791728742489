import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Dictionary } from 'src/app/shared/services/interface';
import { ContentType } from 'src/app/shared/services/enums';
import { UserActivityService } from 'src/app/shared/services/userActivity.service';
import { environment } from 'src/environments/environment';
import { guid } from 'src/app/shared/helpers';
import * as moment from 'moment';
import { RequestService } from 'src/app/shared';

@Injectable()
export class RequestExternalService {
  constructor(protected http: HttpClient, protected requestService: RequestService,
    protected userActivityService: UserActivityService) {
  }
  public callApiMethod(methodName, dataObj: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void) {
    if (this.methodObjects.hasOwnProperty(methodName)) {
      this.methodObjects[methodName](dataObj, (data, error) => {
        if (error) {
          callback(undefined, error);
        }
        if (data) {
          callback(data, undefined);
        }
      });
    } else {
      callback(dataObj, undefined);
    }
  }
  public methodObjects = {
  }
  protected jsonGetRequest(urlString: string, callback: (json?: any, error?: any) => void, params?: Dictionary, token = this.requestService.getToken()) {
    if (urlString) {
      let urlComps = urlString;
      if (params) {
        for (let urlItem of Object.keys(params)) {
          urlComps += '&' + urlItem + '=' + params[urlItem];
        }
      }
      return this.jsonRequest(urlComps, callback, token, 'GET');
    } else {
      return;
    }
  }
  protected jsonRequest(urlString: string,
    callback: (json: any, error: any) => void,
    token = this.requestService.getToken(),
    method: string = 'POST',
    postBody: any = undefined,
    contentType: string = ContentType.JSON) {
    if (urlString) {
      let url: string = urlString || '';
      let headers = {
        'Accept': 'application/json',
      };
      if (contentType)
        headers['Content-Type'] = contentType;
      if (token) {
        headers['Authorization'] = token;
      }
      const httpHeaders = new HttpHeaders(headers);
      let httpOptions: any = {
        responseType: 'json',
        headers: httpHeaders,
        method: method
      }

      let bodyString = postBody;
      if (method === 'POST') {
        bodyString = JSON.stringify(postBody);
        httpOptions['body'] = bodyString;
      }
      return this.http.request(method, url, httpOptions)
        // .pipe(map(
        //     (res: any) => {
        //       // below might need to be changed
        //       if (res.status >= 404) {
        //         window.location.reload();
        //       } else if (res.status >= 400) {
        //         callback(undefined, 'server');
        //         return;
        //       }
        //       return res;
        //     }
        //   ))
        .subscribe(
          (data) => {
            let version = undefined;
            let showdialog = undefined;
            // this.userActivityService.insertLog('api', 'jsonRequest', 'Api return Successfully', {version, showdialog}, url);
            if (data.hasOwnProperty('status')) {
              if (!data['status']) {
                this.userActivityService.insertLog('api', 'jsonRequest', 'Api return Successfully but status false', { data, showdialog, headers }, url);
              } else {
                this.userActivityService.insertLog('api', 'jsonRequest', 'Api return Successfully', { version, showdialog, headers }, url);
              }
            }
            callback(data, undefined);
            // console.log(url, data);
          },
          (err) => {
            this.userActivityService.insertLog('api', 'jsonRequest', 'Api Return Unsuccessfully', { err, headers, httpOptions }, url);
            if (err) {
              if (err.status >= 404) {
                // window.location.reload();
                callback(undefined, 'Refresh page');
              } else {
                callback(undefined, ' Connectivity issue.');
              }
            }
          });

    } else {
      // this.logger.log('Failed to create URL');
      //console.log('Failed to create URL');
    }
  }
}
