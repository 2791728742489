<!-- <div [ngStyle]="{'z-index': _zIndex, 'left': _leftPosition ? _leftPosition + 'px':'', 'display': _hide ? 'none' : 'initial'}"
	cdkDrag [class.draggable]="!disableDrag" #answersDraggable (cdkDragStarted)="dragged(true)"
	[cdkDragDisabled]="disableDrag" [@showDialog]="animationState" cdkDragBoundary=".dragBoundary">
	<mat-accordion>
		<mat-expansion-panel [class.grey-out]="!disableDrag" [expanded]="expanded" [hideToggle]="!disableDrag"
			[disabled]="true">
			<mat-expansion-panel-header class="panel-header" *ngIf="!disableDrag"
				[ngStyle]="{'padding': disableDrag? '0 20px 0 10px' : '0 10px 0 10px'}" collapsedHeight="40px"
				expandedHeight="40px">
				<mat-panel-title class="toolbar-label">
					<mat-icon>question_answer</mat-icon>
					{{'Answers' | translate}}
				</mat-panel-title>
				<button mat-icon-button class="drag-handle" cdkDragHandle style="margin: 0 5px 0 0">
					<mat-icon class="toolbar-label">open_with</mat-icon>
				</button>
				<button mat-icon-button title="{{'Close' | translate}}" (click)="closeDialog()">
					<mat-icon class="toolbar-label">close</mat-icon>
				</button>
			</mat-expansion-panel-header> -->
<div style="height: 463px; overflow-y: auto">
	<div class="mainQuestions" *ngFor="let itm of dataList">
		<div class="itemQuestion">
			{{itm.question}}
		</div>
		<div class="dataQuestion" *ngIf="itm.answers">
			<table style="width:100%" border=0>
				<tr *ngFor="let itmans of itm.answers;let index = index">
					<td [class.bgWhite]="index%2===0">{{itmans.value}}</td>
					<td [class.bgWhite]="index%2===0" style="text-align:right">
						<span *ngIf="itmans.total !== undefined">{{itmans.total}} <span
								*ngIf="itmans.total === 1">Answer</span><span
								*ngIf="itmans.total >1 || itmans.total === 0">Answers</span> <span
								*ngIf="itmans.total > 0"> - </span> </span>{{itmans.total}} / {{itmans.percentage}}
						<button mat-stroked-button *ngIf="itmans.percentage !== '' && itmans.percentage !== '0%'"
							[disabled]="loading" style="margin-left: 5px"
							(click)="selectRandomPick(itm, itmans)">{{'Random Pick' | translate}}</button>
					</td>
				</tr>
			</table>
		</div>
	</div>
</div>
<!-- </mat-expansion-panel>
	</mat-accordion>
</div> -->