<div class="video-librar-container" [ngStyle]="{'visibility': fixedDimensions || mediaWidth ? 'visible' : 'hidden'}">
    <div fxLayout="column" class="playlist-menu" [class.expanded]="menuState === 'in'"
        [class.collapsed]="menuState === 'out'"
        [@showMenu]="{ value: menuState, params: { collapse_to: (mediaLeft - 35) + 'px' } }"
        [ngStyle]="{ 'width': fixedDimensions ? '25%' : menuWidth + 'px', 'height': fixedDimensions ? '100%' : containerHeight + 'px', 'float': fixedDimensions? 'left':'unset', 'position': fixedDimensions ? 'unset' : 'inherit' }">
        <div fxFlex fxGrow="0" fxLayout="row">
            <div fxFlex fxGrow="0" *ngIf="!fixedDimensions && !disableExpand">
                <mat-icon (click)="toggleMenu()" *ngIf="menuState === 'in'" [title]="'Collapse' | translate">
                    navigate_next</mat-icon>
            </div>
            <div fxFlex fxLayoutAlign="end end" *ngIf="menuState === 'in'" fxLayoutGap="0.5vw">
                <mat-icon *ngIf="isShuffleOn" (click)="toggleShuffle()" [title]="'Shuffle On' | translate">shuffle_on
                </mat-icon>
                <mat-icon *ngIf="!isShuffleOn" (click)="toggleShuffle()" [title]="'Shuffle Off' | translate">shuffle
                </mat-icon>
                <mat-icon *ngIf="isPlayOn" (click)="togglePlay()" [title]="'Pause' | translate">pause</mat-icon>
                <mat-icon *ngIf="!isPlayOn" (click)="togglePlay()" [title]="'Play' | translate">play_arrow</mat-icon>
                <mat-icon *ngIf="(selectedMedia?.index !== (media.length - 1) && !isShuffleOn) || isShuffleOn || loop"
                    (click)="skipVideo()" [title]="'Skip' | translate">skip_next</mat-icon>
            </div>
            <div fxFlex fxLayout="column" fxLayoutAlign="start" *ngIf="menuState === 'out'" fxLayoutGap="0.5vw">
                <mat-icon fxFlex (click)="toggleMenu()" [title]="'Expand' | translate" *ngIf="width >= 30">
                    navigate_before</mat-icon>
                <mat-icon fxFlex *ngIf="isShuffleOn" (click)="toggleShuffle()" [title]="'Shuffle On' | translate">
                    shuffle_on
                </mat-icon>
                <mat-icon fxFlex *ngIf="!isShuffleOn" (click)="toggleShuffle()" [title]="'Shuffle Off' | translate">
                    shuffle
                </mat-icon>
                <mat-icon fxFlex *ngIf="isPlayOn" (click)="togglePlay()" [title]="'Pause' | translate">pause</mat-icon>
                <mat-icon fxFlex *ngIf="!isPlayOn" (click)="togglePlay()" [title]="'Play' | translate">play_arrow
                </mat-icon>
                <mat-icon fxFlex
                    *ngIf="(selectedMedia?.index !== (media.length - 1) && !isShuffleOn) || isShuffleOn || loop"
                    (click)="skipVideo()" [title]="'Skip' | translate">skip_next</mat-icon>
            </div>
        </div>
        <mat-form-field fxFlex style="width: 100%;" fxGrow="0" *ngIf="menuState === 'in'">
            <mat-label class="search-input">{{'Search' | translate}}...</mat-label>
            <input matInput class="search-input" [(ngModel)]="filterByText" (ngModelChange)="searchByText()">
        </mat-form-field>
        <mat-list class="playlist" fxFlex *ngIf="menuState === 'in'">
            <ng-container *ngFor="let md of media; let index = index">
                <mat-list-item [id]="md.id" [class.selected]="selectedMedia.index === index"
                    (click)="selectVideo(index)" [title]="md.name">
                    <img *ngIf="md.thumbnail" [src]="md.thumbnail" [title]="md.name" />
                    <img *ngIf="!md.thumbnail && md.type === 'audio'" [src]="'/assets/images/audio-default.jpg'"
                        [title]="md.name" />
                    <img *ngIf="!md.thumbnail && (md.type === 'vimeoPlst' || md.type === 'youtubePlst' || md.type === 'video' || md.type === 'vimeoVid' || md.type === 'youtubeVid')"
                        src="/assets/images/videolibrary.png" [title]="md.name" style="max-height:100%" />
                    <span class="media-name">{{md.name}}</span>
                </mat-list-item>
            </ng-container>
        </mat-list>
    </div>
    <div [class.expanded]="menuState === 'in'" [class.collapsed]="menuState === 'out'" class="video-player"
        [ngStyle]="{ 'align-self': selectedMedia && (selectedMedia.type === 'vimeoVid' || selectedMedia.type === 'vimeoPlst') ? 'center' : 'auto', 'width': fixedDimensions ? '75%' : mediaWidth + 'px', 'top': 0, 'left': mediaLeft + 'px', 'position': fixedDimensions ? 'unset' : 'absolute', 'height': fixedDimensions ? '100%' : containerHeight? containerHeight + 'px' : 'unset', 'float': fixedDimensions? 'left':'unset' }">
        <div #mediaLibraryPlayer style="width: 100%; height: 100%;"
            [hidden]="selectedMedia?.type === 'audio' || selectedMedia?.type === 'video'"></div>
        <div *ngIf="selectedMedia?.type === 'video'">
            <app-video *ngIf="selectedMedia.url" [isAutoPlay]="autoplay || overRideAutoPlay" [width]="'100%'"
                [height]="'2'" [id]="selectedMedia.id" [volume]="volume" [src]="selectedMedia.url"
                (isVideoPlaying)="isMediaPlaying($event)" (isVideoEnded)="onMediaEnd()"
                [mediaPlayerUID]="mediaPlayerUID">
            </app-video>
        </div>
        <div *ngIf="selectedMedia?.type === 'audio'">
            <img src="/assets/images/audio-default.jpg" class="audio-background" />
            <div class="audio-player">
                <div class="audio-player-container">
                    <app-audio *ngIf="selectedMedia.url" [isAutoPlay]="autoplay || overRideAutoPlay" [width]="'100%'"
                        [height]="'2'" [id]="selectedMedia.id" [volume]="volume" [src]="selectedMedia.url"
                        (isAudioPlaying)="isMediaPlaying($event)" (isAudioEnded)="onMediaEnd()"
                        [mediaPlayerUID]="mediaPlayerUID">
                    </app-audio>
                </div>
            </div>
        </div>
    </div>
</div>