<div class="room-gallery-container vertical-scroll" style="height: 100%;">
    <div class="row-mosaic">
        <div class="column-mosaic" *ngFor="let col of selectDataMatrix; let index = index">
            <div class="content-mosaic" *ngFor="let dt of col;let indexCol = index" (click)="openRoom(dt)"
                title="{{dt.name}}">
                <div class="roomTitle" [class.addRoomTitle]="index === 0 && indexCol === 0">{{dt.title}}</div>
                <ng-container *ngIf="dt._id === 'create'">
                    <img src="assets/images/add-room.jpg" loading="lazy">
                </ng-container>
                <ng-container *ngIf="dt._id !== 'create'">
                    <img *ngIf="dt.pictureLink && dt.pictureLink !== ''" [src]="dt.pictureLink" loading="lazy">
                    <img *ngIf="!dt.pictureLink || dt.pictureLink === ''" src="assets/images/default-room.jpg"
                        loading="lazy">
                </ng-container>
                <ng-container *ngIf="dt.updatedAt && dt.updatedAt !== ''">
                    <div class="updatedAt">Last updated: {{dt.updatedAt | date: 'MM/dd/yyyy HH:mm'}}</div>
                </ng-container>
            </div>
        </div>
    </div>
</div>