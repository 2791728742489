import { Injectable, NgZone } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { LayoutUtilsService } from './utils/layout-utils.service';
import { UserActivityService } from './userActivity.service';

@Injectable()
export class LoaderService {
    private networkDialog: any = undefined;
    public status: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private layoutUtilsService: LayoutUtilsService, private translate: TranslateService, private zone: NgZone, private userActivityService: UserActivityService) { }

    display(value: boolean) {
        this.status.next(value);
    }

    showNetworkDisconnectedDialog() {
        this.userActivityService.insertLog('LoaderService', 'showNetworkDisconnectedDialog', this.translate.instant('Network Disconnected.'));
        if (this.networkDialog)
            this.networkDialog.close();

        this.networkDialog = this.layoutUtilsService.alertActionElement('', this.translate.instant('Network Disconnected.'), {
            overlayClickToClose: false,
            showCloseButton: false,
            confirmText: this.translate.instant('Refresh')
        });
        this.networkDialog.afterClosed().subscribe(res => {
            if (res) {
                if (res.action === 'confirmText') {
                    this.zone.run(() => window.location.reload());
                }
            }
        });
        return this.networkDialog;
    }

    closeNetworkDialog() {
        if (this.networkDialog)
            this.networkDialog.close();
    }
}
