<div [ngStyle]="{'z-index': _zIndex, 'left': _leftPosition ? _leftPosition + 'px':'', 'display': _hide ? 'none' : 'initial'}"
    cdkDrag [class.breakout-draggable]="!disableDrag" #breakoutDraggable (cdkDragStarted)="dragged(true)"
    *ngIf="!dialogData" [cdkDragDisabled]="disableDrag" [@showDialog]="animationState" cdkDragBoundary=".dragBoundary">
    <mat-accordion>
        <mat-expansion-panel [class.grey-out]="!disableDrag" [expanded]="true" [hideToggle]="!disableDrag"
            [disabled]="disableExpansion">
            <mat-expansion-panel-header cdkDragHandle class="panel-header drag-handle"
                [ngStyle]="{'padding': disableDrag? '0 20px 0 10px' : '0 10px 0 10px'}" collapsedHeight="40px"
                expandedHeight="40px">
                <mat-panel-title class="toolbar-label">
                    <mat-icon class="icon-title">call_split</mat-icon>
                    <span>{{'Breakout' | translate}}</span>
                </mat-panel-title>
                <!-- <button *ngIf="!disableDrag" mat-icon-button class="drag-handle" cdkDragHandle
                    style="margin: 0 5px 0 0">
                    <mat-icon class="toolbar-label">open_with</mat-icon>
                </button> -->
                <button *ngIf="!disableDrag" mat-icon-button title="{{'Close' | translate}}" (click)="closeDialog()">
                    <mat-icon class="toolbar-label">close</mat-icon>
                </button>
            </mat-expansion-panel-header>
            <ng-container *ngTemplateOutlet="breakoutTemplate"></ng-container>
        </mat-expansion-panel>
    </mat-accordion>
</div>

<div class="col-xl-12" *ngIf="dialogData">
    <h1 mat-dialog-title>{{dialogData.title}}</h1>
    <div mat-dialog-content>
        <div class="select-page-body">
            <mat-card>
                <mat-card-content>
                    <ng-container *ngTemplateOutlet="breakoutTemplate"></ng-container>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
    <div mat-dialog-actions>
        <button mat-raised-button color="danger" (click)="closeModal()">Close</button>
        <button mat-raised-button (click)="saveGroups()">Save</button>
    </div>
    <br />
</div>

<ng-template #breakoutTemplate>
    <div fxLayout="column" fxLayoutGap="0.5vw" style="margin:10px">
        <div fxLayoutAlign="center center" [hidden]="!loading" class="loading">
            <mat-progress-spinner [diameter]="60" mode="indeterminate">
            </mat-progress-spinner>
        </div>
        <div fxFlex fxLayout="row" fxLayoutGap="0.5vw" fxFlexAlign="end" *ngIf="!loading" class="top-bar">
            <mat-form-field fxFlex="12" fxFlexAlign="end" class="timer" *ngIf="!breakoutStartedAt">
                <mat-label>{{'Timeout in minutes' | translate}}</mat-label>
                <input matInput type="number" min="1" [(ngModel)]="breakoutTimer" />
            </mat-form-field>
            <div fxFlex class="buttons" fxFlexAlign="end" fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="end">
                <span *ngIf="isBreakoutStarted && isGroupsLocked" class="bold">
                    <mat-icon title="{{'Breakouts are locked' | translate}}">lock</mat-icon>
                    {{'Breakouts are locked' | translate}}
                </span>
                <span *ngIf="isBreakoutStarted && !isGroupsLocked" class="bold">
                    <mat-icon title="{{'Breakouts are unlocked' | translate}}">lock_open</mat-icon>
                    {{'Breakouts are unlocked' | translate}}
                </span>
                <span fxFlex class="countdown-breakout-edit"
                    *ngIf="(countDownTextDay >= 0 || countDownTextHour >= 0 || countDownTextMin >= 0 || countDownTextSec >= 0) && !loading">
                    {{'Breakout ending in' | translate}}
                    <span *ngIf="countDownTextDay">{{countDownTextDay}}d:</span><span
                        *ngIf="countDownTextHour">{{countDownTextHour}}h:</span><span
                        *ngIf="countDownTextMin != undefined && countDownTextMin.toString().length == 1">
                        0{{countDownTextMin}}m:</span><span
                        *ngIf="countDownTextMin != undefined && countDownTextMin.toString().length != 1">{{countDownTextMin}}m:</span><span
                        *ngIf="countDownTextSec != undefined && countDownTextSec.toString().length == 1">0{{countDownTextSec}}s</span><span
                        *ngIf="countDownTextSec != undefined && countDownTextSec.toString().length != 1">{{countDownTextSec}}s</span>
                </span>
                <ng-container *ngIf="!isBreakoutStarted">
                    <div fxFlex class="label">{{'Groups' | translate}}:
                        <button mat-stroked-button class="breakout-btn" *ngIf="!isGroupsLocked && groups.length"
                            (click)="lockGroups(false)" title="{{'Click to lock groups' | translate}}">
                            <mat-icon>lock_open</mat-icon>
                        </button>
                        <button mat-stroked-button class="breakout-btn" *ngIf="isGroupsLocked && groups.length"
                            (click)="lockGroups(true)" title="{{'Click to unlock groups' | translate}}">
                            <mat-icon>lock</mat-icon>
                        </button>
                        <button mat-stroked-button class="breakout-btn" (click)="addGroup()"
                            title="{{'Add Group' | translate}}">
                            <mat-icon>add</mat-icon>
                        </button>
                    </div>
                    <div fxFlex class="label">
                        {{'Assign by' | translate}}:
                        <button mat-stroked-button (click)="autoAssignByGroup()" class="breakout-btn"
                            title="{{'Auto-Assign by group' | translate}}">
                            <mat-icon>groups</mat-icon>
                        </button>
                        <button mat-stroked-button (click)="promptAutoAssignDialog()" class="breakout-btn"
                            title="{{'Auto-Assign by fixing number of users in breakout' | translate}}">
                            <mat-icon>group</mat-icon>
                        </button>
                    </div>
                    <div fxFlex *ngIf="!dialogData" class="label">
                        {{'Actions' | translate}}:
                        <button mat-stroked-button class="breakout-btn" (click)="saveGroups()"
                            title="{{'Save' | translate}}">
                            <mat-icon>save</mat-icon>
                        </button>
                        <ng-container *ngIf="session && !isBreakoutStarted && sessionData.active">
                            <button mat-stroked-button *ngIf="!isStreaming && groups.length" class="breakout-btn"
                                (click)="saveStartBreakout()" title="{{'Save & Start Breakout' | translate}}">
                                <mat-icon>call_split
                                </mat-icon>
                            </button>
                            <button mat-stroked-button *ngIf="isStreaming" class="breakout-btn"
                                title="{{'You cannot start a breakout when one or more people are streaming' | translate}}">
                                <mat-icon style="color: grey !important;">call_split
                                </mat-icon>
                            </button>
                        </ng-container>
                    </div>
                </ng-container>
                <div fxFlex fxGrow="0" *ngIf="isBreakoutStarted && session">
                    <button mat-stroked-button class="breakout-btn" (click)="endBreakout()"
                        title="{{'End Breakout' | translate}}">
                        <mat-icon>cancel_schedule_send</mat-icon>
                    </button>
                </div>
            </div>
        </div>
        <div fxFlex fxLayout="row" fxLayoutGap="0.5vw" *ngIf="!loading">
            <div fxFlex class="select-from">
                <div class="drag-container">
                    <div class="section-heading">{{'Available' | translate }}</div>
                    <div fxLayout="row" fxLayoutGap="0.5vw">
                        <mat-form-field fxFlex class="search-by" fxFlexAlign="start">
                            <input matInput placeholder="{{ 'Search by Name' | translate }}" [(ngModel)]="filterByText"
                                (ngModelChange)="filterList()">
                        </mat-form-field>
                        <section fxFlex fxGrow="0">
                            <mat-checkbox [(ngModel)]="filterByRegistered" (ngModelChange)="filterList()">
                                {{'Registered' | translate}}</mat-checkbox>
                            <mat-checkbox [(ngModel)]="filterByOnline" (ngModelChange)="filterList()">
                                {{'Online' | translate}}</mat-checkbox>
                        </section>
                    </div>
                    <div *ngIf="_filteredList" cdkDropList id="0000" [cdkDropListData]="_filteredList"
                        [cdkDropListConnectedTo]="connectedTo" class="item-list" (cdkDropListDropped)="drop($event)">
                        <div class="item-box" [ngStyle]="{'cursor': isBreakoutStarted ? '' : 'move'}"
                            [cdkDragDisabled]="isBreakoutStarted" *ngFor="let attendee of _filteredList" cdkDrag
                            fxFlexLayout="row" fxLayoutGap="0.5vw">
                            <div fxFlex fxGrow="0">
                                <mat-checkbox fxFlex [(ngModel)]="attendee.include"
                                    title="{{'Include in Auto-Assign' | translate}}">
                                </mat-checkbox>
                            </div>
                            <div fxFlex class="name">{{attendee.name}}</div>
                            <div fxFlex="45" fxAlign="end" fxGrow="0" style="text-align: right;">
                                <span *ngIf="attendee.registered && !attendee.online">{{'Registered' |
                                    translate}}</span>
                                <span *ngIf="!attendee.registered && attendee.online">{{'Online' | translate}}</span>
                                <span *ngIf="attendee.registered && attendee.online">{{'Registered & Online' |
                                    translate}}</span>
                            </div>
                            <!-- <mat-icon *ngIf="attendee.registered" title="{{'Registered' | translate}}">push_pin
                            </mat-icon> -->
                        </div>
                    </div>
                </div>
            </div>
            <div fxFlex fxLayoutGap="0.5vw" class="basket">
                <div class="drag-container" *ngFor="let group of groups">
                    <div class="section-heading" fxLayout="row" fxLayoutGap="0.5vw">
                        <div fxFlex>
                            <mat-form-field>
                                <mat-label>{{'Breakout Name' | translate}}</mat-label>
                                <input matInput type="string" [(ngModel)]="group.name" />
                            </mat-form-field>
                            <button mat-stroked-button *ngIf="breakoutStartedAt && session" class="join-btn"
                                (click)="joinBreakout(group.id)">{{'Join' | translate}}</button>
                        </div>
                        <mat-icon fxFlex fxGrow="0" *ngIf="!breakoutStartedAt" fxLayoutAlign="end"
                            (click)="removeGroup(group.id)">close
                        </mat-icon>
                    </div>
                    <div cdkDropList id="{{group.id}}" [cdkDropListData]="group.attendees"
                        [cdkDropListConnectedTo]="connectedTo" class="item-list" (cdkDropListDropped)="drop($event)">
                        <ng-container *ngFor="let attendee of group.attendees; let index = index">
                            <div class="item-box" fxLayout="row" fxLayoutGap="0.5vw"
                                [ngStyle]="{'cursor': isBreakoutStarted ? '' : 'move'}"
                                [cdkDragDisabled]="isBreakoutStarted"
                                (mouseenter)="highlightFlag(true, index, group.id)"
                                (mouseleave)="highlightFlag(false, index, group.id)" cdkDrag>
                                <div fxFlex class="name">{{attendee.name}}</div>
                                <div fxFlex="45" fxGrow="0" class="registered-label">
                                    <span *ngIf="attendee.registered && !attendee.online">{{'Registered' |
                                        translate}}</span>
                                    <span *ngIf="!attendee.registered && attendee.online">{{'Online' |
                                        translate}}</span>
                                    <span *ngIf="attendee.registered && attendee.online">{{'Registered & Online' |
                                        translate}}</span>
                                </div>
                                <!-- <mat-icon *ngIf="attendee.registered" title="{{'FcaRegistered' | translate}}">push_pin
                            </mat-icon> -->
                                <div fxFlex="10" fxAlign="end" fxGrow="0" style="text-align: right;">
                                    <mat-icon *ngIf="attendee.isHost" (click)="markAsHost(index, group.id)"
                                        title="{{'Host' | translate}}">how_to_reg
                                    </mat-icon>
                                    <mat-icon (click)="markAsHost(index, group.id)"
                                        *ngIf="attendee.isFlagHighlight && !attendee.isHost"
                                        title="{{'Flag as host' | translate}}">
                                        how_to_reg</mat-icon>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>