import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guard/auth.guard';
import { EmptyComponent } from './empty/empty.component';
import { MobileComponent } from './mobile/mobile.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'login/:orgId',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'login/:orgId/:appId/:locId/:lang',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'loginWithDialog/:orgId',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'loginWithDialog/:orgId/:appId/:locId/:lang',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'loginWithDialog',
        loadChildren: () => import('./login/login.module').then(m => m.LoginModule)
    },
    {
        path: 'mobile',
        component: MobileComponent
    },
    { path: 'empty', component: EmptyComponent },
    { path: 'empty/:action', component: EmptyComponent },
    { path: 'notfound/:type', component: ErrorPageComponent },
    {
        path: '**', pathMatch: 'full',
        component: ErrorPageComponent
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
    providers: [AuthGuard]
})
export class AppRoutingModule { }
