import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { RequestService, MenuConfigService, FeaturesPermissionService } from '../../../shared/services';
import { environment } from '../../../../environments/environment';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmEntityDialogComponent } from '../modals/confirm-entity-dialog/confirm-entity-dialog.component';
// Object path
import * as objectPath from 'object-path';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public isMobile: boolean = false;
  public selectedUser: any;
  public organization = undefined;
  public paymentGateway: string = environment.paymentGateway;
  public hasListingDirectory: boolean = environment.hasListingDirectory;
  public showMenu: string;
  public isSuperAdmin: boolean = false;
  public menuConfigs: any[] = [];
  public settingOrgObject = undefined;
  public settingOrgPageObject = undefined;
  public subscriptionOrgObject = undefined;
  constructor(public dialog: MatDialog, private translate: TranslateService, private changeDetectionRef: ChangeDetectorRef, private requestService: RequestService, private menuConfigService: MenuConfigService, private featuresPermissionService: FeaturesPermissionService, public deviceService: DeviceDetectorService) {
    if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
      this.isMobile = true;
    }
    this.menuConfigs = objectPath.get(this.menuConfigService.getMenus(), 'aside.items');
  }

  ngOnInit() {

    this.subscriptions.push(
      this.featuresPermissionService.paymentGatewaySubject.subscribe((data) => {
        if (data) {
          this.paymentGateway = data;
        }
      })
    );
    this.subscriptions.push(
      this.menuConfigService.onConfigUpdated$.subscribe((data) => {
        if (data) {
          this.menuConfigs = objectPath.get(this.menuConfigService.getMenus(), 'aside.items');
        }
      })
    );
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.isSuperAdmin = this.requestService.isUserRoleSuperAdmin();
        }
      })
    );
    this.subscriptions.push(
      this.requestService.pageOrganization.subscribe((data) => {
        if (data) {
          this.organization = data;
          if (data.hasOwnProperty('settings')) {
            this.settingOrgObject = data.settings;
            if (this.settingOrgObject && this.settingOrgObject.web && this.settingOrgObject.web.page) {
              this.settingOrgPageObject = this.settingOrgObject['web']['page'];
              this.menuConfigs = this.menuConfigs.filter((itm) => {
                if (this.organization.parent && this.organization.parent._id && this.organization.parent._id !== '') {
                  if (itm['groupParent'] && (itm['groupParent']['isSuperAdmin'] && !this.isSuperAdmin)) {
                    return false;
                  }
                }
                if (itm.hasOwnProperty('fieldEnableBoolean')) {
                  return this.settingOrgPageObject[itm.fieldEnableBoolean];
                }
                return true;
              })
            } else {
              this.menuConfigs = this.menuConfigs.filter((itm) => {
                if (this.organization.parent && this.organization.parent._id && this.organization.parent._id !== '') {
                  if (itm['groupParent'] && (itm['groupParent']['isSuperAdmin'] && !this.isSuperAdmin)) {
                    return false;
                  }
                }
                if (itm.hasOwnProperty('fieldEnableBoolean')) {
                  return false;
                }
                return true;
              })
            }
            if (this.organization.parent && this.organization.parent._id && this.organization.parent._id !== '') {
              let isParent = this.organization.parent._id === this.organization._id;
              this.menuConfigs = this.menuConfigs.map((itm) => {
                if (itm.hasOwnProperty('submenu')) {
                  let submenu = itm.submenu;
                  submenu = submenu.filter((subItm) => {
                    if (!subItm.hasOwnProperty('groupParent')) {
                      return true;
                    }
                    if (subItm['groupParent'] && ((subItm['groupParent']['isGroupParent'] && isParent) || (subItm['groupParent']['isSuperAdmin'] && this.isSuperAdmin))) {
                      return true;
                    }
                    return false;
                  });
                  itm.submenu = submenu;
                }
                return itm;
              })
            } else {
              // is not group parent organizations
              this.menuConfigs = this.menuConfigs.map((itm) => {
                if (itm.hasOwnProperty('submenu')) {
                  let submenu = itm.submenu;
                  submenu = submenu.filter((subItm) => {
                    if (subItm.hasOwnProperty('showInGroupParent') && subItm.showInGroupParent) {
                      return false;
                    }
                    return true;
                  });
                  itm.submenu = submenu;
                }
                return itm;
              })
            }
          }
        }
      })
    );
    this.subscriptions.push(
      this.featuresPermissionService.currentSubscriptionSubject.subscribe((data) => {
        if (data) {
          let currentUser = this.requestService.currentUserSubject.getValue();
          if (data.email && data.email === currentUser.email) {
            this.subscriptionOrgObject = data;
          }
        }
      })
    );
    this.showMenu = 'admin';
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    // if (!this.changeDetectionRef['destroyed']) {
    //     this.changeDetectionRef.detectChanges();
    // }
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  clickingOutside() {
    const dom: any = document.querySelector('body');
    dom.classList.remove('push-right');
  }
  hideMenu() {
    this.toggleSidebar();
  }
  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle('push-right');
  }
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  publishIt() {
    let url = this.paymentGateway + '/#/login/' + this.requestService.orgId + '?returnUrl=%2Fdashboard%2Fpublish%2F' + this.requestService.orgId + '&token=' + this.requestService.getToken();
    window.open(url, "_blank");
    // const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
    //   disableClose: true,
    //   width: '600px',
    //   data: {
    //     title: this.translate.instant('Publish Event'),
    //     data: '',
    //     description: this.translate.instant('Contact your sales'),
    //     cancelbtn: this.translate.instant('Close'),
    //     confirmbtn: this.translate.instant('Go to Payment Gateway')
    //   }
    // });
    // dialogRef.afterClosed().subscribe(result => {
    //   if (result) {
    //     let url = this.paymentGateway +  '/#/login/' + this.requestService.orgId + '?returnUrl=dashboard&token=' + this.requestService.getToken();
    //     window.open(url, "_blank");
    //     // window.location.href = this.paymentGateway;
    //   }
    // });
    // window.location.href = this.paymentGateway;
  }
  subscribeToIt() {
    let url = this.paymentGateway + '/#/login/' + this.requestService.orgId + '?returnUrl=%2Fdashboard%2Fsubscribe%2F' + this.requestService.orgId + '&token=' + this.requestService.getToken();
    window.open(url, "_blank");
  }
}
