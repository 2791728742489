import { Injectable } from '@angular/core';

const isClientSide = typeof window !== 'undefined';

const encryptionAlgorithm = {
    name: 'AES-GCM',
    length: 256,
};

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {

    private async getEncryptionKey(): Promise<CryptoKey> {
        const key = await window.crypto.subtle.generateKey(encryptionAlgorithm, true, ['encrypt', 'decrypt']);
        return key;
    }

    private async exportEncryptionKey(key: CryptoKey): Promise<JsonWebKey> {
        const exportedKey = await window.crypto.subtle.exportKey('jwk', key);
        return exportedKey as JsonWebKey;
    }

    private async importEncryptionKey(keyData: JsonWebKey): Promise<CryptoKey> {
        const key = await window.crypto.subtle.importKey('jwk', keyData, encryptionAlgorithm, true, ['encrypt', 'decrypt']);
        return key;
    }

    public async setEncryptedItem(key: string, value: any): Promise<void> {
        if (isClientSide) {
            const encryptionKey = await this.getEncryptionKey();
            const iv = window.crypto.getRandomValues(new Uint8Array(12));
            const encryptedValue = await window.crypto.subtle.encrypt({ name: 'AES-GCM', iv }, encryptionKey, new TextEncoder().encode(JSON.stringify(value)));
            const exportedKey = await this.exportEncryptionKey(encryptionKey);
            window.localStorage.setItem(key, JSON.stringify({ key: exportedKey, iv: Array.from(iv), value: new Uint8Array(encryptedValue) }));
        }
    }

    public async getEncryptedItem<T>(key: string): Promise<T> {
        if (isClientSide) {
            const item = window.localStorage.getItem(key);
            if (item) {
                const { key: keyData, iv, value: encryptedValue } = JSON.parse(item);
                const encryptionKey = await this.importEncryptionKey(keyData);
                const decryptedValue = await window.crypto.subtle.decrypt({ name: 'AES-GCM', iv: new Uint8Array(iv) }, encryptionKey, encryptedValue.buffer);
                return JSON.parse(new TextDecoder().decode(decryptedValue)) as T;
            }
        }
        return null;
    }

    public removeItem(key: string): void {
        if (isClientSide) {
            window.localStorage.removeItem(key);
        }
    }

    public clear(): void {
        if (isClientSide) {
            window.localStorage.clear();
        }
    }
}