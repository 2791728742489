import { EventType } from "../enums/eventType";

export class EventTrack {
    o: string; //orgid
    e: EventType; //event type
    u: string; //user id
    v: string; //event value
    l1: string; //level 1 id can be room id
    l2: string; //level 2 id can be session id
    l3: string; //level 2 id can be breakout id
    m: string; // meta value
    n: string; // name
    i: string; // email

    /**
     *  @constructor
     */
    public constructor(e: EventType, v: any, l1: string, l2: string, l3: string, m: string, u?: string, n?: string, i?: string) {
        this.e = e;
        this.v = v;
        this.l1 = l1;
        this.l2 = l2;
        this.l3 = l3;
        this.m = m;
        this.i = i;
        this.n = n;
        this.u = u;
    }
}
