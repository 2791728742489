<div class="col-xl-12 audioGalleryPopup">
	<div mat-dialog-title class="dialog-title">
		<div class="mb-20" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
			<div fxFlex class="filterName">
				{{data.title}}
			</div>
		</div>
	</div>
	<div mat-dialog-content class="dialog-content" style="max-height: 50vh;">
		<div class="itemViewParent">
			<mat-card class="itemView" [class.selected]="itm.url === fileSelected?.url"
				*ngFor="let itm of dataList; let index=index" (click)="setFileSelected(itm)">
				<mat-card-header>
					<mat-card-title>{{itm.name}}</mat-card-title>
					<!-- <input value="true" (change)="setMultipleSelected($event, itm._id, itm)" [checked]="multipleSelected[itm._id]" [(ngModel)]="multipleSelected[itm._id]" class="checkit" title="Check to multiselect" type="checkbox" > -->
					<mat-checkbox class="checkit" title="Check to multiselect" [checked]="multipleSelected[itm._id]"
						(click)="$event.stopPropagation()" (change)="setMultipleSelected($event, itm._id, itm)">
					</mat-checkbox>
					<em class="material-icons deleteit" (click)="delete($event, itm._id)">delete_forever</em>
				</mat-card-header>
				<img *ngIf="!(itm.url && itm.url !== '')" mat-card-image src="/assets/images/audiobg.png">
				<app-audio *ngIf="itm.url && itm.url !== ''" [isAutoPlay]="false" [isOnLoop]="false" [id]="index"
					[width]="'15'" [volume]="0.5" [src]="itm.url">
				</app-audio>
			</mat-card>
		</div>
	</div>
	<div mat-dialog-actions class="dialog-actions">
		<mat-paginator [pageSize]="pageSize" [length]="paginatorTotal | async" [showFirstLastButtons]="true">
		</mat-paginator>
		<mat-divider></mat-divider>
		<div style="width: 100%;margin-top:10px;">
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
			<input #imageInput [hidden]="true" type="file" name="upfileLogo" accept="{{allowedAcceptExtensions}}"
				multiple="true" id="image_selector" (change)="onBrowseFiles($event.target)">
			<button mat-raised-button (click)="imageInput.click()">{{'Upload new audio files' | translate}}</button>
			<!-- <button *ngIf="!noSelection" mat-raised-button (click)="closeModal(fileSelected.url)" [hidden]="!fileSelected">{{'Select' | translate}}</button> -->
			<button *ngIf="multipleSelection && hasSelected()" mat-raised-button (click)="multiSelect()">{{'Add selected
				Audio files' | translate}}</button>
		</div>
	</div>
	<br />
</div>