<div id="targetCalendarElement" #targetCalendarElement fxLayout="row" fxFlex fxLayoutGap="20px"
  [class.mobileCalendar]="isMobile" [class.tabletCalendar]="isTablet">
  <div fxFlex>
    <div #cont id="cont" fxLayout="column" fxLayoutAlign="space-between center"
      (keydown.arrowleft)="previousButtonClick()" (keydown.arrowright)="nextButtonClick()"
      (swipeleft)="nextButtonClick()" (swiperight)="previousButtonClick()" style="height: 100%; ">

      <div class="header-date" fxLayout="row" fxLayoutAlign="space-between center" style="min-height: 60px;"
        fxLayout.lt-md="column">

        <!-- <button mat-icon-button class="buttons" (click)="previousButtonClick()">
          <mat-icon>
            arrow_back_ios
          </mat-icon>
        </button> -->
        <div>
          <div data-html2canvas-ignore="true">
            <mat-button-toggle-group *ngIf="showDesignModeOptions" [(ngModel)]="viewDesignMode" aria-label="Font Style">
              <mat-button-toggle value="{{itm}}" *ngFor="let itm of viewDesignModesArray"
                title="{{viewDesignModes[itm]}}">
                <mat-icon>{{itm}}</mat-icon>
              </mat-button-toggle>
            </mat-button-toggle-group>
            <span style="margin-left: 10px;">
              <mat-button-toggle-group [(ngModel)]="viewMode" (change)="viewModeChange($event.value)"
                aria-label="Font Style">
                <mat-button-toggle value="{{itm}}" *ngFor="let itm of viewModesArray">{{viewModes[itm]}}
                </mat-button-toggle>
              </mat-button-toggle-group>
            </span>
          </div>
        </div>
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <button data-html2canvas-ignore="true" mat-icon-button class="buttons" (click)="previousButtonClick()">
            <mat-icon>
              arrow_back_ios
            </mat-icon>
          </button>
          <span *ngIf="viewMode === 'month'" class="titleDate">
            {{ actDate }}
          </span>
          <span *ngIf="viewMode === 'week' && currentWeek !== undefined" class="titleDate">
            <!-- {{ actDate }} Week {{currentWeek+1}} -->
            {{ actWeekDate }} Week {{currentWeekView+1}}
          </span>
          <span *ngIf="viewMode === 'day' && today !== undefined" class="titleDate">
            <!-- {{today | date: 'yyyy. MMMM dd - EEEE'}} -->
            {{actFullDate}}
          </span>
          <button data-html2canvas-ignore="true" *ngIf="viewMode === 'month'" mat-icon-button
            (click)="showChangeDate = !showChangeDate">
            <mat-icon *ngIf="!showChangeDate">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="showChangeDate">keyboard_arrow_up</mat-icon>

          </button>
          <button data-html2canvas-ignore="true" mat-icon-button class="buttons" (click)="nextButtonClick()">
            <mat-icon>
              arrow_forward_ios
            </mat-icon>
          </button>
          <!-- <button mat-icon-button (click)="showChangeDate = !showChangeDate">
            <mat-icon *ngIf="!showChangeDate">keyboard_arrow_down</mat-icon>
            <mat-icon *ngIf="showChangeDate">keyboard_arrow_up</mat-icon>

          </button> -->

        </div>
        <div>
          <div data-html2canvas-ignore="true">
            <button mat-button class="clearButtons" (click)="goToToday()" title="Today">Today</button>
            <button mat-icon-button *ngIf="canAddEvent && eventsActions.length == 0" (click)="createEvent('occurrence')"
              title="Add {{dataTypeDisplay}}">
              <mat-icon>{{menuIcon}}</mat-icon>
            </button>
            <button *ngIf="canAddEvent && eventsActions.length > 0" mat-icon-button [matMenuTriggerFor]="addEvent">
              <mat-icon>{{menuIcon}}</mat-icon>
            </button>
            <mat-menu #addEvent="matMenu">
              <!-- <div class="header" style="text-align: center;font-weight: bold;">
              Select Event Type
            </div> -->
              <button *ngFor="let itm of eventsActions" mat-menu-item
                (click)="createEvent(itm.value)">{{itm.displayName}}</button>
              <!-- <button mat-menu-item (click)="createOccurrence()">One Event Occurrence</button>
              <button mat-menu-item (click)="createBlueprint()">Event Blueprint</button>
              <button mat-menu-item (click)="createEvent()">Select from Blueprints</button> -->
            </mat-menu>
          </div>
        </div>
        <!-- <mat-button-toggle-group aria-label="Font Style">
          <mat-button-toggle (click)="goToToday()">Today</mat-button-toggle>
          <mat-button-toggle (click)="addEventClicked()"><mat-icon>add</mat-icon></mat-button-toggle>
        </mat-button-toggle-group> -->
        <!-- <button *ngIf="btnAddShow" mat-icon-button (click)="addEventClicked()">
          <mat-icon>add</mat-icon>
        </button> -->



      </div>
      <div class="header-date-extra" fxLayout="row" fxLayoutAlign="space-between center" *ngIf="enableAvailability">
        <div fxFlex align="right">
          <span style="margin-right:8px">{{availabilityBtn}}</span>
          <mat-slide-toggle [checked]="myAvailability" color="primary" style="margin-right:5px;"
            (change)="myAvailability = !myAvailability">

          </mat-slide-toggle>
        </div>
      </div>

      <div data-html2canvas-ignore="true" class="header-select-date" fxLayout="row" fxLayoutAlign="center center"
        style="height: auto;" *ngIf="showChangeDate && viewMode === 'month'">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="space-between center" [@enterAnimation]>

          <mat-form-field style="margin: 5px;">
            <mat-select (selectionChange)="onYearChange($event)" [(ngModel)]="currentYear">
              <mat-option *ngFor="let y of years" [value]="y">{{ y }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field style="margin: 5px;">
            <mat-select (selectionChange)="onMonthChange($event)" [(ngModel)]="currentMonth">
              <mat-option *ngFor="let m of months" [value]="m.id">{{ m.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>

        </div>
      </div>



      <div id="calendarContainer" #calendarContainer class="calendar-container" fxLayout="column" fxFlex
        *ngIf="viewDesignMode === 'calendar_month'">
        <!-- Weekdays header -->
        <div fxLayout="row" *ngIf="['month'].includes(viewMode)" fxLayoutAlign="space-between center"
          class="weekdays-container" style="height: 30px;">
          <div *ngIf="viewMode === 'week'" fxFlex="29px" class="weekdaysTitle"></div>
          <div class="weekdaysTitle" *ngFor="let day of weekdays" fxLayout="row" fxLayoutAlign="center center">
            <span *ngIf="!isSmall">
              {{day.name}}
            </span>
            <span *ngIf="isSmall">
              {{day.shortName}}
            </span>
          </div>
        </div>
        <div fxLayout="row" *ngIf="['week'].includes(viewMode)" fxLayoutAlign="space-between center"
          class="weekdays-container" style="height: 30px;">
          <div fxFlex="29px" class="weekdaysTitle"></div>
          <div class="weekdaysTitle" *ngFor="let day of currentWeekData" fxLayout="row" fxLayoutAlign="center center">
            <span *ngIf="!isSmall">
              {{day.dayName.name}} <span [class.selectedWeekDay]="day.day == actDay &&
              day.month == actMonth - 1 &&
              day.year == actYear">{{day.day}}</span>
            </span>
            <span *ngIf="isSmall">
              {{day.dayName.shortName}} <span [class.selectedWeekDay]="day.day == actDay &&
              day.month == actMonth - 1 &&
              day.year == actYear">{{day.day}}</span>
            </span>
          </div>
        </div>

        <!-- Days body -->
        <!-- Months -->
        <div *ngIf="viewMode === 'month'" fxFlex fxLayout="column" class="cell-container-main"
          [ngStyle]="{'min-height': bodyHeight ? bodyHeight : 'calc(100vh - 240px)'}">
          <div fxFlex class="cell-container" fxLayout="row" *ngFor="let item of arrCalendar;let indexItem=index;">
            <ng-container *ngFor="let cellItem of item;let indexCell = index;">
              <ng-container
                *ngIf="enableAvailability && myAvailability && cellItem.availability.length > 0 && dayAvailabilityActions.length > 0">
                <div fxFlex class="cell-item" id="cell-item-{{cellItem.day}}-{{cellItem.month}}-{{cellItem.year}}"
                  fxLayoutAlign="space-between stretch" (click)="selectCellItem(cellItem)" [ngStyle]="{
                  backgroundColor:
                  enableAvailability && myAvailability && cellItem.availability.length > 0
                      ? defaultColors.availability
                      : showSelectedDay && cellItem.day == selectedDay.day && cellItem.month == selectedDay.month && cellItem.year == selectedDay.year
                      ? defaultColors.selectedDay
                      : defaultColors.default}" style="height: 100%; width: 100%;"
                  [matMenuTriggerFor]="afterMenuMonth">
                  <div fxFlex fxLayout="column" style="height: 100%; width: 100%; ">
                    <div style="text-align: end;">
                      <span *ngIf="cellItem.events && cellItem.events.length > 0 && isSmall" class="dot"></span>
                      <strong class="calendar-days" [ngStyle]="{
                                color: cellItem.month == currentMonth ? 'black' : 'lightgray'
                              }" [class.selectedDay]="cellItem.day == actDay &&
                      cellItem.month == actMonth - 1 &&
                      cellItem.year == actYear">{{
                        cellItem.day }}
                      </strong>
                    </div>
                    <div fxFlex *ngIf="!isSmall" style="height: 100%; overflow-x: hidden;">
                      <div class="row-items" *ngFor="let event of cellItem.events; let i = index"
                        [ngStyle]="{ backgroundColor: event.color || event.color!== ''? event.color : defaultColors.eventBg, color: event.color!== ''? 'white' : defaultColors.eventBg === 'unset' ? 'inherit' : defaultColors.eventFont, 'pointer-events': eventsSelectable ? 'auto' : 'none' }"
                        (click)="selectEventItem($event, cellItem, event)" customToolTip [showToolTip]="showToolTip"
                        [contentTemplate]="contentTemplate || eventTemplate" [contentTemplateData]="event"
                        [class.row-items-opique]="event.eventType === 'blueprint'">
                        <ng-container [ngTemplateOutlet]="contentDataTemplate || eventDataTemplate"
                          [ngTemplateOutletContext]="{ contentTemplateData: {event: event ,currentDay:cellItem} }">
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
                <mat-menu #afterMenuMonth="matMenu" xPosition="before">
                  <button mat-menu-item (click)="selectDayCellItem(itm.value, cellItem)"
                    *ngFor="let itm of dayAvailabilityActions">{{itm.displayName}}</button>
                </mat-menu>
              </ng-container>

              <ng-container
                *ngIf="!(enableAvailability && myAvailability && cellItem.availability.length > 0 && dayAvailabilityActions.length > 0)">
                <div fxFlex class="cell-item" id="cell-item-{{cellItem.day}}-{{cellItem.month}}-{{cellItem.year}}"
                  fxLayoutAlign="space-between stretch" (click)="selectCellItem(cellItem)" [ngStyle]="{
                  backgroundColor:
                  enableAvailability && myAvailability && cellItem.availability.length > 0
                      ? defaultColors.availability
                      : showSelectedDay && cellItem.day == selectedDay.day && cellItem.month == selectedDay.month && cellItem.year == selectedDay.year
                      ? defaultColors.selectedDay
                      : defaultColors.default}" style="height: 100%; width: 100%;">
                  <div fxFlex fxLayout="column" style="height: 100%; width: 100%; ">
                    <div style="text-align: end;">
                      <span *ngIf="cellItem.events && cellItem.events.length > 0 && isSmall" class="dot"></span>
                      <strong class="calendar-days" [ngStyle]="{
                                color: cellItem.month == currentMonth ? 'black' : 'lightgray'
                              }" [class.selectedDay]="cellItem.day == actDay &&
                      cellItem.month == actMonth - 1 &&
                      cellItem.year == actYear">{{
                        cellItem.day }}
                      </strong>
                    </div>
                    <div fxFlex *ngIf="!isSmall" style="height: 100%; overflow-x: hidden;">
                      <div class="row-items" *ngFor="let event of cellItem.events; let i = index"
                        [ngStyle]="{ backgroundColor: event.color || event.color!== ''? event.color : defaultColors.eventBg, color: event.color!== ''? 'white' : defaultColors.eventBg === 'unset' ? 'inherit' : defaultColors.eventFont, 'pointer-events': eventsSelectable ? 'auto' : 'none' }"
                        (click)="selectEventItem($event, cellItem, event)" customToolTip [showToolTip]="showToolTip"
                        [contentTemplate]="contentTemplate || eventTemplate" [contentTemplateData]="event"
                        [class.row-items-opique]="event.eventType === 'blueprint'">
                        <ng-container [ngTemplateOutlet]="contentDataTemplate || eventDataTemplate"
                          [ngTemplateOutletContext]="{ contentTemplateData: {event: event ,currentDay:cellItem} }">
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
        <!-- Weeks -->
        <div *ngIf="viewMode === 'week'" fxFlex fxLayout="column" class="cell-container-main-week"
          [ngStyle]="{'min-height': bodyHeight ? bodyHeight : 'calc(100vh - 240px)'}">
          <div fxFlex class="cell-container" fxLayout="row">
            <div fxFlex="29px" fxLayout="column" class="weekdays">
              <ng-container *ngFor="let hr of hours">
                <div class="hours">
                  {{hr.displayName}}
                </div>
                <div class="half-hours">

                </div>
              </ng-container>
            </div>
            <div fxFlex class="cell-day-item" fxLayoutAlign="space-between stretch" [ngStyle]="{
              backgroundColor:
              showSelectedDay && cellItem.day == selectedDay.day && cellItem.month == selectedDay.month && cellItem.year == selectedDay.year
                  ? defaultColors.selectedDay
                  : defaultColors.default}" *ngFor="let cellItem of currentWeekData;let k = index"
              style="position: relative;height: 100%; width: 100%;overflow: hidden;">
              <div fxFlex fxLayout="column" class="main-events" (click)="selectCellItemSmall(cellItem)">
                <div *ngIf="!isSmall" style="height: 100%; overflow-x: hidden;">
                  <div class="main-days-bg">
                    <div fxLayout="column" class="day-bg">
                      <ng-container *ngFor="let hr of hours">
                        <div class="hours" id="hr-cell-item-{{cellItem.day}}-{{hr.value}}"
                          (click)="selectCellItemHours(cellItem, hr.value, false)">

                        </div>
                        <div class="half-hours" (click)="selectCellItemHours(cellItem, hr.value, true)">

                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div *ngIf="enableAvailability && myAvailability" fxFlex fxLayout="row"
                    class="main-days-bg week-items">
                    <ng-container *ngIf="newDesign">
                      <div fxFlex class="events-row-items" style="height: calc(31px * 48);">
                        <ng-container *ngFor="let event of cellItem.availabilityBuild; let i = index">
                          <ng-container *ngIf="dayAvailabilityActions.length > 0">
                            <div class="row-items row-week-items row-items-opique row-week-item-new-design"
                              [ngStyle]="{ backgroundColor: defaultColors.availability,'inset': event.inset, opacity: hoverEvent['av-row-week-items-'+ k + '-' + i] ? '1' : hoverColumnEvent['av-row-week-items'+k] ? '0.5' : '1'}"
                              (click)="selectAvailabilityEventItem($event, cellItem, event)"
                              (mouseover)="hoverEvent['av-row-week-items-'+ k + '-' + i] = true;hoverColumnEvent['av-row-week-items'+k] = true"
                              (mouseleave)="hoverEvent['av-row-week-items-'+ k + '-' + i] = false;hoverColumnEvent['av-row-week-items'+k] = false"
                              [matMenuTriggerFor]="afterMenuWeek">
                            </div>
                            <mat-menu #afterMenuWeek="matMenu" xPosition="before">
                              <button mat-menu-item (click)="selectAvailabilityEventDayItem(itm.value, cellItem, event)"
                                *ngFor="let itm of dayAvailabilityActions">{{itm.displayName}}</button>
                            </mat-menu>
                          </ng-container>
                          <ng-container *ngIf="dayAvailabilityActions.length == 0">
                            <div class="row-items row-week-items row-items-opique row-week-item-new-design"
                              [ngStyle]="{ backgroundColor: defaultColors.availability,'inset': event.inset, opacity: hoverEvent['av-row-week-items-'+ k + '-' + i] ? '1' : hoverColumnEvent['av-row-week-items'+k] ? '0.5' : '1'}"
                              (click)="selectAvailabilityEventItem($event, cellItem, event)"
                              (mouseover)="hoverEvent['av-row-week-items-'+ k + '-' + i] = true;hoverColumnEvent['av-row-week-items'+k] = true"
                              (mouseleave)="hoverEvent['av-row-week-items-'+ k + '-' + i] = false;hoverColumnEvent['av-row-week-items'+k] = false">
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!newDesign">
                      <div fxFlex class="events-row-items"
                        *ngFor="let colEvents of cellItem.availabilityView;let m = index">
                        <ng-container *ngFor="let event of colEvents; let i = index">
                          <ng-container *ngIf="dayAvailabilityActions.length > 0">
                            <div class="row-items row-week-items row-items-opique"
                              [ngStyle]="{ backgroundColor: defaultColors.availability, marginTop: event.arrayView[cellItem.date] ? event.arrayView[cellItem.date].topMargin : '0px', height: event.arrayView[cellItem.date] ? event.arrayView[cellItem.date].height : '0px', opacity: hoverEvent['av-row-week-items-'+ k + '-' + m + '-' + i] ? '1' : hoverColumnEvent['av-row-week-items'+k] ? '0.5' : '1'}"
                              (click)="selectAvailabilityEventItem($event, cellItem, event)"
                              (mouseover)="hoverEvent['av-row-week-items-'+ k + '-' + m + '-' + i] = true;hoverColumnEvent['av-row-week-items'+k] = true"
                              (mouseleave)="hoverEvent['av-row-week-items-'+ k + '-' + m + '-' + i] = false;hoverColumnEvent['av-row-week-items'+k] = false"
                              [matMenuTriggerFor]="afterMenuWeek">
                            </div>
                            <mat-menu #afterMenuWeek="matMenu" xPosition="before">
                              <button mat-menu-item (click)="selectAvailabilityEventDayItem(itm.value, cellItem, event)"
                                *ngFor="let itm of dayAvailabilityActions">{{itm.displayName}}</button>
                            </mat-menu>
                          </ng-container>
                          <ng-container *ngIf="dayAvailabilityActions.length == 0">
                            <div class="row-items row-week-items row-items-opique"
                              [ngStyle]="{ backgroundColor: defaultColors.availability, marginTop: event.arrayView[cellItem.date] ? event.arrayView[cellItem.date].topMargin : '0px', height: event.arrayView[cellItem.date] ? event.arrayView[cellItem.date].height : '0px', opacity: hoverEvent['av-row-week-items-'+ k + '-' + m + '-' + i] ? '1' : hoverColumnEvent['av-row-week-items'+k] ? '0.5' : '1'}"
                              (click)="selectAvailabilityEventItem($event, cellItem, event)"
                              (mouseover)="hoverEvent['av-row-week-items-'+ k + '-' + m + '-' + i] = true;hoverColumnEvent['av-row-week-items'+k] = true"
                              (mouseleave)="hoverEvent['av-row-week-items-'+ k + '-' + m + '-' + i] = false;hoverColumnEvent['av-row-week-items'+k] = false">
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                  <div fxFlex fxLayout="row" class="week-items">
                    <ng-container *ngIf="newDesign">
                      <div fxFlex class="events-row-items" style="height: calc(31px * 48);">
                        <div class="row-items row-week-items row-week-item-new-design"
                          *ngFor="let event of cellItem.eventsBuild; let i = index"
                          [ngStyle]="{ backgroundColor: event.color || event.color!== ''? event.color : defaultColors.eventBg, color: event.color!== ''? 'white' : defaultColors.eventBg === 'unset' ? 'inherit' : defaultColors.eventFont, opacity: hoverEvent['row-week-items-'+ k + '-' + m + '-' + i] ? '1' : hoverColumnEvent['row-week-items'+k] ? '0.5' : '1', 'pointer-events': eventsSelectable ? 'auto' : 'none', 'inset': event.inset}"
                          customToolTip [showToolTip]="showToolTip" [contentTemplate]="contentTemplate || eventTemplate"
                          [contentTemplateData]="event" (click)="selectEventItem($event, cellItem, event)"
                          [class.row-items-opique]="event.eventType === 'blueprint'"
                          (mouseover)="hoverEvent['row-week-items-'+ k + '-' + m + '-' + i] = true;hoverColumnEvent['row-week-items'+k] = true"
                          (mouseleave)="hoverEvent['row-week-items-'+ k + '-' + m + '-' + i] = false;hoverColumnEvent['row-week-items'+k] = false">
                          <ng-container [ngTemplateOutlet]="contentDataTemplate || eventDataTemplate"
                            [ngTemplateOutletContext]="{ contentTemplateData: {event: event ,currentDay:cellItem} }">
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!newDesign">
                      <div fxFlex class="events-row-items" *ngFor="let colEvents of cellItem.eventsView;let m = index">
                        <div class="row-items row-week-items" *ngFor="let event of colEvents; let i = index"
                          [ngStyle]="{ backgroundColor: event.color || event.color!== ''? event.color : defaultColors.eventBg, color: event.color!== ''? 'white' : defaultColors.eventBg === 'unset' ? 'inherit' : defaultColors.eventFont, marginTop: event.arrayView[cellItem.date] ? event.arrayView[cellItem.date].topMargin : '0px', height: event.arrayView[cellItem.date] ? event.arrayView[cellItem.date].height : '0px', opacity: hoverEvent['row-week-items-'+ k + '-' + m + '-' + i] ? '1' : hoverColumnEvent['row-week-items'+k] ? '0.5' : '1', 'pointer-events': eventsSelectable ? 'auto' : 'none'}"
                          customToolTip [showToolTip]="showToolTip" [contentTemplate]="contentTemplate || eventTemplate"
                          [contentTemplateData]="event" (click)="selectEventItem($event, cellItem, event)"
                          [class.row-items-opique]="event.eventType === 'blueprint'"
                          (mouseover)="hoverEvent['row-week-items-'+ k + '-' + m + '-' + i] = true;hoverColumnEvent['row-week-items'+k] = true"
                          (mouseleave)="hoverEvent['row-week-items-'+ k + '-' + m + '-' + i] = false;hoverColumnEvent['row-week-items'+k] = false">
                          <ng-container [ngTemplateOutlet]="contentDataTemplate || eventDataTemplate"
                            [ngTemplateOutletContext]="{ contentTemplateData: {event: event ,currentDay:cellItem} }">
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>

                  </div>
                </div>
                <span *ngIf="cellItem.events && cellItem.events.length > 0 && isSmall" class="dot"></span>
              </div>
            </div>
          </div>
        </div>
        <!-- Day -->
        <div *ngIf="viewMode === 'day'" fxFlex fxLayout="column" class="cell-container-day-main"
          [ngStyle]="{'min-height': bodyHeight ? bodyHeight : 'calc(100vh - 200px)'}">
          <div fxFlex class="cell-container" fxLayout="row">
            <div fxFlex="29px" fxLayout="column" class="weekdays">
              <ng-container *ngFor="let hr of hours">
                <div class="hours">
                  {{hr.displayName}}
                </div>
                <div class="half-hours">

                </div>
              </ng-container>
            </div>
            <div fxFlex class="cell-selected-day-item" fxLayoutAlign="space-between stretch"
              [ngStyle]="{backgroundColor:defaultColors.default}" style="height: 100%; width: 100%;">
              <div fxFlex fxLayout="column" class="main-events">
                <div style="height: 100%; overflow-x: hidden;">
                  <div class="main-day-bg">
                    <div fxLayout="column" class="day-bg">
                      <ng-container *ngFor="let hr of hours">
                        <div class="hours" id="hr-cell-item-{{currentDay.day}}-{{hr.value}}"
                          (click)="selectCellItemHours(currentDay, hr.value, false)">

                        </div>
                        <div class="half-hours" (click)="selectCellItemHours(currentDay, hr.value, true)">

                        </div>
                      </ng-container>
                    </div>
                  </div>
                  <div *ngIf="enableAvailability && myAvailability" fxFlex fxLayout="row" class="day-items main-day-bg">
                    <ng-container *ngIf="newDesign">
                      <div fxFlex class="events-row-items" style="height: calc(31px * 48);">
                        <ng-container *ngFor="let event of currentDay.availabilityBuild; let i = index">
                          <ng-container *ngIf="dayAvailabilityActions.length > 0">
                            <div class="row-items row-week-items row-items-opique row-week-item-new-design"
                              [ngStyle]="{ backgroundColor: defaultColors.availability, 'inset': event.inset, opacity: hoverEvent['av-row-week-items-' + i] ? '1' : hoverColumnEvent['av-row-week-items0'] ? '0.5' : '1'}"
                              (click)="selectAvailabilityEventItem($event, currentDay, event)"
                              (mouseover)="hoverEvent['av-row-week-items-'+ i] = true;hoverColumnEvent['av-row-week-items0'] = true"
                              (mouseleave)="hoverEvent['av-row-week-items-'+ i] = false;hoverColumnEvent['av-row-week-items0'] = false"
                              [matMenuTriggerFor]="afterMenuDay">
                            </div>
                            <mat-menu #afterMenuDay="matMenu" xPosition="before">
                              <button mat-menu-item
                                (click)="selectAvailabilityEventDayItem(itm.value, currentDay, event)"
                                *ngFor="let itm of dayAvailabilityActions">{{itm.displayName}}</button>
                            </mat-menu>
                          </ng-container>
                          <ng-container *ngIf="dayAvailabilityActions.length == 0">
                            <div class="row-items row-week-items row-items-opique row-week-item-new-design"
                              [ngStyle]="{ backgroundColor: defaultColors.availability, 'inset': event.inset, opacity: hoverEvent['av-row-week-items-' + i] ? '1' : hoverColumnEvent['av-row-week-items0'] ? '0.5' : '1'}"
                              (click)="selectAvailabilityEventItem($event, currentDay, event)"
                              (mouseover)="hoverEvent['av-row-week-items-'+ i] = true;hoverColumnEvent['av-row-week-items0'] = true"
                              (mouseleave)="hoverEvent['av-row-week-items-'+ i] = false;hoverColumnEvent['av-row-week-items0'] = false">
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!newDesign">
                      <div fxFlex class="events-row-items"
                        *ngFor="let colEvents of currentDay.availabilityView;let m = index">
                        <ng-container *ngFor="let event of colEvents; let i = index">
                          <ng-container *ngIf="dayAvailabilityActions.length > 0">
                            <div class="row-items row-week-items row-items-opique"
                              [ngStyle]="{ backgroundColor: defaultColors.availability, marginTop: event.arrayView[currentDay.date] ? event.arrayView[currentDay.date].topMargin : '0px', height: event.arrayView[currentDay.date] ? event.arrayView[currentDay.date].height : '0px', opacity: hoverEvent['av-row-week-items-' + m + '-' + i] ? '1' : hoverColumnEvent['av-row-week-items0'] ? '0.5' : '1'}"
                              (click)="selectAvailabilityEventItem($event, currentDay, event)"
                              (mouseover)="hoverEvent['av-row-week-items-'+ m + '-' + i] = true;hoverColumnEvent['av-row-week-items0'] = true"
                              (mouseleave)="hoverEvent['av-row-week-items-'+ m + '-' + i] = false;hoverColumnEvent['av-row-week-items0'] = false"
                              [matMenuTriggerFor]="afterMenuDay">
                            </div>
                            <mat-menu #afterMenuDay="matMenu" xPosition="before">
                              <button mat-menu-item
                                (click)="selectAvailabilityEventDayItem(itm.value, currentDay, event)"
                                *ngFor="let itm of dayAvailabilityActions">{{itm.displayName}}</button>
                            </mat-menu>
                          </ng-container>
                          <ng-container *ngIf="dayAvailabilityActions.length == 0">
                            <div class="row-items row-week-items row-items-opique"
                              [ngStyle]="{ backgroundColor: defaultColors.availability, marginTop: event.arrayView[currentDay.date] ? event.arrayView[currentDay.date].topMargin : '0px', height: event.arrayView[currentDay.date] ? event.arrayView[currentDay.date].height : '0px', opacity: hoverEvent['av-row-week-items-' + m + '-' + i] ? '1' : hoverColumnEvent['av-row-week-items0'] ? '0.5' : '1'}"
                              (click)="selectAvailabilityEventItem($event, currentDay, event)"
                              (mouseover)="hoverEvent['av-row-week-items-'+ m + '-' + i] = true;hoverColumnEvent['av-row-week-items0'] = true"
                              (mouseleave)="hoverEvent['av-row-week-items-'+ m + '-' + i] = false;hoverColumnEvent['av-row-week-items0'] = false">
                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                  <div fxFlex fxLayout="row" class="day-items">
                    <ng-container *ngIf="newDesign">
                      <div fxFlex class="events-row-items" style="height: calc(31px * 48);">
                        <div class="row-items row-week-items row-week-item-new-design"
                          *ngFor="let event of currentDay.eventsBuild;let i = index"
                          [ngStyle]="{ backgroundColor: event.color || event.color!== ''? event.color : defaultColors.eventBg, color: event.color!== ''? 'white' : defaultColors.eventBg === 'unset' ? 'inherit' : defaultColors.eventFont, 'inset': event.inset, opacity: hoverEvent['row-week-items-' + i] ? '1' : hoverColumnEvent['row-week-items0'] ? '0.5' : '1', 'pointer-events': eventsSelectable ? 'auto' : 'none'}"
                          customToolTip [showToolTip]="showToolTip" [contentTemplate]="contentTemplate || eventTemplate"
                          [contentTemplateData]="event" (click)="selectEventItem($event, currentDay, event)"
                          [class.row-items-opique]="event.eventType === 'blueprint'"
                          (mouseover)="hoverEvent['row-week-items-' + i] = true;hoverColumnEvent['row-week-items0'] = true"
                          (mouseleave)="hoverEvent['row-week-items-' + i] = false;hoverColumnEvent['row-week-items0'] = false">
                          <ng-container [ngTemplateOutlet]="contentDataTemplate || eventDataTemplate"
                            [ngTemplateOutletContext]="{ contentTemplateData: {event: event ,currentDay:currentDay} }">
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="!newDesign">
                      <div fxFlex class="events-row-items"
                        *ngFor="let colEvents of currentDay.eventsView;let m = index">
                        <div class="row-items row-week-items" *ngFor="let event of colEvents; let i = index"
                          [ngStyle]="{ backgroundColor: event.color || event.color!== ''? event.color : defaultColors.eventBg, color: event.color!== ''? 'white' : defaultColors.eventBg === 'unset' ? 'inherit' : defaultColors.eventFont, marginTop: event.arrayView[currentDay.date] ? event.arrayView[currentDay.date].topMargin : '0px', height: event.arrayView[currentDay.date] ? event.arrayView[currentDay.date].height : '0px', opacity: hoverEvent['row-week-items-' + m + '-' + i] ? '1' : hoverColumnEvent['row-week-items0'] ? '0.5' : '1', 'pointer-events': eventsSelectable ? 'auto' : 'none'}"
                          customToolTip [showToolTip]="showToolTip" [contentTemplate]="contentTemplate || eventTemplate"
                          [contentTemplateData]="event" (click)="selectEventItem($event, currentDay, event)"
                          [class.row-items-opique]="event.eventType === 'blueprint'"
                          (mouseover)="hoverEvent['row-week-items-'+ m + '-' + i] = true;hoverColumnEvent['row-week-items0'] = true"
                          (mouseleave)="hoverEvent['row-week-items-'+ m + '-' + i] = false;hoverColumnEvent['row-week-items0'] = false">
                          <ng-container [ngTemplateOutlet]="contentDataTemplate || eventDataTemplate"
                            [ngTemplateOutletContext]="{ contentTemplateData: {event: event ,currentDay:currentDay} }">
                          </ng-container>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="calendarListContainer" #calendarListContainer class="calendar-container" fxLayout="column" fxFlex
        *ngIf="viewDesignMode === 'format_list_bulleted'">
        <div *ngIf="viewMode === 'month'" fxFlex fxLayout="column" class="cell-container-list-main">
          <ul>
            <ng-container *ngFor="let item of arrListCalendar;let indexItem=index;">
              <ng-container *ngFor="let cellItem of item;let indexCell = index;">
                <ng-container *ngFor="let event of cellItem.events; let i = index">
                  <ng-container [ngTemplateOutlet]="contentListTemplate || eventListTemplate"
                    [ngTemplateOutletContext]="{ contentTemplateData: {event: event ,currentDay:cellItem, index: indexItem + indexCell + i} }">
                  </ng-container>
                </ng-container>
              </ng-container>
            </ng-container>
          </ul>
        </div>

        <div *ngIf="viewMode === 'week'" fxFlex fxLayout="column" class="cell-container-list-main">
          <ul>
            <ng-container *ngFor="let cellItem of currentListWeekData;let indexCell = index;">
              <ng-container *ngFor="let event of cellItem.events; let i = index">
                <ng-container [ngTemplateOutlet]="contentListTemplate || eventListTemplate"
                  [ngTemplateOutletContext]="{ contentTemplateData: {event: event ,currentDay:cellItem, index: indexCell + i} }">
                </ng-container>
              </ng-container>
            </ng-container>
          </ul>
        </div>

        <div *ngIf="viewMode === 'day'" fxFlex fxLayout="column" class="cell-container-list-main">
          <ul>
            <ng-container *ngFor="let colEvents of currentDay['eventsViewList']">
              <ng-container *ngFor="let event of colEvents; let i = index">
                <ng-container [ngTemplateOutlet]="contentListTemplate || eventListTemplate"
                  [ngTemplateOutletContext]="{ contentTemplateData: {event: event ,currentDay:currentDay, index: i} }">
                </ng-container>
              </ng-container>
            </ng-container>
          </ul>
        </div>

      </div>
    </div>
  </div>
  <ng-template #eventTemplate let-contentTemplateData="contentTemplateData">
    <div class="eventOverlay" *ngIf="contentTemplateData">
      <div class="eventOverlayTitle" *ngIf="contentTemplateData.name">
        <div>{{contentTemplateData.name}}</div>
      </div>
      <div class="eventOverlayContent" *ngIf="contentTemplateData.description">
        <div>Description: </div>
        <div>{{contentTemplateData.description}}</div>
      </div>
      <div class="eventOverlayContent" *ngIf="contentTemplateData.startdate">
        <div>Start: </div>
        <div>{{contentTemplateData.startdate | date: dateFormat+ ' hh:mm aa'}}</div>
      </div>
      <div class="eventOverlayContent" *ngIf="contentTemplateData.enddate">
        <div>End: </div>
        <div>{{contentTemplateData.enddate | date: dateFormat+ ' hh:mm aa'}}</div>
      </div>
    </div>
  </ng-template>
  <ng-template #eventListTemplate let-contentTemplateData="contentTemplateData">
    <li (click)="selectEventItem($event, contentTemplateData.currentDay, contentTemplateData.event)" customToolTip
      [contentTemplate]="contentTemplate || eventTemplate" [contentTemplateData]="contentTemplateData.event">
      <div class="eventContent">
        <div class="dateTimeEventMain" fxFlex fxLayout="row" fxLayoutGap="0.5vw">
          <div class="dateTimeEvent" fxFlex>
            <div class="dateTimeEventDate">{{contentTemplateData.event.startdate | date: 'dd'}}</div>
            <div class="dateTimeEventMonth">{{contentTemplateData.event.startdate | date: 'MMM'}}</div>
          </div>
          <div *ngIf="!contentTemplateData.event.sameDay" class="dateTimeEvent dateTimeEventSecond" fxFlex>
            <div class="dateTimeEventDate">{{contentTemplateData.event.enddate | date: 'dd'}}</div>
            <div class="dateTimeEventMonth">{{contentTemplateData.event.enddate | date: 'MMM'}}</div>
          </div>
        </div>
        <div class="infoEvent" fxFlex fxLayout="column">
          <div class="infoEventTitle" fxFlex>
            <span style="font-weight: bold;">
              {{ (contentTemplateData.event.name.length>150)? (contentTemplateData.event.name | slice:0:150)+'...':
              contentTemplateData.event.name }}
            </span>
          </div>
          <div class="infoEventMore" fxFlex>
            <span class="dateTime">
              <mat-icon>schedule</mat-icon>
              {{contentTemplateData.event.startdate |
              date:
              'hh:mm aa'}} -
              {{contentTemplateData.event.enddate | date: 'hh:mm aa'}}
            </span>
            <span
              *ngIf="(contentTemplateData.event.meetingType == '1' && contentTemplateData.event.place == '1' && contentTemplateData.event.room) || (contentTemplateData.event.meetingType == '2' && contentTemplateData.event.location && contentTemplateData.event.location !== '')">
              <mat-icon class="eventIcon" title="{{'Location'}}">location_on</mat-icon>
              <span
                *ngIf="(contentTemplateData.event.meetingType == '1' && contentTemplateData.event.room && contentTemplateData.event.room.name && contentTemplateData.event.room.name !== '' && contentTemplateData.event.place == '1')">{{contentTemplateData.event.room.name}}</span>

              <span
                *ngIf="contentTemplateData.event.meetingType == '2' && contentTemplateData.event.location !== ''">{{contentTemplateData.event.location}}</span>
            </span>
            <span *ngIf="contentTemplateData.event.isRecurrence">
              <mat-icon class="eventIcon" title="{{'Recurrent'}}">cached</mat-icon> Recurrent
            </span>
            <span *ngIf="contentTemplateData.event.eventType === 'blueprint'">
              <mat-icon class="eventIcon" title="{{'Blueprint'}}">
                push_pin
              </mat-icon>
            </span>
          </div>
          <!-- <span
            *ngIf="contentTemplateData.currentDay.day == (contentTemplateData.event.startdate | date: 'dd') && contentTemplateData.event.description !== ''"
            class="descData"> - {{ (contentTemplateData.event.description.length>1000)?
            (contentTemplateData.event.description | slice:0:1000)+'...':
            contentTemplateData.event.description }}
          </span> -->
        </div>
      </div>
    </li>
  </ng-template>
  <ng-template #eventDataTemplate let-contentTemplateData="contentTemplateData">
    <mat-icon class="eventIcon" *ngIf="contentTemplateData.event.eventType === 'blueprint'" title="{{'Blueprint'}}">
      push_pin
    </mat-icon>
    <mat-icon class="eventIcon"
      *ngIf="contentTemplateData.event.isRecurrence && contentTemplateData.event.eventType === 'blueprint'"
      title="{{'Repeating blueprint'}}">
      cached
    </mat-icon>
    <mat-icon class="eventIcon"
      *ngIf="contentTemplateData.event.isRecurrence && contentTemplateData.event.eventType !== 'blueprint'"
      title="{{'Repeating event'}}">
      cached
    </mat-icon>
    <span style="font-weight: bold;">
      {{ (contentTemplateData.event.name.length>150)? (contentTemplateData.event.name | slice:0:150)+'...':
      contentTemplateData.event.name }}
    </span>
    <!-- <span *ngIf="contentTemplateData.currentDay.day == (contentTemplateData.event.startdate | date: 'dd') && contentTemplateData.event.description !== ''"> -->
    <span *ngIf="contentTemplateData.event.description !== ''">
      -
      {{ (contentTemplateData.event.description.length>150)? (contentTemplateData.event.description |
      slice:0:150)+'...':
      contentTemplateData.event.description
      }}</span>
  </ng-template>
  <!--[ngClass]="(event.startdate | date: 'dd') == (event.enddate | date: 'dd') ? 'row-items' 
                : ((event.startdate | date: 'dd') == test.day ? 'row-items-multi-first' 
                : ((event.enddate | date: 'dd') == test.day ? 'row-items-multi-last' : 'row-items-multi') )" 
              
                Multi day event:
              <span *ngIf="test.day == (event.startdate | date: 'dd')" >{{event.name}}</span> &nbsp;
                <span *ngIf="test.day == (event.startdate | date: 'dd')"> - {{event.description}}</span> </div>
              -->