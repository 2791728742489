<div class="col-xl-12 pictureDialog" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content >
      <mat-card >
				<div class="example-full-width">
					<mat-form-field type="url" style="width: 100%;">
							<input matInput placeholder="{{ 'URL' | translate }}" [(ngModel)]="returnData['src']">
					</mat-form-field>
				</div>
				<div class="example-full-width">
					<mat-label>{{'Volume' | translate}}</mat-label>
					<mat-slider min="0" max="1" step="0.1" [thumbLabel]="true" [(ngModel)]="returnData['volume']"></mat-slider>
				</div>
				<div class="example-full-width">
					<mat-icon *ngIf="!returnData['autoPlay']" (click)="returnData['autoPlay']=true" title="{{'Auto Play' | translate}}" style="height: 30px;vertical-align: middle;cursor:pointer">check_box_outline_blank</mat-icon>
					<mat-icon *ngIf="returnData['autoPlay']" (click)="returnData['autoPlay']=false" title="{{'Auto Play' | translate}}" style="height: 30px;vertical-align: middle;cursor:pointer">check_box</mat-icon>
					<label style="width: 13vw" for="autoPlay"> {{'Auto Play' | translate}}</label>
				</div>
				<div class="example-full-width">
					<mat-icon *ngIf="!returnData['loop']" (click)="returnData['loop']=true" title="{{'Loop' | translate}}" style="height: 30px;vertical-align: middle;cursor:pointer">check_box_outline_blank</mat-icon>
					<mat-icon *ngIf="returnData['loop']" (click)="returnData['loop']=false" title="{{'Loop' | translate}}" style="height: 30px;vertical-align: middle;cursor:pointer">check_box</mat-icon>
					<label style="width: 13vw" for="loop"> {{'Loop' | translate}}</label>
				</div>
      </mat-card>
	</div>
	<div mat-dialog-actions>
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
			<button mat-raised-button (click)="closeModal(returnData)">{{'Confirm' | translate}}</button>
	</div>
	<br/>
</div>
