<mat-form-field class="example-full-width" *ngIf="!simple">
  <input #inputName [value]="dataText" matInput type="text" placeholder="{{placeholder}}" aria-label="Number"
    [matAutocomplete]="auto" (input)="termSearch($event.target.value)" class="truncateInput"
    (focusin)="onFocusMethod($event.target.value)" (focusout)="onBlurMethod($event.target.value)" title="{{dataText}}">
  <mat-autocomplete class="autocomplete" #auto="matAutocomplete" [displayWith]="displayFn"
    (optionSelected)="setAttribute($event, $event.option.value)">
    <mat-option *ngIf="addAll && !hasClearBtn" [value]="{_id: '-1', text: 'Select All'}"
      title="{{'Select All' | translate}}">{{'Select All' | translate}}
    </mat-option>
    <mat-option *ngFor="let option of filteredData | async" [value]="option" title="{{option.text | translate}}">
      <span class="optionText" [class.optionTextWithIcon]="canDelete">{{option.text | translate}}</span>
      <mat-icon *ngIf="canDelete" (click)="deleteItem($event, option)" class="editIcon" style="margin-top: 10px;"
        matTooltip="{{'Delete' | translate}} {{dataTypeDisplay | translate}}" matTooltipClass="tooltip-red">delete
      </mat-icon>
    </mat-option>
  </mat-autocomplete>
  <mat-icon *ngIf="hasClearBtn && value" (click)="clearValue($event)" class="editIcon"
    matTooltip="{{'Clear' | translate}} {{dataTypeDisplay | translate}}" matTooltipClass="tooltip-red">close</mat-icon>
  <mat-icon *ngIf="hasEditBtn && value" (click)="sendSignal($event, 'edit')" class="editIcon"
    matTooltip="{{'Edit' | translate}} {{dataTypeDisplay | translate}}" matTooltipClass="tooltip-red">edit_box
  </mat-icon>
  <mat-icon *ngIf="!canNewList && hasAddBtn" (click)="sendSignal($event, 'add')" class="addIcon"
    matTooltip="{{'Add' | translate}} {{dataTypeDisplay}}" matTooltipClass="tooltip-red">add_box</mat-icon>
  <mat-icon *ngIf="canNewList && hasAddBtn" (click)="triggerButtun($event, 'createClick')" class="addIcon"
    matTooltip="{{'Add' | translate}} {{dataTypeDisplay}}" matTooltipClass="tooltip-red">add_box</mat-icon>
</mat-form-field>
<mat-form-field class="example-full-width" *ngIf="simple">
  <mat-label>{{placeholder | translate}}</mat-label>
  <mat-select name="simple-select" title="{{dataText}}" (selectionChange)="setSimpleAttribute($event, $event.value)"
    [value]="_value" (openedChange)="openedChange($event)">
    <mat-option value="" *ngIf="!hasClearBtn">{{itemPlaceholder | translate}}</mat-option>
    <mat-option *ngIf="addAll" value="-1" title="{{'Select All' | translate}}">{{'Select All' | translate}}
    </mat-option>
    <mat-option *ngFor="let option of filteredData | async" [value]="option._id" title="{{option.text | translate}}">
      <span class="optionText" [class.optionTextWithIcon]="(showActive && option.active) || canDelete">{{option.text |
        translate}}</span>
      <mat-icon *ngIf="showActive && option.active" class="editIcon" style="margin-top: 10px;color:#598846"
        matTooltip="{{'Active' | translate}}" matTooltipClass="tooltip-red" svgIcon="active-icon"></mat-icon>
      <mat-icon *ngIf="canDelete" (click)="deleteItem($event, option)" class="editIcon" style="margin-top: 10px;"
        matTooltip="{{'Delete' | translate}} {{dataTypeDisplay | translate}}" matTooltipClass="tooltip-red">delete
      </mat-icon>
    </mat-option>
    <mat-select-trigger>
      <div style="width: 85%;display: inline-block;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
        {{dataText}}
      </div>
    </mat-select-trigger>
  </mat-select>
  <mat-icon *ngIf="hasClearBtn && value" (click)="clearSimpleValue($event)" class="cancelIcon"
    matTooltip="{{'Clear' | translate}} {{dataTypeDisplay | translate}}" matTooltipClass="tooltip-red">close</mat-icon>
  <mat-icon *ngIf="hasEditBtn && value" (click)="sendSignal($event, 'edit')" class="editIcon"
    matTooltip="{{'Edit' | translate}} {{dataTypeDisplay | translate}}" matTooltipClass="tooltip-red">edit_box
  </mat-icon>
  <mat-icon *ngIf="!canNewList && hasAddBtn" (click)="sendSignal($event, 'create')" class="addIcon"
    matTooltip="{{'Add' | translate}} {{dataTypeDisplay}}" matTooltipClass="tooltip-red">add_box</mat-icon>
  <mat-icon *ngIf="canNewList && hasAddBtn" (click)="triggerButtun($event, 'createClick')" class="addIcon"
    matTooltip="{{'Add' | translate}} {{dataTypeDisplay}}" matTooltipClass="tooltip-red">add_box</mat-icon>
</mat-form-field>
<button *ngIf="canNewList && hasAddBtn" mat-icon-button id="createClick" style="visibility: hidden;"
  [matMenuTriggerFor]="canNewListoption" matTooltip="{{ 'Create New' | translate }}" matTooltipClass="tooltip-red">
  <mat-icon>add_circle_outline</mat-icon>
</button>
<mat-menu #canNewListoption="matMenu">
  <button mat-menu-item *ngFor="let clk of canNewList" [disabled]="clk.disabled"
    (click)="sendSignal(undefined, clk.value)"><span style="pointer-events: all !important;"
      matTooltipClass="tooltip-red" matTooltip="{{clk.disabled ? clk.disabledLabel : ''}}">{{clk.displayName |
      translate}}</span></button>
</mat-menu>