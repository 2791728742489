import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter, Renderer2, ViewChild } from '@angular/core';
import { RequestService, LayoutUtilsService, LoaderService, RoomSessionService, MessagesStatusService, FeaturesPermissionService } from '../../../../shared/services';
import { Location } from '@angular/common';
import { Subscription, interval } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BdcWalkService } from '../../../../shared/modules/bdc-walkthrough';
import { environment } from '../../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { urlSafeBase64Encoding } from '../../../../shared/helpers';
import { roundDecimal, guid } from '../../../../shared/helpers';
import { Observable } from 'rxjs';
import { DeviceDetectorService } from "ngx-device-detector";
import { FileModalDialogComponent } from '../../../../shared/components/custom-file-upload-dialog/custom-file-upload-dialog.component';
import { ModalDialogComponent } from '../../../../shared/components/custom-dialog/custom-dialog.component';
import { SessionUsersAssignDialogComponent } from '../../../../shared/components/session-users-assign-dialog/session-users-assign-dialog.component';
import { SimpleAlertEntityDialogComponent } from '../../modals/simple-entity-dialog/simple-entity-dialog.component';
import { SessionAssignDialogComponent } from '../../../../shared/components/session-assign-dialog/session-assign-dialog.component';
import { ModalAdvancedGalleryDialogComponent } from '../../../../shared/components/custom-advanced-gallery-dialog/custom-advanced-gallery-dialog.component';
import { LayoutComponents, DefaultSettings, CommonFunctions } from '../';
import { TileSelectDialogComponent } from '../../../../shared/components/tile-select-dialog/tile-select-dialog.component';
import { BillboardSelectDialogComponent } from '../../../../shared/components/billboard-select-dialog/billboard-select-dialog.component';
import { BreakoutEditComponent } from '../../../../pages/rooms/components/session-page/breakout/edit/breakout-edit.component';
import { PictureDialogComponent } from '../picture-dialog/picture-dialog.component';
import { AudioEditDialogComponent } from '../audio-edit-dialog/audio-edit-dialog.component';
import { VideoPlaylistDialogComponent } from '../video-playlist-dialog/video-playlist-dialog.component';
import { PartyStreamDialogComponent } from '../party-stream-dialog/party-stream-dialog.component';
import { CustomSelectCreateAutocompleteDialogComponent } from '../../../../shared/components/custom-select-create-autocomplete-dialog/custom-select-create-autocomplete-dialog.component';
import { ResourcesSessionAssignDialogComponent } from '../../../../shared/components/resources-session-assign-dialog/resources-session-assign-dialog.component';
import { PictureWallDialogComponent } from '../picture-wall-dialog/picture-wall-dialog.component';
import { ModalTileCreateDialogComponent } from '../../../../shared/components/tile-create-dialog/tile-create-dialog.component';
import { ScreenLayoutComponent } from '../screen-layout/screen-layout.component';
import { AblyService } from 'src/app/shared/services/ably.service';
import { ConfirmEntityDialogComponent } from '../../modals/confirm-entity-dialog/confirm-entity-dialog.component';
import { CustomDynamicDialogComponent } from '../../modals/custom-dynamic-dialog/custom-dynamic-dialog.component';
import { IframeComponent } from '../iframe/iframe.component';
import { QuuRadioDialogComponent } from '../quu-radio-dialog/quu-radio-dialog.component';
import { tmpdir } from 'os';
import { SessionsGalleryComponent } from '../../sessions-gallery/sessions-gallery.component';

@Component({
  selector: 'app-layout-dynamic-data',
  templateUrl: './layout-dynamic-data.component.html',
  styleUrls: ['./layout-dynamic-data.component.scss']
})
export class LayoutDynamicDataComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public selectedUser: any;
  public isMobile: boolean = false;
  public isAdmin: boolean = false;
  public isSuperAdmin: boolean = false;
  public orgType = environment.orgType;
  public settingOrgObject = undefined;
  public tempSelectedComponent = undefined;
  public errorMessage: string = '';
  public loading: boolean = false;
  public hasNewRoomChanges: boolean = false;
  public hasOfflineNewChanges: boolean = false;
  public hasNewChanges: boolean = false;
  public hasFormErrors: boolean = false;
  public columnsLengthType: any = { desktop: 1, 'desktop-2': 1, 'desktop-3': 1, 'desktop-4': 1, 'desktop-offline': 1, 'desktop-hls': 1 };
  public isSubmitted: boolean = true;
  public viewMode: string = 'desktop';
  public viewRoomMode: string = 'room-offline';
  public showMobile: boolean = false;
  public desktopNamesKeys: any = DefaultSettings.desktopNamesKeys;
  public componentsType: any = DefaultSettings.componentsType;
  public componentsRoomType: any = DefaultSettings.componentsRoomType;
  public restrictedComponents: string[] = DefaultSettings.restrictedComponents;
  public cameraComponents: string[] = DefaultSettings.cameraComponents;
  public pipcameraComponents: string[] = DefaultSettings.pipcameraComponents;
  public camerasCountComponents: string[] = DefaultSettings.camerasCountComponents;
  public videoComponents: string[] = DefaultSettings.videoComponents;
  public videoComponentTypes: any[] = DefaultSettings.videoComponentTypes;
  public tileComponents: string[] = DefaultSettings.tileComponents;
  public componentsName: any = LayoutComponents.componentsName;
  public componentsNameKeys: any = LayoutComponents.componentsNameKeys;
  public emptyComponents: any = DefaultSettings.emptyComponents;
  public advancedComponents: any = DefaultSettings.advancedComponents;
  public peopleType: any = DefaultSettings.peopleType;
  public peopleTypeKeys: any = DefaultSettings.peopleTypeKeys;
  public meetingMode: any = DefaultSettings.meetingMode;
  public hlsComponentsList: any = DefaultSettings.hlsComponentsList;
  public cloudliveComponentsList: any = DefaultSettings.cloudliveComponentsList;
  public garbageList: any[] = [];
  public componentsListType: any = { desktop: [], 'desktop-2': [], 'desktop-3': [], 'desktop-4': [], 'desktop-offline': [], 'desktop-hls': [] };
  public componentsRoomListType: any = { 'room-offline': [], 'green-room': [] };
  public defaultSetting = DefaultSettings.defaultSetting;
  public defaultRoomSetting = DefaultSettings.defaultRoomSetting;
  public settingObject = undefined;
  public settingRoomObject = undefined;
  public displayingVersion = undefined;
  public metaFieldSetting = undefined;
  public originalTableSetting: any = undefined;
  public guestsTotal: number = 0;
  public openedLeftMenu: string = '';
  public openedRightMenu: string = 'componentSettings';
  public rightComponentMenu: boolean = true;
  public openedRightTemplateMenu: string = '';

  public optionDesktopSettingsStatusDefault: any = DefaultSettings.optionDesktopSettingsStatus;
  public optionDesktopSettingsStatus: any = JSON.parse(JSON.stringify(this.optionDesktopSettingsStatusDefault));

  public dialogRoomComponentsChk: boolean = false;

  public showPageSettings: boolean = false;
  public showStreamModeSettings: boolean = false;
  public bgBackgroundLink: string = '';
  public bgBackgroundLink1: string = '';
  public bgBackgroundLink2: string = '';
  public bgBackgroundLink3: string = '';
  public bgBackgroundLink4: string = '';
  public bgBackgroundColor: string = '';
  public stat_rem_viwer_hrs: number = 0;

  public selectedColumn = undefined;
  public selectedComponent = undefined;
  public liveXY: any = { name: '', x: 0, y: 0, c: 0, m: 0, w: 0, h: 0, z: 1, bgcolor: '', t: 100, rx: 0, ry: 0, rz: 0 };

  public templateList: any[] = [];

  public sessionList = [];
  public sessionChained = undefined;
  public subscriptionOrgObject = undefined;
  public subscriptionStatus = undefined;
  // public cloudliveSubscriptionStatus: boolean = false;
  public subscriptionUpgradeStatus = DefaultSettings.SubscriptionUpgradeMessage;
  public SubscriptionCloudliveStatus = DefaultSettings.SubscriptionCloudliveMessage;
  public subscriptionExhibitStatus = DefaultSettings.SubscriptionExhibitMessage;
  public subscriptionExhibitUpgradeStatus = DefaultSettings.SubscriptionExhibitUpgradeMessage;
  public canNewList: any[] = DefaultSettings.roomNewList;
  public viewModeDisplay: any = { desktop: 'Desktop', 'desktop-2': 'Desktop', 'desktop-3': 'Desktop', 'desktop-4': 'Desktop', 'desktop-offline': 'Desktop', 'desktop-hls': 'Desktop' };
  public rtchlsModeDisplay: any = { desktop: 'RTC', 'desktop-offline': 'RTC', 'desktop-2': 'RTC', 'desktop-3': 'RTC', 'desktop-4': 'RTC', 'desktop-hls': 'HLS' };
  public rtchlsModeHLSDisplay: any = { desktop: 'Speaker / Moderator Layout', 'desktop-offline': 'Speaker / Moderator Layout', 'desktop-2': 'Speaker / Moderator Layout', 'desktop-3': 'Speaker / Moderator Layout', 'desktop-4': 'Speaker / Moderator Layout', 'desktop-hls': 'Attendee layout' };
  public streamModeDisplay: any = { rtc: 'RTC', rtctohls: 'RTC to HLS', meeting: 'Meeting', largemeeting: 'Large Meeting', hls: 'HLS', cloudlive: 'CloudLive' };
  public streamModeHLSDisplay: any = { rtc: 'Speaker / Moderator Layout', rtctohls: 'RTC to HLS', meeting: 'Meeting', largemeeting: 'Large Meeting', hls: 'Attendee layout', cloudlive: 'CloudLive' };
  public streamModeDisplayToolTip: any = { rtc: 'Realtime Interaction (RTC)', rtctohls: 'Dynamic (RTC to HLS)', meeting: 'Meeting', largemeeting: 'Large Meeting', hls: 'Broadcast (HLS)', cloudlive: 'CloudLive' };

  // @Input() templateSettings: any = undefined;
  @Input() viewPortMode: any = 'desktop';
  @Input() backLayer: any = false;
  @Input() data: any = undefined;
  @Output() closeModalReturn = new EventEmitter<any>();
  @Output() sessionReturn = new EventEmitter<any>();
  @Output() roomReturn = new EventEmitter<any>();
  @Output() viewModeReturn = new EventEmitter<string>();
  @Output() streamModeReturn = new EventEmitter<string>();
  @Output() showMobileReturn = new EventEmitter<boolean>();
  @Output() backLayerReturn = new EventEmitter<boolean>();
  @Output() templateActionReturn = new EventEmitter<string>();
  @Output() activeModeReturn = new EventEmitter<boolean>();

  @ViewChild('desktopOnline') desktopOnline: ScreenLayoutComponent;
  @ViewChild('desktopDesktop2') desktop2: ScreenLayoutComponent;
  @ViewChild('desktopDesktop3') desktop3: ScreenLayoutComponent;
  @ViewChild('desktopDesktop4') desktop4: ScreenLayoutComponent;
  @ViewChild('desktopOffline') desktopOffline: ScreenLayoutComponent;
  @ViewChild('desktophlsOnline') desktophlsOnline: ScreenLayoutComponent;
  @ViewChild('roomOffline') roomOffline: ScreenLayoutComponent;
  @ViewChild('dialogRoomComponents') dialogRoomComponents: CustomDynamicDialogComponent;
  @ViewChild('sessionsGalleryList') sessionsGalleryList: SessionsGalleryComponent;
  constructor(private location: Location,
    private layoutUtilsService: LayoutUtilsService, private requestService: RequestService, private translate: TranslateService, public renderer: Renderer2,
    public dialog: MatDialog, private router: Router, private activatedRoute: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef, private loaderService: LoaderService, private bdcWalkService: BdcWalkService, public roomSessionService: RoomSessionService, private ablyService: AblyService, private messagesStatusService: MessagesStatusService, private featuresPermissionService: FeaturesPermissionService, public deviceService: DeviceDetectorService) {
  }

  ngOnInit() {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          // console.log('this.data', this.data);
          this.selectedUser = data;
          this.isAdmin = this.requestService.isUserRoleAdmin();
          this.isSuperAdmin = this.requestService.isUserRoleSuperAdmin();
          this.buildSetting();
          this.getSessions(this.data.roomData);
          if (this.data.roomData) {
            this.bgBackgroundLink1 = this.data.roomData.pictureLink;
            this.bgBackgroundLink2 = this.data.roomData.pictureLink2;
            this.bgBackgroundLink3 = this.data.roomData.pictureLink3;
            this.bgBackgroundLink4 = this.data.roomData.pictureLink4;
            this.bgUpdate(this.data.roomData.pictureLink, this.data.roomData.bgcolor);
            if (!this.data.roomData.settings || !this.data.roomData.settings.hasOwnProperty('version') || (this.data.roomData.settings && this.data.roomData.settings === '') || (this.data.roomData.settings && this.data.roomData.settings.hasOwnProperty('version') && this.data.roomData.settings.version !== this.defaultRoomSetting.version)) {
              this.loadRoomDefaultSettings();
            } else {
              this.cleanRoomSettings();
            }
          }
          // if (this.data.openBg) {
          //   this.galleryLibrary();
          // }

          // else{
          //   this.renderer.setAttribute(document.body, 'style', "background: url('/assets/images/defaultbgd2.jpg'); background-repeat:no-repeat; background-size:cover; background-attachment: fixed; background-position: center 64px;");
          // }
        }
      })
    );
    // this.subscriptions.push(
    //   this.bdcWalkService.changes.subscribe((event) => {
    //       // get the value of a task
    //       const taskCompleted = this.bdcWalkService.getTaskCompleted('taskCreateApp');
    //   })
    // );
    this.subscriptions.push(
      this.featuresPermissionService.currentSubscriptionSubject.subscribe((data) => {
        if (data) {
          this.subscriptionOrgObject = data;
          this.subscriptionStatus = this.checkSubscriptionStatus();
          if (!this.subscriptionOrgObject.features.includes('exhibit-booths')) {
            this.canNewList[1].disabled = true;
          } else {
            if (this.subscriptionOrgObject.package === 'group' || this.subscriptionOrgObject.package === 'full' || this.subscriptionOrgObject.package === 'professional') {
              if (this.subscriptionOrgObject.total_subscription_exhibit_rooms > 0) {
                if (this.subscriptionOrgObject.number_of_remaining_exhibit_rooms <= 0) {
                  this.canNewList[1].disabled = true;
                  this.canNewList[1].disabledLabel = this.subscriptionExhibitUpgradeStatus;
                }
              } else {
                this.canNewList[1].disabled = true;
                this.canNewList[1].disabledLabel = this.subscriptionExhibitStatus;
              }
            }
          }
        }
      })
    );
    this.subscriptions.push(
      this.featuresPermissionService.currentSettingsSubject.subscribe((data) => {
        if (data) {
          this.settingOrgObject = data;
        }
      })
    );
  }
  checkSubscriptionStatus() {
    if (this.subscriptionOrgObject) {
      //we need to enhance this with free hour
      if (this.subscriptionOrgObject.hasOwnProperty('stat_rem_viwer_hrs')) {
        this.stat_rem_viwer_hrs = Number(this.subscriptionOrgObject.stat_rem_viwer_hrs);
        if (this.stat_rem_viwer_hrs > 1) {
          return undefined;
        } else if (this.stat_rem_viwer_hrs > 0 && this.stat_rem_viwer_hrs <= 1) {
          return 'You have 1 hour of free streaming. You will have to purchase streaming hours to be able to stream after that';
        } else {
          return 'You already used your free streaming hour. Please purchase broadcast viewer hours to be able to stream';
        }
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  }
  ngOnDestroy() {
    this.renderer.removeStyle(document.body, 'background');
    this.renderer.removeStyle(document.body, 'overflow-y');
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  ngAfterViewInit() {
    this.subscriptions.push(
      this.activatedRoute.queryParams.subscribe((param) => {
        if ((this.data && this.data.openBg) || param.openBg === 'true') {
          if (param.openBg === 'true') {
            this.router.navigate([], {
              queryParams: {}
            });
          }
          this.galleryLibrary(this.data.openBg || param.openBg);
        }
      }));
  }
  canExit(message: string = 'Are you sure you want to leave without saving?', hasDeclineText: boolean = true): Promise<boolean> {
    if (this.hasNewChanges || this.hasNewRoomChanges || this.hasOfflineNewChanges) {
      let alertSetting = {};
      alertSetting['overlayClickToClose'] = false;
      alertSetting['showCloseButton'] = true;
      alertSetting['confirmText'] = this.translate.instant('Save Changes');
      if (hasDeclineText) {
        alertSetting['declineText'] = this.translate.instant('Leave without saving');
      }

      const dialogRef = this.layoutUtilsService.alertActionElement(this.translate.instant('Save Changes'), this.translate.instant(message), alertSetting);
      return dialogRef.afterClosed()
        .toPromise()
        .then(result => {
          if (result) {
            if (result.action === 'confirmText') {
              return new Promise((resolve, reject) => {
                this.handleGlobalSave((callbackStatus) => {
                  resolve(callbackStatus);
                });
              });
            } else if (result.action === 'declineText') {
              return Promise.resolve(true);
            }
          } else {
            return Promise.resolve(false);
          }
        });
      // if (confirm("Are you sure you want to leave without saving?")) {
      //   return true;
      // } else {
      //   return false;
      // }
    } else {
      return Promise.resolve(true);
    }
  }
  bgUpdate(bgBackgroundLink, bgBackgroundColor) {
    this.bgBackgroundColor = bgBackgroundColor;
    this.bgBackgroundLink = bgBackgroundLink;
    if (this.viewMode === 'desktop-2') {
      this.bgBackgroundLink2 = bgBackgroundLink;
    } else if (this.viewMode === 'desktop-3') {
      this.bgBackgroundLink3 = bgBackgroundLink;
    } else if (this.viewMode === 'desktop-4') {
      this.bgBackgroundLink4 = bgBackgroundLink;
    } else {
      this.bgBackgroundLink1 = bgBackgroundLink;
    }
    let styleBG = "background: ";
    if (bgBackgroundColor !== '') {
      styleBG = styleBG + bgBackgroundColor;
    } else {
      styleBG = styleBG + '#efefef';
    }

    let bgBackgroundposition = "center 55px";
    if (this.isMobile) {
      bgBackgroundposition = "center 0px";
    }
    if (bgBackgroundLink !== '') {
      styleBG = styleBG + " url(" + bgBackgroundLink + "); background-repeat:no-repeat; background-size:100%; background-attachment: fixed; background-position: " + bgBackgroundposition + "; overflow-y: hidden;";
      this.renderer.setAttribute(document.body, 'style', styleBG);
    } else {
      styleBG = styleBG + ";"
      this.renderer.setAttribute(document.body, 'style', styleBG);
    }
  }
  cancelThru(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
  }
  triggerButtun(name) {
    document.getElementById(name).click();
  }
  selectThisComponent(subIndex) {
    setTimeout(() => {
      if (this.viewMode === 'desktop') {
        this.desktopOnline.selectComponent(undefined, 0, subIndex);
      } else if (this.viewMode === 'desktop-2') {
        this.desktop2.selectComponent(undefined, 0, subIndex);
      } else if (this.viewMode === 'desktop-3') {
        this.desktop3.selectComponent(undefined, 0, subIndex);
      } else if (this.viewMode === 'desktop-4') {
        this.desktop4.selectComponent(undefined, 0, subIndex);
      } else if (this.viewMode === 'desktop-offline') {
        this.desktopOffline.selectComponent(undefined, 0, subIndex);
      } else if (this.viewMode === 'desktop-hls') {
        this.desktophlsOnline.selectComponent(undefined, 0, subIndex);
      }
    }, 100);
  }
  selectThisRoomComponent(subIndex) {
    setTimeout(() => {
      if (this.viewRoomMode === 'room-offline') {
        this.roomOffline.selectComponent(undefined, 0, subIndex);
      }
    }, 100);
  }
  selectComponent(cmp) {
    // console.log('selectComponent', cmp);
    if (cmp.hasOwnProperty('selectedColumn')) {
      this.selectedColumn = cmp.selectedColumn;
    } else {
      this.selectedColumn = undefined;
    }
    if (cmp.hasOwnProperty('selectedComponent')) {
      this.selectedComponent = cmp.selectedComponent;
    } else {
      this.selectedComponent = undefined;
    }
    if (cmp.hasOwnProperty('liveXY')) {
      cmp.liveXY.rx = !isNaN(cmp.liveXY.rx) ? cmp.liveXY.rx : 0;
      cmp.liveXY.ry = !isNaN(cmp.liveXY.ry) ? cmp.liveXY.ry : 0;
      cmp.liveXY.rz = !isNaN(cmp.liveXY.rz) ? cmp.liveXY.rz : 0;
      this.liveXY = cmp.liveXY;
    }
  }
  selectedComponentChange(option, $eventValue, isNumeric: boolean = false, $event?) {
    let val = $eventValue;
    if (val < 0 && option === 'z') {
      val = Math.abs(val);
    }
    if (isNumeric) {
      val = Number($eventValue);
    }

    if ((option === 'w' || option === 'h') && val > 100) {
      val = 10;
    }
    if ((option === 'y' || option === 'x') && val > 99) {
      val = 0;
    }
    if (option === 'z' && val > 99) {
      val = 99;
    }
    if (option === 'z' && val < 0) {
      val = 0;
    }
    if (option === 't') {
      if (val > 100)
        val = 100;
      else if (val < 10)
        val = 10;
    }
    if (option === 'rx') {
      if (val < -180)
        val = -180;
      else if (val > 180)
        val = 180;
      $event.target.value = val;
    }
    else if (option === 'ry') {
      if (val < -180)
        val = -180;
      else if (val > 180)
        val = 180;
      $event.target.value = val;
    }
    else if (option === 'rz') {
      if (val < -180)
        val = -180;
      else if (val > 180)
        val = 180;
      $event.target.value = val;
    }

    if (this.backLayer) {
      let settingObject = JSON.parse(JSON.stringify(this.settingRoomObject[this.viewRoomMode]));
      settingObject['columns'][this.selectedColumn]['components'][this.selectedComponent][option] = val;
      this.liveXY[option] = val;
      this.data.roomData['settings'][this.viewRoomMode] = JSON.parse(JSON.stringify(settingObject));
      this.cleanRoomSettings()
      this.hasNewRoomChanges = true;
    } else {
      let settingObject = JSON.parse(JSON.stringify(this.settingObject[this.viewMode]));
      settingObject['columns'][this.selectedColumn]['components'][this.selectedComponent][option] = val;
      this.liveXY[option] = val;
      this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(settingObject));
      this.cleanSettings(false);
      this.hasNewChanges = true;
    }
    this.changeDetectorRef.detectChanges();
  }
  validateComponents(cmp, viewMode) {
    if (!this.componentsName[cmp.name].fixed) {
      let currentSettings = JSON.parse(JSON.stringify(this.settingObject[viewMode]));
      for (let col of currentSettings['columns']) {
        col['components'] = col['components'].filter((comp) => cmp.name === comp.name);
        if (col['components'].length > 0) {
          return false;
        }
      }
    }
    return true;
  }
  validateRoomComponents(cmp, viewRoomMode) {
    if (!this.componentsName[cmp.name].fixed) {
      let currentSettings = JSON.parse(JSON.stringify(this.settingRoomObject[viewRoomMode]));
      for (let col of currentSettings['columns']) {
        col['components'] = col['components'].filter((comp) => cmp.name === comp.name);
        if (col['components'].length > 0) {
          return false;
        }
      }
    }
    return true;
  }
  insertEmptyComponent() {
    let cmpType = 'empty-click';
    let cmp = this.componentsName[cmpType]['content'];
    this.insertComponent(cmp);
  }
  flipLayer(value) {
    if (!value && this.sessionList.length === 0) {
      this.saveData('edit');
    }
    this.backLayerReturn.emit(value);
  }
  /**
  * Add DefaultVideo aka Media
  */
  insertDefaultVideoComponent(cmp, type) {
    cmp.type = type;
    this.openedLeftMenu = '';
    const dialogRef = this.dialog.open(PartyStreamDialogComponent, {
      width: '600px',
      disableClose: false,
      autoFocus: false,
      data: {
        handleBgColor: false,
        isDesigner: true,
        isDesignerOffline: this.viewMode === 'desktop-offline',
        title: this.translate.instant('Add') + ' ' + this.translate.instant('Streaming Media'),
        data: cmp,
        source: 'designer',
        currentUserId: this.selectedUser._id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        cmp['autoStart'] = result.data.autoStart;
        cmp['loop'] = result.data.loop;
        cmp['controls'] = result.data.controls;
        cmp['type'] = result.data.type;
        cmp['url'] = result.data.url;
        cmp['clickThruUrl'] = result.data.clickThruUrl;
        cmp['hlsUrl'] = result.data.hlsUrl;
        cmp['startMin'] = result.data.startMin;
        cmp['startSec'] = result.data.startSec;
        cmp['autoRepeat'] = result.data.autoRepeat;


        if (this.backLayer) {
          let currentSettings = JSON.parse(JSON.stringify(this.settingRoomObject[this.viewRoomMode]));
          if (cmp.name === 'defaultvideo') {
            let componentsCopy = currentSettings['columns'][0]['components'];
            let componentsListNotDefaultvideo = componentsCopy.filter((cmp) => cmp.name !== 'defaultvideo');
            let highestZIndex = this.getHighestZ(componentsListNotDefaultvideo);
            cmp.z = highestZIndex + 1;
          }
          currentSettings['columns'][0]['components'].push(cmp);
          this.data.roomData['settings'][this.viewRoomMode] = JSON.parse(JSON.stringify(currentSettings));
          this.cleanRoomSettings();
          this.selectRoomChildLastComponent();
          this.hasNewRoomChanges = true;
        } else {
          let currentSettings = JSON.parse(JSON.stringify(this.settingObject[this.viewMode]));
          if (cmp.name === 'defaultvideo') {
            let componentsCopy = currentSettings['columns'][0]['components'];
            let componentsListNotDefaultvideo = componentsCopy.filter((cmp) => cmp.name !== 'defaultvideo');
            let highestZIndex = this.getHighestZ(componentsListNotDefaultvideo);
            cmp.z = highestZIndex + 1;
          }
          currentSettings['columns'][0]['components'].push(cmp);
          this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(currentSettings));
          this.cleanSettings();
          this.selectChildLastComponent();
          this.hasNewChanges = true;
        }
        this.openedLeftMenu = '';
      }
    });
  }
  /**
  * Add DefaultVideo aka Media
  */
  insertVideoLibraryComponent(cmp) {
    this.openedLeftMenu = '';
    const dialogRef = this.dialog.open(VideoPlaylistDialogComponent, {
      width: '600px',
      disableClose: false,
      autoFocus: false,
      data: {
        title: this.translate.instant('Add') + ' ' + this.translate.instant('Media Library'),
        data: cmp['metadata']
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        cmp['metadata'] = result;
        if (this.backLayer) {
          let currentSettings = JSON.parse(JSON.stringify(this.settingRoomObject[this.viewRoomMode]));
          currentSettings['columns'][0]['components'].push(cmp);
          this.data.roomData['settings'][this.viewRoomMode] = JSON.parse(JSON.stringify(currentSettings));
          this.cleanRoomSettings();
          this.selectRoomChildLastComponent();
          this.hasNewRoomChanges = true;
        } else {
          let currentSettings = JSON.parse(JSON.stringify(this.settingObject[this.viewMode]));
          currentSettings['columns'][0]['components'].push(cmp);
          this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(currentSettings));
          this.cleanSettings();
          this.selectChildLastComponent();
          this.hasNewChanges = true;
        }
        this.openedLeftMenu = '';
      }
    });
  }
  insertComponent(originCmp, x: number = undefined, y: number = undefined) {
    let cmp = JSON.parse(JSON.stringify(originCmp));
    if (this.restrictedComponents.includes(cmp.name) && this.subscriptionStatus && this.subscriptionOrgObject) {
      if (this.stat_rem_viwer_hrs === 0) {
        this.layoutUtilsService.showNotification(this.subscriptionStatus, this.translate.instant('Dismiss'));
        return;
      }
    }
    cmp['uid'] = guid(); // generate Uid for new component
    cmp.x = roundDecimal(((window.screen.width / 2) * 100) / window.screen.width, 2) - cmp.w / 2;
    cmp.y = roundDecimal(((window.screen.height / 2) * 100) / window.screen.width, 2) - cmp.w / 2;
    if (this.validateComponents(cmp, this.viewMode)) {
      if (this.tileComponents.includes(cmp.name)) {
        this.addTile(cmp);
      } else if (cmp.name === 'billboard') {
        this.addBillboard(cmp);
      } else if (cmp.name === 'picturewall') {
        this.addPicturewall(cmp);
      } else if (cmp.name === 'picture') {
        this.addPicture(cmp);
      } else if (cmp.name === 'quuNowPlaying') {
        this.addQuuNowPlaying(cmp);
      } else if (cmp.name === 'quuSongs') {
        this.addQuuSongs(cmp);
      } else if (cmp.name === 'iframe') {
        this.addIframe(cmp);
      } else if (cmp.name === 'empty-click') {
        this.addEmptyClick(cmp);
      } else if (cmp.name === 'audio') {
        this.addAudio(cmp);
      } else {
        if (cmp.name === 'panelView') {
          cmp['groups'] = [
            { uid: guid(), cameras: [] }
          ];
        } else if (cmp.name === 'audienceView') {
          cmp['groups'] = [
            { uid: guid(), cameras: [] }
          ];
        }

        if (this.backLayer) {
          let currentSettings = JSON.parse(JSON.stringify(this.settingRoomObject[this.viewRoomMode]));
          currentSettings['columns'][0]['components'].push(cmp);
          this.data.roomData['settings'][this.viewRoomMode] = JSON.parse(JSON.stringify(currentSettings));
          this.cleanRoomSettings();
          this.selectRoomChildLastComponent();
          this.hasNewRoomChanges = true;
        } else {
          let currentSettings = JSON.parse(JSON.stringify(this.settingObject[this.viewMode]));
          currentSettings['columns'][0]['components'].push(cmp);
          this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(currentSettings));
          this.cleanSettings(false);
          this.selectChildLastComponent();
          this.hasNewChanges = true;
        }
        this.openedLeftMenu = '';
        this.openedRightTemplateMenu = '';
      }
    } else {
      if (this.backLayer) {
        let currentSettings = JSON.parse(JSON.stringify(this.settingRoomObject[this.viewRoomMode]));
        currentSettings['columns'][0]['components'].push(cmp);
        this.data.roomData['settings'][this.viewRoomMode] = JSON.parse(JSON.stringify(currentSettings));
        this.cleanRoomSettings();
        this.selectRoomChildLastComponent();
        this.hasNewRoomChanges = true;
      } else {
        let currentSettings = JSON.parse(JSON.stringify(this.settingObject[this.viewMode]));
        currentSettings['columns'][0]['components'].push(cmp);
        this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(currentSettings));
        this.cleanSettings();
        this.selectChildLastComponent();
        this.hasNewChanges = true;
      }
      this.openedLeftMenu = '';
      this.openedRightTemplateMenu = '';
    }
    for (let cmpType of this.componentsName[cmp.name].rules['requireCmp']) {
      let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
      let cmpAttachAvailable = currentSettings[this.viewMode]['columns'][0]['components'].filter((cmp) => cmp.name === cmpType);
      if (cmpAttachAvailable.length === 0) {
        let cmpAttach = JSON.parse(JSON.stringify(this.componentsName[cmpType]['content']));
        this.insertComponent(cmpAttach, cmp.x - cmp.w, undefined);
      }
    }
  }
  selectChildLastComponent() {
    setTimeout(() => {
      let currentSettings = JSON.parse(JSON.stringify(this.settingObject[this.viewMode]));
      let subIndex = currentSettings['columns'][0]['components'].length - 1;
      if (this.viewMode === 'desktop') {
        this.desktopOnline.selectComponent(undefined, 0, subIndex);
      } else if (this.viewMode === 'desktop-2') {
        this.desktop2.selectComponent(undefined, 0, subIndex);
      } else if (this.viewMode === 'desktop-3') {
        this.desktop3.selectComponent(undefined, 0, subIndex);
      } else if (this.viewMode === 'desktop-4') {
        this.desktop4.selectComponent(undefined, 0, subIndex);
      } else if (this.viewMode === 'desktop-offline') {
        this.desktopOffline.selectComponent(undefined, 0, subIndex);
      } else if (this.viewMode === 'desktop-hls') {
        this.desktophlsOnline.selectComponent(undefined, 0, subIndex);
      }
    }, 100);
  }
  selectRoomChildLastComponent() {
    setTimeout(() => {
      let currentSettings = JSON.parse(JSON.stringify(this.settingRoomObject[this.viewRoomMode]));
      let subIndex = currentSettings['columns'][0]['components'].length - 1;
      if (this.viewRoomMode === 'room-offline') {
        this.roomOffline.selectComponent(undefined, 0, subIndex);
      }
    }, 100);
  }
  updateLayout(returnObject) {
    this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(returnObject.currentSettings));
    this.cleanSettings(false);
    this.hasNewChanges = true;
  }
  updateOfflineLayout(returnObject) {
    this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(returnObject.currentSettings));
    this.cleanSettings();
    this.hasOfflineNewChanges = true;
  }
  updateRoomLayout(returnObject) {
    this.data.roomData['settings'][this.viewRoomMode] = JSON.parse(JSON.stringify(returnObject.currentSettings));
    this.cleanRoomSettings()
    this.hasNewRoomChanges = true;
  }
  cleanRoomSettings() {
    let currentSettings = JSON.parse(JSON.stringify(this.data.roomData['settings']));
    this.settingRoomObject = JSON.parse(JSON.stringify(currentSettings));

    this.cleanRoomTypeSettings('room-offline');

  }
  public cleanRoomTypeSettings(type) {
    let currentSettings = JSON.parse(JSON.stringify(this.data.roomData['settings'][type]));
    currentSettings = this.syncSettings(currentSettings); // this is to make sure data are synced with new changes
    let componentsList = []; // the list to show on left add comonent buttun

    for (let key of this.componentsNameKeys) {
      if (this.componentsRoomType[type].includes(key)) {
        componentsList.push(JSON.parse(JSON.stringify(this.componentsName[key]['content'])));
      }
    }
    // console.log('componentsList', type, componentsList);
    this.componentsRoomListType[type] = JSON.parse(JSON.stringify(componentsList));
    this.settingRoomObject[type] = JSON.parse(JSON.stringify(currentSettings));
  }
  private buildSetting() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.data.modalSetting.fields = [];
      this.requestService.getMetaData(this.data.dataType, undefined, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.originalTableSetting = data.results;
          this.metaFieldSetting = this.buildMetaSetting(data.results);
          let newFields = data.results.fields;
          let idx = 0;
          if (this.data.modalSetting.hasOwnProperty('customSettings')) {
            for (let fld of newFields) {
              if (this.data.modalSetting.customSettings.hasOwnProperty(fld.name)) {
                newFields[idx]['visible'] = this.data.modalSetting.customSettings[fld.name].visible;
              }
              idx++;
            }
          }
          this.data.modalSetting.fields = newFields;
          if (this.data.data.hasOwnProperty('_id')) {
            this.loadData();
          }
        } else {
          console.log(this.translate.instant('Something is Wrong'));
        }
      });
    }
  }
  selectSession(sessionId) {
    if (sessionId === 'create') {
      this.createSession()
    } else {
      if (this.data.data._id !== sessionId) {
        this.canExit().then((result) => {
          if (result) {
            this.showPageSettings = false;
            this.showStreamModeSettings = false;
            this.data.data = { _id: sessionId };
            this.settingObject = undefined;
            if (sessionId) {
              this.loadData();
            } else {
              sessionStorage.removeItem('sessionId');
              this.sessionReturn.emit({ _id: undefined, name: undefined });
              this.settingObject = undefined;
              this.location.go('/rooms/settings');
              this.hasNewChanges = false;
              this.hasOfflineNewChanges = false;
            }
          }
        });
      }
    }
  }
  editSessionSetting(e, sessionId, trigger) {
    if (e) {
      e.stopPropagation()
    }
    trigger.closeMenu();
    if (this.data.data._id !== sessionId) {
      this.canExit().then((result) => {
        if (result) {
          this.showPageSettings = false;
          this.data.data = { _id: sessionId };
          this.loadData();
          this.showPageSettings = true;
        }
      });
    } else {
      this.showPageSettings = true;
    }
  }
  buildMetaSetting(data, parent = undefined) {
    let dataObject = {};
    let tabObject = [];
    for (let col of data.fields) {
      if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
        if (parent) {
          col['inputName'] = parent + col['name'];
        }
        dataObject[col.name] = col;
      } else if (col.type === 'object') {
        dataObject[col.name] = this.buildMetaSetting(col);
        tabObject.push({ name: col.name, displayName: col.displayName, fields: this.buildMetaSetting(col, col.name) });
      } else if (col.type === 'table') {
        dataObject[col.name] = this.buildMetaSetting(col);
      }
    }
    return dataObject;
  }
  loadRoomDefaultSettings(hasNewRoomChanges = false) {
    this.data.roomData['settings'] = JSON.parse(JSON.stringify(this.defaultRoomSetting));
    this.settingRoomObject = undefined;
    setTimeout(() => {
      this.cleanRoomSettings();
      this.hasNewRoomChanges = hasNewRoomChanges;
    }, 100);
  }
  loadDefaultSettings(hasNewChanges = false) {
    this.data.data['settings'] = JSON.parse(JSON.stringify(this.defaultSetting));
    this.settingObject = undefined;
    setTimeout(() => {
      this.cleanSettings();
      this.hasNewChanges = hasNewChanges;
      this.hasOfflineNewChanges = hasNewChanges;
    }, 100);
  }
  resetRoomSettings() {
    if (this.data.roomData._id) {
      this.loadroomData();
    } else {
      this.loadRoomDefaultSettings();
    }
  }
  resetSettings() {
    if (this.data.data._id) {
      let currentSession = JSON.parse(JSON.stringify(this.data.data._id));
      this.showPageSettings = false;
      this.showStreamModeSettings = false;
      this.data.data = { _id: currentSession };
      this.settingObject = undefined;
      this.loadData();
    } else {
      this.loadDefaultSettings();
    }
  }
  setOptions(e, id, val, viewMode = 'desktop') {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.settingObject[viewMode]['optionSettings'][id] = val;
    if (id === 'enableScreenShareButton' && val) {
      this.settingObject[viewMode]['optionSettings']['enableRaiseHand'] = true;
    }
    this.hasNewChanges = true;
  }
  setCheckBoxOptions(id, val, viewMode = 'desktop') {
    this.settingObject[viewMode]['optionSettings'][id] = val;
    if (id === 'enableScreenShareButton' && val) {
      this.settingObject[viewMode]['optionSettings']['enableRaiseHand'] = true;
    }
    this.hasNewChanges = true;
  }
  /**
  * setNumericOptions function
  */
  setNumericOptions(e, id, val, min, max) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    let newVal = Number(val);
    if (min !== undefined && min > newVal) {
      newVal = min;
    }
    if (max !== undefined && max < newVal) {
      newVal = max;
    }
    this.settingObject['desktop']['optionSettings'][id] = newVal;
    this.changeDetectorRef.detectChanges();
    this.hasNewChanges = true;
  }
  loadTemplateSettings() {
    this.templateList = [];
    let filters: any = {
      '$and': [
        { 'organizationId._id': { '$eq': this.requestService.orgId } }
      ]
    };
    this.requestService.getDataList('layout', {
      fieldKeys: ['_id', 'name', 'settingType', 'bgImage', 'bgcolor', 'settings'], orderDir: 'desc', orderBy: 'createdAt', filter: filters
    }, (data, error) => {
      if (error) {
        this.layoutUtilsService.showNotification('No ' + this.translate.instant('Template') + ' available', 'Dismiss');
      }
      if (data) {
        this.templateList = data.results;
      } else {
        this.templateList = [];
      }
      this.openedRightTemplateMenu = 'tempalatesList';
    });
  }
  public loadTemplateSetting(dataId) {
    this.settingObject = undefined;
    this.openedRightTemplateMenu = '';
    if (!this.loading) {
      this.loading = true;
      this.loaderService.display(true);
      this.errorMessage = '';
      this.requestService.getSingleData('layout', dataId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loaderService.display(false);
        this.loading = false;
        if (data) {
          let templatedata = data.results;
          let templateSettings = JSON.parse(JSON.stringify(templatedata.settings));
          this.data.data['settings'] = this.getCleanSettings(templateSettings);
          //We might need to add bgcolor
          // this.bgBackgroundColor = this.data.roomData.bgcolor;
          this.bgUpdate(templatedata.bgImage, templatedata.bgcolor);
          this.hasNewChanges = true;
          this.cleanSettings();
        }
      });
    }
  }
  public deleteTemplate($event, index, dataId) {
    if ($event) {
      $event.preventDefault();
      $event.stopPropagation();
    }
    if (!this.loading) {
      const _title: string = this.translate.instant('Template') + ' ' + this.translate.instant('Deletion');
      const _description: string = this.translate.instant('Are you sure you want to permanently delete this') + ' ' + this.translate.instant('Template') + '?';
      const _waitDesciption: string = this.translate.instant('Deleting') + '...';

      const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
      dialogRef.afterClosed().subscribe(res => {
        if (!res) {
          return;
        }
        this.realTemplateDelete(index, dataId);
      });
    }
  }
  public realTemplateDelete(index, id: any) {
    const _deleteMessage = this.translate.instant('Template') + ' ' + this.translate.instant('Deleted Successfully') + '.';
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.deleteSingleData('layout', id, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(error, this.translate.instant('Dismiss'));
          this.openedRightTemplateMenu = 'tempalatesList';
        }
        this.loading = false;
        if (data) {
          this.templateList.splice(index, 1);
          this.layoutUtilsService.showNotification(_deleteMessage, this.translate.instant('Dismiss'));
        }
      });
    }
  }
  public loadData() {
    if (!this.loading) {
      this.loading = true;
      this.loaderService.display(true);
      this.errorMessage = '';
      this.settingObject = undefined;
      let dataId = this.data.data['_id'];
      // if (this.data.modalSetting.hasOwnProperty('useOrgId')) {
      //   dataId = dataId + '/' + this.requestService.orgId;
      // }
      this.requestService.getSingleData(this.data.dataType, dataId, (data, error) => {
        this.loaderService.display(false);
        if (error) {
          // this.errorMessage = error;
          // this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
          this.selectSession(undefined);
          this.location.go('/rooms/settings');
          sessionStorage.removeItem('sessionId');
        }
        if (data) {
          this.data.data = data.results;
          this.sessionReturn.emit(this.data.data);
          this.location.go('/rooms/' + this.data.roomData['_id'] + '/session/' + dataId + '/settings');
          sessionStorage.setItem('sessionId', JSON.stringify(dataId));
          this.streamModeReturn.emit(this.data.data.streamMode);
          this.activeModeReturn.emit(this.data.data.active);
          this.sessionChained = this.data.data.chainedtosession?._id;
          if (!this.data.data.settings || !this.data.data.settings.hasOwnProperty('version') || (this.data.data.settings && this.data.data.settings === '') || (this.data.data.settings && this.data.data.settings.hasOwnProperty('version') && this.data.data.settings.version !== this.defaultSetting.version)) {
            this.loadDefaultSettings();
          }
          // this.loadDefaultSettings();
          let host = [];
          if (this.data.data.users) {
            let guests = this.data.data.users.filter((user) => user.type === 'guest');
            this.guestsTotal = guests.length;
            host = this.data.data.users.filter((user) => user.host);

          }
          if (!this.data.data.users || host.length === 0) {
            this.addPeople('host');
          }
          // if (this.subscriptionOrgObject && this.data.data['streamMode'] === 'cloudlive') {
          //   // !this.subscriptionOrgObject.features.includes('cloudlive-streaming')
          //   this.checkValidCloudlive();
          // }
          this.cleanSettings();
          this.backLayerReturn.emit(false);
          this.hasNewChanges = false;
          this.hasOfflineNewChanges = false;
        }
        this.loading = false;
      });
    }
  }
  calculateCameraTotal(): number {
    let currentSettings = JSON.parse(JSON.stringify(this.data.data['settings']['desktop']));
    let specialNumber = 15000;
    let componentsCopy = JSON.parse(JSON.stringify(currentSettings['columns'][0]['components']));
    let cameras = componentsCopy.filter((cmp) => this.camerasCountComponents.includes(cmp.name) && cmp.active);
    let panelList = componentsCopy.filter((cmp) => cmp.name === 'panelView' && cmp.active);
    let total = cameras.length;
    if (total > 0 && total < 11) {
      specialNumber = 15000;
    } else if (total >= 11 && total < 16) {
      specialNumber = 13000;
    } else if (total >= 16 && total < 21) {
      specialNumber = 11000;
    } else if (total >= 21 && total < 26) {
      specialNumber = 8000;
    } else if (total >= 26 && total < 31) {
      specialNumber = 6800;
    } else if (total >= 31 && total < 36) {
      specialNumber = 5700;
    } else if (total >= 36 && total < 41) {
      specialNumber = 5000;
    } else if (total >= 41 && total < 46) {
      specialNumber = 4300;
    } else if (total >= 46 && total < 51) {
      specialNumber = 4000;
    } else if (total >= 51 && total < 61) {
      specialNumber = 3400;
    } else if (total >= 61 && total < 76) {
      specialNumber = 2900;
    } else if (total >= 76 && total < 81) {
      specialNumber = 2800;
    } else if (total >= 81 && total < 101) {
      specialNumber = 2000;
    } else if (total >= 101 && total < 126) {
      specialNumber = 1600;
    } else if (total >= 126) {
      specialNumber = 800;
    }

    if (panelList.length > 0 && specialNumber > 13000) {
      specialNumber = 13000;
    }
    // }
    let maximumAttendees = specialNumber;
    return Math.floor(maximumAttendees);
  }
  calculateAttendeesTotal(total): number {
    let specialNumber = 15000;
    if (total > 0 && total < 3) {
      specialNumber = 15000;
    } else if (total >= 3 && total < 4) {
      specialNumber = 13000;
    } else if (total >= 4 && total < 5) {
      specialNumber = 11000;
    } else if (total >= 5 && total < 6) {
      specialNumber = 8000;
    } else if (total >= 6 && total < 7) {
      specialNumber = 6800;
    } else if (total >= 7 && total < 8) {
      specialNumber = 5700;
    } else if (total >= 8 && total < 9) {
      specialNumber = 5000;
    } else if (total >= 9 && total < 10) {
      specialNumber = 4300;
    } else if (total >= 10 && total < 11) {
      specialNumber = 4000;
    }
    return Math.floor(specialNumber);
  }
  // calculateCameraTotal(): number {
  //   let currentSettings = JSON.parse(JSON.stringify(this.data.data['settings']['desktop']));
  //   let cameraTotal = 0;
  //   let enableAllowAllSpeak = false;
  //   for (let col of currentSettings['columns']) {
  //     col['components'] = col['components'].filter((cmp) => this.cameraComponents.includes(cmp.name));
  //     if (this.data.data['streamMode'] === 'rtctohls' || this.data.data['streamMode'] === 'hls') { // attendee camera is not there we exclude it
  //       col['components'] = col['components'].filter((cmp) => cmp.name !== 'camera');
  //     }
  //     if (col['components'].length > 0) {
  //       for (let cmp of col['components']) {
  //         if (cmp.active) {
  //           if (cmp.name === 'camera') {
  //             if (cmp.enableAllowAllSpeak) {
  //               enableAllowAllSpeak = cmp.enableAllowAllSpeak;
  //             }
  //           }
  //           cameraTotal = cameraTotal + 1;
  //         }
  //       }
  //     } else {
  //       return -1;
  //     }
  //   }
  //   // console.log('cameraTotal' ,enableAllowAllSpeak, cameraTotal);
  //   let specialNumber = 2800;
  //   if (enableAllowAllSpeak) {
  //     specialNumber = 53;
  //   }
  //   let maximumAttendees = specialNumber / cameraTotal;
  //   return Math.floor(maximumAttendees);
  // }
  getUserByStreamMode() {
    if (this.data.data.hasOwnProperty('users') && this.data.data.users) {
      if (this.data.data.streamMode === 'meeting') {
        return this.data.data.users.map((usr) => {
          if (usr.host) {
            usr.type = 'lead';
          } else {
            usr.type = 'attendee';
          }
          return usr;
        });
      } else {
        return this.data.data.users
      }
    }
    return [];
  }
  setStreamMode(value) {
    if (value !== undefined) {
      if (value === 'largemeeting') {
        this.settingObject['desktop']['optionSettings']['numberOfAttendees'] = 0;
      }
      if (value === 'hls') {
        this.data.data['autoend'] = false;
      }
      if (value === 'cloudlive') {
        this.data.data['autoend'] = false;
        this.settingObject['desktop']['optionSettings']['numberOfAttendees'] = 0;
      }
      this.data.data['streamMode'] = value;
      this.data.data['configurations'] = {};
      this.data.data.users = this.getUserByStreamMode();
      this.streamModeReturn.emit(value);
    }
    this.cleanSettings();
    this.hasNewChanges = true;

    //below code to refresh code because we are deleting a component and the cdkdrop is cleaning its cached array
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    this.settingObject = undefined;
    setTimeout(() => {
      this.settingObject = JSON.parse(JSON.stringify(currentSettings));
    }, 100);
  }
  applyChain(e, value) {
    if (e) {
      e.stopPropagation()
    }
    if (!this.data.data.hasOwnProperty('chainedtosession')) {
      this.data.data['chainedtosession'] = {};
    }
    if (value._id !== this.data.data['chainedtosession']['_id']) {
      this.data.data['chainedtosession'] = { _id: value._id, name: value.name };
      this.sessionChained = value._id;
    } else {
      this.data.data['chainedtosession'] = {};
      this.sessionChained = undefined;
    }
    this.hasNewChanges = true;
  }
  cleanStreamMode(changeViewMode = true) {
    if (this.data.data.streamMode !== '') {
      if (this.data.data.streamMode === 'hls') {
        if (changeViewMode && this.viewMode !== 'desktop-offline' && this.viewMode !== 'desktop-2' && this.viewMode !== 'desktop-3' && this.viewMode !== 'desktop-4') {
          this.setViewModeReturn(undefined, 'desktop-hls');
        }
        this.data.data.maximumAttendees = 0;
        this.data.data.maximumCameras = 12;
      } else {
        if (this.viewMode !== 'desktop-offline' && this.viewMode !== 'desktop-2' && this.viewMode !== 'desktop-3' && this.viewMode !== 'desktop-4') {
          this.setViewModeReturn(undefined, 'desktop');
        }
        if (this.data.data.streamMode === 'rtc') {
          let numberofcameras = this.calculateCameraTotal();
          this.data.data.maximumAttendees = numberofcameras;
          this.data.data.maximumCameras = 250;
        } else if (this.data.data.streamMode === 'cloudlive') {
          this.data.data.maximumAttendees = 0;
          this.data.data.maximumCameras = 0;
        } else if (this.data.data.streamMode === 'meeting') {
          this.data.data.maximumAttendees = 55;
          this.data.data.maximumCameras = 0;
        } else if (this.data.data.streamMode === 'largemeeting') {
          this.data.data.maximumAttendees = 55;
          this.data.data.maximumCameras = 0;
        } else {
          // rtctohls
          let numberofcameras = this.calculateCameraTotal();
          this.data.data.maximumAttendees = numberofcameras;
          this.data.data.maximumCameras = 250;
        }
      }
    } else {
      this.data.data.maximumAttendees = 0;
      this.data.data.maximumCameras = 0;
      this.setViewModeReturn(undefined, 'desktop-offline');
    }
  }
  goLeft() {
    if (this.viewMode === 'desktop') {
      this.setViewModeReturn(undefined, 'desktop-4');
      this.bgUpdate(this.bgBackgroundLink4, this.bgBackgroundColor);
    } else if (this.viewMode === 'desktop-2') {
      this.setViewModeReturn(undefined, 'desktop');
      this.bgUpdate(this.bgBackgroundLink1, this.bgBackgroundColor);
    } else if (this.viewMode === 'desktop-3') {
      this.setViewModeReturn(undefined, 'desktop-2');
      this.bgUpdate(this.bgBackgroundLink2, this.bgBackgroundColor);
    } else if (this.viewMode === 'desktop-4') {
      this.setViewModeReturn(undefined, 'desktop-3');
      this.bgUpdate(this.bgBackgroundLink3, this.bgBackgroundColor);
    } else if (this.viewMode === 'desktop-offline') {
      this.setViewModeReturn(undefined, 'desktop-4');
      this.bgUpdate(this.bgBackgroundLink4, this.bgBackgroundColor);
    } else if (this.viewMode === 'desktop-hls') {
      this.setViewModeReturn(undefined, 'desktop-4');
      this.bgUpdate(this.bgBackgroundLink4, this.bgBackgroundColor);
      // } else if (this.viewMode === 'mobile') {
      //   this.setViewModeReturn(undefined, 'mobile-4');
      //   this.bgUpdate(this.bgBackgroundLink4, this.bgBackgroundColor);
      // } else if (this.viewMode === 'mobile-2') {
      //   this.setViewModeReturn(undefined, 'mobile');
      //   this.bgUpdate(this.bgBackgroundLink1, this.bgBackgroundColor);
      // } else if (this.viewMode === 'mobile-3') {
      //   this.setViewModeReturn(undefined, 'mobile-2');
      //   this.bgUpdate(this.bgBackgroundLink2, this.bgBackgroundColor);
      // } else if (this.viewMode === 'mobile-4') {
      //   this.setViewModeReturn(undefined, 'mobile-3');
      //   this.bgUpdate(this.bgBackgroundLink3, this.bgBackgroundColor);
      // } else if (this.viewMode === 'mobile-offline') {
      //   this.setViewModeReturn(undefined, 'mobile-4');
      //   this.bgUpdate(this.bgBackgroundLink4, this.bgBackgroundColor);
      // } else if (this.viewMode === 'mobile-hls') {
      //   this.setViewModeReturn(undefined, 'mobile-4');
      //   this.bgUpdate(this.bgBackgroundLink4, this.bgBackgroundColor);
    }
    this.cleanSettings();
  }
  goRight() {
    if (this.viewMode === 'desktop') {
      this.setViewModeReturn(undefined, 'desktop-2');
      this.bgUpdate(this.bgBackgroundLink2, this.bgBackgroundColor);
    } else if (this.viewMode === 'desktop-2') {
      this.setViewModeReturn(undefined, 'desktop-3');
      this.bgUpdate(this.bgBackgroundLink3, this.bgBackgroundColor);
    } else if (this.viewMode === 'desktop-3') {
      this.setViewModeReturn(undefined, 'desktop-4');
      this.bgUpdate(this.bgBackgroundLink4, this.bgBackgroundColor);
    } else if (this.viewMode === 'desktop-4') {
      this.setViewModeReturn(undefined, 'desktop');
      this.bgUpdate(this.bgBackgroundLink1, this.bgBackgroundColor);
    } else if (this.viewMode === 'desktop-offline') {
      this.setViewModeReturn(undefined, 'desktop-2');
      this.bgUpdate(this.bgBackgroundLink2, this.bgBackgroundColor);
    } else if (this.viewMode === 'desktop-hls') {
      this.setViewModeReturn(undefined, 'desktop-2');
      this.bgUpdate(this.bgBackgroundLink2, this.bgBackgroundColor);
      // } else if (this.viewMode === 'mobile') {
      //   this.setViewModeReturn(undefined, 'mobile-2');
      //   this.bgUpdate(this.bgBackgroundLink2, this.bgBackgroundColor);
      // } else if (this.viewMode === 'mobile-2') {
      //   this.setViewModeReturn(undefined, 'mobile-3');
      //   this.bgUpdate(this.bgBackgroundLink3, this.bgBackgroundColor);
      // } else if (this.viewMode === 'mobile-3') {
      //   this.setViewModeReturn(undefined, 'mobile-4');
      //   this.bgUpdate(this.bgBackgroundLink4, this.bgBackgroundColor);
      // } else if (this.viewMode === 'mobile-4') {
      //   this.setViewModeReturn(undefined, 'mobile');
      //   this.bgUpdate(this.bgBackgroundLink1, this.bgBackgroundColor);
      // } else if (this.viewMode === 'mobile-offline') {
      //   this.setViewModeReturn(undefined, 'mobile-2');
      //   this.bgUpdate(this.bgBackgroundLink2, this.bgBackgroundColor);
      // } else if (this.viewMode === 'mobile-hls') {
      //   this.setViewModeReturn(undefined, 'mobile-2');
      //   this.bgUpdate(this.bgBackgroundLink2, this.bgBackgroundColor);
    }
    this.cleanSettings();
  }
  refresh() {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    this.data.data['settings'] = JSON.parse(JSON.stringify(currentSettings));
    this.cleanSettings();
  }
  public syncSettings(currentSettings) {
    for (let col of currentSettings['columns']) {
      col['components'] = col['components'].map((cmp) => {
        if (!cmp.hasOwnProperty('uid') || !cmp['uid']) {
          cmp['uid'] = guid();
        }
        if (!cmp.hasOwnProperty('metadata') && cmp.name === 'presenter-camera') {
          cmp['metadata'] = { id: '', name: '' };
        }
        if (!cmp.hasOwnProperty('camerasPerRow') && cmp.name === 'panelView') {
          cmp['camerasPerRow'] = 2;
        }
        if (cmp.name === 'cloudlive') {
          if (!cmp['type']) {
            cmp['type'] = 'meeting';
          }
        }
        if ((!cmp.hasOwnProperty('groups') || (cmp.hasOwnProperty('groups') && cmp.groups.length === 0)) && cmp.name === 'panelView') {
          cmp['groups'] = [
            { uid: guid(), cameras: [] }
          ];
        }
        if ((!cmp.hasOwnProperty('groups') || (cmp.hasOwnProperty('groups') && cmp.groups.length === 0)) && cmp.name === 'audienceView') {
          cmp['groups'] = [
            { uid: guid(), cameras: [] }
          ];
        }
        return cmp;
      });
    }
    // console.log('currentSettings', currentSettings);
    return currentSettings;
  }
  public syncDefaultSettings() {
    if (!this.data.data['settings'].hasOwnProperty('desktop-2')) {
      this.data.data['settings']['desktop-2'] = JSON.parse(JSON.stringify(this.defaultSetting['desktop-2']));
    }
    if (!this.data.data['settings'].hasOwnProperty('desktop-3')) {
      this.data.data['settings']['desktop-3'] = JSON.parse(JSON.stringify(this.defaultSetting['desktop-3']));
    }
    if (!this.data.data['settings'].hasOwnProperty('desktop-4')) {
      this.data.data['settings']['desktop-4'] = JSON.parse(JSON.stringify(this.defaultSetting['desktop-4']));
    }
  }
  public cleanSettings(changeViewMode = true) {
    this.syncDefaultSettings();
    let currentSettings = JSON.parse(JSON.stringify(this.data.data['settings']));
    this.settingObject = JSON.parse(JSON.stringify(currentSettings));
    if (currentSettings.hasOwnProperty('version') && currentSettings.version === this.defaultSetting.version) {
      this.cleanTypeSettings('desktop');
      this.cleanTypeSettings('desktop-2');
      this.cleanTypeSettings('desktop-3');
      this.cleanTypeSettings('desktop-4');
      this.cleanTypeSettings('desktop-offline');
      this.cleanHLSSettings('desktop-hls');
      this.cleanStreamMode(changeViewMode);
    } else {
      this.loadDefaultSettings();
      // this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + ' Version not compatible, reset to default settings', this.translate.instant('Dismiss'));
    }
    // console.log('settingObject', this.settingObject);
    // console.log('componentsListType', this.componentsListType);
  }
  public cleanTypeSettings(type) {
    let currentSettings = JSON.parse(JSON.stringify(this.data.data['settings'][type]));
    currentSettings = this.syncSettings(currentSettings); // this is to make sure data are synced with new changes
    let componentsList = []; // the list to show on left add comonent buttun
    let selectedComponents = [];
    let selectedActiveComponents = [];
    this.columnsLengthType[type] = currentSettings['columns'].length;
    for (let col of currentSettings['columns']) {
      if (this.data.data['streamMode'] === 'cloudlive') {
        if (type === 'desktop') {
          let cloudliveComponent = col['components'].filter((cmp) => cmp.name == 'cloudlive');
          if (cloudliveComponent.length == 0) {
            let cmp = this.componentsName['cloudlive']['content'];
            cmp['uid'] = guid();
            col['components'].push(cmp);
          }
        }
        col['components'] = col['components'].filter((cmp) => this.cloudliveComponentsList.includes(cmp.name));
      } else if (this.data.data['streamMode'] === 'hls') {
        col['components'] = col['components'].filter((cmp) => this.hlsComponentsList.includes(cmp.name));
      } else {
        col['components'] = col['components'].filter((cmp) => this.componentsType[type].includes(cmp.name));
      }
      if (this.data.data['streamMode'] === 'rtctohls' || this.data.data['streamMode'] === 'hls') {
        col['components'] = col['components'].filter((cmp) => cmp.name !== 'camera');
      }
      for (let cmp of col['components']) {
        selectedComponents.push(cmp.name);
        if (cmp.active) {
          selectedActiveComponents.push(cmp.name);
        }
      }
    }
    let componentsNameKeys = this.componentsNameKeys;
    if (this.data.data['streamMode'] === 'rtctohls' || this.data.data['streamMode'] === 'hls') {
      componentsNameKeys = componentsNameKeys.filter((cmp) => cmp !== 'camera');
    }
    if (this.data.data['streamMode'] === 'hls') {
      componentsNameKeys = componentsNameKeys.filter((cmp) => this.hlsComponentsList.includes(cmp));
    } else if (this.data.data['streamMode'] === 'cloudlive') {
      componentsNameKeys = componentsNameKeys.filter((cmp) => this.cloudliveComponentsList.includes(cmp));
    }

    for (let key of componentsNameKeys) {
      if (this.componentsType[type].includes(key)) {
        if (selectedComponents.indexOf(key) === -1 || this.componentsName[key]['fixed']) {
          componentsList.push(JSON.parse(JSON.stringify(this.componentsName[key]['content'])));
        }
      }
    }
    if (type === 'desktop') {
      this.optionDesktopSettingsStatus = JSON.parse(JSON.stringify(this.optionDesktopSettingsStatusDefault));
      if (this.data.data.streamMode === 'largemeeting' || this.data.data.streamMode === 'meeting') {
        this.optionDesktopSettingsStatus['enableRaiseHand'] = false;
        currentSettings['optionSettings']['enableRaiseHand'] = false;
        this.optionDesktopSettingsStatus['enableScreenShareButton'] = true;
        this.optionDesktopSettingsStatus['hasTwoPipCamera'] = false;
        this.optionDesktopSettingsStatus['enableExpressions'] = false;
        // this.optionDesktopSettingsStatus['hasScreenShare'] = false;
        this.optionDesktopSettingsStatus['enableBreakout'] = false;
        currentSettings['optionSettings']['enableBreakout'] = false;
      } else if (this.data.data.streamMode === 'cloudlive') {
        this.optionDesktopSettingsStatus['enableAskQuestions'] = true;

        this.optionDesktopSettingsStatus['enableScreenShareButton'] = false;
        currentSettings['optionSettings']['enableScreenShareButton'] = false;
        this.optionDesktopSettingsStatus['enableFullScreen'] = false;
        currentSettings['optionSettings']['enableFullScreen'] = false;
        this.optionDesktopSettingsStatus['enableCaptureScreen'] = false;
        currentSettings['optionSettings']['enableCaptureScreen'] = false;
        this.optionDesktopSettingsStatus['enableRaiseHand'] = false;
        currentSettings['optionSettings']['enableRaiseHand'] = false;
        this.optionDesktopSettingsStatus['enableChat'] = false;
        currentSettings['optionSettings']['enableChat'] = false;
        this.optionDesktopSettingsStatus['enableBreakout'] = false;
        currentSettings['optionSettings']['enableBreakout'] = false;
        this.optionDesktopSettingsStatus['enableExpressions'] = false;
        currentSettings['optionSettings']['enableExpressions'] = false;
      } else {
        if (this.data.data.streamMode === 'hls') {
          currentSettings['optionSettings']['enableScreenShareButton'] = false;
          currentSettings['optionSettings']['enableFullScreen'] = false;
          currentSettings['optionSettings']['enableCaptureScreen'] = false;
          currentSettings['optionSettings']['enableRaiseHand'] = false;
          currentSettings['optionSettings']['enableChat'] = false;
          currentSettings['optionSettings']['enableAskQuestions'] = false;
          currentSettings['optionSettings']['enableBreakout'] = false;
        } else {
          if (this.data.data['attendance'] === "in-person") {
            this.optionDesktopSettingsStatus['enableBreakout'] = false;
            currentSettings['optionSettings']['enableBreakout'] = false;
          } else { // online , hybrid
            this.optionDesktopSettingsStatus['enableBreakout'] = true;
          }
        }
        // console.log('selectedActiveComponents', selectedActiveComponents);
        if (selectedActiveComponents.indexOf('camera') === -1) {
          this.optionDesktopSettingsStatus['enableRaiseHand'] = false;
          currentSettings['optionSettings']['enableRaiseHand'] = false;
        } else {
          this.optionDesktopSettingsStatus['enableRaiseHand'] = true;
        }
        if (selectedActiveComponents.indexOf('camera') === -1 || selectedActiveComponents.indexOf('screenshare') === -1) {
          this.optionDesktopSettingsStatus['enableScreenShareButton'] = false;
          currentSettings['optionSettings']['enableScreenShareButton'] = false;
        } else {
          this.optionDesktopSettingsStatus['enableScreenShareButton'] = true;
        }

        let secondaryCameraList = selectedActiveComponents.filter((cmp) => cmp === 'guest-camera');
        let pipcameraComponentsList = selectedActiveComponents.filter((cmp) => this.pipcameraComponents.includes(cmp));
        if (pipcameraComponentsList.length === 2 && secondaryCameraList.length < 2) {
          this.optionDesktopSettingsStatus['hasTwoPipCamera'] = true;
        } else {
          this.optionDesktopSettingsStatus['hasTwoPipCamera'] = false;
        }


        // if (selectedActiveComponents.indexOf('camera') > -1 && selectedActiveComponents.indexOf('audienceView') > -1) {
        //   for (let col of currentSettings['columns']) {
        //     for (let cmp of col['components']) {
        //       if (cmp.name === 'camera') {
        //         cmp.enableAllowAllSpeak = false;
        //       }
        //     }
        //   }
        // }

      }
      if (this.data.data['sessionsource'] === "event") {
        this.optionDesktopSettingsStatus['enableChat'] = false;
        currentSettings['optionSettings']['enableChat'] = false;
      }
    }
    // console.log('componentsList', type, componentsList);
    this.componentsListType[type] = JSON.parse(JSON.stringify(componentsList));
    this.settingObject[type] = JSON.parse(JSON.stringify(currentSettings));
  }
  public cleanHLSSettings(type) {
    if (!this.data.data['settings'].hasOwnProperty(type) || (this.data.data['settings'].hasOwnProperty(type) && !this.data.data['settings'][type].hasOwnProperty('columns'))) {
      this.data.data['settings'][type] = JSON.parse(JSON.stringify(this.defaultSetting[type]));
    }
    let currentSettings = JSON.parse(JSON.stringify(this.data.data['settings'][type]));
    let componentsList = [];
    let selectedComponents = [];
    let selectedActiveComponents = [];
    this.columnsLengthType[type] = currentSettings['columns'].length;
    for (let col of currentSettings['columns']) {
      col['components'] = col['components'].filter((cmp) => this.componentsType[type].includes(cmp.name));
      for (let cmp of col['components']) {
        selectedComponents.push(cmp.name);
        if (cmp.active) {
          selectedActiveComponents.push(cmp.name);
        }
      }
    }
    let componentsNameKeys = this.componentsNameKeys;
    for (let key of componentsNameKeys) {
      if (this.componentsType[type].includes(key)) {
        if (selectedComponents.indexOf(key) === -1 || this.componentsName[key]['fixed']) {
          componentsList.push(JSON.parse(JSON.stringify(this.componentsName[key]['content'])));
        }
      }
    }
    if (type === 'desktop-hls') { // this below effect the layout when addig or enabling screenshare component in desktop
      if (!this.optionDesktopSettingsStatus.hasTwoPipCamera && currentSettings['columns'][0]['components'][0] && currentSettings['columns'][0]['components'][0]['layout'] === 'pip')
        currentSettings['columns'][0]['components'][0]['layout'] = 'bestFit';
    }
    this.componentsListType[type] = JSON.parse(JSON.stringify(componentsList));
    this.settingObject[type] = JSON.parse(JSON.stringify(currentSettings));
  }
  public setAttribute(id, val) {
    this.data.data[id] = val;
  }
  public setAttributeBoolean(id, val) {
    this.data.data[id] = JSON.parse(val);
  }
  public setReferenceAttribute(parentId, id, val) {
    this.data.data[parentId][id] = val;
  }
  public setMultipleReferenceAttribute(id, val) {
    this.data.data[id] = val;
  }
  public setRoleReferenceAttribute(parentId, val) {
    this.data.data[parentId] = { _id: val._id, name: val.name };
  }
  public setDateAttribute(id, val) {
    // console.log('setDateAttribute', id, val.toISOString());
    try {
      this.data.data[id] = val.toISOString();
    } catch (e) {
      // error
    }
  }
  private getCleanObject(data) {
    let newObj = { _id: data._id };
    for (let col of this.data.modalSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== 'action') {
        if (col.dataType === 'password') {
          newObj[col.name] = urlSafeBase64Encoding(data[col.name]);
        } else if (col.type === 'reference') {
          if (col.reference.kind === 'multiple') {
            if (data[col.name] && data[col.name].length > 0)
              newObj[col.name] = data[col.name];
          } else {
            if (data[col.name] !== '')
              newObj[col.name] = data[col.name];
          }
        } else {
          newObj[col.name] = data[col.name];
        }
        // if (this.data.modalSetting.hasOwnProperty('customSettings')) {
        //   if (this.data.modalSetting.customSettings.hasOwnProperty(col.name)) {
        //     newObj[col.name] = this.data.modalSetting.customSettings[col.name].value;
        //   }
        // }
      }
    }
    return newObj;
  }
  private validateObject(data) {
    for (let col of this.data.modalSetting.fields) {
      if ((!col.nullable && !col.generated) && col.type !== 'action' && col.visible) {
        if (col.type === 'reference') {
          if (col.reference.kind === 'multiple') {
            // console.log('col.name', col.name, data[col.name] );
            if (data && data[col.name] && data[col.name].length === 0) {
              return false;
            }
          } else {
            if (data && data[col.name] && data[col.name] === '') {
              // console.log('col.name', col.name, data[col.name] );
              return false;
            }
          }
        } else if (col.type === 'tags') {
          if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        } else {
          if (data && (data[col.name] === '' || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        }
      }
    }
    return true;
  }
  public saveData(type) {
    // console.log('settingObject', this.settingObject);
    // console.log('this.data.modalSetting', this.data.modalSetting);
    // console.log('getCleanObject', this.getCleanObject(this.data.data));
    if (!this.loading) {
      if (this.data.data['_id']) {
        if (type === 'edit-sessions') {
          this.saveAllSessionsRoom();
        } else {
          this.handleGlobalSave((callbackStatus) => {
            // do nothing
          });
        }
      } else {
        this.handleGlobalSave((callbackStatus) => {
          // do nothing
        });
      }
    }
  }
  private getIsSessionliveOrActive(session): boolean {
    if (session['_id']) {
      if (session.active) {
        return true;
      }
    }
    return false;
  }
  private getIsliveOrActiveOrOffline(): boolean {
    if (this.data.data['_id']) {
      if (this.data.data.active || (!this.data.data.active && this.hasOfflineNewChanges)) {
        return true;
      }
    }
    return false;
  }
  private handleGlobalRoomSave(callback: (dataResponse: boolean) => void) {
    let mainMsg = 'This will save the layout and refresh the pages of everyone in the session. Also, some of the changes that you made will be applied to all sessions under this room. Are you sure you want to Save?';
    // let smallMsg = 'P.S: Room Changes are applied to all sessions under the same room.';
    let confirmBtn = 'Proceed with Save and Refresh';
    let declineBtn = 'Discard Changes';
    this.handleGlobalSaveModal(mainMsg, confirmBtn, declineBtn, (callbackStatus) => {
      callback(callbackStatus);
    });
  }
  private handleGlobalSave(callback: (dataResponse: boolean) => void) {
    if (this.hasNewRoomChanges) {
      let role = this.requestService.getUserRole();
      if (role === 'edit') {
        this.getLiveSessions(this.data.roomData, (sessions) => {
          if (sessions.length > 0) {
            this.layoutUtilsService.showNotification('You cannot make room level changes while there are active sessions in the same room', this.translate.instant('Dismiss'));
            callback(false);
          } else {
            this.handleGlobalRoomSave((callbackStatus) => {
              callback(callbackStatus);
            });
          }
        });
      } else {
        this.handleGlobalRoomSave((callbackStatus) => {
          callback(callbackStatus);
        });
      }
    } else if (!this.hasNewRoomChanges && (this.hasNewChanges || this.hasOfflineNewChanges) && this.getIsliveOrActiveOrOffline()) {
      let mainMsg = 'This will save the layout and refresh the pages of everyone in the session. Are you sure you want to proceed?';
      let confirmBtn = 'Proceed with Save and Refresh';
      let declineBtn = 'Discard Changes';
      this.handleGlobalSaveModal(mainMsg, confirmBtn, declineBtn, (callbackStatus) => {
        callback(callbackStatus);
      });
    } else {
      this.handleGlobalSaveApi((callbackStatus) => {
        callback(callbackStatus);
      });
    }
  }
  private handleGlobalSaveModal(mainMsg: string, confirmBtn: string, declineBtn: string, callback: (dataResponse: boolean) => void, smallMsg: string = undefined) {
    let alertSetting = {};
    alertSetting['overlayClickToClose'] = false;
    alertSetting['showCloseButton'] = true;
    alertSetting['smallMsg'] = smallMsg;
    alertSetting['confirmText'] = confirmBtn;
    alertSetting['declineText'] = declineBtn;

    const dialogRef = this.layoutUtilsService.alertActionElement(this.translate.instant('Save Changes'), this.translate.instant(mainMsg), alertSetting);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.action === 'confirmText') {
          this.handleGlobalSaveApi((callbackStatus) => {
            callback(callbackStatus);
          });
        } else if (result.action === 'declineText') {
          // callback(true);
          window.location.reload();
        }
      } else {
        callback(false);
      }
    });
  }
  private handleGlobalSaveApi(callback: (dataResponse: boolean) => void) {
    // this.hasNewChanges = true;
    // console.log('settingRoomObject', this.settingRoomObject);
    // console.log('settingObject', this.settingObject);
    // callback(true);
    // return;
    if (this.hasNewRoomChanges) {
      this.updateRoom((roomdata) => {
        if (this.data.data['_id'] && (this.hasNewChanges || this.hasOfflineNewChanges)) {
          // if (this.data.data['_id']) {
          this.editApplySession((callbackStatus) => {
            callback(callbackStatus);
          })
        } else {
          this.hasNewRoomChanges = false;
          this.getSessions(this.data.roomData, () => {
            for (let sessionItem of this.sessionList) {
              if (this.getIsSessionliveOrActive(sessionItem)) {
                this.ablyService.sendSessionSignal(sessionItem._id, 'forceRefresh', 'true');
              }
            }
            this.layoutUtilsService.showNotification('Settings ' + this.translate.instant('Updated Successfully'), this.translate.instant('Dismiss'));
            if (this.sessionList.length === 0 && !this.data.roomData.emptyRoom) {
              this.handleGlobalRoomSaveModal((callbackStatus) => {
                if (callbackStatus) {
                  // open session creation
                  this.createSession();
                  callback(false);
                } else {
                  callback(true);
                }
              });
            } else {
              callback(true);
            }
          });
        }
      });
    } else {
      if (this.data.data['_id'] && (this.hasNewChanges || this.hasOfflineNewChanges)) {
        // if i remove this the save will always work even if there are no changes
        // if (this.data.data['_id']) {
        this.editApplySession((callbackStatus) => {
          callback(callbackStatus);
        })
      } else {
        callback(true);
      }
    }
  }
  private handleGlobalRoomSaveModal(callback: (dataResponse: boolean) => void) {
    let alertSetting = {};
    alertSetting['overlayClickToClose'] = false;
    alertSetting['showCloseButton'] = false;
    alertSetting['confirmText'] = 'Now';
    alertSetting['declineText'] = 'Later';

    const dialogRef = this.layoutUtilsService.alertActionElement(this.translate.instant('Create Session'), this.translate.instant('Do you want to create a session now or later?'), alertSetting);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.action === 'confirmText') {
          callback(true);
        } else if (result.action === 'declineText') {
          callback(false);
        }
      } else {
        callback(false);
      }
    });

  }
  private setCamerasGroup(viewMode) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    if (currentSettings.hasOwnProperty(viewMode) && currentSettings[viewMode].hasOwnProperty('columns')) {
      // if(currentSettings[viewMode].hasOwnProperty('groups')){
      //   delete currentSettings[viewMode]['groups'];
      // }
      currentSettings[viewMode]['groups'] = [];
      if (currentSettings[viewMode].hasOwnProperty('columns') && currentSettings[viewMode]['columns'].length > 0) {
        let cameras = currentSettings[viewMode]['columns'][0]['components'].filter((cmp) => this.camerasCountComponents.includes(cmp.name) && cmp.active);
        if (cameras.length > 0) {
          let groupLength = 1;
          if (cameras.length > 0 && cameras.length < 3) {
            groupLength = 1;
          } else if (cameras.length >= 3 && cameras.length < 5) {
            groupLength = 2;
          } else if (cameras.length >= 5 && cameras.length < 7) {
            groupLength = 3;
          } else if (cameras.length >= 7 && cameras.length < 9) {
            groupLength = 4;
          } else if (cameras.length >= 9) {
            groupLength = 5;
          }

          let eachGroupLength = Math.ceil(cameras.length / groupLength);
          for (let g = 1; g <= groupLength; g++) {
            if (cameras.length < eachGroupLength) {
              eachGroupLength = cameras.length;
            }
            let groupCameras = [];
            let cams = cameras.splice(0, eachGroupLength);
            for (let cam of cams) {
              groupCameras.push(cam.uid);
            }
            currentSettings[viewMode]['groups'].push({ uid: guid(), cameras: groupCameras });
            if (cameras.length > 0 && g !== groupLength) {
              eachGroupLength = Math.ceil(cameras.length / (groupLength - g));
            }
          }
        }
        // console.log('groups', currentSettings[viewMode]['groups']);
      }
    }
    this.settingObject = JSON.parse(JSON.stringify(currentSettings));
  }
  private getSessionGroups(viewMode) {
    // type host is the session
    let userIds = [
      {
        userId: this.data.data._id,
        type: 'host'
      }
    ];
    // type host is the producer host if available
    if (this.data.data.users) {
      let producers = this.data.data.users.filter((user) => user.type === 'moderator' && user.host);
      if (producers.length > 0) {
        userIds = [
          {
            userId: producers[0]._id,
            type: 'host'
          }
        ];
      }
    }
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject));
    if (currentSettings.hasOwnProperty(viewMode)) {
      if (currentSettings[viewMode].hasOwnProperty('groups')) {
        for (let grp of currentSettings[viewMode]['groups']) {
          userIds.push(
            {
              userId: grp.uid,
              type: 'main'
            }
          );
        }
      }
      if (currentSettings[viewMode].hasOwnProperty('columns') && currentSettings[viewMode]['columns'].length > 0) {
        if (currentSettings[viewMode]['columns'][0].hasOwnProperty('components')) {
          let panels = currentSettings[viewMode]['columns'][0]['components'].filter((cmp) => ['panelView'].includes(cmp.name));
          if (panels.length > 0) {
            let panel = panels[0];
            if (panel.groups) {
              for (let grp of panel.groups) {
                userIds.push(
                  {
                    userId: grp.uid,
                    type: 'panel'
                  }
                );
              }
            }
          }
          let audienceViews = currentSettings[viewMode]['columns'][0]['components'].filter((cmp) => ['audienceView'].includes(cmp.name));
          if (audienceViews.length > 0) {
            let audienceView = audienceViews[0];
            if (audienceView.groups) {
              for (let grp of audienceView.groups) {
                userIds.push(
                  {
                    userId: grp.uid,
                    type: 'spotlight'
                  }
                );
              }
            }
          }
        }
      }
    }
    return userIds;
  }
  private createTokBoxSessions(groupIds, callback: (success: boolean) => any) {
    // console.log('createTokBoxSessions', groupIds);
    // if(false){ // comment this
    this.requestService.createMultipleSessions(groupIds, this.data.data._id, (data, error) => {
      if (data && data.status) {
        callback(true);
      } else {
        callback(false);
      }
    });
    // }else{
    //   callback(false);
    // }
  }
  hasMedia(viewMode) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject[viewMode]));
    for (let col of currentSettings['columns']) {
      let componentsList = col['components'].filter((cmp) => this.restrictedComponents.includes(cmp.name) && cmp.active);
      if (componentsList.length > 0) {
        return true;
      }
    }
    return false;
  }
  private editApplySession(callback: (dataResponse: boolean) => void) {
    this.setCamerasGroup('desktop'); // set up the groups of the cameras
    this.data.data['settings'] = JSON.parse(JSON.stringify(this.settingObject)); // adding the selected form
    this.data.data['scheduler'] = this.cleanTaskHub(this.data.data['scheduler'], this.data.data['settings'], this.data.data.users, this.data.data.tiles);
    this.validateLayoutByApi((callbackStatus) => {
      if (callbackStatus) {

        if (!this.validateLayout()) {
          callback(false);
          return;
        }

        this.loading = true;
        this.loaderService.display(true);

        if (this.data.data['streamMode'] === 'hls') {
          this.data.data['isKeepActive'] = false;
        }

        let hasMedia = false;
        if (this.data.data['streamMode'] === 'hls' || this.data.data['streamMode'] === 'meeting') {
          hasMedia = true;
        } else {
          hasMedia = this.hasMedia('desktop');
        }

        let dataToSave = { _id: this.data.data['_id'], name: this.data.data['name'], settings: this.data.data['settings'], maximumAttendees: this.data.data['maximumAttendees'], maximumCameras: this.data.data['maximumCameras'], streamMode: this.data.data['streamMode'], chainedtosession: this.data.data['chainedtosession'], autoend: this.data.data['autoend'], isKeepActive: this.data.data['isKeepActive'], configurations: this.data.data['configurations'], hasMedia: hasMedia, scheduler: this.data.data['scheduler'] };
        this.errorMessage = '';
        this.requestService.saveData(this.data.dataType, dataToSave, (data, error) => {
          if (error) {
            this.errorMessage = error;
            this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
            callback(false);
          }
          if (data) {
            this.activeModeReturn.emit(data.results.active);
            let groupList = this.getSessionGroups('desktop');
            if (groupList.length > 0) {
              this.createTokBoxSessions(groupList, (status) => {
                this.handleSessionAfterSave(data, (callbackStatus) => {
                  callback(callbackStatus);
                });
              });
              return;
            } else {
              this.handleSessionAfterSave(data, (callbackStatus) => {
                callback(callbackStatus);
              });
              return;
            }
          }
          this.loading = false;
          callback(false);
        });
      } else {
        callback(false);
      }
    })


  }
  private cleanTaskHub(scheduler, settings, users = [], tiles = [], viewMode = 'desktop') {
    // working on this you need to work on the part on schedular too
    let schedulerTasks = [];
    if (scheduler.hasOwnProperty('tasks')) {
      schedulerTasks = scheduler.tasks;
      let tileList = tiles || [];
      let guestsList = users.filter((user) => user.type === 'guest');
      let leadList = users.filter((user) => user.type === 'lead');
      let producersList = users.filter((user) => user.type === 'moderator');
      let currentSettings = JSON.parse(JSON.stringify(settings));
      if (currentSettings.hasOwnProperty(viewMode)) {
        let settingObject = JSON.parse(JSON.stringify(currentSettings[viewMode]));
        let activeSharescreens = [];

        let guestCamerasList = [];
        let guestCamerasListIds = [];
        let leadCamerasList = [];
        let leadCamerasId = undefined;
        let editorsList = [];
        let editorsListIds = [];
        let iframeList = [];
        let iframeListIds = [];
        let picturesList = [];
        let picturesListIds = [];
        let defaultvideoList = [];
        let defaultvideoListIds = [];
        let panelList = [];
        let panelGroups = [];
        let panelId = undefined;
        let layoutHasShareComponent = false;
        let layoutHasBreakoutChecked = false;

        for (let col of settingObject['columns']) {
          guestCamerasList = col['components'].filter((comp) => comp.name === 'guest-camera' && comp.active);
          guestCamerasListIds = guestCamerasList.map((comp) => comp['uid']);
          leadCamerasList = col['components'].filter((comp) => comp.name === 'presenter-camera' && comp.active);
          if (leadCamerasList.length > 0) {
            leadCamerasId = leadCamerasList[0]['uid'];
          }
          editorsList = col['components'].filter((comp) => comp.name === 'editor' && comp.active);
          editorsListIds = editorsList.map((comp) => comp['uid']);
          iframeList = col['components'].filter((comp) => comp.name === 'iframe' && comp.active);
          iframeListIds = iframeList.map((comp) => comp['uid']);
          picturesList = col['components'].filter((comp) => comp.name === 'picture' && comp.active);
          picturesListIds = picturesList.map((comp) => comp['uid']);
          defaultvideoList = col['components'].filter((comp) => comp.name === 'defaultvideo' && comp.active);
          defaultvideoListIds = defaultvideoList.map((comp) => comp['uid']);
          panelList = col['components'].filter((comp) => comp.name === 'panelView' && comp.active);
          if (panelList.length > 0) {
            panelId = panelList[0]['uid'];
            if (panelList[0]['groups'] && panelList[0]['groups'].length > 0) {
              panelGroups = panelList[0]['groups'];
            }
          }
          // this.panelListIds = this.panelList.map((comp) => comp['uid']);
          activeSharescreens = col['components'].filter((comp) => comp.name === 'screenshare' && comp.active);
        }
        if (activeSharescreens.length > 0) {
          layoutHasShareComponent = true;
        }
        if (settingObject.hasOwnProperty('optionSettings') && settingObject.optionSettings['enableBreakout']) {
          layoutHasBreakoutChecked = true;
        }

        schedulerTasks = schedulerTasks.filter((tsk) => {
          if (tsk.name === 'start-lead-camera' || tsk.name === 'start-guest-camera' || tsk.name === 'push-tile' || tsk.name === 'share-screen' || tsk.name === 'breakout' || tsk.name === 'update-editor' || tsk.name === 'update-picture' || tsk.name === 'update-iframe' || tsk.name === 'update-panel' || tsk.name === 'update-defaultvideo') {
            if ((tsk.name === 'start-lead-camera' && leadCamerasList.length > 0 && leadCamerasId === tsk.uid && (leadList.length > 0 || guestsList.length > 0)) ||
              (tsk.name === 'start-guest-camera' && guestCamerasList.length > 0 && (leadList.length > 0 || guestsList.length > 0)) ||
              (tsk.name === 'update-editor' && editorsList.length > 0 && this.data.data['streamMode'] !== 'hls') ||
              (tsk.name === 'update-picture' && picturesList.length > 0 && this.data.data['streamMode'] !== 'hls') ||
              (tsk.name === 'update-iframe' && iframeList.length > 0 && this.data.data['streamMode'] !== 'hls') ||
              (tsk.name === 'update-defaultvideo' && defaultvideoList.length > 0 && this.data.data['streamMode'] !== 'hls') ||
              (tsk.name === 'update-panel' && panelGroups.length > 0 && panelList.length > 0 && this.data.data['streamMode'] !== 'hls') ||
              (tsk.name === 'share-screen' && layoutHasShareComponent) ||
              (tsk.name === 'breakout' && layoutHasBreakoutChecked && this.data.data['streamMode'] !== 'hls') ||
              (tsk.name === 'push-tile' && tileList.length > 0)
            ) {
              return true;
            }
          } else if (tsk === 'start-session' || tsk === 'end-session' || tsk === 'share-video' || tsk === 'rtmp-pull' || tsk === 'generate-rtmp') {
            // this so not ruin old data 
            return false;
          } else {
            return true;
          }
          return false;
        });
        schedulerTasks = schedulerTasks.map((tsk) => {
          if (tsk.name === 'start-lead-camera' || tsk.name === 'start-guest-camera') {
            let userList = leadList.concat(guestsList);
            if (!this.containsItem(tsk.metadata.id, userList)) {
              tsk.action = '';
              tsk.metadata = {
                id: '',
                name: ''
              };
            }
          }
          return tsk;
        });
      }
    }
    scheduler['tasks'] = schedulerTasks;
    return scheduler;
  }
  private containsItem(id, arrList) {
    for (let itm of arrList) {
      if (itm._id === id) {
        return true;
      }
    }
    return false;
  }
  private handleSessionAfterSave(data, callback: (dataResponse: boolean) => void) {
    this.data.data.attendance = data.attendance;
    if (this.hasNewRoomChanges) {
      this.getSessions(this.data.roomData, () => {
        for (let sessionItem of this.sessionList) {
          if (sessionItem._id !== data.results._id && this.getIsSessionliveOrActive(sessionItem)) {
            this.ablyService.sendSessionSignal(sessionItem._id, 'forceRefresh', 'true');
          } else if (sessionItem._id === data.results._id && this.getIsliveOrActiveOrOffline() && (this.hasNewChanges || this.hasOfflineNewChanges)) {
            this.ablyService.sendSessionSignal(sessionItem._id, 'forceRefresh', 'true');
          }
        }
        this.layoutUtilsService.showNotification(this.data.dataTypeTitle + ' ' + this.translate.instant('Updated Successfully'), this.translate.instant('Dismiss'));
        this.loading = false;
        this.hasNewChanges = false;
        this.hasOfflineNewChanges = false;
        this.hasNewRoomChanges = false;
        this.loaderService.display(false);
        callback(true);
      });
    } else {
      if (this.getIsliveOrActiveOrOffline() && (this.hasNewChanges || this.hasOfflineNewChanges)) {
        this.ablyService.sendSessionSignal(data.results._id, 'forceRefresh', 'true');
      }
      this.layoutUtilsService.showNotification(this.data.dataTypeTitle + ' ' + this.translate.instant('Updated Successfully'), this.translate.instant('Dismiss'));
      this.getSessions(this.data.roomData);
      this.loading = false;
      this.hasNewChanges = false;
      this.hasOfflineNewChanges = false;
      this.loaderService.display(false);
      callback(true);
    }
  }
  private saveAllSessionsRoom() {
    const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
      disableClose: true,
      data: {
        title: this.translate.instant('Save for all sessions in this room'),
        data: '',
        description: this.translate.instant('This will save the setting on all sessions under the current room and refresh the pages of everyone in the sessions. Are you sure you want to proceed?'),
        cancelbtn: this.translate.instant('Cancel'),
        confirmbtn: this.translate.instant('Proceed'),
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.updateRoom((roomdata) => {
          this.hasNewRoomChanges = false;

          this.setCamerasGroup('desktop'); // set up the groups of the cameras
          this.data.data['settings'] = JSON.parse(JSON.stringify(this.settingObject)); // adding the selected form
          let settings = JSON.parse(JSON.stringify(this.settingObject)); // adding the selected form
          let settingsToSave = this.getCleanSettings(settings);
          if (!this.validateLayout()) {
            return;
          }
          this.loading = true;
          this.loaderService.display(true);
          let dataToSave = { settings: settingsToSave, maximumAttendees: this.data.data['maximumAttendees'], maximumCameras: this.data.data['maximumCameras'], streamMode: this.data.data['streamMode'], chainedtosession: this.data.data['chainedtosession'] };
          this.errorMessage = '';
          this.requestService.updateSessionDataToRoom(this.data.roomData['_id'], dataToSave, (data, error) => {
            if (error) {
              this.errorMessage = error;
              this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
            }
            if (data && data.hasOwnProperty('results')) {

              let dataList = data.results;
              for (let itm of dataList) {
                this.ablyService.sendSessionSignal(itm._id, 'forceRefresh', 'true');
              }
              this.getSessions(this.data.roomData);

              let description = 'This layout has been successfully applied to the following sessions:';
              let descriptionArray = dataList.map((itm) => { return { title: itm.name } });
              if (dataList.length === 0) {
                let description = 'No sessions were affected';
              }
              const dialogRef = this.dialog.open(SimpleAlertEntityDialogComponent, {
                width: '600px',
                disableClose: true,
                data: {
                  title: 'Design Layout Successfully Applied',
                  description: description,
                  descriptionArray: descriptionArray
                }
              });
            }
            this.loading = false;
            this.hasNewChanges = false;
            this.hasOfflineNewChanges = false;
            this.loaderService.display(false);
          });
        });
      }
    });
  }
  /**
  * Clean the session setting to sae to other sessions under the room
  */
  public getCleanSettings(settings) {
    let currentSettings = JSON.parse(JSON.stringify(settings));
    for (let viewItem of this.desktopNamesKeys) {
      if (currentSettings.hasOwnProperty(viewItem) && currentSettings[viewItem].hasOwnProperty('columns')) {
        for (let cmp of currentSettings[viewItem]['columns'][0]['components']) {
          if (['guest-camera', 'presenter-camera'].includes(cmp.name)) {
            cmp['metadata'] = { id: '', name: '' };
          } else if (cmp.name === 'panelView') {
            cmp['metadata'] = [];
          }
        }
      }

    }
    return currentSettings;
  }
  // public checkValidCloudlive() {
  //   // call login to cloudlive to see if valid cloudelive
  //   this.requestService.signupCloudLive(this.selectedUser._id, 1, 1, undefined, (response, error) => {
  //     if (error) {
  //       this.cloudliveSubscriptionStatus = false;
  //     }
  //     if (response) {
  //       this.cloudliveSubscriptionStatus = true;
  //       console.log('response', response);
  //     }
  //   });
  // }
  public validateLayoutByApi(callback: (dataResponse: boolean) => void) {
    if (this.data.data['streamMode'] === 'cloudlive') {
      if (this.subscriptionOrgObject && this.subscriptionOrgObject.features.includes('cloudlive-streaming')) {
        this.requestService.getCloudLiveMeeting(this.selectedUser._id + '-' + this.data.data.uuid, (data, error) => {
          if (error) {
            const dialog = this.layoutUtilsService.alertActionElement('', this.translate.instant('CloudLive streaming services are not available yet. It might take CloudLive up to one week to set up your account before you are able to start streaming.'), {
              overlayClickToClose: false,
              showCancelButton: false,
              declineText: this.translate.instant('OK')
            }, 'fit-content');
            callback(false);
          } else {
            if (data) {
              callback(true);
            } else {
              const dialog = this.layoutUtilsService.alertActionElement('', this.translate.instant('CloudLive streaming services are not available yet. It might take CloudLive up to one week to set up your account before you are able to start streaming.'), {
                overlayClickToClose: false,
                showCancelButton: false,
                declineText: this.translate.instant('OK')
              }, 'fit-content');
              callback(false);
            }
          }
        });
      } else {
        this.layoutUtilsService.showNotification(this.SubscriptionCloudliveStatus, this.translate.instant('Dismiss'));
        callback(false);
      }
    } else {
      callback(true);
    }
  }
  public validateLayout() {
    //Desktop validate
    let viewMode = 'desktop';
    if (this.data.data['streamMode'] === 'meeting' && (this.data.data['attendance'] === 'in-person' || this.data.data['attendance'] === 'hybrid')) {
      this.layoutUtilsService.showNotification('This session has Meeting mode so the attendance can only be set to Online. Please switch to Online attendance or change the Streaming Mode.', this.translate.instant('Dismiss'));
      return false;
    }
    if (this.data.data['streamMode'] === 'hls') {
      let currentSettings = JSON.parse(JSON.stringify(this.data.data['settings'][viewMode]));
      let selectedComponents = [];
      let componentsCopy = JSON.parse(JSON.stringify(currentSettings['columns'][0]['components']));
      let componentsList = componentsCopy.filter((cmp) => this.camerasCountComponents.includes(cmp.name) && cmp.active);
      for (let cmp of componentsList) {
        selectedComponents.push(cmp.name);
      }
      if (selectedComponents.length > 12) {
        if (this.viewMode === 'desktop') {
          this.layoutUtilsService.showNotification('Maximum number of cameras is 12', this.translate.instant('Dismiss'));
        } else {
          this.layoutUtilsService.showNotification('Maximum number of cameras is 12 for the Speaker/Moderator Layout.', this.translate.instant('Dismiss'));
        }

        return false;
      }
      // if (this.viewMode === 'desktop') {
      //   let currentSettings = JSON.parse(JSON.stringify(this.data.data['settings'][viewMode]));
      //   let componentsCopy = JSON.parse(JSON.stringify(currentSettings['columns'][0]['components']));
      //   let componentsList = componentsCopy.filter((cmp) => cmp.name === 'presenter-camera' && cmp.active);
      //   if (componentsList.length === 0) {
      //     let isAdvancedSpeaker = this.data.data.users.filter((user) => user.type === 'lead' && user.host);
      //     if (isAdvancedSpeaker.length > 0) {
      //       this.layoutUtilsService.showNotification(this.translate.instant('A ' + this.componentsName['presenter-camera'].displayName + ' should be added when there is only one speaker and no producers in the session'), this.translate.instant('Dismiss'));
      //       return false;
      //     }
      //   }
      // }
      viewMode = 'desktop-hls';
    } else if (this.data.data['streamMode'] === 'cloudlive') {
      if (!this.subscriptionOrgObject || (this.subscriptionOrgObject && !this.subscriptionOrgObject.features.includes('cloudlive-streaming'))) {
        this.layoutUtilsService.showNotification(this.SubscriptionCloudliveStatus, this.translate.instant('Dismiss'));
        return false;
      }
    }
    let currentSettings = JSON.parse(JSON.stringify(this.data.data['settings'][viewMode]));
    if (this.data.data['streamMode'] === 'rtctohls' || this.data.data['streamMode'] === 'rtc' || this.data.data['streamMode'] === 'hls' || this.data.data['streamMode'] === 'cloudlive') {
      for (let col of currentSettings['columns']) {
        for (let cmp of col['components']) {
          if (!this.componentsName[cmp.name].fixed) {
            for (let col of currentSettings['columns']) {
              let componentsCopy = JSON.parse(JSON.stringify(col['components']));
              let componentsList = componentsCopy.filter((comp) => cmp.name === comp.name);
              if (componentsList.length > 1) {
                this.layoutUtilsService.showNotification('You cannot select more than one ' + this.translate.instant(this.componentsName[cmp.name].displayName), this.translate.instant('Dismiss'));
                return false;
              }
            }
          }
          if (cmp.name === 'audienceView') {
            if (cmp['gridSizeX'] * cmp['gridSizeY'] > 10) {
              this.layoutUtilsService.showNotification(this.translate.instant('The maximum grid size is 10 for the Audience View component'), this.translate.instant('Dismiss'));
              return false;
            }
          }
          // validation fail safe for older layout
          for (let cmpType of this.componentsName[cmp.name].rules['requireCmp']) {
            let componentsCopy = JSON.parse(JSON.stringify(col['components']));
            let cmpAttachAvailable = componentsCopy.filter((cmp) => cmp.name === cmpType && cmp.active);
            if (cmpAttachAvailable.length === 0) {
              this.layoutUtilsService.showNotification(this.translate.instant('You need add an active ') + this.componentsName[cmpType].displayName + this.translate.instant(' component when you have a ') + this.componentsName[cmp.name].displayName + this.translate.instant(' component'), this.translate.instant('Dismiss'));
              return false;
            }
          }
        }

      }
      //Check if we need to add rtc too
      if (this.data.data['streamMode'] === 'rtctohls' || this.data.data['streamMode'] === 'hls' || this.data.data['streamMode'] === 'cloudlive') {
        let selectedComponents = [];
        let componentsCopy = JSON.parse(JSON.stringify(currentSettings['columns'][0]['components']));
        let componentsList = componentsCopy.filter((cmp) => this.camerasCountComponents.includes(cmp.name) && cmp.active);
        for (let cmp of componentsList) {
          selectedComponents.push(cmp.name);
        }
        if (selectedComponents.length > 12) {
          this.layoutUtilsService.showNotification('Maximum number of cameras is 12', this.translate.instant('Dismiss'));
          return false;
        }
      } else if (this.data.data['streamMode'] === 'rtc') {
        let componentsCopy = JSON.parse(JSON.stringify(currentSettings['columns'][0]['components']));
        let componentsList = componentsCopy.filter((cmp) => this.camerasCountComponents.includes(cmp.name) && cmp.active);
        // let panelList = componentsCopy.filter((cmp) => cmp.name === 'panelView' && cmp.active);
        // if (panelList.length > 0 && componentsList.length > 20) {
        //   this.layoutUtilsService.showNotification('You can add up to 20 camera components only when there is a Panel component added', this.translate.instant('Dismiss'));
        //   return false;
        // } 
        if (componentsList.length > 50) {
          this.layoutUtilsService.showNotification('You can add up to 50 camera components only in the Realtime Interaction mode', this.translate.instant('Dismiss'));
          return false;
        }
      }
      let componentsCopy = JSON.parse(JSON.stringify(currentSettings['columns'][0]['components']));

      // Advanced speaker validate Rule
      // if (this.data.data['streamMode'] !== 'cloudlive' && this.data.data['streamMode'] !== 'meeting') {
      //   let componentsList = componentsCopy.filter((cmp) => cmp.name === 'presenter-camera' && cmp.active);
      //   if (componentsList.length === 0) {
      //     let isAdvancedSpeaker = this.data.data.users.filter((user) => user.type === 'lead' && user.host);
      //     if (isAdvancedSpeaker.length > 0) {
      //       this.layoutUtilsService.showNotification(this.translate.instant('A ' + this.componentsName['presenter-camera'].displayName + ' should be added when there is only one speaker and no producers in the session'), this.translate.instant('Dismiss'));
      //       return false;
      //     }
      //   }
      // }
      let componentsListDefaultvideo = componentsCopy.filter((cmp) => cmp.name === 'defaultvideo');
      let componentsListNotDefaultvideo = componentsCopy.filter((cmp) => cmp.name !== 'defaultvideo');
      if (componentsListDefaultvideo.length > 0 && componentsListNotDefaultvideo.length > 0) {
        let highestZIndex = this.getHighestZ(componentsListNotDefaultvideo);
        if (componentsListDefaultvideo.filter((cmp) => cmp.z <= highestZIndex).length > 0) {
          this.layoutUtilsService.showNotification(this.translate.instant('Please make sure that the Z Position of Streaming Media components is larger than that of other components in order to be able to use the video controls. The Z Position can be found on the right toolbar that appears when you click on the component.'), this.translate.instant('Dismiss'), { duration: 12000 });
          return false;
        }
      }
    } else if (this.data.data['streamMode'] === 'meeting' && !this.data.data.opentoall) {
      if (this.data.data.users.length > this.data.data.maximumAttendees) {
        this.layoutUtilsService.showNotification(this.translate.instant('You can only add up to ') + this.data.data.maximumAttendees + this.translate.instant(' attendees to a meeting'), this.translate.instant('Dismiss'));
        return false;
      }
    }
    return true
  }
  getHighestZ(componentsList) {
    let highestZIndex = 1;
    for (let itm of componentsList) {
      if (itm.z > highestZIndex) {
        highestZIndex = itm.z;
      }
    }
    return highestZIndex > 99 ? 99 : highestZIndex;
  }
  public updateRoom(callback: (dataResponse: any | undefined) => void) {
    // console.log('settingRoomObject', this.settingRoomObject);
    let dataToSave = { _id: this.data.roomData['_id'], name: this.data.roomData['name'], pictureLink: this.bgBackgroundLink1, pictureLink2: this.bgBackgroundLink2, pictureLink3: this.bgBackgroundLink3, pictureLink4: this.bgBackgroundLink4, bgcolor: this.bgBackgroundColor, settings: this.settingRoomObject };
    this.requestService.saveData('room', dataToSave, (data, error) => {
      if (error) {
        this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
      }
      if (data) {
        this.data.roomData.pictureLink = this.bgBackgroundLink1;
        this.data.roomData.pictureLink2 = this.bgBackgroundLink2;
        this.data.roomData.pictureLink3 = this.bgBackgroundLink3;
        this.data.roomData.pictureLink4 = this.bgBackgroundLink4;
        this.data.roomData.bgcolor = this.bgBackgroundColor;
        this.data.roomData.settings = this.settingRoomObject;
        this.roomReturn.emit(data.results);
        callback(data);
        // Room Image updated
      }
    });
  }
  public loadroomData() {
    this.errorMessage = '';
    this.settingRoomObject = undefined;
    this.loaderService.display(true);
    this.requestService.getSingleData('room', this.data.roomData._id, (data, error) => {
      if (error) {
        this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
      }
      if (data) {
        this.data.roomData.pictureLink = data.results.pictureLink;
        this.data.roomData.bgcolor = data.results.bgcolor;
        this.data.roomData.settings = data.results.settings;
        this.settingRoomObject = this.data.roomData.settings;
        this.hasNewRoomChanges = false;
      }
      this.loaderService.display(false);
    });
  }
  closeModal(data): void {
    this.closeModalReturn.emit(data);
  }
  setShowMobileReturn(e): void {
    if (e && e.screenX > 0) {
      //console.log('setShowMobileReturn', e);
      this.showMobile = !this.showMobile;
      this.showMobileReturn.emit(this.showMobile);
    }
  }
  setViewModeReturn(e, mode: string = 'desktop'): void {
    //if (e && e.screenX > 0) {
    this.viewMode = mode;
    this.viewModeReturn.emit(this.viewMode);
    //}
  }
  public toggleClick(action, target, data) {
    if (target === 'parent') {
      if (this.validateObject(this.data.data)) {
        this.closeModal({ action: action, data: this.data.data, value: data });
      } else {
        this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + this.translate.instant('You need to select all mandatory fields'), this.translate.instant('Dismiss'));
      }
    } else {
      //console.log('toggleClick Self', action, target, data);
      if (action === 'close') {
        this.closeModal(undefined);
      }
    }
  }
  clearIt(e) {
    // e.preventDefault();
    // e.stopPropagation();
  }
  /**
  * Get sessions by room
  */
  getSessions(roomData, callback = undefined) {
    let roomId = roomData._id;
    let filters = {
      "$and": [{ "room._id": { "$eq": roomId } }]
    };
    // let filters = {
    //   "$and": [{ "room._id": { "$eq": roomId } }, { "opentoall": true }]
    // };
    this.requestService.getDataList('session', {
      fieldKeys: ['_id', 'name', 'startsAt', 'chainedtosession', 'active', 'isKeepActive'], orderDir: 'asc', orderBy: 'startsAt', filter: filters
    }, (data, error) => {
      if (error) {
        this.layoutUtilsService.showNotification('No ' + this.translate.instant('session') + ' available', 'Dismiss');
      }
      if (data) {
        this.sessionList = data.results;
        // if (!this.data.data._id && this.sessionList.length > 0) {
        //   this.selectSession(this.sessionList[0]._id);
        // }
        if (this.sessionList.length === 0) {
          this.flipLayer(true);
        }
      } else {
        this.sessionList = [];
      }
      if (callback) {
        callback();
      }
    });
  }
  /**
  * Get Active sessions by room
  */
  getLiveSessions(roomData, callback) {
    let roomId = roomData._id;
    let filters = {
      "$and": [{ "room._id": { "$eq": roomId }, "$or": [{ "active": true }, { "isKeepActive": true }] }]
    };
    // let filters = {
    //   "$and": [{ "room._id": { "$eq": roomId } }, { "opentoall": true }]
    // };
    this.requestService.getDataList('session', {
      fieldKeys: ['_id', 'name', 'active', 'isKeepActive'], orderDir: 'asc', orderBy: 'startsAt', filter: filters
    }, (data, error) => {
      if (error) {
        this.layoutUtilsService.showNotification('No ' + this.translate.instant('session') + ' available', 'Dismiss');
      }
      if (data) {
        callback(data.results);
      } else {
        callback([]);
      }
    }, '', '/summary');
  }
  /**
  * Create session popup
  */
  createSession() {
    this.canExit().then((result) => {
      if (result) {
        const dialogRef = this.dialog.open(ModalDialogComponent, {
          width: '800px',
          data: {
            dataType: this.data.dataType,
            dataTypeTitle: this.translate.instant('Session'),
            title: this.translate.instant('Create') + ' ' + this.translate.instant('Session'),
            data: {},
            modalSetting: Object.assign({}, this.data.modalSetting)
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.confirmCreate(result.data);
          }
        });
      }
    });
  }
  public confirmCreate(data) {
    if (!this.loading) {
      this.loading = true;
      //  this.loaderService.display(true);
      data['settings'] = this.defaultSetting;
      this.errorMessage = '';
      this.requestService.saveDataToRoom(this.data.roomData._id, this.data.dataType, data, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.layoutUtilsService.showNotification(this.translate.instant('Session') + ' ' + this.translate.instant('created Successfully'), this.translate.instant('Dismiss'));
          this.hasNewChanges = false;
          this.selectSession(data.results._id);
          this.getSessions(this.data.roomData);
        }
      });
    }
  }
  /**
  * Session Properties popup
  */
  editSession(sessionId) {
    const dialogRef = this.dialog.open(ModalDialogComponent, {
      width: '800px',
      data: {
        dataType: this.data.dataType,
        dataTypeTitle: this.translate.instant('Session'),
        title: this.translate.instant('Session') + ' ' + this.translate.instant('Properties'),
        data: { _id: sessionId },
        modalSetting: Object.assign({}, this.data.modalSetting)
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log('result', result);
        this.confirmEdit(result.data);
      }
    });
  }
  public confirmEdit(data) {
    if (!this.loading) {
      this.loading = true;
      //  this.loaderService.display(true);
      this.errorMessage = '';
      this.requestService.saveData(this.data.dataType, data, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.layoutUtilsService.showNotification(this.translate.instant('Session') + ' ' + this.translate.instant('edited Successfully'), this.translate.instant('Dismiss'));
          if (this.data.data._id === data.results._id) {
            this.data.data.name = data.results.name;
          }
          if (data.results.attendance) {
            this.data.data.attendance = data.results.attendance;
            this.cleanSettings(false);
          }
          this.activeModeReturn.emit(data.results.active);
          this.getSessions(this.data.roomData);
        }
      });
    }
  }
  /**
  * Delete session popup
  */
  deleteSession(sessionId) {
    if (!this.loading) {
      const _title: string = this.translate.instant('Session') + ' ' + this.translate.instant('Deletion');
      const _description: string = this.translate.instant('Are you sure you want to permanently delete this') + ' ' + this.translate.instant('Session') + '?';
      const _waitDesciption: string = this.translate.instant('Deleting') + '...';

      const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
      dialogRef.afterClosed().subscribe(res => {
        if (!res) {
          return;
        }
        this.realDelete(sessionId);
      });
    }
  }
  public realDelete(id: any) {
    const _deleteMessage = this.translate.instant('Session') + ' ' + this.translate.instant('Deleted Successfully') + '.';
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.deleteSingleData(this.data.dataType, id, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.hasNewChanges = false;
          this.layoutUtilsService.showNotification(_deleteMessage, this.translate.instant('Dismiss'));
          if (this.sessionsGalleryList) {
            this.sessionsGalleryList.loadDataSearch();
          }
          this.selectSession(undefined);
          this.getSessions(this.data.roomData);
        }
      });
    }
  }
  /**
  * Gallery popup
  */
  public galleryLibrary(openBg: boolean = false) {
    this.openedLeftMenu = '';
    const dialogRef = this.dialog.open(ModalAdvancedGalleryDialogComponent, {
      width: '100vw',
      disableClose: false,
      autoFocus: false,
      data: {
        handleBgColor: true,
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Background'),
        data: { bgBackgroundColor: this.bgBackgroundColor, bgBackgroundLink: this.bgBackgroundLink },
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.bgBackgroundColor = result.bgBackgroundColor;
        this.bgBackgroundLink = result.bgBackgroundLink;
        this.bgUpdate(this.bgBackgroundLink, this.bgBackgroundColor);
        this.hasNewRoomChanges = true;
        if (openBg) {
          this.dialogRoomComponents.open();
          // this.updateRoom((roomdata) => {
          //   this.hasNewRoomChanges = false;
          //   this.dialogRoomComponents.open();
          // });
        }
      }
    });
  }
  /**
  * Add tile popup
  */
  addTile(cmp) {
    this.openedLeftMenu = '';
    let addNote = undefined;
    if (this.backLayer) {
      addNote = 'Note: Tiles with questionnaires can only be submitted when placed on the Session level.'
    }
    const dialogRef = this.dialog.open(TileSelectDialogComponent, {
      width: '600px',
      autoFocus: false,
      data: {
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Tile'),
        data: {},
        hasAdd: true,
        hideClear: true,
        addNote: addNote
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.hasOwnProperty('_id')) {
          if (this.backLayer) {
            let currentSettings = JSON.parse(JSON.stringify(this.settingRoomObject[this.viewRoomMode]));
            cmp['metadata']['id'] = result['_id'];
            cmp['metadata']['name'] = result['title'];
            cmp['metadata']['categoryId'] = result['categoryId'];
            currentSettings['columns'][0]['components'].push(cmp);
            this.data.roomData['settings'][this.viewRoomMode] = JSON.parse(JSON.stringify(currentSettings));
            this.cleanRoomSettings();
            this.selectRoomChildLastComponent();
            this.hasNewRoomChanges = true;
          } else {
            let currentSettings = JSON.parse(JSON.stringify(this.settingObject[this.viewMode]));
            cmp['metadata']['id'] = result['_id'];
            cmp['metadata']['name'] = result['title'];
            cmp['metadata']['categoryId'] = result['categoryId'];
            currentSettings['columns'][0]['components'].push(cmp);
            this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(currentSettings));
            this.cleanSettings(false);
            this.selectChildLastComponent();
            this.hasNewChanges = true;
          }
          this.openedLeftMenu = '';
        } else if (result.hasOwnProperty('new')) {
          this.createTile(cmp);
        }
      }
    });
  }
  /**
  * Add Picture popup
  */
  addPicture(cmp) {
    this.openedLeftMenu = '';
    const dialogRef = this.dialog.open(ModalAdvancedGalleryDialogComponent, {
      width: '100vw',
      disableClose: false,
      autoFocus: false,
      data: {
        handleBgColor: false,
        noImage: true,
        notBg: true,
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Image'),
        data: { bgBackgroundLink: cmp['image'] },
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.backLayer) {
          let currentSettings = JSON.parse(JSON.stringify(this.settingRoomObject[this.viewRoomMode]));
          cmp['image'] = result.bgBackgroundLink;
          currentSettings['columns'][0]['components'].push(cmp);
          this.data.roomData['settings'][this.viewRoomMode] = JSON.parse(JSON.stringify(currentSettings));
          this.cleanRoomSettings();
          this.selectRoomChildLastComponent();
          this.hasNewRoomChanges = true;
        } else {
          let currentSettings = JSON.parse(JSON.stringify(this.settingObject[this.viewMode]));
          cmp['image'] = result.bgBackgroundLink;
          currentSettings['columns'][0]['components'].push(cmp);
          this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(currentSettings));
          this.cleanSettings(false);
          this.selectChildLastComponent();
          this.hasNewChanges = true;
        }
        this.openedLeftMenu = '';
      }
    });
  }
  /**
  * Add QuuNowPlaying popup
  */
  addQuuNowPlaying(cmp) {
    this.openedLeftMenu = '';
    const dialogRef = this.dialog.open(QuuRadioDialogComponent, {
      width: '600px',
      disableClose: false,
      autoFocus: false,
      data: {
        hasSongsNo: false,
        title: this.translate.instant('Edit') + ' Quu Radio - Now Playing ' + this.translate.instant('Settings'),
        data: undefined,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.backLayer) {
          let currentSettings = JSON.parse(JSON.stringify(this.settingRoomObject[this.viewRoomMode]));
          cmp['metadata'] = result;
          currentSettings['columns'][0]['components'].push(cmp);
          this.data.roomData['settings'][this.viewRoomMode] = JSON.parse(JSON.stringify(currentSettings));
          this.cleanRoomSettings();
          this.selectRoomChildLastComponent();
          this.hasNewRoomChanges = true;
        } else {
          let currentSettings = JSON.parse(JSON.stringify(this.settingObject[this.viewMode]));
          cmp['metadata'] = result;
          currentSettings['columns'][0]['components'].push(cmp);
          this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(currentSettings));
          this.cleanSettings(false);
          this.selectChildLastComponent();
          this.hasNewChanges = true;
        }
        this.openedLeftMenu = '';
      }
    });
  }
  /**
  * Add QuuSongs popup
  */
  addQuuSongs(cmp) {
    this.openedLeftMenu = '';
    const dialogRef = this.dialog.open(QuuRadioDialogComponent, {
      width: '600px',
      disableClose: false,
      autoFocus: false,
      data: {
        hasSongsNo: true,
        title: this.translate.instant('Edit') + ' Quu Radio - Songs ' + this.translate.instant('Settings'),
        data: undefined,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.backLayer) {
          let currentSettings = JSON.parse(JSON.stringify(this.settingRoomObject[this.viewRoomMode]));
          cmp['metadata'] = result;
          currentSettings['columns'][0]['components'].push(cmp);
          this.data.roomData['settings'][this.viewRoomMode] = JSON.parse(JSON.stringify(currentSettings));
          this.cleanRoomSettings();
          this.selectRoomChildLastComponent();
          this.hasNewRoomChanges = true;
        } else {
          let currentSettings = JSON.parse(JSON.stringify(this.settingObject[this.viewMode]));
          cmp['metadata'] = result;
          currentSettings['columns'][0]['components'].push(cmp);
          this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(currentSettings));
          this.cleanSettings(false);
          this.selectChildLastComponent();
          this.hasNewChanges = true;
        }
        this.openedLeftMenu = '';
      }
    });
  }
  /**
  * Add Iframe popup
  */
  addIframe(cmp) {
    this.openedLeftMenu = '';
    const dialogRef = this.dialog.open(IframeComponent, {
      width: '25vw',
      disableClose: false,
      autoFocus: false,
      data: {
        title: this.translate.instant('Insert') + ' ' + this.translate.instant('Iframe') + ' ' + this.translate.instant('URL'),
        data: {},
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.backLayer) {
          let currentSettings = JSON.parse(JSON.stringify(this.settingRoomObject[this.viewRoomMode]));
          cmp['metadata'].link = result.data.link;
          currentSettings['columns'][0]['components'].push(cmp);
          this.data.roomData['settings'][this.viewRoomMode] = JSON.parse(JSON.stringify(currentSettings));
          this.cleanRoomSettings();
          this.selectRoomChildLastComponent();
          this.hasNewRoomChanges = true;
        } else {
          let currentSettings = JSON.parse(JSON.stringify(this.settingObject[this.viewMode]));
          cmp['metadata'].link = result.data.link;
          currentSettings['columns'][0]['components'].push(cmp);
          this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(currentSettings));
          this.cleanSettings(false);
          this.selectChildLastComponent();
          this.hasNewChanges = true;
        }
        this.openedLeftMenu = '';
      }
    });
  }
  /**
  * Add Empty Click popup
  */
  addEmptyClick(cmp) {
    this.openedLeftMenu = '';
    const dialogRef = this.dialog.open(PictureDialogComponent, {
      width: '600px',
      disableClose: false,
      autoFocus: false,
      data: {
        hasShape: true,
        isEmptyClick: true,
        title: this.translate.instant('Clickable Area Settings'),
        data: { type: cmp['metadata']['type'], link: cmp['link'], linkDisplay: cmp['metadata']['linkDisplay'], title: cmp['title'], shape: cmp['shape'], autoPlay: cmp['metadata']['autoPlay'], loop: cmp['metadata']['loop'], volume: cmp['metadata']['volume'] },
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let currentSettings = JSON.parse(JSON.stringify(this.settingRoomObject[this.viewRoomMode]));
        cmp['metadata']['type'] = result.type;
        cmp['metadata']['link'] = result.link;
        cmp['metadata']['linkDisplay'] = result.linkDisplay;
        cmp['metadata']['autoPlay'] = result.autoPlay;
        cmp['metadata']['loop'] = result.loop;
        cmp['metadata']['volume'] = result.volume;
        cmp['title'] = result.title;
        cmp['shape'] = result.shape;
        if (result.shape === 'poly') {
          cmp['svg'] = [{ x: 1.46, y: 0.99 }, { x: 0.63, y: 3.18 }, { x: 3.07, y: 4.06 }, { x: 4.48, y: 1.98 }];
        }
        currentSettings['columns'][0]['components'].push(cmp);
        this.data.roomData['settings'][this.viewRoomMode] = JSON.parse(JSON.stringify(currentSettings));
        this.cleanRoomSettings();
        // this.backLayerReturn.emit(true);
        this.selectRoomChildLastComponent();
        this.openedLeftMenu = '';
        this.openedRightTemplateMenu = '';
        this.hasNewRoomChanges = true;
      }
    });
  }
  /**
  * Add Empty Click popup
  */
  addAudio(cmp) {
    this.openedLeftMenu = '';
    const dialogRef = this.dialog.open(AudioEditDialogComponent, {
      width: '600px',
      disableClose: false,
      autoFocus: false,
      data: {
        title: this.translate.instant('Edit') + ' ' + this.translate.instant('Audio Player') + ' ' + this.translate.instant('Settings'),
        data: cmp['metadata']
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.backLayer) {
          let currentSettings = JSON.parse(JSON.stringify(this.settingRoomObject[this.viewRoomMode]));
          cmp['metadata'] = result;
          currentSettings['columns'][0]['components'].push(cmp);
          this.data.roomData['settings'][this.viewRoomMode] = JSON.parse(JSON.stringify(currentSettings));
          this.cleanRoomSettings();
          this.selectRoomChildLastComponent();
          this.hasNewRoomChanges = true;
        } else {
          let currentSettings = JSON.parse(JSON.stringify(this.settingObject[this.viewMode]));
          cmp['metadata'] = result;
          currentSettings['columns'][0]['components'].push(cmp);
          this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(currentSettings));
          this.cleanSettings(false);
          this.selectChildLastComponent();
          this.hasNewChanges = true;
        }
        this.openedLeftMenu = '';
      }
    });
  }
  /**
  * Add Picture wall popup
  */
  addPicturewall(cmp) {
    this.openedLeftMenu = '';
    const dialogRef = this.dialog.open(PictureWallDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Picture Wall') + ' ' + this.translate.instant('Settings'),
        data: cmp
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (this.backLayer) {
          let currentSettings = JSON.parse(JSON.stringify(this.settingRoomObject[this.viewRoomMode]));
          cmp['gridSizeX'] = result['gridSizeX'];
          cmp['gridSizeY'] = result['gridSizeY'];
          cmp['timer'] = result['timer'];
          cmp['moderated'] = result['moderated'];
          cmp['bgcolor'] = result['bgcolor'];
          // cmp['opacity'] = result['opacity'];
          currentSettings['columns'][0]['components'].push(cmp);
          this.data.roomData['settings'][this.viewRoomMode] = JSON.parse(JSON.stringify(currentSettings));
          this.cleanRoomSettings();
          this.selectRoomChildLastComponent();
          this.hasNewRoomChanges = true;
        } else {
          let currentSettings = JSON.parse(JSON.stringify(this.settingObject[this.viewMode]));
          cmp['gridSizeX'] = result['gridSizeX'];
          cmp['gridSizeY'] = result['gridSizeY'];
          cmp['timer'] = result['timer'];
          cmp['moderated'] = result['moderated'];
          cmp['bgcolor'] = result['bgcolor'];
          // cmp['opacity'] = result['opacity'];
          currentSettings['columns'][0]['components'].push(cmp);
          this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(currentSettings));
          this.cleanSettings(false);
          this.selectChildLastComponent();
          this.hasNewChanges = true;
        }
        this.openedLeftMenu = '';
      }
    });
  }
  /**
  * Add Billboard popup
  */
  addBillboard(cmp) {
    this.openedLeftMenu = '';
    const dialogRef = this.dialog.open(BillboardSelectDialogComponent, {
      width: '600px',
      data: {
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Billboard'),
        data: { metadata: { id: '', name: '' } },
        hasAdd: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.hasOwnProperty('_id')) {
          if (this.backLayer) {
            let currentSettings = JSON.parse(JSON.stringify(this.settingRoomObject[this.viewRoomMode]));
            cmp['metadata']['id'] = result['_id'];
            if (result.hasOwnProperty('text')) {
              cmp['metadata']['name'] = result['text'];
            } else {
              cmp['metadata']['name'] = result['title'];
            }
            currentSettings['columns'][0]['components'].push(cmp);
            this.data.roomData['settings'][this.viewRoomMode] = JSON.parse(JSON.stringify(currentSettings));
            this.cleanRoomSettings();
            this.selectRoomChildLastComponent();
            this.hasNewRoomChanges = true;
          } else {
            let currentSettings = JSON.parse(JSON.stringify(this.settingObject[this.viewMode]));
            cmp['metadata']['id'] = result['_id'];
            if (result.hasOwnProperty('text')) {
              cmp['metadata']['name'] = result['text'];
            } else {
              cmp['metadata']['name'] = result['title'];
            }
            currentSettings['columns'][0]['components'].push(cmp);
            this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(currentSettings));
            this.cleanSettings(false);
            this.selectChildLastComponent();
            this.hasNewChanges = true;
          }
          this.openedLeftMenu = '';
        } else if (result.hasOwnProperty('new')) {
          this.createBillboard(cmp);
        }
      }
    });
  }

  /**
  * Save Template popup
  */
  saveTemplateSettings() {
    let filterData: any = {
      '$and': [
        { 'organizationId._id': { '$eq': this.requestService.orgId } }
      ]
    };
    const dialogRef = this.dialog.open(ModalDialogComponent, {
      width: '600px',
      data: {
        dataType: 'layout',
        dataTypeTitle: this.translate.instant('Template'),
        title: this.translate.instant('Create') + ' ' + this.translate.instant('Template'),
        data: {},
        modalSetting: this.getTemplateSetting({}, this.getTemplateCustomFilter())
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.confirmLayoutCreate(result.data);
      }
    });
  }
  private getTemplateSetting(data, filters) {
    let tableSetting = data;
    tableSetting['target'] = 'parent';
    if (filters)
      tableSetting['filters'] = filters;
    tableSetting['customSettings'] = {
      organizationId: {
        visible: false,
        value: { _id: this.requestService.orgId, name: '' }
      },
      bgImage: {
        visible: false,
        value: this.bgBackgroundLink
      },
      bgcolor: {
        visible: false,
        value: this.bgBackgroundColor
      },
      settingType: {
        visible: true
      },
      settings: {
        visible: false,
        value: JSON.parse(JSON.stringify(this.settingObject))
      }
    };

    // {displayName: "Meeting", value: "1"}
    // {displayName: "Lecture", value: "2"}

    if (this.data.data['streamMode'] == 'rtc') {
      tableSetting['customSettings']['settingType']['value'] = ["2"];
      tableSetting['customSettings']['settingType']['enumDisabled'] = ["1"];
    } else if (this.data.data['streamMode'] == 'meeting') {
      tableSetting['customSettings']['settingType']['value'] = ["1"];
      tableSetting['customSettings']['settingType']['enumDisabled'] = ["2"];
    }
    return tableSetting;
  }
  private getTemplateCustomFilter() {
    let filters = {};
    filters['$and'] = [{ "organizationId._id": { "$eq": this.requestService.orgId } }];
    return filters;
  }
  public confirmLayoutCreate(data) {
    if (!this.loading) {
      this.loading = true;
      this.loaderService.display(true);
      this.errorMessage = '';
      this.requestService.saveData('layout', data, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        this.loaderService.display(false);
        if (data) {
          this.layoutUtilsService.showNotification(this.translate.instant('Template') + ' ' + this.translate.instant('created Successfully'), this.translate.instant('Dismiss'));
          this.loadTemplateSettings();
        }
      });
    }
  }
  /**
  * Create Billboard popup
  */
  createBillboard(cmp) {
    let filterData: any = {
      '$and': [
        { 'organizationId._id': { '$eq': this.requestService.orgId } }
      ]
    };
    const dialogRef = this.dialog.open(ModalDialogComponent, {
      width: '100vw',
      data: {
        dataType: 'banner',
        dataTypeTitle: this.translate.instant('Billboard'),
        title: this.translate.instant('Create') + ' ' + this.translate.instant('Billboard'),
        data: {},
        modalSetting: this.getBillboardSetting({}, this.getBillboardCustomFilter())
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.confirmBillboardCreate(cmp, result.data);
      }
    });
  }
  private getBillboardSetting(data, filters) {
    let tableSetting = data;
    tableSetting['target'] = 'parent';
    if (filters)
      tableSetting['filters'] = filters;
    tableSetting['customSettings'] = {
      organizationId: {
        visible: false,
        value: { _id: this.requestService.orgId, name: '' }
      }
    };
    return tableSetting;
  }
  private getBillboardCustomFilter() {
    let filters = {};
    filters['$and'] = [{ "organizationId._id": { "$eq": this.requestService.orgId } }];
    return filters;
  }
  public confirmBillboardCreate(cmp, data) {
    if (!this.loading) {
      this.loading = true;
      //  this.loaderService.display(true);
      this.errorMessage = '';
      this.requestService.saveData('banner', data, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          let result = data.results;
          if (result.hasOwnProperty('_id')) {
            cmp['metadata']['id'] = result['_id'];
            cmp['metadata']['name'] = result['name'];
            if (this.backLayer) {
              let currentSettings = JSON.parse(JSON.stringify(this.settingRoomObject[this.viewRoomMode]));
              currentSettings['columns'][0]['components'].push(cmp);
              this.data.roomData['settings'][this.viewRoomMode] = JSON.parse(JSON.stringify(currentSettings));
              this.cleanRoomSettings();
              this.selectRoomChildLastComponent();
              this.hasNewRoomChanges = true;
            } else {
              let currentSettings = JSON.parse(JSON.stringify(this.settingObject[this.viewMode]));
              currentSettings['columns'][0]['components'].push(cmp);
              this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(currentSettings));
              this.cleanSettings();
              this.selectChildLastComponent();
              this.hasNewChanges = true;
            }
            this.openedLeftMenu = '';
            this.layoutUtilsService.showNotification(this.translate.instant('Billboard') + ' ' + this.translate.instant('created Successfully'), this.translate.instant('Dismiss'));
          }
        }
      });
    }
  }
  /**
  * Create Tile popup
  */
  createTile(cmp) {
    let filterData: any = {
      '$and': [
        { 'organizationId._id': { '$eq': this.requestService.orgId } }
      ]
    };
    const dialogRef = this.dialog.open(ModalTileCreateDialogComponent, {
      width: '100vw',
      data: {
        title: this.translate.instant('Create') + ' ' + this.translate.instant('Tile'),
        data: {}
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        cmp['metadata']['id'] = result['_id'];
        cmp['metadata']['name'] = result['name'];
        if (this.backLayer) {
          let currentSettings = JSON.parse(JSON.stringify(this.settingRoomObject[this.viewRoomMode]));
          currentSettings['columns'][0]['components'].push(cmp);
          this.data.roomData['settings'][this.viewRoomMode] = JSON.parse(JSON.stringify(currentSettings));
          this.cleanRoomSettings();
          this.selectRoomChildLastComponent();
          this.hasNewRoomChanges = true;
        } else {
          let currentSettings = JSON.parse(JSON.stringify(this.settingObject[this.viewMode]));
          currentSettings['columns'][0]['components'].push(cmp);
          this.data.data['settings'][this.viewMode] = JSON.parse(JSON.stringify(currentSettings));
          this.cleanSettings();
          this.selectChildLastComponent();
          this.hasNewChanges = true;
        }
        this.openedLeftMenu = '';
      }
    });
  }
  /**
  * Add People Function
  */
  addPeople(ppl) {
    this.settingUsers(ppl, this.data);
    this.openedLeftMenu = '';
  }
  settingUsers(type, event) {
    this.canExit('You need to save changes to continue', false).then((canExitResult) => {
      if (canExitResult) {
        let title = 'Assign users to ' + event.data.name;
        let disableClose = false;
        if (type === 'host') {
          title = 'Assign Session Host';
          disableClose = true;
        }
        const dialogRef = this.dialog.open(SessionUsersAssignDialogComponent, {
          width: '1600px',
          disableClose: disableClose,
          data: {
            targetDataType: 'resource/user',
            targetDataTypeDisplay: this.translate.instant('Users'),
            dataType: this.data.dataType,
            title: title,
            columnData: type,
            data: event.data,
            filters: {
              '$and': [
                { 'organizationId._id': { '$eq': this.requestService.orgId } }
              ]
            },
          }
        });
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            if (result.users) {
              this.data.data.users = result.users.users;
            }
            this.editApplySession((callbackStatus) => {
              if (result.affectedSessions && result.type && result.user) {
                this.showAffectedSessions(result.affectedSessions, result.type, result.user)
              }
            });

            // if (this.data.data.users && type === 'guest') {
            //   let guests = this.data.data.users.filter((user) => user.type === 'guest');
            //   this.guestsTotal = guests.length;
            // }
          }
        });
      }
    });

  }
  showAffectedSessions(list, type, user): void {
    let description = user.name + ' has been successfully assigned as a ' + this.translate.instant(this.peopleType[type]) + ' to the following sessions:';
    let descriptionArray = list.map((itm) => { return { body: itm.name } });
    if (list.length === 0) {
      description = user.name + ' has not been assigned as a ' + this.translate.instant(this.peopleType[type]) + ' to any session';
    }
    const dialogRef = this.dialog.open(SimpleAlertEntityDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {
        title: this.translate.instant('Producer Assignment Success'),
        description: description,
        descriptionArray: descriptionArray
      }
    });
  }
  assignContent() {
    let dataList = [];
    if (this.data.data.hasOwnProperty('tiles')) {
      for (let dt of this.data.data.tiles) {
        dataList.push({ _id: dt._id, text: dt.name });
      }
    }
    const dialogRef = this.dialog.open(CustomSelectCreateAutocompleteDialogComponent, {
      width: '1600px',
      data: {
        targetDataType: 'tile',
        targetDataTypeDisplay: this.translate.instant('Content'),
        dataType: this.data.dataType,
        title: this.translate.instant('Assign content to') + ' ' + this.data.data.name,
        columnData: 'tiles',
        askToRefresh: this.data.data.active,
        data: { _id: this.data.data._id },
        selectedData: dataList,
        filters: {
          '$and': [
            { 'organizationId': { '$eq': this.requestService.orgId } },
            { 'createdBy': { '$eq': this.selectedUser._id } }
          ]
        },
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log(result);
        if (!this.loading) {
          this.loading = true;
          this.errorMessage = '';
          let dataList = [];
          for (let dt of result) {
            dataList.push({ _id: dt._id, name: dt.text, active: false });
          }
          let obg = { tiles: dataList };
          this.requestService.assignTilesToSession(this.data.data._id, obg, (data, error) => {
            if (error) {
              this.errorMessage = error;
              this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
            }
            this.loading = false;
            if (data) {
              if (this.data.data.active) {
                this.ablyService.sendSessionSignal(this.data.data._id, 'forceRefresh', 'true');
              }
              this.data.data.tiles = data.results.tiles;
            }
          });
        }
      }
    });
  }
  assignResources() {
    let dataList = [];
    if (this.data.data.hasOwnProperty('resources')) {
      for (let dt of this.data.data.resources) {
        dataList.push({ _id: dt._id, text: dt.name, availability: dt.availability });
      }
    }
    const dialogRef = this.dialog.open(ResourcesSessionAssignDialogComponent, {
      width: '1600px',
      data: {
        targetDataType: 'docs',
        targetDataTypeDisplay: this.translate.instant('Resources'),
        dataType: this.data.dataType,
        title: this.translate.instant('Assign resources to') + ' ' + this.data.data.name,
        columnData: 'resources',
        data: { _id: this.data.data._id },
        selectedData: dataList,
        filters: {
          '$and': [
            { 'organizationId._id': { '$eq': this.requestService.orgId } },
            // { 'createdBy': { '$eq': this.selectedUser._id } }
          ]
        },
      }
    });
    dialogRef.afterClosed().subscribe(resultPop => {
      if (resultPop) {
        //console.log(result);
        if (!this.loading) {
          const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
            disableClose: true,
            data: {
              title: this.translate.instant('Save Layout'),
              data: '',
              description: this.translate.instant('This will save the layout and refresh the pages of everyone in the session. Are you sure you want to proceed?'),
              cancelbtn: this.translate.instant('Cancel'),
              confirmbtn: this.translate.instant('Proceed'),
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.loading = true;
              this.errorMessage = '';
              let dataList = [];
              for (let dt of resultPop) {
                dataList.push({ _id: dt._id, name: dt.text, availability: dt.availability });
              }
              let obg = { resources: dataList };
              this.requestService.assignResourcesToSession(this.data.data._id, obg, (data, error) => {
                if (error) {
                  this.errorMessage = error;
                  this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
                }
                this.loading = false;
                if (data) {
                  this.ablyService.sendSessionSignal(this.data.data._id, 'forceRefresh', 'true');
                  this.data.data.resources = data.results.resources;
                }
              });
            }
          });

        }
      }
    });
  }

  addBreakoutSession() {
    let attendees = this.requestService.getSessionRoleByType(this.data.data, 'attendee');
    const dialogRef = this.dialog.open(BreakoutEditComponent, {
      width: '55vw',
      data: {
        disableDrag: true,
        hideBreakout: undefined,
        attendees: attendees.filter(i => i._id !== this.selectedUser['_id']),
        selectedUser: this.selectedUser,
        title: this.translate.instant('Breakout'),
        disableExpansion: true,
        sessionId: this.data.data._id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      //if (result) {
      let dataId = this.data.data['_id'];
      this.requestService.getSingleData(this.data.dataType, dataId, (data, error) => {
        this.loaderService.display(false);
        if (data) {
          this.data.data.users = data.results.users;
        }
      });
      //}
    });
  }
  openSession(id) {
    this.loading = true;
    this.loaderService.display(true);
    this.roomSessionService.openSessionById(id, (currentSession) => {
      this.loading = false;
      this.loaderService.display(false);
      if (currentSession) {
        this.roomSessionService.openSession(currentSession.room, currentSession);
      }
    }
    );
  }
  showSelfHelp(target) {
    if (this.bdcWalkService.getTaskCompletedShow(target)) {
      this.bdcWalkService.setTaskCompletedShow(target, false);
    } else {
      this.bdcWalkService.setTaskCompletedShow(target, true);
    }
  }
  setTemplateActionReturn(action): void {
    if (this.hasNewRoomChanges && (action === 'room-create' || action === 'room-create-room-exhibit' || action === 'create-room-lobby')) {
      this.templateActionReturn.emit('edit');
    } else {
      this.canExit().then((result) => {
        if (result) {
          this.templateActionReturn.emit(action);
        }
      });
    }

  }
  setViewModeOffline(status): void {
    if (status) {
      // if(['rtc', 'hls', 'rtctohls', 'meeting', 'largemeeting'].includes(this.streamMode)){
      if (['desktop-offline', 'desktop', 'desktop-2', 'desktop-3', 'desktop-4', 'desktop-hls'].includes(this.viewMode)) {
        this.setViewModeReturn(undefined, 'desktop-offline');
        // } else if (['mobile-offline', 'mobile', 'mobile-hls'].includes(this.viewMode)) {
        //   this.setViewModeReturn(undefined, 'mobile-offline');
      }
    } else {
      if (['rtc', 'rtctohls', 'meeting', 'largemeeting', 'cloudlive'].includes(this.data.data['streamMode']) && ['desktop-offline', 'desktop', 'desktop-2', 'desktop-3', 'desktop-4', 'desktop-hls'].includes(this.viewMode)) {
        this.setViewModeReturn(undefined, 'desktop');
        // } else if (['rtc', 'rtctohls', 'meeting', 'largemeeting'].includes(this.data.data['streamMode']) && ['mobile-offline', 'mobile', 'mobile-hls'].includes(this.viewMode)) {
        //   this.setViewModeReturn(undefined, 'mobile');
      } else if (this.data.data['streamMode'] === 'hls' && ['desktop-offline', 'desktop-2', 'desktop-3', 'desktop-4', 'desktop', 'desktop-hls'].includes(this.viewMode)) {
        this.setViewModeReturn(undefined, 'desktop-hls');
        // } else if (this.data.data['streamMode'] === 'hls' && ['mobile-offline', 'mobile', 'mobile-hls'].includes(this.viewMode)) {
        //   this.setViewModeReturn(undefined, 'mobile-hls');
      } else {
        if (['desktop-offline', 'desktop-2', 'desktop-3', 'desktop-4', 'desktop', 'desktop-hls'].includes(this.viewMode)) {
          this.setViewModeReturn(undefined, 'desktop');
          // } else if (['mobile-offline', 'mobile', 'mobile-hls'].includes(this.viewMode)) {
          //   this.setViewModeReturn(undefined, 'mobile');
        }
      }
    }
  }
  public deleteRoom(id: any) {
    if (!this.loading) {
      const _title: string = 'Room ' + this.translate.instant('Deletion');
      const _description: string = this.translate.instant('Are you sure you want to permanently delete this') + ' Room?';
      const _waitDesciption: string = this.translate.instant('Deleting') + '...';

      const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
      dialogRef.afterClosed().subscribe(res => {
        if (!res) {
          return;
        }
        this.realDeleteRoom(id);
      });
    }
  }
  public realDeleteRoom(id: any) {
    const _deleteMessage = 'Room ' + this.translate.instant('Deleted Successfully') + '.';
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.deleteSingleData('room', id, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.hasNewRoomChanges = false;
          this.hasNewChanges = false;
          this.layoutUtilsService.showNotification(_deleteMessage, this.translate.instant('Dismiss'));
          this.router.navigateByUrl('/loading', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/rooms/settings'], { relativeTo: this.activatedRoute });
          });
        }
      });
    }
  }
  getComponentIndex(viewMode, name, index, subIndex) {
    let currentSettings = JSON.parse(JSON.stringify(this.settingObject[viewMode]));
    return CommonFunctions.getComponentIndex(currentSettings['columns'][index]['components'], 'name', name, subIndex);
  }
  handleRoomComponentsClose() {
    if (this.dialogRoomComponentsChk) {
      this.messagesStatusService.setStatus('dialogRoomComponents', true)
    }
    this.dialogRoomComponents.close();
    // this.galleryLibrary();
  }
}
