import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, NgZone, OnDestroy, OnInit, Optional, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { CdkDrag, CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ConfirmInputEntityDialogComponent } from '../../../../../../shared/components/modals/confirm-input-entity-dialog/confirm-input-entity-dialog.component';
import { ConfirmEntityDialogComponent } from '../../../../../../shared/components/modals/confirm-entity-dialog/confirm-entity-dialog.component';
import { RequestService, LayoutUtilsService, TokBoxService } from '../../../../../../shared/services';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AblyService } from 'src/app/shared/services/ably.service';
import { SchedulerService } from 'src/app/shared/services/scheduler.service';

interface DialogData {
    data: any;
}

@Component({
    selector: 'app-breakout-edit',
    templateUrl: './breakout-edit.component.html',
    styleUrls: ['./breakout-edit.component.scss'],
    animations: [
        trigger('showDialog', [ // used for animating this popup
            state('in', style({
                opacity: 0,
                top: '0%'
            })),
            state('out', style({
                opacity: 0.98,
                top: '7.5%'
            })),
            transition('in => out', animate('1500ms ease-in-out'))
        ])
    ]
})
export class BreakoutEditComponent implements OnInit, OnDestroy {

    animationState: string = 'in';
    _zIndex: number = 0;
    _zIndexTemp: number = 0;
    private subscriptions: Subscription[] = [];
    _hide: boolean = false;
    _leftPosition: any;
    _filteredList: object[] = new Array<object>();
    filterByText: string = undefined;
    groups = [];
    deletedGroups = [];
    availableAttendees = [{ id: '0000', name: 'Available', edit: false, attendees: [] }];
    connectedTo = [];
    fixedNumberOfUsersInGroups: number = 2;
    loading: boolean = false;
    sessionId: string = undefined;
    breakoutStartedAt: any = undefined;
    isBreakoutStarted: boolean = false;
    disableExpansion: boolean = true;
    breakoutTimer: number = 1;
    counterSubscription: Subscription;
    tick: number = 1000;
    countDownTextDay: number = undefined;
    countDownTextHour: number = undefined;
    countDownTextMin: number = undefined;
    countDownTextSec: number = undefined;
    _session: OT.Session = undefined;
    _attendees: any = [];
    isGroupsLocked: boolean = true;
    filterByRegistered: boolean = true;
    filterByOnline: boolean = true;
    _sessionData: any = undefined;

    @Input() selectedUser: any = undefined;
    @Input()
    set hide(hide: boolean) { // on unhide set the animationstate to start the animation
        if (hide != undefined) {
            this._hide = hide;
            if (!hide) {
                this.animationState = 'out';
                this.maximize();
            }
            else {
                this.closeDialog();
            }
        }
    }
    @Input() disableDrag: boolean = false;
    @Input() isHost: boolean = false;
    @Input()
    set session(session: OT.Session) {
        if (session) {
            this._session = session;
        }
    }
    get session() {
        return this._session;
    }
    @Input() roomData: any = undefined;
    @Input()
    set sessionData(data: any) {
        if (data) {
            this._sessionData = data;
            this.sessionId = data._id;
        }
    }
    get sessionData() {
        return this._sessionData;
    }
    @Input() isStreaming: boolean = false;

    @Output() hideDialog = new EventEmitter<boolean>(undefined);

    @ViewChild('breakoutDraggable') breakoutDraggable: ElementRef;
    @ViewChild(CdkDrag) cdkDrag: CdkDrag;

    constructor(private opentokService: TokBoxService, private changeDetectorRef: ChangeDetectorRef, private layoutUtilsService: LayoutUtilsService, private zone: NgZone, private dialog: MatDialog, private translate: TranslateService, private requestService: RequestService, private activatedRoute: ActivatedRoute, @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: DialogData, private router: Router, @Optional() public dialogRef: MatDialogRef<BreakoutEditComponent>, private ablyService: AblyService, private schedulerService: SchedulerService) {
        for (let group of this.availableAttendees) {
            this.connectedTo.push(group.id);
        };
    }


    /**
     * Animate the dialog on close
     *
     * @memberof BreakoutEditComponent
     */
    closeDialog() {
        this.animationState = 'in';
        if (this.cdkDrag)
            this.cdkDrag._dragRef.reset();
        this.hideDialog.emit(true);

    }

    private updateLists(message: any) {
        if (message.id) {
            let user = this.availableAttendees[0].attendees.find(i => i.id === message.senderId); // get existing user obj from bucket
            if (user) {
                this.availableAttendees[0].attendees = this.availableAttendees[0].attendees.filter(i => i.id !== message.senderId); // remove from bucket
                this._filteredList = this.availableAttendees[0].attendees;
                let groupIndex = this.groups.findIndex(i => i.id === message.id); // put in desired group
                if (groupIndex !== -1)
                    this.groups[groupIndex].attendees.push(user);
            }
            else {
                let groupIndex = this.groups.findIndex(i => i.attendees.find(j => j.id === message.senderId));
                if (groupIndex !== -1) {
                    let userIndex = this.groups[groupIndex].attendees.findIndex(i => i.id === message.senderId);
                    if (userIndex !== -1) {
                        let user = this.groups[groupIndex].attendees[userIndex];
                        user.isHost = false;
                        this.groups[groupIndex].attendees = this.groups[groupIndex].attendees.filter(i => i.id !== message.senderId);
                        groupIndex = this.groups.findIndex(i => i.id === message.id); // put in desired group
                        this.groups[groupIndex].attendees.push(user);
                    }
                }
            }
        }
    }

    ngOnInit() {
        // this is a reused module, the below is used only when this component was called within a mat dialog
        if (this.dialogData) {
            this.disableDrag = this.dialogData['disableDrag'];
            this.hide = this.dialogData['hide'];
            this._attendees = this.dialogData['attendees'];
            this.sessionId = this.dialogData['sessionId'];
            this.selectedUser = this.dialogData['selectedUser'];
            this.disableExpansion = this.dialogData['disableExpansion'];
            this.getBreakouts();
        }

        if (this.sessionData) {
            let registeredAttendees = this.requestService.getSessionRoleByType(this.sessionData, 'attendee');
            this._attendees = registeredAttendees;
        }
        let restructured = [];
        this._attendees.forEach(element => {
            restructured.push({ id: element._id, name: element.name, online: false, registered: true, include: true });
        });
        this.availableAttendees[0].attendees = restructured;
        this._filteredList = this.availableAttendees[0]['attendees'];

        // capture the last z-index used on the layout
        this.subscriptions.push(this.opentokService.zIndexDraggable.subscribe(value => {
            if (value != undefined) {
                this._zIndexTemp = value;
            }
        }));

        // fetch the breakouts with getBreakouts
        this.subscriptions.push(this.activatedRoute.params.subscribe(params => {
            if (params.hasOwnProperty('id') && params.hasOwnProperty('sessionid')) {
                this.sessionId = params['sessionid'];
                this.getBreakouts();
            }
        }));

        this.subscriptions.push(this.opentokService.attendeeJoinedSession.subscribe(value => {
            if (value) {
                // check if user is already assigned to a group
                let groupIndex = this.groups.findIndex(i => i.attendees.find(j => j.id === value['id']));
                if (value.joined) {
                    if (groupIndex === -1) {
                        let findUserInAvailableList = this.availableAttendees[0].attendees.findIndex(i => i.id === value['id']);
                        if (findUserInAvailableList === -1) {
                            let user = { id: value['id'], name: value['name'], online: true };
                            if (this._attendees.findIndex(i => i._id === value['id']) !== -1)
                                user['registered'] = true;
                            else
                                user['registered'] = false;
                            user['include'] = true;
                            user['connection'] = value.connection;
                            this.availableAttendees[0].attendees.push(user);
                        }
                        else {
                            this.availableAttendees[0].attendees[findUserInAvailableList].online = true;
                        }
                    }
                    else {
                        let userIndex = this.groups[groupIndex].attendees.findIndex(i => i.id === value['id']);
                        this.groups[groupIndex].attendees[userIndex].connection = value.connection;
                        this.groups[groupIndex].attendees[userIndex].online = true;
                    }
                }
                else {
                    if (groupIndex === -1) {
                        let findUserInAvailableList = this.availableAttendees[0].attendees.findIndex(i => i.id === value['id']);
                        if (findUserInAvailableList === -1 && !this.availableAttendees[0].attendees[findUserInAvailableList]?.registered) {
                            this.availableAttendees[0].attendees = this.availableAttendees[0].attendees.filter(i => i.id !== value['id']);
                        }
                        else {
                            this.availableAttendees[0].attendees[findUserInAvailableList].online = false;
                        }
                    }
                    else {
                        let userIndex = this.groups[groupIndex].attendees.findIndex(i => i.id === value['id']);
                        this.groups[groupIndex].attendees[userIndex].online = false;
                    }
                }
                this._filteredList = this.availableAttendees[0]['attendees'];
            }
        }));

        this.subscriptions.push(this.schedulerService.startBreakout.subscribe(value => {
            if (value != undefined) {
                if (value.task.action === 'play' ? true : false) {
                    if (!this.isBreakoutStarted && this.session && this.sessionData.active)
                        if (!this.isStreaming)
                            this.saveStartBreakout(value.task, value.index);
                        else {
                            this.zone.run(() => {
                                this.layoutUtilsService.alertActionElement('', this.translate.instant('You cannot start a breakout when one or more people are streaming'), {
                                    overlayClickToClose: true,
                                    showCancelButton: false,
                                    declineText: 'OK'
                                }, 'fit-content');
                                value.task.action = '';
                                // this.schedulerService.callBackScheduler.next({ task: value.task, index: value.index, sendSignal: false });
                                this.schedulerService.findAndUpdateTasks.next({ name: 'breakout', action: '', uniqueId: value.task.uniqueId });
                            });
                        }
                    else
                        this.zone.run(() => {
                            this.layoutUtilsService.alertActionElement('', this.translate.instant('You cannot start a breakout at this time'), {
                                overlayClickToClose: true,
                                showCancelButton: false,
                                declineText: 'OK'
                            }, 'fit-content');
                            value.task.action = '';
                            // this.schedulerService.callBackScheduler.next({ task: value.task, index: value.index, sendSignal: false });
                            this.schedulerService.findAndUpdateTasks.next({ name: 'breakout', action: '', uniqueId: value.task.uniqueId });
                        });
                }
                else
                    if (this.isBreakoutStarted && this.session)
                        this.endBreakout();
                // else
                //     this.zone.run(() => {
                //         this.layoutUtilsService.alertActionElement('', this.translate.instant('You cannot end the breakout at this time'), {
                //             overlayClickToClose: true,
                //             showCancelButton: false,
                //             declineText: 'OK'
                //         }, 'fit-content');
                //         value.task.action = '';
                //         this.schedulerService.callBackScheduler.next({ task: value.task, index: value.index, sendSignal: false });
                // });
            }
        }));

        if (this.isHost) {
            this.ablyService.subscribe('attendeeSelectedAGroup', (data) => {
                let message = JSON.parse(data);
                if (this.isGroupsLocked) {
                    this.zone.run(() => {
                        // if (this.selectionDialog)
                        //     this.selectionDialog.close(false);

                        const dialog = this.dialog.open(ConfirmEntityDialogComponent, {
                            disableClose: true,
                            data: {
                                title: this.translate.instant('Request to join a breakout'),
                                description: this.translate.instant('Incoming request from ' + message.sender + ' to join ' + message.name),
                                confirmbtn: this.translate.instant('Approve'),
                                cancelbtn: this.translate.instant('Reject')
                            }
                        });
                        dialog.afterClosed().subscribe(result => {
                            let payload;
                            if (result !== false) {
                                if (result) {
                                    this.updateLists(message);
                                    // if (this.isBreakoutStarted) {
                                    payload = { id: message.id, senderId: this.selectedUser._id, isBreakoutStarted: this.isBreakoutStarted, accepted: true, group: message.name };
                                    this.saveGroups(undefined, true);
                                    // }
                                }
                                else {
                                    payload = { id: message.id, senderId: this.selectedUser._id, isBreakoutStarted: this.isBreakoutStarted, accepted: false, group: message.name };
                                }
                                this.ablyService.quickPublishToPrivateChannel(message.senderId, 'attendeeSelectedAGroupResponse', payload);
                            }
                        });
                    });
                }
                else {
                    this.updateLists(message);
                }
            }, this.ablyService.getChannel('private-' + this.selectedUser._id));
        }
    }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());

        this.ablyService.getChannel('private-' + this.selectedUser._id).unsubscribe(['attendeeSelectedAGroup']);
    }


    /**
     * Show the dialog increase z-index and center
     *
     * @param {Event} [event]
     * @memberof BreakoutEditComponent
     */
    maximize(event?: Event) {
        if (event)
            event.stopPropagation();
        this.dragged(true);
        this.detectChanges();
        // console.log('onlineAttendees', this.onlineAttendees)
        // detecting the changes before the centered position is calculated
        this._leftPosition = (document.body.offsetWidth / 2) - (this.breakoutDraggable.nativeElement.offsetWidth / 2);
    }

    private detectChanges() {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }


    /**
     *
     * Everytime the dialog is dragged z-index is increased to stay on the top layer
     * @param {boolean} bool
     * @memberof BreakoutEditComponent
     */
    dragged(bool: boolean) {
        if (bool) {
            this._zIndex = this._zIndexTemp + 1;
            this.opentokService.zIndexDraggable.next(this._zIndex);
        }
    }


    /**
     * main function to filter an array by string
     *
     * @memberof BreakoutEditComponent
     */
    filterList() {
        let listToFilter = this.availableAttendees[0]['attendees'];
        if (this.filterByText)
            listToFilter = this._filter(this.filterByText, listToFilter);
        this._filteredList = this.filterByOption(listToFilter);
        this.detectChanges();
    }


    /**
     *
     * Filter an array by string
     * @private
     * @param {string} value
     * @param {*} list
     * @return {*}  {[]}
     * @memberof BreakoutEditComponent
     */
    private _filter(value: string, list: any): [] {
        const filterValue = value.toLowerCase();
        return list.filter(option => option['name'].toLowerCase().indexOf(filterValue) != -1);
    }


    /**
     * Used by the drag and drop to move an item from one array to another
     *
     * @param {CdkDragDrop<string[]>} event
     * @memberof BreakoutEditComponent
     */
    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            event.previousContainer.data[event.previousIndex]['isHost'] = false;
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
    }


    /**
     * Add a new group with default values
     *
     * @param {string} [groupId='G_new-' + Math.floor(Math.random() * 1000)]
     * @param {string} [groupName='Group']
     * @param {string} [started='']
     * @param {*} [attendees=[]]
     * @memberof BreakoutEditComponent
     */
    addGroup(groupId: string = 'G_new-' + Math.floor(Math.random() * 1000), groupName: string = 'Group' + ' ' + (this.groups.length + 1), started: string = '', attendees: any = []) {
        this.groups.unshift({
            id: groupId,
            name: groupName,
            started: started,
            attendees: attendees
        });
        this.connectedTo.push(groupId); // add to the list of array that the drag and drop is listening to
    }


    /**
     *
     * Remove group and put attendees back in the available array
     *
     * @param {string} groupId
     * @memberof BreakoutEditComponent
     */
    removeGroup(groupId: string) {
        this.putAttendeesBackInBasket(groupId);
        this.deletedGroups.push(groupId);
        // remove group
        this.groups = this.groups.filter(i => i.id != groupId);
        this.connectedTo = this.connectedTo.filter(i => i != groupId);
    }


    /**
     * Loop over attendee array in a group and put back in the available array
     *
     * @param {string} groupId
     * @memberof BreakoutEditComponent
     */
    putAttendeesBackInBasket(groupId: string) {
        let putBackToAvailable = this.groups.find(i => i.id === groupId);
        if (putBackToAvailable) {
            putBackToAvailable.attendees.forEach(i => i.isHost = false);
            this.availableAttendees[0]['attendees'] = this.availableAttendees[0]['attendees'].concat(putBackToAvailable.attendees);
            this._filteredList = this.availableAttendees[0]['attendees'];
        }
    }


    /**
     * Dialog that prompts to enter the number of users in a group to auto-assign at a later stage
     *
     * @memberof BreakoutEditComponent
     */
    promptAutoAssignDialog() {
        this.zone.run(() => {
            const dialog = this.dialog.open(ConfirmInputEntityDialogComponent, {
                data: {
                    width: '30vw',
                    title: this.translate.instant('Auto-Assign Attendees'),
                    data: this.fixedNumberOfUsersInGroups,
                    type: 'number',
                    min: '2',
                    cancelbtn: this.translate.instant('Cancel'),
                    confirmbtn: this.translate.instant('Select')
                }
            });
            dialog.afterClosed().subscribe(result => {
                if (result !== undefined && result > 0) {
                    if (result > 0) {
                        this.fixedNumberOfUsersInGroups = result;
                        this.autoAssignBypassGroupNumber(result);
                    }
                    else {
                        this.layoutUtilsService.showNotificationSnack(this.translate.instant('You should use a positive number.'), this.translate.instant('Dismiss'));
                    }
                }
            });
        });
    }


    /**
     * Fix number of groups and distribute attendees on those groups
     *
     * @memberof BreakoutEditComponent
     */
    autoAssignByGroup() {
        let numberOfGroups = this.groups.length;
        if (numberOfGroups > 0) {

            // count number of attendees
            let numberOfAttendees: number = 0;
            this.availableAttendees[0].attendees.forEach(element => {
                if (element.include)
                    numberOfAttendees++;
            });
            this.groups.forEach(i => i.attendees.forEach(j => numberOfAttendees++));

            let maxNumberOfAttendeesInGroup = numberOfAttendees / numberOfGroups;
            if (maxNumberOfAttendeesInGroup > 50)
                this.layoutUtilsService.showNotificationSnack('You should add more groups, group(s) exceeded 50 attendees.', 'Dismiss');
            else {
                let remainder = maxNumberOfAttendeesInGroup % 1;
                let totalToSplitAgain = 0;
                if (remainder > 0) {
                    totalToSplitAgain = remainder * numberOfGroups;
                }

                // put all attendees back in the available list
                for (let i = 0; i < this.groups.length; i++) {
                    this.putAttendeesBackInBasket(this.groups[i].id);
                    this.groups[i].maxNumberofUsers = Math.floor(maxNumberOfAttendeesInGroup);
                }

                // add the remainder to the group max allowed seats up until we reach 0
                for (let i = 0; i < this.groups.length; i++) {
                    if (totalToSplitAgain > 0) {
                        this.groups[i].maxNumberofUsers += 1;
                        totalToSplitAgain--;
                    }
                }

                // prepare ids to be shuffled
                let attendeeIdsToShuffle = [];
                this.availableAttendees[0].attendees.forEach(element => {
                    if (element.include)
                        attendeeIdsToShuffle.push(element.id);
                });

                // randomly order the ids
                let shuffledAttendeeIds = this.shuffle(attendeeIdsToShuffle);

                let shuffledAttendees = [];
                shuffledAttendeeIds.forEach(element => {
                    shuffledAttendees.push(this.availableAttendees[0].attendees.find(i => i.id === element));

                    // remove those that are assigned from the list of available attendees
                    this.availableAttendees[0].attendees = this.availableAttendees[0].attendees.filter(i => i.id !== element);
                });
                this._filteredList = this.availableAttendees[0].attendees;

                // distribute attendees to groups
                this.groups.forEach(element => {
                    element.attendees = shuffledAttendees.slice(0, element.maxNumberofUsers);
                    if (element.attendees.length) {
                        let randomIndex = Math.floor(Math.random() * element.attendees.length);
                        element.attendees[randomIndex].isHost = true;
                    }
                    shuffledAttendees = shuffledAttendees.slice(element.maxNumberofUsers);
                });
            }
        }
        else {
            this.layoutUtilsService.showNotificationSnack('You should add at least one group', 'Dismiss');
        }
    }


    /**
     * Assign attendees by fixing number of users and add/removing groups to fit all
     *
     * @param {number} fixedNumberOfUsers
     * @memberof BreakoutEditComponent
     */
    autoAssignBypassGroupNumber(fixedNumberOfUsers: number) {
        if (fixedNumberOfUsers) {
            // get the max number of groups required, count attendees
            let numberOfAttendees: number = 0;
            this.availableAttendees[0].attendees.forEach(element => {
                if (element.include)
                    numberOfAttendees++;
            });
            this.groups.forEach(i => i.attendees.forEach(j => numberOfAttendees++));

            let numberOfGroupsNeeded = Math.ceil(numberOfAttendees / fixedNumberOfUsers);
            let existingGroups = [];
            for (let i = 0; i < this.groups.length; i++) {
                existingGroups.push(this.groups[i].id);
            }

            for (let i = 0; i < existingGroups.length; i++) {
                this.removeGroup(existingGroups[i]);
            }

            for (let i = 0; i < numberOfGroupsNeeded; i++) {
                this.addGroup();
            }

            let attendeeIdsToShuffle = [];
            this.availableAttendees[0].attendees.forEach(element => {
                if (element.include)
                    attendeeIdsToShuffle.push(element.id);
            });

            // randomly order the ids
            let shuffledAttendeeIds = this.shuffle(attendeeIdsToShuffle);

            let shuffledAttendees = [];
            shuffledAttendeeIds.forEach(element => {
                shuffledAttendees.push(this.availableAttendees[0].attendees.find(i => i.id === element));

                // remove those that are assigned from the list of available attendees
                this.availableAttendees[0].attendees = this.availableAttendees[0].attendees.filter(i => i.id !== element);
                this._filteredList = this.availableAttendees[0].attendees;
            });

            // distribute attendees to groups
            this.groups.forEach(element => {
                element.attendees = shuffledAttendees.slice(0, fixedNumberOfUsers);
                let randomIndex = Math.floor(Math.random() * element.attendees.length);
                element.attendees[randomIndex].isHost = true;
                shuffledAttendees = shuffledAttendees.slice(fixedNumberOfUsers);
            });
        }
    }


    /**
     * Shuffles an array by using a randomly generated index
     *
     * @param {*} array
     * @return {*}
     * @memberof BreakoutEditComponent
     */
    shuffle(array) {
        var currentIndex = array.length, temporaryValue, randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex -= 1;

            // And swap it with the current element.
            temporaryValue = array[currentIndex];
            array[currentIndex] = array[randomIndex];
            array[randomIndex] = temporaryValue;
        }

        return array;
    }


    /**
     * Highlight the is host flag only if the breakouts haven't started yet
     *
     * @param {boolean} show
     * @param {number} index
     * @param {string} groupId
     * @memberof BreakoutEditComponent
     */
    highlightFlag(show: boolean, index: number, groupId: string) {
        if (!this.breakoutStartedAt) {
            let groupIndex = this.groups.findIndex(i => i.id === groupId);
            if (groupIndex !== -1)
                if (this.groups[groupIndex].attendees[index] && !this.groups[groupIndex].attendees[index].isHost)
                    this.groups[groupIndex].attendees[index].isFlagHighlight = show;
        }
    }


    /**
     * Mark a user as a host update flag
     *
     * @param {number} index
     * @param {string} groupId
     * @memberof BreakoutEditComponent
     */
    markAsHost(index: number, groupId: string) {
        if (!this.breakoutStartedAt) {
            let groupIndex = this.groups.findIndex(i => i.id === groupId);
            if (groupIndex !== -1)
                if (this.groups[groupIndex].attendees.length >= 1) {
                    let currentFlagStatus = this.groups[groupIndex].attendees[index].isHost;

                    this.groups[groupIndex].attendees.forEach(i => i.isHost = false);
                    this.groups[groupIndex].attendees.forEach(i => i.isFlagHighlight = false);

                    this.groups[groupIndex].attendees[index].isHost = !currentFlagStatus;
                }
        }
    }


    /**
     * Save or update groups in DB
     *
     * @param {() => void} [callback]
     * @memberof BreakoutEditComponent
     */
    saveGroups(callback?: () => void, byPassCheckOnHost: boolean = false) {

        let findGroupWithNoHost = false;
        this.groups.forEach(element => {
            if (element.attendees.length > 0 && element.attendees.findIndex(i => i.isHost) === -1) {
                findGroupWithNoHost = true;
            }
        });

        if (findGroupWithNoHost && !byPassCheckOnHost)
            this.layoutUtilsService.showNotificationSnack(this.translate.instant('Some groups do not have a host.'), this.translate.instant('Dismiss'));
        else {
            this.persistDB(callback);
        }
    }


    /**
     * Persist group changes to DB
     *
     * @param {() => void} [callback]
     * @memberof BreakoutEditComponent
     */
    persistDB(callback?: () => void) {
        this.loading = true;
        this.deletedGroups.forEach(id => {
            this.requestService.deleteBreakout({ _id: id }, (data, error) => { });
        });
        // let ids: any = [this.sessionData._id];
        this.ablyService.sendSessionSignal(this.sessionId, 'lockBreakouts', this.isGroupsLocked + '');

        this.groups.forEach(element => {
            let users = [];

            element.attendees.forEach(attendee => {
                let role = 'attendee';
                if (attendee.isHost)
                    role = 'host';
                users.push({ userId: attendee.id, role: role });
            });

            let timer = 1;
            if (this.breakoutTimer)
                timer = this.breakoutTimer;

            if (element.id.indexOf('G_new-') === -1) {
                //update
                this.requestService.updateBreakout({ _id: element.id, name: element.name, sessionId: this.sessionId, timer: timer, freeSelection: !this.isGroupsLocked, started: element.started, users: users }, (data, error) => {
                    this.loading = false;
                });
            }
            else {
                this.requestService.saveData('breakout/create', { name: element.name, sessionId: this.sessionId, timer: timer, freeSelection: !this.isGroupsLocked, users: users, type: 'group' }, (data, error) => {
                    if (data)
                        element.id = data.results._id;
                    this.loading = false;
                    // this.layoutUtilsService.showNotificationSnack(this.translate.instant('Save Successfully'), this.translate.instant('Dismiss'));
                });
            }
        });
        this.loading = false;
        if (callback)
            callback();
        this.layoutUtilsService.showNotificationSnack(this.translate.instant('Saved Successfully'), this.translate.instant('Dismiss'));
        this.opentokService.breakoutGroups.next(this.groups);
    }

    /**
     * Save groups and start a breakout using a callback method
     * Method will also send signals
     *
     * @memberof BreakoutEditComponent
     */
    saveStartBreakout(schedulerTask?: any, schedulerIndex?: number) {
        if (!this.isStreaming) {
            let findGroupWithNoHost = false;
            this.groups.forEach(element => {
                if (element.attendees.findIndex(i => i.isHost) === -1) {
                    findGroupWithNoHost = true;
                }
            });

            if (findGroupWithNoHost) {
                this.layoutUtilsService.showNotificationSnack(this.translate.instant('Assign a host to start the breakouts.'), this.translate.instant('Dismiss'));
                if (schedulerTask && schedulerIndex) {
                    schedulerTask.action = 'play';
                    this.schedulerService.callBackScheduler.next({ task: schedulerTask, index: schedulerIndex, sendSignal: false });
                }
            }
            else {
                this.breakoutStartedAt = moment.utc();
                this.groups.forEach(i => i.started = this.breakoutStartedAt);
                this.persistDB(() => {
                    this.isBreakoutStarted = true;
                    let breakoutHosts = [];
                    this.groups.map(group => group.attendees.map(attendee => {
                        if (attendee.isHost)
                            breakoutHosts.push({
                                userId: attendee.id,
                                breakout: true,
                                breakoutId: group.id,
                                type: 'breakout'
                            });
                    }));
                    this.requestService.createMultipleSessions(breakoutHosts, this.sessionId, (data, error) => {
                        // this.opentokService.sendSignalToBreakoutAttendees('startBreakout', this.groups, this.session); // send the signal through the current 
                        // session to all online attendees to join their breakouts
                        this.groups.forEach(element => {
                            element.attendees.forEach(attendee => {
                                // if (attendee.connection && attendee.online) {
                                this.ablyService.quickPublishToPrivateChannel(attendee.id, 'startBreakout', { breakoutId: element.id, sender: this.selectedUser._id, roomId: this.roomData._id, sessionId: this.sessionId });
                                // this.sendSignal(message, element.id, session, attendee.connection);
                                // }
                            });
                            // let findGroup = groups.find(i => i.attendees.find(j => j.id === element.id));
                            // if (findGroup && element.connection)
                            //   this.sendSignal(message, findGroup.id, session, element.connection);
                        });
                        this.countDownForBreakout(moment(this.breakoutStartedAt).add(this.breakoutTimer, 'm').local()); // start the count down before ending the breakout
                    });
                });
            }
        }
    }


    /**
     * Fetch from DB the breakouts and initiate variables
     *
     * @memberof BreakoutEditComponent
     */
    getBreakouts() {
        this.loading = true;
        this.requestService.getBreakoutsPerSession(this.sessionId, 'group', (data, error) => {
            if (data && data.length) {
                data.forEach(element => {
                    this.breakoutTimer = element.timer;

                    let attendees: any = [];
                    element.users.forEach(attendee => {
                        let isHost = false;
                        if (attendee.role === 'host')
                            isHost = true;

                        let userObj = this.availableAttendees[0].attendees.find(i => i.id === attendee.userId);

                        this.availableAttendees[0].attendees = this.availableAttendees[0].attendees.filter(i => i.id !== attendee.userId);
                        this._filteredList = this.availableAttendees[0]['attendees'];
                        if (userObj) {
                            let user = { id: attendee.userId, isHost: isHost, name: userObj.name };
                            if (this.availableAttendees[0].attendees.findIndex(i => i.id === attendee.userId) !== -1)
                                user['online'] = true;
                            else
                                user['online'] = false;
                            if (this._attendees.findIndex(i => i._id === attendee.userId) !== -1)
                                user['registered'] = true;
                            else
                                user['registered'] = false;

                            user['include'] = true;
                            attendees.push(user);
                        }
                    });
                    this.addGroup(element._id, element.name, element.started, attendees);
                    if (moment(element.started).add(element.timer, 'm').isAfter(moment().utc())) {
                        this.breakoutStartedAt = element.started;
                    }
                    else {
                        this.breakoutStartedAt = '';
                    }
                    // console.log('u attendees', attendees)
                    this.isGroupsLocked = !element.freeSelection;
                    this.loading = false;
                });
                if (this.breakoutStartedAt) {
                    this.isBreakoutStarted = true;
                    this.countDownForBreakout(moment(this.breakoutStartedAt).add(this.breakoutTimer, 'm').local());
                }
                else {
                    this.isBreakoutStarted = false;
                    this.groups.forEach(i => i.started = '');
                    // this.saveGroups();
                }
                this.opentokService.breakoutGroups.next(data);
                // this.opentokService.breakoutStatus.next(this.breakoutStarted);
            }
            else {
                this.loading = false;
            }
        });
    }


    /**
     * End the breakout, clear variables and send signals
     *
     * @memberof BreakoutEditComponent
     */
    endBreakout() {
        this.counterSubscription?.unsubscribe();
        this.countDownTextDay = undefined;
        this.countDownTextHour = undefined;
        this.countDownTextMin = undefined;
        this.countDownTextSec = undefined;

        this.breakoutStartedAt = '';
        this.groups.forEach(i => i.started = '');

        // let breakoutIds: any = [];
        // this.groups.forEach(i => breakoutIds.push(i.id));
        this.saveGroups(() => {
            this.isBreakoutStarted = false;
            // sending through the server which would send a signal to the breakout sessions to go back to the main session. This same signal is also sent through the global session in case the users were not in their breakouts for any reason
            // let ids: any = [this.sessionData._id];
            this.ablyService.sendSessionSignal(this.sessionId, 'endBreakout', '1');
            // this.opentokService.sendSignalThroughServer(ids, 'endBreakout', '1');
            // this.opentokService.sendSignal('endBreakout', '1', this.opentokService.globalSession);
        }, true);
    }


    /**
     * Countdown used by producer to see the remaining time for breakouts before automatically ending and sending the signal
     *
     * @private
     * @param {*} dateStr
     * @return {*}  {*}
     * @memberof BreakoutEditComponent
     */
    private countDownForBreakout(dateStr): any {
        let countDownDate = new Date(dateStr).getTime();
        if (countDownDate - new Date().getTime() > 0) {
            this.counterSubscription = this.opentokService.getCounter(this.tick).subscribe(() => {
                let distance = countDownDate - new Date().getTime();
                let days = Math.floor(distance / (1000 * 60 * 60 * 24));
                let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                let seconds = Math.floor((distance % (1000 * 60)) / 1000);

                this.countDownTextDay = days;
                this.countDownTextHour = hours;
                this.countDownTextMin = minutes;
                this.countDownTextSec = seconds;

                if (distance <= 0) {
                    this.endBreakout();
                }
                this.detectChanges();
            });
        }
    }


    /**
     * Redirect producer to a breakout session
     *
     * @param {string} breakoutId
     * @memberof BreakoutEditComponent
     */
    joinBreakout(breakoutId: string) {
        this.zone.run(() => {
            this.router.navigateByUrl('/loading', { skipLocationChange: true }).then(() =>
                this.router.navigate(['/rooms/' + this.roomData._id + '/sessions/' + this.sessionId + '/breakout/' + breakoutId]));
        });
    }


    /**
     * Lock groups and disable users from choosing their groups
     *
     * @param {boolean} bool
     * @memberof BreakoutEditComponent
     */
    lockGroups(bool: boolean) {
        this.isGroupsLocked = !bool;
        this.groups.forEach(i => i.freeSelection = bool);
        this.saveGroups(() => {
            // this.opentokService.sendSignal('lockBreakouts', this.isGroupsLocked + '', this.session);
        });
    }


    /**
     * Close modal used by pages where this component is used in a dialog
     *
     * @memberof BreakoutEditComponent
     */
    closeModal(): void {
        this.dialogRef.close();
    }


    /**
     * filter by registered and online checkboxes
     *
     * @private
     * @param {*} list
     * @return {*}
     * @memberof BreakoutEditComponent
     */
    private filterByOption(list: any) {
        let filteredList = [];
        if (this.filterByRegistered && this.filterByOnline) {
            filteredList = list.filter(option => option['registered'] === true || option['online'] === true);
        }
        else if (this.filterByRegistered && !this.filterByOnline) {
            filteredList = list.filter(option => option['registered'] === true);
        }
        else if (!this.filterByRegistered && this.filterByOnline) {
            filteredList = list.filter(option => option['online'] === true);
        }
        else {
            filteredList = list.filter(option => !option['registered'] && !option['online']);
        }
        return filteredList;
    }
}
