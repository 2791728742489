<form #personForm="ngForm" *ngIf="data" class="customDialog">
	<div class="col-xl-12" *ngIf="data">
		<h1 mat-dialog-title [class.titleMobile]="isMobile">{{data.title}}</h1>
		<div mat-dialog-content>
			<div *ngIf="data.modalSetting">
				<mat-card *ngIf="data.modalSetting.fields">
					<!-- <mat-card-header>
						<mat-card-title>{{data.type}}</mat-card-title>
					</mat-card-header> -->
					<mat-card-content>
						<div class="form-group">
							<div *ngFor="let col of data.modalSetting.fields" class="fieldItem">
								<div
									*ngIf="col.type !== 'action' && (col.editable || !col.generated) && col.creatable && !col.nullable && col.registration">
									<!-- <label class="control-label" *ngIf="col.name">{{col.displayName}}<span *ngIf="col.required || !col.generated"> *</span>: </label> -->
									<span class="fieldInput">
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'string'">

											<input matInput [required]="!col.nullable" name="{{col.name}}"
												ng-model="col.name" [(ngModel)]="data.data[col.name]"
												[required]="!col.nullable" placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher">
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
										</mat-form-field>
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'number'">
											<input matInput type="number" [required]="!col.nullable" name="{{col.name}}"
												ng-model="col.name" [(ngModel)]="data.data[col.name]"
												[required]="!col.nullable" placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher">
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
										</mat-form-field>
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'email'">
											<input matInput type="email" [required]="!col.nullable" name="{{col.name}}"
												ng-model="col.name" [(ngModel)]="data.data[col.name]"
												[required]="!col.nullable" placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher"
												pattern="^\w+([\.\+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$"
												[disabled]="data.data._id && col.unique">
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
										</mat-form-field>
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'url'">
											<input matInput type="text" [required]="!col.nullable" name="{{col.name}}"
												ng-model="col.name" [(ngModel)]="data.data[col.name]"
												[required]="!col.nullable" placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher"
												pattern="^((http[s]?):\/)+\/?([^:\/\s]?)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$"
												[disabled]="data.data._id && col.unique">
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
										</mat-form-field>
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'phone'">
											<input matInput type="phone" [required]="!col.nullable" name="{{col.name}}"
												ng-model="col.name" [(ngModel)]="data.data[col.name]"
												[required]="!col.nullable" placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher">
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
										</mat-form-field>
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'color'">
											<input matInput type="color" [required]="!col.nullable" name="{{col.name}}"
												ng-model="col.name" [(ngModel)]="data.data[col.name]"
												[required]="!col.nullable" placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher">
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
										</mat-form-field>
										<mat-form-field *ngIf="col.type === 'password'">
											<input matInput type="password" maxlength="64" minlength="8"
												[required]="!col.nullable" name="{{col.name}}" ng-model="col.name"
												[(ngModel)]="data.data[col.name]" [required]="!col.nullable"
												placeholder="{{col.displayName | translate}}"
												[errorStateMatcher]="esMatcher">
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
											<mat-error
												*ngIf="data.data[col.name] && (data.data[col.name].length < 8 || data.data[col.name].length > 64)">
												{{col['displayName'] | translate}} should be of at least 8 characters
												and maximum 64 characters.
											</mat-error>
										</mat-form-field>
										<div *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'textarea'">
											<mat-form-field>
												<textarea matInput placeholder="{{col['displayName'] | translate}}"
													name="{{col['name']}}" [required]="!col['nullable']"
													maxlength="1000" [(ngModel)]="data.data[col.name]"
													ng-model="col['name']" cdkTextareaAutosize cdkAutosizeMinRows="3"
													cdkAutosizeMaxRows="5" [errorStateMatcher]="esMatcher">
													</textarea>
												<mat-error
													*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
													{{col['displayName'] | translate}} is required.
												</mat-error>
											</mat-form-field>
										</div>
										<div *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'boolean'"
											style="margin-bottom:15px;">
											<mat-checkbox *ngIf="col.name!== 'privacyterm'" class="example-margin"
												name="{{col.name}}" [(ngModel)]="data.data[col.name]">
												{{col.displayName}}</mat-checkbox>
											<div style="margin-top:10px" *ngIf="col.name=== 'privacyterm'">
												<mat-checkbox class="example-margin" name="{{col.name}}"
													[(ngModel)]="data.data[col.name]">I Agree to the <a
														href="https://www.interactivelife.com/privacy"
														rel="noopener noreferrer" target="_blank">Privacy Terms</a>
												</mat-checkbox>
											</div>
										</div>
										<div *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'textview'"
											style="padding:20px 0px;color: #878787;">
											{{col.value}}
										</div>
										<div *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'tags'">
											<app-custom-tags [parentFormSubmitted]="isSubmitted"
												[value]="data.data[col.name]" [required]="!col.nullable"
												[placeholder]="col.displayName" [canAdd]="true"
												(onSelectReturn)="setAttribute(col.name, $event)" [itemName]="col.name"
												[canDelete]="true" [dataTypeParent]="data.dataType"
												[tagType]="col.tagtype" [dataTypeParentDisplay]="data.dataType">
											</app-custom-tags>
										</div>
										<div
											*ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'reference'">
											<app-custom-select
												*ngIf="data.data[col.name] && col.reference.kind === 'single'"
												[parentFormSubmitted]="isSubmitted" [value]="data.data[col.name]['_id']"
												[itemName]="col.name" [required]="!col.nullable"
												[dataType]="col.reference.to" [apiTarget]="col.reference.api"
												[placeholder]="col.displayName"
												(onRoleSelectReturn)="setRoleReferenceAttribute(col.name, $event)"
												(onSelectReturn)="setReferenceAttribute(col.name, '_id', $event)">
											</app-custom-select>
											<app-custom-select
												*ngIf="data.data[col.name] && col.reference.kind === 'multiple' && col.name === 'resources'"
												[parentFormSubmitted]="isSubmitted"
												[value]="data.data[col.name][0]['_id']" [itemName]="col.name"
												[required]="!col.nullable" [dataType]="col.reference.to"
												[apiTarget]="col.reference.api" [placeholder]="col.displayName"
												(onRoleSelectReturn)="setRoleReferenceAttribute(col.name, $event)"
												(onSelectReturn)="setReferenceAttribute(col.name, '_id', $event)">
											</app-custom-select>
											<app-custom-multiple-select
												*ngIf="data.data[col.name] && col.reference.kind === 'multiple' && col.name !== 'resources'"
												[value]="data.data[col.name]" [itemName]="col.name"
												[required]="!col.nullable" [dataType]="col.reference.to"
												[apiTarget]="col.reference.api" [placeholder]="col.displayName"
												(onSelectReturn)="setMultipleReferenceAttribute(col.name, $event)">
											</app-custom-multiple-select>
										</div>
										<div *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'datetime'">
											<app-custom-datetime [value]="data.data[col.name]" [metadata]="col"
												(dateChange)="data.data[col.name] = $event"></app-custom-datetime>
										</div>
										<div *ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'date'">
											<mat-form-field>
												<input matInput name="{{col.name}}" [required]="!col.nullable"
													[(ngModel)]="data.data[col.name]" ng-model="col.name"
													[matDatepicker]="picker"
													placeholder="{{col.displayName| translate}}"
													(dateChange)="setDateAttribute(col.name, $event.value)"
													[errorStateMatcher]="esMatcher">
												<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
												<mat-datepicker #picker></mat-datepicker>
												<mat-error
													*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
													{{col.displayName| translate}} is required.
												</mat-error>
											</mat-form-field>

										</div>
										<mat-form-field
											*ngIf="(col.visible || (col.admin && isAdmin)) && col.type === 'enum'"
											class="enumClass">
											<input [style.visibility]="'hidden'" matInput name="{{col.name}}"
												[(ngModel)]="data.data[col.name]" [required]="!col.nullable"
												[errorStateMatcher]="esMatcher">
											<mat-label>{{col.displayName | translate}}</mat-label>
											<mat-select ng-model="col.name" [required]="!col.nullable"
												(selectionChange)="setAttribute(col.name, $event.value)"
												[value]="data.data[col.name]">
												<!-- <mat-option value="" >Select {{col.displayName | translate}}</mat-option> -->
												<mat-option *ngFor="let itm of col.enum" [value]="itm.value">
													{{itm.displayName | translate}}</mat-option>
											</mat-select>
											<mat-icon *ngIf="data.data[col.name]"
												(click)="clearSimpleValue($event, col.name)" class="cancelIcon"
												matTooltip="{{'Clear' | translate}}" matTooltipClass="tooltip-red">
												close</mat-icon>
											<mat-error
												*ngIf="!col.nullable && (data.data[col.name] === undefined || data.data[col.name] === '')">
												{{col.displayName | translate}} is required.
											</mat-error>
										</mat-form-field>
									</span>
								</div>
							</div>
						</div>
					</mat-card-content>
				</mat-card>
			</div>
		</div>
		<div mat-dialog-actions>
			<!-- <span *ngIf="data.modalSetting && !data.modalSetting.hasOwnProperty('footer')"> -->
			<button color="danger" mat-raised-button (click)="closeModal(undefined)">{{'Cancel' | translate}}</button>
			<button *ngIf="!data.data._id" mat-raised-button (click)="saveData('create')"
				[disabled]="loading">{{'Continue' | translate}}</button>
		</div>
		<br />
	</div>
</form>