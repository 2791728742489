import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Dictionary } from './interface';
import { ContentType } from './enums';
import { StoreService } from './store.service';
import { UserActivityService } from './userActivity.service';
import { Utils } from '../helpers/utils';
import { guid } from '../helpers';
import * as pls from 'pls';
import { NgxPermissionsService, NgxRolesService } from '../modules/ngx-permissions';
import { urlSafeBase64Encoding } from '../helpers';
import { FeaturesPermissionService } from './featuresPermission.service';
import { EventTrack } from './models/event-tracking.model';
import { EventType } from './enums/eventType';
import { callbackify } from 'util';

@Injectable()
export class RequestService {
  protected authURL = environment.serverUrl;
  protected authCASURL = environment.casUrl;
  protected productId = environment.productId;
  protected quuURL = environment.serverQuuUrl;
  protected orgType = environment.orgType;
  protected loading: boolean = false;
  protected token: any = '';
  protected userType: string = 'default';
  public version: string = '0.0';
  public loginEnteredIds = undefined;
  public orgId = undefined;
  public appId = undefined;
  public locId = undefined;
  public autoLogin: boolean = false; // used to know if we are accessing the site login with email
  public lang = 'en';
  public serverUrlNoSlash = environment.serverUrlNoSlash;
  public vimeoApiKey = environment.vimeo.apiKey;
  public environmentserverHostUrl = environment.serverHostUrl;
  public serverHostUrl = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
  public appStatusSubject: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  public parentSubject: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  public newAppVersion: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public authenticatedUser: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public pageOrganization: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  protected cachedObj = {};
  public currentUserSubject: BehaviorSubject<any | undefined> = new BehaviorSubject<any | undefined>(undefined);
  public _currentUser: any | undefined = undefined;
  set currentUser(currentUser: any | undefined) {
    if (currentUser) {
      this._currentUser = currentUser;
      let userObject: any = currentUser;
      this.userType = currentUser.type || 'default';
      this.store.init('default_' + userObject._id);
      this.setToken(userObject.token);
      this.currentUserSubject.next(userObject);
      if (userObject['uisettings']) {
        this.featuresPermissionService.setUpUISettings(userObject.uisettings);
      }
      // this.store.set('te', 'temp');
      this.permissionsService.flushPermissions();
      // for (let res of userObject.resources) {
      //   this.permissionsService.addPermission(res._id);
      // }
    } else {
      this._currentUser = undefined;
      this.orgId = undefined;
      this.currentUserSubject.next(undefined);
      this.token = '';
      this.userType = 'default';
      this.store.init('default');
      this.permissionsService.flushPermissions();
    }
  }
  get currentUser(): any | undefined {
    return this._currentUser;
  }
  public updateCurrentUser(newData: any) {
    let objectUser = Object.assign(this._currentUser, newData);
    this.currentUser = objectUser;
  }
  public updatePermissions(resources: any) {
    this.permissionsService.flushPermissions();
    this.permissionsService.addPermission(resources._id);
  }
  public updateUserData(key, value) {
    let userObject: any = this.currentUserSubject.getValue();
    // let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
    userObject[key] = value;
    localStorage.setItem('currentUser', JSON.stringify(userObject));
    this.currentUserSubject.next(userObject);
  }
  constructor(protected utils: Utils, public store: StoreService, protected router: Router, protected http: HttpClient,
    protected permissionsService: NgxPermissionsService, protected userActivityService: UserActivityService, protected featuresPermissionService: FeaturesPermissionService
  ) {
    this.store.init('Anonomous');
    this.holdVersion();
  }
  public loggedIn() {
    if (this.currentUser) {
      return true;
    } else {
      return false;
    }
  }
  public getUserType() {
    return this.userType;
  }
  public getUserId() {
    if (this.currentUser && this.currentUser.hasOwnProperty('_id')) {
      return this.currentUser['_id'];
    } else {
      return '';
    }
  }
  holdVersion() {
    if (localStorage.getItem('v')) {
      let versionData = JSON.parse(localStorage.getItem('v'));
      this.version = versionData;
    }
  }
  sendPostMessage(message = ''): void {
    if (this.parentSubject.getValue()) {
      let targetOrigin = '*'; // Set the target origin accordingly
      let parentWindow = this.parentSubject.getValue();
      if (parentWindow) {
        parentWindow.postMessage(message, targetOrigin);
      }
    }
  }
  public setVersion(version: any, showdialog: boolean = false) {
    if (localStorage.getItem('v')) {
      if (showdialog) {
        localStorage.setItem('v', JSON.stringify(version));
      }
    } else {
      localStorage.setItem('v', JSON.stringify(version));
    }
  }
  public setToken(token: any) {
    this.token = token;
  }
  public getToken() {
    return this.token;
  }
  public redirectTo(uri: string) {
    this.router.navigateByUrl('/loading', { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }
  public addLanguageToURL(url: string, lang?: string): string {
    // if (url) {
    //   let langEnd = lang;
    //   if (langEnd === undefined) {
    //       langEnd = 'en';
    //   }
    //   if (~url.indexOf('?')) {
    //     url += '&locale=' + langEnd;
    //   } else {
    //     url += '?locale=' + langEnd;
    //   }
    //   return url;
    // } else {
    //   return '';
    // }
    return url;
  }
  public getBrand() {
    let serverHostUrl = this.environmentserverHostUrl.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];
    let currentHostname = (window.location.hostname).replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];
    if (currentHostname !== 'localhost' && serverHostUrl !== currentHostname) {
      let remainingLink = currentHostname.replace(serverHostUrl, "");
      remainingLink = remainingLink.replace('.', "");
      return remainingLink;
    }
    return undefined;
  }
  public getDataFromList(lst: any[], idKey: string = '_id'): string[] {
    let dataList = [];
    if (lst) {
      for (let itm of lst) {
        if (itm.hasOwnProperty(idKey)) {
          dataList.push(itm[idKey]);
        }
      }
    }
    return dataList;
  }
  public getDataFromListContains(lst: any[], val: string[], idKey: string = '_id'): any[] {
    let dataList = [];
    for (let itm of lst) {
      if (itm.hasOwnProperty(idKey) && val.includes(itm[idKey])) {
        dataList.push(itm);
      }
    }
    return dataList;
  }
  public getItemFromListContains(lst: any[], val: string, idKey: string = '_id'): any {
    if (lst) {
      for (let itm of lst) {
        if (itm.hasOwnProperty(idKey) && itm[idKey] === val) {
          return itm;
        }
      }
    }
    return undefined;
  }
  public checkListContains(lst: any[], val: string, idKey: string = '_id'): boolean {
    for (let itm of lst) {
      if (itm.hasOwnProperty(idKey) && itm[idKey] === val) {
        return true;
      }
    }
    return false;
  }
  public logout(redirect = true, showDialog = false, notExist = false, noURLReturn = false) {
    this.userActivityService.insertLog('logout', 'logout', 'RequestService', { redirect, showDialog });
    this.token = '';
    localStorage.removeItem('currentUser');
    localStorage.removeItem('o');
    localStorage.removeItem('org');
    localStorage.removeItem('a');
    localStorage.removeItem('l');
    localStorage.removeItem('isOwner');
    sessionStorage.removeItem('live');
    // this.logOutApi();// don't enable this, cause a signal is being sent by backend
    // sessionStorage.clear()
    this.appStatusSubject.next(undefined);
    this.authenticatedUser.next(false);
    this.currentUser = undefined;
    if (redirect) {
      if (sessionStorage.getItem('loginEnteredIds')) {
        let loginEnteredIds = JSON.parse(sessionStorage.getItem('loginEnteredIds'));
        let orgId = loginEnteredIds.orgId;
        if (loginEnteredIds.hasOwnProperty('appId') && loginEnteredIds.hasOwnProperty('locId') && loginEnteredIds.hasOwnProperty('lang')) {
          let appId = loginEnteredIds.appId;
          let locId = loginEnteredIds.locId;
          let lang = loginEnteredIds.lang;
          if (showDialog) {
            this.sendPostMessage('stellar:logout');
            this.router.navigate(['/loginWithDialog', orgId, appId, locId, lang]);
          } else if (notExist) {
            this.sendPostMessage('stellar:logout');
            this.router.navigate(['/login', orgId, appId, locId, lang]);
          } else {
            if (noURLReturn) {
              this.sendPostMessage('stellar:logout');
              this.router.navigate(['/login', orgId, appId, locId, lang]).then(() => {
                window.location.reload();
              });
            } else {
              window.location.reload();
            }
          }
        } else {
          if (showDialog) {
            this.sendPostMessage('stellar:logout');
            this.router.navigate(['/loginWithDialog', orgId]);
          } else if (notExist) {
            this.sendPostMessage('stellar:logout');
            this.router.navigate(['/login', orgId]);
          } else {
            if (noURLReturn) {
              this.sendPostMessage('stellar:logout');
              this.router.navigate(['/login', orgId]).then(() => {
                window.location.reload();
              });
            } else {
              window.location.reload();
            }
          }
        }
      } else {
        if (showDialog) {
          this.sendPostMessage('stellar:logout');
          this.router.navigate(['/loginWithDialog']);
        } else if (notExist) {
          this.sendPostMessage('stellar:logout');
          this.router.navigate(['/login']);
        } else {
          if (noURLReturn) {
            this.sendPostMessage('stellar:logout');
            this.router.navigate(['/login']).then(() => {
              window.location.reload();
            });
          } else {
            window.location.reload();
          }
        }
      }
    }
  }
  public logOutApi(message: string = '', noURLReturn = false) {
    if (!this.loading && this.token !== '') {
      this.userActivityService.insertLog('logout', 'logOutApi', message);
      this.loading = true;
      let data = {};
      let urlStr = this.authCASURL + 'user/logout';
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          //do nothing
          this.logout(true, false, false, noURLReturn);
        }
        if (jsonObj) {
          this.logout(true, false, false, noURLReturn);
        }
        this.loading = false;
      }, 'POST', data, ContentType.JSON, 10.0, false, 1.5, 60.0, false, true, true);
    }
  }
  public getMe(callback: (dataResponse: any | undefined,
    requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'resource/me/' + this.orgId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          let userObject = jsonObj.results;
          callback(userObject, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, 'Data error from server ');
      }
    });
  }
  public loginWithToken(token, callback: (dataResponse: any | undefined,
    requestError: any | undefined) => void, lang?: string) {
    this.token = token;
    let urlStr = this.authURL + 'resource/me/' + this.orgId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          let userObject = jsonObj.results;
          if (jsonObj.hasOwnProperty('token')) {
            this.setToken(jsonObj.token);
            userObject['token'] = jsonObj.token;
          }
          callback(userObject, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, 'Data error from server ');
      }
    });
  }
  public requestLoginOld(username: string, password: string, callback: (dataResponse: any | undefined,
    requestError: any | undefined, returnIt: boolean) => void, lang?: string) {
    let encodedPassword = urlSafeBase64Encoding(password); // make it from backend
    let uuid = guid();
    let urlStr = this.authURL + 'resource/user/login?email=' + encodeURIComponent(username.trim()) + '&password=' + encodedPassword + '&vertical=' + this.orgType + '&uuid=' + uuid;
    if (this.orgId) {
      urlStr += '&organizationId=' + this.orgId;
    }
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        //console.log(error);
        if (jsonObj && jsonObj.hasOwnProperty('return')) {
          callback(undefined, error, jsonObj.return);
        } else {
          callback(undefined, error, false);
        }
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          let userObject = jsonObj.results;
          if (jsonObj.hasOwnProperty('token')) {
            this.logTrackEvent(new EventTrack(EventType.SIGNIN, undefined, undefined, undefined, undefined, undefined, jsonObj.results._id, jsonObj.results.name, jsonObj.results.email), (res, err) => { });
            this.setToken(jsonObj.token);
            userObject['token'] = jsonObj.token;
          }
          callback(userObject, undefined, false);
        } else {
          if (jsonObj.hasOwnProperty('return')) {
            callback(undefined, jsonObj, jsonObj.return);
          } else {
            callback(undefined, jsonObj, false);
          }
        }
      } else {
        callback(undefined, 'Data error from server ', false);
      }
    });
  }
  public requestLogin(username: string, password: string, callback: (dataResponse: any | undefined,
    requestError: any | undefined, returnIt: boolean) => void, lang?: string) {
    let encodedPassword = urlSafeBase64Encoding(password); // make it from backend
    let uuid = guid();
    let objData = {
      "productId": this.productId,
      "userName": username.trim(),
      "password": encodedPassword,
      "organizationId": this.orgId,
      "uuid": uuid
    }
    let urlStr = this.authCASURL + 'user/login';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        //console.log(error);
        if (jsonObj && jsonObj.hasOwnProperty('return')) {
          callback(undefined, error, jsonObj.return);
        } else {
          callback(undefined, error, false);
        }
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          let userObject = jsonObj.results;
          if (jsonObj.hasOwnProperty('token')) {
            if (!userObject.changepassword) {
              this.logTrackEvent(new EventTrack(EventType.SIGNIN, undefined, undefined, undefined, undefined, undefined, jsonObj.results._id, jsonObj.results.name, jsonObj.results.email), (res, err) => { });
            }
            this.setToken(jsonObj.token);
            userObject['token'] = jsonObj.token;
          }
          callback(userObject, undefined, false);
        } else {
          if (jsonObj.hasOwnProperty('return')) {
            callback(undefined, jsonObj, jsonObj.return);
          } else {
            callback(undefined, jsonObj, false);
          }
        }
      } else {
        callback(undefined, 'Data error from server ', false);
      }
    }, 'POST', objData, ContentType.JSON, 10.0, false, 1.5, 60.0, false, true);
  }
  public requestLoginPassCheckOld(username: string, callback: (dataResponse: any | undefined,
    requestError: any | undefined, returnIt: boolean) => void, lang?: string) {
    let uuid = guid();
    let urlStr = this.authURL + 'resource/password/required?email=' + encodeURIComponent(username.trim()) + '&vertical=' + this.orgType + '&uuid=' + uuid;
    if (this.orgId) {
      urlStr += '&organizationId=' + this.orgId;
    }
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        //console.log(error);
        if (jsonObj && jsonObj.hasOwnProperty('return')) {
          callback(undefined, error, jsonObj.return);
        } else {
          callback(undefined, error, false);
        }
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          let userObject = jsonObj.results;
          if (jsonObj.hasOwnProperty('token')) {
            this.logTrackEvent(new EventTrack(EventType.SIGNIN, undefined, undefined, undefined, undefined, undefined, jsonObj.results._id, jsonObj.results.name, jsonObj.results.email), (res, err) => { });
            this.setToken(jsonObj.token);
            userObject['token'] = jsonObj.token;
          }
          callback(userObject, undefined, false);
        } else {
          if (jsonObj.hasOwnProperty('return')) {
            callback(undefined, jsonObj, jsonObj.return);
          } else {
            callback(undefined, jsonObj, false);
          }
        }
      } else {
        callback(undefined, 'Data error from server ', false);
      }
    });
  }
  public requestLoginPassCheck(username: string, callback: (dataResponse: any | undefined,
    requestError: any | undefined, returnIt: boolean) => void, lang?: string) {
    let urlStr = this.authCASURL + 'user/checkexists';
    let uuid = guid();
    let objData = {
      "productId": this.productId,
      "userName": username.trim(),
      "organizationId": this.orgId,
      "uuid": uuid
    }
    objData['userName'] = username.trim();
    if (this.orgId) {
      objData['organizationId'] = this.orgId;
    }
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        //console.log(error);
        if (jsonObj && jsonObj.hasOwnProperty('return')) {
          callback(undefined, error, jsonObj.return);
        } else {
          callback(undefined, error, false);
        }
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          let userObject = {};
          if (jsonObj.hasOwnProperty('results')) {
            userObject = jsonObj.results;
          }
          callback(userObject, undefined, false);
        } else {
          if (jsonObj.hasOwnProperty('return')) {
            callback(undefined, jsonObj, jsonObj.return);
          } else {
            callback(undefined, jsonObj, false);
          }
        }
      } else {
        callback(undefined, 'Data error from server ', false);
      }
    }, 'POST', objData, ContentType.JSON, 10.0, false, 1.5, 60.0, false, true);
  }
  public requestLoginTokenCheck(token: string, callback: (dataResponse: any | undefined,
    requestError: any | undefined, returnIt: boolean) => void, lang?: string) {
    this.token = token;
    let uuid = guid();
    let urlStr = this.authURL + 'resource/account/login' + '?uuid=' + uuid;
    if (this.orgId) {
      urlStr += '&organizationId=' + this.orgId;
    }
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        //console.log(error);
        if (jsonObj && jsonObj.hasOwnProperty('return')) {
          callback(undefined, error, jsonObj.return);
        } else {
          callback(undefined, error, false);
        }
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          let userObject = jsonObj.results;
          if (jsonObj.hasOwnProperty('token')) {
            this.logTrackEvent(new EventTrack(EventType.SIGNIN, undefined, undefined, undefined, undefined, undefined, jsonObj.results._id, jsonObj.results.name, jsonObj.results.email), (res, err) => { });
            this.setToken(jsonObj.token);
            userObject['token'] = jsonObj.token;
          }
          callback(userObject, undefined, false);
        } else {
          if (jsonObj.hasOwnProperty('return')) {
            callback(undefined, jsonObj, jsonObj.return);
          } else {
            callback(undefined, jsonObj, false);
          }
        }
      } else {
        callback(undefined, 'Data error from server ', false);
      }
    });
  }
  public requestLoginCheck(username: string, password: string, callback: (dataResponse: any | undefined,
    requestError: any | undefined, returnIt: boolean) => void, lang?: string) {
    let encodedPassword = urlSafeBase64Encoding(password); // make it from backend
    let uuid = guid();
    let urlStr = this.authURL + 'resource/logged/check?email=' + encodeURIComponent(username.trim()) + '&password=' + encodedPassword + '&vertical=' + this.orgType + '&uuid=' + uuid;
    if (this.orgId) {
      urlStr += '&organizationId=' + this.orgId;
    }
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        //console.log(error);
        if (jsonObj && jsonObj.hasOwnProperty('return')) {
          callback(undefined, error, jsonObj.return);
        } else {
          callback(undefined, error, false);
        }
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          // this.logTrackEvent(new EventTrack(EventType.SIGNIN, undefined, undefined, undefined, undefined, undefined, jsonObj.results._id, jsonObj.results.name, jsonObj.results.email), (res, err) => { });
          let userObject = jsonObj.results;
          this.setToken(jsonObj.token);
          userObject['token'] = jsonObj.token;
          callback(userObject, undefined, false);
        } else {
          if (jsonObj.hasOwnProperty('return')) {
            callback(undefined, jsonObj.message, jsonObj.return);
          } else {
            callback(undefined, jsonObj.message, false);
          }
        }
      } else {
        callback(undefined, 'Data error from server ', false);
      }
    });
  }
  public forgotPassword(email: string, callback: (dataResponse: any | undefined,
    requestError: any | undefined, returnIt: boolean) => void, lang?: string) {
    let urlStr = this.authCASURL + 'user/forgotpassword?email=' + encodeURIComponent(email.trim());
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        if (error.hasOwnProperty('return')) {
          callback(undefined, error, jsonObj.return);
        } else {
          callback(undefined, error, false);
        }
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined, false);
        } else {
          if (jsonObj.hasOwnProperty('return')) {
            callback(undefined, jsonObj.message, jsonObj.return);
          } else {
            callback(undefined, jsonObj.message, false);
          }
        }
      } else {
        callback(undefined, 'Data error from server ', false);
      }
    }, 'POST', {}, ContentType.JSON, 10.0, false, 1.5, 60.0, false, true, false);
  }
  public forgotPasswordOld(email: string, callback: (dataResponse: any | undefined,
    requestError: any | undefined, returnIt: boolean) => void, lang?: string) {
    let urlStr = this.authURL + 'resource/user/forgotpassword?email=' + encodeURIComponent(email.trim());
    if (this.orgId) {
      urlStr += '&organizationId=' + this.orgId;
    }
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        if (error.hasOwnProperty('return')) {
          callback(undefined, error, jsonObj.return);
        } else {
          callback(undefined, error, false);
        }
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined, false);
        } else {
          if (jsonObj.hasOwnProperty('return')) {
            callback(undefined, jsonObj.message, jsonObj.return);
          } else {
            callback(undefined, jsonObj.message, false);
          }
        }
      } else {
        callback(undefined, 'Data error from server ', false);
      }
    });
  }
  public validateUser(callback: (dataResponse: any | undefined,
    requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'resource/user/valid';
    callback(true, undefined);
    // this.jsonGetRequest(urlStr, (jsonObj, error) => {
    //     if (error !== undefined) {
    //         callback(undefined, error);
    //         return;
    //     }
    //     if (jsonObj) {
    //       if (jsonObj.status) {
    //         callback(true, undefined);
    //       }else {
    //         callback(undefined, jsonObj.message);
    //       }
    //     } else {
    //       callback(undefined, 'Data error from server ');
    //     }
    // });
  }
  protected toBase64(stringToSign: string) {
    let base64 = btoa(stringToSign);
    return base64;
  }
  public getMetaData(type: string, feilds: any[], callback: (dataResponse: any | undefined, requestError: any | undefined) => void, orgId?: string, lang?: string) {
    let urlStr = this.authURL + type + '/metadata';
    let ic = '?';
    if (feilds) {
      urlStr = urlStr + ic + 'fields=' + feilds;
      ic = '&';
    }
    if (orgId) {
      urlStr = urlStr + ic + 'organizationId=' + orgId;
      ic = '&';
    }
    if (lang) {
      urlStr = urlStr + ic + 'language=' + lang;
    }
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public updateProfileData(data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'resource/user/profileupdate/' + data._id + '/' + this.orgId;
    return this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public updateUserUISettingsData(data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'organization/' + this.currentUser._id + '/' + this.orgId + '/uisettings';
    return this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public updateUserSettingsData(data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'resource/user/' + this.currentUser._id + '/settings';
    return this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public saveData(type: string, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, useOrg: boolean = false, lang?: string) {
    if (data.hasOwnProperty('_id') && data._id) {
      let urlStr = this.authURL + type + '/' + data._id;
      if (useOrg) {
        urlStr = urlStr + '/' + this.orgId
      }
      return this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, ' Connectivity issue.');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            if (type === 'session') {
              jsonObj.results.users = this.featuresPermissionService.validateSessionUsers(jsonObj.results.users);
            }
            callback(jsonObj, undefined);
          } else {
            if (jsonObj.hasOwnProperty('type')) {
              this.appStatusSubject.next(jsonObj.type);
            }
            callback(undefined, jsonObj.message);
          }
        } else {
          callback(undefined, error);
        }
      }, 'POST', data);
    } else {
      let urlStr = this.authURL + type;
      // urlStr = this.addLanguageToURL(urlStr, lang);
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, ' Connectivity issue.');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            if (type === 'session') {
              jsonObj.results.users = this.featuresPermissionService.validateSessionUsers(jsonObj.results.users);
            }
            callback(jsonObj, undefined);
          } else {
            if (jsonObj.hasOwnProperty('type')) {
              this.appStatusSubject.next(jsonObj.type);
            }
            callback(undefined, jsonObj.message);
          }
        } else {
          callback(undefined, error);
        }
      }, 'POST', data);
    }
  }
  public signUp(orgId, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, resourceType: string, lang?: string) {
    let uuid = guid();
    let urlStr = this.authURL + 'resource/signup/' + this.orgId + '/' + resourceType + '/' + uuid;
    // urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        //console.log(error);
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          // let userObject = jsonObj.results;
          // if (jsonObj.hasOwnProperty('token')) {
          //   this.setToken(jsonObj.token);
          //   userObject['token'] = jsonObj.token;
          // }
          callback(jsonObj, undefined);
        } else {
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, 'Data error from server ');
      }
    }, 'POST', data);
  }
  public cropImageByOrg(data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'img/resource/crop/' + this.orgId;
    // urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public cropImageByOrgByAny(id: string, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'img/resource/crop/' + this.orgId + '/' + id;
    // urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public setFavoriteData(status: boolean, type: string, favoriteId: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let assignStatus = 'assign';
    if (!status) {
      assignStatus = 'unassign';
    }
    let urlStr = this.authURL + 'favorites/' + assignStatus + '/' + this.orgId + '/' + favoriteId + '/' + type;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getOrganizationbyRoomId(id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'organization/' + id + '/byroom';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getSingleData(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/' + id;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          if (type === 'session') {
            jsonObj.results.users = this.featuresPermissionService.validateSessionUsers(jsonObj.results.users);
          }
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getSingleCachData(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string) {
    if (cached) {
      if (this.cachedObj.hasOwnProperty(type + '/' + id)) {
        callback(this.cachedObj[type + '/' + id], undefined);
        return;
      }
    }
    let urlStr = this.authURL + type + '/' + id;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          // if (cached) {
          this.cachedObj[type + '/' + id] = jsonObj.results;
          // }
          callback(jsonObj.results, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getUserMetaData(id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'usermetadata/list/' + id;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj.results, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getUserInfoByEmail(email: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'resource/user/validate/superadmin?email=' + encodeURIComponent(email.trim());
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj.results, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getMySingleData(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/' + id;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public deleteSingleData(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string, checkusability: boolean = false) {
    let urlStr = this.authURL + type + '/' + id + '/delete';
    urlStr = this.addLanguageToURL(urlStr, lang);

    let objData = undefined;
    if (checkusability) {
      objData = {
        check: checkusability
      };
    }
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', objData);
  }
  public deleteSingleDataByOrg(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string, checkusability: boolean = false) {
    let urlStr = this.authURL + type + '/' + id + '/' + this.orgId + '/delete';
    urlStr = this.addLanguageToURL(urlStr, lang);
    let objData = undefined;
    if (checkusability) {
      objData = {
        check: checkusability
      };
    }
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', objData);
  }
  public deleteSingleDataByApi(type: string, id: any, targetApi: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/' + id + '/' + targetApi;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST');
  }
  public deleteProfileImage(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/' + id + '/deleteprofile';
    urlStr = this.addLanguageToURL(urlStr, lang);
    let objData = {
      // folder: "string",
      url: id
    };
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', objData);
  }
  public deleteImageDataByOrg(type: string, id: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/delete/' + this.orgId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    let objData = {
      // folder: "string",
      url: id
    };
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', objData);
  }
  public deleteImageDataByOrgByAny(id: string, type: string, url: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/delete/' + this.orgId + '/' + id;
    urlStr = this.addLanguageToURL(urlStr, lang);
    let objData = {
      // folder: "string",
      url: url
    };
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', objData);
  }
  public deleteBackgroundImageData(type: string, id: string, imageUrl: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'img/delete/' + id + '/tilebackground';
    let data = {
      type: type,
      imageUrl: imageUrl
    };
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public updateDBVersion(version: any = undefined, callback: (dataResponse: any | undefined, requestError: any | undefined) => void) {
    let urlStr = this.authURL + 'version/generate';
    let data = {
      showdialog: true
      // version : '1.0.2.0'
    };
    if (version) {
      data['version'] = version
    }
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public getDataListByGet(type: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/list';
    // let urlStr = this.authURL + 'app/' + type;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getDataListByOrgByGet(type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/list/' + this.orgId;
    // let urlStr = this.authURL + 'app/' + type;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getDataListByOrgByGetByAny(id: string, type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/list/' + this.orgId + '/' + id;
    // let urlStr = this.authURL + 'app/' + type;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getDataListBySelection(type: string, sessionId: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, source: string = '', sourceTarget: string = '', lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');
    let urlStr = this.authURL + source + type + '/' + sessionId + '/tilesearch';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getDataList(type: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, source: string = '', sourceTarget: string = '', lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');
    let urlStr = this.authURL + source + type + '/search' + sourceTarget;
    urlStr = this.addLanguageToURL(urlStr, lang);
    return this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          if (type === 'session') {
            jsonObj.results = jsonObj.results.map(session => {
              session.users = this.featuresPermissionService.validateSessionUsers(session.users);
              return session;
            })
          }
          // console.log('jsonObj', jsonObj);
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getDataListByListByOrg(type: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, source: string = '', sourceTarget: string = '', lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');
    let urlStr = this.authURL + source + type + '/list/' + this.orgId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    return this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getDataListByListByOrgByAny(id: string, type: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, source: string = '', sourceTarget: string = '', lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');
    let urlStr = this.authURL + source + type + '/list/' + this.orgId + '/' + id;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getDataListByList(type: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, source: string = '', sourceTarget: string = '', lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');
    let urlStr = this.authURL + source + type + '/list';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getDataListSummary(type: string, conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');
    let urlStr = this.authURL + type + '/search/summary';
    urlStr = this.addLanguageToURL(urlStr, lang);
    return this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          if (cached) {
            this.cachedObj[type] = jsonObj;
          }
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getThemes(orgId: string, id: string, createdOrg: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    var urlStr = this.authURL + 'tiletheme/list';
    var query = {};

    if (this.utils.isNullOrEmpty(createdOrg)) {
      if (!this.utils.isNullOrEmpty(id)) {
        query["_id"] = id;
      } else {
        query["organizationId"] = orgId;
      }
    } else {
      query["createdOrg"] = createdOrg;
    }
    urlStr = this.addLanguageToURL(urlStr, lang);
    let lgObj = 'form_data=' + encodeURI(JSON.stringify(query));
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', lgObj);
  };
  public getDataLByOrg(type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string) {
    if (cached) {
      if (this.cachedObj.hasOwnProperty(type + '/' + this.orgId)) {
        callback(this.cachedObj[type + '/' + this.orgId], undefined);
        return;
      }
    }
    // let urlStr = this.authURL  + type;
    let urlStr = this.authURL + type + '/list/' + this.orgId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          if (cached) {
            this.cachedObj[type + '/' + this.orgId] = jsonObj;
          }
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getDataLByOrgType(datatype: string, type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, lang?: string) {
    if (cached) {
      if (this.cachedObj.hasOwnProperty(datatype + '/' + this.orgId + '/' + type)) {
        callback(this.cachedObj[datatype + '/' + this.orgId + '/' + type], undefined);
        return;
      }
    }
    let urlStr = this.authURL + datatype + '/list/' + this.orgId + '/' + type;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          if (cached) {
            this.cachedObj[datatype + '/' + this.orgId + '/' + type] = jsonObj;
          }
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getDataL(type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, cached: boolean = false, extraId: string = '', lang?: string) {
    if (cached) {
      if (this.cachedObj.hasOwnProperty(type + '/' + extraId)) {
        callback(this.cachedObj[type + '/' + extraId], undefined);
        return;
      }
    }
    // let urlStr = this.authURL  + type;
    let urlStr = this.authURL + type + '/list';
    if (extraId) {
      urlStr = urlStr + '/' + extraId;
    }
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          if (cached) {
            this.cachedObj[type + '/' + extraId] = jsonObj;
          }
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getUsersList(conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');
    let urlStr = this.authURL + 'resource/user/search';
    urlStr = this.addLanguageToURL(urlStr, lang);
    return this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getMyUsersList(conf: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let cleanConf = this.buildSearchRequestSToAPI(conf, '');
    let urlStr = this.authURL + 'my/resource/user/search/summary';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', cleanConf);
  }
  public getUser(userId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'resource/user/' + userId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public registeruser(orgId, user: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + '/resource/signup/' + orgId + '/' + environment.customKeys.roleView;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', user);
  }
  public editUser(userId: any, user: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'resource/user/' + userId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', user);
  }
  public confirmUserPassword(email: string, confirmationCode: any, newpassword: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authCASURL + 'user/confirm/forgotpassword';
    let encodedNewPassword = urlSafeBase64Encoding(newpassword);
    let user = { email: email.trim(), confirmationCode: confirmationCode, newPassword: encodedNewPassword };

    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', user, ContentType.JSON, 10.0, false, 1.5, 60.0, false, true);
  }
  public editUserPassword(oldpassword: any, newpassword: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authCASURL + 'user/changepassword';
    let encodedOldPassword = urlSafeBase64Encoding(oldpassword);
    let encodedNewPassword = urlSafeBase64Encoding(newpassword);
    let user = { oldPassword: encodedOldPassword, newPassword: encodedNewPassword };

    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', user, ContentType.JSON, 10.0, false, 1.5, 60.0, false, true, true);
  }
  public editUserPasswordOld(oldpassword: any, newpassword: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'resource/user/changepassword';
    let encodedOldPassword = urlSafeBase64Encoding(oldpassword);
    let encodedNewPassword = urlSafeBase64Encoding(newpassword);
    let user = { oldpassword: encodedOldPassword, newpassword: encodedNewPassword };

    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', user);
  }
  public resetUserPassword(userId: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'resource/user/resetpassword/' + userId;
    if (this.orgId) {
      urlStr = urlStr + '/' + this.orgId;
    }
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public requestPassword(useremail: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'resource/user/requestpassword/' + useremail;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getAnswersRadonPick(sessionId: string, tileId: string, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'tile/answered/ramdompick/' + tileId + '/' + sessionId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public getAnswersTileList(sessionId: string, tileId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'tile/answer/' + tileId + '/' + sessionId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getAnswersTilePercentage(sessionId: string, tileId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'tile/answerwithpercentage/' + tileId + '/' + sessionId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public setActiveTile(sessionId: string, tileId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'session/activate/' + sessionId + '/' + tileId + '/tile';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public deactivateTile(sessionId: string, tileId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'session/deactivate/' + sessionId + '/' + tileId + '/tile';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public unDoneTile(sessionId: string, tileId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'session/undone/' + sessionId + '/' + tileId + '/tile';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getActiveTile(sessionId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'session/get/' + sessionId + '/tile';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public setActiveLecture(roomId: string, sessionId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'session/activate/' + sessionId + '/' + roomId + '/room';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public deactivateLecture(roomId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'session/deactivate/' + roomId + '/room';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getActiveLecture(roomId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'session/room/' + roomId + '/get';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getLectureByClass(classId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'getLectureByClass/' + classId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public setActiveSession(roomId: string, sessionId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'session/activate/' + sessionId + '/' + roomId + '/room';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public deactivateSession(roomId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'session/deactivate/' + roomId + '/room';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getActiveSession(sessionId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'session/' + sessionId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          jsonObj.results.users = this.featuresPermissionService.validateSessionUsers(jsonObj.results.users);
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getSessionByRoom(roomId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'getSessionByRoom/' + roomId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public clearPosterVotes(posterId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'poster/' + posterId + '/clearvote';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public editSelfUser(userId: any, user: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'resource/user/userself/' + userId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', user);
  }
  public assignTilesToLecture(sessionId: any, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'session/' + sessionId + '/assign/tile';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public assignTilesToSession(sessionId: any, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'session/' + sessionId + '/assign/tile';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public assignResourcesToSession(sessionId: any, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'session/' + sessionId + '/assign/resources';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public assignUsersToClass(classId: any, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'class/' + classId + '/assign';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public updateSessionDataToRoom(roomId: any, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'room/' + roomId + '/updatesettings';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public assignUsersToRoom(roomId: any, data: any, type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'room/' + roomId + '/assign/' + type;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public assignUsersToSession(sessionId: any, type: any, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'session/' + sessionId + '/assign/' + type;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public assignPresenterToSession(sessionId: any, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'session/' + sessionId + '/assign/presenter';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public saveDataToClass(classId: any, datatype: string, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + datatype + '/' + classId + '/save';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public saveDataToRoom(roomId: any, datatype: string, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + datatype + '/' + roomId + '/save';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public createSessionFromEvent(eventId: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'session/event/' + eventId + '/add';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public gatLectureAnswers(sessionId: string, tileId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'tile/answer/' + sessionId + '/' + tileId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public posterVote(posterId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'poster/' + posterId + '/vote';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getSubscriptionPLan(callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'organization/subscription/plan/' + this.orgId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  public getTileLink(lectureId: string = undefined, tileId: any, readOnly: boolean = true) {
    let iframeLink = environment.serverTileUrl + 'app/remote/tile/' + tileId + '?vertical=' + this.orgType;

    if (lectureId && this.currentUser) {
      iframeLink = environment.serverTileUrl + 'app/remote/tile/' + this.appId + '/' + this.currentUser._id + '/' + tileId + '/' + lectureId + '?vertical=' + this.orgType;
      if (this.currentUser.locationId && this.currentUser.locationId.length > 0) {
        iframeLink = iframeLink + '&locId=' + this.currentUser.locationId[0]._id;
      }
    }
    if (readOnly) {
      iframeLink = iframeLink + '&action=view';
    }
    return iframeLink;
  }
  protected buildSearchRequestSToAPI(conf: any, token: string = '', addCustomData: boolean = true): {} {
    let searchRequestGeneric: any = {
    };
    if (conf.perpage) {
      searchRequestGeneric['count'] = conf.perpage || 10;
    }
    if (searchRequestGeneric.count === -1) {
      delete searchRequestGeneric.count;
    }
    if (conf.orderBy && conf.orderDir) {
      searchRequestGeneric['order'] = [];
      searchRequestGeneric['order'].push({ field: conf.orderBy, order: conf.orderDir });
    }
    if (conf.order) {
      searchRequestGeneric['order'] = conf.order;
    }
    let fieldList: string[] = [];
    if (conf.hasOwnProperty('fieldKeys')) {
      fieldList = conf['fieldKeys'];
    }
    if (fieldList.length > 0) {
      searchRequestGeneric['fields'] = fieldList;
    }
    if (conf.hasOwnProperty('term') && conf['term'] !== undefined) {
      searchRequestGeneric['term'] = conf['term'] || '';
    }
    if (conf.hasOwnProperty('termfields') && conf['termfields'] !== undefined) {
      searchRequestGeneric['termfields'] = conf['termfields'] || '';
    }
    let filterList = {};
    if (conf.customData && addCustomData) {
      if (Object.keys(conf.customData).length > 0) {
        for (let field of Object.keys(conf.customData)) {
          if (field)
            filterList[field] = { op: 'eq', value: conf.customData[field] };
        }
      }
    }
    if (conf.filterFieldKey) {
      for (let field of conf.filterFieldKey) {
        if (field) {
          filterList[field.field] = { op: field.op, value: field.search };
          if (field.type && field.type === 'number') {
            filterList[field.field].value = Number(filterList[field.field].value);
          }
        }
      }
    }
    if (Object.keys(filterList).length > 0) {
      searchRequestGeneric['filter'] = filterList;
    }
    if (conf.hasOwnProperty('filter')) {
      searchRequestGeneric['filter'] = conf.filter;
    }
    if (conf.hasOwnProperty('page')) {
      searchRequestGeneric['page'] = conf.page;
    }
    if (token !== '') {
      searchRequestGeneric['paginationToken'] = token;
    }
    if (conf.hasOwnProperty('include') && conf['include'] !== undefined) {
      searchRequestGeneric['include'] = conf['include'] || [];
    }
    if (conf.hasOwnProperty('exclude') && conf['exclude'] !== undefined) {
      searchRequestGeneric['exclude'] = conf['exclude'] || [];
    }
    if (conf.hasOwnProperty('organizationId') && conf['organizationId'] !== undefined) {
      searchRequestGeneric['organizationId'] = conf['organizationId'] || '';
    }
    return searchRequestGeneric;
  }
  protected urlEncode(str: string): string {
    return encodeURI(str);
  }
  protected jsonRequestSimple(urlString: string, callback: (json?: any, error?: any) => void, params: Dictionary, timeout: number = 60.0) {
    let body;
    if (params) {
      body = params;
    } else {
      // we need to recheck this
      //console.log('Parameters sent to jsonRequestSimple are not serializable into JSON');
    }
    return this.jsonRequest(urlString, (json, error) => {
      callback(json, error);
    }, 'POST', body, ContentType.JSON, timeout);
  }
  protected jsonGetRequest(urlString: string, callback: (json?: any, error?: any) => void, params?: Dictionary) {
    if (urlString) {
      let urlComps = urlString;
      if (params) {
        for (let urlItem of Object.keys(params)) {
          urlComps += '&' + urlItem + '=' + params[urlItem];
        }
      }
      return this.jsonRequest(urlComps, callback, 'GET');
    } else {
      return;
    }
  }
  protected jsonRequest(urlString: string,
    callback: (json: any, error: any) => void,
    method: string = 'POST',
    postBody: any = undefined,
    contentType: string = ContentType.JSON,
    timeout: number = 10.0,
    retry: boolean = false,
    retryFactor: number = 1.5,
    maxTimeout: number = 60.0, excludeVertical: boolean = false, excludeProject: boolean = false, includeToken: boolean = false) {
    if (urlString) {
      let url: string = urlString || '';
      let headers = {
        'Accept': 'application/json',
      };
      if (contentType)
        headers['Content-Type'] = contentType;
      if (this.token && (urlString.startsWith(this.authURL) || urlString.startsWith(environment.trackingUrl) || includeToken)) {
        headers['Authorization'] = this.token;
      }
      if (!excludeVertical)
        headers['vertical'] = this.orgType;
      if (!excludeProject)
        headers['project'] = 'stellar';

      headers['productid'] = environment.productId;
      if (this.orgId && this.orgId !== '') {
        headers['organizationid'] = this.orgId;
        headers['integratedid'] = this.orgId;
      }
      const httpHeaders = new HttpHeaders(headers);
      let httpOptions: any = {
        responseType: 'json',
        headers: httpHeaders,
        method: method
      }

      let bodyString = postBody;
      if (method === 'POST') {
        bodyString = JSON.stringify(postBody);
        httpOptions['body'] = bodyString;
      }
      return this.http.request(method, url, httpOptions)
        // .pipe(map(
        //     (res: any) => {
        //       // below might need to be changed
        //       if (res.status >= 404) {
        //         window.location.reload();
        //       } else if (res.status >= 400) {
        //         callback(undefined, 'server');
        //         return;
        //       }
        //       return res;
        //     }
        //   ))
        .subscribe(
          (data) => {
            let version = undefined;
            let showdialog = undefined;
            if (data && data.hasOwnProperty('version') && data.hasOwnProperty('showdialog')) {
              version = data['version'];
              showdialog = data['showdialog'];
              this.setVersion(version, showdialog);
            }
            // this.userActivityService.insertLog('api', 'jsonRequest', 'Api return Successfully', {version, showdialog}, url);
            if (data.hasOwnProperty('status')) {
              if (!data['status']) {
                this.userActivityService.insertLog('api', 'jsonRequest', 'Api return Successfully but status false', { data, showdialog, headers }, url);
              } else {
                this.userActivityService.insertLog('api', 'jsonRequest', 'Api return Successfully', { version, showdialog, headers }, url);
              }
            }
            callback(data, undefined);
            // console.log(url, data);
          },
          (err) => {
            this.userActivityService.insertLog('api', 'jsonRequest', 'Api Return Unsuccessfully', { err, headers, httpOptions }, url);
            if (err) {
              if (err.status >= 404) {
                // window.location.reload();
                callback(undefined, 'Refresh page');
              } else if (err.status >= 400) {
                if (this.currentUser) {
                  try {
                    let jsonErr = err.json();
                    if (jsonErr.hasOwnProperty('type') && jsonErr.type === 'login') {
                      this.appStatusSubject.next(jsonErr.type);
                      // this.logout();
                    } else {
                      callback(undefined, ' Connectivity issue.');
                    }
                  } catch (e1) {
                    try {
                      if (err.hasOwnProperty('error')) {
                        let jsonErr = err.error;
                        if (jsonErr.hasOwnProperty('type') && jsonErr.type === 'login') {
                          this.appStatusSubject.next(jsonErr.type);
                          // this.logout();
                        } else {
                          callback(undefined, ' Connectivity issue.');
                        }
                      } else {
                        callback(undefined, ' Connectivity issue.');
                      }
                    } catch (e2) {
                      callback(undefined, ' Connectivity issue.');
                    }
                  }
                }
              } else {
                callback(undefined, ' Connectivity issue.');
              }
            }
          });

    } else {
      // this.logger.log('Failed to create URL');
      //console.log('Failed to create URL');
    }
  }
  public onUploadUserImage(browsed_file: any, id: string): Observable<{}> {

    let headers = {
      'Authorization': this.token,
      'Accept': 'application/json',
      'vertical': this.orgType
    };
    headers['productid'] = environment.productId;
    if (this.orgId && this.orgId !== '') {
      headers['organizationid'] = this.orgId;
      headers['integratedid'] = this.orgId;
    }
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    let url = this.authURL + 'resource/user/image/upload/' + id;
    let formData = new FormData();
    formData.append('upfile', browsed_file.originalFile);
    formData.append('name', browsed_file.text);
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }
  public onUploadUserTranscript(browsed_file: any, userId: string, transcript: any): Observable<{}> {
    let headers = {
      'Authorization': this.token,
      'Accept': 'application/json',
      'vertical': this.orgType
    };
    headers['productid'] = environment.productId;
    if (this.orgId && this.orgId !== '') {
      headers['organizationid'] = this.orgId;
      headers['integratedid'] = this.orgId;
    }
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    let url = this.authURL + 'transcript/upload/' + userId;
    let formData = new FormData();
    formData.append('upfile', browsed_file.originalFile);
    formData.append('name', browsed_file.text);
    formData.append('year', transcript.year);
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }
  public onUploadFiles(browsed_file: any, folder: string = '', type: string = '', isEncoded: string = 'false'): Observable<{}> {
    let headers = {
      'Authorization': this.token,
      'Accept': 'application/json',
      'vertical': this.orgType
    };
    headers['productid'] = environment.productId;
    if (this.orgId && this.orgId !== '') {
      headers['organizationid'] = this.orgId;
      headers['integratedid'] = this.orgId;
    }
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    let url = this.authURL + 'img/upload/' + this.orgId;
    let formData = new FormData();
    // formData.append('file', browsed_file.originalFile, browsed_file.originalFile.name);
    formData.append('upfile', browsed_file.originalFile);
    formData.append('folder', folder);
    formData.append('type', type);
    formData.append('isEncoded', isEncoded);
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }
  public onUploadFilesByPathNew(path: string, browsed_file: any, type: string = undefined, isEncoded: string = undefined, id: string = undefined, folder: string = undefined, addName: boolean = true): Observable<HttpEvent<any>> {
    let headers = {
      'Authorization': this.token,
      'Accept': 'application/json',
      'vertical': this.orgType
    };
    headers['productid'] = environment.productId;
    if (this.orgId && this.orgId !== '') {
      headers['organizationid'] = this.orgId;
      headers['integratedid'] = this.orgId;
    }
    let httpOptions: any = {
      headers: new HttpHeaders(headers),
      reportProgress: true,
      responseType: 'json'
    }
    let url = this.authURL + path;
    const formData: FormData = new FormData();
    formData.append('upfile', browsed_file.originalFile);
    if (addName) {
      formData.append('name', browsed_file.text);
    }
    if (folder !== undefined) {
      formData.append('folder', folder);
    }
    if (type !== undefined) {
      formData.append('type', type);
    }
    if (id !== undefined) {
      formData.append('id', id);
    }
    if (isEncoded !== undefined) {
      formData.append('isEncoded', isEncoded);
    }
    const req = new HttpRequest('POST', url, formData, httpOptions);
    return this.http.request(req);
  }
  public onUploadFilesByPath(path: string, browsed_file: any): Observable<{}> {
    let headers = {
      'Authorization': this.token,
      'Accept': 'application/json',
      'vertical': this.orgType
    };
    headers['productid'] = environment.productId;
    if (this.orgId && this.orgId !== '') {
      headers['organizationid'] = this.orgId;
      headers['integratedid'] = this.orgId;
    }
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    let url = this.authURL + path;
    let formData = new FormData();
    // formData.append('file', browsed_file.originalFile, browsed_file.originalFile.name);
    formData.append('upfile', browsed_file.originalFile);
    formData.append('name', browsed_file.text);
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }
  public onUploadFilesByAny(id: string, browsed_file: any, folder: string = '', type: string = '', isEncoded: string = 'false', selectedImageId: string = undefined): Observable<{}> {
    let headers = {
      'Authorization': this.token,
      'Accept': 'application/json',
      'vertical': this.orgType
    };
    headers['productid'] = environment.productId;
    if (this.orgId && this.orgId !== '') {
      headers['organizationid'] = this.orgId;
      headers['integratedid'] = this.orgId;
    }
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    let url = this.authURL + 'img/resource/upload/' + this.orgId + '/' + id;
    let formData = new FormData();
    // formData.append('file', browsed_file.originalFile, browsed_file.originalFile.name);
    formData.append('upfile', browsed_file.originalFile);
    formData.append('folder', folder);
    formData.append('type', type);
    if (selectedImageId) {
      formData.append('id', selectedImageId);
    }
    formData.append('isEncoded', isEncoded);
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }
  public onUploadAnyFile(id: string, browsed_file: any, type: string = '', isEncoded: string = 'false', selectedFileId: string = undefined): Observable<{}> {
    let headers = {
      'Authorization': this.token,
      'Accept': 'application/json',
      'vertical': this.orgType
    };
    headers['productid'] = environment.productId;
    if (this.orgId && this.orgId !== '') {
      headers['organizationid'] = this.orgId;
      headers['integratedid'] = this.orgId;
    }
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    let url = this.authURL + 'media/upload/' + this.orgId;
    let formData = new FormData();
    // formData.append('file', browsed_file.originalFile, browsed_file.originalFile.name);
    formData.append('upfile', browsed_file.originalFile);
    formData.append('type', type);
    if (selectedFileId) {
      formData.append('id', selectedFileId);
    }
    formData.append('isEncoded', isEncoded);
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }
  public onUploadFilesByOrg(browsed_file: any, folder: string = '', type: string = '', isEncoded: string = 'false', selectedImageId: string = undefined): Observable<{}> {
    let headers = {
      'Authorization': this.token,
      'Accept': 'application/json',
      'vertical': this.orgType
    };
    headers['productid'] = environment.productId;
    if (this.orgId && this.orgId !== '') {
      headers['organizationid'] = this.orgId;
      headers['integratedid'] = this.orgId;
    }
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    let url = this.authURL + 'img/resource/upload/' + this.orgId;
    let formData = new FormData();
    // formData.append('file', browsed_file.originalFile, browsed_file.originalFile.name);
    formData.append('upfile', browsed_file.originalFile);
    formData.append('folder', folder);
    formData.append('type', type);
    if (selectedImageId) {
      formData.append('id', selectedImageId);
    }
    formData.append('isEncoded', isEncoded);
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }
  public onUploadBackgroundFiles(tileId: string, type: string, browsed_file: any): Observable<{}> {
    let headers = {
      'Authorization': this.token,
      'Accept': 'application/json',
      'vertical': this.orgType
    };
    headers['productid'] = environment.productId;
    if (this.orgId && this.orgId !== '') {
      headers['organizationid'] = this.orgId;
      headers['integratedid'] = this.orgId;
    }
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    let url = this.authURL + 'img/upload/' + tileId + '/tilebackground';
    let formData = new FormData();
    // formData.append('file', browsed_file.originalFile, browsed_file.originalFile.name);
    formData.append('upfile', browsed_file.originalFile);
    formData.append('tileId', tileId);
    formData.append('type', type);
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }
  public onUploadPictureByBanner(browsed_file: any): Observable<{}> {
    let headers = {
      'Authorization': this.token,
      'Accept': 'application/json',
      'vertical': this.orgType
    };
    headers['productid'] = environment.productId;
    if (this.orgId && this.orgId !== '') {
      headers['organizationid'] = this.orgId;
      headers['integratedid'] = this.orgId;
    }
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    let url = this.authURL + 'banner/image/upload/' + this.orgId;
    let formData = new FormData();
    formData.append('upfile', browsed_file.originalFile);
    formData.append('name', browsed_file.text);
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }
  public onUploadLoginFiles(browsed_file: any, name: string = ''): Observable<{}> {
    let headers = {
      'Authorization': this.token,
      'Accept': 'application/json',
      'vertical': this.orgType
    };
    headers['productid'] = environment.productId;
    if (this.orgId && this.orgId !== '') {
      headers['organizationid'] = this.orgId;
      headers['integratedid'] = this.orgId;
    }
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    let url = this.authURL + 'img/upload/' + this.orgId + '/login';
    let formData = new FormData();
    // formData.append('file', browsed_file.originalFile, browsed_file.originalFile.name);
    formData.append('upfile', browsed_file.originalFile);
    formData.append('name', name);
    formData.append('vertical', this.orgType);
    formData.append('orgId', this.orgId);
    return this.http.post(url,
      formData, httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.status) {
            return jsonObj;
          } else {
            return { status: false, message: 'Upload Unsuccessful' };
          }
        } else {
          return { status: false, message: 'Upload Unsuccessful' };
        }
      }));
  }
  public getVimeoVideos(videoPlaylistId: string, page: number = 1): Observable<{}> {
    let headers = {
      'Authorization': 'bearer ' + this.vimeoApiKey,
      'Accept': 'application/json',
    };
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    let url = 'https://api.vimeo.com/me/albums/' + videoPlaylistId + '/videos?per_page=100&sort=manual&page=' + page;
    return this.http.get(url,
      httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.data) {
            return { status: false, message: 'Videos successfully extracted', data: jsonObj };
          } else {
            if (jsonObj.error) {
              return { status: false, error: jsonObj.error };
            } else {
              return { status: false, error: 'Not a correct vimeo url' };
            }
          }
        } else {
          return { status: false, error: 'Not a correct vimeo url' };
        }
      }));
  }
  public getVimeoVideo(vimeoUrl: string): Observable<{}> {
    let headers = {
      'Authorization': 'bearer ' + this.vimeoApiKey,
      'Accept': 'application/json',
    };
    let httpOptions = {
      headers: new HttpHeaders(headers)
    }
    let url = 'https://api.vimeo.com/videos/' + vimeoUrl;
    return this.http.get(url,
      httpOptions).pipe(map((response: any) => {
        let jsonObj = response;
        if (jsonObj) {
          if (jsonObj.link) {
            return { status: false, message: 'Video successfully extracted', data: jsonObj };
          } else {
            if (jsonObj.error) {
              return { status: false, error: jsonObj.error };
            } else {
              return { status: false, error: 'Not a correct vimeo url' };
            }
          }
        } else {
          return { status: false, error: 'Not a correct vimeo url' };
        }
      }));
  }
  public deleteLoginFiles(name: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void) {
    let urlStr = this.authURL + 'img/delete/' + this.orgId + '/login';
    let data = {
      imageUrl: this.serverUrlNoSlash + "/img/conference/" + this.orgId + "/" + name + '.jpg'
    };
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public getOpenTokSessionKey(userId: string, sessionId: string, isRecord: boolean = false, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, breakOutId: string = undefined, type: string = undefined) {
    if (userId && sessionId) {
      let data = { 'userId': userId, 'sessionId': sessionId, 'enableArchive': isRecord };

      if (breakOutId) {
        data['breakoutId'] = breakOutId;
        data['breakout'] = true;
      }

      if (type) {
        data['type'] = type;
      }

      //console.log(data)
      let urlStr = this.authURL + 'opentok/createsession/';
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, ' Connectivity issue.');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
            // } else {
            //   if (jsonObj.hasOwnProperty('type')) {
            //     this.appStatusSubject.next(jsonObj.type);
            //   }
            //   callback(undefined, jsonObj.message);
            // }
          } else {
            callback(undefined, error);
          }
        }
      }, 'POST', data);
    }
  }

  public getOpenTokSessionKeyForRoom(roomId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void) {
    if (roomId) {
      let data = { 'roomId': roomId };

      let urlStr = this.authURL + 'opentok/createsessionforroom/';
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, ' Connectivity issue.');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);

          } else {
            callback(undefined, error);
          }
        }
      }, 'POST', data);
    }
  }

  public getOpenTokSessionKeyWithOrg(orgId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void) {
    if (orgId) {
      let data = { 'organizationId': orgId };
      //console.log(data)
      let urlStr = this.authURL + 'opentok/createsessionfororg/';
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, ' Connectivity issue.');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            callback(undefined, error);
          }
        }
      }, 'POST', data);
    }
  }

  public getOpenTokSessionKeyForUser(hostId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void) {
    if (hostId) {
      let data = { 'userId': hostId };
      //console.log(data)
      let urlStr = this.authURL + 'opentok/createsessionforuser/';
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, ' Connectivity issue.');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            callback(undefined, error);
          }
        }
      }, 'POST', data);
    }
  }

  public getOpenTokSessionKeyForTest(hostId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void) {
    if (hostId) {
      let data = { 'userId': hostId, 'type': 'test' };
      //console.log(data)
      let urlStr = this.authURL + 'opentok/createtestsession/';
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, ' Connectivity issue.');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            callback(undefined, error);
          }
        }
      }, 'POST', data);
    }
  }

  public getOpenTokTokenKey(hostId: string, userObj: object, sessionId: string, role: string, userImage: string, stellarRole: string, advancedView: boolean, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, location: any, breakoutId: string = undefined, language: string = undefined, type?: string, browser?: string) {
    if (userObj && sessionId && role) {
      let data = { 'hostId': hostId, 'userId': userObj['_id'], 'sessionId': sessionId, 'name': userObj['name'], 'role': role, 'userImage': userImage, 'stellarRole': stellarRole, 'location': location };

      if (advancedView != undefined)
        data['advancedView'] = advancedView;

      if (userObj.hasOwnProperty('companyName') && userObj['companyName'])
        data['companyName'] = userObj['companyName'];

      // if (userObj.hasOwnProperty('nickName') && userObj['nickName'])
      //   data['nickname'] = userObj['nickName'];
      // else
      data['nickname'] = userObj['firstName'] + ' ' + userObj['lastName'].substr(0, 1);

      if (userObj.hasOwnProperty('age') && userObj['age'])
        data['age'] = userObj['age'];

      if (userObj.hasOwnProperty('gender') && userObj['gender'])
        data['gender'] = userObj['gender'];

      if (breakoutId) {
        data['breakout'] = true;
        data['breakoutId'] = breakoutId;
      }

      if (language) {
        data['lang'] = language;
      }

      if (type) {
        data['type'] = type;
      }

      if (browser)
        data['browser'] = browser;

      //console.log('getOpenTokTokenKey', data);
      let urlStr = this.authURL + 'opentok/createtoken/';
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, ' Connectivity issue.');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            callback(undefined, error);
          }
        }
      }, 'POST', data);
    }
  }

  public getOpenTokTokenKeyForRoom(hostId: string, userObj: object, roomId: string, role: string, userImage: string, stellarRole: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, language: string = undefined, type?: string) {
    if (userObj && roomId && role) {
      let data = { 'hostId': hostId, 'userId': userObj['_id'], 'roomId': roomId, 'name': userObj['name'], 'role': role, 'userImage': userImage, vertical: environment.orgType, 'stellarRole': stellarRole, 'sessionId': '' };

      if (userObj.hasOwnProperty('companyName') && userObj['companyName'])
        data['companyName'] = userObj['companyName'];

      // if (userObj.hasOwnProperty('nickName') && userObj['nickName'])
      //   data['nickname'] = userObj['nickName'];
      // else
      data['nickname'] = userObj['firstName'] + ' ' + userObj['lastName'].substr(0, 1);

      if (userObj.hasOwnProperty('age') && userObj['age'])
        data['age'] = userObj['age'];

      if (userObj.hasOwnProperty('gender') && userObj['gender'])
        data['gender'] = userObj['gender'];

      if (language) {
        data['lang'] = language;
      }

      if (type) {
        data['type'] = type;
      }

      //console.log('getOpenTokTokenKey', data);
      let urlStr = this.authURL + 'opentok/createtokenforroom/';
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, ' Connectivity issue.');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            callback(undefined, error);
          }
        }
      }, 'POST', data);
    }
  }

  public getOpenTokTokenKeyWithOrg(userObj: object, orgId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void) {
    if (userObj && orgId) {
      let nickname;
      // if (userObj.hasOwnProperty('nickName') && userObj['nickName'])
      //   nickname = userObj['nickName'];
      // else
      nickname = userObj['firstName'] + ' ' + userObj['lastName'].substr(0, 1);
      let data = { 'userId': userObj['_id'], 'organizationId': orgId, 'name': userObj['name'], 'nickname': nickname };

      if (userObj['chat_dnd'] == undefined)
        data['chat_dnd'] = false;
      else
        data['chat_dnd'] = userObj['chat_dnd'];

      if (userObj['isTextChat'] == undefined)
        data['showTextChat'] = false;
      else
        data['showTextChat'] = userObj['isTextChat'];

      if (userObj['isVideoChat'] == undefined)
        data['showVideoChat'] = false;
      else
        data['showVideoChat'] = userObj['isVideoChat'];

      //console.log('createtokenfororg', data);


      let urlStr = this.authURL + 'opentok/createtokenfororg/';
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, ' Connectivity issue.');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            callback(undefined, error);
          }
        }
      }, 'POST', data);
    }
  }

  public getOpenTokTokenKeyForUser(userObj: object, hostId: string, userImage: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void) {
    if (userObj) {
      let data = { 'userId': userObj['_id'], 'name': userObj['name'], 'hostId': hostId, 'userImage': userImage, role: 'publisher' };

      //console.log('createtokenforuser', data);


      let urlStr = this.authURL + 'opentok/createtokenforuser/';
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, ' Connectivity issue.');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            callback(undefined, error);
          }
        }
      }, 'POST', data);
    }
  }

  public getOpenTokTokenKeyForTest(userId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void) {
    if (userId) {
      let data = { 'hostId': userId, 'type': 'test' };
      let urlStr = this.authURL + 'opentok/createtesttoken/';
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, ' Connectivity issue.');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            callback(undefined, error);
          }
        }
      }, 'POST', data);
    }
  }

  public isUserRoleAdmin(): boolean {
    let user = this.currentUser;
    if (user) {
      if (user['resources'].find(role => role['_id'] === environment.customKeys.roleAdmin && role['organizationId'] === this.orgId))
        return true;
    }
    return false;
  }
  public isUserRoleSuperAdmin(): boolean {
    let user = this.currentUser;
    if (user && user.isSuperAdmin) {
      if (user['resources'].find(role => role['_id'] === environment.customKeys.roleAdmin && role['organizationId'] === this.orgId))
        return true;
    }
    return false;
  }
  public getUserRole(): string {
    let user = this.currentUser;
    if (user) {
      if (user['resources'].find(role => role['_id'] === environment.customKeys.roleAdmin && role['organizationId'] === this.orgId))
        return 'admin';
      if (user['resources'].find(role => role['_id'] === environment.customKeys.roleEdit && role['organizationId'] === this.orgId))
        return 'edit';
      if (user['resources'].find(role => role['_id'] === environment.customKeys.roleView && role['organizationId'] === this.orgId))
        return 'view';
    }

    return 'anonymous';
  }
  public getUserRoleByUserDataNoOrg(user): string {
    if (user) {
      if (user['resources'].find(role => role['_id'] == environment.customKeys.roleEdit))
        return 'edit';
      else if (user['resources'].find(role => role['_id'] == environment.customKeys.roleView))
        return 'view';
      else if (user['resources'].find(role => role['_id'] == environment.customKeys.roleAdmin))
        return 'admin';
    }
    return 'anonymous';
  }
  public getUserRoleByUserData(user): string {
    if (user) {
      if (user['resources'].find(role => role['_id'] == environment.customKeys.roleEdit && role['organizationId'] === this.orgId))
        return 'edit';
      else if (user['resources'].find(role => role['_id'] == environment.customKeys.roleView && role['organizationId'] === this.orgId))
        return 'view';
      else if (user['resources'].find(role => role['_id'] == environment.customKeys.roleAdmin && role['organizationId'] === this.orgId))
        return 'admin';
    }
    return 'anonymous';
  }
  public getUserClassRole(classData: any): string {
    return 'anonymous';
  }
  public getUserRoomRole(roomData: any): string {
    if (roomData.hasOwnProperty('owner')) {
      for (let dt of roomData.owner) {
        if (this.currentUser && dt._id === this.currentUser._id) {
          return 'owner';
        }
      }
    }
    return this.getUserRole();
  }
  public getUserSessionRole(roomData: any): string {
    if (roomData.hasOwnProperty('users')) {
      for (let dt of roomData.users) {
        if (this.currentUser && dt._id === this.currentUser._id) {
          return dt.type;
        }
      }
    }
    if (this.getUserRole() === 'admin') {
      return 'admin';
    }
    return 'anonymous'; // Viewer
  }
  public getIsSessionAtAnyTime(sessionData: any): boolean {
    if (sessionData.hasOwnProperty('users')) {
      for (let dt of sessionData.users) {
        if (dt.type === 'attendee' && this.currentUser && dt._id === this.currentUser._id) {
          return dt.sessionAtAnyTime;
        }
      }
    }
    return false;
  }
  public getSessionRoleByType(sessionData: any, type): any[] {
    let userList = [];
    if (sessionData && sessionData.hasOwnProperty('users')) {
      for (let dt of sessionData.users) {
        if (dt.type === type) {
          userList.push(dt);
        }
      }
    }
    return userList;
  }
  public getSessionRoleByUser(sessionData: any, userId: string): string {
    if (sessionData && sessionData.hasOwnProperty('users')) {
      for (let dt of sessionData.users) {
        if (dt._id === userId) {
          return dt.type;
        }
      }
    }
    return 'anonymous';
  }
  public saveQuestion(type: string, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, useOrg: boolean = false, lang?: string) {
    let urlStr = this.authURL + type;
    // urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }
  public getQuestions(type: string, sessionId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + type + '/list/' + sessionId;
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }
  postRequest(type: string, subType: string, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => void) {
    let urlStr = this.authURL + type + '/' + subType;
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    }, 'POST', data);
  }

  getSessionHost(sessionData: any): string {
    if (sessionData.hasOwnProperty('users')) {
      for (let dt of sessionData.users) {
        if (dt.host) {
          return dt._id;
        }
      }
    }
    return undefined;
  }
  getSessionHostObject(sessionData: any): any {
    if (sessionData.hasOwnProperty('users')) {
      for (let dt of sessionData.users) {
        if (dt.host) {
          return dt;
        }
      }
    }
    return undefined;
  }
  public deactivateAllTiles(sessionId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void, lang?: string) {
    let urlStr = this.authURL + 'session/deactivateall/' + sessionId + '/tile';
    urlStr = this.addLanguageToURL(urlStr, lang);
    this.jsonGetRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, error);
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      } else {
        callback(undefined, error);
      }
    });
  }

  startBroadcast(data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    let urlStr = this.authURL + 'opentok/broadcast/start';
    // let urlStr = 'http://localhost:8080/broadcast/start'
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (jsonObj) {
        callback(jsonObj, undefined);
      }
      else
        callback(undefined, error);
    }, 'POST', data);
  }

  endBroadcast(data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    let urlStr = this.authURL + 'opentok/broadcast/stop';
    // let urlStr = 'http://localhost:8080/broadcast/end'
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (jsonObj) {
        callback(jsonObj, undefined);
      }
      else
        callback(undefined, error);
    }, 'POST', data);
  }

  getActiveBroadcast(data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    let urlStr = this.authURL + 'opentok/broadcasts';
    // let urlStr = 'http://localhost:8080/broadcast/list'
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (jsonObj) {
        callback(jsonObj, undefined);
      }
      else
        callback(undefined, error);
    }, 'POST', data);
  }

  setLayout(data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    let urlStr = this.authURL + 'opentok/broadcasts/layout';
    // let urlStr = 'http://localhost:8080/broadcast/layout'
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (jsonObj) {
        callback(jsonObj, undefined);
      }
      else
        callback(undefined, error);
    }, 'POST', data);
  }

  setStreamClass(data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    let urlStr = this.authURL + 'opentok/broadcasts/stream';
    // let urlStr = 'http://localhost:8080/broadcast/layout'
    this.jsonRequest(urlStr, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (jsonObj) {
        callback(jsonObj, undefined);
      }
      else
        callback(undefined, error);
    }, 'POST', data);
  }

  getLocationByGoogle(type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    // this.jsonRequest('https://www.googleapis.com/geolocation/v1/geolocate?key=AIzaSyCXttJj9vjasr-Inv8Kn9hR5iueow40y2Y', (jsonObj, error) => {
    this.jsonRequest('https://api.ipstack.com/check?access_key=' + environment.ipStack.apiKey, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (jsonObj) {
        this.jsonRequest(this.authURL + 'geo/reversegeocode', (obj, error) => {
          if (error !== undefined) {
            callback(undefined, ' Connectivity issue.');
            return;
          }
          if (obj) {
            callback(obj.results, undefined);
          }
          else {
            callback(undefined, error);
          }
          // }, 'POST', { coordinates: jsonObj.results.location.lat + ',' + jsonObj.results.location.lng });
        }, 'POST', { coordinates: jsonObj.latitude + ',' + jsonObj.longitude, resultType: type });
        // callback(jsonObj, undefined);
      }
      else
        callback(undefined, error);
    }, 'GET', undefined, null, undefined, undefined, undefined, undefined, true);
  }

  logUserSession(data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    this.jsonRequest(this.authURL + 'session/user', (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (jsonObj) {
        callback(jsonObj, undefined);
      }
      else
        callback(undefined, error);
    }, 'POST', data);
  }

  getBreakout(breakoutId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    this.jsonGetRequest(this.authURL + 'breakout/' + breakoutId, (obj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (obj) {
        callback(obj.results, undefined);
      }
      else {
        callback(undefined, error);
      }
    });
  }

  getBreakoutsPerSession(sessionId: string, type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    let url = this.authURL + 'session/breakouts/' + sessionId;
    if (type)
      url = url + '/' + type;
    this.jsonGetRequest(url, (obj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (obj) {
        callback(obj.results, undefined);
      }
      else {
        callback(undefined, error);
      }
    });
  }

  deleteBreakout(data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    this.jsonRequest(this.authURL + 'breakout/delete', (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      }
      else
        callback(undefined, error);
    }, 'POST', data);
  }

  updateBreakout(data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    this.jsonRequest(this.authURL + 'breakout/update', (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      }
      else
        callback(undefined, error);
    }, 'POST', data);
  }

  sendSignal(data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    this.jsonRequest(this.authURL + 'opentok/sendsignal', (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      }
      else
        callback(undefined, error);
    }, 'POST', data);
  }
  sendActivity(subject: string, data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    let dataObject = { subject: subject, data: data, email: 'amerghalayini@interactivelife.com' };
    this.jsonRequest(this.authURL + 'email/useractivity', (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          if (jsonObj.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonObj.type);
          }
          callback(undefined, jsonObj.message);
        }
      }
      else
        callback(undefined, error);
    }, 'POST', dataObject);
  }

  generateRtmp(data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    callback(undefined, 'Contact Administration');
    // this.jsonRequest(this.authURL + 'awssdk/push', (jsonObj, error) => {
    //   if (error !== undefined) {
    //     callback(undefined, ' Connectivity issue.');
    //     return;
    //   }
    //   if (jsonObj) {
    //     if (jsonObj.status) {
    //       callback(jsonObj, undefined);
    //     } else {
    //       if (jsonObj.hasOwnProperty('type')) {
    //         this.appStatusSubject.next(jsonObj.type);
    //       }
    //       callback(undefined, jsonObj.message);
    //     }
    //   }
    //   else
    //     callback(undefined, error);
    // }, 'POST', data);
  }

  rtmpPull(data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    callback(undefined, 'Contact Administration');
    // this.jsonRequest(this.authURL + 'awssdk/pull', (jsonObj, error) => {
    //   if (error !== undefined) {
    //     callback(undefined, ' Connectivity issue.');
    //     return;
    //   }
    //   if (jsonObj) {
    //     if (jsonObj.status) {
    //       callback(jsonObj, undefined);
    //     } else {
    //       if (jsonObj.hasOwnProperty('type')) {
    //         this.appStatusSubject.next(jsonObj.type);
    //       }
    //       callback(undefined, jsonObj.message);
    //     }
    //   }
    //   else
    //     callback(undefined, error);
    // }, 'POST', data);
  }
  rtmpDelete(data: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    callback(undefined, 'Contact Administration');
    // this.jsonRequest(this.authURL + 'awssdk/delete', (jsonObj, error) => {
    //   if (error !== undefined) {
    //     callback(undefined, ' Connectivity issue.');
    //     return;
    //   }
    //   if (jsonObj) {
    //     if (jsonObj.status) {
    //       callback(jsonObj, undefined);
    //     } else {
    //       if (jsonObj.hasOwnProperty('type')) {
    //         this.appStatusSubject.next(jsonObj.type);
    //       }
    //       callback(undefined, jsonObj.message);
    //     }
    //   }
    //   else
    //     callback(undefined, error);
    // }, 'POST', data);
  }
  rtmpAction(action, channelId: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    callback(undefined, 'Contact Administration');
    // let data = { channelId: channelId }
    // this.jsonRequest(this.authURL + 'awssdk/' + action, (jsonObj, error) => {
    //   if (error !== undefined) {
    //     callback(undefined, ' Connectivity issue.');
    //     return;
    //   }
    //   if (jsonObj) {
    //     if (jsonObj.status) {
    //       callback(jsonObj, undefined);
    //     } else {
    //       if (jsonObj.hasOwnProperty('type')) {
    //         this.appStatusSubject.next(jsonObj.type);
    //       }
    //       callback(undefined, jsonObj.message);
    //     }
    //   }
    //   else
    //     callback(undefined, error);
    // }, 'POST', data);
  }
  rtmpStatus(channelIds: any[], callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    callback(undefined, 'Contact Administration');
    // let data = { channelIds: channelIds }
    // this.jsonRequest(this.authURL + 'awssdk/status', (jsonObj, error) => {
    //   if (error !== undefined) {
    //     callback(undefined, ' Connectivity issue.');
    //     return;
    //   }
    //   if (jsonObj) {
    //     if (jsonObj.status) {
    //       callback(jsonObj, undefined);
    //     } else {
    //       if (jsonObj.hasOwnProperty('type')) {
    //         this.appStatusSubject.next(jsonObj.type);
    //       }
    //       callback(undefined, jsonObj.message);
    //     }
    //   }
    //   else
    //     callback(undefined, error);
    // }, 'POST', data);
  }

  public getOpenTokSessionKeyForTranslation(hostId: string, sessionId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void) {
    if (hostId && sessionId) {
      let data = { userId: hostId, sessionId: sessionId };

      let urlStr = this.authURL + 'opentok/createsessionfortranslator/';
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, ' Connectivity issue.');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            callback(undefined, error);
          }
        }
      }, 'POST', data);
    }
  }

  public getOpenTokTokenKeyForTranslation(hostId: string, userObj: object, language: string, sessionId: string, role: string, stellarRole: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void) {
    if (userObj && sessionId && role) {
      let data = { 'hostId': hostId, 'userId': userObj['_id'], 'sessionId': sessionId, 'name': userObj['name'], 'role': role, vertical: environment.orgType, 'stellarRole': stellarRole, lang: language };

      let urlStr = this.authURL + 'opentok/createtokenfortranslator/';
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, ' Connectivity issue.');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            callback(undefined, error);
          }
        }
      }, 'POST', data);
    }
  }

  public createMultipleSessions(hostIds: any, sessionId: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => void) {
    if (hostIds && hostIds.length) {
      let data = { 'userIds': hostIds, 'sessionId': sessionId, 'enableArchive': false };

      // if (type) {
      //   data['type'] = type;
      // }

      let urlStr = this.authURL + 'opentok/createMultipleSessions/';
      this.jsonRequest(urlStr, (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, ' Connectivity issue.');
          return;
        }
        if (jsonObj) {
          if (jsonObj.status) {
            callback(jsonObj, undefined);
          } else {
            callback(undefined, error);
          }
        }
      }, 'POST', data);
    }
  }

  getDataById(id: string, type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    let url = this.authURL + type + '/' + id;
    this.jsonGetRequest(url, (obj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (obj) {
        callback(obj.results, undefined);
      }
      else {
        callback(undefined, error);
      }
    });
  }

  getSpotLightData(sessionId: string, userId: string, type: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    let url = this.authURL + 'session/spotlight/' + sessionId;

    if (userId)
      url += '/' + userId;

    url += '/' + type;

    this.jsonGetRequest(url, (obj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (obj) {
        callback(obj.results, undefined);
      }
      else {
        callback(undefined, error);
      }
    });
  }

  logTrackEvent(data: EventTrack, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    // let urlStr = this.authURL + 'opentok/createtokenfortranslator/';
    data.o = this.orgId;

    if (!data.u)
      data.u = this.currentUser?._id;

    if (!data.n)
      data.n = this.currentUser?.name;

    if (!data.i)
      data.i = this.currentUser?.email;

    this.jsonRequest(environment.trackingUrl + '/create', (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          callback(undefined, error);
        }
      }
    }, 'POST', data);
  }

  searchES(query: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    if (query) {
      this.jsonRequest(environment.trackingUrl + '/search', (jsonObj, error) => {
        if (error !== undefined) {
          callback(undefined, ' Connectivity issue.');
          return;
        }
        if (jsonObj) {
          callback(jsonObj, undefined);
        }
      }, 'POST', query);
    }
  }
  public onGetStationDataByCallLetter(stationId: string): Observable<{}> {
    // headers.append('Authorization', 'bearer ' + this.token);
    let url = this.quuURL + '/stationdetails?callLetters=' + stationId + '&type=json';
    return this.http.get(url).pipe(map((response: any) => {
      let jsonObj = response;
      if (jsonObj) {
        return { status: true, result: jsonObj };
      } else {
        return { status: false, message: 'Get Station  Unsuccessful' };
      }
    }));
  }
  public onGetStationData(stationId: string): Observable<{}> {
    // headers.append('Authorization', 'bearer ' + this.token);
    let url = this.quuURL + '/stationdetails?id=' + stationId + '&type=json';
    return this.http.get(url).pipe(map((response: any) => {
      let jsonObj = response;
      if (jsonObj) {
        return { status: true, result: jsonObj };
      } else {
        return { status: false, message: 'Get Station  Unsuccessful' };
      }
    }));
  }

  public getQuuNowPlaying(stationId: string): Observable<{}> {
    return this.http.get(this.quuURL + '/webnowplaying?callLetters=' + stationId + '&type=json').pipe(map((response: any) => {
      let jsonObj = response;
      if (jsonObj) {
        return { status: true, result: jsonObj };
      } else {
        return { status: false, message: 'Now playing unsuccessful' };
      }
    }));
  }

  public getQuuStationDetails(stationId: string): Observable<{}> {
    return this.http.get(this.quuURL + '/stationdetails?callLetters=' + stationId + '&type=json').pipe(map((response: any) => {
      let jsonObj = response;
      if (jsonObj) {
        return { status: true, result: jsonObj };
      } else {
        return { status: false, message: 'Station details unsuccessful' };
      }
    }));
  }

  public getQuuAdDetails(stationId: string, adId: string): Observable<{}> {
    return this.http.get(this.quuURL + '/ad?id=' + adId + '&callLetters=' + stationId + '&type=json').pipe(map((response: any) => {
      let jsonObj = response;
      if (jsonObj) {
        return { status: true, result: jsonObj };
      } else {
        return { status: false, message: 'Ad unsuccessful' };
      }
    }));
  }

  public getQuuSong(stationId: string, songId: string): Observable<{}> {
    return this.http.get(this.quuURL + '/song?id=' + songId + '&callLetters=' + stationId + '&type=json').pipe(map((response: any) => {
      let jsonObj = response;
      if (jsonObj) {
        return { status: true, result: jsonObj };
      } else {
        return { status: false, message: 'Now playing unsuccessful' };
      }
    }));
  }

  public getQuuPlaylist(stationId: string, limit: number = 15): Observable<{}> {
    return this.http.get(this.quuURL + '/mobilewebplaylist?callLetters=' + stationId + '&type=json&limit=' + limit).pipe(map((response: any) => {
      let jsonObj = response;
      if (jsonObj) {
        return { status: true, result: jsonObj };
      } else {
        return { status: false, message: 'Playlist unsuccessful' };
      }
    }));
  }

  public getPLS(url: string): Observable<{}> {
    return this.http.get(url, { responseType: 'text' as 'json' }).pipe(map((response: any) => {
      if (response)
        return pls.parse(response);
      return;
    }));
  }

  public emailQuuCoupon(stationId: string, email: string, itemId: string): Observable<{}> {
    return this.http.get(this.quuURL + '/SendCoupon?id=' + itemId + ' &email=' + email + '&callLetters=' + stationId + '&type=json').pipe(map((response: any) => {
      if (response) {
        return { status: true, result: response };
      } else {
        return { status: false, message: 'email coupon unsuccessful' };
      }
    }));
  }

  public getQuuBanner(stationId: string): Observable<{}> {
    return this.http.get(this.quuURL + '/adbanner?callLetters=' + stationId + '&type=json').pipe(map((response: any) => {
      if (response) {
        return { status: true, result: response };
      } else {
        return { status: false, message: 'Banner unsuccessful' };
      }
    }));
  }

  public signupCloudLive(userId: any, canCreateMeet: number = 0, canStream: number = 0, name: string, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    let url = this.authURL + 'resource/user/cloudlivesignup?userId=' + userId + '&canCreateMeet=' + canCreateMeet + '&canStream=' + canStream + '&canUpload=0&organizationId=' + this.orgId;

    if (name)
      url += '&name=' + name;

    this.jsonGetRequest(url, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (jsonObj) {
        callback(jsonObj, undefined);
      }
      else
        callback(undefined, error)
    });
  }

  public getCloudLive(userId: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    this.jsonGetRequest(this.authURL + 'resource/user/cloudliveget/' + userId + '?organizationId=' + this.orgId, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (jsonObj) {
        callback(jsonObj, undefined);
      }
      else
        callback(undefined, error)
    });
  }

  public getCloudLiveMeeting(userId: any, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    this.jsonGetRequest(this.authURL + 'resource/user/cloudlivegetmeeting/' + userId + '?organizationId=' + this.orgId, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (jsonObj) {
        if (jsonObj.status) {
          callback(jsonObj, undefined);
        } else {
          callback(undefined, error);
        }
      }
      else
        callback(undefined, error)
    });
  }

  public createCloudLiveMeeting(userId: any, sessionId: any, isBroadcast: number = 1, callback: (dataResponse: any | undefined, requestError: any | undefined) => any) {
    let url = this.authURL + 'resource/user/cloudlivecreatemeeting/?userId=' + userId + '&sessionId=' + sessionId + '&startLiveMeet=' + isBroadcast + '&organizationId=' + this.orgId;
    this.jsonGetRequest(url, (jsonObj, error) => {
      if (error !== undefined) {
        callback(undefined, ' Connectivity issue.');
        return;
      }
      if (jsonObj) {
        callback(jsonObj, undefined);
      }
      else
        callback(undefined, error)
    });
  }

  // public getSymblToken(): Observable<{}> {
  //   let headers = {
  //     'accept': 'application/json',
  //     'content-type': 'application/json'
  //   };
  //   let httpOptions = {
  //     headers: new HttpHeaders(headers)
  //   }

  //   return this.http.post('https://api.symbl.ai/oauth2/token:generate', {
  //     "type": "application",
  //     "appId": "5755535637366a51744652496e336f4654356e4945585654394e645a5330456f",
  //     "appSecret": "786e5439696a6542307238305150622d596c4870366f717a6c586a6f4275624d74573935556a386937556a424535376f7a474741674b306d372d413950433758"
  //   }, httpOptions).pipe(map((response: any) => {
  //     if (response) {
  //       return { status: true, result: response };
  //     } else {
  //       return { status: false, message: 'Token unsuccessful' };
  //     }
  //   }));
  // }

  public getAblyToken() {
    let headers = {
      'Authorization': this.token,
    };
    headers['productid'] = environment.productId;
    if (this.orgId && this.orgId !== '') {
      headers['organizationid'] = this.orgId;
      headers['integratedid'] = this.orgId;
      headers['vertical'] = this.orgType;
    }
    return this.http.get(this.authURL + 'resource/ably/auth', {
      headers: headers,
      responseType: 'text' as 'json'
    }).pipe(map((response: any) => {
      if (response) {
        let jsonResponse = JSON.parse(response);
        if (jsonResponse.status)
          return { status: true, result: jsonResponse.results };
        else {
          if (jsonResponse.hasOwnProperty('type')) {
            this.appStatusSubject.next(jsonResponse.type);
          }
          return { status: false, message: 'ably token unsuccessful' };
        }
      }
      else {
        return { status: false, message: 'ably token unsuccessful' };
      }
    }));
    // this.http.get(this.authURL + 'resource/auth', {
    //   headers: {
    //     'Authorization': this.token
    //   }
    // }).subscribe(
    //   (data) => {
    //     console.log('daya', data)
    //   });
    // this.jsonGetRequest(this.authURL + 'resource/auth', (jsonObj) => {
    //   console.log('s', jsonObj)
    //   // if (error !== undefined) {
    //   //   callback(undefined, ' Connectivity issue.');
    //   //   return;
    //   // }
    //   // if (jsonObj) {
    //   //   callback(jsonObj, undefined);
    //   // }
    //   // else
    //   //   callback(undefined, error)
    // });
  }
}
