<div class="col-xl-12 posterDetails" *ngIf="data && dataContent">
	<h1 mat-dialog-title style="margin: 0 0 5px;">
		<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
			<div fxFlex="90">
				<span title="{{dataContent.name}}">{{dataContent.name | truncate : ['100','...']}}</span>
				<button mat-raised-button class="voteButtons" [class.voteButtonsSelected]="dataContent.voted"
					(click)="voteUp()" title="{{dataContent.voted ? 'Voted' : 'Vote for this poster' | translate}}">
					<mat-icon *ngIf="!dataContent.voted" style="height: 26px;vertical-align: middle;margin-right: 5px;">
						thumb_up_off_alt</mat-icon>
					<mat-icon class="voted" *ngIf="dataContent.voted"
						style="height: 26px;vertical-align: middle;margin-right: 5px;">thumb_up</mat-icon>
					<span *ngIf="!dataContent.voted">{{'Vote for this poster' | translate}}</span>
					<span *ngIf="dataContent.voted">{{'Voted' | translate}}</span>
				</button>
			</div>
			<div fxFlex="10" *ngIf="pictureLink">
				<mat-icon (click)="maximizeIt()" title="{{ 'Maximize' | translate }}"
					style="cursor:pointer;float: right;">open_in_new</mat-icon>
			</div>
		</div>
		<!-- <button mat-raised-button class="voteButtons" [class.voteButtonsSelected]="dataContent.voted"
			(click)="voteUp()" title="{{dataContent.voted ? 'Voted' : 'Vote' | translate}}">
			<mat-icon *ngIf="!dataContent.voted" style="height: 26px;vertical-align: middle;">thumb_up_off_alt</mat-icon>
			<mat-icon class="voted" *ngIf="dataContent.voted" style="height: 26px;vertical-align: middle;">thumb_up</mat-icon>
			<span *ngIf="!dataContent.voted">{{'Click to Vote' | translate}}</span>
			<span *ngIf="dataContent.voted">{{'Voted' | translate}}</span>
		</button> -->
	</h1>
	<div mat-dialog-content [class.mat-dialog-content-mobile]="isMobile">
		<div *ngIf="dataContent">
			<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
				<div fxFlex="70" class="information">
					<div class="author-name" *ngIf="dataContent.author && dataContent.author.length > 0"><span
							*ngFor="let user of dataContent['author'];let index = index"><span *ngIf="index > 0">,
							</span>{{user.name}}</span>
					</div>
				</div>
				<!-- <div fxFlex="30" style="text-align: right;">
						<div class="voteIcon">
							<mat-icon *ngIf="!dataContent.voted" (click)="voteUp()" title="{{'Vote' | translate}}" style="height: 30px;vertical-align: middle;">thumb_up_off_alt</mat-icon>
							<mat-icon class="voted" *ngIf="dataContent.voted" title="{{'Voted' | translate}}" style="height: 30px;vertical-align: middle;">thumb_up</mat-icon>
						</div>
					</div> -->
			</div>
			<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px"
				*ngIf="pictureLink && pictureLink !== ''">
				<div fxFlex>
					<!-- <figure class="card-profile-image">
								<img [src]="pictureLink"class="responsive-img">
						</figure> -->
					<img [src]="pictureLink" class="responsive-img">
				</div>
			</div>
			<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px"
				*ngIf="dataContent.details && dataContent.details !== ''">
				<div fxFlex>
					<span *ngIf="dataContent && dataContent.details && dataContent.details !== ''"
						(click)="openLink($event, dataContent.details)" class="url-details">{{'Additional Info' |
						translate}}
					</span>
				</div>
			</div>
		</div>
	</div>
	<div mat-dialog-actions>
		<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
			<div fxFlex fxLayoutAlign="start center">
				<span>
					<button mat-raised-button color="danger" (click)="closeModal(voted)">{{'Close' |
						translate}}</button>
				</span>
			</div>
			<!-- <div fxFlex fxLayoutAlign="end center">
					<span (click)="voteUp()" class="voteButtons">
						<img *ngIf="!dataContent.voted" src="/assets/images/vote.png" title="{{'Click to Vote' | translate}}">
						<img *ngIf="dataContent.voted" src="/assets/images/voted.png" title="{{'Voted' | translate}}">
					</span>
				</div> -->
		</div>
	</div>
</div>