import { Component, OnInit, ElementRef, ViewChild, ChangeDetectorRef, TemplateRef, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { RequestService, LayoutUtilsService, LoaderService, SubheaderService, RoomSessionService, RequestExternalService, FeaturesPermissionService } from '../../../shared/services';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';
import { EventData } from '../../../shared/modules/calendar/interface/event-data';
import { CustomSelectCreateAutocompleteComponent } from '../custom-select-create-autocomplete/custom-select-create-autocomplete.component';
import { TranslateService } from '@ngx-translate/core';
import { ModalDialogComponent } from 'src/app/shared/components/custom-dialog/custom-dialog.component';
import { ModalViewEventDialogComponent } from '../../../shared/modules/calendar/view-event-dialog/view-event-dialog.component';
import { Moment } from 'moment';
import * as moment from 'moment';
import { utc } from 'moment';
import { DefaultSettings } from '../layout-components/defaultSettings';
import { ConfirmEntityDialogComponent } from '../modals/confirm-entity-dialog/confirm-entity-dialog.component';
import { CalendarComponent } from '../../modules/calendar';
import { addUrlParam } from '../../helpers';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-custom-scheduler',
  templateUrl: './custom-scheduler.component.html',
  styleUrls: ['./custom-scheduler.component.scss']
})
export class CustomSchedulerComponent implements OnInit {
  public subscriptions: any[] = <any>[];
  public selectedUser: any;
  public organization = undefined;
  public isAdmin: boolean = false;
  public isSuperAdmin: boolean = false;
  public originalTableSetting: any = undefined;
  public metaFieldSetting = undefined;
  public tableSetting: any = undefined;
  public settingOrgObject = undefined;
  public loading: boolean = false;
  public permissions = {
    canViewEvent: false,
    canAddEvent: false,
    canEditEvent: false,
    canDeleteEvent: false,
    canCreateEvent: false
  }

  public peopleType: any = DefaultSettings.peopleSingleType;
  public peopleTypeKeys: any = DefaultSettings.peopleTypeKeys;
  public hasRolesOrgRequired: boolean = false;
  public viewDesignMode: string = !environment.integratedProduct ? 'calendar_month' : 'format_list_bulleted';
  public vertical = environment.orgType;
  public title = environment.serverName;
  public subTitle = environment.subServerName;
  public dataType: string = 'schedule/event';
  public dataTypeDisplay: string = this.translate.instant('Event');
  public eventsActionsType: any = {
    'blueprint-occurrence': { displayName: 'Event From Blueprint', color: '#008000', type: 'blueprint' },
    'occurrence': { displayName: 'Event Occurrence', color: '#242852', type: 'default' },
    'blueprint': { displayName: 'Event Blueprint', color: '#ff0000', type: 'blueprint' },
  }
  public eventsActions: any[] = [];
  public currentDay: any = undefined;
  public currentWeek: any = undefined;
  public dataSource: any = { events: <EventData[]>[], skelleton: <EventData[]>[] };
  public dataArray: EventData[] = [];
  public dataSkelletonArray: EventData[] = [];
  public maxRange: number = 5;

  public isMobile: boolean = false;
  public isTablet: boolean = false;
  public dataListAll: any[] = [];
  public dataListBlueprintAll: any[] = [];
  public errorMessage: string = '';
  public filterData = undefined;
  public disableEventBlueprints = false;
  public selectedFilter = undefined;
  @Input() doLoadData: boolean = true;
  @Input() personal: boolean = false;
  @Input() dateFormat: string = 'dd-MM-yyyy';
  utc = utc;
  @ViewChild('customViewEventTemplate') public customViewEventTemplate: TemplateRef<any>;
  @ViewChild('blueprintList') public blueprintList: CustomSelectCreateAutocompleteComponent;
  @ViewChild('calendarComp') public calendarComp: CalendarComponent;

  constructor(protected router: Router, protected loaderService: LoaderService,
    protected requestService: RequestService,
    protected requestExternalService: RequestExternalService,
    protected dialog: MatDialog,
    protected snackBar: MatSnackBar, protected translate: TranslateService,
    protected layoutUtilsService: LayoutUtilsService,
    protected changeDetectorRefs: ChangeDetectorRef, protected activatedRoute: ActivatedRoute,
    protected subheaderService: SubheaderService, protected roomSessionService: RoomSessionService, protected featuresPermissionService: FeaturesPermissionService, protected deviceService: DeviceDetectorService) { }

  ngOnInit() {
    this.ngOnInitCall();
  }
  ngOnInitCall() {
    this.isMobile = this.deviceService.isMobile();
    this.isTablet = this.deviceService.isTablet();
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.isAdmin = this.requestService.isUserRoleAdmin();
          this.isSuperAdmin = this.requestService.isUserRoleSuperAdmin();
          // this.loadDBData();
          this.filterData = this.getBlueprintCustomFilter();
          this.buildSetting();
          // console.log(moment.localeData().longDateFormat('L'));
        }
      })
    );

    this.subscriptions.push(
      this.requestService.pageOrganization.subscribe((data) => {
        if (data) {
          this.organization = data;
          this.hasRolesOrgRequired = (data.roleCount && data.roleCount > 0);
          if (data.hasOwnProperty('settings')) {
            // console.log(data.settings);
            this.settingOrgObject = data.settings;
            if (this.settingOrgObject && this.settingOrgObject.web && this.settingOrgObject.web.page && this.settingOrgObject['web']['page']['enableScheduler']) {
              // can access
            } else {
              this.router.navigate(['/rooms/list'], { relativeTo: this.activatedRoute });
            }
          }
        }
      })
    );
    this.subscriptions.push(
      this.featuresPermissionService.currentSettingsSubject.subscribe(currentSettings => {
        if (currentSettings) {
          if (currentSettings.web && currentSettings.web.global) {
            if (currentSettings.web.global.hasOwnProperty('disableEventBlueprints')) {
              this.disableEventBlueprints = currentSettings.web.global.disableEventBlueprints;
            }
          }
        }
      })
    );
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  goTo(path) {
    this.router.navigate([path]);
  }
  private buildSetting() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getMetaData(this.dataType, undefined, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error: ' + error, 'Dismiss');
        }
        this.loading = false;
        if (data) {
          this.originalTableSetting = Object.assign({}, data.results);
          this.metaFieldSetting = this.buildMetaSetting(data.results, parent = undefined);
          if (this.disableEventBlueprints) {
            this.eventsActions = [];
          } else {
            this.eventsActions = this.metaFieldSetting['eventType'].enum;
          }
          // this.eventsActions.push({displayName: 'Event From Blueprint', value: 'blueprint-occurrence'});
          const copiedItem = JSON.parse(JSON.stringify(this.originalTableSetting));
          this.tableSetting = this.getSetting(copiedItem, this.getCustomFilter());
          // console.log('this.metaFieldSetting', this.metaFieldSetting);
          if (this.isAdmin) {
            this.permissions.canAddEvent = true;
            this.permissions.canEditEvent = true;
            this.permissions.canDeleteEvent = true;
          }
          this.permissions.canViewEvent = true;
          if (this.doLoadData) {
            this.loadDBData();
          } else {
            this.loaderService.display(false);
            this.loading = false;
          }
        } else {
          console.log(this.translate.instant('Something is Wrong'));
        }
      });
    }
  }
  buildMetaSetting(data, parent = undefined) {
    let dataObject = {};
    for (let col of data.fields) {
      if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
        if (parent) {
          col['inputName'] = parent + col['name'];
        }
        dataObject[col.name] = col;
      } else if (col.type === 'object') {
        dataObject[col.name] = this.buildMetaSetting(col);
      }
      else if (col.type === 'table') {
        dataObject[col.name] = col;
      }
    }
    return dataObject;
  }
  selectDay(day) {
    if (!this.permissions.canAddEvent) {
      return;
    }
    if (this.selectedFilter) {
      return;
    }
    this.addEvent({ action: 'occurrence', day: day });
  }
  canCreateFromBluePrint(event) {
    let isCurrentUser = false;
    if (event.eventCreator && event.eventCreator.length > 0) {
      isCurrentUser = event.eventCreator.filter((itm) => {
        if (itm.type === 'user' && this.selectedUser._id === itm._id) {
          return true;
        } else if (this.hasRolesOrgRequired && itm.type === 'role' && this.selectedUser.role && this.selectedUser.role.length > 0 && this.selectedUser.role[0]._id === itm._id) {
          return true;
        }
        return false;
      }
      ).length > 0;
    } else {
      isCurrentUser = true;
    }
    return isCurrentUser;
  }
  isAnAttendee(event) {
    let isCurrentUser = false;
    if (event.opentoall) {
      isCurrentUser = true;
    } else {
      if (event.attendees && event.attendees.length > 0) {
        isCurrentUser = event.attendees.filter((itm) => {
          if (itm.type === 'user' && this.selectedUser._id === itm._id) {
            return true;
          } else if (this.hasRolesOrgRequired && itm.type === 'role' && this.selectedUser.role && this.selectedUser.role.length > 0 && this.selectedUser.role[0]._id === itm._id) {
            return true;
          }
          return false;
        }
        ).length > 0;
      }
    }
    return isCurrentUser;
  }
  selectEvent(event) {
    // console.log('selectEvent', event);
    let momentUntil = moment().utc();
    let enddate = moment(event.event.enddate).utc();
    if (this.isAdmin || (!this.isAdmin && event.event.eventType !== 'blueprint')) {
      if (!this.permissions.canViewEvent) {
        return;
      }
      let tableSetting = JSON.parse(JSON.stringify(this.tableSetting));
      let canEditEvent = this.permissions.canEditEvent;
      let canDeleteEvent = this.permissions.canDeleteEvent;
      let canCreateEvent = false;
      let canOpen = (event.event.eventType !== 'blueprint');

      if (event.event.eventType === 'blueprint') {
        canCreateEvent = this.canCreateFromBluePrint(event.event);
      }
      if (event.event.createdBy === this.selectedUser._id) {
        canDeleteEvent = true;
        canEditEvent = true;
      }
      if (momentUntil.diff(enddate, 'minutes') > 0 && !this.isAdmin) {
        canEditEvent = false;
        canDeleteEvent = false;
        canCreateEvent = false;
        canOpen = false;
      }
      if (event.event.meetingType === '2') {
        canOpen = false;
      }
      if (event.event.alwaysOpen) {
        canOpen = true;
      }
      let currentEvent = JSON.parse(JSON.stringify(event.event));
      // below is refering to an event created by an external client we have no access to edit or delete it
      if (currentEvent.client_org && currentEvent.client_org === this.organization._id) {
        canEditEvent = false;
        canDeleteEvent = false;
        canCreateEvent = false;
      }
      let attendeesHost = undefined;
      let attendeesDataList = {
        lead: [], // Speaker
        guest: [],  // Moderator
        attendee: [], // Attendee
        moderator: [], // Producer
        interpreter: [] // Interpreter
      }
      let attendeesRoles = [];

      if (currentEvent.attendees && currentEvent.attendees.length > 0) {
        for (let itm of currentEvent.attendees) {
          if (itm.type === 'role') {
            attendeesRoles.push(itm);
          } else if (itm.type === 'user') {
            if (itm.host) {
              attendeesHost = itm;
            } else {
              attendeesDataList[itm.userType].push(itm);
            }
          }
        }
      }
      currentEvent['attendeesHost'] = attendeesHost;
      currentEvent['attendeesDataList'] = attendeesDataList;
      currentEvent['attendeesRoles'] = attendeesRoles;
      const dialogRef = this.dialog.open(ModalViewEventDialogComponent, {
        width: '800px',
        data: {
          title: currentEvent.name,
          data: currentEvent,
          fields: tableSetting.fields,
          canEdit: canEditEvent,
          canDelete: canDeleteEvent,
          canCreate: canCreateEvent,
          canOpen: canOpen,
          dateFormat: this.dateFormat,
          contentTemplate: this.customViewEventTemplate
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if (result === 'edit') {
            this.editEvent(event.event);
          } else if (result === 'delete') {
            this.deleteEvent(event.event);
          } else if (result === 'open') {
            this.openRoomEvent(event.event);
          } else if (result === 'create') {
            if (momentUntil.diff(enddate, 'minutes') < 0) {
              this.addEvent({ action: 'blueprint-occurrence', blueprint: event.event, event: event });
            } else {
              this.layoutUtilsService.showNotification(this.translate.instant('This event has ended. There are no future available dates.'), this.translate.instant('Dismiss'));
            }
          }
        }
      });
    } else {
      if (momentUntil.diff(enddate, 'minutes') < 0) {
        this.addEvent({ action: 'blueprint-occurrence', blueprint: event.event, event: event });
      } else {
        this.layoutUtilsService.showNotification(this.translate.instant('This event has ended. There are no future available dates.'), this.translate.instant('Dismiss'));
      }
    }
  }
  openRoomEvent(event) {
    if (event.place === '1') {
      this.router.navigate(['/rooms/' + event.room._id + '/event/' + event._id], { relativeTo: this.activatedRoute });
    } else if (event.place === '2' && event.meetingurl !== '') {
      if (event.client_org && event.client_org === this.organization._id) {
        this.router.navigate(['/integratediframe'], { queryParams: { iframeUrl: event.meetingurl } }).then(() => {
          setTimeout(() => {
            window.location.reload()
          }, 100);
        });
      } else {
        window.open(event.meetingurl, "_blank");
      }
    } else {
      // do nothing
    }
  }
  deleteEvent(event) {
    // if (!this.permissions.canDeleteEvent) {
    //   return;
    // }
    this.confirmDelete(event);
  }
  editEvent(event) {
    // console.log('editEvent', event);
    let tableSetting = JSON.parse(JSON.stringify(this.tableSetting));
    // console.log('tableSetting', tableSetting);
    let eventTypeName = this.translate.instant('Event');
    if (event.eventType === 'blueprint') {
      eventTypeName = 'Blueprint';
    }
    let title = this.translate.instant('Edit') + ' ' + eventTypeName;
    if (event.eventType === 'blueprint') {
      title = this.translate.instant('Edit') + ' ' + this.translate.instant('Blueprint');
      tableSetting['customSettings']['attendees'] = {
        nullable: true,
        visible: false,
      };
      tableSetting['customSettings']['eventCreatorType'] = {
        visible: true
      };
      tableSetting['customSettings']['eventCreator'] = {
        visible: true
      };
      tableSetting['customSettings']['color'] = {
        visible: true
      };
      tableSetting['customSettings']['isMht'] = {
        visible: true
      };
    } else if (event.eventType === 'occurrence') {
      tableSetting['customSettings']['maxNumberofHourAllowed'] = {
        visible: false
      };
      tableSetting['customSettings']['attendees'] = {
        nullable: false
      };
      tableSetting['customSettings']['color'] = {
        visible: true
      };
      tableSetting['customSettings']['isMht'] = {
        visible: true
      };
      if (event.blueprint && event.blueprint._id && event.blueprint._id !== '') {
        tableSetting['customSettings']['opentoall'] = {
          visible: true,
          disabled: true
        };
        tableSetting['customSettings']['settingType'] = {
          visible: true,
          disabled: true
        };
        tableSetting['customSettings']['meetingType'] = {
          visible: true,
          disabled: true
        };
        tableSetting['customSettings']['place'] = {
          visible: true,
          disabled: true
        };
        tableSetting['customSettings']['layout'] = {
          visible: true,
          disabled: true
        };
        tableSetting['customSettings']['room'] = {
          visible: true,
          disabled: true
        };
        tableSetting['customSettings']['meetingurl'] = {
          visible: true,
          disabled: false
        };
        tableSetting['customSettings']['location'] = {
          visible: true,
          disabled: false
        };
        tableSetting['customSettings']['isRecurrence'] = {
          visible: true,
          disabled: true
        };
        tableSetting['customSettings']['enableChat'] = {
          visible: true,
          disabled: true
        }
        tableSetting['customSettings']['color'] = {
          visible: true,
          disabled: true
        };
        tableSetting['customSettings']['isMht'] = {
          visible: true,
          disabled: true
        };
        tableSetting['customSettings']['startdate'] = {
          visible: true,
          disabled: true
        };
        tableSetting['customSettings']['enddate'] = {
          visible: true,
          disabled: true
        };
      }
    }
    tableSetting['customSettings']['capacity'] = {
      visible: false
    }
    const dialogRef = this.dialog.open(ModalDialogComponent, {
      width: '800px',
      data: {
        dataType: this.dataType,
        dataTypeTitle: this.translate.instant('Event'),
        title: title,
        data: event,
        modalSetting: tableSetting
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.data.parent_key && result.data.parent_key !== '') {
          // check session data 
          this.roomSessionService.openSessionById(result.data.parent_key, (session) => {
            if (session) {
              // let currentDate: Moment = moment.utc();
              // let startD: Moment = moment.utc(session.startsAt).local();
              // let endD: Moment = moment.utc(session.endAt).local();
              if (session.active) {
                // this.userConfirmEdit(result.data);
                this.layoutUtilsService.alertElement('Alert', this.translate.instant('This event is live right now. You will be able to edit it once the session ends.'));
              } else {
                this.confirmEdit(result.data, eventTypeName);
              }
            } else {
              this.confirmEdit(result.data, eventTypeName);
            }
          });

        } else {
          this.confirmEdit(result.data, eventTypeName);
        }
      }
    });
  }

  userConfirmEdit(data, eventTypeName) {
    let alertSetting = {};
    alertSetting['overlayClickToClose'] = false;
    alertSetting['showCloseButton'] = false;
    alertSetting['confirmText'] = this.translate.instant('Yes');
    alertSetting['declineText'] = this.translate.instant('No');
    // alertSetting['timerEvent'] = 120;

    let activityDialog = this.layoutUtilsService.alertActionElement('Confirmation', 'This event has already started. Are you sure you want to continue?', alertSetting);
    activityDialog.afterClosed().subscribe(res => {
      if (res) {
        // if(res.action === 'declineText'){
        //   // this.subscribeActivity(); // do nothing
        // }else
        if (res.action === 'confirmText') {
          activityDialog.close();
          this.confirmEdit(data, eventTypeName);
        } else {
          // do nothing
        }
      }
    });
  }
  dayShift(event) {
    if (this.currentWeek !== event.currentWeek) {
      this.currentWeek = event.currentWeek;
    }
    if (!this.currentDay || (event.currentDay && this.currentDay && (this.currentDay.month != event.currentDay.month || this.currentDay.year != event.currentDay.year))) {
      if (event.status === 'init' || event.status === 'change') {
        this.currentDay = event.currentDay;
      }
      if (event.status === 'change') {
        this.loadDBData();
      }
    }
  }
  addEvent(event) {
    // console.log('addEvent', event);
    let tableSetting = JSON.parse(JSON.stringify(this.tableSetting));
    let action = event.action;
    let eventTypeName = this.translate.instant('Event');
    if (action === 'blueprint') {
      eventTypeName = 'Blueprint';
    }
    let title = this.translate.instant('Create') + ' New ' + eventTypeName;
    if (action === 'blueprint-occurrence') {
      action = 'occurrence';
      let filters = {
        "foreign_key": { "$eq": this.requestService.orgId },
        'project': { '$eq': 'stellar-' + this.vertical },
        'eventType': { '$eq': 'blueprint' }
      };
      if (!this.isSuperAdmin && !this.isAdmin) {
        filters["$or"] = [
          {
            "createdBy": { "$eq": this.selectedUser._id }
          },
          { "eventCreatorType": { $size: 0 } },
          {
            "$and": [
              {
                "eventCreator.type": "user"
              }
              ,
              {
                "eventCreator._id": this.selectedUser._id
              }
            ]
          }
        ]
        if (this.selectedUser.role && this.selectedUser.role.length > 0) {
          filters["$or"].push({
            "$and": [
              {
                "eventCreator.type": "role"
              }
              ,
              {
                "eventCreator._id": this.selectedUser.role[0]?._id
              }
            ]
          })
        }
      }
      let blueprint = { _id: event.blueprint._id, name: event.blueprint.name };
      tableSetting['customSettings']['blueprint'] = {
        visible: true,
        disabled: true,
        nullable: false,
        control: true,
        filters: filters,
        blueprint: event.blueprint,
        value: blueprint,
      };
      tableSetting['customSettings']['name'] = {
        visible: true,
        controller: 'blueprint',
        value: event.blueprint.name
      };
      tableSetting['customSettings']['description'] = {
        visible: true,
        controller: 'blueprint',
        value: event.blueprint.description
      };
      tableSetting['customSettings']['capacity'] = {
        visible: false,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.capacity
      };
      tableSetting['customSettings']['opentoall'] = {
        visible: true,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.opentoall
      };
      tableSetting['customSettings']['attendees'] = {
        visible: true,
        disabled: false,
        nullable: false,
        controller: 'blueprint',
        value: this.getUserAsAttendee()
        // value: event.blueprint.attendees || []
      };
      tableSetting['customSettings']['settingType'] = {
        visible: true,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.settingType
      };
      tableSetting['customSettings']['meetingType'] = {
        visible: true,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.meetingType
      };
      tableSetting['customSettings']['place'] = {
        visible: false,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.place
      };
      tableSetting['customSettings']['layout'] = {
        visible: false,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.layout
      };
      tableSetting['customSettings']['room'] = {
        visible: false,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.room
      };
      tableSetting['customSettings']['meetingurl'] = {
        visible: false,
        disabled: false,
        controller: 'blueprint',
        value: event.blueprint.meetingurl
      };
      tableSetting['customSettings']['location'] = {
        visible: false,
        disabled: false,
        controller: 'blueprint',
        value: event.blueprint.location
      };
      tableSetting['customSettings']['maxNumberofHourAllowed'] = {
        visible: true,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.maxNumberofHourAllowed
      };
      tableSetting['customSettings']['startdate'] = {
        visible: true,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.startdate
      };
      tableSetting['customSettings']['enddate'] = {
        visible: true,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.enddate
      };
      tableSetting['customSettings']['isRecurrence'] = {
        visible: true,
        disabled: true,
        value: false,
        // controller: 'blueprint' always value false
      };
      tableSetting['customSettings']['enableChat'] = {
        visible: true,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.enableChat
      }

      tableSetting['customSettings']['color'] = {
        visible: true,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.color
      };
      tableSetting['customSettings']['isMht'] = {
        visible: true,
        disabled: true,
        controller: 'blueprint',
        value: event.blueprint.isMht
      };
    } else if (action === 'blueprint') {
      // tableSetting['customSettings']['isRecurrence'] =  {
      //   visible: false,
      //   disabled: true,
      //   value: false
      // };
      tableSetting['customSettings']['attendees'] = {
        nullable: true,
        visible: false,
      };
      tableSetting['customSettings']['eventCreatorType'] = {
        visible: true
      };
      tableSetting['customSettings']['eventCreator'] = {
        visible: true
      };
      tableSetting['customSettings']['color'] = {
        visible: true,
        value: '#242852'
      };

      tableSetting['customSettings']['isMht'] = {
        visible: true
      };
    } else if (action === 'occurrence') {

      tableSetting['customSettings']['isMht'] = {
        visible: true
      };
      tableSetting['customSettings']['capacity'] = {
        visible: false
      }
      tableSetting['customSettings']['maxNumberofHourAllowed'] = {
        visible: false
      };
      tableSetting['customSettings']['attendees'] = {
        nullable: false,
        value: this.getUserAsAttendee()
      };
      if (event.hasOwnProperty('day')) {
        let val = new Date();
        if (event.day.hasOwnProperty('hour')) {
          val = new Date(event.day.year, event.day.month, event.day.day, event.day.hour.split(':')[0], event.day.hour.split(':')[1]);
        } else {
          val = new Date(event.day.year, event.day.month, event.day.day, val.getHours(), val.getMinutes());
        }
        tableSetting['customSettings']['startdate'] = {
          visible: true,
          value: val
        };
        tableSetting['customSettings']['enddate'] = {
          visible: true,
          value: val
        };
      }
      tableSetting['customSettings']['color'] = {
        visible: true,
        value: '#242852'
      };
    }

    tableSetting['customSettings']['capacity'] = {
      visible: false
    }

    tableSetting['customSettings']['eventType'] = {
      visible: false,
      value: action
    };
    // console.log('tableSetting', tableSetting);
    const dialogRef = this.dialog.open(ModalDialogComponent, {
      width: '800px',
      data: {
        dataType: this.dataType,
        dataTypeTitle: this.translate.instant('Event'),
        title: title,
        data: {},
        modalSetting: tableSetting
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.confirmCreate(result.data, eventTypeName);
      }
    });
  }

  private getSetting(data, filters) {
    let tableSetting = data;
    tableSetting['target'] = 'parent';
    if (filters)
      tableSetting['filters'] = filters;
    tableSetting['customSettings'] = {
      foreign_key: {
        visible: false,
        value: this.requestService.orgId
      },
      createdBy: {
        visible: false,
        value: this.selectedUser._id
      },
      project: {
        visible: false,
        value: 'stellar-' + this.vertical
      }
    };
    return tableSetting;
  }
  getUserAsAttendee() {
    return [{ _id: this.selectedUser._id, name: this.selectedUser.name, email: this.selectedUser.email, type: 'user', userType: 'lead', host: true }];
  }
  private getCustomFilter() {
    let filters = {
      "foreign_key": { "$eq": this.requestService.orgId },
      'project': { '$eq': 'stellar-' + this.vertical },
      'eventType': { '$ne': 'blueprint' }
    };
    if (!this.isSuperAdmin && !this.isAdmin) {
      filters["$or"] = [
        {
          "createdBy": { "$eq": this.selectedUser._id }
        },
        {
          "opentoall": true
        },
        {
          "$and": [
            {
              "attendees.type": "user"
            }
            ,
            {
              "attendees._id": this.selectedUser._id
            }
          ]
        }
      ]
      if (this.selectedUser.role && this.selectedUser.role.length > 0) {
        filters["$or"].push({
          "$and": [
            {
              "attendees.type": "role"
            }
            ,
            {
              "attendees._id": this.selectedUser.role[0]?._id
            }
          ]
        })
      }
    }
    return filters;
  }
  private getBlueprintCustomFilter() {
    let filters = {
      "foreign_key": { "$eq": this.requestService.orgId },
      'project': { '$eq': 'stellar-' + this.vertical },
      'eventType': { '$eq': 'blueprint' }
    };
    if (!this.isSuperAdmin && !this.isAdmin) {
      filters["$or"] = [
        {
          "createdBy": { "$eq": this.selectedUser._id }
        },
        { "eventCreatorType": { $size: 0 } },
        {
          "$and": [
            {
              "eventCreator.type": "user"
            }
            ,
            {
              "eventCreator._id": this.selectedUser._id
            }
          ]
        }
      ]
      if (this.selectedUser.role && this.selectedUser.role.length > 0) {
        filters["$or"].push({
          "$and": [
            {
              "eventCreator.type": "role"
            }
            ,
            {
              "eventCreator._id": this.selectedUser.role[0]?._id
            }
          ]
        })
      }
    }
    if (this.selectedFilter && this.selectedFilter !== '') {
      filters['_id'] = { '$eq': this.selectedFilter };
    }
    return filters;
  }
  selectBlueprint(e) {
    this.selectedFilter = e;
    this.dataSource = { events: [], skelleton: [], notInitChanges: true };
    this.loadDBData(undefined, false, true);
  }
  handleReturnBlueprint(e) {
    // console.log('handleReturnBlueprint', e);
    if (e) {
      if (e === 'delete') {
        this.selectedFilter = undefined;
        this.dataSource = { events: [], skelleton: [], notInitChanges: true };
      }
      this.loadDBData();
    }
  }
  getFullDay(currentDay) {
    return new Date(this.currentDay.year, this.currentDay.month, 1);
  }
  loadDBData(focusDay = undefined, goToDayMonth = false, goToDayMonthBluePrint = false) {
    // if (this.selectedFilter && this.selectedFilter !== '') {
    //   this.loadBlueprintDBData([]);
    // } else {
    if (!this.loading) {
      this.loading = true;
      this.loaderService.display(true);

      // let mom = moment;
      let currentStartDate = moment().utc();
      let currentEndDate = moment().utc();
      if (this.currentDay) {
        let fullDate = this.getFullDay(this.currentDay);
        currentStartDate = moment(fullDate).utc();
        currentEndDate = moment(fullDate).utc();
      }
      // let termConfiguration = this.termConfiguration();
      let startFilter = currentStartDate.subtract(this.maxRange, 'months');
      let endFilter = currentEndDate.add(this.maxRange, 'months');
      let filters = {
        'foreign_key': { '$eq': this.requestService.orgId },
        'project': { '$eq': 'stellar-' + this.vertical },
        // 'client_org': { '$eq': this.organization._id },
        'eventType': { '$ne': 'blueprint' },
        'startdate': { '$gte': startFilter.format('YYYY-MM-DDTHH:mm') },
        'enddate': { '$lt': endFilter.format('YYYY-MM-DDTHH:mm') }
      }
      if (!this.isSuperAdmin && !this.isAdmin) {
        filters["$or"] = [
          {
            "createdBy": { "$eq": this.selectedUser._id }
          },
          {
            "opentoall": true
          },
          {
            "$and": [
              {
                "attendees.type": "user"
              }
              ,
              {
                "attendees._id": this.selectedUser._id
              }
            ]
          }
        ]

        if (this.selectedUser.role && this.selectedUser.role.length > 0) {
          filters["$or"].push({
            "$and": [
              {
                "attendees.type": "role"
              }
              ,
              {
                "attendees._id": this.selectedUser.role[0]?._id
              }
            ]
          })
        }
      }
      // let filters = {
      //   "$and": [
      //     { 'foreign_key': { '$eq': this.requestService.orgId } },
      //     { 'project': { '$eq': 'stellar-' + this.vertical } },
      //     { 'eventType': { '$ne': 'blueprint' } },
      //     { 'startdate': { '$gte': startFilter.format('YYYY-MM-DDTHH:mm')}},
      //     // { 'currentuser-attendees': { '$eq': true } },
      //     // { 'currentuser-eventCreator': { '$eq': true } }
      //     // {"startdate": { "$gte": "2021-11-01T00:00:00"}},
      //     {"enddate": {"$lt": endFilter.format('YYYY-MM-DDTHH:mm')}}
      //   ]
      // };
      if (this.selectedFilter && this.selectedFilter !== '') {
        filters['blueprint._id'] = { '$eq': this.selectedFilter };
      }
      let filterObj = { page: 1, perpage: 1000, term: '', orderDir: 'asc', orderBy: 'name', fieldKeys: [], filter: filters };
      this.requestService.getDataList(this.dataType,
        filterObj
        , (data, error) => {
          //console.log(data);
          if (error) {
            this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
            this.loaderService.display(false);
            this.loading = false;
          }
          if (data) {
            let dataList = data.results || [];
            this.requestExternalService.callApiMethod('getMeetingList', dataList, (dataX, errorX) => {
              this.loaderService.display(false);
              this.loading = false;
              if (errorX) {
                this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + 'External Client Error', this.translate.instant('Dismiss'));
              }
              if (dataX) {
                let dataList = dataX;
                if (this.personal) {
                  dataList = dataList.filter((itm) => {
                    if (this.isAnAttendee(itm)) {
                      return true;
                    }
                    return false;
                  })
                }
                this.dataListAll = dataList;
                if (this.disableEventBlueprints) {
                  dataList = dataList.filter((itm) => {
                    if (itm.eventType === 'blueprint-occurrence') {
                      return false;
                    }
                    return true;
                  })
                  this.setUpData(dataList, [], focusDay, goToDayMonth, goToDayMonthBluePrint);
                  this.loaderService.display(false);
                  this.loading = false;
                } else {
                  if (this.selectedFilter && this.selectedFilter !== '') {
                    this.loadBlueprintDBData(dataList, focusDay, goToDayMonth, goToDayMonthBluePrint);
                  } else {
                    this.setUpData(dataList, [], focusDay, goToDayMonth, goToDayMonthBluePrint);
                    this.loaderService.display(false);
                    this.loading = false;
                  }
                }
              }
            });
          }
        });
    }
    // }
  }
  loadBlueprintDBData(events, focusDay = undefined, goToDayMonth = false, goToDayMonthBluePrint = false) {
    if (!this.loading) {
      this.loading = true;
      this.loaderService.display(true);
      // let termConfiguration = this.termConfiguration();
      let filters = this.getBlueprintCustomFilter();

      let filterObj = { page: 1, perpage: 1000, term: '', orderDir: 'asc', orderBy: 'name', fieldKeys: [], filter: filters };
      this.requestService.getDataList(this.dataType,
        filterObj
        , (data, error) => {
          //console.log(data);
          if (error) {
            this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
          }
          if (data) {
            let dataList = data.results || [];
            // console.log('dataList', dataList);
            if (this.personal) {
              dataList = dataList.filter((itm) => {
                if (this.canCreateFromBluePrint(itm)) {
                  return true;
                }
                return false;
              })
            }
            this.dataListBlueprintAll = dataList;
            this.setUpData(events, dataList, focusDay, goToDayMonth, goToDayMonthBluePrint);
          }
          this.loaderService.display(false);
          this.loading = false;
        });
    }
  }
  setUpData(events, skelleton, focusDay = undefined, goToDayMonth = false, goToDayMonthBluePrint = false) {
    this.dataArray = events.map((itm) => {
      if (!itm['color'] || (itm['color'] && (itm['color'] === '' || itm['color'] === '#ffffff'))) {
        if (this.eventsActionsType.hasOwnProperty(itm.eventType)) {
          itm['color'] = this.eventsActionsType[itm.eventType].color;
        } else {
          itm['color'] = '#242852';
        }
      }
      return itm;
    });
    this.dataSkelletonArray = skelleton.map((itm) => {
      if (!itm['color'] || (itm['color'] && (itm['color'] === '' || itm['color'] === '#ffffff'))) {
        if (this.eventsActionsType.hasOwnProperty(itm.eventType)) {
          itm['color'] = this.eventsActionsType[itm.eventType].color;
        } else {
          itm['color'] = '#242852';
        }
      }
      return itm;
    });
    this.dataSource = { events: this.dataArray, skelleton: this.dataSkelletonArray, focusDay: focusDay, goToDayMonth: goToDayMonth, goToDayMonthBluePrint: goToDayMonthBluePrint };
  }
  public confirmCreate(dataToCreate, eventTypeName) {
    if (!this.loading) {
      this.loading = true;
      //  this.loaderService.display(true);
      this.errorMessage = '';
      this.requestService.saveData(this.dataType, dataToCreate, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          let returnResult = data.results;
          if (dataToCreate.eventType === 'blueprint') {
            this.selectBlueprint(returnResult._id);
          } else {
            this.loadDBData(dataToCreate);
          }
          this.layoutUtilsService.showNotification(eventTypeName + ' ' + this.translate.instant('created Successfully'), this.translate.instant('Dismiss'));
        }
      });
    }
  }
  public confirmEdit(dataToUpdate, eventTypeName) {
    if (!this.loading) {
      this.loading = true;
      //  this.loaderService.display(true);
      this.errorMessage = '';
      this.requestService.saveData(this.dataType, dataToUpdate, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.loadDBData(dataToUpdate);
          if (dataToUpdate.eventType === 'blueprint') {
            this.blueprintList.loadData();
          }
          this.layoutUtilsService.showNotification(eventTypeName + ' ' + this.translate.instant('edited Successfully'), this.translate.instant('Dismiss'));
        }
      });
    }
  }
  confirmDelete(event) {
    if (event.isRecurrence && event.eventType !== 'blueprint') {
      let mainMsg = 'You are deleting an event with recurrence. How do you want to proceed?';
      let confirmBtn = 'Keeping past events and deleting the current and future';
      let declineBtn = 'Deleting all events existing and current';
      this.handleConfirmChangeModal(mainMsg, confirmBtn, declineBtn, (callbackStatus) => {
        if (callbackStatus !== undefined) {
          let data = { _id: event._id, eventType: event.eventType }
          if (callbackStatus === true) {
            data['overWrite'] = '4';
          } else if (callbackStatus === false) {
            data['overWrite'] = '5';
          }
          this.confirmRealDelete(data);
        }
      });
    } else {
      this.delete(event);
    }
  }
  public delete(event: any) {
    let id = event._id;
    if (!this.loading) {
      let dataTypeDisplay = this.dataTypeDisplay;
      if (event.eventType === 'blueprint') {
        dataTypeDisplay = 'Blueprint';
      }
      const _title: string = dataTypeDisplay + ' ' + this.translate.instant('Deletion');
      const _description: string = this.translate.instant('Are you sure you want to permanently delete this') + ' ' + dataTypeDisplay + '?';
      const _waitDesciption: string = this.translate.instant('Deleting') + '...';

      const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
      dialogRef.afterClosed().subscribe(res => {
        if (!res) {
          return;
        }
        this.realDelete(event);
      });
    }
  }
  public confirmRealDelete(data) {
    let dataTypeDisplay = this.dataTypeDisplay;
    if (data.eventType === 'blueprint') {
      dataTypeDisplay = 'Blueprint';
    }
    const _deleteMessage = dataTypeDisplay + ' ' + this.translate.instant('Deleted Successfully') + '.';
    if (!this.loading) {
      this.loading = true;
      //  this.loaderService.display(true);
      this.errorMessage = '';
      this.requestService.saveData(this.dataType, data, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.loadDBData();
          this.layoutUtilsService.showNotification(_deleteMessage, this.translate.instant('Dismiss'));
        }
      });
    }
  }
  public realDelete(event: any) {
    let id = event._id;
    let dataTypeDisplay = this.dataTypeDisplay;
    if (event.eventType === 'blueprint') {
      dataTypeDisplay = 'Blueprint';
    }
    const _deleteMessage = dataTypeDisplay + ' ' + this.translate.instant('Deleted Successfully') + '.';
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.deleteSingleData(this.dataType, id, (data, error) => {
        if (error) {
          this.errorMessage = error;
          if (error === 'This event is live right now. You will be able to delete it once the session ends.') {
            this.layoutUtilsService.alertElement('Alert', error);
          } else {
            this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
          }
        }
        this.loading = false;
        if (data) {
          this.layoutUtilsService.showNotification(_deleteMessage, this.translate.instant('Dismiss'));
          if (event.eventType === 'blueprint') {
            this.selectedFilter = undefined;
            this.dataSource = { events: [], skelleton: [], notInitChanges: true };
            this.loadDBData(undefined, true);
            if (this.blueprintList) {
              this.blueprintList.loadData();
            }
          } else {
            this.loadDBData();
          }
        }
      });
    }
  }
  private handleConfirmChangeModal(mainMsg: string, confirmBtn: string, declineBtn: string, callback: (dataResponse: boolean) => void, smallMsg: string = undefined) {
    let alertSetting = {};
    alertSetting['overlayClickToClose'] = false;
    alertSetting['showCloseButton'] = true;
    alertSetting['smallMsg'] = smallMsg;
    alertSetting['confirmText'] = confirmBtn;
    alertSetting['declineText'] = declineBtn;

    const dialogRef = this.layoutUtilsService.alertActionElement(this.translate.instant('Confirm Changes'), this.translate.instant(mainMsg), alertSetting);
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.action === 'confirmText') {
          callback(true);
        } else if (result.action === 'declineText') {
          callback(false);
        }
      } else {
        callback(undefined);
      }
    });
  }
}
