import { Component, OnInit, ChangeDetectorRef, OnDestroy, Input, Output, EventEmitter, ViewChild, ElementRef, OnChanges, AfterViewChecked, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { TokBoxService } from 'src/app/shared/services/tokbox.service';

@Component({
    selector: 'app-camera-play',
    templateUrl: './camera-play.component.html',
    styleUrls: ['./camera-play.component.scss']
})

export class SpeakerCameraComponent implements OnInit, OnDestroy {

    private subscriptions: Subscription[] = [];
    audioOn: boolean = false;
    cameraOn: boolean = false;
    hovered: boolean = false;

    @Input('attendeeMap') attendeeMap: Map<string, object>;
    @Input() disableIcon: boolean = false;
    @Input() session: OT.Session;
    @Input() iconSize: string = '5em';
    @Input() labelSize: string = '1em';
    @Input() firstTime: boolean = true;
    @Input() disableIcons: boolean = false;
    @Input() id: string = undefined;
    @Input() uuid: string = undefined;
    @Input() prefixId: string = undefined;
    @Input() showAudioVideoLabels: boolean = true;
    @Input() isSpeaking: boolean = false;
    @Input() showAudioStatus: boolean = false;
    @Input() name: string = undefined;
    @Input() showLabel: boolean = true;
    @Input()
    set stopMediaFlag(stopMediaFlag: any) {
        if (stopMediaFlag)
            this.stopMedia();
    }
    // @Input()
    // set mediaFlags(mediaFlags: any) {
    //     if (mediaFlags) {
    //         if (mediaFlags.hasOwnProperty('audio'))
    //             this.audioOn = mediaFlags.audio;
    //         if (mediaFlags.hasOwnProperty('camera'))
    //             this.cameraOn = mediaFlags.camera;
    //         this.firstTime = false;
    //     }
    // }

    @Output('permitCamera') permitCamera = new EventEmitter<any>();
    @Output('permitAudio') permitAudio = new EventEmitter<any>();
    @Output('permitMedia') permitMedia = new EventEmitter<any>();

    @ViewChild('publisherCameraDiv') publisherCameraDiv: ElementRef;
    @ViewChild('camera') cameraDiv: ElementRef;

    constructor(
        private opentokService: TokBoxService, private changeDetectorRef: ChangeDetectorRef) { }

    ngOnInit() {
        // this.subscriptions.push(this.opentokService.userJoinedPanel.subscribe((value) => {
        //     if (value != undefined && value.user.userId === this.id) {
        //         this.disableIcons = !value.joined;
        //         if (value.joined)
        //             this.name = value.user.name;
        //     }
        // }));

        this.subscriptions.push(this.opentokService.controlThisStream.subscribe((value) => {
            if (value) {
                let data = JSON.parse(value.connection.data);
                if (data.userId === this.id || value.name === this.uuid) {
                    // if (data['stellarRole'] === 'guest' || data['stellarRole'] === 'lead') {
                    if (!value['destroyed']) {
                        this.cameraOn = value.hasVideo;
                        this.audioOn = value.hasAudio;
                    }
                    else {
                        this.cameraOn = false;
                        this.audioOn = false;
                    }
                    // }
                }
                else if (!this.id && data.userId) {
                    this.cameraOn = false;
                    this.audioOn = false;
                }

                this.detectChanges();
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

    toggleCamera(bool: boolean) {
        if (!this.disableIcon) {
            if (this.firstTime) {
                // this.audioOn = bool;
                // this.cameraOn = bool;
                this.permitMedia.emit({ bool: bool, id: this.id, uuid: this.uuid });
                this.firstTime = false;
            }
            else {
                // this.cameraOn = bool;
                this.permitCamera.emit({ bool: bool, id: this.id, uuid: this.uuid });
            }
        }
    }

    toggleAudio(bool: boolean) {
        if (!this.disableIcon) {
            this.firstTime = false;
            // this.audioOn = bool;
            this.permitAudio.emit({ bool: bool, id: this.id, uuid: this.uuid });
        }
    }

    detectChanges() {
        if (!this.changeDetectorRef['destroyed']) {
            this.changeDetectorRef.detectChanges();
        }
    }

    toggleCameraActions(bool: boolean) {
        this.hovered = bool;
        this.detectChanges();
    }

    stopMedia() {
        this.toggleAudio(false);
        this.toggleCamera(false);
    }
}
