import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { RequestService, LayoutUtilsService, LoaderService, TokBoxService, RoomSessionService, SchedulerService } from '../../../../shared/services';
import { SchedulerTasks } from '../';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { getOtherImage, isValidHttpUrl } from '../../../../shared/helpers';
import { TranslateService } from '@ngx-translate/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmCkeditorEntityDialogComponent } from '../../modals/confirm-ckeditor-entity-dialog/confirm-ckeditor-entity-dialog.component';
import { ConfirmCameraEntityDialogComponent } from '../../modals/confirm-camera-entity-dialog/confirm-camera-entity-dialog.component';
import { ShareVideoDialogComponent } from '../share-video-dialog/share-video-dialog.component';
import { PanelistsUpdateDialogComponent } from '../panelists-update-dialog/panelists-update-dialog.component';
import { SimpleAlertEntityDialogComponent } from '../../modals/simple-entity-dialog/simple-entity-dialog.component';
import { ModalAdvancedGalleryDialogComponent } from '../../custom-advanced-gallery-dialog/custom-advanced-gallery-dialog.component';
import { PartyStreamDialogComponent } from '../party-stream-dialog/party-stream-dialog.component';
import { roundDecimal, guid } from '../../../../shared/helpers';
import { update } from 'lodash';
import videojs from 'video.js';
import { Moment } from 'moment';
import * as moment from 'moment';

import { Idle } from '@ng-idle/core';
import { IframeComponent } from '../iframe/iframe.component';
import { AblyService } from 'src/app/shared/services/ably.service';
declare var CKEDITOR;


@Component({
  selector: 'app-scheduler-component',
  templateUrl: './scheduler-component.component.html',
  styleUrls: ['./scheduler-component.component.scss']
})
export class SchedulerComponent implements OnInit {
  public subscriptions: any[] = <any>[];
  public apiCallSchedulerSubscription: Subscription = undefined;
  public apiCallSettingsSubscription: Subscription = undefined;
  public selectedUser: any;
  public errorMessage: string = '';
  public loading: boolean = false;
  public tasksName: any = SchedulerTasks.tasksName;
  public tasksNameKeys: any[] = SchedulerTasks.tasksNameKeys;
  public tasksKeysToShow: any[] = [];
  public schedulerEnabled: boolean = false;
  public taskSelected: string = '';
  public currentSessionId = undefined;

  public sessionData = undefined;
  public viewMode: string = 'desktop';
  public schedulerTasks = [];
  public producersList = [];
  public guestsList = [];
  public leadCamerasList = [];
  public leadCamerasId = '';
  public guestCamerasList = [];
  public guestCamerasListIds = [];
  public panelList = [];
  public panelId = '';
  public panelGroups = [];
  // public panelListIds = [];
  public editorsList = [];
  public iframeList = [];
  public iframeListIds = [];
  public editorsListIds = [];
  public defaultvideoList = [];
  public defaultvideoListIds = [];
  public picturesList = [];
  public picturesListIds = [];
  public picturesListIdsNotFixed = [];
  public leadList = [];
  public tileList = [];
  public sessionList = [];
  public sessionTaskList = [];
  public selectedTask = undefined;
  public hasChanged: boolean = false;

  public doRefresherTimer = undefined;

  public hasRtmpPull: number = 0;
  public hasUpdatePanel: number = 0;
  public hasGenerateRTMP: number = 0;
  public sessionStarted: boolean = false;
  public sessionRTMP: any = { rtmpUrl: '', hlsOutput: '', channelId: '', streamKey: '' };

  public layoutHasShareComponent: boolean = false;
  public layoutHasBreakoutChecked: boolean = false;

  public sessionRTMPLinks: any = {};

  public isKeepActive: boolean = false;
  public _zIndex: number = 0;
  private _zIndexTemp: number = 0;

  public disablePlaceholders: boolean = false;

  public editorConfig: any = {
    toolbar: [
      // { name: 'document', items: ['Source', '-'] },
      { name: 'clipboard', items: ['Undo', 'Redo', '-'] },
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', '-'] },
      { name: 'links', items: ['Link', 'Unlink', '-'] },
      { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize', '-'] },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
      { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl'] },
    ],
    height: '310px',
    shiftEnterMode: CKEDITOR.ENTER_DIV,
    enterMode: CKEDITOR.ENTER_DIV,
    removePlugins: 'magicline',
    linkShowAdvancedTab: false,
    linkShowTargetTab: false,
    resize_enabled: false,
    versionCheck: false
  };
  public cameraEditorConfig: any = {
    toolbar: [
      // { name: 'document', items: ['Source', '-'] },
      { name: 'clipboard', items: ['Undo', 'Redo', '-'] },
      { name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', '-'] },
      { name: 'links', items: ['Link', 'Unlink', '-'] },
      { name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize', '-'] },
      { name: 'colors', items: ['TextColor', 'BGColor'] },
    ],
    height: '310px',
    shiftEnterMode: CKEDITOR.ENTER_DIV,
    enterMode: CKEDITOR.ENTER_DIV,
    removePlugins: 'magicline',
    linkShowAdvancedTab: false,
    linkShowTargetTab: false,
    resize_enabled: false
  };

  public sessionfilterData = {
    '$and': [
      { 'organizationId._id': { '$eq': this.requestService.orgId } }
    ]
  };

  private _session: OT.Session = undefined;

  @Input()
  set session(session: OT.Session) {
    if (session) {
      this._session = session;
      session.on("signal:syncTaskManager", (event) => {
        let sender = JSON.parse(event['from']['data']);
        if (sender['userId'] !== this.selectedUser._id) {
          this.refreshSessionData();
        }
      });
    }
  };
  get session() {
    return this._session;
  }
  @Input() roomId: any = undefined;
  @Input() sessionId: any = undefined;
  @Input()
  set hide(hide: boolean) {
    this.schedulerEnabled = !hide;
    if (!hide) {
      // this._zIndex = this._zIndexTemp + 1;
      // this.opentokService.zIndexDraggable.next(this._zIndex);
      this.dragged();
      // console.log('hide', hide);
      this.refreshSessionData(); // triggers i commented the one in init
    }
  }
  @Input() isHost: boolean = false;

  @Output() hideDialog = new EventEmitter<boolean>(undefined);
  // @Output() actionReturn = new EventEmitter<any>();
  constructor(private layoutUtilsService: LayoutUtilsService, private requestService: RequestService, private translate: TranslateService, public renderer: Renderer2, private router: Router, public dialog: MatDialog, private activatedRoute: ActivatedRoute, private changeDetectorRef: ChangeDetectorRef, private loaderService: LoaderService, private schedulerService: SchedulerService, private opentokService: TokBoxService, public roomSessionService: RoomSessionService, private idle: Idle, private ablyService: AblyService) {
    this.ablyService.subscribeToSessionSignals('syncTaskManager', (sender) => {
      if (sender !== this.selectedUser._id) {
        this.refreshSessionData();
      }
    }, this.sessionId);
  }

  ngOnInit() {
    this.refreshSessionData();
    this.subscriptions.push(this.opentokService.zIndexDraggable.subscribe(value => {
      if (value != undefined) {
        this._zIndexTemp = value;
      }
    }));
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
        }
      })
    );
    this.subscriptions.push(this.activatedRoute
      .queryParams
      .subscribe(params => {
        if (params.openScheduler) {
          setTimeout(() => {
            this.hideDialog.emit(false);
          }, 100);
        }
      })
    );
    this.subscriptions.push(this.schedulerService.callBackScheduler.subscribe(value => {
      if (this.selectedUser.email === 'amerghalayini@interactivelife.com' || this.selectedUser.email === 'admintest@interactivelife.com') {
        console.log('callBackScheduler', value);
      }
      if (value != undefined) {
        if (value.task != undefined) {
          this.callGaurdAction(value.index, value.task, value.sendSignal, false, value.skipActive || false);
        }
      }
    }));
    // this.subscriptions.push(this.schedulerService.pushCameraOverlay.subscribe(task => {
    //   if (this.selectedUser.email === 'amerghalayini@interactivelife.com' || this.selectedUser.email === 'admintest@interactivelife.com') {
    //     console.log('pushCameraOverlay', task);
    //   }
    // }));
    this.subscriptions.push(this.schedulerService.addTask.subscribe(task => {
      if (this.selectedUser.email === 'amerghalayini@interactivelife.com' || this.selectedUser.email === 'admintest@interactivelife.com') {
        console.log('addTask', task);
      }
      if (task != undefined) {
        this.insertCustomTask(task);
      }
    }));
    this.subscriptions.push(this.schedulerService.findAndUpdateTasks.subscribe(value => {
      if (this.selectedUser.email === 'amerghalayini@interactivelife.com' || this.selectedUser.email === 'admintest@interactivelife.com') {
        console.log('findAndUpdateTasks', value);
      }
      if (value) {
        if (value.hasOwnProperty('action')) {
          if (value.action !== 'play') {
            let schedulerTasks = JSON.parse(JSON.stringify(this.schedulerTasks));
            schedulerTasks.forEach((task, index) => {
              if (task.name === value.name && (!value.uniqueId || (value.uniqueId && task.uniqueId === value.uniqueId))) {
                task.action = value.action;
                // below code applies for 'start-lead-camera', 'start-guest-camera'
                if (task.hasOwnProperty('overlay') && task.overlay['running']) {
                  task.overlay['running'] = false;
                  task.overlay['updatedAt'] = undefined;
                }
                if (task.hasOwnProperty('overlay') && value.hasOwnProperty('updatedAt')) {
                  task.overlay['updatedAt'] = value.updatedAt;
                }
              }
            });
            this.schedulerTasks = schedulerTasks;
            this.updatescheduler(true, schedulerTasks, (data) => {
              if (data) {
                this.syncWithTeamMembers();
              }
            });
          }
        } else if (value.hasOwnProperty('target')) {
          if (value.target && value.target !== '') {
            let schedulerTasks = JSON.parse(JSON.stringify(this.schedulerTasks));
            schedulerTasks.forEach((task, index) => {
              if (task.name === value.name && (value.uniqueId && task.uniqueId === value.uniqueId)) {
                task[value.target] = value[value.target];
                if (task.hasOwnProperty('overlay') && value.hasOwnProperty('updatedAt')) {
                  task.overlay['updatedAt'] = value.updatedAt;
                }
              }
            });
            this.schedulerTasks = schedulerTasks;
            this.updatescheduler(true, schedulerTasks, (data) => {
              if (data) {
                this.syncWithTeamMembers();
              }
            });
          }
        }
      }
    }));

    this.currentSessionId = JSON.parse(JSON.stringify(this.sessionId));
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  setupSessionData(sessionData) {
    this.sessionData = JSON.parse(JSON.stringify(sessionData));
    this.isKeepActive = sessionData.isKeepActive;
    this.sessionStarted = this.sessionData.active;
    if (sessionData.users) {
      this.guestsList = sessionData.users.filter((user) => user.type === 'guest');
      this.leadList = sessionData.users.filter((user) => user.type === 'lead');
      this.producersList = sessionData.users.filter((user) => user.type === 'moderator');
    }
    if (sessionData.tiles) {
      this.tileList = sessionData.tiles;
    }
    if (sessionData.configurations) {
      if (sessionData.configurations.hasOwnProperty('rtmpUrl') && sessionData.configurations.hasOwnProperty('hlsOutput') && sessionData.configurations.hasOwnProperty('channelId') && sessionData.configurations.hasOwnProperty('streamKey')) {
        this.sessionRTMP.rtmpUrl = sessionData.configurations.rtmpUrl;
        this.sessionRTMP.hlsOutput = sessionData.configurations.hlsOutput;
        this.sessionRTMP.channelId = sessionData.configurations.channelId;
        this.sessionRTMP.streamKey = sessionData.configurations.streamKey;
      }
    }
    // console.log('this.tileList', this.tileList);
    if (this.sessionData.scheduler) {
      let schedulerTasks = [];
      if (sessionData.scheduler.hasOwnProperty('tasks')) {
        schedulerTasks = sessionData.scheduler.tasks.filter((tsk) => {
          return this.tasksName.hasOwnProperty(tsk.name);
        }
        );
        schedulerTasks = schedulerTasks.map((tsk) => {
          if (!tsk.hasOwnProperty('uniqueId')) {
            tsk['uniqueId'] = guid();
          }
          if (['start-lead-camera', 'start-guest-camera'].includes(tsk.name) && !tsk.hasOwnProperty('overlay')) {
            tsk['overlay'] = this.tasksName[tsk.name].content.overlay
          }
          return tsk;
        }
        );
      }
      this.schedulerTasks = schedulerTasks;
    }
    // this.schedulerService.schedulerTasks.next(this.sessionData.scheduler.tasks);
    this.analyseLayoutData();
    this.analyseSchedulerData();
    // this.analyseRTMPLinksData();
  }
  analyseRTMPLinksData(doRefresh: boolean = false) {
    let channelsIds = [];
    if (this.sessionRTMP.channelId && this.sessionRTMP.channelId !== '') {
      channelsIds.push(this.sessionRTMP.channelId);
    }
    let getRTMPChannels: any = this.schedulerTasks.filter((tsk) => tsk.name === 'rtmp-pull');
    for (let chnls of getRTMPChannels) {
      if (chnls.channelId && chnls.channelId !== '') {
        channelsIds.push(chnls.channelId);
      }
    }
    this.requestService.rtmpStatus(channelsIds, (data, error) => {
      if (error) {
        //do nothing
      }
      if (data) {
        this.sessionRTMPLinks = data.results;
        // if(doRefresh && !this.doRefresherTimer){
        //   for(let keys of channelsIds){
        //     if (this.sessionRTMPLinks.hasOwnProperty(keys) && this.sessionRTMPLinks[keys] !== 'running') {
        //       this.doRefresherTimer = setTimeout(() => {
        //         this.analyseRTMPLinksData();
        //       }, 30000);
        //       break;
        //     }
        //   }
        // }
      }
    });
  }

  showRTMPPullGenerated(channelId) {
    this.requestService.rtmpStatus([channelId], (data, error) => {
      if (error) {
        //do nothing
      }
      if (data) {
        this.sessionRTMPLinks[channelId] = data.results[channelId];
        if (data.results[channelId] !== 'running') {
          setTimeout(() => {
            this.showRTMPPullGenerated(channelId);
          }, 30000);
        }
      }
    });
  }
  msort(arr: any[]) {
    for (var i = 0; i < arr.length; i++) {
      for (var j = i + 1; j < arr.length; j++) {
        if (this.translate.instant(this.tasksName[arr[i]].displayName) > this.translate.instant(this.tasksName[arr[j]].displayName)) {
          var swap = arr[i];
          arr[i] = arr[j];
          arr[j] = swap;
        }
      }
    }
    return arr;
  }
  analyseLayoutData() {
    this.layoutHasShareComponent = false;
    this.layoutHasBreakoutChecked = false;
    if (this.sessionData && this.sessionData.hasOwnProperty('settings')) {
      let currentSettings = JSON.parse(JSON.stringify(this.sessionData['settings']));
      if (currentSettings.hasOwnProperty(this.viewMode)) {
        let settingObject = JSON.parse(JSON.stringify(currentSettings[this.viewMode]));
        let activeSharescreens = [];
        for (let col of settingObject['columns']) {
          this.guestCamerasList = col['components'].filter((comp) => comp.name === 'guest-camera' && comp.active);
          this.guestCamerasListIds = this.guestCamerasList.map((comp) => comp['uid']);
          this.leadCamerasList = col['components'].filter((comp) => comp.name === 'presenter-camera' && comp.active);
          if (this.leadCamerasList.length > 0) {
            this.leadCamerasId = this.leadCamerasList[0]['uid'];
          }
          this.editorsList = col['components'].filter((comp) => comp.name === 'editor' && comp.active);
          this.editorsListIds = this.editorsList.map((comp) => comp['uid']);
          this.iframeList = col['components'].filter((comp) => comp.name === 'iframe' && comp.active);
          this.iframeListIds = this.iframeList.map((comp) => comp['uid']);
          this.picturesList = col['components'].filter((comp) => comp.name === 'picture' && comp.active);
          this.picturesListIds = this.picturesList.map((comp) => comp['uid']);
          this.defaultvideoList = col['components'].filter((comp) => comp.name === 'defaultvideo' && comp.active);
          this.defaultvideoListIds = this.defaultvideoList.map((comp) => comp['uid']);
          this.panelList = col['components'].filter((comp) => comp.name === 'panelView' && comp.active);
          if (this.panelList.length > 0) {
            this.panelId = this.panelList[0]['uid'];
            if (this.panelList[0]['groups'] && this.panelList[0]['groups'].length > 0) {
              this.panelGroups = this.panelList[0]['groups'];
            }
          }
          // this.panelListIds = this.panelList.map((comp) => comp['uid']);
          activeSharescreens = col['components'].filter((comp) => comp.name === 'screenshare' && comp.active);
        }
        if (activeSharescreens.length > 0) {
          this.layoutHasShareComponent = true;
        }
        if (settingObject.hasOwnProperty('optionSettings') && settingObject.optionSettings['enableBreakout'] && this.sessionData['attendance'] !== "in-person") {
          this.layoutHasBreakoutChecked = true;
        }
      }
    }
  }
  isLeadCameraId(uid) {
    if (this.leadCamerasId !== uid) {
      return false;
    }
    return true;
  }
  analyseSchedulerData() {
    // this.hasGenerateRTMP = (this.schedulerTasks.filter((tsk) => tsk.name === 'generate-rtmp')).length;
    this.hasUpdatePanel = (this.schedulerTasks.filter((tsk) => tsk.name === 'update-panel')).length;
    // this.hasRtmpPull = (this.schedulerTasks.filter((tsk) => tsk.name === 'rtmp-pull')).length;
    this.tasksKeysToShow = [];
    for (let itm of this.tasksNameKeys) {
      if (itm === 'start-lead-camera' || itm === 'start-guest-camera' || itm === 'push-tile' || itm === 'share-screen' || itm === 'breakout' || itm === 'update-editor' || itm === 'update-picture' || itm === 'update-iframe' || itm === 'update-panel' || itm === 'update-defaultvideo') {
        if ((itm === 'start-lead-camera' && this.leadCamerasList.length > 0 && (this.leadList.length > 0 || this.guestsList.length > 0)) ||
          (itm === 'start-guest-camera' && this.guestCamerasList.length > 0 && (this.leadList.length > 0 || this.guestsList.length > 0)) ||
          (itm === 'update-editor' && this.editorsList.length > 0 && this.sessionData.streamMode !== 'hls') ||
          (itm === 'update-picture' && this.picturesList.length > 0 && this.sessionData.streamMode !== 'hls') ||
          (itm === 'update-iframe' && this.iframeList.length > 0 && this.sessionData.streamMode !== 'hls') ||
          (itm === 'update-defaultvideo' && this.defaultvideoList.length > 0 && this.sessionData.streamMode !== 'hls') ||
          (itm === 'update-panel' && this.panelGroups.length > 0 && this.panelList.length > 0 && this.hasUpdatePanel === 0 && this.sessionData.streamMode !== 'hls') ||
          (itm === 'share-screen' && this.layoutHasShareComponent) ||
          (itm === 'breakout' && this.layoutHasBreakoutChecked && this.sessionData.streamMode !== 'hls') ||
          (itm === 'push-tile' && this.tileList.length > 0)
          // || (itm === 'rtmp-pull' && this.hasRtmpPull === 0 && this.defaultvideoList.length > 0 && this.sessionData.streamMode !== 'hls') ||
          // (itm === 'share-video' && this.sessionData.streamMode !== 'hls') ||
          // (itm === 'generate-rtmp' && this.defaultvideoList.length > 0 && this.sessionData.streamMode !== 'hls')
        ) {
          this.tasksKeysToShow.push(itm);
        }
      } else if (itm === 'start-session' || itm === 'end-session' || itm === 'share-video' || itm === 'rtmp-pull' || itm === 'generate-rtmp') {
        // this so not ruin old data
      } else {
        this.tasksKeysToShow.push(itm);
      }
    }
    this.tasksKeysToShow = this.msort(this.tasksKeysToShow);
    // working on this below you need to work on the part on cleantaskhub on the layout-dynamic-data.component.ts
    this.schedulerTasks = this.schedulerTasks.filter((tsk) => {
      if (tsk.name === 'start-lead-camera' || tsk.name === 'start-guest-camera' || tsk.name === 'push-tile' || tsk.name === 'share-screen' || tsk.name === 'breakout' || tsk.name === 'update-editor' || tsk.name === 'update-picture' || tsk.name === 'update-iframe' || tsk.name === 'update-panel' || tsk.name === 'update-defaultvideo') {
        if ((tsk.name === 'start-lead-camera' && this.leadCamerasList.length > 0 && this.isLeadCameraId(tsk.uid) && (this.leadList.length > 0 || this.guestsList.length > 0)) ||
          (tsk.name === 'start-guest-camera' && this.guestCamerasList.length > 0 && (this.leadList.length > 0 || this.guestsList.length > 0)) ||
          (tsk.name === 'update-editor' && this.editorsList.length > 0 && this.sessionData.streamMode !== 'hls') ||
          (tsk.name === 'update-picture' && this.picturesList.length > 0 && this.sessionData.streamMode !== 'hls') ||
          (tsk.name === 'update-iframe' && this.iframeList.length > 0 && this.sessionData.streamMode !== 'hls') ||
          (tsk.name === 'update-defaultvideo' && this.defaultvideoList.length > 0 && this.sessionData.streamMode !== 'hls') ||
          (tsk.name === 'update-panel' && this.panelGroups.length > 0 && this.panelList.length > 0 && this.sessionData.streamMode !== 'hls') ||
          (tsk.name === 'share-screen' && this.layoutHasShareComponent) ||
          (tsk.name === 'breakout' && this.layoutHasBreakoutChecked && this.sessionData.streamMode !== 'hls') ||
          (tsk.name === 'push-tile' && this.tileList.length > 0)
          // || (tsk.name === 'rtmp-pull' && this.defaultvideoList.length > 0 && this.sessionData.streamMode !== 'hls') ||
          // (tsk.name === 'share-video' && this.sessionData.streamMode !== 'hls') ||
          // (tsk.name === 'generate-rtmp' && this.defaultvideoList.length > 0 && this.sessionData.streamMode !== 'hls')
        ) {
          return true;
        }
      } else if (tsk === 'start-session' || tsk === 'end-session' || tsk === 'share-video' || tsk === 'rtmp-pull' || tsk === 'generate-rtmp') {
        // this so not ruin old data 
        return false;
      } else {
        return true;
      }
      return false;
    });
    this.handleStartSession();
  }
  handleStartSession() {
    let idx = 0;
    for (let tsk of this.schedulerTasks) {
      if (tsk.name === 'start-session') {
        if (this.sessionStarted) {
          this.schedulerTasks[idx].action = 'play';
        } else {
          this.schedulerTasks[idx].action = '';
        }
      }
      else if (tsk.name === 'end-session') {
        if (this.sessionStarted) {
          this.schedulerTasks[idx].action = 'play';
        } else {
          this.schedulerTasks[idx].action = '';
        }
      }
      // else if (tsk.name === 'generate-rtmp') {
      //   if (this.sessionRTMP.rtmpUrl && this.sessionRTMP.rtmpUrl !== '') {
      //     this.schedulerTasks[idx].action = 'play';
      //   } else {
      //     this.schedulerTasks[idx].action = '';
      //   }
      // }
      idx++;
    }
  }
  toggleScheduler() {
    // this.schedulerEnabled = !this.schedulerEnabled;
    // if (this.schedulerEnabled) {
    //   this.refreshSessionData()
    // }
    if (this.schedulerEnabled)
      this.hideDialog.emit(true);
  }
  dropschedulerTask(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.schedulerTasks, event.previousIndex, event.currentIndex);
  }

  goToSession(id) {
    if (this.currentSessionId !== id) {
      this.loading = true;
      this.loaderService.display(true);
      this.roomSessionService.openSessionById(id, (currentSession) => {
        this.loading = false;
        this.loaderService.display(false);
        if (currentSession) {
          this.roomSessionService.openSession(currentSession.room, currentSession, { openScheduler: true }, (status) => {
            if (!status) {
              setTimeout(() => {
                this.sessionId = this.currentSessionId;
              }, 200);
            }
          });
        }
      }
      );
    }
  }
  openEdit(index, task) {
    if (task.action === 'play' && task.name !== 'update-panel' && task.name !== 'start-lead-camera' && task.name !== 'start-guest-camera') {
      return;
    }
    // if (task.action === 'play' && task.overlay && task.overlay.trigger === 'schedule' && task.overlay.content !== '' && (task.name === 'start-lead-camera' || task.name === 'start-guest-camera')) {
    //   return;
    // }
    if (task.name === 'make-announcement') {
      this.openAnnouncementSettings(index, task);
      // } else if (task.name === 'share-video') {
      //   this.openShareVideoSettings(index, task);
    } else if (task.name === 'update-editor') {
      this.openEditorSettings(index, task); // make my popup
    } else if (task.name === 'update-panel') {
      this.openPanelSettings(index, task); // make my popup
    } else if (task.name === 'update-picture') {
      // this.openPictureSettings(index, task); // make my popup
    } else if (task.name === 'update-defaultvideo') {
      this.openDefaultvideoSettings(index, task);
    } else if (task.name === 'update-iframe') {
      this.openIframeSettings(index, task);
    } else if (task.name === 'start-lead-camera' || task.name === 'start-guest-camera') {
      this.openCameraSettings(index, task);
    }
  }
  openGallery(index, task) {
    if (task.action === 'play') {
      return;
    }
    if (task.name === 'update-picture') {
      this.openPictureSettings(index, task); // make my popup
    }
  }
  public removeTask(index, task) {
    if (!this.loading) {
      if ((task.name === 'rtmp-pull' && task.channelId && task.channelId !== '') || task.action === 'play') {
        const _title: string = 'Delete Task';
        let _description: string = 'Are you sure you want to permanently delete this playing Task?';

        if ((task.name === 'rtmp-pull' && task.channelId && task.channelId !== '')) {
          _description = 'Are you sure you want to permanently delete this Task linked to a channel?';
        }

        const _waitDesciption: string = 'Deleting...';

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
          if (!res) {
            return;
          }
          this.completeRemoveTask(index, task);
        });
      } else {
        this.completeRemoveTask(index, task);
      }
    }
  }
  completeRemoveTask(index, task) {
    let currentTask = JSON.parse(JSON.stringify(task));
    // if (currentTask.name === 'rtmp-pull' && currentTask.channelId && currentTask.channelId !== '') {
    //   if (currentTask.action === 'play') { // send signal to stop if playing before deleting
    //     currentTask.action = 'stop';
    //     this.schedularActionHandle(currentTask, index);
    //   }
    //   this.applyRTMP('delete', currentTask.channelId, (data) => {
    //     if (data) {
    //       this.schedulerTasks.splice(index, 1);
    //       this.analyseSchedulerData();
    //       this.hasChanged = true;
    //     }
    //   });
    // } else if (currentTask.name === 'generate-rtmp' && this.sessionRTMP.channelId && this.sessionRTMP.channelId !== '' && (this.hasGenerateRTMP === 1 || currentTask.action === 'play')) {
    //   if (currentTask.action === 'play') { // send signal to stop if playing before deleting
    //     currentTask.action = 'stop';
    //     this.schedularActionHandle(currentTask, index);
    //   }
    //   this.applyRTMP('delete', this.sessionRTMP.channelId, (data) => {
    //     if (data) {
    //       this.schedulerTasks.splice(index, 1);
    //       //this.hasChanged = true;
    //       let schedulerTasks = JSON.parse(JSON.stringify(this.schedulerTasks));
    //       this.updatescheduler(false, schedulerTasks, (newData) => {
    //         if (newData) {
    //           this.analyseSchedulerData();
    //           this.saveRTMPSessionSettingsData({ RtmpUrl: "", HlsOutput: "", ChannelId: "", StreamKey: "" }, (updatedData) => {
    //             if (updatedData) {
    //               this.schedularActionHandle(currentTask, -1);
    //               this.syncWithTeamMembers();
    //             }
    //           });
    //         }
    //       })
    //     }
    //   });
    // } else {
    let schedulerTasks = JSON.parse(JSON.stringify(this.schedulerTasks));
    if (currentTask.action === 'play') { // send signal to stop if playing before deleting
      currentTask.action = 'stop';
      schedulerTasks.splice(index, 1);
      this.updatescheduler(false, schedulerTasks, (data) => {
        if (data) {
          if (currentTask.name === 'update-editor' || currentTask.name === 'update-picture' || currentTask.name === 'update-iframe' || currentTask.name === 'update-panel' || currentTask.name === 'start-lead-camera' || currentTask.name === 'start-guest-camera' || currentTask.name === 'update-defaultvideo') {
            let signalTask = JSON.parse(JSON.stringify(currentTask)); // we send this to signal if we will manipluate the task
            currentTask = this.clearSettingsTask(currentTask);
            this.updatesettings(false, currentTask, (settingsData) => {
              // make sure we are updating sesison here
              if (settingsData) {
                this.schedularActionHandle(signalTask, -1);
                this.syncWithTeamMembers();
              }
            });
          } else {
            this.schedularActionHandle(currentTask, -1);
            this.syncWithTeamMembers();
          }
        }
      });
    } else {
      this.schedulerTasks.splice(index, 1);
      this.analyseSchedulerData();
      this.hasChanged = true;
    }
    // }
  }
  clearTask(currentTask) {
    let currentUpdatedTask = JSON.parse(JSON.stringify(currentTask));
    if (currentUpdatedTask.name === 'update-panel') {
      currentUpdatedTask['groups'] = this.setPanelCamerasGroup(this.panelGroups, []);
    } else if (currentUpdatedTask.name === 'start-lead-camera') {
      // clearing updatedAt when stopping
      if (currentUpdatedTask.hasOwnProperty('overlay')) {
        currentUpdatedTask.overlay['updatedAt'] = undefined;
      }
    } else if (currentUpdatedTask.name === 'start-guest-camera') {
      // clearing updatedAt when stopping
      if (currentUpdatedTask.hasOwnProperty('overlay')) {
        currentUpdatedTask.overlay['updatedAt'] = undefined;
      }
    }
    return currentUpdatedTask;
  }
  clearSettingsTask(currentTask) {
    let currentUpdatedTask = JSON.parse(JSON.stringify(currentTask));
    if (currentUpdatedTask.name === 'update-editor') {
      currentUpdatedTask.data = '';
    } else if (currentUpdatedTask.name === 'update-picture') {
      currentUpdatedTask.image = '';
      currentUpdatedTask['thumbImage'] = '';
      currentUpdatedTask.metadata = { type: 'link', link: '', linkDisplay: '' };
    } else if (currentUpdatedTask.name === 'update-iframe') {
      currentUpdatedTask.metadata = { type: 'link', link: '', linkDisplay: '' };
    } else if (currentUpdatedTask.name === 'update-defaultvideo') {
      currentUpdatedTask.type = '';
      currentUpdatedTask.url = '';
      currentUpdatedTask.urlId = '';
      currentUpdatedTask.clickThruUrl = '';
      currentUpdatedTask.autoStart = false;
      currentUpdatedTask.autoRepeat = false;
      currentUpdatedTask.synchost = false;
      currentUpdatedTask.loop = false;
      currentUpdatedTask.isPlaying = false;
      currentUpdatedTask.isUsedByShortcut = false;
      currentUpdatedTask.controls = false;
      currentUpdatedTask.hlsUrl = '';
      currentUpdatedTask.startMin = 0;
      currentUpdatedTask.startSec = 0;
    } else if (currentUpdatedTask.name === 'update-panel') {
      currentUpdatedTask.metadata = [];
      currentUpdatedTask['groups'] = this.setPanelCamerasGroup(this.panelGroups, []);
    } else if (currentUpdatedTask.name === 'start-lead-camera') {
      let content = this.tasksName[currentUpdatedTask.name].content;
      currentUpdatedTask.metadata = content.metadata;
      currentUpdatedTask.overlay = content.overlay;
    } else if (currentUpdatedTask.name === 'start-guest-camera') {
      let content = this.tasksName[currentUpdatedTask.name].content;
      currentUpdatedTask.metadata = content.metadata;
      currentUpdatedTask.overlay = content.overlay;
    }
    return currentUpdatedTask;
  }
  addTask(taskName) {
    setTimeout(() => {
      this.taskSelected = '';
    }, 100);
    let content = this.tasksName[taskName].content;
    content['uniqueId'] = guid(); // generate Uid for new component
    if (taskName === 'update-panel' && this.panelList.length > 0) {
      content['uid'] = this.panelId;
      content['groups'] = this.panelGroups;
      if (this.panelList[0].hasOwnProperty('metadata')) {
        content['metadata'] = this.panelList[0]['metadata'];
      }
    } else if (taskName === 'start-lead-camera' && this.leadCamerasList.length > 0) {
      content['uid'] = this.leadCamerasId;
      if (this.leadList.length === 1 && this.producersList.length === 0) {
        let hostObject = this.leadList[0];
        content['metadata'] = { id: hostObject._id, name: hostObject.name };
      }
    }
    this.schedulerTasks.push(JSON.parse(JSON.stringify(content)));
    this.analyseSchedulerData();
    this.hasChanged = true;
    // if (taskName === 'make-announcement' || taskName === 'share-video') {
    if (taskName === 'make-announcement') {
      let index = this.schedulerTasks.length - 1;
      this.openEdit(index, this.schedulerTasks[index]);
      // } else if (taskName === 'generate-rtmp') {
      //   if (this.sessionStarted) {
      //     if (!this.sessionRTMP.channelId || this.sessionRTMP.channelId === '' || (this.sessionRTMP.channelId !== '' && this.sessionRTMPLinks[this.sessionRTMP.channelId] === 'na')) {
      //       let schedulerTasks = JSON.parse(JSON.stringify(this.schedulerTasks));
      //       this.generateRTMPSettings(undefined, (status) => {
      //         if (status) {
      //           this.updatescheduler(false, schedulerTasks, (data) => {
      //             if (data) {
      //               this.syncWithTeamMembers();
      //             }
      //           });
      //         }
      //       });
      //     } else {
      //       this.generateRTMPSettings('start');
      //     }
      //   }
    }
  }
  cancelThru(e) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.resetActivity();
  }
  resetActivity() {
    this.idle.watch();
  }
  validateTorunStatus(newtask) {
    if (this.tasksName[newtask.name].isHost && !this.isHost) {
      this.layoutUtilsService.showNotification(this.translate.instant('Only the host can trigger the breakout tasks'), this.translate.instant('Dismiss'));
      return false;
    }
    if (newtask.name === 'start-lead-camera' && newtask.action !== 'play') {
      for (let tsk of this.schedulerTasks) {
        if (tsk.name === 'start-lead-camera' && tsk.action === 'play' && tsk.metadata.id === newtask.metadata.id) {
          this.layoutUtilsService.showNotification(this.translate.instant('This user is already playing'), this.translate.instant('Dismiss'));
          return false;
        } else if (tsk.name === 'start-lead-camera' && tsk.action === 'play' && tsk.uid === newtask.uid) {
          this.layoutUtilsService.showNotification(this.translate.instant('This camera is already playing'), this.translate.instant('Dismiss'));
          return false;
          // } else if (tsk.name === 'update-panel' && tsk.action === 'play' && this.checkIfAvailableInArray(newtask.metadata.id, tsk.metadata, 'id')) {
          //   this.layoutUtilsService.showNotification(this.translate.instant('This user is already playing in the panel'), this.translate.instant('Dismiss'));
          //   return false;
        }
      }
    } else if (newtask.name === 'start-guest-camera' && newtask.action !== 'play') {
      for (let tsk of this.schedulerTasks) {
        if (tsk.name === 'start-guest-camera' && tsk.action === 'play' && tsk.metadata.id === newtask.metadata.id) {
          this.layoutUtilsService.showNotification(this.translate.instant('This user is already playing'), this.translate.instant('Dismiss'));
          return false;
        } else if (tsk.name === 'start-guest-camera' && tsk.action === 'play' && tsk.uid === newtask.uid) {
          this.layoutUtilsService.showNotification(this.translate.instant('This camera is already playing'), this.translate.instant('Dismiss'));
          return false;
          // } else if (tsk.name === 'update-panel' && tsk.action === 'play' && this.checkIfAvailableInArray(newtask.metadata.id, tsk.metadata, 'id')) {
          //   this.layoutUtilsService.showNotification(this.translate.instant('This user is already playing in the panel'), this.translate.instant('Dismiss'));
          //   return false;
        }
      }
    } else if (newtask.name === 'share-screen' && newtask.action !== 'play') {
      for (let tsk of this.schedulerTasks) {
        if (tsk.name === 'share-screen' && tsk.action === 'play') {
          this.layoutUtilsService.showNotification(this.translate.instant('Another share screen task already running'), this.translate.instant('Dismiss'));
          return false;
        }
      }
      // } else if ((newtask.name === 'update-defaultvideo' || newtask.name === 'generate-rtmp' || newtask.name === 'rtmp-pull') && newtask.action !== 'play') {
      //   for (let tsk of this.schedulerTasks) {
      //     if ((tsk.name === 'update-defaultvideo' || tsk.name === 'generate-rtmp' || tsk.name === 'rtmp-pull') && tsk.uid === newtask.uid && tsk.action === 'play') {
      //       this.layoutUtilsService.showNotification(this.translate.instant('Another task already running this media'), this.translate.instant('Dismiss'));
      //       return false;
      //     }
      //   }
    }
    return true;
  }
  checkIfAvailableInArray(itmId, dataList, idName = '_id') {
    if (dataList) {
      for (let itemList of dataList) {
        if (itemList[idName] === itmId) {
          return true;
        }
      }
    }
    return false;
  }
  callGaurdAction(index, task, sendSignal: boolean = true, returnSuccessMessage: boolean = true, skipActive: boolean = false) {
    if (this.loading && (this.apiCallSchedulerSubscription || this.apiCallSettingsSubscription)) {
      if (this.apiCallSchedulerSubscription) {
        this.apiCallSchedulerSubscription.unsubscribe();
      }
      if (this.apiCallSettingsSubscription) {
        this.apiCallSettingsSubscription.unsubscribe();
      }
      this.loading = false;
    }
    if (!this.loading) {
      // below is validating when we are sending signal
      if (sendSignal && !this.validateTorunStatus(task)) {
        return;
      }
      if (task.name === 'select-session' && task.action !== 'play') {
        let alertSetting = {};
        alertSetting['overlayClickToClose'] = false;
        alertSetting['showCloseButton'] = false;
        alertSetting['confirmText'] = this.translate.instant('Confirm');
        alertSetting['declineText'] = this.translate.instant('Cancel');
        // alertSetting['timerEvent'] = 120;

        const dialogRef = this.layoutUtilsService.alertActionElement(this.translate.instant('Go to Next Session'), this.translate.instant('Are you sure you want to go to this session and teleport all people who are currently inside the session?'), alertSetting, '500px');
        dialogRef.afterClosed().subscribe(res => {
          if (res) {
            if (res.action === 'confirmText') {
              this.callAction(index, task, sendSignal, skipActive);
            }
          }
        });
      } else if ((task.name === 'start-lead-camera' || task.name === 'start-guest-camera') && task.action === 'play' && !this.sessionStarted) {
        this.layoutUtilsService.showNotification(this.translate.instant('The session is currently offline. Please start the session first.'), this.translate.instant('Dismiss'));
        // } else if ((task.name === 'generate-rtmp' || task.name === 'rtmp-pull') && task.action !== 'play') {
        //   console.log('task', task);
        //   let url = this.sessionRTMP.hlsOutput;
        //   if (task.name === 'rtmp-pull') {
        //     url = task.hlsOutput;
        //   }
        //   let element = document.createElement('video');
        //   this.renderer.addClass(element, 'video-js');
        //   this.renderer.addClass(element, 'vjs-default-skin');
        //   this.renderer.appendChild(document.getElementById('temp-rtmp-player'), element);
        //   let player = videojs(element, {
        //     loadingSpinner: false,
        //     errorDisplay: false,
        //     controls: false,
        //     controlBar: false
        //   });
        //   player.src({
        //     src: url + '_720p30.m3u8',
        //     type: 'application/x-mpegURL',
        //     enableLowInitialPlaylist: true
        //   });
        //   player.volume(0);
        //   player.on('loadeddata', () => {
        //     console.log('loadeddata');
        //     this.renderer.removeChild(document.getElementById('temp-rtmp-player'), element);
        //     this.callAction(index, task, sendSignal, skipActive);
        //   });
        //   player.play().catch((e) => {
        //     let error = player.error();
        //     console.log('error', error);
        //     console.log('e', e);
        //     if (error !== null) {
        //       this.layoutUtilsService.showNotification(this.translate.instant('The rtmp is currently not streaming. Please start streaming first.'), this.translate.instant('Dismiss'));
        //     }
        //   });
      } else {
        this.callAction(index, task, sendSignal, skipActive);
      }
    } else {
      this.savescheduler(true, returnSuccessMessage);
    }
  }
  callAction(index, task, sendSignal, skipActive: boolean = false) {
    let newTask = JSON.parse(JSON.stringify(task));
    let action = ''
    if (newTask.action === '') {
      action = 'play';
    } else if (newTask.action === 'play') {
      action = 'stop';
    } else if (newTask.action === 'stop') {
      action = 'play';
    }
    newTask.action = action;
    let schedulerTasks = JSON.parse(JSON.stringify(this.schedulerTasks));
    // if (newTask.name === 'select-session') {
    //   schedulerTasks[index].action = 'stop'; // completed
    // } else
    if (newTask.name === 'make-announcement') {
      schedulerTasks[index].action = 'stop'; // completed
    } else {
      schedulerTasks[index].action = newTask.action;
    }
    if ((newTask.name === 'start-lead-camera' || newTask.name === 'start-guest-camera')) {
      if (!newTask.hasOwnProperty('overlay')) {
        newTask['overlay'] = this.tasksName[task.name].content.overlay;
      }
      if (!schedulerTasks[index].hasOwnProperty('overlay')) {
        schedulerTasks[index]['overlay'] = this.tasksName[task.name].content.overlay;
      }
      if (!skipActive && newTask.action === 'play') {
        newTask.overlay['updatedAt'] = moment.utc().format('YYYY-MM-DDTHH:mm:ss');
        schedulerTasks[index].overlay['updatedAt'] = moment.utc().format('YYYY-MM-DDTHH:mm:ss');
        schedulerTasks[index].active = false;
        newTask.active = false;
      } else {
        newTask.overlay['running'] = false;
        schedulerTasks[index].overlay['running'] = false;
        schedulerTasks[index].active = true;
        newTask.active = true;
      }
    }
    // if (newTask.name === 'generate-rtmp') {
    //   if (!this.sessionRTMP.channelId || this.sessionRTMP.channelId === '' || (this.sessionRTMP.channelId !== '' && this.sessionRTMPLinks[this.sessionRTMP.channelId] === 'na')) {
    //     this.generateRTMPSettings(undefined, (status) => {
    //       if (status) {
    //         this.updatescheduler(false, schedulerTasks, (data) => {
    //           if (data && sendSignal) {
    //             newTask.hlsOutput = this.sessionRTMP.hlsOutput;
    //             this.schedularActionHandle(newTask, index);
    //             this.syncWithTeamMembers();
    //           }
    //         });
    //       }
    //     });
    //   } else {
    //     if (action === 'stop') {
    //       this.updatescheduler(false, schedulerTasks, (data) => {
    //         if (data && sendSignal) {
    //           this.schedularActionHandle(newTask, index);
    //           this.syncWithTeamMembers();
    //         }
    //       });
    //     } else {
    //       this.generateRTMPSettings('start', (status) => {
    //         if (status) {
    //           this.updatescheduler(false, schedulerTasks, (data) => {
    //             if (data && sendSignal) {
    //               newTask.hlsOutput = this.sessionRTMP.hlsOutput;
    //               this.schedularActionHandle(newTask, index);
    //               this.syncWithTeamMembers();
    //             }
    //           });
    //         }
    //       });
    //     }

    //   }
    // } else 
    if (newTask.name === 'update-editor' || newTask.name === 'update-picture' || newTask.name === 'update-iframe' || newTask.name === 'start-guest-camera' || newTask.name === 'update-panel' || newTask.name === 'update-defaultvideo' || newTask.name === 'start-lead-camera') {
      if (newTask.name === 'update-panel' && newTask.action !== 'stop') {
        // this code is to make when running a pnael to take the groups from the current panel compenent
        let newGroup = this.setPanelCamerasGroup(this.panelGroups, newTask.metadata);
        schedulerTasks[index]['groups'] = newGroup;
        newTask['groups'] = newGroup
      }
      let signalTask = JSON.parse(JSON.stringify(newTask)); // we send this to signal if we will manipluate the task
      let taskListToSignal: any[] = [];
      if (newTask.action === 'stop') {
        schedulerTasks[index] = this.clearTask(schedulerTasks[index]);
        newTask = this.clearSettingsTask(newTask);
      } else {
        if (newTask.name === 'update-panel') {
          // below code to catch all speakers and moderators to stop
          let idx = 0;
          for (let tsk of schedulerTasks) {
            if ((tsk.name === 'start-lead-camera' || tsk.name === 'start-guest-camera') && tsk.action === 'play' && this.checkIfAvailableInArray(tsk.metadata.id, newTask.metadata, 'id')) {
              schedulerTasks[idx].action = 'stop';
              schedulerTasks[idx].active = true;
              // if (schedulerTasks[idx].overlay)
              //   schedulerTasks[idx].overlay['running'] = false;
              taskListToSignal.push({ index: idx, task: schedulerTasks[idx] });
            }
            idx++;
          }
        } else if (newTask.name === 'start-lead-camera' || newTask.name === 'start-guest-camera') {
          let idx = 0;
          for (let tsk of schedulerTasks) {
            if ((tsk.name === 'start-lead-camera' || tsk.name === 'start-guest-camera') && tsk.action === 'play' && tsk.metadata.id === newTask.metadata.id && tsk.uniqueId !== newTask.uniqueId) {
              // schedulerTasks[idx].action = 'stop';
              // schedulerTasks[idx].active = true;
              // taskListToSignal.push({ index: idx, task: schedulerTasks[idx] });
              this.layoutUtilsService.showNotification(this.translate.instant('This user is already playing in another task, please stop it before running this task'), this.translate.instant('Dismiss'));
              return;
            } else if ((tsk.name === 'update-panel') && tsk.action === 'play' && this.checkIfAvailableInArray(newTask.metadata.id, tsk.metadata, 'id')) {
              schedulerTasks[idx]['metadata'] = schedulerTasks[idx]['metadata'].filter(itm => itm.id !== newTask.metadata.id);
              schedulerTasks[idx]['groups'] = this.setPanelCamerasGroup(schedulerTasks[idx]['groups'], schedulerTasks[idx]['metadata']);
              if (schedulerTasks[idx]['metadata'].length === 0) {
                schedulerTasks[idx].action = 'stop';
                schedulerTasks[idx].active = true;
              }
              taskListToSignal.push({ index: idx, task: schedulerTasks[idx] });
              // this.layoutUtilsService.showNotification(this.translate.instant('This user is already playing in a panel task, please stop him before running this again'), this.translate.instant('Dismiss'));
              // return;
            }
            idx++;
          }
        } else if (newTask.name === 'update-defaultvideo') {
          let currentSettings = JSON.parse(JSON.stringify(this.sessionData['settings']));
          let playingComponent = currentSettings[this.viewMode]['columns'][0].components.find(i => i.name === 'defaultvideo' && i.uid === newTask.uid && i.active && i.isUsedByShortcut && i.isPlaying);
          let playingTask = schedulerTasks.find(i => i.name === 'update-defaultvideo' && i.uid === newTask.uid && i.uniqueId !== newTask.uniqueId && i.action === 'play');
          if (playingComponent || playingTask) {
            this.layoutUtilsService.showNotification(this.translate.instant('You already have media playing in this component, please stop it then try again'), this.translate.instant('Dismiss'));
            return;
          }
        }
      }
      taskListToSignal.push({ index: index, task: signalTask });
      this.updatescheduler(false, schedulerTasks, (schedulerData) => {
        if (schedulerData) {
          this.updatesettings(false, newTask, (settingsData) => {
            // make sure we are updating sesison here
            if (settingsData && sendSignal) {
              for (let taskToSignal of taskListToSignal) {
                this.schedularActionHandle(taskToSignal.task, taskToSignal.index);
              }
              this.syncWithTeamMembers();
            }
          });
        }
      });
    } else {
      this.updatescheduler(false, schedulerTasks, (data) => {
        if (data && sendSignal) {
          this.schedularActionHandle(newTask, index);
          this.syncWithTeamMembers();
        }
      });
    }
  }
  callToStartSession(taskName) {
    let newTask = JSON.parse(JSON.stringify(this.tasksName[taskName].content));
    if (taskName === 'start-session') {
      newTask.action = 'play'
    } else {
      newTask.action = 'stop';
    }

    this.schedularActionHandle(newTask, undefined);
  }
  setDisablePlaceholders(val) {
    this.disablePlaceholders = val;
    this.schedulerService.togglePlaceHolder.next(!val);
  }
  savescheduler(loading: boolean = true, returnSuccessMessage: boolean = true) {
    let schedulerTasks = JSON.parse(JSON.stringify(this.schedulerTasks));
    this.updatescheduler(loading, schedulerTasks, (data) => {
      if (data) {
        this.syncWithTeamMembers();
        if (returnSuccessMessage) {
          // this.schedulerService.schedulerTasks.next(this.schedulerTasks);
          this.layoutUtilsService.showNotification(this.translate.instant('Scheduler Updated Successfully'), this.translate.instant('Dismiss'));
        }
      }
    });
  }

  updatescheduler(loading = true, schedulerTasks, callback: (dataResponse: any | undefined) => void) {
    if (this.loading && this.apiCallSchedulerSubscription) {
      this.apiCallSchedulerSubscription.unsubscribe();
      this.loading = false;
    }
    if (!this.loading) {
      // if (!this.validateTasks(schedulerTasks)) {
      //   return;
      // }
      this.loading = true;
      this.loaderService.display(loading);
      let dataToSave = { _id: this.sessionData['_id'], name: this.sessionData['name'], scheduler: { tasks: schedulerTasks } };
      this.errorMessage = '';
      this.apiCallSchedulerSubscription = this.requestService.saveData('session', dataToSave, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        this.loaderService.display(false);
        if (data) {
          this.setupSessionData(data.results);
          callback(data);
          this.hasChanged = false;
        }
      });
    }
  }
  updatesettings(loading = true, newTask, callback: (dataResponse: any | undefined) => void) {
    if (this.loading && this.apiCallSettingsSubscription) {
      this.apiCallSettingsSubscription.unsubscribe();
      this.loading = false;
    }
    if (!this.loading) {
      let currentSettings = JSON.parse(JSON.stringify(this.sessionData['settings']));
      if (currentSettings.hasOwnProperty(this.viewMode)) {
        let settingObject = JSON.parse(JSON.stringify(currentSettings[this.viewMode]));
        for (let col of settingObject['columns']) {
          // if (newTask.action === 'play') {
          //   if (newTask.name === 'start-guest-camera') {
          //     // make sure to remove the moderator from his old position because we can select the camera
          //     for (let cmp of col['components']) {
          //       if (cmp.name === 'guest-camera') {
          //         if (cmp.metadata && cmp.metadata.id === newTask.metadata.id) {
          //           cmp.metadata = { id: '', name: '' };
          //         }
          //       }
          //     }
          //   } else if (newTask.name === 'start-lead-camera') {
          //     for (let cmp of col['components']) {
          //       if (cmp.name === 'presenter-camera') {
          //         if (cmp.metadata && cmp.metadata.id === newTask.metadata.id) {
          //           cmp.metadata = { id: '', name: '' };
          //         }
          //       }
          //     }
          //   } else if (newTask.name === 'update-panel') {
          //     // make sure to remove the moderators or speaker from his old position because we can select the camera
          //     for (let cmp of col['components']) {
          //       if (cmp.name === 'guest-camera' || cmp.name === 'presenter-camera') {
          //         if (cmp.metadata && this.checkIfAvailableInArray(cmp.metadata.id, newTask.metadata, 'id')) {
          //           cmp.metadata = { id: '', name: '' };
          //         }
          //       }
          //     }
          //   }
          // }
          // update new data from the newtask sent
          for (let cmp of col['components']) {
            if (cmp.uid === newTask.uid) {
              if (newTask.name === 'update-editor') {
                cmp.data = newTask.data;
              } else if (newTask.name === 'update-picture') {
                cmp.image = newTask.image;
              } else if (newTask.name === 'update-iframe') {
                cmp.metadata = newTask.metadata;
              } else if (newTask.name === 'update-defaultvideo') {
                cmp.type = newTask.type;
                cmp.url = newTask.url;
                cmp.urlId = newTask.urlId;
                cmp.clickThruUrl = newTask.clickThruUrl;
                cmp.hlsUrl = newTask.hlsUrl;
                cmp.autoStart = newTask.autoStart;
                cmp.autoRepeat = newTask.autoRepeat;
                cmp.synchost = newTask.synchost;
                cmp.loop = newTask.loop;
                cmp.isPlaying = newTask.isPlaying;
                cmp.isUsedByShortcut = newTask.isUsedByShortcut;
                cmp.controls = newTask.controls;
                cmp.startMin = newTask.startMin;
                cmp.startSec = newTask.startSec;
              } else if (newTask.name === 'start-lead-camera') {
                // cmp.metadata = newTask.metadata;
              } else if (newTask.name === 'start-guest-camera') {
                // cmp.metadata = newTask.metadata;
              } else if (newTask.name === 'update-panel') {
                cmp.metadata = newTask.metadata;
                cmp.groups = this.panelGroups;
              }
            }
          }
        }
        currentSettings[this.viewMode] = JSON.parse(JSON.stringify(settingObject));
      }
      this.loading = true;
      this.loaderService.display(loading);
      let dataToSave = { _id: this.sessionId, name: this.sessionData['name'], settings: currentSettings };
      this.apiCallSettingsSubscription = this.requestService.saveData('session', dataToSave, (data, error) => {
        if (error) {
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        this.loaderService.display(false);
        if (data) {
          callback(data);
        }
      });
    }
  }
  resetSessionData() {
    let schedulerTasks = JSON.parse(JSON.stringify(this.schedulerTasks));
    schedulerTasks = schedulerTasks.map((tsk) => {
      if (tsk.action !== 'play') {
        tsk.action = '';
      }
      return tsk;
    });
    this.updatescheduler(false, schedulerTasks, (data) => {
      if (data) {
        this.syncWithTeamMembers();
        // do nothing
      }
    });
  }
  refreshSessionData() {
    // console.log('refreshSessionData');
    // if (!this.loading) {
    // this.loading = true;
    this.requestService.getSingleData('session', this.sessionId, (data, error) => {
      if (error) {
        this.layoutUtilsService.showNotification('Error: ' + error, 'Dismiss');
      }
      // this.loading = false;
      if (data) {
        this.setupSessionData(data.results);
        this.getSessions();
      }
    });
    // }
  }
  validateTasks(schedulerTasks) {
    // console.log('validateTasks', this.schedulerTasks);
    for (let tsk of schedulerTasks) {
      if (tsk.name === 'start-lead-camera') {
        if (!tsk.metadata.id || tsk.metadata.id === '') {
          this.layoutUtilsService.showNotification(this.translate.instant('You need to select a ') + this.translate.instant('user') + ' for ' + this.translate.instant(this.tasksName[tsk.name].displayName) + ' ' + this.translate.instant('Task'), this.translate.instant('Dismiss'));
          return false;
        }
      } else if (tsk.name === 'start-guest-camera') {
        if (!tsk.metadata.id || tsk.metadata.id === '' || !tsk.uid || tsk.uid === '') {
          this.layoutUtilsService.showNotification(this.translate.instant('You need to select a ') + this.translate.instant('user') + ' and a camera ' + ' for ' + this.translate.instant(this.tasksName[tsk.name].displayName) + ' ' + this.translate.instant('Task'), this.translate.instant('Dismiss'));
          return false;
        }
      } else if (tsk.name === 'push-tile') {
        if (!tsk.metadata.id || tsk.metadata.id === '') {
          this.layoutUtilsService.showNotification(this.translate.instant('You need to select a ') + this.translate.instant('Tile') + ' for ' + this.translate.instant(this.tasksName[tsk.name].displayName) + ' ' + this.translate.instant('Task'), this.translate.instant('Dismiss'));
          return false;
        }
      } else if (tsk.name === 'select-session') {
        if (!tsk.metadata.id || tsk.metadata.id === '') {
          this.layoutUtilsService.showNotification(this.translate.instant('You need to select a ') + this.translate.instant('Session') + ' for ' + this.translate.instant(this.tasksName[tsk.name].displayName) + ' ' + this.translate.instant('Task'), this.translate.instant('Dismiss'));
          return false;
        }
      } else if (tsk.name === 'make-announcement') {
        if (!tsk.data || tsk.data === '') {
          this.layoutUtilsService.showNotification(this.translate.instant('You need to insert data') + ' for ' + this.translate.instant(this.tasksName[tsk.name].displayName) + ' ' + this.translate.instant('Task'), this.translate.instant('Dismiss'));
          return false;
        }
        // } else if (tsk.name === 'share-video') {
        //   if (!tsk.url || tsk.url === '') {
        //     this.layoutUtilsService.showNotification(this.translate.instant('You need to set the data') + ' for ' + this.translate.instant(this.tasksName[tsk.name].displayName) + ' ' + this.translate.instant('Task'), this.translate.instant('Dismiss'));
        //     return false;
        //   }
      } else if (tsk.name === 'rtmp-pull') {
        // if (!tsk.rtmpUrl || tsk.rtmpUrl === '') {
        //   this.layoutUtilsService.showNotification(this.translate.instant('You need to set the data') + ' for ' + this.translate.instant(this.tasksName[tsk.name].displayName) + ' ' + this.translate.instant('Task'), this.translate.instant('Dismiss'));
        //   return false;
        // } else {
        //   if (!isValidHttpUrl(tsk.rtmpUrl)) {
        //     this.layoutUtilsService.showNotification(this.translate.instant('Fill in the correct RTMP link format'), this.translate.instant('Dismiss'));
        //     return false;
        //   }
        // }
        if (tsk.rtmpUrl && tsk.rtmpUrl !== '') {
          if (!isValidHttpUrl(tsk.rtmpUrl)) {
            this.layoutUtilsService.showNotification(this.translate.instant('Fill in the correct RTMP link format'), this.translate.instant('Dismiss'));
            return false;
          }
        }
      }
      if (tsk.note && tsk.note !== '' && tsk.note.length > 1000) {
        this.layoutUtilsService.showNotification(this.translate.instant('Note cannot be more than 1000 character') + ' for ' + this.translate.instant(this.tasksName[tsk.name].displayName) + ' ' + this.translate.instant('Task'), this.translate.instant('Dismiss'));
        return false;
      }
    }
    return true;
  }
  public setAttribute(index, id, val) {
    if (this.schedulerTasks[index][id] !== val) {
      this.schedulerTasks[index][id] = val;
      this.hasChanged = true;
    }
  }
  public setMetaDataAttribute(index, val, dataList) {
    this.schedulerTasks[index]['metadata']['id'] = val;
    this.schedulerTasks[index]['metadata']['name'] = '';
    for (let itm of dataList) {
      if (itm._id === val) {
        if (itm.hasOwnProperty('text')) {
          this.schedulerTasks[index]['metadata']['name'] = itm['text'];
        } else if (itm.hasOwnProperty('title')) {
          this.schedulerTasks[index]['metadata']['name'] = itm['title'];
        } else {
          this.schedulerTasks[index]['metadata']['name'] = itm.name;
        }
        break;
      }
    }
    this.hasChanged = true;
  }
  public setCamerasAttribute(index, val) {
    this.schedulerTasks[index]['metadata']['id'] = val;
    this.schedulerTasks[index]['metadata']['name'] = '';
    let usersList = this.leadList.concat(this.guestsList)
    for (let itm of usersList) {
      if (itm._id === val) {
        if (itm.hasOwnProperty('text')) {
          this.schedulerTasks[index]['metadata']['name'] = itm['text'];
        } else if (itm.hasOwnProperty('title')) {
          this.schedulerTasks[index]['metadata']['name'] = itm['title'];
        } else {
          this.schedulerTasks[index]['metadata']['name'] = itm.name;
        }
        break;
      }
    }
    this.hasChanged = true;
  }
  public getSessions() {
    let roomId = this.roomId;
    let filters = {
      "$and": [{ "room._id": { "$eq": roomId } }]
    };
    this.requestService.getDataList('session', {
      fieldKeys: ['_id', 'name', 'startsAt', 'users'], orderDir: 'asc', orderBy: 'startsAt', filter: filters
    }, (data, error) => {
      if (error) {
        this.sessionList = [];
        this.sessionTaskList = [];
      }
      if (data) {
        this.sessionList = data.results.map((session) => {
          let isDisabled = false;
          // let leads = this.requestService.getSessionRoleByType(session, 'lead');
          // if (leads.length == 0) {
          //   isDisabled = true;
          // } else {
          let masterModeratorId = this.requestService.getSessionHost(session);
          if (!masterModeratorId) {
            isDisabled = true;
          }
          // }
          session['isDisabled'] = isDisabled;
          return session;
        });
        this.sessionTaskList = this.sessionList.filter((session) => session._id !== this.sessionId);
      } else {
        this.sessionList = [];
        this.sessionTaskList = [];
      }
    });
  }

  public openAnnouncementSettings(index, task) {
    const dialogRef = this.dialog.open(ConfirmCkeditorEntityDialogComponent, {
      width: '800px',
      disableClose: false,
      data: {
        title: this.translate.instant('Edit Announcement'),
        data: task['data'],
        config: this.editorConfig,
        cancelbtn: this.translate.instant('Close'),
        confirmbtn: this.translate.instant('Confirm'),
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        if (this.schedulerTasks[index]) {
          this.schedulerTasks[index]['data'] = result;
        } else {
          task['data'] = result;
          this.schedulerTasks.push(task);
        }
        this.analyseSchedulerData();
        this.hasChanged = true;
      }
    });
  }
  public openCameraSettings(index, task) {
    const dialogRef = this.dialog.open(ConfirmCameraEntityDialogComponent, {
      width: '800px',
      disableClose: false,
      data: {
        title: this.translate.instant('Edit Overlay'),
        overlay: JSON.parse(JSON.stringify(task['overlay'])),
        taskName: task['name'],
        taskAction: task['action'],
        config: this.cameraEditorConfig,
        cancelbtn: this.translate.instant('Close'),
        confirmbtn: this.translate.instant('Confirm'),
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        if (task.action !== 'play') {
          if (this.schedulerTasks[index]) {
            this.schedulerTasks[index]['overlay'] = result;
          } else {
            task['overlay'] = result;
            this.schedulerTasks.push(task);
          }
          this.analyseSchedulerData();
          this.hasChanged = true;
        } else {
          let newTask = this.schedulerTasks[index];
          newTask['overlay'] = result;
          let schedulerTasks = JSON.parse(JSON.stringify(this.schedulerTasks));
          schedulerTasks[index] = newTask;
          this.updatescheduler(true, schedulerTasks, (newData) => {
            if (newData) {
              this.analyseSchedulerData();
              this.syncWithTeamMembers();
              this.schedulerService.pushCameraOverlay.next(newTask);
            }
          });
        }
      }
    });
  }
  public openIframeSettings(index, task) {
    if (!task.uid || task.uid === '') {
      this.layoutUtilsService.showNotification(this.translate.instant('You need to select an ') + this.translate.instant('Iframe') + ' ' + this.translate.instant('component') + ' ' + this.translate.instant('for') + ' ' + this.translate.instant(this.tasksName[task.name].displayName) + ' ' + this.translate.instant('Task'), this.translate.instant('Dismiss'));
      return;
    }
    let data = this.schedulerTasks[index]['metadata'];
    if (!data || data === '' || (data && data.link === '')) {
      let currentSettings = JSON.parse(JSON.stringify(this.sessionData['settings']));
      if (currentSettings.hasOwnProperty(this.viewMode)) {
        let settingObject = JSON.parse(JSON.stringify(currentSettings[this.viewMode]));
        let targetComponent = undefined;
        for (let col of settingObject['columns']) {
          for (let cmp of col['components']) {
            if (cmp.uid === task.uid) {
              targetComponent = cmp;
            }
          }
        }
        if (targetComponent) {
          data = targetComponent.metadata;
        }
      }
    }

    const dialogRef = this.dialog.open(IframeComponent, {
      width: '25vw',
      disableClose: false,
      autoFocus: false,
      data: {
        title: this.translate.instant('Update') + ' ' + this.translate.instant('iFrame') + ' ' + this.translate.instant('URL'),
        data: { link: data.link }
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.schedulerTasks[index]['metadata'].link = result.data.link;
        this.analyseSchedulerData();
        this.hasChanged = true;
      }
    });
  }
  public runOverlay(index, task, run) {
    if (task.action !== 'play') {
      return;
    } else {
      let newTask = this.schedulerTasks[index];
      if (!newTask.hasOwnProperty('overlay')) {
        newTask['overlay'] = this.tasksName[task.name].content.overlay;
      }
      newTask.overlay['running'] = run;
      let schedulerTasks = JSON.parse(JSON.stringify(this.schedulerTasks));
      schedulerTasks[index] = newTask;
      this.updatescheduler(true, schedulerTasks, (newData) => {
        if (newData) {
          this.analyseSchedulerData();
          this.syncWithTeamMembers();
          this.schedulerService.pushCameraOverlay.next(newTask);
        }
      });
    }
  }
  public openEditorSettings(index, task) {
    if (!task.uid || task.uid === '') {
      this.layoutUtilsService.showNotification(this.translate.instant('You need to select an ') + this.translate.instant('editor') + ' ' + this.translate.instant('component') + ' ' + this.translate.instant('for') + ' ' + this.translate.instant(this.tasksName[task.name].displayName) + ' ' + this.translate.instant('Task'), this.translate.instant('Dismiss'));
      return;
    }
    let data = this.schedulerTasks[index]['data'];
    if (!data || data === '') {
      let currentSettings = JSON.parse(JSON.stringify(this.sessionData['settings']));
      if (currentSettings.hasOwnProperty(this.viewMode)) {
        let settingObject = JSON.parse(JSON.stringify(currentSettings[this.viewMode]));
        let targetComponent = undefined;
        for (let col of settingObject['columns']) {
          for (let cmp of col['components']) {
            if (cmp.uid === task.uid) {
              targetComponent = cmp;
            }
          }
        }
        if (targetComponent) {
          data = targetComponent.data;
        }
      }
    }
    const dialogRef = this.dialog.open(ConfirmCkeditorEntityDialogComponent, {
      width: '800px',
      disableClose: false,
      data: {
        title: this.translate.instant('Edit Announcement'),
        data: data,
        config: this.editorConfig,
        cancelbtn: this.translate.instant('Close'),
        confirmbtn: this.translate.instant('Confirm'),
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.schedulerTasks[index]['data'] = result;
        this.analyseSchedulerData();
        this.hasChanged = true;
      }
    });
  }
  // public openShareVideoSettings(index, task) {
  //   const dialogRef = this.dialog.open(ShareVideoDialogComponent, {
  //     width: '600px',
  //     disableClose: false,
  //     autoFocus: false,
  //     data: {
  //       title: this.translate.instant('Edit Share Video'),
  //       data: { url: this.schedulerTasks[index]['url'], type: this.schedulerTasks[index]['type'], startMin: this.schedulerTasks[index]['startMin'], startSec: this.schedulerTasks[index]['startSec'] },
  //     }
  //   });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       if (result.type === 'rtmp' && this.schedulerTasks[index]['rtmpUrl'] !== result.rtmpUrl && this.schedulerTasks[index]['streamKey'] !== result.streamKey && this.schedulerTasks[index]['channelId'] !== result.channelId && this.schedulerTasks[index]['channelId'] && this.schedulerTasks[index]['channelId'] !== '') {
  //         // delete old if exist
  //         this.applyRTMP('delete', this.schedulerTasks[index]['channelId'], (data) => {
  //           if (data) {
  //             let schedulerTasks = JSON.parse(JSON.stringify(this.schedulerTasks));
  //             schedulerTasks[index]['url'] = result.url;
  //             schedulerTasks[index]['type'] = result.type;
  //             schedulerTasks[index]['startMin'] = result.startMin;
  //             schedulerTasks[index]['startSec'] = result.startSec;
  //             this.updatescheduler(false, schedulerTasks, (data) => {
  //               if (data) {
  //                 this.syncWithTeamMembers();
  //                 // do nothing
  //               }
  //             });
  //           }
  //         });
  //       } else {
  //         this.schedulerTasks[index]['url'] = result.url;
  //         this.schedulerTasks[index]['type'] = result.type;
  //         this.schedulerTasks[index]['startMin'] = result.startMin;
  //         this.schedulerTasks[index]['startSec'] = result.startSec;
  //         this.analyseSchedulerData();
  //         this.hasChanged = true;
  //       }
  //     }
  //   });
  // }
  public openPictureSettings(index, task) {
    if (!task.uid || task.uid === '') {
      this.layoutUtilsService.showNotification(this.translate.instant('You need to select a ') + this.translate.instant('picture') + ' ' + this.translate.instant('component') + ' ' + this.translate.instant('for') + ' ' + this.translate.instant(this.tasksName[task.name].displayName) + ' ' + this.translate.instant('Task'), this.translate.instant('Dismiss'));
      return;
    }
    let data = this.schedulerTasks[index]['image'];
    if (!data || data === '') {
      let currentSettings = JSON.parse(JSON.stringify(this.sessionData['settings']));
      if (currentSettings.hasOwnProperty(this.viewMode)) {
        let settingObject = JSON.parse(JSON.stringify(currentSettings[this.viewMode]));
        let targetComponent = undefined;
        for (let col of settingObject['columns']) {
          for (let cmp of col['components']) {
            if (cmp.uid === task.uid) {
              targetComponent = cmp;
            }
          }
        }
        if (targetComponent) {
          data = targetComponent.image;
        }
      }
    }
    const dialogRef = this.dialog.open(ModalAdvancedGalleryDialogComponent, {
      width: '100vw',
      disableClose: false,
      autoFocus: false,
      data: {
        handleBgColor: false,
        noImage: false,
        notBg: true,
        title: this.translate.instant('Select') + ' ' + this.translate.instant('Image'),
        data: { bgBackgroundLink: data },
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.schedulerTasks[index]['image'] = result.bgBackgroundLink;
        if (result.bgBackgroundLink && result.bgBackgroundLink !== '') {
          this.schedulerTasks[index]['thumbImage'] = getOtherImage('', result.bgBackgroundLink, '_thumbnail');
        } else {
          this.schedulerTasks[index]['thumbImage'] = '';
        }
        this.analyseSchedulerData();
        this.hasChanged = true;
      }
    });
  }
  getThumbnail(bgBackgroundLink) {
    if (bgBackgroundLink) {
      let img = getOtherImage('', bgBackgroundLink, '_thumbnail');
      return img;
    }
  }

  public openPanelSettings(index, task) {
    if (!task.uid || task.uid === '' || (task.uid && task.uid !== '' && task.uid !== this.panelId)) {
      return;
    }
    let data = this.schedulerTasks[index]['metadata'];
    if (!data || data === '') {
      let currentSettings = JSON.parse(JSON.stringify(this.sessionData['settings']));
      if (currentSettings.hasOwnProperty(this.viewMode)) {
        let settingObject = JSON.parse(JSON.stringify(currentSettings[this.viewMode]));
        let targetComponent = undefined;
        for (let col of settingObject['columns']) {
          for (let cmp of col['components']) {
            if (cmp.uid === task.uid) {
              targetComponent = cmp;
            }
          }
        }
        if (targetComponent) {
          data = targetComponent.metadata;
        }
      }
    }
    const dialogRef = this.dialog.open(PanelistsUpdateDialogComponent, {
      width: '800px',
      disableClose: false,
      autoFocus: false,
      data: {
        title: this.translate.instant('Edit Panelist'),
        data: { metadata: data },
        guestsList: this.guestsList,
        leadList: this.leadList,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.schedulerTasks[index]['metadata'] = result.metadata;
        this.schedulerTasks[index]['groups'] = this.setPanelCamerasGroup(this.schedulerTasks[index]['groups'], result.metadata);
        this.analyseSchedulerData();
        this.hasChanged = true;
        let newTask = this.schedulerTasks[index];
        if (newTask.action === 'play') {
          newTask.action = 'stop';
          this.callGaurdAction(index, newTask);
        }
      }
    });
  }
  public openDefaultvideoSettings(index, task) {
    if (!task.uid || task.uid === '') {
      this.layoutUtilsService.showNotification(this.translate.instant('You need to select a ') + this.translate.instant('Media') + ' ' + this.translate.instant('component') + ' ' + this.translate.instant('for') + ' ' + this.translate.instant(this.tasksName[task.name].displayName) + ' ' + this.translate.instant('Task'), this.translate.instant('Dismiss'));
      return;
    }
    let data = JSON.parse(JSON.stringify(this.schedulerTasks[index]));
    let url = data['url'];
    if (!url || url === '') {
      let currentSettings = JSON.parse(JSON.stringify(this.sessionData['settings']));
      if (currentSettings.hasOwnProperty(this.viewMode)) {
        let settingObject = JSON.parse(JSON.stringify(currentSettings[this.viewMode]));
        let targetComponent = undefined;
        for (let col of settingObject['columns']) {
          for (let cmp of col['components']) {
            if (cmp.uid === task.uid) {
              targetComponent = cmp;
            }
          }
        }
        if (targetComponent) {
          data = {
            autoStart: targetComponent.autoStart,
            autoRepeat: targetComponent.autoRepeat,
            synchost: targetComponent.synchost,
            loop: targetComponent.loop,
            isPlaying: targetComponent.isPlaying,
            isUsedByShortcut: targetComponent.isUsedByShortcut,
            controls: targetComponent.controls,
            type: targetComponent.type,
            url: targetComponent.url,
            urlId: targetComponent.urlId,
            clickThruUrl: targetComponent.clickThruUrl,
            hlsUrl: targetComponent.hlsUrl,
            startMin: targetComponent.startMin,
            startSec: targetComponent.startSec
          };
        }
      }
    }

    const dialogRef = this.dialog.open(PartyStreamDialogComponent, {
      width: '600px',
      disableClose: false,
      autoFocus: false,
      data: {
        title: this.translate.instant('Edit') + ' ' + this.translate.instant('Media') + ' ' + this.translate.instant('Settings'),
        data: data,
        source: 'tasks',
        currentUserId: this.selectedUser._id
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.data) {
        // console.log('result', result);
        this.schedulerTasks[index]['autoStart'] = result.data['autoStart'];
        this.schedulerTasks[index]['autoRepeat'] = result.data['autoRepeat'];
        this.schedulerTasks[index]['synchost'] = result.data['synchost'];
        this.schedulerTasks[index]['loop'] = result.data['loop'];
        this.schedulerTasks[index]['isPlaying'] = false;
        this.schedulerTasks[index]['isUsedByShortcut'] = result.data['isUsedByShortcut'];
        this.schedulerTasks[index]['controls'] = result.data['controls'];
        this.schedulerTasks[index]['type'] = result.data['type'];
        this.schedulerTasks[index]['url'] = result.data['url'];
        this.schedulerTasks[index]['urlId'] = result.data['urlId'];
        this.schedulerTasks[index]['clickThruUrl'] = result.data['clickThruUrl'];
        this.schedulerTasks[index]['hlsUrl'] = result.data['hlsUrl'];
        this.schedulerTasks[index]['startMin'] = result.data['startMin'];
        this.schedulerTasks[index]['startSec'] = result.data['startSec'];
        this.analyseSchedulerData();
        this.hasChanged = true;
      }
    });
  }
  // public generateRTMPSettings(apiAction, callback: (dataResponse: any | undefined) => void | undefined = undefined) {
  //   if (!apiAction) {
  //     if (!this.loading) {
  //       this.loading = true;
  //       this.loaderService.display(true);
  //       this.requestService.generateRtmp({ organizationId: this.requestService.orgId, activate: true }, (data, error) => {
  //         if (error) {
  //           this.layoutUtilsService.showNotification(this.translate.instant('Not able to generate RTMP Link right now'), this.translate.instant('Dismiss'));
  //           this.loading = false;
  //           this.loaderService.display(false);
  //           if (callback) {
  //             callback(false);
  //           }
  //         }
  //         if (data) {
  //           this.loading = false;
  //           this.saveRTMPSessionSettingsData(data.results, () => {
  //             // this.sessionRTMPLinks[this.sessionRTMP.channelId] = 'running'; // make sure it is active by mat
  //             this.showRTMPGenerated(false);
  //             if (callback) {
  //               callback(true);
  //             }
  //           });
  //         }
  //       });
  //     }
  //   } else {
  //     let channelId = this.sessionRTMP.channelId; // generate-rtmp
  //     this.applyRTMP(apiAction, channelId, (data) => {
  //       if (data) {
  //         if (apiAction === 'start') {
  //           this.sessionRTMPLinks[this.sessionRTMP.channelId] = 'running';
  //         } else {
  //           this.sessionRTMPLinks[this.sessionRTMP.channelId] = 'idle';
  //         }
  //         if (callback) {
  //           callback(true);
  //         }
  //       }
  //     });
  //     //this.showRTMPGenerated();
  //     // this.layoutUtilsService.showNotification(this.translate.instant('RTMP Link already generated'), this.translate.instant('Dismiss'));
  //   }
  // }
  // public generateRTMPPullSettings(apiAction, index, task) {
  //   if (!apiAction) {
  //     if (!this.loading) {
  //       this.loading = true;
  //       this.loaderService.display(true);
  //       this.requestService.rtmpPull({ rtmpLink: task.rtmpUrl, organizationId: this.requestService.orgId, activate: true }, (data, error) => {
  //         if (error) {
  //           this.layoutUtilsService.showNotification(this.translate.instant('Not able to fetch RTMP Link right now'), this.translate.instant('Dismiss'));
  //           this.loading = false;
  //           this.loaderService.display(false);
  //         }
  //         if (data) {
  //           this.loading = false;
  //           let schedulerTasks = JSON.parse(JSON.stringify(this.schedulerTasks));
  //           schedulerTasks[index].hlsOutput = data.results.HlsOutput;
  //           schedulerTasks[index].channelId = data.results.ChannelId;
  //           this.updatescheduler(false, schedulerTasks, (data) => {
  //             if (data) {
  //               this.syncWithTeamMembers();
  //               this.sessionRTMPLinks[schedulerTasks[index].channelId] = 'running';
  //             }
  //           });
  //         }
  //       });
  //     }
  //   } else {
  //     this.applyRTMP(apiAction, task.channelId, (data) => {
  //       if (data) {
  //         if (apiAction === 'start') {
  //           this.sessionRTMPLinks[task.channelId] = 'starting';
  //           this.showRTMPPullGenerated(task.channelId);
  //         } else {
  //           this.sessionRTMPLinks[task.channelId] = 'stopping';
  //           this.showRTMPPullGenerated(task.channelId);
  //         }
  //       }
  //     });
  //     //this.showRTMPGenerated();
  //     // this.layoutUtilsService.showNotification(this.translate.instant('RTMP Link already generated'), this.translate.instant('Dismiss'));
  //   }
  // }
  // showRTMPGenerated(showPopup: boolean = true) {
  //   this.requestService.rtmpStatus([this.sessionRTMP.channelId], (data, error) => {
  //     if (error) {
  //       //do nothing
  //     }
  //     if (data) {
  //       this.sessionRTMPLinks[this.sessionRTMP.channelId] = data.results[this.sessionRTMP.channelId];
  //       if (data.results[this.sessionRTMP.channelId] === 'running' && showPopup) {
  //         let descriptionArray = [];
  //         // descriptionArray.push({ title: "Channel Id:", body: this.sessionRTMP.channelId });
  //         // descriptionArray.push({ title: "Hls Output:", body: this.sessionRTMP.hlsOutput });
  //         descriptionArray.push({ title: "RTMP URL:", body: this.sessionRTMP.rtmpUrl });
  //         descriptionArray.push({ title: "Stream Key:", body: this.sessionRTMP.streamKey });
  //         this.showPopup(this.translate.instant('RTMP Link Generated'), descriptionArray);
  //       } else {
  //         if (!showPopup) {
  //           setTimeout(() => {
  //             this.showRTMPGenerated(false);
  //           }, 30000);
  //         }
  //       }
  //     }
  //   });
  //   // this.sessionRTMPLinks[this.sessionRTMP.channelId] = 'running'; // make sure it is active by mat
  //   // this.showRTMPGenerated();


  // }
  // saveRTMPSessionSettingsData(newData, callback: (dataResponse: any | undefined) => void) {
  //   if (!this.loading) {
  //     this.loading = true;
  //     this.loaderService.display(true);
  //     let dataToSave = { _id: this.sessionData['_id'], sessionId: this.sessionData['_id'], rtmpUrl: newData.RtmpUrl, hlsOutput: newData.HlsOutput, channelId: newData.ChannelId, streamKey: newData.StreamKey };
  //     this.errorMessage = '';
  //     this.requestService.saveData('sessionsettings', dataToSave, (data, error) => {
  //       if (error) {
  //         this.errorMessage = error;
  //         this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
  //       }
  //       this.loading = false;
  //       this.loaderService.display(false);
  //       if (data) {
  //         this.sessionRTMP = { rtmpUrl: data.results.rtmpUrl, hlsOutput: data.results.hlsOutput, channelId: data.results.channelId, streamKey: data.results.streamKey };
  //         callback(data.results);
  //       }
  //     });
  //   }
  // }
  showPopup(title, descriptionArray) {
    const dialogRef = this.dialog.open(SimpleAlertEntityDialogComponent, {
      width: '600px',
      disableClose: true,
      data: {
        title: title,
        descriptionArray: descriptionArray
      }
    });
  }
  actionStatus(task) {
    // fix when the moerator camera and editor gets deleted from layout
    if (!task.active || (!this.sessionStarted && task.name !== 'select-session') || ((task.name === 'push-tile' || task.name === 'select-session' || task.name === 'start-lead-camera') && task.hasOwnProperty('metadata') && (!task.metadata.id || task.metadata.id === '')) ||
      (task.name === 'start-guest-camera' && task.hasOwnProperty('metadata') && (!task.metadata.id || task.metadata.id === '' || !task.uid || task.uid === '' || (task.uid && task.uid !== '' && !this.guestCamerasListIds.includes(task.uid)))) ||
      (task.name === 'update-editor' && ((!task.data || task.data === '') || (!task.uid || task.uid === '' || (task.uid && task.uid !== '' && !this.editorsListIds.includes(task.uid))))) ||
      (task.name === 'update-picture' && ((!task.image || task.image === '') || (!task.uid || task.uid === '' || (task.uid && task.uid !== '' && !this.picturesListIds.includes(task.uid))))) ||
      (task.name === 'update-iframe' && ((!task.metadata.link || task.metadata.link === '') || (!task.uid || task.uid === '' || (task.uid && task.uid !== '' && !this.iframeListIds.includes(task.uid))))) ||
      (task.name === 'update-defaultvideo' && ((!task.url || task.url === '') || (!task.uid || task.uid === '' || (task.uid && task.uid !== '' && !this.defaultvideoListIds.includes(task.uid))))) ||
      (task.name === 'make-announcement' && (!task.data || task.data === '')) ||
      (task.name === 'update-panel' && (!task.groups || (task.groups && task.groups.length === 0) || (task.metadata && task.metadata.length === 0 && task.action !== 'play') || (!task.uid || task.uid === '' || (task.uid && task.uid !== '' && task.uid !== this.panelId))))
      // || (task.name === 'rtmp-pull' && ((!task.channelId || task.channelId === '' || (task.channelId !== '' && !this.sessionRTMPLinks.hasOwnProperty(task.channelId)) || (task.channelId !== '' && this.sessionRTMPLinks.hasOwnProperty(task.channelId) && (this.sessionRTMPLinks[task.channelId] === 'na' || this.sessionRTMPLinks[task.channelId] === 'idle') && (task.action === '' || task.action === 'stop'))) || (!task.uid || task.uid === '' || (task.uid && task.uid !== '' && !this.defaultvideoListIds.includes(task.uid)))))
      // || (task.name === 'share-video' && (!task.url || task.url === ''))
      // || (task.name === 'generate-rtmp' && ((!this.sessionRTMP.channelId || this.sessionRTMP.channelId === '' || (this.sessionRTMP.channelId !== '' && this.sessionRTMPLinks && this.sessionRTMPLinks.hasOwnProperty(this.sessionRTMP.channelId) && (this.sessionRTMPLinks[this.sessionRTMP.channelId] === 'na' || this.sessionRTMPLinks[this.sessionRTMP.channelId] === 'idle') && (task.action === '' || task.action === 'stop'))) || (!task.uid || task.uid === '' || (task.uid && task.uid !== '' && !this.defaultvideoListIds.includes(task.uid)))))
    ) {
      return true;
    }
    return false;
  }
  public sendRefreshSession(type) {
    this.schedularActionHandle({ name: 'refresh-session', type: type, active: true }, -1);
  }
  private schedularActionHandle(action, index) {
    if (this.selectedUser.email === 'amerghalayini@interactivelife.com' || this.selectedUser.email === 'admintest@interactivelife.com') {
      console.log('Signal Sent to session', action);
    }
    // if (action.active) {
    switch (action.name) {
      case 'start-session':
        this.schedulerService.toggleSession.next(action.action === 'play' ? false : true);
        break;
      case 'end-session':
        this.schedulerService.toggleSession.next(action.action === 'stop' ? true : false);
        break;
      case 'refresh-session':
        this.schedulerService.refreshSession.next(action.type);
        break;
      case 'start-lead-camera':
        this.schedulerService.speakerCamera.next({ task: JSON.parse(JSON.stringify(action)), index: index });
        break;
      case 'start-guest-camera':
        this.schedulerService.guestCamera.next({ task: JSON.parse(JSON.stringify(action)), index: index });
        break;
      case 'push-tile':
        this.schedulerService.pushTile.next({ task: JSON.parse(JSON.stringify(action)), index: index, start: action.action === 'play' ? true : false });
        break;
      case 'share-screen':
        this.schedulerService.shareScreen.next(action.action === 'play' ? true : false);
        break;
      // case 'share-video':
      //   this.schedulerService.shareVideo.next({ metadata: JSON.parse(JSON.stringify(action)), start: action.action === 'play' ? true : false });
      //   break;
      case 'make-announcement':
        if (action.action === 'play')
          this.schedulerService.makeAnnouncement.next(JSON.parse(JSON.stringify(action)).data);
        break;
      case 'breakout':
        this.schedulerService.startBreakout.next({ task: JSON.parse(JSON.stringify(action)), index: index });
        break;
      case 'select-session':
        this.schedulerService.changeSession.next({ id: JSON.parse(JSON.stringify(action)).metadata.id, start: action.action === 'play' ? true : false });
        break;
      case 'update-editor':
        this.schedulerService.updateEditor.next({ task: JSON.parse(JSON.stringify(action)), start: action.action === 'play' ? true : false });
        break;
      case 'update-panel':
        this.schedulerService.updatePanel.next({ task: JSON.parse(JSON.stringify(action)), start: action.action === 'play' ? true : false });
        break;
      case 'update-picture':
        this.schedulerService.updatePicture.next({ task: JSON.parse(JSON.stringify(action)), start: action.action === 'play' ? true : false });
        break;
      case 'update-defaultvideo':
        this.schedulerService.updateVideo.next({ task: action, start: action.action === 'play' ? true : false });
        break;
      // case 'generate-rtmp':
      //   this.schedulerService.generateRtmp.next({ task: action, start: action.action === 'play' ? true : false });
      //   break;
      // case 'rtmp-pull':
      //   this.schedulerService.rtmpPull.next({ task: action, start: action.action === 'play' ? true : false });
      //   break;
      case 'update-iframe':
        this.schedulerService.updateIframe.next({ task: action, start: action.action === 'play' ? true : false });
        break;
    }
    // }
  }
  // applyRTMP(action, channelId, callback: (dataResponse: any | undefined) => void) {
  //   if (!this.loading) {
  //     // if (!this.validateTasks()) {
  //     //   return;
  //     // }
  //     this.loading = true;
  //     this.loaderService.display(true);
  //     this.errorMessage = '';
  //     this.requestService.rtmpAction(action, channelId, (data, error) => {
  //       if (error) {
  //         this.errorMessage = error;
  //         this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
  //       }
  //       this.loading = false;
  //       this.loaderService.display(false);
  //       if (data) {
  //         callback(data);
  //       }
  //     });
  //   }
  // }
  dragged() {
    this._zIndex = this._zIndexTemp + 1;
    this.opentokService.zIndexDraggable.next(this._zIndex);
  }
  public syncWithTeamMembers() {
    if (this.session)
      this.opentokService.sendSignal('syncTaskManager', '1', this.session);
    else if (this.sessionData.streamMode === 'cloudlive') {
      this.ablyService.sendSessionSignal(this.sessionId, 'syncTaskManager', this.selectedUser._id);
    }
  }

  private setPanelCamerasGroup(groups, selectedUsers) {
    let newGroups = [];
    if (groups && groups.length > 0) {
      newGroups = groups;
    } else {
      newGroups = this.panelGroups;
    }
    if (newGroups.length > 1) {
      newGroups = groups.slice(0, 1);
    }
    if (selectedUsers && selectedUsers.length > 0) {
      let selectedUsersId = selectedUsers.map((comp) => comp['id']);
      for (let grp of newGroups) {
        grp.cameras = grp.cameras.filter((cam) => selectedUsersId.includes(cam.id));
      }
      let groupLength = 1;
      // if (selectedUsers.length > 0 && selectedUsers.length < 4) {
      //   groupLength = selectedUsers.length;
      // } else if (selectedUsers.length >= 4) {
      //   groupLength = 3;
      // }
      // let eachGroupLength = Math.ceil(selectedUsers.length / groupLength);
      let eachGroupLength = selectedUsers.length;

      let remainingUsers = selectedUsers.filter((usr) => {
        for (let grp of newGroups) {
          for (let cmr of grp.cameras) {
            if (cmr.id === usr.id) {
              return false;
            }
          }
        }
        return true;
      })
      for (let usr of remainingUsers) {
        // let leastCameras = this.getLeastCameras(newGroups, eachGroupLength);
        // if (leastCameras > -1) {
        // newGroups[leastCameras].cameras.push(
        newGroups[0].cameras.push(
          {
            uid: guid(),
            id: usr.id,
            name: usr.name,
            type: usr.type
          }
        );
        // }
      }
      this.panelGroups = newGroups;
    } else {
      newGroups = this.panelGroups;
    }
    return newGroups;
  }
  // getLeastCameras(newGroups, eachGroupLength) {
  //   if (newGroups.length > 0) {
  //     let min = newGroups[0].cameras.length;
  //     let selectedIdx = 0;
  //     let idx = 0;
  //     for (let grp of newGroups) {
  //       if (grp.cameras.length < min && grp.cameras.length <= eachGroupLength) {
  //         min = grp.cameras.length;
  //         selectedIdx = idx;
  //       }
  //       idx++;
  //     }
  //     return selectedIdx;
  //   }
  //   return -1;
  // }
  insertCustomTask(task) {
    setTimeout(() => {
      this.taskSelected = '';
    }, 100);
    let content = task;
    content['uniqueId'] = guid(); // generate Uid for new component
    this.schedulerTasks.push(JSON.parse(JSON.stringify(task)));
    this.hasChanged = true;
    let schedulerTasks = JSON.parse(JSON.stringify(this.schedulerTasks));
    this.updatescheduler(false, schedulerTasks, (newData) => {
      if (newData) {
        this.analyseSchedulerData();
        this.syncWithTeamMembers();
      }
    });
  }
}
