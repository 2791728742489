import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { RequestService, LayoutUtilsService, LoaderService } from '../../services';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { urlSafeBase64Encoding, isValidHttpUrl, guid, getFileName, getOtherImage } from '../../helpers';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ConfirmInputEntityDialogComponent } from '../modals';
import { ModalGalleryDialogComponent } from '../../../shared/components/custom-gallery-dialog/custom-gallery-dialog.component';
import { ErrorStateMatcher } from '@angular/material/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { DefaultSettings } from '../layout-components';
import { MatDialog } from '@angular/material/dialog';
import { Moment } from 'moment';
import * as moment from 'moment';

class MyDialogErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

interface DialogData {
  dataType: string;
  dataTypeTitle: string;
  templateSettings: any;
  templateDisabled: boolean;
  title: string;
  data: any;
  modalSetting: any;
  confirmData: any;
}

@Component({
  selector: 'app-imagegallery-dialog-modal',
  templateUrl: './custom-imagegallery-dialog.component.html',
  styleUrls: ['./custom-imagegallery-dialog.component.scss']
})
export class ModalImagegalleryDialogComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public errorMessage: string = '';
  private organization: any = undefined;
  public selectedUser: any;
  public loading: boolean = false;
  public esMatcher = new MyDialogErrorStateMatcher();
  public isSubmitted: boolean = true;
  public metaFieldSetting = undefined;
  public thumbnailsStatuses: any[] = DefaultSettings.thumbnailsStatuses;
  public imageListTemp: any[] = [];
  public imageList: any[] = [];
  public slideshowsettings: any = {};
  public originalData = undefined;
  private defaultSettings: any = {
    imageList: [], slideshowsettings: JSON.parse(JSON.stringify(DefaultSettings.slideshowSettings))
  };
  public imagesType: any = {
    image: 'Image'
  }
  public imagesTypeKeys: string[] = Object.keys(this.imagesType);
  selected = new FormControl(0);
  constructor(private translate: TranslateService, public dialog: MatDialog,
    private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService, private changeDetectorRef: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ModalImagegalleryDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {
    //console.log('DialogData', data);
  }
  ngOnInit() {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.buildSetting();
        }
      })
    );
    this.subscriptions.push(
      this.requestService.pageOrganization.subscribe((data) => {
        if (data) {
          this.organization = data;
        }
      })
    );
  }
  closeModal(data): void {
    this.dialogRef.close(data);
  }
  openImageLibrary(): void {
    const dialogRef = this.dialog.open(ModalGalleryDialogComponent, {
      width: '1600px',
      disableClose: false,
      autoFocus: false,
      data: {
        title: this.translate.instant('Select Images to insert'),
        galleryType: 'imagegallery',
        multipleSelection: true,
        data: [],
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (Array.isArray(result)) {
          for (let itm of result) {
            let fileName = getFileName(itm.imageUrl, false);
            this.imageList.unshift({ id: guid(), name: fileName, type: 'image', url: itm.imageUrl, thumbnail: itm.thumbnail });
          }
        } else {
          let fileName = getFileName(result, false);
          this.imageList.unshift({ id: guid(), name: fileName, type: 'image', url: result, thumbnail: getOtherImage('', result, '-thumbnail') });
        }
      }
      // console.log('this.imageList', this.imageList);
    });
  }
  addImages(): void {
    this.imageList.unshift({ id: guid(), name: '', type: 'image', url: '', thumbnail: '' });
  }
  removeImage(idx): void {
    this.imageList.splice(idx, 1);
  }
  public setType(index, val) {
    let selectedData = JSON.parse(JSON.stringify(this.imageList));
    let idx = 0;
    for (let dt of selectedData) {
      if (idx === index) {
        selectedData[idx]['url'] = '';
        selectedData[idx]['type'] = val;
      }
      idx++;
    }
    this.imageList = JSON.parse(JSON.stringify(selectedData));
  }
  private buildSetting() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.data.modalSetting.fields = [];

      let orgId = undefined;
      let lang = undefined;
      if (this.data.dataType === 'resource/user') {
        orgId = this.requestService.orgId;
        lang = this.requestService.lang;
      }
      this.requestService.getMetaData(this.data.dataType, undefined, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          let newFields = data.results.fields;
          this.metaFieldSetting = this.buildMetaSetting(data.results, parent = undefined);
          let idx = 0;
          if (this.data.modalSetting.hasOwnProperty('customSettings')) {
            for (let fld of newFields) {
              if (this.data.modalSetting.customSettings.hasOwnProperty(fld.name)) {
                newFields[idx]['visible'] = this.data.modalSetting.customSettings[fld.name].visible;
              }
              idx++;
            }
          }
          this.data.modalSetting.fields = newFields;
          if (this.data.data.hasOwnProperty('_id')) {
            this.loadData();
          } else {
            this.data.data = this.getEmptyObject();
            this.imageList = this.data.data['settings']['imageList'];
            this.slideshowsettings = this.data.data['settings']['slideshowsettings'];
          }
        } else {
          console.log(this.translate.instant('Something is Wrong'));
        }
      }, orgId, lang);
    }
  }
  buildMetaSetting(data, parent = undefined) {
    let dataObject = {};
    // let tabObject = [];
    for (let col of data.fields) {
      if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
        if (parent) {
          col['inputName'] = parent + col['name'];
        }
        dataObject[col.name] = col;
      } else if (col.type === 'object') {
        dataObject[col.name] = this.buildMetaSetting(col);
      }
      else if (col.type === 'table') {
        dataObject[col.name] = col;
      }
    }
    return dataObject;
  }
  public loadData() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let dataId = this.data.data['_id'];
      if (this.data.modalSetting.hasOwnProperty('useOrgId')) {
        dataId = dataId + '/' + this.requestService.orgId;
      }
      this.requestService.getSingleData(this.data.dataType, dataId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          this.data.data = this.getCleanDataObject(data.results);
          if (!this.data.data.defaultlayout)
            this.data.data.defaultlayout = this.metaFieldSetting.defaultlayout.default;
          this.originalData = JSON.parse(JSON.stringify(this.data.data));
          this.imageList = this.data.data['settings']['imageList'];
          this.slideshowsettings = this.data.data['settings']['slideshowsettings'];
        }
        this.loading = false;
      });
    }
  }
  private getCleanDataObject(currentObject) {
    let newObj: any = currentObject;
    for (let col of this.data.modalSetting.fields) {
      if (col.type === 'datetime' && newObj.hasOwnProperty(col.name)) {
        newObj[col.name] = moment.utc(newObj[col.name]).local().format('YYYY-MM-DDTHH:mm');
      } else if (col.type === 'tags' && !newObj.hasOwnProperty(col.name)) {
        newObj[col.name] = [];
      } else if (col.type === 'boolean' && col.name === 'isPasswordRequired' && newObj['isSuperAdmin']) {
        newObj[col.name] = true;
      }
      if (newObj[col.name] === null || newObj[col.name] === undefined) {
        if (col.type === 'reference') {
        } else if (col.type === 'boolean') {
          newObj[col.name] = false;
        } else if (col.type === 'color') {
          newObj[col.name] = '#ffffff';
        } else if (col.type === 'picturearray') {
          newObj[col.name] = [];
        } else if (col.type === 'maparray' || col.type === 'tags') {
          newObj[col.name] = [];
        } else if (col.type === 'datetime') {
          newObj[col.name] = moment.utc().format('YYYY-MM-DDTHH:mm');
        } else {
          newObj[col.name] = '';
        }
      }
      if (col.name === 'settings') {
        if (!newObj[col.name] || (newObj[col.name] && !newObj[col.name]['imageList'])) {
          newObj[col.name] = JSON.parse(JSON.stringify(this.defaultSettings));
        }
        if (!newObj[col.name]['slideshowsettings']) {
          newObj[col.name]['slideshowsettings'] = JSON.parse(JSON.stringify(this.defaultSettings['slideshowsettings']));
        }
      }
    }
    return newObj;
  }

  public setAttribute(id, val) {
    this.data.data[id] = val;
  }
  public setAttributeBoolean(id, val) {
    this.data.data[id] = JSON.parse(val);
  }
  public setReferenceAttribute(parentId, id, val) {
    this.data.data[parentId][id] = val;
  }
  public setMultipleReferenceAttribute(id, val) {
    this.data.data[id] = val;
  }
  private getCleanObject(data) {
    let newObj: any = { _id: data._id };
    for (let col of this.data.modalSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== 'action') {
        if (col.dataType === 'password') {
          newObj[col.name] = urlSafeBase64Encoding(data[col.name]);
        } else if (col.type === 'reference') {
          if (col.reference.kind === 'multiple') {
            if (data[col.name] && data[col.name].length > 0)
              newObj[col.name] = data[col.name];
          } else {
            if (data[col.name] !== '')
              newObj[col.name] = data[col.name];
          }
        } else if (col.type === 'datetime') {
          newObj[col.name] = moment(data[col.name]).utc().format('YYYY-MM-DDTHH:mm');
        } else {
          newObj[col.name] = data[col.name];
        }
        // if (this.data.modalSetting.hasOwnProperty('customSettings')) {
        //   if (this.data.modalSetting.customSettings.hasOwnProperty(col.name)) {
        //     newObj[col.name] = this.data.modalSetting.customSettings[col.name].value;
        //   }
        // }
      }
    }

    return newObj;
  }
  private validateObject(data: any) {
    for (let col of this.data.modalSetting.fields) {
      if ((!col.nullable && !col.generated) && col.type !== 'action' && col.name !== 'privacyterm' && col.visible) {
        if (col.type === 'reference') {
          if (col.reference.kind === 'multiple') {
            // console.log('col.name', col.name, data[col.name] );
            if (col.name === 'resources') {
              if (data && data[col.name] && data[col.name].length === 0) {
                return false;
              }
              if (data[col.name][0]['_id'] === '') {
                return false;
              }
            } else {
              if (data && data[col.name] && data[col.name].length === 0) {
                return false;
              }
            }
          } else {
            if (data && data[col.name] && data[col.name]['_id'] === '') {
              // console.log('col.name', col.name, data[col.name] );
              return false;
            }
          }
        } else if (col.type === 'tags') {
          if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        } else if (col.type === 'picturearray') {
          if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        } else if (col.type === 'email') {
          if (data && (data[col.name].length === 0 || data[col.name] === undefined || !this.isEmailValid(data[col.name]))) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        } else if (col.type === 'url') {
          if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        } else {
          if (data && (data[col.name] === '' || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        }
      }
    }
    return true;
  }
  isValidImageUrl(url) {
    if (url.toLowerCase().indexOf('.bmp') > -1 || url.toLowerCase().indexOf('.jpeg') > -1 || url.toLowerCase().indexOf('.jpg') > -1 || url.toLowerCase().indexOf('.gif') > -1 || url.toLowerCase().indexOf('.png') > -1) {
      return true;
    } else {
      return false;
    };
  }
  private validateLogic(data) {
    for (let col of this.data.modalSetting.fields) {
      if (col.name === 'settings') {
        if (data && data[col.name] && data[col.name]['imageList'] && data[col.name]['imageList'].length > 0) {
          for (let vid of data[col.name]['imageList']) {
            if (vid.name.trim() === '') {
              return this.translate.instant('Fill in the title');
            } else if (vid.type === '') {
              return this.translate.instant('Select Type for video');
            } else if (vid.url.trim() === '' || (vid.url && !isValidHttpUrl(vid.url))) {
              return this.translate.instant('Fill in the correct URL link format');
            } else if (!this.isValidImageUrl(vid.url)) {
              return this.translate.instant('You should use an jpeg, jpg, gif, png link format');
            }
          }
        }
        if (data && data[col.name] && data[col.name]['slideshowsettings']) {
          if (Math.abs(data[col.name]['slideshowsettings']['mainImage']) + Math.abs(data[col.name]['slideshowsettings']['thumbnails']) !== 100) {
            return this.translate.instant('Please make sure the main image and thumbnail percentages add up to 100');
          }
        }
      }
      if (!col.generated && col.type !== 'action' && col.name !== 'privacyterm' && col.visible) {
        if (col.hasOwnProperty('charlimit')) {
          if (data && data[col.name] && data[col.name].length > col.charlimit) {
            return this.translate.instant(col.displayName + ' should have a maximum of ' + col.charlimit + ' characters');
          }
        } else {
          if (col.type === 'textarea') {
            if (data && data[col.name] && data[col.name].length > 1000) {
              return this.translate.instant(col.displayName + ' should have a maximum of 1000 characters');
            }
          }
        }
        if (col.type === 'url') {
          if (data && data[col.name] && data[col.name].length > 0) {
            if (!isValidHttpUrl(data[col.name])) {
              return this.translate.instant('Fill in the correct URL link format');
            }
          }
        }
      }
      if (((!col.nullable || (col.nullable && col.type === 'boolean')) && !col.generated) && col.type !== 'action' && col.name !== 'privacyterm' && col.visible) {
        if (col.hasOwnProperty('validation')) {
          for (let vald of col.validation) {
            if (vald.operator === 'lt') {
              if (col.type === 'datetime') {
                // console.log('data[vald.target]', data[vald.target]);
                // console.log('moment(data[col.name]', data[col.name]);
                // console.log('minutes', moment(data[vald.target]).diff(moment(data[col.name]), 'minutes'));
                if (!(data[col.name] && data[vald.target] && moment(data[vald.target]).diff(moment(data[col.name]), 'minutes') > 0)) {
                  return col.displayName + ' should be less than ' + this.metaFieldSetting[vald.target]['displayName'];
                }
              } else {
                if (!(data[col.name] && data[vald.target] && data[col.name] < data[vald.target])) {
                  return col.displayName + ' should be less than ' + this.metaFieldSetting[vald.target]['displayName'];
                }
              }
            } else if (vald.operator === 'gt') {
              if (col.type === 'datetime') {
                if (!(data[col.name] && data[vald.target] && moment(data[col.name]).diff(moment(data[vald.target]), 'minutes') > 0)) {
                  return col.displayName + ' should be greater than ' + this.metaFieldSetting[vald.target]['displayName'];
                }
              } else {
                if (!(data[col.name] && data[vald.target] && data[col.name] < data[vald.target])) {
                  return col.displayName + ' should be greater than ' + this.metaFieldSetting[vald.target]['displayName'];
                }
              }
            } else if (vald.operator === 'eq') {
              if (data[col.name] !== data[vald.target]) {
                if (vald.hasOwnProperty('value')) {
                  if (data[col.name] !== vald.value) {
                    return col.displayName + ' should be equal to ' + this.metaFieldSetting[vald.target]['displayName'];
                  }
                } else {
                  return col.displayName + ' should be equal to ' + this.metaFieldSetting[vald.target]['displayName'];
                }
              }
            } else if (vald.operator === 'url') {
              if (!this.isUrlValid(data[col.name])) {
                return col.displayName + ' should url format.';
              }
            }
          }
        }
      }
    }
    return undefined;
  }
  public saveData(type) {
    // console.log('saveData', this.data.data);
    // console.log('this.data.modalSetting', this.data.modalSetting);
    // console.log('getCleanObject', this.getCleanObject(this.data.data));
    if (!this.loading) {
      for (let vid of this.imageList) {
        vid.url = vid.url.trim();
        vid.name = vid.name.trim();
      }
      this.data.data['settings']['imageList'] = JSON.parse(JSON.stringify(this.imageList));
      this.data.data['settings']['slideshowsettings'] = JSON.parse(JSON.stringify(this.slideshowsettings));
      // console.log(this.data.data['settings']['imageList']);
      let validateLogic = this.validateLogic(this.data.data);
      if (!validateLogic) {
        if (this.validateObject(this.data.data)) {
          try {
            this.data.data['settings']['imageList'] = JSON.parse(JSON.stringify(this.imageList));
            this.loading = true;
            this.errorMessage = '';
            this.requestService.saveData(this.data.dataType, this.getCleanObject(this.data.data), (data, error) => {
              if (error) {
                this.errorMessage = error;
                this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
              }
              if (data) {
                if (type === 'create') {
                  this.layoutUtilsService.showNotification(this.data.dataTypeTitle + ' ' + this.translate.instant('created Successfully'), this.translate.instant('Dismiss'));
                } else if (type === 'edit') {
                  this.layoutUtilsService.showNotification(this.data.dataTypeTitle + ' ' + this.translate.instant('edited Successfully'), this.translate.instant('Dismiss'));
                }
                this.closeModal({ action: 'refresh', data: data });
              }
              this.loading = false;
            });
          } catch (e) {
            this.layoutUtilsService.showNotification(this.translate.instant('Something is wrong contact the administrator'), this.translate.instant('Dismiss'));
          }
        } else {
          this.layoutUtilsService.showNotification(this.translate.instant('You need to set all mandatory fields'), this.translate.instant('Dismiss'));
        }
      } else {
        this.layoutUtilsService.showNotification(this.translate.instant(validateLogic), this.translate.instant('Dismiss'));
      }
    }
  }
  private getEmptyObject() {
    let newObj = {};
    for (let col of this.data.modalSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== 'action') {
        if (col.type === 'reference') {
          if (col.reference.kind === 'multiple') {
            if (col.name === 'resources') {
              newObj[col.name] = [{ _id: '', name: '' }];
            } else {
              newObj[col.name] = [];
            }
          } else {
            newObj[col.name] = { _id: '', name: '' };
          }
        } else if (col.type === 'boolean') {
          newObj[col.name] = false;
        } else if (col.type === 'color') {
          newObj[col.name] = '#ffffff';
        } else if (col.type === 'picturearray') {
          newObj[col.name] = [];
        } else if (col.type === 'maparray' || col.type === 'tags') {
          newObj[col.name] = [];
        } else if (col.type === 'datetime') {
          newObj[col.name] = moment.utc().format('YYYY-MM-DDTHH:mm');
        } else if (col.type === 'json') {
          newObj[col.name] = JSON.parse(JSON.stringify(this.defaultSettings));
        } else {
          newObj[col.name] = '';
        }
        if (this.data.modalSetting.hasOwnProperty('customSettings')) {
          if (this.data.modalSetting.customSettings.hasOwnProperty(col.name)) {
            newObj[col.name] = this.data.modalSetting.customSettings[col.name].value;
          }
        }
      }
    }
    return newObj;
  }
  private isUrlValid(url) {
    var re = /^((http[s]?):\/)+\/?([^:\/\s]?)((\/\w+)*\/)([\w\-\.]+[^#?\s]+)(.*)?(#[\w\-]+)?$/;
    return re.test(String(url).toLowerCase());
  }
  private isEmailValid(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  drop(event: CdkDragDrop<any[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }
  filterNumber(val, id) {
    if (val < 0) {
      this.slideshowsettings[id] = Math.abs(val);
    }
  }
}
