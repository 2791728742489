import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { ErrorStateMatcher, ThemePalette } from '@angular/material/core';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { ProgressBarMode } from '@angular/material/progress-bar';
import { I } from '@angular/cdk/keycodes';
import { financial, humanFileSize } from '../../helpers/functions';

interface DialogData {
    title: string;
    maxHeight: number;
    maxWidth: number;
    path: string;
    type: string;
    isEncoded: string;
    folder: string;
    confirmData: any;
    target: any;
    addName: boolean;
    isImageUpload: boolean;
    allowedExtensions: any[];
    allowedAcceptExtensions: string;
    limitNumberOfImages: number;
    alreadyUploadedImagesLength: number;
}
@Component({
    selector: 'app-multiple-upload-dialog',
    templateUrl: './custom-multiple-upload-dialog.component.html',
    styleUrls: ['./custom-multiple-upload-dialog.component.scss']
})
export class MultipleModalDialogComponent implements OnInit {
    public errorMessage: string = '';
    public loading: boolean = false;
    public hasFormErrors: boolean = false;
    public allowedExtensions: string[] = [];
    public allowedAcceptExtensions: string = '';
    public max_size: number = 5000000;
    public maxHeight: number = 5000;
    public maxWidth: number = 5000;
    public isEncoded: string = undefined;
    public folder: string = undefined;
    public type: string = undefined;
    public path: string = undefined;
    public target: any = undefined;
    public addName: boolean = true;
    public isImageUpload: boolean = false;
    public limitNumberOfImages: number = 0;
    public alreadyUploadedImagesLength: number = 0;

    currentFiles: any[] = [];
    selectedFiles: FileList;
    progressInfosOld = [];
    fileInfos: Observable<any>;

    color: ThemePalette = 'primary';
    mode: ProgressBarMode = 'determinate';
    constructor(
        public dialogRef: MatDialogRef<MultipleModalDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, private loaderService: LoaderService,
        private layoutUtilsService: LayoutUtilsService,
        private requestService: RequestService,
        private changeDetectorRefs: ChangeDetectorRef, private translate: TranslateService) {
        // console.log('DialogData', data);
        this.maxHeight = data.maxHeight || 3000;
        this.maxWidth = data.maxWidth || 3000;
        this.path = data.path;
        this.type = data.type;
        this.isEncoded = data.isEncoded;
        this.folder = data.folder;
        this.allowedExtensions = data.allowedExtensions || [];
        this.addName = data.addName;
        this.isImageUpload = data.isImageUpload;
        this.alreadyUploadedImagesLength = data.alreadyUploadedImagesLength;
        if (data.target) {
            this.selectFiles(data.target);
        } else {
            this.closeModal()
        }
        this.limitNumberOfImages = data.limitNumberOfImages || 0;
    }
    ngOnInit() {

    }

    closeModal(): void {
        let uploadedProcesses = this.progressInfosOld.filter((itm) => itm.uploaded);
        if (uploadedProcesses.length > 0) {
            this.dialogRef.close(uploadedProcesses);
        } else {
            this.dialogRef.close();
        }
    }
    selectFiles(target) {
        // this.progressInfos = [];
        // this.currentFiles = [];
        this.selectedFiles = target.files;
        // this.hasFormErrors = false;
        this.readFiles(this.selectedFiles, (currentFile) => {
            if (currentFile.error) {
                this.hasFormErrors = true;
            }
            currentFile.action = 'read';
            this.currentFiles.push(currentFile);
            // if(this.selectedFiles.length === this.currentFiles.length){
            //     this.uploadFiles();
            // }
        });
    }
    deleteFile(i) {
        this.currentFiles.splice(i, 1);
        this.hasFormErrors = (this.currentFiles.filter((itm) => itm.error)).length > 0;
    }
    cancelFile(idx) {
        if (this.currentFiles[idx]['subscription']) {
            this.currentFiles[idx]['subscription'].unsubscribe();
        }
        this.currentFiles[idx].error = true;
        this.currentFiles[idx].complete = true;
        this.currentFiles[idx].value = 0;
        this.currentFiles[idx].message = 'Cancelled';
        this.updateLoadingStatus();
    }
    uploadFiles() {
        let toUploadFiles = this.currentFiles.filter((itm) => {
            if (itm.action != 'upload') {
                return true;
            }
            return false
        })
        if (toUploadFiles.length > 0) {
            this.errorMessage = '';
            this.loading = true;
            this.loaderService.display(true);
            for (let i = 0; i < this.currentFiles.length; i++) {
                if (this.currentFiles[i].action != 'upload') {
                    this.currentFiles[i].action = 'upload';
                    // this.upload(i, this.currentFiles[i]);
                    this.upload(i);
                }

            }
        }
    }
    updateLoadingStatus() {
        let remainingProcesses = this.currentFiles.filter((itm) => !itm.complete && !itm.error);
        if (remainingProcesses.length === 0) {
            this.loading = false;
            this.loaderService.display(false);
            this.progressInfosOld = this.currentFiles;
        }
    }
    upload(idx) {
        this.currentFiles[idx]['value'] = 0;
        this.currentFiles[idx]['uploaded'] = undefined;
        this.currentFiles[idx]['complete'] = false;
        this.currentFiles[idx]['subscription'] = undefined;
        this.currentFiles[idx]['message'] = undefined;
        // if (browsed_file.message) {
        //     this.progressInfos[idx]['error'] = true;
        //     this.progressInfos[idx]['complete'] = true;
        // } else {
        this.currentFiles[idx]['subscription'] = this.requestService.onUploadFilesByPathNew(this.path, this.currentFiles[idx], this.type, this.isEncoded, undefined, this.folder, this.addName).subscribe(
            event => {
                if (event.type === HttpEventType.UploadProgress) {
                    this.currentFiles[idx].value = Math.round(100 * event.loaded / event.total);
                } else if (event instanceof HttpResponse) {
                    this.currentFiles[idx].complete = true;
                    this.currentFiles[idx].message = 'Completed';
                    if (event.body && !event.body.status && event.body.message) {
                        this.currentFiles[idx].error = true;
                        this.currentFiles[idx].message = 'Could not upload the file';
                    }
                    if (event.body && event.body.status) {
                        this.currentFiles[idx]['uploaded'] = event.body.results;
                    }
                    this.updateLoadingStatus();
                }
            },
            err => {
                this.currentFiles[idx].error = true;
                this.currentFiles[idx].complete = true;
                this.currentFiles[idx].value = 0;
                this.currentFiles[idx].message = 'Could not upload the file';
                this.currentFiles[idx]['subscription'].unsubscribe;
                this.updateLoadingStatus();
            });
        // }
    }

    /**
     *  @param files: list of browsed files
     *  @param index: iterator over browsed images
     *
     *  read files browsed by user
     */
    readFiles(files, callback): void {
        for (let index = 0; index < files.length; index++) {
            let currentFile = { error: false, name: files[index].name, text: files[index].name.split('.')[0], id: files[index].id, originalFile: files[index], extension: 'jpeg', source_url: null, message: 'Ready for upload', size: '0 MB', action: '' };
            let fileExt = files[index].name.split('.').pop();
            currentFile.extension = fileExt.toLowerCase();
            const max_size = this.max_size;
            currentFile.size = humanFileSize(files[index].size, true);
            if (this.allowedExtensions.indexOf(currentFile.extension) === -1) {
                currentFile.error = true;
                currentFile.message = this.translate.instant('The file type is not allowed');
                callback(currentFile);
            } else if (files[index].size > max_size) {
                currentFile.error = true;
                currentFile.message = this.translate.instant('Maximum size allowed is') + ' ' + max_size / 1000000 + 'MB';
                callback(currentFile);
            } else {
                this.readFile(files[index], (event) => {
                    if (this.isImageUpload) {
                        const max_height = this.maxHeight;
                        const max_width = this.maxWidth;
                        var image = new Image();
                        this.readImage(event, image, (imgresult) => {
                            // console.log('readImage', imgresult);
                            var canvas = document.createElement("canvas");
                            var context = canvas.getContext("2d");
                            var ratio = Math.min(max_width / imgresult.width, max_height / imgresult.height);

                            if (imgresult.width < imgresult.width * ratio && imgresult.height < imgresult.height * ratio) {
                                canvas.width = imgresult.width;
                                canvas.height = imgresult.height;
                            } else {
                                canvas.width = imgresult.width * ratio;
                                canvas.height = imgresult.height * ratio;
                            }
                            context.drawImage(image,
                                0,
                                0,
                                image.width,
                                image.height,
                                0,
                                0,
                                canvas.width,
                                canvas.height
                            );
                            if (image.width > max_width || image.height > max_height) {
                                currentFile['originalFile'] = this.dataURLtoFile(canvas.toDataURL("image/" + currentFile['extension'], 1), currentFile.text + '.' + currentFile['extension']);
                            }
                            callback(currentFile);
                        });
                    } else {
                        callback(currentFile);
                    }
                });
            }
        }
    }
    readFile(file, callback): void {
        let reader = new FileReader();
        reader.onload = () => {
            callback(reader.result);
        }
        reader.readAsDataURL(file);
    }
    readImage(file, image, callback): void {
        image.onload = () => {
            callback(image);
        }
        image.src = file;
    }
    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }

}
