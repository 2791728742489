<div class="gallery-container" *ngIf="!errorLoading" #gallery [class.vertical-scroll]="view === 'grid'"
    (mouseenter)="showToggleIcon(true)" (mouseleave)="showToggleIcon(false)" style="height: 100%;">
    <div class="icon" *ngIf="showIcon && images.length > 0">
        <mat-icon *ngIf="view === 'grid'" [title]="'Slideshow View' | translate" (click)="view = 'slideshow'">
            view_carousel</mat-icon>
        <mat-icon *ngIf="view === 'slideshow'" [title]="'Grid View' | translate" (click)="view = 'grid'">grid_view
        </mat-icon>
    </div>
    <div class="grid" *ngIf="view === 'grid'">
        <div class="image" *ngFor="let image of images">
            <img [src]="image.url" (error)="image.url = errorDefaultImage" (click)="selectedImage = image; openImage()">
        </div>
    </div>

    <div class="slideshow" fxFlex fxLayout="column" *ngIf="view === 'slideshow'">
        <div class="center-image" fxFlex fxGrow="1" fxLayout="row"
            [ngStyle]="{ 'max-height': slideshowImageHeight + 'px', 'min-height': slideshowImageHeight + 'px' }">
            <div fxFlex class="next-previous" fxGrow="0" fxLayoutAlign="start center">
                <mat-icon [title]="'Previous' | translate" (click)="movePrevious()">arrow_back</mat-icon>
            </div>
            <div fxFlex style="align-self: center; height: 100%;">
                <img *ngIf="selectedImage" (click)="openImage()" [src]="selectedImage.url"
                    (error)="selectedImage.url = errorDefaultImage" [class.slide-in-left]="direction === 'previous'"
                    style="height: 100%;" [class.slide-in-right]="direction === 'next'">
            </div>
            <div fxFlex class="next-previous" fxGrow="0" fxLayoutAlign="end center">
                <mat-icon [title]="'Next' | translate" (click)="moveNext()">arrow_forward</mat-icon>
            </div>
        </div>
        <div class="carousel" [id]="'carousel-' + galleryId" fxFlex="30" *ngIf="thumbnailHeight > 0 && showThumbnail">
            <div class="carousel-grid">
                <div class="image" *ngFor="let image of images" [ngStyle]="{ 'max-height': thumbnailHeight + 'px' }">
                    <img [src]="image.url" (error)="image.url = errorDefaultImage" [style.height.px]="thumbnailHeight"
                        [id]="image.id + '-' + galleryId" (click)="selectedImage = image">
                </div>
            </div>
        </div>
    </div>
</div>

<div class="gallery-container" *ngIf="errorLoading">
    {{errorMessage}}
</div>