import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../../shared/services';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { isValidHttpUrl } from '../../../../shared/helpers';

interface ViewDialogData {
	title: string;
	data: any;
	dataList: any[];
	confirmData: any;
}

@Component({
	selector: 'app-audio-edit-dialog-modal',
	templateUrl: './audio-edit-dialog.component.html',
	styleUrls: ['./audio-edit-dialog.component.scss']
})
export class AudioEditDialogComponent implements OnInit {
	public errorMessage: string = '';
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public returnData: any = { src: '', autoPlay: false, loop: false, volume: 0.5};
	constructor(
		private requestService: RequestService, private translate: TranslateService,
		private layoutUtilsService: LayoutUtilsService, public dialog: MatDialog,
		public dialogRef: MatDialogRef<AudioEditDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		console.log('ViewDialogData', data);
	}

	ngOnInit() {
		if(this.data.data){
			this.returnData = this.data.data;
		}
	}

	closeModal(data): void {
		if (data) {
			if (data.src && data.src !== '') {
				if (!isValidHttpUrl(data.src)) {
					this.layoutUtilsService.showNotification(this.translate.instant('Fill in the correct URL link format'), this.translate.instant('Dismiss'));
					return;
				}else if (!this.validateAudio(data.src)){
					this.layoutUtilsService.showNotification(this.translate.instant('Not a valid URL audio extension (mp3, aac, wav, m4a)'), this.translate.instant('Dismiss'));
					return;
				}
			}else{
				this.layoutUtilsService.showNotification(this.translate.instant('Fill in the URL link'), this.translate.instant('Dismiss'));
				return;
			}
		}
		this.dialogRef.close(data);
	}
	validateAudio(url){
		let types = /(\.|\/)(mp3|aac|wav|m4a)$/i;
		if (types.test(url)) {
		    return true;
		}
		return false;
	}
}
