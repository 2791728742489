<div class="kt-portlet">
	<div class="kt-portlet__head kt-portlet__head__custom">
		<div class="kt-portlet__head-label">
			<h3 class="kt-portlet__head-title">
				<div *ngIf="data.alertSetting.hasOwnProperty('titleIsTemplate') && data.alertSetting.titleIsTemplate">
					<div [innerHTML]="data.title"></div>
				</div>
				<div
					*ngIf="!data.alertSetting.hasOwnProperty('titleIsTemplate') || data.alertSetting.hasOwnProperty('titleIsTemplate') && !data.alertSetting.titleIsTemplate">
					<mat-icon *ngIf="data.alertSetting.icon">{{data.alertSetting.icon}}</mat-icon>
					<span [class.title-with-icon]="data.alertSetting.icon">{{data.title}}</span>
				</div>
			</h3>
		</div>
	</div>
	<div class="kt-form">
		<div class="kt-portlet__body">
			<div class="form-group kt-form__group row">
				<div class="col-lg-12">
					<div
						*ngIf="data.alertSetting.hasOwnProperty('messageIsTemplate') && data.alertSetting.messageIsTemplate">
						<div [innerHTML]="data.description | safeHtml"></div>
					</div>
					<div *ngIf="!data.alertSetting.hasOwnProperty('messageIsTemplate') || data.alertSetting.hasOwnProperty('messageIsTemplate') && !data.alertSetting.messageIsTemplate"
						style="line-height: 25px; font-size: 10pt;word-break: break-all;">
						{{data.description}}
					</div>
				</div>
			</div>
		</div>
		<div class="kt-portlet__foot kt-portlet__no-border kt-portlet__foot--fit text-right" style="margin-top: 15px;">
			<div class="kt-form__actions kt-form__actions--sm">
				<button *ngIf="data.alertSetting.hasOwnProperty('confirmText')" mat-button
					[color]="data.alertSetting?.buttonColor"
					(click)="onYesClick('confirmText', data.alertSetting.confirmText)"
					style="float:right; margin: 2px 2px;"
					[cdkCopyToClipboard]="data.description">{{data.alertSetting.confirmText}}</button>&nbsp;
				<button *ngIf="data.alertSetting.hasOwnProperty('declineText')" mat-button
					(click)="onYesClick('declineText', data.alertSetting.declineText)"
					style="float:right; margin: 2px 2px;">{{data.alertSetting.declineText}}</button>&nbsp;
				<button *ngIf="data.alertSetting.showCancelButton" mat-button (click)="onNoClick()"
					style="float:right; margin: 2px 2px;">{{'Close' | translate }}</button>
			</div>
			<div class="kt-form__actions kt-form__actions--sm" *ngIf="data.alertSetting.smallMsg"
				style="clear: both;font-size: 0.8rem;">
				{{data.alertSetting.smallMsg | translate}}
			</div>
		</div>
	</div>
</div>