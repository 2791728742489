<div class="producer-video-link" [ngStyle]="{ 'z-index': zIndex}" *ngIf="video && video.url">
    <ng-container *ngIf="video.controls && video.player && !isHost">
        <mat-icon (click)="toggleMuteVimeo($event)" [style]="{'color': video.muted ? 'red' : 'lime'}">
            volume_up
        </mat-icon>
        <mat-icon (click)="permitVimeoFullScreen($event)">
            fullscreen
        </mat-icon>
    </ng-container>
    <a target="_blank" *ngIf="video.clickThruUrl && video.player" [href]="video.clickThruUrl">
        <mat-icon>touch_app</mat-icon>
    </a>
</div>
<div [id]="'video-vimeo-' + uuid" class="video-player" [hidden]="!video?.player">
</div>