<div [ngStyle]="{'z-index': _zIndex, 'left': _leftPosition ? _leftPosition + 'px':'', 'display': _hide ? 'none' : 'initial'}"
    cdkDrag #resourcesDraggable class="draggable" (cdkDragStarted)="dragged()" [cdkDragDisabled]="disableDrag"
    [@showDialog]="{ value: animationState, params: { top: isMobile ? '20%' : '7.5%'}}" cdkDragBoundary=".dragBoundary">
    <mat-accordion>
        <mat-expansion-panel [class.grey-out]="true" [expanded]="true" [hideToggle]="true" [disabled]="true">
            <mat-expansion-panel-header cdkDragHandle class="panel-header drag-handle" collapsedHeight="40px"
                expandedHeight="40px">
                <mat-panel-title class="toolbar-label">
                    <mat-icon class="icon-title">library_books</mat-icon>
                    <span>{{'Resources' | translate}}</span>
                </mat-panel-title>
                <!-- <button mat-icon-button class="drag-handle" cdkDragHandle style="margin: 0 5px 0 0">
                    <mat-icon class="toolbar-label">open_with</mat-icon>
                </button> -->
                <button mat-icon-button title="{{'Close' | translate}}" (click)="closeDialog()">
                    <mat-icon class="toolbar-label">close</mat-icon>
                </button>
            </mat-expansion-panel-header>
            <div fxLayout="column" style="margin:10px 5px 5px 5px;" class="resources-container">
                <table mat-table *ngIf="dataList.length" [dataSource]="dataList" class="mat-elevation-z8">
                    <ng-container matColumnDef="icon">
                        <td mat-cell *matCellDef="let element" style="width: 100px; min-width: 100px !important">
                            <div style="max-width:100px; text-align: left; margin: 0px 0px 5px;"
                                fxLayoutAlign="start center"
                                [ngStyle]="{'background-color': element.icon ? '' : '#f2f2f2'}">
                                <img *ngIf="element.icon" [src]="element.icon" style="max-height: 50px;" />
                            </div>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="name">
                        <td mat-cell *matCellDef="let element">
                            <div class="company-name">{{element.type}} </div>
                            <div style="margin: 5px 0px;font-weight: bold;font-size: 11pt;color: #5d5d5d;">
                                {{element.name}} </div>
                            <div class="company-name" style="margin: 0px 0px 10px 0px;">{{element.author}} </div>
                        </td>
                    </ng-container>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;" style="cursor:pointer"
                        (click)="viewData(row)"></tr>
                </table>
                <mat-progress-spinner [diameter]="30" *ngIf="loading" class="mat-spinner-color" color="warn"
                    mode="indeterminate">
                </mat-progress-spinner>
                <div class="mat-table__message"><span *ngIf="!loading && dataList?.length === 0">{{ 'No records found' |
                        translate }}</span>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>