import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-custom-checkbox',
  templateUrl: './custom-checkbox.component.html',
  styleUrls: ['./custom-checkbox.component.scss']
})
export class CustomCheckboxComponent implements OnInit {
  @Input() value: boolean = false;
  @Input() disabled: boolean = false;
  @Input() label: string = '';
  @Input() disabledLabel: string = '';
  @Output() onChangeReturn = new EventEmitter<any>();
  constructor() {
  }
  ngOnInit() { }
  setOptions(e, val) {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.onChangeReturn.emit(val);
  }
}
