import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { urlSafeBase64Encoding } from '../../../shared/helpers';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

interface ViewDialogData {
	title: string;
	data: any;
	modalSetting: any;
	confirmData: any;
}

@Component({
	selector: 'app-custom-poster-view-dialog-modal',
	templateUrl: './custom-poster-view-dialog.component.html',
	styleUrls: ['./custom-poster-view-dialog.component.scss']
})
export class ModalPosterViewDialogComponent implements OnInit {
	public errorMessage: string = '';
	public loading: boolean = false;
	public isMobile: boolean = false;
	public hasFormErrors: boolean = false;
	public dataType: string = 'poster';
	public dataContent: any = undefined;
	public pictureLink: string = '';
	public voted: boolean = false;
	constructor(
		private requestService: RequestService, private translate: TranslateService,
		private layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<ModalPosterViewDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData, public deviceService: DeviceDetectorService) {
		this.isMobile = this.deviceService.isMobile();
		//console.log('ViewDialogData', data);
	}

	ngOnInit() {
		this.loadData();
	}
	public loadData() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.getSingleData(this.dataType, this.data.data['_id'], (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
				}
				if (data) {
					this.data.data = data.results;
					this.dataContent = data.results;
					this.pictureLink = this.data.data['pictureLink'];
				}
				this.loading = false;
			});
		}
	}
	public voteUp() {
		if (!this.loading && !this.data.data.voted) {
			let alertSetting = {};
			alertSetting['overlayClickToClose'] = false;
			alertSetting['titleIsTemplate'] = true;
			alertSetting['overlayClickToClose'] = false;
			alertSetting['confirmText'] = 'Yes';
			alertSetting['declineText'] = 'No';

			const _title: string = this.translate.instant('Vote');
			const _description: string = this.translate.instant('Are you sure you want to vote for this poster?');

			const dialogRef = this.layoutUtilsService.alertActionElement(_title, _description, alertSetting);
			dialogRef.afterClosed().subscribe(res => {
				if (!res) {
					return;
				} else {
					if (res.value === 'Yes') {
						this.continueVoteUp(this.data.data['_id'])
					} else {
						return;
					}
				}
			});
		}
	}
	public continueVoteUp(id) {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.posterVote(id, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error: ') + error, this.translate.instant('Dismiss'));
				}
				if (data) {
					this.data.data['voted'] = true;
					this.voted = true;
					this.layoutUtilsService.showNotification(this.translate.instant('Voted Successfully'), this.translate.instant('Dismiss'));
				}
				this.loading = false;
			});
		}
	}
	openLink(e, url) {
		if (e) {
			e.preventDefault();
			e.stopPropagation();
		}
		if (url) {
			window.open(url, '_blank');
		}
	}
	maximizeIt() {
		if (this.pictureLink) {
			window.open(this.pictureLink, '_blank');
		}
	}
	closeModal(data): void {
		this.dialogRef.close(data);
	}
}
