export class CommonFunctions {
  public static getComponentIndex(list, variableName, value, subIndex){
    let idx = 1;
    let subIdx = 0;
    for(let cmp of list){
      if(cmp[variableName] === value){
        if(subIdx === subIndex){
          break;
        }
        idx++;
      }
      subIdx++;
    }
    return idx;
  }
}
