<div class="mainTableAgenda" [class.mainTableAgendaMobile]="isMobile">
  <div *ngIf="dataSource && dataSource.length > 0">
    <div class="agenda-table-container" *ngFor="let ky of dataSourceKeys">
      <ng-container *ngIf="dataSourceKeysValue[ky] && dataSourceKeysValue[ky].length > 0">
        <div class="tableTitle">{{dataSourceKeysValue[ky][0].startsAt | date: 'EEEE - MMM dd, yyyy'}}</div>
        <table mat-table [dataSource]="dataSourceKeysValue[ky]" matSort class="mat-elevation-z8"
          (matSortChange)="applySort($event)" [matSortActive]="orderBy" [matSortDirection]="orderDir">
          <ng-container matColumnDef="favorite">
            <th mat-header-cell *matHeaderCellDef> Favs </th>
            <td mat-cell *matCellDef="let element;let index = index" class="fave defaultTableFont">
              <img (click)="setFavorite($event, true, element._id)" src="/assets/images/unstar.png"
                *ngIf="!element.favorite" style="max-width: 125px; max-height: 32px;cursor:pointer;"
                title="{{'Add to Favorites' | translate}}" />
              <img (click)="setFavorite($event, false, element._id)" src="/assets/images/star.png"
                *ngIf="element.favorite" style="max-width: 125px; max-height: 32px;cursor:pointer;"
                title="{{'Remove from Favorites' | translate}}" />
            </td>
          </ng-container>
          <ng-container matColumnDef="startsAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Time </th>
            <td mat-cell *matCellDef="let element; let index = index" class="time defaultTableFont">
              <!-- <div class="company-name">{{element.startsAt | date: 'EEEE - MMM dd, yyyy'}}<span
                *ngIf="!element.sameDate"> - {{element.endAt | date: 'EEEE - MMM dd, yyyy'}}</span></div> -->
              <div>
                {{element.startsAt | date: 'hh:mm aa'}} - {{element.endAt | date: 'hh:mm aa'}}</div>
            </td>
          </ng-container>
          <ng-container matColumnDef="active">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element; let index = index" class="status">
              <img *ngIf="element.showLive && (type === 'current' || type === 'live')"
                src="/assets/images/StellarLive.png" style="max-width: 125px; max-height: 30px;"
                title="{{'Live Now' | translate}}" />
              <img *ngIf="element.active && (type !== 'current' && type !== 'live')"
                src="/assets/images/StellarLive.png" style="max-width: 125px; max-height: 30px;"
                title="{{'Live Now' | translate}}" />
              <img *ngIf="(type !== 'current' && type !== 'live') && !element.active && element.recordAvailable"
                src="/assets/images/StellarReplay.png" style="max-width: 125px; max-height: 30px;"
                title="{{'Watch Again' | translate}}" />
            </td>
          </ng-container>
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Title </th>
            <td mat-cell *matCellDef="let element; let index = index" class="title">
              <div class="company-name">{{element.typeName}} </div>
              <div class="defaultTableFont">{{element.name}}
              </div>
              <!-- <div class="company-name" style="margin: 0px 0px 10px 0px;">
              <span *ngFor="let user of element['specificusers'];let index = index"><span *ngIf="index > 0">,
                </span>{{user.name}}</span>
            </div> -->
              <div class="company-name" style="margin: 0px 0px 10px 0px;font-size:14px;font-style: italic;">
                <!-- <span *ngIf="element['tags'].length > 0" style="font-weight:bold">Categories: </span> -->
                <span *ngFor="let tg of element['tags'];let index = index;"><span *ngIf="index > 0">,
                  </span>{{tg.name}}</span>
                <!-- <mat-chip-list aria-label="Categories">
                <mat-chip *ngFor="let tg of element['tags']">{{tg.name}}</mat-chip>
              </mat-chip-list> -->
              </div>
              <div class="company-name moreBtn" *ngIf="element.description">
                <div *ngIf="(showMore || element.showMore)" class="descriptionList"
                  [innerHTML]="convertToList(element.description)"></div>
                <div class="showMore" [hidden]="showMore"
                  (click)="$event.stopImmediatePropagation();$event.preventDefault();element.showMore = !element.showMore">
                  Show
                  <span>{{element.showMore ? 'less' : 'More'}}</span>
                </div>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="specificusers">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> {{'Leads' | translate}} /
              <span style="font-style:italic;margin-left:5px;"> {{'Guests' | translate}}</span>
            </th>
            <td mat-cell *matCellDef="let element; let index = index" class="speaker">
              <div class="defaultTableFont">
                <span *ngFor="let user of element['specificusersLeads'];let index = index"
                  (click)="viewProfile($event, user)" title="{{'Show Profile' | translate}}"><span *ngIf="index > 0">,
                  </span>{{user.name}}</span>
              </div>
              <div class="defaultTableFont">
                <span *ngFor="let user of element['specificusersGuests'];let index = index"
                  (click)="viewProfile($event, user)" title="{{'Show Profile' | translate}}"
                  style="font-style:italic"><span *ngIf="index > 0">,
                  </span>{{user.name}}</span>
              </div>
            </td>
          </ng-container>
          <ng-container matColumnDef="room">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Room </th>
            <td mat-cell *matCellDef="let element; let index = index" class="room defaultTableFont">
              {{element.room.name}}
            </td>
          </ng-container>
          <ng-container matColumnDef="resources">
            <th mat-header-cell *matHeaderCellDef> Res. </th>
            <td mat-cell *matCellDef="let element;let index = index" class="resources">
              <button *ngIf="element.resources && element.resources.length > 0" mat-icon-button
                (click)="showResources($event, element.resources, element)" title="{{'Show Resources' | translate}}"
                disableRipple="true" style="position: static;">
                <mat-icon style="color:#151a3f; height: 30px;vertical-align: middle;cursor:pointer">library_books
                </mat-icon>
              </button>
            </td>
          </ng-container>
          <ng-container matColumnDef="calender">
            <th mat-header-cell *matHeaderCellDef> Cal. </th>
            <td mat-cell *matCellDef="let element;let index = index" class="calender">
              <button mat-icon-button [matMenuTriggerFor]="menuCalender" #menuTrigger="matMenuTrigger"
                (click)="cancelThru($event)" title="{{'Add to Calendar' | translate}}" disableRipple="true"
                style="position: static;">
                <mat-icon style="color:#151a3f; height: 30px;vertical-align: middle;cursor:pointer">today</mat-icon>
              </button>
              <mat-menu #menuCalender="matMenu">
                <button mat-menu-item (click)="addtoMyCalender($event, element, 'download', menuTrigger)"
                  class="iconCalender">
                  <img src="/assets/images/icon/microsoft-outlook.png" />
                  <span>{{'Outlook' | translate}}</span>
                </button>
                <button mat-menu-item (click)="addtoMyCalender($event, element, 'outlook', menuTrigger)"
                  class="iconCalender">
                  <img src="/assets/images/icon/icon-outlookcom-t5.svg" />
                  <span>{{'Outlook Web' | translate}}</span>
                </button>
                <button mat-menu-item (click)="addtoMyCalender($event, element, 'google', menuTrigger)"
                  class="iconCalender">
                  <img src="/assets/images/icon/icon-google-t5.svg" />
                  <span>{{'Google' | translate}}</span>
                </button>
                <button mat-menu-item (click)="addtoMyCalender($event, element, 'yahoo', menuTrigger)"
                  class="iconCalender">
                  <img src="/assets/images/icon/icon-yahoo-t5.svg" />
                  <span>{{'Yahoo!' | translate}}</span>
                </button>
                <button mat-menu-item (click)="addtoMyCalender($event, element, 'download', menuTrigger)"
                  class="iconCalender">
                  <img src="/assets/images/icon/icon-apple-t5.svg" />
                  <span>{{'Apple' | translate}}</span>
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;let index=index;" style="cursor:pointer;"
            title="{{'Proceed to room' | translate}}" [class.colorF]="index%2===1" [class.colorS]="index%2===0"
            (click)="viewData($event, row)" [ngStyle]="{'background-color':row.room.color}"></tr>
        </table>
      </ng-container>
    </div>
    <!-- <mat-progress-spinner [diameter]="30" *ngIf="loading" class="mat-spinner-color" color="warn" mode="indeterminate">
  </mat-progress-spinner> -->
  </div>
  <div class="mat-table__message noDataFound"><span *ngIf="!loading && dataSource && dataSource.length === 0">{{ 'No
      sessions scheduled' | translate }}</span>
  </div>
</div>