<div class="playlist" *ngIf="playlist?.length">
    <div class="song-list">
        <mat-nav-list>
            <ng-container *ngFor="let song of playlist">
                <mat-list-item *ngIf="song.playlistid === 34" style="cursor: default;" [title]="song.title">
                    <div matLine class="ad">
                        {{song.title}}
                    </div>
                    <div mat-line *ngIf="song.artist" class="artist-name">
                        {{song.artist}}
                    </div>
                    <div class="time" *ngIf="song.time">{{song.time}}</div>
                </mat-list-item>
                <mat-list-item *ngIf="song.playlistid !== 34" (click)="openDialog(song)" [title]="song.title"
                    [class.selected]="song.playlistid === selectedSong?.songId">
                    <img mat-list-icon [src]="song.logo" class="song-logo" />
                    <div matLine class="title">
                        {{song.title}}
                    </div>
                    <div mat-line *ngIf="song.artist" class="artist-name">
                        {{song.artist}}
                    </div>
                    <div class="time" *ngIf="song.time">{{song.time}}</div>
                </mat-list-item>
            </ng-container>
        </mat-nav-list>
    </div>
</div>