<form #personForm="ngForm" *ngIf="metaFieldSetting && data" class="layoutDialog">
	<div class="col-xl-12" *ngIf="data.data">
		<div *ngIf="data.modalSetting">
			<div *ngIf="data.modalSetting.fields">
				<div class="wallsmenu" *ngIf="!backLayer && data.roomData && data.roomData._id && data.roomData.is3rd">
					<button mat-icon-button color="primary" class="west" (click)="goLeft()"
						matTooltip="{{'Go to left' | translate}}">
						<mat-icon>west</mat-icon>
					</button>
					<button mat-icon-button color="primary" class="east" (click)="goRight()"
						matTooltip="{{'Go to right' | translate}}">
						<mat-icon>east</mat-icon>
					</button>
				</div>
				<div class="rightmenu">
					<ng-container
						*ngIf="viewPortMode!== 'mobile' && selectedColumn !== undefined && selectedComponent !== undefined">
						<mat-card class="rightmenuBox" [hidden]="openedRightMenu !== 'componentSettings'">
							<mat-card-title class="headIcon">
								<mat-icon class="actions" *ngIf="rightComponentMenu"
									(click)="rightComponentMenu = !rightComponentMenu">horizontal_rule</mat-icon>
								<mat-icon class="actions" *ngIf="!rightComponentMenu"
									(click)="rightComponentMenu = !rightComponentMenu">web_asset</mat-icon>
							</mat-card-title>
							<div fxLayout="row" fxLayout.lt-md="column" fxFlex>
								<div fxFlex>
									<div class="rootcomp-toolbar">
										<div class="header-seperator"></div>
										<div [hidden]="!rightComponentMenu">
											<div>
												<!-- <div class="inner button-grid">
														<span class="button">
															<svg width="13" height="15" viewBox="0 0 13 15" class="symbol symbol-toolbarCopy">
																<path fill-rule="evenodd" d="M11.08 12h-6C3.97 12 3 11.1 3 9.99v-8C3 .89 3.97 0 5.08 0h6C12.18 0 13 .89 13 1.99v8c0 1.1-.82 2.01-1.92 2.01zM11 2H5v1h6V2zm0 2H5v1h6V4zm-1 9v2H3.08C1.42 15 0 13.65 0 11.99V4h2v7.99c0 .55.53 1.01 1.08 1.01H10z"></path>
															</svg>
														</span>
														<span class="button">
																<svg width="14" height="15" viewBox="0 0 14 15" class="symbol symbol-toolbarPaste">
																<path fill-rule="evenodd" d="M11.58 15h-5c-1.11 0-2.08-.9-2.08-2.01v-3C4.5 8.89 5.47 8 6.58 8h5c1.1 0 1.92.89 1.92 1.99v3c0 1.11-.82 2.01-1.92 2.01zm-.08-5h-5v1h5v-1zm0 2h-5v1h5v-1zm-8-3.01V14H1.58C.47 14-.5 13.1-.5 11.99v-9C-.5 1.89.48 1 1.58 1h.92V.49c0-.27.3-.49.58-.49h4c.27 0 .42.22.42.49V1h1.08c1.1 0 1.92.89 1.92 1.99V7H5.58C4.47 7 3.5 7.89 3.5 8.99zM6.5 1h-3v1h3V1z"></path>
															</svg>
														</span>
														<span class="button">
															<svg width="13" height="15" viewBox="0 0 13 15" class="symbol symbol-toolbarDuplicate">
																<path fill-rule="evenodd" d="M11 12H5c-1.1 0-2-.9-2-2V2c0-1.1.9-2 2-2h4l4 4v6c0 1.1-.9 2-2 2zm0-7H8V2H5v8h6V5zm-1 8v2H3c-1.65 0-3-1.35-3-3V4h2v8c0 .55.45 1 1 1h7z"></path>
															</svg>
														</span>
														<span class="button">
															<svg width="13" height="15" viewBox="0 0 13 15" class="symbol symbol-toolbarDelete"><path fill-rule="evenodd" d="M12.02 2H8.98V0h-5v2H1.02C.47 2 0 2.44 0 3c0 .55.98.99.98.99v8C.98 13.65 2.36 15 4.02 15h5c1.66 0 2.96-1.35 2.96-3.01v-8c1 0 1.02-.44 1.02-.99 0-.56-.43-1-.98-1zM4.98 12h-1V5h1v7zm4 0h-1V5h1v7zm-4-11h3v1h-3V1z"></path>
															</svg>
														</span>
													</div>
													<div class="seperator"></div>
													<div class="inner button-grid">
														<div class="tool-selector" style="position: relative;">
															<span class="button">
																<svg width="14" height="14" viewBox="0 0 14 14" class="symbol symbol-toolbarMoveForward"><path fill-rule="evenodd" d="M11.58 2.5H8.5V1.49C8.5.39 7.68-.5 6.58-.5h-5C.47-.5-.5.39-.5 1.49v5C-.5 7.6.47 8.5 1.58 8.5h.92v2.99c0 1.11.97 2.01 2.08 2.01h7c1.1 0 1.92-.9 1.92-2.01v-7c0-1.1-.82-1.99-1.92-1.99zm-7 0c-1.11 0-2.08.89-2.08 1.99V6.5h-1v-5h5v1H4.58z"></path>
																</svg>
															</span>
															<div class="small-indicator"></div>
														</div> -->
												<!-- <div class="tool-selector" style="position: relative;">
															<span class="button">
																<svg width="15" height="15" viewBox="0 0 15 15" class="symbol symbol-toolbarAlignRight"><path fill-rule="evenodd" d="M13 15V0h2v15h-2zm-3.01-1H2.15C1 14 0 13.19 0 12.19V9.81C0 8.81 1 8 2.15 8h7.84C11.14 8 12 8.81 12 9.81v2.38c0 1-.86 1.81-2.01 1.81zM10 7H5c-1.1 0-2-.9-2-2V3c0-1.1.9-2 2-2h5c1.1 0 2 .9 2 2v2c0 1.1-.9 2-2 2z"></path>
																</svg>
															</span>
															<div class="small-indicator"></div>
														</div>
														<div class="tool-selector disabled" style="position: relative;">
															<span class="button disabled">
																<svg width="34" height="34" viewBox="0 0 34 34" class="symbol symbol-distribute-vertical-btn-bld"><path d="M20 20h-5a2 2 0 0 0 0 4h5a2 2 0 0 0 0-4zm-5-6h5a2 2 0 0 0 0-4h-5a2 2 0 0 0 0 4zm9 1H11a2 2 0 0 0 0 4h13a2 2 0 0 0 0-4z" class="st1"></path>
																</svg>
															</span>
															<div class="small-indicator"></div>
														</div> -->
												<!-- <div class="tool-selector disabled" style="position: relative;">
															<span class="button disabled">
																<svg width="34" height="34" viewBox="0 0 34 34" class="symbol symbol-mech-to-height-btn-bld"><path d="M15 24h-2a2 2 0 0 1-2-2V12a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2zm3-14h6v2h-6v-2zm0 12h6v2h-6v-2z" class="st1"></path><path d="M24 19a2 2 0 0 1-2 2h-2a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v4" class="st2"></path>
																</svg>
															</span>
															<div class="small-indicator"></div>
														</div> -->
												<!-- </div> -->
											</div>
											<!-- <div class="seperator"></div>
												<div class="inner form">
													<label data-input-title="input_rotation" class="row">
														<span class="disabled">
															<svg width="14" height="12" viewBox="0 0 14 12" class="symbol symbol-toolbarRotation">
																<path fill-rule="evenodd" d="M9.59 11.64c.18-1.37-.26-3.05-1-4.22-.63-1-1.52-1.76-2.55-2.24L9 .93 8.18.36-.37 12.64h13.74v-1H9.59zm-4-6c.92.4 1.6 1.43 2.15 2.31.62.98.88 2.15.73 3.3-.01.06-.06.23-.1.39H1.54l4.05-6z"></path>
															</svg>
														</span>
														<div class="input-stepper disabled has-units small degrees">
															<input type="text" readonly="" maxlength="8" step="1" class="input" value="0" disabled="">
															<span class="units-container">
																<span class="units">°</span>
															</span>
														</div>
													</label>
												</div>
												<div class="inner button-grid">
													<span class="button disabled">
														<svg width="33" height="33" viewBox="0 0 33 33" class="symbol symbol-flip-horizontal symbol symbol-symbol-flip-horizontal"><path d="M16 23V9h2v14h-2zm-5.993-12c.246 0 .497.098.7.316L15 16l-4.293 4.684a.95.95 0 0 1-.7.316C9.493 21 9 20.571 9 19.924v-7.848C9 11.429 9.493 11 10.007 11zm.493 2.311v5.378L12.965 16 10.5 13.311zM19 16l3.823-4.483c.679-.973 2.177-.481 2.177.714v7.538c0 1.195-1.498 1.687-2.177.714L19 16z" class="st1"></path>
														</svg>
													</span>
													<span class="button disabled">
														<svg width="33" height="33" viewBox="0 0 33 33" class="symbol symbol-flip-vertical symbol symbol-symbol-flip-vertical"><path d="M10 17v-2h14v2H10zm7 1l4.684 4.293c.675.63.197 1.707-.76 1.707h-7.848c-.957 0-1.435-1.077-.76-1.707L17 18zm0 2.035L14.311 22.5h5.378L17 20.035zM17 14l-4.483-3.823C11.544 9.498 12.036 8 13.231 8h7.538c1.195 0 1.687 1.498.714 2.177L17 14z" class="st1"></path>
														</svg>
													</span>
												</div> -->
											<div class="seperator"></div>
											<div class="inner form">
												<span class="layout-title">Size (V)</span>
												<label class="row" matTooltip="Width" matTooltipPosition="left"
													matTooltipClass="tooltip-red">
													<span class="label">W:</span>
													<div class="input-stepper small"
														[class.disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)">
														<input type="number"
															[disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)"
															maxlength="5" data-aid="width-stepper" step="1"
															class="input" [value]="liveXY['w']"
															(input)="selectedComponentChange('w', $event.target.value, true)">
													</div>
												</label>
												<label class="row" matTooltip="Height" matTooltipPosition="left"
													matTooltipClass="tooltip-red">
													<span class="label">H:</span>
													<div class="input-stepper small"
														[class.disabled]="(liveXY.name !== '' && componentsName[liveXY.name].rules.ratioSize) || !(selectedColumn !== undefined && selectedComponent !== undefined)">
														<input type="number"
															[disabled]="(liveXY.name !== '' && componentsName[liveXY.name].rules.ratioSize) || !(selectedColumn !== undefined && selectedComponent !== undefined)"
															maxlength="5" data-aid="height-stepper" step="1"
															class="input" [value]="liveXY['h']"
															(input)="selectedComponentChange('h', $event.target.value, true)">
													</div>
												</label>
											</div>
											<div class="seperator"></div>
											<div class="inner form">
												<span class="layout-title">Position</span>
												<label data-input-title="input_x" class="row input_x"
													matTooltip="X Position" matTooltipPosition="left"
													matTooltipClass="tooltip-red">
													<span class="input_x_label label">X:</span>
													<div class="input-stepper small"
														[class.disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)">
														<input type="number" maxlength="5" step="1" class="input"
															value="0"
															[disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)"
															[value]="liveXY['x']"
															(input)="selectedComponentChange('x', $event.target.value, true)">
													</div>
												</label>
												<label data-input-title="input_y" class="row" matTooltip="Y Position"
													matTooltipPosition="left" matTooltipClass="tooltip-red">
													<span class="label">Y:</span>
													<div class="input-stepper small"
														[class.disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)">
														<input type="number"
															[disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)"
															maxlength="5" step="1" class="input" [value]="liveXY['y']"
															(input)="selectedComponentChange('y', $event.target.value, true)">
													</div>
												</label>
												<label data-input-title="input_z" class="row" matTooltip="Z Position"
													matTooltipPosition="left" matTooltipClass="tooltip-red">
													<span class="label">Z:</span>
													<div class="input-stepper small"
														[class.disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)">
														<input type="number"
															[disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)"
															maxlength="2" step="1" class="input" [value]="liveXY['z']"
															min="0" maxs="100"
															(input)="selectedComponentChange('z', $event.target.value, true)">
													</div>
												</label>
												<label data-input-title="input_c" class="row"
													matTooltip="Center Position" matTooltipPosition="left"
													matTooltipClass="tooltip-red">
													<span class="label">C:</span>
													<div class="input-stepper small" [class.disabled]="true">
														<input type="text" [disabled]="true" maxlength="5" step="1"
															class="input" [value]="liveXY['c']">
													</div>
												</label>
												<label data-input-title="input_t" class="row"
													[matTooltip]="'Transparency' | translate" matTooltipPosition="left"
													matTooltipClass="tooltip-red">
													<span class="label">T:</span>
													<div class="input-stepper small input-with-unit"
														[class.disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)">
														<input type="number"
															[disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)"
															maxlength="3" step="1" min="10" max="100" class="input"
															[value]="liveXY['t'] >= 0 ? liveXY['t']:100"
															(input)="selectedComponentChange('t', $event.target.value, true)">
														<span>%</span>
													</div>
												</label>
												<div class="seperator"></div>
												<div class="inner form">
													<span class="layout-title">{{'Rotation' | translate}}</span>
													<label data-input-title="input_rotate" class="row"
														[matTooltip]="'between -180 and 180' | translate"
														matTooltipPosition="left" matTooltipClass="tooltip-red">
														<span class="label">X:</span>
														<div class="input-stepper small input-with-unit"
															[class.disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)">
															<input type="number"
																[disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)"
																maxlength="3" step="1" min="-180" max="180"
																class="input"
																[value]="liveXY['rx'] !== undefined ? liveXY['rx'] : 0"
																(input)="selectedComponentChange('rx', $event.target.value, true, $event)">
															<span>&deg;</span>
														</div>
													</label>
													<label data-input-title="input_rotate" class="row"
														[matTooltip]="'between -180 and 180' | translate"
														matTooltipPosition="left" matTooltipClass="tooltip-red">
														<span class="label">Y:</span>
														<div class="input-stepper small input-with-unit"
															[class.disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)">
															<input type="number"
																[disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)"
																maxlength="3" step="1" min="-180" max="180"
																class="input"
																[value]="liveXY['ry'] !== undefined ? liveXY['ry'] : 0"
																(input)="selectedComponentChange('ry', $event.target.value, true, $event)">
															<span>&deg;</span>
														</div>
													</label>
													<label data-input-title="input_rotate" class="row"
														[matTooltip]="'between -180 and 180' | translate"
														matTooltipPosition="left" matTooltipClass="tooltip-red">
														<span class="label">Z:</span>
														<div class="input-stepper small input-with-unit"
															[class.disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)">
															<input type="number"
																[disabled]="!(selectedColumn !== undefined && selectedComponent !== undefined)"
																maxlength="3" step="1" min="-180" max="180"
																class="input"
																[value]="liveXY['rz'] !== undefined ? liveXY['rz'] : 0"
																(input)="selectedComponentChange('rz', $event.target.value, true, $event);">
															<span>&deg;</span>
														</div>
													</label>
												</div>
												<!-- <label data-input-title="input_m" class="row" matTooltip="Middle Position"  matTooltipPosition="left" matTooltipClass="tooltip-red">
														<span class="label">M:</span>
														<div class="input-stepper small" [class.disabled]="true">
															<input type="text" [disabled]="true" maxlength="2" step="1" class="input" [value]="liveXY['m']">
														</div>
													</label> -->
											</div>
											<div class="seperator"></div>
											<div class="inner form" *ngIf="liveXY.name === 'editor'">
												<!-- <span class="layout-title">BG Color</span> -->
												<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="3px">
													<div fxFlex style="text-align: left;">
														<mat-icon *ngIf="liveXY['bgcolor']"
															(click)="selectedComponentChange('bgcolor', '')"
															matTooltip="Clear Color" matTooltipClass="tooltip-red">
															invert_colors</mat-icon>
														<mat-icon *ngIf="!liveXY['bgcolor'] || liveXY['bgcolor'] === ''"
															(click)="selectedComponentChange('bgcolor', '#efefef')"
															matTooltip="Enable Color" matTooltipClass="tooltip-red">
															invert_colors_off</mat-icon>
													</div>
													<div fxFlex style="text-align: center;">
														<input matInput
															[disabled]="!liveXY['bgcolor'] || liveXY['bgcolor'] === ''"
															type="color" name="bgcolor" [(value)]="liveXY['bgcolor']"
															style="width: 25px"
															(change)="selectedComponentChange('bgcolor', $event.target.value)"
															matTooltip="Select Background"
															matTooltipClass="tooltip-red">
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</mat-card>
					</ng-container>
					<ng-container>
						<mat-card class="rightmenuTemplatesBox" [hidden]="openedRightTemplateMenu !== 'tempalatesList'"
							(click)="cancelThru($event)" clickOutside (clickOutside)="openedRightTemplateMenu = ''">
							<mat-card-title>
								{{'Templates' | translate}}
								<mat-icon class="actions" (click)="openedRightTemplateMenu='';">cancel</mat-icon>
							</mat-card-title>
							<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
								<div fxFlex style="min-height: 50vh;height: calc(100vh - 115px);overflow-y: scroll;">
									<mat-card *ngFor="let tmp of templateList;let indexT = index"
										(click)="loadTemplateSetting(tmp._id)"
										style="cursor:pointer;margin-bottom:10px">
										<mat-card-title style="display: flex;">
											{{tmp.name}}
											<mat-icon class="actions" (click)="deleteTemplate($event,indexT, tmp._id)">
												delete</mat-icon>
										</mat-card-title>
										<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px"
											*ngIf="tmp.settings && tmp.settings.desktop">
											<!-- <div fxFlex style="min-height: 100px;
    border: 1px solid #ecebeb;overflow: hidden;border-radius: 2px;"
												[ngStyle]="{'background-color': tmp.bgcolor ? tmp.bgcolor : '#f2f2f2'}">
												<img style="margin: 0 -16px -3px -16px;" mat-card-image
													[src]="tmp.bgImage">
											</div> -->
											<div fxFlex style="height: 100px;width: 100px;
    border: 1px solid #ecebeb;overflow: hidden;border-radius: 1px;">
												<app-screen-layout-preview [settingObject]="tmp.settings.desktop">
												</app-screen-layout-preview>
											</div>
										</div>
									</mat-card>
								</div>
							</div>
						</mat-card>
					</ng-container>
				</div>
				<div class="leftmenu">
					<mat-menu [overlapTrigger]="false" class="leftmenuBox" #pagesPop="matMenu" xPosition="after">
						<div class="container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()"
							tabindex="-1">
							<div class="title">
								<div class="header">{{'Select another session to link to current session' | translate}}
								</div>
								<mat-icon class="actions" (click)="triggerButtun('pagesPopClick')">cancel</mat-icon>
							</div>
							<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" class="bodyData">
								<div fxFlex>
									<mat-divider></mat-divider>
									<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
										<div fxFlex>
											<div class="leftmenuBoxBody">
												<mat-list role="list" class="maxHeight">
													<ng-container *ngFor="let session of sessionList">
														<mat-list-item role="listitem"
															*ngIf="data.data._id !== session._id">
															<div fxLayout="row" fxLayout.lt-md="column" fxFlex
																fxLayoutGap="20px"
																(click)="applyChain($event, session)">
																<div fxFlex class="sessionItem"
																	[class.selectedSession]="data.data._id === session._id"
																	title="{{session.name}}">
																	<mat-icon class="chainedIcon"
																		title="Unlink from selected session"
																		*ngIf="data.data._id !== session._id && session._id === sessionChained">
																		link</mat-icon>
																	<mat-icon class="unchainedIcon"
																		title="Link to selected session"
																		*ngIf="data.data._id !== session._id && session._id !== sessionChained">
																		link_off</mat-icon>
																	<!-- <mat-icon class="mainChainIcon" title="Linked to selected session when selected session ends" *ngIf="data.data._id === session._id && !sessionChained">nat</mat-icon>
																				<mat-icon class="mainChainIconColored" title="Linked to selected session when selected session ends" *ngIf="data.data._id === session._id && sessionChained" >nat</mat-icon> -->
																	{{session.name | truncate : ['50','...']}}
																</div>
																<!-- <div fxFlex="10" align="right">
																				<button mat-icon-button [matMenuTriggerFor]="menu" #menuTrigger="matMenuTrigger"><mat-icon title="More Actions">more_vert</mat-icon></button>
															          <mat-menu #menu="matMenu" [overlapTrigger]="true">
															            <button mat-menu-item (click)="editSession(session._id)" >{{'Properties' | translate}}</button>
															            <button mat-menu-item (click)="editSessionSetting($event,session._id,menuTrigger)" >{{'Streaming Mode' | translate}}</button>
															            <button mat-menu-item *ngIf="data.data._id !== session._id && sessionChained !== session._id" (click)="applyChain($event, session)" >{{'Link to current session' | translate}}</button>
															            <button mat-menu-item *ngIf="data.data._id !== session._id && sessionChained === session._id" (click)="applyChain($event, session)" >{{'Unlink to current session' | translate}}</button>
															            <button mat-menu-item *ngIf="data.data._id === session._id && sessionChained" (click)="applyChain($event, session)" >{{'Remove link' | translate}}</button>
															            <button mat-menu-item (click)="deleteSession(session._id)" >{{'Delete' | translate}}</button>
															          </mat-menu>
																			</div> -->
															</div>
														</mat-list-item>
													</ng-container>
												</mat-list>
											</div>
											<!-- <div style="display: flex;margin-top: 10px;justify-content: flex-end;">
															<button mat-raised-button (click)="createSession()">Add {{'Session' | translate}}</button>
														</div> -->
										</div>
									</div>
								</div>
							</div>
						</div>
					</mat-menu>
					<mat-menu [overlapTrigger]="false" class="leftmenuBox" #pagesPopStreamMode="matMenu"
						xPosition="after">
						<div class="container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()"
							tabindex="-1">
							<div class="title">
								<div class="header">{{'Select Stream Mode' | translate}}</div>
								<mat-icon class="actions" (click)="triggerButtun('pagesPopStreamModeClick')">cancel
								</mat-icon>
							</div>
							<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" class="bodyData">
								<div fxFlex>
									<mat-divider></mat-divider>
									<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
										<div fxFlex>
											<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
												<div fxFlex>
													<div class="leftmenuBoxBody" style="padding-top: 15px;">
														<div class="fieldInput" style="width:100%">
															<mat-form-field style="width:100%">
																<mat-label>
																	{{metaFieldSetting['streamMode']['displayName'] |
																	translate}}</mat-label>
																<mat-select ng-model="col.name"
																	(selectionChange)="setStreamMode($event.value)"
																	[value]="data.data['streamMode']" style="color: rgba(0,0,0,.54);
																					margin-top: 8px;">
																	<mat-option value="" disabled>Select
																		{{metaFieldSetting['streamMode']['displayName']
																		| translate}}</mat-option>
																	<ng-container
																		*ngFor="let itm of metaFieldSetting['streamMode'].enum">
																		<ng-container
																			*ngIf="itm.value !== 'rtctohls' && itm.value !== 'largemeeting'">
																			<mat-option [value]="itm.value"
																				[disabled]="itm.value === 'cloudlive' && subscriptionOrgObject && !subscriptionOrgObject.features.includes('cloudlive-streaming')"
																				matTooltipClass="tooltip-red"
																				matTooltip="{{itm.value === 'cloudlive' &&subscriptionOrgObject && !subscriptionOrgObject.features.includes('cloudlive-streaming') ? subscriptionUpgradeStatus : ''}}">
																				{{itm.displayName | translate}}
																			</mat-option>
																		</ng-container>
																	</ng-container>
																</mat-select>
															</mat-form-field>
															<div style="color: rgba(0,0,0,.54);font-size: 12px;">
																<span
																	*ngIf="data.data['streamMode'] === 'hls'">Unlimited
																	number of attendees</span>
																<span
																	*ngIf="(data.data['streamMode'] === 'rtc' || data.data['streamMode'] === 'meeting') && data.data['maximumAttendees'] > -1">
																	Maximum number of attendees is
																	{{data.data['maximumAttendees']}}</span>
																<!-- <span
																	*ngIf="data.data['streamMode'] === 'rtctohls' && data.data['maximumAttendees'] > -1">
																	First {{data.data['maximumAttendees']}} attendees
																	will view in RTC, the rest in HLS</span> -->
															</div>
														</div>
													</div>
												</div>
											</div>
											<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px"
												*ngIf="settingObject && optionDesktopSettingsStatus['numberOfAttendees'] && data.data['streamMode'] === 'largemeeting' && settingObject['desktop'] && settingObject['desktop']['optionSettings']">
												<div fxFlex>
													<div class="fieldInput" style="width:100%">
														<mat-form-field style="width: 100%;">
															<input matInput type="number" min="0"
																placeholder="{{ 'Expected number of attendees' | translate }}"
																name="numberOfAttendees" ng-model="numberOfAttendees"
																[(ngModel)]="settingObject['desktop']['optionSettings']['numberOfAttendees']"
																(change)="setNumericOptions($event, 'numberOfAttendees', $event.target.value, 0, undefined)">
														</mat-form-field>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</mat-menu>
					<mat-menu [overlapTrigger]="false" class="leftmenuBox" #componentPop="matMenu"
						[xPosition]="'after'">
						<div class="container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()"
							tabindex="-1">
							<div class="title">
								<div class="header">
									{{'Add Components' | translate}}<br>
									<span class="note" *ngIf="backLayer">Components added at the room level will appear
										in all sessions</span>
								</div>
								<mat-icon class="actions" (click)="triggerButtun('componentPopClick')">cancel</mat-icon>
							</div>
							<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" class="bodyData">
								<div fxFlex>
									<mat-divider></mat-divider>
									<div class="leftmenuBoxBody">
										<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px"
											class="subBodyData">
											<div fxFlex *ngIf="!backLayer">
												<ng-container *ngFor="let cmp of componentsListType[viewMode]">
													<div class="example-box-horizontal"
														*ngIf="!emptyComponents.includes(cmp.name) && !cameraComponents.includes(cmp.name) && !videoComponents.includes(cmp.name)"
														[class.selectedComponent]="tempSelectedComponent && tempSelectedComponent.name === cmp.name"
														(click)="triggerButtun('componentPopClick');insertComponent(cmp)">
														{{componentsName[cmp.name]['displayName'] | translate}}
													</div>
												</ng-container>
											</div>
											<div fxFlex *ngIf="backLayer">
												<ng-container *ngFor="let cmp of componentsRoomListType[viewRoomMode]">
													<div class="example-box-horizontal"
														*ngIf="!emptyComponents.includes(cmp.name) && !cameraComponents.includes(cmp.name) && !videoComponents.includes(cmp.name)"
														[class.selectedComponent]="tempSelectedComponent && tempSelectedComponent.name === cmp.name"
														(click)="triggerButtun('componentPopClick');insertComponent(cmp)">
														{{componentsName[cmp.name]['displayName'] | translate}}
													</div>
												</ng-container>
											</div>
										</div>
										<!-- <mat-divider ></mat-divider>
												<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" style="margin:20px 0px 10px 0px">
													<div fxFlex >
														Add Advanced Components
													</div>
												</div>
												<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" >
													<div fxFlex >
														<ng-container *ngFor="let cmp of componentsListType[viewMode]">
																<div class="example-box-horizontal" *ngIf="advancedComponents.includes(cmp.name)" [class.selectedComponent]="tempSelectedComponent && tempSelectedComponent.name === cmp.name"  (click)="triggerButtun('componentPopClick');insertComponent(cmp)">
																	{{componentsName[cmp.name]['displayName'] | translate}}
																</div>
														</ng-container>
													</div>
												</div> -->
									</div>
								</div>
							</div>
						</div>
					</mat-menu>
					<mat-menu [overlapTrigger]="false" class="leftmenuBox" #componentsAddedPop="matMenu"
						[xPosition]="'after'">
						<div class="container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()"
							tabindex="-1">
							<div class="title">
								<div class="header">{{'Inserted Components' | translate}}</div>
								<mat-icon class="actions" (click)="triggerButtun('componentsAddedPopClick')">cancel
								</mat-icon>
							</div>
							<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" class="bodyData">
								<div fxFlex>
									<mat-divider></mat-divider>
									<div class="leftmenuBoxBody">
										<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px"
											*ngIf="(settingRoomObject && viewRoomMode === 'room-offline') || (settingObject && (viewMode==='desktop' || viewMode==='desktop-2' || viewMode==='desktop-3' || viewMode==='desktop-4'))"
											class="subBodyData">
											<div fxFlex
												*ngIf="!backLayer && settingObject && settingObject.hasOwnProperty('version') && settingObject.version == defaultSetting.version">
												<ng-container
													*ngFor="let cmp of settingObject[viewMode]['columns'][0]['components'];let index = index">
													<div class="example-box-horizontal"
														(click)="triggerButtun('componentsAddedPopClick');selectThisComponent(index)">
														{{componentsName[cmp.name]['displayName'] | translate}}
														<ng-container *ngIf="cmp.name === 'guest-camera'">
															{{getComponentIndex(viewMode, cmp.name, 0, index)}}
														</ng-container>
													</div>
												</ng-container>
												<span
													*ngIf="settingObject[viewMode]['columns'][0]['components'].length === 0">
													No Components Inserted
												</span>
											</div>
											<div fxFlex *ngIf="backLayer && settingRoomObject">
												<ng-container
													*ngFor="let cmp of settingRoomObject[viewRoomMode]['columns'][0]['components'];let index = index">
													<div class="example-box-horizontal"
														(click)="triggerButtun('componentsAddedPopClick');selectThisRoomComponent(index)">
														{{cmp.name=== 'empty-click' && cmp.title && cmp.title !== '' ?
														cmp.title : componentsName[cmp.name]['displayName']}}
													</div>
												</ng-container>
												<span
													*ngIf="settingRoomObject[viewRoomMode]['columns'][0]['components'].length === 0">
													No Components Inserted
												</span>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</mat-menu>
					<mat-menu [overlapTrigger]="false" class="leftmenuBox" #videoPop="matMenu" [xPosition]="'after'">
						<div class="container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()"
							tabindex="-1">
							<div class="title">
								<div class="header">{{'Streaming Media' | translate}}</div>
								<mat-icon class="actions" (click)="triggerButtun('videoPopClick')">cancel</mat-icon>
							</div>
							<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" class="bodyData">
								<div fxFlex>
									<mat-divider></mat-divider>
									<div class="leftmenuBoxBody">
										<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px"
											*ngIf="(backLayer && viewRoomMode==='room-offline') || (!backLayer && (viewMode==='desktop' || viewMode==='desktop-2' || viewMode==='desktop-3' || viewMode==='desktop-4' || viewMode==='desktop-offline'))"
											class="subBodyData">
											<div fxFlex *ngIf="backLayer">
												<ng-container *ngFor="let cmp of componentsRoomListType[viewRoomMode]">
													<div
														*ngIf="videoComponents.includes(cmp.name) && cmp.name === 'defaultvideo'">
														<div class="example-box-horizontal"
															*ngFor="let vidType of videoComponentTypes"
															[class.selectedComponent]="tempSelectedComponent && tempSelectedComponent.name === cmp.name"
															(click)="triggerButtun('videoPopClick');insertDefaultVideoComponent(cmp, vidType.value)">
															{{vidType['displayName'] | translate}}
														</div>
													</div>
													<div
														*ngIf="videoComponents.includes(cmp.name) && cmp.name !== 'defaultvideo'">
														<div class="example-box-horizontal"
															[class.selectedComponent]="tempSelectedComponent && tempSelectedComponent.name === cmp.name"
															(click)="triggerButtun('videoPopClick');insertVideoLibraryComponent(cmp)">
															{{componentsName[cmp.name]['displayName'] | translate}}
														</div>
													</div>
												</ng-container>
											</div>
											<div fxFlex *ngIf="!backLayer">
												<ng-container *ngFor="let cmp of componentsListType[viewMode]">
													<div
														*ngIf="videoComponents.includes(cmp.name) && cmp.name === 'defaultvideo'">
														<div class="example-box-horizontal"
															*ngFor="let vidType of videoComponentTypes"
															[class.selectedComponent]="tempSelectedComponent && tempSelectedComponent.name === cmp.name"
															(click)="triggerButtun('videoPopClick');insertDefaultVideoComponent(cmp, vidType.value)">
															{{vidType['displayName'] | translate}}
														</div>
													</div>
													<div
														*ngIf="videoComponents.includes(cmp.name) && cmp.name !== 'defaultvideo'">
														<div class="example-box-horizontal"
															[class.selectedComponent]="tempSelectedComponent && tempSelectedComponent.name === cmp.name"
															(click)="triggerButtun('videoPopClick');insertVideoLibraryComponent(cmp)">
															{{componentsName[cmp.name]['displayName'] | translate}}
														</div>
													</div>
												</ng-container>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</mat-menu>
					<mat-menu [overlapTrigger]="false" class="leftmenuBox" #cameraComponentPop="matMenu"
						[xPosition]="'after'">
						<div class="container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()"
							tabindex="-1">
							<div class="title">
								<div class="header">{{'Add Camera' | translate}}</div>
								<mat-icon class="actions" (click)="triggerButtun('cameraComponentPopClick')">cancel
								</mat-icon>
							</div>
							<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" class="bodyData">
								<div fxFlex>
									<mat-divider></mat-divider>
									<div class="leftmenuBoxBody">
										<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px"
											*ngIf="viewMode==='desktop' || viewMode==='desktop-2' || viewMode==='desktop-3' || viewMode==='desktop-4' || viewMode==='desktop-offline'"
											class="subBodyData">
											<div fxFlex>
												<ng-container *ngFor="let cmp of componentsListType[viewMode]">
													<ng-container *ngIf="cameraComponents.includes(cmp.name)">
														<div *ngIf="cmp.name !== 'audienceView' || ( cmp.name === 'audienceView' && (!subscriptionOrgObject || (subscriptionOrgObject && subscriptionOrgObject.features.includes('audience-view/spot-light'))))"
															class="example-box-horizontal"
															[class.selectedComponent]="tempSelectedComponent && tempSelectedComponent.name === cmp.name"
															(click)="triggerButtun('cameraComponentPopClick');insertComponent(cmp)"
															matTooltipClass="tooltip-red"
															matTooltip="{{restrictedComponents.includes(cmp.name) ? subscriptionStatus : ''}}"
															[class.disabledComponent]="subscriptionStatus"
															[class.notAllowedComponent]="subscriptionStatus && subscriptionOrgObject && stat_rem_viwer_hrs === 0">
															{{componentsName[cmp.name]['displayName'] | translate}}
														</div>
														<div *ngIf="cmp.name === 'audienceView' && subscriptionOrgObject && !subscriptionOrgObject.features.includes('audience-view/spot-light')"
															class="example-box-horizontal disabledComponent notAllowedComponent"
															[class.selectedComponent]="tempSelectedComponent && tempSelectedComponent.name === cmp.name"
															matTooltipClass="tooltip-red"
															matTooltip="{{subscriptionUpgradeStatus}}">
															{{componentsName[cmp.name]['displayName'] | translate}}
														</div>
													</ng-container>
												</ng-container>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</mat-menu>
					<mat-menu [overlapTrigger]="false" class="leftmenuBox" #peoplePop="matMenu" [xPosition]="'after'">
						<div class="container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()"
							tabindex="-1">
							<div class="title">
								<div class="header">{{'Assign Host* and other Team Members**' | translate}}
									<span class="note"><br>
										*Required and can be changed later<br>
										**Can be assigned at a later stage</span>
								</div>
								<mat-icon class="actions" (click)="triggerButtun('peoplePopClick')">cancel</mat-icon>
							</div>
							<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" class="bodyData">
								<div fxFlex>
									<mat-divider></mat-divider>
									<div class="leftmenuBoxBody">
										<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px"
											class="subBodyData">
											<div fxFlex>
												<ng-container *ngFor="let ppl of peopleTypeKeys">
													<ng-container *ngIf="ppl === 'moderator'">
														<div *ngIf="(!subscriptionOrgObject || (subscriptionOrgObject && subscriptionOrgObject.features.includes('production-room-and-green-room')))"
															class="example-box-horizontal"
															(click)="triggerButtun('peoplePopClick');addPeople(ppl)">
															Assign {{peopleType[ppl] | translate}}
														</div>
														<div *ngIf="subscriptionOrgObject && !subscriptionOrgObject.features.includes('production-room-and-green-room')"
															class="example-box-horizontal disabledComponent notAllowedComponent"
															matTooltipClass="tooltip-red"
															matTooltip="{{subscriptionUpgradeStatus}}">
															Assign {{peopleType[ppl] | translate}}
														</div>
													</ng-container>
													<ng-container *ngIf="ppl === 'interpreter'">
														<div *ngIf="(!subscriptionOrgObject || (subscriptionOrgObject && subscriptionOrgObject.features.includes('interpretation')))"
															class="example-box-horizontal"
															(click)="triggerButtun('peoplePopClick');addPeople(ppl)">
															Assign {{peopleType[ppl] | translate}}
														</div>
														<div *ngIf="subscriptionOrgObject && !subscriptionOrgObject.features.includes('interpretation')"
															class="example-box-horizontal disabledComponent notAllowedComponent"
															matTooltipClass="tooltip-red"
															matTooltip="{{subscriptionUpgradeStatus}}">
															Assign {{peopleType[ppl] | translate}}
														</div>
													</ng-container>
													<div *ngIf="ppl !== 'moderator' && ppl !== 'interpreter'"
														class="example-box-horizontal"
														(click)="triggerButtun('peoplePopClick');addPeople(ppl)">
														Assign {{peopleType[ppl] | translate}}
													</div>
												</ng-container>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</mat-menu>
					<mat-menu [overlapTrigger]="false" class="leftmenuBox" #settingsPop="matMenu" [xPosition]="'after'">
						<div class="container" (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()"
							tabindex="-1">
							<div class="title">
								<div class="header">{{'Enable Options' | translate}}</div>
								<mat-icon class="actions" (click)="triggerButtun('settingsPopClick')">cancel</mat-icon>
							</div>
							<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" class="bodyData">
								<div fxFlex>
									<mat-divider></mat-divider>
									<div class="leftmenuBoxBody">
										<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px"
											class="subBodyData">
											<div fxFlex>
												<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
													<div fxFlex
														*ngIf="settingObject && settingObject.hasOwnProperty('desktop') && viewMode==='desktop'">
														<div class="settingsPop"
															*ngIf="optionDesktopSettingsStatus['enableExpressions']">
															<mat-icon
																*ngIf="!settingObject['desktop']['optionSettings']['enableExpressions']"
																(click)="setOptions($event, 'enableExpressions', true)"
																title="{{'Interactive Impressions' | translate}}"
																style="height: 30px;vertical-align: middle;">
																check_box_outline_blank</mat-icon>
															<mat-icon
																*ngIf="settingObject['desktop']['optionSettings']['enableExpressions']"
																(click)="setOptions($event, 'enableExpressions', false)"
																title="{{'Interactive Impressions' | translate}}"
																style="height: 30px;vertical-align: middle;">check_box
															</mat-icon>
															<label>{{'Interactive Impressions' | translate}}</label>
														</div>
														<div class="settingsPop"
															*ngIf="optionDesktopSettingsStatus['enableFullScreen']">
															<mat-icon
																*ngIf="!settingObject['desktop']['optionSettings']['enableFullScreen']"
																(click)="setOptions($event, 'enableFullScreen', true)"
																title="{{'Full Screen' | translate}}"
																style="height: 30px;vertical-align: middle;">
																check_box_outline_blank</mat-icon>
															<mat-icon
																*ngIf="settingObject['desktop']['optionSettings']['enableFullScreen']"
																(click)="setOptions($event, 'enableFullScreen', false)"
																title="{{'Full Screen' | translate}}"
																style="height: 30px;vertical-align: middle;">check_box
															</mat-icon>
															<label>{{'Full Screen' | translate}}</label>
															<mat-icon class="infoCssSetting"
																title="{{'Viewers will have a Full Screen option in the Controls bar for the shared screen component' | translate}}">
																info</mat-icon>
														</div>
														<div class="settingsPop"
															*ngIf="optionDesktopSettingsStatus['enableCaptureScreen']">
															<mat-icon
																*ngIf="!settingObject['desktop']['optionSettings']['enableCaptureScreen']"
																(click)="setOptions($event, 'enableCaptureScreen', true)"
																title="{{'Screen Capture' | translate}}"
																style="height: 30px;vertical-align: middle;">
																check_box_outline_blank</mat-icon>
															<mat-icon
																*ngIf="settingObject['desktop']['optionSettings']['enableCaptureScreen']"
																(click)="setOptions($event, 'enableCaptureScreen', false)"
																title="{{'Screen Capture' | translate}}"
																style="height: 30px;vertical-align: middle;">check_box
															</mat-icon>
															<label>{{'Screen Capture' | translate}}</label>
														</div>
														<div class="settingsPop"
															*ngIf="optionDesktopSettingsStatus['enableChat']">
															<mat-icon
																*ngIf="!settingObject['desktop']['optionSettings']['enableChat']"
																(click)="setOptions($event, 'enableChat', true)"
																title="{{'Chat' | translate}}"
																style="height: 30px;vertical-align: middle;">
																check_box_outline_blank</mat-icon>
															<mat-icon
																*ngIf="settingObject['desktop']['optionSettings']['enableChat']"
																(click)="setOptions($event, 'enableChat', false)"
																title="{{'Chat' | translate}}"
																style="height: 30px;vertical-align: middle;">check_box
															</mat-icon>
															<label>{{'Chat' | translate}}</label>
														</div>
														<div class="settingsPop"
															*ngIf="optionDesktopSettingsStatus['enableAskQuestions'] && !meetingMode.includes(data.data['streamMode'])">
															<mat-icon
																*ngIf="!settingObject['desktop']['optionSettings']['enableAskQuestions']"
																(click)="setOptions($event, 'enableAskQuestions', true)"
																title="{{'Ask Questions' | translate}}"
																style="height: 30px;vertical-align: middle;">
																check_box_outline_blank</mat-icon>
															<mat-icon
																*ngIf="settingObject['desktop']['optionSettings']['enableAskQuestions']"
																(click)="setOptions($event, 'enableAskQuestions', false)"
																title="{{'Ask Questions' | translate}}"
																style="height: 30px;vertical-align: middle;">check_box
															</mat-icon>
															<label>{{'Ask Questions' | translate}}</label>
														</div>
														<div class="settingsPop"
															*ngIf="optionDesktopSettingsStatus['enableBreakout'] ">
															<app-custom-checkbox
																[value]="settingObject['desktop']['optionSettings']['enableBreakout']"
																[disabled]="subscriptionOrgObject && !subscriptionOrgObject.features.includes('room-breakouts')"
																[label]="'Join Breakout' | translate"
																[disabledLabel]="subscriptionUpgradeStatus | translate"
																(onChangeReturn)="setCheckBoxOptions('enableBreakout', $event)">
															</app-custom-checkbox>
														</div>
														<div class="settingsPop"
															*ngIf="optionDesktopSettingsStatus['enableScreenShareButton']">
															<mat-icon
																*ngIf="!settingObject['desktop']['optionSettings']['enableScreenShareButton']"
																(click)="setOptions($event, 'enableScreenShareButton', true)"
																title="{{'Screen Share Button' | translate}}"
																style="height: 30px;vertical-align: middle;">
																check_box_outline_blank</mat-icon>
															<mat-icon
																*ngIf="settingObject['desktop']['optionSettings']['enableScreenShareButton']"
																(click)="setOptions($event, 'enableScreenShareButton', false)"
																title="{{'Screen Share Button' | translate}}"
																style="height: 30px;vertical-align: middle;">check_box
															</mat-icon>
															<label>{{'Screen Share Button' | translate}}</label>
														</div>
														<div class="settingsPop"
															*ngIf="optionDesktopSettingsStatus['enableRaiseHand'] && !meetingMode.includes(data.data['streamMode'])">
															<mat-icon
																*ngIf="!settingObject['desktop']['optionSettings']['enableRaiseHand'] && !settingObject['desktop']['optionSettings']['enableScreenShareButton']"
																(click)="setOptions($event, 'enableRaiseHand', true)"
																title="{{'Hand Raise' | translate}}"
																style="height: 30px;vertical-align: middle;">
																check_box_outline_blank</mat-icon>
															<mat-icon
																*ngIf="settingObject['desktop']['optionSettings']['enableRaiseHand'] && !settingObject['desktop']['optionSettings']['enableScreenShareButton']"
																(click)="setOptions($event, 'enableRaiseHand', false)"
																title="{{'Hand Raise' | translate}}"
																style="height: 30px;vertical-align: middle;">check_box
															</mat-icon>
															<mat-icon
																*ngIf="settingObject['desktop']['optionSettings']['enableScreenShareButton']"
																title="{{'Hand Raise Cannot be disabled ' | translate}}"
																style="height: 30px;vertical-align: middle;">
																browser_not_supported</mat-icon>
															<label>{{'Hand Raise' | translate}}</label>
														</div>
														<!-- <div class="settingsPop" *ngIf="optionDesktopSettingsStatus['enableAudienceView'] && data.data['streamMode'] === 'rtc'">
																			<mat-icon *ngIf="!settingObject['desktop']['optionSettings']['enableAudienceView']" (click)="setOptions($event, 'enableAudienceView', true)" title="{{'Enable Audience View' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
																			<mat-icon *ngIf="settingObject['desktop']['optionSettings']['enableAudienceView']" (click)="setOptions($event, 'enableAudienceView', false)" title="{{'Enable Audience View' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
																			<label>{{'Enable Audience View' | translate}}</label>
																		</div> -->
														<!-- <div class="settingsPop" *ngIf="optionDesktopSettingsStatus['enableLargeMeeting'] && !meetingMode.includes(data.data['streamMode'])">
																			<mat-icon *ngIf="!settingObject['desktop']['optionSettings']['enableLargeMeeting']" (click)="setOptions($event, 'enableLargeMeeting', true)" title="{{'Enable Large Meeting' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
																			<mat-icon *ngIf="settingObject['desktop']['optionSettings']['enableLargeMeeting']" (click)="setOptions($event, 'enableLargeMeeting', false)" title="{{'Enable Large Meeting' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
																			<label>{{'Enable Large Meeting' | translate}}</label>
																		</div>
																		<div class="settingsPop" *ngIf="optionDesktopSettingsStatus['numberOfAttendees'] && ((data.data['streamMode'] === 'meeting' && settingObject['desktop']['optionSettings']['enableLargeMeeting']) || (data.data['streamMode'] === 'rtc' && settingObject['desktop']['optionSettings']['enableAudienceView']))">
																			<mat-form-field style="width: 100%;">
																					<input matInput type="number" min="0" placeholder="{{ 'Number Of Attendees' | translate }}" [(value)]="settingObject['desktop']['optionSettings']['numberOfAttendees']" (change)="setNumericOptions($event, 'numberOfAttendees', $event.target.value, 1, 20)">
																			</mat-form-field>
																		</div> -->
													</div>
													<!-- <div fxFlex *ngIf="settingObject && settingObject.hasOwnProperty('desktop-hls') && settingObject['desktop-hls'].hasOwnProperty('optionSettings') && viewMode==='desktop-hls'">
							 											<div class="settingsPop" >
							 													 <mat-icon *ngIf="!settingObject['desktop-hls']['optionSettings']['addScreenShare']" (click)="setOptionsHLS($event, 'addScreenShare', true)" title="{{'Screen Share' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
							 													 <mat-icon *ngIf="settingObject['desktop-hls']['optionSettings']['addScreenShare']" (click)="setOptionsHLS($event, 'addScreenShare', false)" title="{{'Screen Share' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
							 													 <label>{{'Screen Share' | translate}}</label>
							 											 </div>
																</div> -->
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</mat-menu>
					<mat-list role="list" class="leftmenuList">
						<mat-list-item role="listitem" class="leftmenuListItemMode">
							<span class="icon-helper-parent">
								<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep2')">help</mat-icon>
							</span>
							<!-- <button [bdcWalkTriggerFor]="sessionStep2" [enabled]="openedLeftMenu===''" mat-icon-button color="primary" (click)="insertEmptyComponent()" matTooltip="{{'Add Clickable Areas' | translate}}" matTooltipPosition="above" matTooltipClass="tooltip-red">
										<mat-icon >flip_to_back</mat-icon>
									</button> -->
							<button mat-icon-button color="primary" (click)="flipLayer(!backLayer)"
								matTooltip="{{backLayer ? 'Switch to Session Setup' : 'Switch to Room Setup' | translate}}"
								matTooltipPosition="above" matTooltipClass="tooltip-red" [class.roomButton]="backLayer"
								[disabled]="!backLayer && data.data && data.data['streamMode'] ==='meeting'"
								[class.roomButtonDisabled]="!backLayer && data.data && data.data['streamMode'] ==='meeting'">
								<div class="designMode">
									<div *ngIf="backLayer" class="designModeRoom">Room</div>
									<div *ngIf="!backLayer" class="designModeSession">Session</div>
								</div>
								<mat-icon *ngIf="backLayer" style="overflow: visible;">toggle_off</mat-icon>
								<mat-icon *ngIf="!backLayer" style="overflow: visible;"
									[class.roomButtonDisabled]="!backLayer && data.data && data.data['streamMode'] ==='meeting'">
									toggle_on</mat-icon>
							</button>
						</mat-list-item>

						<mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingObject">
							<mat-divider class="menu-btn-divider" [vertical]="true"></mat-divider>
						</mat-list-item>

						<mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingObject && !backLayer">
							<button mat-icon-button id="editLiveClick" [matMenuTriggerFor]="liveoption"
								matTooltip="Selected Mode: {{ viewMode.indexOf('-offline') > -1 ? 'Offline' : 'Live' }}"
								matTooltipClass="tooltip-red" [class.offlineButton]="viewMode.indexOf('-offline') > -1">
								<mat-icon *ngIf="viewMode.indexOf('-offline') == -1">live_tv</mat-icon>
								<mat-icon *ngIf="viewMode.indexOf('-offline') > -1">tv_off</mat-icon>
								<!-- <span *ngIf="viewMode.indexOf('-offline') == -1">Live</span>
											<span *ngIf="viewMode.indexOf('-offline') > -1">Offline</span> -->
							</button>
							<mat-menu #liveoption="matMenu">
								<button mat-menu-item (click)="setViewModeOffline(false)"><span>{{'Live Mode' |
										translate}}</span></button>
								<button mat-menu-item (click)="setViewModeOffline(true)"><span>{{'Offline Mode' |
										translate}}</span></button>
							</mat-menu>
						</mat-list-item>
						<mat-list-item role="listitem" class="leftmenuListItem"
							*ngIf="settingObject && !backLayer && viewMode.indexOf('-offline') == -1">
							<!-- <span class="icon-helper-parent">
										<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep3')">help</mat-icon>
									</span> -->
							<!-- <button [matMenuTriggerFor]="pagesPopStreamMode" id="pagesPopStreamModeClick" [bdcWalkTriggerFor]="sessionStep3" [enabled]="openedLeftMenu===''" mat-icon-button color="primary"  matTooltip="Setup {{'Sessions' | translate}}" matTooltipPosition="above" matTooltipClass="tooltip-red">
										<mat-icon>pages</mat-icon>
									</button> -->
							<button *ngIf="data.data['sessionsource'] !== 'event'"
								[matMenuTriggerFor]="pagesPopStreamMode" id="pagesPopStreamModeClick" mat-icon-button
								color="primary"
								matTooltip="{{'Selected Stream Mode:' | translate}}  {{streamModeDisplay[data.data['streamMode']] | translate}}"
								matTooltipPosition="above" matTooltipClass="tooltip-red"
								[class.offlineButton]="viewMode.indexOf('-offline') > -1">
								<mat-icon>stream</mat-icon>
							</button>

							<button *ngIf="data.data['sessionsource'] === 'event'" id="pagesPopStreamModeClick"
								class="disabledMenuIcon" type="button" mat-icon-button color="primary"
								matTooltip="{{'This session belongs to a Calendar event. You can edit the session settings and attendees on the Calendar' | translate}}"
								matTooltipPosition="above" matTooltipClass="tooltip-red"
								[class.offlineButton]="viewMode.indexOf('-offline') > -1">
								<mat-icon>stream</mat-icon>
							</button>
						</mat-list-item>
						<mat-list-item role="listitem" class="leftmenuListItem"
							*ngIf="settingObject && !backLayer && viewMode.indexOf('-offline') == -1 && data.data['streamMode'] === 'hls'">
							<!-- <button mat-icon-button class="breakWord"
											*ngIf="data.data['streamMode'] === 'rtc'"
											matTooltip="{{rtchlsModeDisplay[viewMode]}}" matTooltipClass="tooltip-red"
											[class.notBTN]="data.data['streamMode'] !== 'hls'">
											<span>RTC</span>
									</button> -->
							<button mat-icon-button [matMenuTriggerFor]="streammode" class="breakWord"
								matTooltip="{{'Selected Layout:' | translate}} {{rtchlsModeHLSDisplay[viewMode]}}"
								matTooltipClass="tooltip-red" [class.offlineButton]="viewMode.indexOf('-offline') > -1">
								<mat-icon *ngIf="viewMode.indexOf('-hls') == -1">supervised_user_circle</mat-icon>
								<mat-icon *ngIf="viewMode.indexOf('-hls') > -1">groups</mat-icon>
							</button>
							<mat-menu #streammode="matMenu">
								<button mat-menu-item [disabled]="viewMode=== 'desktop'"
									*ngIf="viewMode=== 'desktop-hls' || viewMode=== 'desktop'"
									(click)="setViewModeReturn($event, 'desktop')"
									[class.offlineButton]="viewMode.indexOf('-offline') > -1">
									<span
										*ngIf="data.data['streamMode'] === 'hls'">{{streamModeHLSDisplay['rtc']}}</span>
									<span *ngIf="data.data['streamMode'] !== 'hls'">{{streamModeDisplay['rtc']}}</span>
								</button>
								<button mat-menu-item [disabled]="viewMode=== 'desktop-hls'"
									(click)="setViewModeReturn($event, 'desktop-hls')"
									*ngIf="viewMode=== 'desktop-hls' || viewMode=== 'desktop'"
									[class.offlineButton]="viewMode.indexOf('-offline') > -1">
									<span
										*ngIf="data.data['streamMode'] === 'hls'">{{streamModeHLSDisplay['hls']}}</span>
									<span *ngIf="data.data['streamMode'] !== 'hls'">{{streamModeDisplay['hls']}}</span>
								</button>
							</mat-menu>
						</mat-list-item>
						<mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingObject && !backLayer">
							<mat-divider class="menu-btn-divider" [vertical]="true"></mat-divider>
						</mat-list-item>
						<mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingRoomObject && backLayer">
							<span class="icon-helper-parent">
								<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep1')">help</mat-icon>
							</span>
							<!-- <button [bdcWalkTriggerFor]="sessionStep1" [enabled]="openedLeftMenu===''" mat-icon-button color="primary" (click)="galleryLibrary()" matTooltip="{{'Select Background' | translate}}" matTooltipPosition="above" matTooltipClass="tooltip-red">
										<mat-icon>image</mat-icon>
									</button> -->
							<button mat-icon-button color="primary" (click)="galleryLibrary()"
								matTooltip="{{'Set Background' | translate}}" matTooltipPosition="above"
								matTooltipClass="tooltip-red" class="roomButton">
								<mat-icon>image</mat-icon>
							</button>
						</mat-list-item>
						<mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingRoomObject && backLayer">
							<button mat-icon-button color="primary" (click)="insertEmptyComponent()"
								matTooltip="{{'Add Clickable Areas' | translate}}" matTooltipPosition="above"
								matTooltipClass="tooltip-red" class="roomButton">
								<mat-icon>border_outer</mat-icon>
							</button>
						</mat-list-item>

						<mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingObject"
							[hidden]="backLayer">
							<span class="icon-helper-parent">
								<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep4')">help</mat-icon>
							</span>
							<ng-container *ngIf="data.data['sessionsource'] !== 'event'">
								<button *ngIf="data.data['streamMode'] ==='meeting'" id="peoplePopClick" mat-icon-button
									color="primary" matTooltip="{{'Add People' | translate}}" matTooltipPosition="above"
									matTooltipClass="tooltip-red" (click)="addPeople('attendee')">
									<mat-icon>group_add</mat-icon>
								</button>
								<button *ngIf="data.data['streamMode'] !=='meeting'" [matMenuTriggerFor]="peoplePop"
									id="peoplePopClick" mat-icon-button color="primary"
									matTooltip="{{'Assign Host and other Team Members' | translate}}"
									matTooltipPosition="above" matTooltipClass="tooltip-red"
									[class.offlineButton]="viewMode.indexOf('-offline') > -1">
									<mat-icon>group_add</mat-icon>
								</button>
							</ng-container>
							<button *ngIf="data.data['sessionsource'] === 'event'" id="peoplePopClick"
								class="disabledMenuIcon" type="button" mat-icon-button color="primary"
								matTooltip="{{'This session belongs to a Calendar event. You can edit the session settings and attendees on the Calendar' | translate}}"
								matTooltipPosition="above" matTooltipClass="tooltip-red"
								[class.offlineButton]="viewMode.indexOf('-offline') > -1">
								<mat-icon>group_add</mat-icon>
							</button>
						</mat-list-item>

						<mat-list-item role="listitem" class="leftmenuListItem"
							*ngIf="settingObject && (viewMode==='desktop' ) && data.data['streamMode'] !=='hls' && viewMode !=='desktop-hls'"
							[hidden]="backLayer">
							<span class="icon-helper-parent">
								<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep8')">help</mat-icon>
							</span>
							<!-- <button [matMenuTriggerFor]="settingsPop" id="settingsPopClick" [bdcWalkTriggerFor]="sessionStep8" [enabled]="openedLeftMenu===''" mat-icon-button color="primary" matTooltip="{{'Enable Options' | translate}}" matTooltipPosition="above" matTooltipClass="tooltip-red">
										<mat-icon>tune</mat-icon>
									</button> -->
							<button [matMenuTriggerFor]="settingsPop" id="settingsPopClick" mat-icon-button
								color="primary" matTooltip="{{'Enable Options' | translate}}" matTooltipPosition="above"
								matTooltipClass="tooltip-red" [class.offlineButton]="viewMode.indexOf('-offline') > -1">
								<mat-icon>tune</mat-icon>
							</button>
						</mat-list-item>
						<mat-list-item role="listitem" class="leftmenuListItem"
							*ngIf="settingObject && viewMode !=='desktop-hls' && !meetingMode.includes(data.data['streamMode']) && viewMode !=='desktop-offline' && viewMode!=='desktop-2' && viewMode!=='desktop-3' && viewMode!=='desktop-4'"
							[hidden]="backLayer">
							<mat-divider class="menu-btn-divider" [vertical]="true"></mat-divider>
						</mat-list-item>
						<mat-list-item role="listitem" class="leftmenuListItem"
							*ngIf="settingObject && viewMode !=='desktop-hls' && !meetingMode.includes(data.data['streamMode']) && data.data['streamMode'] !== 'cloudlive' && viewMode !=='desktop-offline' && viewMode!=='desktop-2' && viewMode!=='desktop-3' && viewMode!=='desktop-4'"
							[hidden]="backLayer">
							<span class="icon-helper-parent">
								<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep5')">help</mat-icon>
							</span>
							<!-- <button [matMenuTriggerFor]="cameraComponentPop" id="cameraComponentPopClick" [bdcWalkTriggerFor]="sessionStep5" [enabled]="openedLeftMenu===''" mat-icon-button color="primary" matTooltip="{{'Add Camera' | translate}}" matTooltipPosition="above" matTooltipClass="tooltip-red">
										<mat-icon>video_call</mat-icon>
									</button> -->
							<button [matMenuTriggerFor]="cameraComponentPop" id="cameraComponentPopClick"
								mat-icon-button color="primary" matTooltip="{{'Add Camera' | translate}}"
								matTooltipPosition="above" matTooltipClass="tooltip-red"
								[class.offlineButton]="viewMode.indexOf('-offline') > -1">
								<mat-icon>video_call</mat-icon>
							</button>
						</mat-list-item>
						<!-- (backLayer && settingRoomObject && viewRoomMode === 'room-offline') ||  -->
						<mat-list-item role="listitem" class="leftmenuListItem"
							*ngIf="(backLayer && viewRoomMode==='room-offline') || (!backLayer && settingObject && data.data['streamMode'] !=='hls' && viewMode !=='desktop-hls' && (!meetingMode.includes(data.data['streamMode']) || (meetingMode.includes(data.data['streamMode']) && (viewMode ==='desktop-offline' || viewMode==='desktop-2' || viewMode==='desktop-3' || viewMode==='desktop-4'))))">
							<span class="icon-helper-parent">
								<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep6')">help</mat-icon>
							</span>
							<!-- <button [matMenuTriggerFor]="videoPop" id="videoPopClick" [bdcWalkTriggerFor]="sessionStep6" [enabled]="openedLeftMenu===''" mat-icon-button color="primary"  matTooltip="{{'Add Streaming Media' | translate}}"  matTooltipPosition="above" matTooltipClass="tooltip-red">
										<mat-icon>ondemand_video</mat-icon>
									</button> -->
							<button [matMenuTriggerFor]="videoPop" id="videoPopClick" mat-icon-button color="primary"
								matTooltip="{{'Add Streaming Media' | translate}}" matTooltipPosition="above"
								matTooltipClass="tooltip-red"
								[class.offlineButton]="!backLayer && viewMode.indexOf('-offline') > -1"
								[class.roomButton]="backLayer">
								<mat-icon>cast</mat-icon>
							</button>

						</mat-list-item>
						<mat-list-item role="listitem" class="leftmenuListItem"
							*ngIf="!backLayer && settingObject && data.data['streamMode'] !=='hls' && viewMode !=='desktop-hls' && (!meetingMode.includes(data.data['streamMode']) || (meetingMode.includes(data.data['streamMode']) && (viewMode ==='desktop-offline' || viewMode==='desktop-2' || viewMode==='desktop-3' || viewMode==='desktop-4')))">
							<span class="icon-helper-parent">
								<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep7')">help</mat-icon>
							</span>
							<button [matMenuTriggerFor]="componentPop" id="componentPopClick" mat-icon-button
								color="primary" matTooltip="{{'Add Components' | translate}}" matTooltipPosition="above"
								matTooltipClass="tooltip-red" [class.offlineButton]="viewMode.indexOf('-offline') > -1">
								<!-- <mat-icon>add_circle</mat-icon> -->
								<mat-icon>grid_view</mat-icon>
							</button>

						</mat-list-item>
						<mat-list-item role="listitem" class="leftmenuListItem"
							*ngIf="backLayer && settingRoomObject && viewRoomMode ==='room-offline'">
							<span class="icon-helper-parent">
								<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep7')">help</mat-icon>
							</span>
							<button [matMenuTriggerFor]="componentPop" id="componentPopClick" mat-icon-button
								color="primary" matTooltip="{{'Add Components' | translate}}" matTooltipPosition="above"
								matTooltipClass="tooltip-red" class="roomButton">
								<!-- <mat-icon>add_circle</mat-icon> -->
								<mat-icon>grid_view</mat-icon>
							</button>

						</mat-list-item>
						<mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingObject && !backLayer">
							<mat-divider class="menu-btn-divider" [vertical]="true"></mat-divider>
						</mat-list-item>


						<mat-list-item role="listitem" class="leftmenuListItem"
							*ngIf="settingObject && data.data['streamMode'] !== 'cloudlive'" [hidden]="backLayer">
							<span class="icon-helper-parent">
								<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep9')">help</mat-icon>
							</span>
							<!-- <button [bdcWalkTriggerFor]="sessionStep9" [enabled]="openedLeftMenu===''" mat-icon-button color="primary" (click)="assignContent()" matTooltip="{{'Assign Polls and Triggered Content' | translate}}" matTooltipPosition="above" matTooltipClass="tooltip-red">
										<mat-icon>list_alt</mat-icon>
									</button> -->
							<button mat-icon-button color="primary" (click)="assignContent()"
								matTooltip="{{'Assign Polls and Triggered Content' | translate}}"
								matTooltipPosition="above" matTooltipClass="tooltip-red"
								[class.offlineButton]="viewMode.indexOf('-offline') > -1">
								<mat-icon>list_alt</mat-icon>
							</button>
						</mat-list-item>

						<mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingObject"
							[hidden]="backLayer">
							<span class="icon-helper-parent">
								<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep11')">help</mat-icon>
							</span>
							<!-- <button [bdcWalkTriggerFor]="sessionStep11" [enabled]="openedLeftMenu===''" mat-icon-button color="primary" (click)="assignResources()" matTooltip="{{'Assign Resources' | translate}}" matTooltipPosition="above" matTooltipClass="tooltip-red">
										<mat-icon>library_books</mat-icon>
									</button> -->
							<button mat-icon-button color="primary" (click)="assignResources()"
								matTooltip="{{'Assign Resources' | translate}}" matTooltipPosition="above"
								matTooltipClass="tooltip-red" [class.offlineButton]="viewMode.indexOf('-offline') > -1">
								<mat-icon>library_books</mat-icon>
							</button>
						</mat-list-item>
						<mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingObject && !backLayer">
							<mat-divider class="menu-btn-divider" [vertical]="true"></mat-divider>
						</mat-list-item>
						<mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingObject && !backLayer">
							<!-- <span class="icon-helper-parent">
										<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep3')">help</mat-icon>
									</span> -->
							<!-- <button [matMenuTriggerFor]="pagesPop" id="pagesPopClick" [bdcWalkTriggerFor]="sessionStep3" [enabled]="openedLeftMenu===''" mat-icon-button color="primary"  matTooltip="Setup {{'Sessions' | translate}}" matTooltipPosition="above" matTooltipClass="tooltip-red">
										<mat-icon>pages</mat-icon>
									</button> -->
							<button [matMenuTriggerFor]="pagesPop" id="pagesPopClick" mat-icon-button color="primary"
								matTooltip="{{'Select another session to link to current session' | translate}}"
								matTooltipPosition="above" matTooltipClass="tooltip-red"
								[class.offlineButton]="viewMode.indexOf('-offline') > -1">
								<mat-icon>add_link</mat-icon>
							</button>

						</mat-list-item>
						<mat-list-item role="listitem" class="leftmenuListItem"
							*ngIf="settingObject && data.data['streamMode'] !=='hls' && data.data['attendance'] !=='in-person' && viewMode !=='desktop-hls' && data.data['streamMode'] !== 'cloudlive' && data.data['streamMode'] !== 'meeting' && (!subscriptionOrgObject || (subscriptionOrgObject && subscriptionOrgObject['features'].includes('room-breakouts')))"
							[hidden]="backLayer">
							<span class="icon-helper-parent">
								<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep10')">help</mat-icon>
							</span>
							<!-- <button [bdcWalkTriggerFor]="sessionStep10" [enabled]="openedLeftMenu===''" mat-icon-button color="primary" (click)="addBreakoutSession()" matTooltip="{{'Add Breakout' | translate}}" matTooltipPosition="above" matTooltipClass="tooltip-red">
										<mat-icon>call_split</mat-icon>
									</button> -->
							<button mat-icon-button color="primary" (click)="addBreakoutSession()"
								matTooltip="{{'Add Breakout' | translate}}" matTooltipPosition="above"
								matTooltipClass="tooltip-red" [class.offlineButton]="viewMode.indexOf('-offline') > -1">
								<mat-icon>call_split</mat-icon>
							</button>
						</mat-list-item>
						<mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingRoomObject && backLayer">
							<mat-divider class="menu-btn-divider" [vertical]="true"></mat-divider>
						</mat-list-item>

						<mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingObject && !backLayer">
							<mat-divider class="menu-btn-divider" [vertical]="true"></mat-divider>
						</mat-list-item>
						<mat-list-item role="listitem" class="leftmenuListItem"
							*ngIf="(backLayer && settingRoomObject && viewRoomMode === 'room-offline') || (!backLayer && settingObject && viewMode !=='desktop-hls' && (!meetingMode.includes(data.data['streamMode']) || (meetingMode.includes(data.data['streamMode']) && (viewMode ==='desktop-offline' || viewMode==='desktop-2' || viewMode==='desktop-3' || viewMode==='desktop-4'))))">
							<span class="icon-helper-parent">
								<mat-icon class="icon-helper" (click)="showSelfHelp('sessionStep12')">help</mat-icon>
							</span>
							<!-- <button [matMenuTriggerFor]="componentsAddedPop" id="componentsAddedPopClick" [bdcWalkTriggerFor]="sessionStep12" [enabled]="openedLeftMenu===''" mat-icon-button color="primary" matTooltip="{{'List Inserted Components' | translate}}" matTooltipPosition="above" matTooltipClass="tooltip-red">
										<mat-icon>view_list</mat-icon>
									</button> -->
							<button [matMenuTriggerFor]="componentsAddedPop" id="componentsAddedPopClick"
								mat-icon-button color="primary" matTooltip="{{'List Inserted Components' | translate}}"
								matTooltipPosition="above" matTooltipClass="tooltip-red"
								[class.offlineButton]="!backLayer && viewMode.indexOf('-offline') > -1"
								[class.roomButton]="backLayer">
								<mat-icon>view_list</mat-icon>
							</button>
						</mat-list-item>
						<mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingRoomObject && backLayer">
							<button id="editRoomClick" mat-icon-button color="primary"
								matTooltip="{{'Edit Room Properties' | translate}}" matTooltipPosition="above"
								matTooltipClass="tooltip-red" (click)="setTemplateActionReturn('room-edit')"
								class="roomButton">
								<mat-icon>edit</mat-icon>
							</button>
						</mat-list-item>
						<mat-list-item role="listitem" class="leftmenuListItem" *ngIf="backLayer">
							<button id="addRoomClick" mat-icon-button color="primary"
								matTooltip="{{'Add Room' | translate}}" matTooltipPosition="above"
								matTooltipClass="tooltip-red" [matMenuTriggerFor]="canNewListoption"
								[class.offlineButton]="!backLayer && viewMode.indexOf('-offline') > -1"
								[class.roomButton]="backLayer">
								<!-- 'room-create-room-exhibit' -->
								<mat-icon>add_circle</mat-icon>
							</button>
							<mat-menu #canNewListoption="matMenu">
								<button mat-menu-item *ngFor="let clk of canNewList"
									(click)="setTemplateActionReturn(clk.secondValue)" [disabled]="clk.disabled"><span
										style="pointer-events: all !important;" matTooltipClass="tooltip-red"
										matTooltip="{{clk.disabled ? clk.disabledLabel : ''}}">{{clk.displayName |
										translate}}</span></button>
							</mat-menu>
						</mat-list-item>

						<mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingObject && !backLayer">
							<button *ngIf="data.data['sessionsource'] !== 'event'" id="editSessionClick" mat-icon-button
								color="primary" matTooltip="{{'Edit Session Properties' | translate}}"
								matTooltipPosition="above" matTooltipClass="tooltip-red"
								(click)="editSession(data.data._id)"
								[class.offlineButton]="viewMode.indexOf('-offline') > -1">
								<mat-icon>edit</mat-icon>
							</button>
							<button *ngIf="data.data['sessionsource'] === 'event'" id="editSessionClick"
								class="disabledMenuIcon" type="button" mat-icon-button color="primary"
								matTooltip="{{'This session belongs to a Calendar event. You can edit the session settings and attendees on the Calendar' | translate}}"
								matTooltipPosition="above" matTooltipClass="tooltip-red"
								[class.offlineButton]="viewMode.indexOf('-offline') > -1">
								<mat-icon>edit</mat-icon>
							</button>
						</mat-list-item>
						<mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingRoomObject && !backLayer">
							<button id="addSessionClick" mat-icon-button color="primary"
								matTooltip="{{'Add Session' | translate}}" matTooltipPosition="above"
								matTooltipClass="tooltip-red" (click)="createSession()"
								[class.offlineButton]="viewMode.indexOf('-offline') > -1">
								<mat-icon>add_circle</mat-icon>
							</button>
						</mat-list-item>
						<mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingObject && !backLayer">
							<button id="deleteSessionClick" mat-icon-button color="primary"
								matTooltip="{{'Delete Session' | translate}}" matTooltipPosition="above"
								matTooltipClass="tooltip-red" (click)="deleteSession(data.data._id)"
								[class.offlineButton]="viewMode.indexOf('-offline') > -1">
								<mat-icon>delete_forever</mat-icon>
							</button>
						</mat-list-item>
						<mat-list-item role="listitem" class="leftmenuListItem" *ngIf="settingRoomObject && backLayer">
							<button id="deleteRoomClick" mat-icon-button color="primary"
								matTooltip="{{'Delete Room' | translate}}" matTooltipPosition="above"
								matTooltipClass="tooltip-red" (click)="deleteRoom(data.roomData._id)"
								class="roomButton">
								<mat-icon>delete_forever</mat-icon>
							</button>
						</mat-list-item>
					</mat-list>
				</div>
				<div class="sessionIsActive" *ngIf="data.data.active && !isAdmin" (click)="cancelThru($event)">
					<mat-card>
						<mat-card-content>
							{{'You cannot edit the settings while the session is live' | translate}}
						</mat-card-content>
					</mat-card>
				</div>
				<!-- session gallery list Version -->
				<div [hidden]="(data.data.hasOwnProperty('_id') && data.data._id) || backLayer" fxLayout="row"
					fxLayout.lt-md="column" fxFlex *ngIf="settingRoomObject && data.roomData && data.roomData['_id']">
					<div fxFlex>
						<div style="width: 95vw;margin: 10px auto;height: 85vh;">
							<app-sessions-gallery #sessionsGalleryList [roomId]="data.roomData['_id']"
								(onSelectReturn)="selectSession($event)"></app-sessions-gallery>
						</div>
					</div>
				</div>
				<!-- Room Version -->
				<div [hidden]="!backLayer" fxLayout="row" fxLayout.lt-md="column" fxFlex *ngIf="settingRoomObject">
					<div fxFlex *ngIf="settingRoomObject.hasOwnProperty('room-offline')">
						<app-screen-layout #roomOffline
							*ngIf="settingRoomObject.hasOwnProperty('version') && settingRoomObject.version == defaultRoomSetting.version"
							[type]="'room-offline'" [data]="data" [settingObject]="settingRoomObject['room-offline']"
							(setSelectComponent)="selectComponent($event)" (setUpdateLayout)="updateRoomLayout($event)"
							[viewPortMode]="viewPortMode" [bgColor]="bgBackgroundColor"
							[pictureLink]="bgBackgroundLink1">
						</app-screen-layout>
					</div>
				</div>
				<!-- desktop Version -->
				<div [hidden]="backLayer" fxLayout="row" fxLayout.lt-md="column" fxFlex
					*ngIf="settingObject && viewMode==='desktop' && !meetingMode.includes(data.data['streamMode'])">
					<div fxFlex *ngIf="settingObject.hasOwnProperty('desktop')">
						<app-screen-layout #desktopOnline
							*ngIf="settingObject.hasOwnProperty('version') && settingObject.version == defaultSetting.version"
							[type]="'desktop'" [data]="data" [settingObject]="settingObject['desktop']"
							(setSelectComponent)="selectComponent($event)" (setUpdateLayout)="updateLayout($event)"
							[viewPortMode]="viewPortMode" [bgColor]="bgBackgroundColor"
							[pictureLink]="bgBackgroundLink1">
						</app-screen-layout>
					</div>
				</div>
				<!-- hls Version -->
				<div [hidden]="backLayer" fxLayout="row" fxLayout.lt-md="column" fxFlex
					*ngIf="settingObject && viewMode==='desktop-hls' && !meetingMode.includes(data.data['streamMode'])">
					<div fxFlex *ngIf="settingObject.hasOwnProperty('desktop-hls')">
						<app-screen-layout #desktophlsOnline
							*ngIf="settingObject.hasOwnProperty('version') && settingObject.version == defaultSetting.version"
							[type]="'desktop-hls'" [guestsTotal]="guestsTotal" [data]="data"
							[settingObject]="settingObject['desktop-hls']"
							(setSelectComponent)="selectComponent($event)" (setUpdateLayout)="updateLayout($event)"
							[hasTwoPipCamera]="optionDesktopSettingsStatus.hasTwoPipCamera"
							[viewPortMode]="viewPortMode" [bgColor]="bgBackgroundColor"
							[pictureLink]="bgBackgroundLink1"></app-screen-layout>
					</div>
				</div>
				<!-- Offline Version -->
				<div [hidden]="backLayer" fxLayout="row" fxLayout.lt-md="column" fxFlex
					*ngIf="settingObject && viewMode==='desktop-offline'">
					<div fxFlex>
						<app-screen-layout #desktopOffline
							*ngIf="settingObject.hasOwnProperty('version') && settingObject.version == defaultSetting.version"
							[type]="'desktop-offline'" [data]="data" [settingObject]="settingObject['desktop-offline']"
							(setSelectComponent)="selectComponent($event)"
							(setUpdateLayout)="updateOfflineLayout($event)" [viewPortMode]="viewPortMode"
							[bgColor]="bgBackgroundColor" [pictureLink]="bgBackgroundLink1">
						</app-screen-layout>
					</div>
				</div>
				<!-- desktop-2 Version -->
				<div [hidden]="backLayer" fxLayout="row" fxLayout.lt-md="column" fxFlex
					*ngIf="settingObject && settingObject.hasOwnProperty('desktop-2') &&  viewMode==='desktop-2'">
					<div fxFlex>
						<app-screen-layout #desktopDesktop2
							*ngIf="settingObject.hasOwnProperty('version') && settingObject.version == defaultSetting.version"
							[type]="'desktop-2'" [data]="data" [settingObject]="settingObject['desktop-2']"
							(setSelectComponent)="selectComponent($event)" (setUpdateLayout)="updateLayout($event)"
							[viewPortMode]="viewPortMode" [bgColor]="bgBackgroundColor"
							[pictureLink]="bgBackgroundLink1">
						</app-screen-layout>
					</div>
				</div>
				<!-- desktop-3 Version -->
				<div [hidden]="backLayer" fxLayout="row" fxLayout.lt-md="column" fxFlex
					*ngIf="settingObject && settingObject.hasOwnProperty('desktop-3') &&  viewMode==='desktop-3'">
					<div fxFlex>
						<app-screen-layout #desktopDesktop3
							*ngIf="settingObject.hasOwnProperty('version') && settingObject.version == defaultSetting.version"
							[type]="'desktop-3'" [data]="data" [settingObject]="settingObject['desktop-3']"
							(setSelectComponent)="selectComponent($event)" (setUpdateLayout)="updateLayout($event)"
							[viewPortMode]="viewPortMode" [bgColor]="bgBackgroundColor"
							[pictureLink]="bgBackgroundLink1">
						</app-screen-layout>
					</div>
				</div>
				<!-- desktop-4 Version -->
				<div [hidden]="backLayer" fxLayout="row" fxLayout.lt-md="column" fxFlex
					*ngIf="settingObject && settingObject.hasOwnProperty('desktop-4') &&  viewMode==='desktop-4'">
					<div fxFlex>
						<app-screen-layout #desktopDesktop4
							*ngIf="settingObject.hasOwnProperty('version') && settingObject.version == defaultSetting.version"
							[type]="'desktop-4'" [data]="data" [settingObject]="settingObject['desktop-4']"
							(setSelectComponent)="selectComponent($event)" (setUpdateLayout)="updateLayout($event)"
							[viewPortMode]="viewPortMode" [bgColor]="bgBackgroundColor"
							[pictureLink]="bgBackgroundLink1">
						</app-screen-layout>
					</div>
				</div>
			</div>
		</div>
	</div>
</form>
<bdc-walk-popup #sessionStep1 name="sessionStep1" header="{{'Add Clickable Areas' | translate}}" xPosition="after"
	[horizontal]="true" sideNoteText="1/12" [showButton]="true" buttonText="Next"
	[mustCompleted]="{sessionNext: 'room'}" [onButtonCompleteTask]="{sessionNext: 1}" buttonPreviousText="Previous"
	[onButtonPreviousTask]="{sessionNext: undefined,  sessionStep0:false }">
	{{'Set your clickable links on the background' | translate}}
</bdc-walk-popup>

<bdc-walk-popup #sessionStep2 name="sessionStep2" header="{{'Select Background' | translate}}" xPosition="after"
	[horizontal]="true" sideNoteText="2/12" [showButton]="true" buttonText="Next" [mustCompleted]="{sessionNext: 1}"
	[onButtonCompleteTask]="{sessionNext: 2}" buttonPreviousText="Previous"
	[onButtonPreviousTask]="{sessionNext: 'room', sessionStep1:false}">
	{{'Set the background color or image' | translate}}
</bdc-walk-popup>

<bdc-walk-popup #sessionStep3 name="sessionStep3" header="Setup {{'Sessions' | translate}}" xPosition="after"
	[horizontal]="true" sideNoteText="3/12" [showButton]="true" buttonText="Next" [mustCompleted]="{sessionNext: 2}"
	[onButtonCompleteTask]="{sessionNext: 3}" buttonPreviousText="Previous"
	[onButtonPreviousTask]="{sessionNext: 1, sessionStep2:false}">
	{{'Create, edit, delete and set the stream mode type of the session' | translate}}
</bdc-walk-popup>

<bdc-walk-popup #sessionStep4 name="sessionStep4" header="{{'Add People' | translate}}" xPosition="after"
	[horizontal]="true" sideNoteText="4/12" [showButton]="true" buttonText="Next" [mustCompleted]="{sessionNext: 3}"
	[onButtonCompleteTask]="{sessionNext: 4}" buttonPreviousText="Previous"
	[onButtonPreviousTask]="{sessionNext: 2, sessionStep3:false}">
	{{'Assign the various users in the session' | translate}}
</bdc-walk-popup>

<bdc-walk-popup #sessionStep5 name="sessionStep5" header="{{'Add Streaming Media' | translate}}" xPosition="after"
	[horizontal]="true" sideNoteText="5/12" [showButton]="true" buttonText="Next" [mustCompleted]="{sessionNext: 4}"
	[onButtonCompleteTask]="{sessionNext: 5}" buttonPreviousText="Previous"
	[onButtonPreviousTask]="{sessionNext: 3, sessionStep4:false}">
	{{'Add and position a Streaming Media component in the session' | translate}}
</bdc-walk-popup>

<bdc-walk-popup #sessionStep6 name="sessionStep6" header="{{'Add Camera' | translate}}" xPosition="after"
	[horizontal]="true" sideNoteText="6/12" [showButton]="true" buttonText="Next" [mustCompleted]="{sessionNext: 5}"
	[onButtonCompleteTask]="{sessionNext: 6}" buttonPreviousText="Previous"
	[onButtonPreviousTask]="{sessionNext: 4, sessionStep5:false}">
	{{'Add and position a camera in the session' | translate}}
</bdc-walk-popup>

<bdc-walk-popup #sessionStep7 name="sessionStep7" header="{{'Add Components' | translate}}" xPosition="after"
	[horizontal]="true" sideNoteText="7/12" [showButton]="true" buttonText="Next" [mustCompleted]="{sessionNext: 6}"
	[onButtonCompleteTask]="{sessionNext: 7}" buttonPreviousText="Previous"
	[onButtonPreviousTask]="{sessionNext: 5, sessionStep6:false}">
	{{'Add and position a component in the session' | translate}}
</bdc-walk-popup>

<bdc-walk-popup #sessionStep8 name="sessionStep8" header="{{'Enable Options' | translate}}" xPosition="after"
	[horizontal]="true" sideNoteText="8/12" buttonText="Next" [showButton]="true" [mustCompleted]="{sessionNext: 7}"
	[onButtonCompleteTask]="{sessionNext: 8}" buttonPreviousText="Previous"
	[onButtonPreviousTask]="{sessionNext: 6, sessionStep7:false}">
	{{'Enable and disable the options for the selected mode in the session' | translate}}
</bdc-walk-popup>

<bdc-walk-popup #sessionStep9 name="sessionStep9" header="{{'Assign Polls and Triggered Content' | translate}}"
	xPosition="after" [horizontal]="true" sideNoteText="9/12" [showButton]="true" buttonText="Next"
	[mustCompleted]="{sessionNext: 8}" [onButtonCompleteTask]="{sessionNext: 9}" buttonPreviousText="Previous"
	[onButtonPreviousTask]="{sessionNext: 7, sessionStep8:false}">
	{{'Assign the polls and triggered content created in the library' | translate}}
</bdc-walk-popup>

<bdc-walk-popup #sessionStep10 name="sessionStep10" header="{{'Add Breakout' | translate}}" xPosition="after"
	[horizontal]="true" sideNoteText="10/12" [showButton]="true" buttonText="Next" [mustCompleted]="{sessionNext: 9}"
	[onButtonCompleteTask]="{sessionNext: 10}" buttonPreviousText="Previous"
	[onButtonPreviousTask]="{sessionNext: 8, sessionStep9:false}">
	{{'Create groups for the breakouts in the session' | translate}}
</bdc-walk-popup>

<bdc-walk-popup #sessionStep11 name="sessionStep11" header="{{'Assign Resources' | translate}}" xPosition="after"
	[horizontal]="true" sideNoteText="11/12" [showButton]="true" [mustCompleted]="{sessionNext: 10}"
	[onButtonCompleteTask]="{sessionNext: 11}" buttonPreviousText="Previous"
	[onButtonPreviousTask]="{sessionNext: 9, sessionStep10:false}">
	{{'Assign resources created' | translate}}
</bdc-walk-popup>

<bdc-walk-popup #sessionStep12 name="sessionStep12" header="{{'List Inserted Components' | translate}}"
	xPosition="after" [horizontal]="true" sideNoteText="12/12" [showButton]="true" [mustCompleted]="{sessionNext: 11}"
	[onButtonCompleteTask]="{sessionNext: 12}" buttonPreviousText="Previous"
	[onButtonPreviousTask]="{sessionNext: 10, sessionStep11:false}">
	{{'View and Select the added components and cameras in the session' | translate}}
</bdc-walk-popup>

<custom-dynamic-dialog #dialogRoomComponents name="dialogRoomComponents" [keyName]="'dialogRoomComponents'"
	width="600px">
	<h1>Build your Stellar Room!</h1>
	<mat-dialog-content>
		<p>
			You can now add different Components to this Room. Select the Component you want to add from the menu
			buttons below.
		</p>
		<p>
			Components added to the Room will appear during all Sessions taking place in this Room.
		</p>
		<p>
			You can use the Size and Position indices for exact size and location. The Z index determines the layer
			level. A Component with higher Z index lay on top of a Component with a lower index.
		</p>
		<p>
			You can add multiple Sessions to a Room. Toggle the 1st button in the menu below to Session Mode.
		</p>
		<p>
			Components added to a Session appear only during that Session. Broadcast Components such as a live camera
			can be added during a session only.
		</p>
		<p>
			You can add, edit or delete a Room or a Session within a Room at any time.
		</p>
	</mat-dialog-content>

	<div class="buttons">
		<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" style="width: 100%;">
			<div fxFlex>
				<mat-checkbox class="example-margin" name="dialogRoomComponentsChk"
					[(ngModel)]="dialogRoomComponentsChk">{{'Do not show again' | translate}}</mat-checkbox>
			</div>
			<div fxFlex>
				<button (click)="handleRoomComponentsClose()" mat-raised-button color="primary"
					style="float:right">{{'Ok' | translate}}</button>
			</div>
		</div>
	</div>
</custom-dynamic-dialog>