export const mounthsHU = [
  { id: 0, name: 'Január' },
  { id: 1, name: 'Február' },
  { id: 2, name: 'Március' },
  { id: 3, name: 'Április' },
  { id: 4, name: 'Május' },
  { id: 5, name: 'Június' },
  { id: 6, name: 'Július' },
  { id: 7, name: 'Augusztus' },
  { id: 8, name: 'Szeptember' },
  { id: 9, name: 'Október' },
  { id: 10, name: 'November' },
  { id: 11, name: 'December' },
];
