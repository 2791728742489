<form #personForm="ngForm" *ngIf="data" class="customDialog">
	<div class="col-xl-12 recurrenceDialog">
		<h1 mat-dialog-title>{{data.title}}</h1>
		<div mat-dialog-content>
			<mat-card>
				<div class="fieldItem" *ngIf="returnData && metaFieldSetting">
					<div fxLayout="row" fxLayoutGap="10px">
						<span fxFlex fxLayoutAlign="space-between center" class="fieldInput">
							<mat-form-field class="repeats">
								<label class="control-label">Repeat:</label>
								<mat-select [(value)]="returnData.repeats" class="repeatsSelect"
									(selectionChange)="onRepeatsChange($event.value)">
									<ng-container *ngFor="let itm of metaFieldSetting['repeats'].enum">
										<ng-container *ngIf="itm.value !== 'hourly'">
											<mat-option *ngIf="itm.value !== 'minutely'" [value]="itm.value">
												{{itm.displayName}}
											</mat-option>
										</ng-container>
									</ng-container>
								</mat-select>
							</mat-form-field>
						</span>
						<div fxFlex *ngIf="returnData.repeats === 'yearly'"></div>
						<span fxFlex fxLayoutAlign="space-between center" class="fieldInput"
							*ngIf="returnData.repeats !== 'yearly'">
							<mat-form-field>
								<label class="control-label">{{'Every' | translate}} *: </label>
								<input matInput type="number" [appRestrictInput]="'.-e'" [required]="true"
									name="interval" (change)="onEveryChange($event)" ng-model="interval" max="999"
									[(ngModel)]="returnData.interval" [errorStateMatcher]="esMatcher">
								<mat-error
									*ngIf="returnData.interval === undefined || returnData.interval === '' || returnData.interval === null">
									{{'Every' | translate}} is required.
								</mat-error>
							</mat-form-field>
						</span>
						<div fxFlex fxLayoutAlign="space-between center" *ngIf="returnData.repeats === 'daily'">day(s)
						</div>
						<div fxFlex fxLayoutAlign="space-between center" *ngIf="returnData.repeats === 'hourly'">hour(s)
						</div>
						<div fxFlex fxLayoutAlign="space-between center" *ngIf="returnData.repeats === 'weekly'">week(s)
						</div>
						<div fxFlex fxLayoutAlign="space-between center" *ngIf="returnData.repeats === 'monthly'">
							month(s)</div>
					</div>
					<div style="margin-bottom: 20px;" *ngIf="returnData.repeats === 'weekly'">
						<mat-button-toggle-group [(ngModel)]="returnData.byweekday" name="weekDays"
							aria-label="weekDays" class="weekDays-group" multiple
							(change)="onByweekdayChange($event.value)">
							<ng-container *ngFor="let itm of metaFieldSetting['byweekday'].enum">
								<mat-button-toggle value="{{itm.value}}" class="weekDays"
									[disabled]="weekOfDay === itm.value">{{itm.displayName}}
								</mat-button-toggle>
							</ng-container>
						</mat-button-toggle-group>
					</div>
					<div *ngIf="returnData.repeats === 'monthly'">
						<div fxLayout="row" fxLayoutGap="10px">
							<div fxFlex="15" fxLayoutAlign="start center">
								<input type="radio" id="rruleMonthlyType1" name="rruleMonthlyType"
									[checked]="returnData.monthlyType === 'dayofmonth'"
									[(ngModel)]="returnData.monthlyType" value="dayofmonth"
									(change)="onMonthlyTypeChange($event.target.value)">
								<label>on day:</label>
							</div>
							<span fxFlex="25" fxLayoutAlign="space-between center" class="fieldInput">
								<mat-form-field class="repeats">
									<label class="control-label"></label>
									<mat-select [(value)]="returnData.bymonthday" class="repeatsSelect"
										(selectionChange)="onChangeAttribute($event)"
										[disabled]="returnData.monthlyType !== 'dayofmonth'">
										<ng-container *ngFor="let itm of metaFieldSetting['bymonthday'].enum">
											<mat-option [value]="itm.value">
												{{itm.displayName}}
											</mat-option>
										</ng-container>
									</mat-select>
								</mat-form-field>
							</span>
						</div>
						<div fxLayout="row" fxLayoutGap="10px">
							<div fxFlex="15" fxLayoutAlign="start center">
								<input type="radio" id="rruleMonthlyType1" name="rruleMonthlyType"
									[checked]="returnData.monthlyType === 'weekdayofmonth'"
									[(ngModel)]="returnData.monthlyType" value="weekdayofmonth"
									(change)="onMonthlyTypeChange($event.target.value)">
								<label>on the:</label>
							</div>
							<span fxFlex="25" fxLayoutAlign="space-between center" class="fieldInput">
								<mat-form-field class="repeats">
									<label class="control-label"></label>
									<mat-select [disabled]="returnData.monthlyType !== 'weekdayofmonth'"
										(selectionChange)="onChangeAttribute($event)" [(value)]="returnData.bysetpos"
										class="repeatsSelect">
										<ng-container *ngFor="let itm of metaFieldSetting['bysetpos'].enum">
											<mat-option [value]="itm.value">
												{{itm.displayName}}
											</mat-option>
										</ng-container>
									</mat-select>
								</mat-form-field>
							</span>
							<span fxFlex="25" fxLayoutAlign="space-between center" class="fieldInput">
								<mat-form-field class="repeats">
									<label class="control-label"></label>
									<mat-select [disabled]="returnData.monthlyType !== 'weekdayofmonth'"
										(selectionChange)="onChangeAttribute($event)" [(value)]="returnData.byday"
										class="repeatsSelect">
										<ng-container *ngFor="let itm of metaFieldSetting['byday'].enum">
											<mat-option [value]="itm.value">
												{{itm.displayName}}
											</mat-option>
										</ng-container>
									</mat-select>
								</mat-form-field>
							</span>
						</div>
					</div>
					<div *ngIf="returnData.repeats === 'yearly'">
						<div fxLayout="row" fxLayoutGap="10px">
							<div fxFlex="15" fxLayoutAlign="start center">
								<input type="radio" id="rruleYearlyType1" name="rruleYearlyType"
									[checked]="returnData.yearlyType === 'dayofmonth'"
									[(ngModel)]="returnData.yearlyType" value="dayofmonth"
									(change)="onYearlyTypeChange($event.target.value)">
								<label>on :</label>
							</div>
							<span fxFlex="20" fxLayoutAlign="space-between center" class="fieldInput">
								<mat-form-field class="repeats">
									<label class="control-label"></label>
									<mat-select [disabled]="returnData.yearlyType !== 'dayofmonth'"
										(selectionChange)="onChangeAttribute($event)" [(value)]="returnData.bymonth"
										class="repeatsSelect">
										<ng-container *ngFor="let itm of metaFieldSetting['bymonth'].enum">
											<mat-option [value]="itm.value">
												{{itm.displayName}}
											</mat-option>
										</ng-container>
									</mat-select>
								</mat-form-field>
							</span>
							<span fxFlex="20" fxLayoutAlign="space-between center" class="fieldInput">
								<mat-form-field class="repeats">
									<label class="control-label"></label>
									<mat-select [disabled]="returnData.yearlyType !== 'dayofmonth'"
										(selectionChange)="onChangeAttribute($event)" [(value)]="returnData.bymonthday"
										class="repeatsSelect">
										<ng-container *ngFor="let itm of metaFieldSetting['bymonthday'].enum">
											<mat-option [value]="itm.value">
												{{itm.displayName}}
											</mat-option>
										</ng-container>
									</mat-select>
								</mat-form-field>
							</span>
						</div>
						<div fxLayout="row" fxLayoutGap="10px">
							<div fxFlex="15" fxLayoutAlign="start center">
								<input type="radio" id="rruleYearlyType1" name="rruleYearlyType"
									[checked]="returnData.yearlyType === 'weekdayofmonth'"
									[(ngModel)]="returnData.yearlyType" value="weekdayofmonth"
									(change)="onYearlyTypeChange($event.target.value)">
								<label>on the:</label>
							</div>
							<span fxFlex="20" fxLayoutAlign="space-between center" class="fieldInput">
								<mat-form-field class="repeats">
									<label class="control-label"></label>
									<mat-select [disabled]="returnData.yearlyType !== 'weekdayofmonth'"
										(selectionChange)="onChangeAttribute($event)" [(value)]="returnData.bysetpos"
										class="repeatsSelect">
										<ng-container *ngFor="let itm of metaFieldSetting['bysetpos'].enum">
											<mat-option [value]="itm.value">
												{{itm.displayName}}
											</mat-option>
										</ng-container>
									</mat-select>
								</mat-form-field>
							</span>
							<span fxFlex="20" fxLayoutAlign="space-between center" class="fieldInput">
								<mat-form-field class="repeats">
									<label class="control-label"></label>
									<mat-select [disabled]="returnData.yearlyType !== 'weekdayofmonth'"
										(selectionChange)="onChangeAttribute($event)" [(value)]="returnData.byday"
										class="repeatsSelect">
										<ng-container *ngFor="let itm of metaFieldSetting['byday'].enum">
											<mat-option [value]="itm.value">
												{{itm.displayName}}
											</mat-option>
										</ng-container>
									</mat-select>
								</mat-form-field>
							</span>
							<div fxFlex="10" fxLayoutAlign="center center">of</div>
							<span fxFlex="20" fxLayoutAlign="space-between center" class="fieldInput">
								<mat-form-field class="repeats">
									<label class="control-label"></label>
									<mat-select [disabled]="returnData.yearlyType !== 'weekdayofmonth'"
										(selectionChange)="onChangeAttribute($event)" [(value)]="returnData.bymonth"
										class="repeatsSelect">
										<ng-container *ngFor="let itm of metaFieldSetting['bymonth'].enum">
											<mat-option [value]="itm.value">
												{{itm.displayName}}
											</mat-option>
										</ng-container>
									</mat-select>
								</mat-form-field>
							</span>
						</div>
					</div>
					<!-- <mat-divider></mat-divider> -->
					<div fxLayout="row" fxLayoutGap="10px">
						<span fxFlex fxLayoutAlign="space-between center" class="fieldInput">
							<mat-form-field class="repeats">
								<label class="control-label">End:</label>
								<mat-select [(value)]="returnData.end" class="repeatsSelect"
									(selectionChange)="onEndChange($event.value)">
									<ng-container *ngFor="let itm of metaFieldSetting['end'].enum">
										<mat-option [value]="itm.value">
											{{itm.displayName}}
										</mat-option>
									</ng-container>
								</mat-select>
							</mat-form-field>
						</span>
						<span *ngIf="returnData.end === 'after'" fxFlex fxLayoutAlign="space-between center"
							class="fieldInput">
							<mat-form-field>
								<label class="control-label">{{'Count' | translate}} *: </label>
								<input matInput type="number" [appRestrictInput]="'.-e'" [required]="true" name="count"
									max="{{maxCount}}" (change)="onCountChange($event)" ng-model="count"
									[(ngModel)]="returnData.count" [errorStateMatcher]="esMatcher">
								<mat-error
									*ngIf="returnData.count === undefined || returnData.count === '' || returnData.count === null">
									{{'Count' | translate}} is required.
								</mat-error>
								<mat-error
									*ngIf="returnData.count !== undefined && returnData.count !== '' && returnData.count !== null && returnData.count > maxCount">
									You can only create events with up to 1000 repetitions. Please change the recurrence
									to continue.
								</mat-error>
							</mat-form-field>
						</span>
						<span *ngIf="returnData.end === 'date'" fxFlex fxLayoutAlign="space-between end"
							class="fieldInput">
							<mat-form-field>
								<input matInput name="until" [required]="true" [(ngModel)]="returnData.untilView"
									ng-model="until" [matDatepicker]="picker" placeholder="{{'Until'| translate}}"
									[min]="currentMinDate" [max]="currentMaxDate"
									(dateChange)="setUntilAttribute($event.value)" [errorStateMatcher]="esMatcher">
								<mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
								<mat-datepicker #picker></mat-datepicker>
								<mat-error *ngIf="returnData.until === undefined || returnData.until === ''">
									{{'Until'| translate}} is required.
								</mat-error>
								<!-- <mat-error
									*ngIf="returnData.untilTotal !== undefined && returnData.untilTotal !== '' && returnData.untilTotal !== null && returnData.untilTotal > 10">
									You can only create events with up to 1000 repetitions. Please change the recurrence
									to continue.
								</mat-error> -->
							</mat-form-field>
						</span>
						<div fxFlex fxLayoutAlign="space-between center"></div>
					</div>
					<!-- <mat-divider></mat-divider> -->
					<div style="margin-top: 20px;">
						{{ruleText}}
					</div>
				</div>
			</mat-card>
		</div>
		<div mat-dialog-actions>
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
			<button mat-raised-button (click)="closeModal(returnData)">{{'Confirm' | translate}}</button>
		</div>
		<br />
	</div>
</form>