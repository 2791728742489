<div class="col-xl-12 panelistDialog" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content >
      <mat-card >
				<mat-form-field style="width: 100%;" >
					<mat-label>{{'Select Panelists' | translate}}</mat-label>
					<mat-select [(value)]="speakerFilter" (selectionChange)="analyzeData($event)" multiple [panelClass]="'matSelectPanel'">
						<input class="matSelectInput" matInput placeholder="{{'Search Panelists' | translate}}" (keyup)="onKey($event.target.value)" >
						<div class="matSelectOptions">
						<!-- <mat-option *ngFor="let itm of leadList" [value]="itm._id" [disabled]="containsLead && !speakerFilter.includes(itm._id)" style="height:2em;line-height:2em">
							{{itm.name}} ({{'Lead' | translate}})
						</mat-option> -->
						<mat-option *ngFor="let itm of leadList" [value]="itm._id" [disabled]="speakerFilter.length >= 12 && !speakerFilter.includes(itm._id)" style="height:2em;line-height:2em">
							{{itm.name}} ({{'Lead' | translate}})
						</mat-option>
						<mat-option *ngFor="let itm of guestsList" [value]="itm._id" [disabled]="speakerFilter.length >= 12 && !speakerFilter.includes(itm._id)" style="height:2em;line-height:2em">
							{{itm.name}} ({{'Guest' | translate}})
						</mat-option>
						</div>
					</mat-select>
					<!-- <mat-hint align="end">You can only select 1 {{'lead' | translate}} and multiple {{'guests' | translate}}</mat-hint> -->
					<mat-hint align="start">{{speakerFilter.length}} selected</mat-hint>
					<mat-hint align="end">You can only select 12 {{'panelists' | translate}}</mat-hint>
				</mat-form-field>
      </mat-card>
	</div>
	<div mat-dialog-actions>
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
			<button mat-raised-button (click)="closeModal(returnData)">{{'Confirm' | translate}}</button>
	</div>
	<br/>
</div>
