import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { LayoutUtilsService } from '../shared/services/utils/layout-utils.service';

@Component({
    selector: 'app-empty',
    templateUrl: './empty.component.html',
})
export class EmptyComponent {
    protected subscriptions: Subscription[] = [];
    constructor(private activatedRoute: ActivatedRoute, private layoutUtilsService: LayoutUtilsService, private translate: TranslateService) {
    }
    ngOnInit() {
        // this.subscriptions.push(
        //     this.activatedRoute.params.subscribe(params => {
        //         console.log('params', params);
        //         if (params.hasOwnProperty('action')) {
        //             if(params.action === 'close'){
        //                 setTimeout(()=>{
        //                     this.alreadyLoggedIn('', 'Stellar is open in another tab. Switch to the other tab and close here.')
        //                 }, 200)
        //             }
        //         }
        //     })
        // );
    }
    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }
    alreadyLoggedIn(title: string, msg: string) {
        let alertSetting = {};
        alertSetting['overlayClickToClose'] = false;
        alertSetting['showCloseButton'] = false;
        // alertSetting['confirmText'] = this.translate.instant('Login in here instead');
        // alertSetting['declineText'] = this.translate.instant('Cancel');
        // alertSetting['timerEvent'] = 120;

        const dialogRef = this.layoutUtilsService.alertActionElement(title, msg, alertSetting, '500px');
        dialogRef.afterClosed().subscribe(res => {
            if (res) {
                // if (res.action === 'confirmText') {

                // }
            }
        });
    };
}
