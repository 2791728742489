// Angular
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
declare var CKEDITOR;

@Component({
	selector: 'kt-confirm-ckeditor-entity-dialog',
	templateUrl: './confirm-ckeditor-entity-dialog.component.html',
	styleUrls: ['../modal.scss']
})
export class ConfirmCkeditorEntityDialogComponent implements OnInit {
	isReady: boolean = false;
	/**
	 * Component constructor
	 *
	 * @param dialogRef: MatDialogRef<DeleteEntityDialogComponent>
	 * @param data: any
	 */
	editorConfig = undefined;
	widget_Buttons_map = ['dial', 'email'];
	constructor(
		public dialogRef: MatDialogRef<ConfirmCkeditorEntityDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: any
	) {
		if (data.hasOwnProperty('config')) {
			this.editorConfig = data.config;
		} else {
			this.editorConfig = {
				toolbar: [
					// { name: 'document', items: ['Source', '-'] },
					{ name: 'clipboard', items: ['Undo', 'Redo', '-'] },
					{ name: 'basicstyles', items: ['Bold', 'Italic', 'Underline', 'Strike', '-'] },
					{ name: 'links', items: ['Link', 'Unlink', '-'] },
					{ name: 'styles', items: ['Styles', 'Format', 'Font', 'FontSize', '-'] },
					{ name: 'colors', items: ['TextColor', 'BGColor'] },
					{ name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl'] },
				],
				height: '310px',
				shiftEnterMode: CKEDITOR.ENTER_DIV,
				enterMode: CKEDITOR.ENTER_DIV,
				removePlugins: 'magicline',
				linkShowAdvancedTab: false,
				linkShowTargetTab: false,
				resize_enabled: false,
				versionCheck: false
			};
		}
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
	}

	/**
	 * Close dialog with false result
	 */
	onNoClick(): void {
		this.dialogRef.close();
	}

	/**
	 * Close dialog with true result
	 */
	onYesClick(): void {
		this.dialogRef.close(this.data.data);
	}

	public onChange(data) {
		this.data.data = data;
	}
}
