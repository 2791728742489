import { Component, OnInit, Input, Output, EventEmitter, Renderer2, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { LoaderService } from '../../../services/loader.service';
import { LayoutComponents } from '../components';
import { DefaultSettings } from '../defaultSettings';

import { roundDecimal } from '../../../../shared/helpers';

@Component({
  selector: 'app-screen-layout-preview',
  templateUrl: './screen-layout-preview.component.html',
  styleUrls: ['./screen-layout-preview.component.scss']
})
export class ScreenLayoutPreviewComponent implements OnInit {
  public subscriptions: any[] = <any>[];
  public defaultSetting = DefaultSettings.defaultSetting;
  public errorMessage: string = '';
  public loading: boolean = false;

  public componentsType: any = DefaultSettings.componentsType;
  public cameraComponents: string[] = DefaultSettings.cameraComponents;
  public tileComponents: string[] = DefaultSettings.tileComponents;
  public componentsName: any = LayoutComponents.componentsName;
  public componentsNameKeys: any = LayoutComponents.componentsNameKeys;
  public videoComponentTypes: any[] = DefaultSettings.videoComponentTypes;

  public scale = 2;

  public resizingObjectX: any = {};
  public resizingObjectY: any = {};

  @Input() settingObject: any = undefined;
  constructor(public renderer: Renderer2, private changeDetectorRef: ChangeDetectorRef, private loaderService: LoaderService) { }

  ngOnInit() {
    console.log('settingObject', this.settingObject);
  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  getHeight(h, w, ratioSize, notFixedRatio = false) {
    // console.log('notFixedRatio', notFixedRatio);
    if (ratioSize && !notFixedRatio) {
      return roundDecimal((Number(w) * ratioSize.h) / ratioSize.w, 2);
    } else {
      return h;
    }
  }
  getX(x, mainBoundary, idx) {
    let currentValue = Math.round((x * mainBoundary.offsetWidth) / 100);
    if(!this.resizingObjectX[idx]){
        this.resizingObjectX[idx] = currentValue;
    }
    return this.resizingObjectX[idx];
  }
  onResize(e) {
    this.resizingObjectX = {};
    this.resizingObjectY = {};
  }

  getY(y, mainBoundary, idx) {
    let currentValue = Math.round((y * mainBoundary.offsetWidth) / 100);
    if(!this.resizingObjectY[idx]){
        this.resizingObjectY[idx] = currentValue;
    }
    return this.resizingObjectY[idx];
  }
}
