<div class="col-xl-12" *ngIf="data">
	<h1 mat-dialog-title>{{data.title}}</h1>
	<div mat-dialog-content>
		<app-tiles-list #tileList (selectItem)="selectItemCheck($event)" [hasCategories]="true" [canDelete]="false"
			[hideTitle]="true" [returnData]="returnData">
		</app-tiles-list>
	</div>
	<div mat-dialog-actions>
		<div *ngIf="addNote" class="tileNote">{{addNote}}</div>
		<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
		<button mat-raised-button *ngIf="!data.hideClear" (click)="closeModal({clear: true})">{{'Clear' |
			translate}}</button>
		<button mat-raised-button (click)="closeModal({new: true})">{{'Add New Tile' | translate}}</button>
	</div>
	<br />
</div>