import { Component, Inject, NgZone, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CustomTileViewComponent } from '../custom-tile-view/custom-tile-view.component';
import { environment } from '../../../../environments/environment';

interface ViewDialogData {
	title: string;
	data: any;
	readonly: boolean;
}

@Component({
	selector: 'app-tile-dialog-modal',
	templateUrl: './custom-tile-dialog.component.html',
  styleUrls: ['./custom-tile-dialog.component.scss']
})
export class ModalTileDialogComponent {
	public errorMessage: string = '';
	public title: string = '';
	public readonly: boolean = true;
  public loading: boolean = false;

	@ViewChild('customtileview') customtileview: CustomTileViewComponent;
	constructor(private zone: NgZone,
		public dialogRef: MatDialogRef<ModalTileDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
			// console.log('UserViewDialogData', data);
			this.title = this.data.title;
			this.readonly = this.data.readonly;
	}
	ngOnInit() {

	}
  setAction(data): void {
		if(data.action === 'hideSubmit'){
			this.readonly = true;
		}
  }
  setTitle(data): void {
		this.title = data;
  }
  closeModal(data): void {
		   this.dialogRef.close(data);
  }
  submitData(): void {
		this.customtileview.submitData();
  }
}
