<app-topnav *ngIf="errorType == 'notvalidlink'"></app-topnav>
<div class="mb-20" flFlex flexLayout="row" flexLayout.lt-md="column">
    <div fxFlex class="errorMessageParent">
        <div class="errorMessage">
            <h1 *ngIf="errorType !== 'notvalidlink' && errorType !== 'notfound'">{{errorType}}</h1>
            <img src="./assets/images/logo-loader.png" />
            <h4 *ngIf="errorType !== '404' && errorType !== 'notvalidlink' && errorType !== 'notfound'">You are
                unauthorized to view this page.<br>
                To access it, please login first.
            </h4>
            <h4 *ngIf="errorType === '404' || errorType === 'notfound'">Page Not Found
            </h4>
            <h4 *ngIf="errorType === 'notvalidlink'">This link refers to content that does not exist. Please use a valid
                link.
            </h4>
            <button *ngIf="errorType !== 'notvalidlink' && errorType !== 'notfound'" mat-raised-button color="danger"
                (click)="goToLogin()"><span>Login Page</span></button>
        </div>
    </div>
</div>