<div>
  <h1 mat-dialog-title *ngIf="data.title && data.type.toLowerCase() !== 'com'">{{data.title}}</h1>
  <div mat-dialog-content style="height: 100%;">
    <div *ngIf="loading" fxLayout="column" class="loading" fxLayoutAlign="center center">
      <mat-spinner color="primary" diameter="50"></mat-spinner>
    </div>
    <ng-container *ngIf="data.type.toLowerCase() === 'com' && adDetails && !loading">
      <div fxLayout="column" fxLayoutGap="0.5vw">
        <div fxLayout="row" fxFlex fxLayoutGap="0.5vw">
          <div fxFlex="60" fxLayoutAlign="center center">
            <audio *ngIf="adDetails.placeholder?.type === 'audio'" controls="controls" controlsList="nodownload"
              [autoplay]="false" [loop]="false">
              <source [src]="adDetails.placeholder.source"
                *ngIf="adDetails.placeholder?.source.toLowerCase().indexOf('.mp3') !== -1" type="audio/mpeg" />
              <source [src]="adDetails.placeholder.source"
                *ngIf="adDetails.placeholder?.source.toLowerCase().indexOf('.wav') !== -1" type="audio/wav" />
              <source [src]="adDetails.placeholder.source"
                *ngIf="adDetails.placeholder?.source.toLowerCase().indexOf('.ogg') !== -1" type="audio/ogg" />
              <source [src]="adDetails.placeholder.source"
                *ngIf="adDetails.placeholder?.source.toLowerCase().indexOf('.aac') !== -1" type="audio/aac" />
            </audio>
            <iframe *ngIf="adDetails.placeholder?.type === 'video'" [src]="adDetails.placeholder.source | safe"
              style="border: none;" width="470px" height="290px"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen></iframe>
            <img *ngIf="adDetails.placeholder?.type === 'image'" class="top-image" [src]="adDetails.placeholder.source"
              [title]="adDetails.title" />
          </div>
          <div fxFlex fxLayoutAlign="start center" *ngIf="banner.length">
            <a [href]="banner[selectedBannerIndex].website" target="_blank">
              <img class="banner-image" [src]="banner[selectedBannerIndex].image"
                [title]="banner[selectedBannerIndex].title" />
            </a>
          </div>
        </div>
        <div fxLayout="row" fxFlex fxLayoutGap="0.5vw" *ngIf="!showCoupon">
          <div fxFlex fxGrow="0">
            <img *ngIf="adDetails.logo" class="main-image" [src]="adDetails.logo" [title]="adDetails.title" />
            <div *ngIf="adDetails.website && adDetails.website !== 'null' && adDetails.clickable === 'WS'"
              class="website" (click)="openWebsite(adDetails.website)">
              {{'Visit Website' | translate}}
            </div>
          </div>
          <div fxFlex class="ad-details">
            <div class="shortcut" fxLayout="row" fxLayoutGap="0.5vw" *ngIf="adDetails.video || adDetails.audio">
              <div fxFlex fxGrow="0" *ngIf="adDetails.video" (click)="showItem(adDetails.video, 'video')">{{'Video' |
                translate}}</div>
              <div fxFlex fxGrow="0" *ngIf="adDetails.audio" (click)="showItem(adDetails.audio, 'audio')">{{'Audio' |
                translate}}</div>
            </div>
            <div class="title" *ngIf="adDetails.title">{{adDetails.title}}</div>
            <div class="tagline" *ngIf="adDetails.tagline">{{adDetails.tagline}}</div>
            <div class="phone" *ngIf="adDetails.phone">{{adDetails.phone}}</div>
            <mat-icon (click)="openWebsite(adDetails.map)" class="location">place</mat-icon>
            <div class="coupon" fxLayout="row" *ngIf="adDetails.coupon" (click)="toggleCoupon()">
              <div fxFlex fxGrow="0" class="text">{{'Coupon' | translate}}</div>
              <div class="arrow" fxFlex fxGrow="0"></div>
            </div>
            <div class="images">
              <ng-container *ngFor="let image of adDetails.images">
                <img *ngIf="image" [src]="image" (click)="showItem(image, 'image')" />
              </ng-container>
            </div>
          </div>
        </div>
        <div fxFlex class="coupon-details" *ngIf="showCoupon" fxLayout="column" fxLayoutAlign="center center">
          <mat-icon class="close" (click)="toggleCoupon()">highlight_off</mat-icon>
          <div fxFlex class="coupon-actions-container">
            <div class="coupon-actions">
              <mat-form-field appearance="fill">
                <mat-label>{{'Email' | translate}}</mat-label>
                <input type="email" matInput [formControl]="emailFormControl" placeholder="Ex. pat@example.com">
                <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">
                  {{'Please enter a valid email address' | translate}}
                </mat-error>
                <mat-error *ngIf="emailFormControl.hasError('required')">
                  Email is <strong>required</strong>
                </mat-error>
              </mat-form-field>
              <button mat-raised-button (click)="emailCoupon()">{{'Email Coupon' |
                translate}}</button>
              <button mat-raised-button (click)="printCoupon()">{{'Print Coupon' |
                translate}}</button>
            </div>
          </div>
          <div id="couponImage" fxFlex>
            <img [src]="adDetails.coupon" />
          </div>
        </div>
        <div fxFlex *ngIf="!showCoupon">
          {{adDetails.description}}
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="data.type.toLowerCase() !== 'com'">
      <div class="quu-video">
        <app-youtube-media [uuid]="data.id" *ngIf="data.id"></app-youtube-media>
      </div>
      <img src="./assets/images/quu-no-video.png" *ngIf="!data.id" />
    </ng-container>
  </div>
  <div mat-dialog-actions>
    <button mat-raised-button color="danger" (click)="closeModal()">{{'Close' |
      translate}}</button>
  </div>
</div>