<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" *ngIf="settingObject">
  <div fxFlex>
    <div fxFlex *ngFor="let col of settingObject['columns'];let index = index" class="example-list">
      <div class="overlayLayout example-boundary" #mainBoundary  (window:resize)="onResize($event)">
          <div  id="rowsmenu{{index}}" name="rowsmenu" class="example-box-row">
            <div class="example-box" *ngFor="let cmp of col['components'];let subIndex = index"
            [ngStyle]="{'z-index': cmp.z, 'height' : getHeight(cmp.h*scale,cmp.w*scale, componentsName[cmp.name].rules.ratioSize, cmp['notFixedRatio']) + 'px', 'width' : cmp.w* scale+'px', 'backgroundColor': '#f2f2f2', left: cmp.x * scale+ 'px', top: cmp.y * scale+ 'px'}"
            [class.cameraToggleComponent]="cmp.name === 'screenshare'"
            [class.cameraMainComponent]="cmp.name === 'presenter-camera'"
            [class.cameraComponent]="cameraComponents.includes(cmp.name) && cmp.name !== 'panelView' && cmp.name !== 'presenter-camera' && cmp.name !== 'screenshare'"
            [class.audioComponent]="cmp.name === 'audio'" [class.partystreaming]="cmp.name === 'defaultvideo'"
            [class.videoLibrary]="cmp.name === 'videolibrary'"
            [class.example-box-transparent]="cmp.name === 'panelView' || cmp['name'] === 'audienceView'"
            [class.imagegallery]="cmp.name === 'imagegallery'"
            [class.editorClass]="cmp.name === 'editor' || cmp.name === 'empty-click' || cmp.name === 'picturewall' || cmp['name'] === 'audienceView' "
            [class.circleShape]="cmp.name === 'empty-click' && cmp.shape === 'circle'"
            >
            
            </div>
          </div>
      </div>
    </div>
  </div>
</div>