import { Component, OnInit, Input, Output, EventEmitter, HostListener, TemplateRef, ElementRef, ViewChild } from '@angular/core';
import { EventData } from './interface/event-data';
import { yearsLabel } from './locale/years';
import { formatDate } from '@angular/common';
import { mounthsHU } from './locale/monthsHU';
import { weekdayHU } from './locale/weekdaysHU';
import { mounthsEN } from './locale/monthsEN';
import { weekdayEN } from './locale/weekdaysEN';
import { hoursLabel } from './locale/hours';
import { Day } from './interface/day';
import { trigger, transition, style, animate } from '@angular/animations';
import * as moment from 'moment';
import { utc } from 'moment';

declare var rrule: any;

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  animations: [
    trigger(
      'enterAnimation', [
      transition(':enter', [
        style({ transform: 'translateX(100%)', opacity: 0 }),
        animate('250ms', style({ transform: 'translateX(0)', opacity: 1 }))
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)', opacity: 1 }),
        animate('250ms', style({ transform: 'translateX(100%)', opacity: 0 }))
      ])
    ]
    )

  ]
})
export class CalendarComponent implements OnInit {

  isSmall = false; // not supported

  // currentTzOffset = moment().utcOffset();
  today = new Date();
  currentMonth;
  currentYear;
  currentWeek;
  currentWeekView;
  selectedDay: Day;
  currentDay: Day;
  currentWeekData: Day[];
  currentListWeekData: Day[];
  currentListDayData: Day;
  firstDay;
  daysInMonth;
  daysInLastMonth;
  actDay;
  lastMonth;
  actMonth;
  hours: any[] = hoursLabel;
  months: any;
  weekdaysObject: any[] = [];
  weekdays: any;
  years = [];
  bufferYears = 10;
  actFullDate;
  actDate: any;
  actWeekDate: any;
  arrTest = [];
  arrCalendar = [];
  arrListCalendar = [];
  eventsData: any = [];
  eventsSkelletonData: any = [];
  actYear;
  hoverEvent: any = {};
  hoverColumnEvent: any = {};
  showChangeDate = false;
  btnAddShow: boolean;
  status: string = undefined;
  @Input() defaultColors: any = {
    default: 'white',
    selectedDay: '#7b80a536',
    availability: '#d5f0ff',
    eventBg: '#242852',
    eventFont: 'white',
  }
  private viewModes: any = {
    month: 'Month',
    week: 'Week',
    day: 'Day',
  }
  private viewDesignModes: any = {
    calendar_month: 'Calendar',
    format_list_bulleted: 'List',
  }
  utc = utc;
  private previosDays = [6, 0, 1, 2, 3, 4, 5];// Week day 1 start monday
  @Input() language: string = 'en';
  @Input() startWeekDay: number = 1; // Week day 1 start monday {0: sunday, 1:monday, 2: tuesday, 3: wednesday, 4: thursday, 5: friday, 6: saturday}
  @Input() dayAvailabilityActions: any[] = []; // {value: 'export', displayName: 'Export'}
  @Input() dataTypeDisplay: string = 'Event';
  @Input() availabilityBtn: string = 'My Availability';
  @Input() isMobile: boolean = false;
  @Input() isTablet: boolean = false;
  @Input() showToolTip: boolean = true;
  @Input() eventsSelectable: boolean = true;
  @Input() eventsAvlbSelectable: boolean = true;
  @Input() anchorDate = new Date();
  @Input() viewMode = 'month';
  @Input() viewDesignMode = 'calendar_month';
  @Input() viewModesArray: any[] = Object.keys(this.viewModes);
  @Input() viewDesignModesArray: any[] = Object.keys(this.viewDesignModes);
  @Input() canAddEvent: boolean = false;
  @Input() eventsActions: any[] = []; // {value: 'occurrence', displayName: 'Event Occurrence'}
  public _dataSource: any = { events: <EventData[]>[], skelleton: <EventData[]>[], targetDay: undefined };
  @Input() showAddButton: boolean;
  @Input() menuIcon: string = 'add';
  @Input() showSelectedDay: boolean = false;
  @Input() showDesignModeOptions: boolean = true;
  @Input() enableAvailability: boolean = false;
  @Input() myAvailability: boolean = false;
  @Input() newDesign: boolean = false;
  @Input() bodyHeight: string = undefined;
  @Input() selectedFilter: string = undefined;
  @Input() blueprintDurationBreak: any = undefined;
  // @Input() blueprintDurationBreak: any = {
  //   name: 'maximum-appointment-time',
  //   durations: {
  //     1: 15,
  //     2: 30,
  //     3: 60,
  //     4: 90,
  //     5: 120
  //   },
  //   durationUnit: 'minutes'
  // };
  @Input() contentListTemplate: TemplateRef<any>;
  @Input() contentDataTemplate: TemplateRef<any>;
  @Input() contentTemplate: TemplateRef<any>;
  @Input() hourToScrollTo: string = undefined;
  @Input() dateFormat: string = 'dd-MM-yyyy';
  @Output() dayEvents = new EventEmitter();
  @Output() dayEvent = new EventEmitter();
  @Output() newEvent = new EventEmitter();
  @Output() dayShift = new EventEmitter();

  @Input()
  set dataSource(dataSource: any) {
    this._dataSource = dataSource;
    if (!dataSource.notInitChanges) {
      this.initChanges();
    }
  }
  get dataSource(): any {
    return this._dataSource;
  }
  @ViewChild('targetCalendarElement') targetCalendarElementRef!: ElementRef;
  @ViewChild('cont') cont!: ElementRef;
  constructor() {
    this.currentMonth = this.anchorDate.getMonth();
    this.currentYear = this.anchorDate.getFullYear();
    this.currentDay = this.getDay(Number(formatDate(this.anchorDate, 'dd', 'en')), this.currentMonth, this.currentYear);
    this.selectedDay = this.getDay(Number(formatDate(this.anchorDate, 'dd', 'en')), this.currentMonth, this.currentYear);
    this.loadYears(this.currentYear);
  }
  loadYears(centerYear: number) {
    this.years = [];
    for (let i = -this.bufferYears; i <= this.bufferYears; i++) {
      this.years.push(centerYear + i);
    }
  }
  onYearSelected(year: number) {
    this.loadMoreYears(year);
  }
  loadMoreYears(selectedYear: number) {
    this.addYearsForward(selectedYear + 1, this.bufferYears);
    this.addYearsBackward(selectedYear - 1, this.bufferYears);
  }

  addYearsForward(startYear: number, count: number) {
    for (let i = 0; i < count; i++) {
      const yearToAdd = startYear + i;
      if (!this.years.includes(yearToAdd)) {
        this.years.push(yearToAdd);
      }
    }
    this.years.sort((a, b) => a - b); // Ensure the years are sorted
  }

  addYearsBackward(startYear: number, count: number) {
    for (let i = 0; i < count; i++) {
      const yearToAdd = startYear - i;
      if (!this.years.includes(yearToAdd)) {
        this.years.unshift(yearToAdd);
      }
    }
    this.years.sort((a, b) => a - b); // Ensure the years are sorted
  }
  ngOnInit() {
    this.changeLanguage();
    this.actFullDate = formatDate(this.anchorDate, 'yyyy MMMM dd - EEEE', 'en');
    this.actDate = formatDate(this.anchorDate, 'yyyy MMMM', 'en');
    this.actWeekDate = formatDate(this.anchorDate, 'yyyy MMMM', 'en');
    this.actDay = formatDate(this.anchorDate, 'dd', 'en');
    this.actMonth = formatDate(this.anchorDate, 'MM', 'en');
    this.actYear = formatDate(this.anchorDate, 'yyyy', 'en');
    this.eventsData = this.dataSource.events;
    this.eventsSkelletonData = this.buildSkelletonEvents(this.dataSource.skelleton);
    this.btnAddShow = this.showAddButton;
    this.dayShift.emit({ currentDay: { month: this.currentMonth, year: this.currentYear, day: this.currentDay.day }, viewMode: this.viewMode, currentWeek: this.currentWeek, status: 'init', selectedDay: this.selectedDay });
    this.initChanges();
  }
  ngAfterViewInit(): void {
    // const height = document.getElementById('cont').offsetHeight;
    const width = this.cont.nativeElement.offsetWidth;

    // // TODO: if small only show badges not all the events
    setTimeout(() => {
      // if (height <= 500 || width <= 769) {
      if (width <= 769) {
        this.isSmall = true;
      } else {
        this.isSmall = false;
      }
    }, 300);

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    // const height = document.getElementById('cont').offsetHeight;
    const width = this.cont.nativeElement.offsetWidth;
    // if (height <= 500 || width <= 600) {
    if (width <= 600) {
      this.isSmall = true;
    } else {
      this.isSmall = false;
    }
  }

  // ngOnChanges() {
  //   // console.log('this.dataSource', this.dataSource);
  //   this.eventsData = this.dataSource.events;
  //   this.eventsSkelletonData = this.buildSkelletonEvents(this.dataSource.skelleton);
  //   this.changeLanguage();
  //   this.createCalendar();
  // } 
  initChanges() {
    // console.log('initChanges', this.dataSource);
    this.eventsData = this.dataSource.events;
    this.eventsSkelletonData = this.buildSkelletonEvents(this.dataSource.skelleton);
    this.createCalendar();
    let currentTargetDay = this.currentDay.day;
    let currentTargetMonth = this.currentDay.month;
    let currentTargetYear = this.currentDay.year;
    let currentTargetHour = moment().hour();
    if (this.dataSource.focusDay) {
      let currentTargetDate: any = new Date(this.dataSource.focusDay.startdate);
      currentTargetDay = currentTargetDate.getDate();
      currentTargetMonth = currentTargetDate.getMonth();
      currentTargetYear = currentTargetDate.getFullYear();
      currentTargetHour = currentTargetDate.getHours();
    }
    if (this.dataSource.goToDayMonthBluePrint) {
      let targetDay = this.getNearestBPDay(this.eventsSkelletonData);
      if (targetDay) {
        let currentTargetDate: any = new Date(targetDay.startdate);
        currentTargetDay = currentTargetDate.getDate();
        currentTargetMonth = currentTargetDate.getMonth();
        currentTargetYear = currentTargetDate.getFullYear();
        currentTargetHour = currentTargetDate.getHours();
        this.goToDayMonth(currentTargetMonth, currentTargetYear);
      }
    }
    if (this.dataSource.goToDayMonth) {
      this.goToDayMonth(currentTargetMonth, currentTargetYear);
    }
    setTimeout(() => {
      if (this.viewMode !== 'month') {
        this.focusToHour(currentTargetDay, currentTargetHour);
      } else {
        let currentMonth = this.today.getMonth();
        let currentYear = this.today.getFullYear();
        let currentDay = this.getDay(Number(formatDate(this.today, 'dd', 'en')), currentMonth, currentYear);
        if (currentDay.day == currentTargetDay && currentMonth == currentTargetMonth && currentYear == currentTargetYear) {
          this.focusToDay(currentTargetDay, currentTargetMonth, currentTargetYear);
        }
      }
    }, 1000);
  }
  getNearestBPDay(eventsData) {
    let targetDay = undefined;
    if (eventsData.length > 0) {
      let eventsDataList = JSON.parse(JSON.stringify(eventsData));
      let diffdate: any = new Date();
      let beforedates = eventsDataList.filter((d) => {
        let currentDate: any = new Date(d.startdate);
        return currentDate - diffdate < 0;
      });
      let afterdates = eventsDataList.filter((d) => {
        let currentDate: any = new Date(d.startdate);
        return currentDate - diffdate >= 0;
      });
      if (afterdates.length > 0) {
        targetDay = afterdates[0];
      } else if (beforedates.length > 0) {
        targetDay = beforedates[beforedates.length - 1];
      }
    }
    return targetDay;
  }
  getCurrentEndDate(firstMoment, minutes) {
    firstMoment.add(minutes, 'minutes');
    return firstMoment;
  }
  buildSkelletonEvents(skelletonData) {
    let skelletonDataTemp = [];
    // let skelletonDataTemp = JSON.parse(JSON.stringify(skelletonData));
    // skelletonDataTemp = skelletonDataTemp.filter((itm) => !itm.isRecurrence || !itm.recurrence);
    for (let itm of skelletonData) {
      try {
        let startdate = moment(itm["startdate"]).utc();
        let endEventdate = moment(itm["enddate"]).utc();
        let removedBlueprintsDates = [];
        if (itm.hasOwnProperty('removed-blueprints-dates') && itm['removed-blueprints-dates'].length > 0) {
          removedBlueprintsDates = itm['removed-blueprints-dates'].map((itmDate) => {
            return moment(itmDate).utc();
          });
        }
        if (itm.isRecurrence && itm.recurrence) {
          //itm.recurrence['startdate'] = itm["startdate"]; //we add this to force start date to be taken from event in case it didn't
          let ruleTextAll = this.getRecurrenceDatas(itm.recurrence, startdate.toDate(), endEventdate.toDate()).all;
          let minutes = endEventdate.diff(startdate, 'minutes');
          if (ruleTextAll.length > 0) {
            // ruleTextAll.shift();
            for (let date of ruleTextAll) {
              if (this.blueprintDurationBreak && this.blueprintDurationBreak['name'] && itm.hasOwnProperty(this.blueprintDurationBreak['name']) && itm[this.blueprintDurationBreak['name']] && itm[this.blueprintDurationBreak['name']] !== '') {
                let optionSelected = itm[this.blueprintDurationBreak['name']];
                let durationUnit = this.blueprintDurationBreak['durationUnit'];
                let durationSplit = this.blueprintDurationBreak['durations'][optionSelected];
                let startEventdatePer = moment(date).utc();
                let endEventdateCurrent = this.getCurrentEndDate(moment(date).utc(), minutes);
                let endEventdatePer = moment(date).utc();
                endEventdatePer.add(durationSplit, durationUnit);
                while (!startEventdatePer.isSame(endEventdatePer)) {
                  // console.log(startEventdatePer, endEventdatePer)
                  if (this.blueprintReproduce(startEventdatePer, removedBlueprintsDates)) {
                    let eventObj = Object.assign({}, itm);
                    eventObj["startdate"] = startEventdatePer.format('YYYY-MM-DDTHH:mm');
                    eventObj["enddate"] = endEventdatePer.format('YYYY-MM-DDTHH:mm');
                    skelletonDataTemp.push(eventObj);
                  }
                  startEventdatePer = endEventdatePer.clone();
                  endEventdatePer = endEventdatePer.clone().add(durationSplit, durationUnit);
                  let minutesPer = endEventdatePer.diff(endEventdateCurrent, 'minutes');
                  if (minutesPer > 0) {
                    endEventdatePer = endEventdateCurrent;
                  }
                }
              } else {
                let startEventdatePer = moment(date).utc();
                if (this.blueprintReproduce(startEventdatePer, removedBlueprintsDates)) {
                  let endEventdatePer = moment(date).utc();
                  endEventdatePer.add(minutes, 'minutes');
                  let eventObj = Object.assign({}, itm);
                  eventObj["startdate"] = startEventdatePer.format('YYYY-MM-DDTHH:mm');
                  eventObj["enddate"] = endEventdatePer.format('YYYY-MM-DDTHH:mm');
                  skelletonDataTemp.push(eventObj);
                }
              }
            }
          }
        } else {
          // itm[this.blueprintDurationBreak['name']] = 2;// added this for testing
          if (this.blueprintDurationBreak && this.blueprintDurationBreak['name'] && itm.hasOwnProperty(this.blueprintDurationBreak['name']) && itm[this.blueprintDurationBreak['name']] && itm[this.blueprintDurationBreak['name']] !== '') {
            let optionSelected = itm[this.blueprintDurationBreak['name']];
            let durationUnit = this.blueprintDurationBreak['durationUnit'];
            let durationSplit = this.blueprintDurationBreak['durations'][optionSelected];
            let startEventdatePer = moment(itm["startdate"]).utc();
            let endEventdatePer = moment(itm["startdate"]).utc();
            endEventdatePer.add(durationSplit, durationUnit);
            while (!startEventdatePer.isSame(endEventdatePer)) {
              if (this.blueprintReproduce(startEventdatePer, removedBlueprintsDates)) {
                let eventObj = Object.assign({}, itm);
                eventObj["startdate"] = startEventdatePer.format('YYYY-MM-DDTHH:mm');
                eventObj["enddate"] = endEventdatePer.format('YYYY-MM-DDTHH:mm');
                skelletonDataTemp.push(eventObj);
              }
              startEventdatePer = endEventdatePer.clone();
              endEventdatePer = endEventdatePer.clone().add(durationSplit, durationUnit);
              let minutesPer = endEventdatePer.diff(endEventdate, 'minutes');
              if (minutesPer > 0) {
                endEventdatePer = moment(itm["enddate"]).utc();
              }
            }
          } else {
            if (this.blueprintReproduce(startdate, removedBlueprintsDates)) {
              skelletonDataTemp.push(itm);
            }
          }
        }
      } catch (e) {
        console.log('setRuleText', e);
      }
    }
    // console.log('skelletonDataTemp', skelletonDataTemp);
    return skelletonDataTemp;
  }
  blueprintReproduce(startdate, removedBlueprintsDates) {
    let status = false;
    if (removedBlueprintsDates.length > 0) {
      let remainingRep = removedBlueprintsDates.filter((itmDate) => {
        if (startdate.isSame(itmDate)) {
          return true;
        } else {
          return false;
        }
      })
      if (remainingRep.length === 0) {
        status = true;
      }
    } else {
      status = true;
    }
    return status;
  }
  getDay(day, month, year, event = [], availability = []) {
    return {
      day: day,
      month: month,
      year: year,
      events: event,
      availability: availability,
      eventsView: [],
      availabilityView: []
    }
  }
  goToToday() {
    this.currentMonth = this.today.getMonth();
    this.currentYear = this.today.getFullYear();

    this.currentDay = this.getDay(Number(formatDate(this.today, 'dd', 'en')), this.currentMonth, this.currentYear);
    // this.currentWeek = this.getWeekIndex(this.currentDay);
    this.currentWeek = undefined;
    this.createCalendar();
    this.selectedDay = JSON.parse(JSON.stringify(this.currentDay));
    this.dayShift.emit({ currentDay: { month: this.currentMonth, year: this.currentYear, day: this.currentDay.day }, viewMode: this.viewMode, currentWeek: this.currentWeek, status: 'change', selectedDay: this.selectedDay });

    if (this.viewMode !== 'month') {
      let currentTargetHour = moment().hour();
      this.focusToHour(this.currentDay.day, currentTargetHour);
    } else {
      this.focusToDay(this.currentDay.day, this.currentDay.month, this.currentDay.year);
    }
  }

  focusToDay(day, month, year) {
    if (this.targetCalendarElementRef) {
      const elementInsideTarget = this.targetCalendarElementRef.nativeElement.querySelector('#cell-item-' + day + '-' + month + '-' + year);
      if (elementInsideTarget) {
        elementInsideTarget.scrollIntoView({ behavior: 'smooth' });
      }
    }

  }
  focusToHour(day, currentTargetHour) {
    setTimeout(() => {
      if (this.targetCalendarElementRef) {
        let hour = currentTargetHour.toString().padStart(2, '0');
        if (this.hourToScrollTo && this.hourToScrollTo !== '') {
          hour = this.hourToScrollTo;
        }
        const elementInsideTarget = this.targetCalendarElementRef.nativeElement.querySelector('#hr-cell-item-' + day + '-' + hour);
        if (elementInsideTarget) {
          elementInsideTarget.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, 500);
  }
  viewModeChange(val) {
    setTimeout(() => {
      let currentMonth = this.today.getMonth();
      let currentYear = this.today.getFullYear();
      if (val !== 'month') {
        let currentDay = this.today.getDate();
        if (val === 'day') {
          if (currentDay == this.currentDay.day && currentMonth == this.currentDay.month && currentYear == this.currentDay.year) {
            let currentTargetHour = moment().hour();
            this.focusToHour(this.currentDay.day, currentTargetHour);
          } else {
            // this.currentDay = this.getDay(1, this.currentMonth, this.currentYear);
            // this.currentWeek = 0; 
            // this.createCalendar();
            // this.dayShift.emit({ currentDay: { month: this.currentMonth, year: this.currentYear, day: this.currentDay.day }, viewMode: this.viewMode, currentWeek: this.currentWeek, status: 'change' });
            // let currentTargetHour = moment().hour();
            // // this.focusToHour(this.currentDay.day, currentTargetHour);
          }
        } else if (val === 'week') {
          let currentWeekDay = this.getDay(currentDay, this.currentMonth, this.currentYear);
          let currentWeek = this.getWeekIndex(currentWeekDay);
          if (currentWeek == this.currentWeek && currentMonth == this.currentDay.month && currentYear == this.currentDay.year) {
            let currentTargetHour = moment().hour();
            this.focusToHour(this.currentDay.day, currentTargetHour);
          } else {
            // this.currentDay = this.getDay(1, this.currentMonth, this.currentYear);
            // this.currentWeek = 0;
            // this.createCalendar();
            // this.dayShift.emit({ currentDay: { month: this.currentMonth, year: this.currentYear, day: this.currentDay.day }, viewMode: this.viewMode, currentWeek: this.currentWeek, status: 'change' });
            // let currentTargetHour = moment().hour();
            // // this.focusToHour(this.currentDay.day, currentTargetHour);
          }
        }
      } else {
        let currentDay = this.getDay(Number(formatDate(this.today, 'dd', 'en')), currentMonth, currentYear);
        if (currentDay.day == this.currentDay.day && currentMonth == this.currentDay.month && currentYear == this.currentDay.year) {
          this.focusToDay(this.currentDay.day, this.currentDay.month, this.currentDay.year);
        }

      }
    }, 500);
  }
  createCalendar(getWeekByCurrentDate = true) {
    this.arrTest = [];
    this.arrCalendar = [];
    this.firstDay = new Date(this.currentYear, this.currentMonth).getDay();
    this.daysInMonth = this.getDaysInMonth(this.currentMonth, this.currentYear);
    this.daysInLastMonth = this.getDaysInMonth(
      this.currentMonth - 1,
      this.currentYear
    );
    const lmd = this.daysInLastMonth - (this.previosDays[this.firstDay] - 1);

    let eventsData = this.prepareViewEvent(this.eventsData);
    let eventsSkelletonData = this.prepareViewEvent(this.eventsSkelletonData);
    // console.log('this.firstDay', this.firstDay);
    // console.log('lmd', lmd);
    // console.log('this.daysInLastMonth', this.daysInLastMonth);
    // Last month days
    for (let index = lmd; index <= this.daysInLastMonth; index++) {
      this.updateEventArray(eventsData, eventsSkelletonData, index, this.currentMonth - 1, this.currentYear);
    }
    // Actual month
    for (let index = 1; index <= this.daysInMonth; index++) {
      this.updateEventArray(eventsData, eventsSkelletonData, index, this.currentMonth, this.currentYear);
    }

    for (let i = this.arrTest.length, j = 1; i < 42; i++, j++) {
      this.updateEventArray(eventsData, eventsSkelletonData, j, this.currentMonth + 1, this.currentYear);
    }

    if (!this.dataSource.passSelectedDay) {
      this.selectedDay = JSON.parse(JSON.stringify(this.currentDay));
    } else {
      this.dataSource.passSelectedDay = false;
    }
    this.dayShift.emit({ currentDay: this.currentDay, viewMode: this.viewMode, currentWeek: this.currentWeek, status: 'initData', selectedDay: this.selectedDay });

    for (let i = 0; i < 6; i++) {
      const arrWeek = this.arrTest.splice(0, 7);
      if (i === 5) {
        let isLastWeekNextMonth = arrWeek.filter((itm) => itm.month !== this.currentMonth + 1);
        if (isLastWeekNextMonth.length > 0) {
          this.arrCalendar.push(arrWeek);
        }
      } else {
        this.arrCalendar.push(arrWeek);
      }
    }
    // console.log('Data 1', {currentDay:this.currentDay, currentMonth: this.currentMonth, currentYear:this.currentYear}); && this.currentWeek === undefined
    // if (this.currentWeek === undefined) {
    let currentWeek = this.currentWeek;
    if (getWeekByCurrentDate && this.currentWeek === undefined) {
      currentWeek = this.getWeekIndex(this.currentDay);
    }
    if (this.arrCalendar.length <= this.currentWeek) {
      currentWeek = this.arrCalendar.length - 1;
    }
    this.currentWeek = currentWeek;
    // }
    this.currentWeekData = this.arrCalendar[this.currentWeek];
    if (this.currentWeekData) {
      for (let cellItem of this.currentWeekData) {
        cellItem = this.enhanceDayView(cellItem);
        cellItem = this.enhanceDayViewUI(cellItem);
        if (this.enableAvailability) {
          cellItem = this.enhanceDayView(cellItem, 'availability', 'availabilityView');
          cellItem = this.enhanceDayViewUI(cellItem, 'availability', 'availabilityBuild');
        }
      }
    }
    // console.log('currentWeekData', this.currentWeekData);
    let totalWeeks = this.getWeekNumberViewable(this.currentYear, this.currentMonth);
    if (this.currentWeek >= totalWeeks.numberOfWeeks && totalWeeks.remainingDays > 0) {
      let currentMonth = this.currentMonth + 1;
      let currentYear = this.currentYear;
      if (currentMonth >= 12) {
        currentMonth = 0;
        currentYear = this.currentYear + 1;
      }
      this.currentWeekView = 0;
      this.actWeekDate = this.creatActMonthYear(currentYear, currentMonth);
    } else {
      this.currentWeekView = this.currentWeek;
      this.actWeekDate = this.creatActMonthYear();
    }
    this.actDate = this.creatActMonthYear();
    this.actFullDate = this.creatActFullDay();

    this.arrListCalendar = this.prepareListEvent(JSON.parse(JSON.stringify(this.arrCalendar)));
    this.currentListWeekData = this.prepareWeekListEvent(JSON.parse(JSON.stringify(this.currentWeekData)));
    // this.currentListWeekData = this.arrListCalendar[this.currentWeek];
    this.currentListDayData = this.enhanceDayViewList(this.currentDay);

    this.onYearSelected(this.currentYear);
    // console.log(this.currentDay)
    // console.log('Data 2', { arrCalendar: this.arrCalendar, currentWeek: this.currentWeek, daysInMonth: this.daysInMonth, daysInLastMonth: this.daysInLastMonth });
  }
  enhanceDayViewList(cellItem) {
    cellItem['eventsViewList'] = [];
    let cellItemEvents = JSON.parse(JSON.stringify(cellItem.events));
    cellItemEvents = cellItemEvents.sort((a: any, b: any) => {
      let aDate = new Date(a.startdate);
      let bDate = new Date(b.startdate);
      return aDate.getTime() - bDate.getTime();
    });
    cellItem['eventsViewList'] = [cellItemEvents];
    return cellItem;
  }
  enhanceDayView(cellItem, sourceId = 'events', targetId = 'eventsView') {
    cellItem[targetId] = [];
    let currentColumn = [[]];
    let cellItemEvents = JSON.parse(JSON.stringify(cellItem[sourceId]));
    cellItemEvents = cellItemEvents.sort((a: any, b: any) => {
      let aDate = new Date(a.startdate);
      let bDate = new Date(b.startdate);
      return aDate.getTime() - bDate.getTime();
    });
    for (let event of cellItemEvents) {
      let gotInserted = false;
      for (let col of currentColumn) {
        if (col.length === 0 || !this.coincideEvents(event, col)) {
          col.push(event);
          gotInserted = true;
          break;
        }
      }
      if (!gotInserted) {
        currentColumn.push([event]);
      }
    }
    cellItem[targetId] = currentColumn;
    return cellItem;
  }
  enhanceDayViewUI(cellItem, sourceId = 'events', targetId = 'eventsBuild') {
    let eventBuild = [];
    let totalSlots = 24;
    let perItemHeightHour = 100 / totalSlots;
    let perItemHeightMinutes = perItemHeightHour / 60;
    let cellItemEvents = JSON.parse(JSON.stringify(cellItem[sourceId]));
    cellItemEvents = cellItemEvents.sort((a: any, b: any) => {
      let aDate = new Date(a.startdate);
      let bDate = new Date(b.startdate);
      return aDate.getTime() - bDate.getTime();
    });
    let currentColumn = this.coincideEventsGroup(cellItemEvents);
    for (let itmRow of currentColumn) {
      let insetTop = 0;
      let insetRight = 0;
      let insetButtom = 0;
      let insetLeft = 0;
      let idx = 1;
      let perItemWidth = 100 / itmRow.length;

      for (let event of itmRow) {
        let start = moment(event.startdate).local();
        let end = moment(event.enddate).local();
        let startHour = Number(formatDate(event.startdate, 'HH', 'en'));
        let startMinute = Number(formatDate(event.startdate, 'mm', 'en'));
        let endHour = Number(formatDate(event.enddate, 'HH', 'en'));
        let endMinute = Number(formatDate(event.enddate, 'mm', 'en'));
        if (start.isSame(end, 'day')) {
          insetTop = (startHour * perItemHeightHour) + (startMinute * perItemHeightMinutes);
          insetButtom = 100 - ((endHour * perItemHeightHour) + (endMinute * perItemHeightMinutes));
        } else {
          let currentStartHour = startHour;
          let currentEndHour = endHour;
          let currentStartMinute = startMinute;
          let currentEndMinute = endMinute;

          let currentStartDay = start.date();
          let currentEndDay = end.date();
          if (currentStartDay !== cellItem.day) {
            currentStartHour = 0;
            currentStartMinute = 0;
          }
          if (currentEndDay !== cellItem.day) {
            currentEndHour = 24;
            currentEndMinute = 0;
          }
          insetTop = currentStartHour * perItemHeightHour + currentStartMinute * perItemHeightMinutes;
          insetButtom = 100 - ((currentEndHour * perItemHeightHour) + (currentEndMinute * perItemHeightMinutes));
        }
        insetRight = itmRow.length === 1 ? 0 : (itmRow.length - idx) * perItemWidth;
        insetLeft = itmRow.length === 1 ? 0 : (idx - 1) * perItemWidth;

        event['inset'] = insetTop + '% ' + insetRight + '% ' + insetButtom + '% ' + insetLeft + '%';
        eventBuild.push(event);
        idx++;
      }
    }
    cellItem[targetId] = eventBuild;
    return cellItem;
  }
  coincideEvents(event, eventList) {
    for (let itm of eventList) {
      if (new Date(event.startdate).getTime() < new Date(itm.enddate).getTime() && new Date(event.enddate).getTime() > new Date(itm.startdate).getTime()) {
        return true; // Overlap found
      }
    }
    return false;
  }
  coincideEventsGroup(events) {
    let groupedEvents = [];

    events.forEach((event) => {
      // Check if the event coincides with any existing groups
      let foundGroup = false;
      groupedEvents.forEach((group) => {
        let groupEvents = JSON.parse(JSON.stringify(group));
        for (let ev of groupEvents) {
          if (new Date(event.startdate).getTime() < new Date(ev.enddate).getTime() && new Date(event.enddate).getTime() > new Date(ev.startdate).getTime()) {
            group.push(event);
            foundGroup = true;
            break;
          }
        };
      });
      // If the event doesn't coincide with any existing group, create a new group
      if (!foundGroup) {
        groupedEvents.push([event]);
      }
    });
    return groupedEvents;
  }
  updateEventArray(dataList, skelletonDataList, index, month, year) {
    const filterDataList = dataList.filter(event => {
      return (
        new Date(event.startdate).getTime() <
        new Date(
          year,
          month,
          index + 1
        ).getTime() &&
        new Date(event.enddate).getTime() >
        new Date(year, month, index).getTime()
      );
    });
    const filterSkelletonDataList = skelletonDataList.filter(event => {
      return (
        new Date(event.startdate).getTime() <
        new Date(
          year,
          month,
          index + 1
        ).getTime() &&
        new Date(event.enddate).getTime() >
        new Date(year, month, index).getTime()
      );
    });

    let filterDataListAll = [];
    let filterDataListAllAvailability = [];
    // const filterDataListAll = filterDataList.concat(filterSkelletonDataList);
    if (this.enableAvailability) {
      filterDataListAll = filterDataList;
      filterDataListAllAvailability = filterSkelletonDataList;
    } else {
      if (this.selectedFilter) {
        filterDataListAll = filterSkelletonDataList.concat(filterDataList);
      } else {
        filterDataListAll = filterDataList;
      }
    }
    //Sorted events by date
    const arrSortedDataByDate = filterDataListAll.sort((a: any, b: any) => {
      let aDate = new Date(a.startdate);
      let bDate = new Date(b.startdate);
      return aDate.getTime() - bDate.getTime();
    });
    const arrSortedDataByDateAvailability = filterDataListAllAvailability.sort((a: any, b: any) => {
      let aDate = new Date(a.startdate);
      let bDate = new Date(b.startdate);
      return aDate.getTime() - bDate.getTime();
    });

    this.arrTest.push({
      day: index,
      month: month,
      year: year,
      dayName: this.getDayName(year, month, index),
      events: arrSortedDataByDate,
      availability: arrSortedDataByDateAvailability,
      date: this.createDateWithFormat(year, month, index),
      fullDate: this.createDateWithFormat(year, month, index, 'YYYY-MM-DDTHH:mm'),

    });
    if (index === this.selectedDay.day && month === this.selectedDay.month && year === this.selectedDay.year) {
      this.selectedDay['events'] = arrSortedDataByDate;
      this.selectedDay['availability'] = arrSortedDataByDateAvailability;
      this.selectedDay['date'] = this.createDateWithFormat(year, month, index);
      this.selectedDay['fullDate'] = this.createDateWithFormat(year, month, index, 'YYYY-MM-DDTHH:mm');
      this.selectedDay = this.enhanceDayView(this.selectedDay);
      this.selectedDay = this.enhanceDayViewUI(this.selectedDay);
      if (this.enableAvailability) {
        this.selectedDay = this.enhanceDayView(this.selectedDay, 'availability', 'availabilityView');
        this.selectedDay = this.enhanceDayViewUI(this.selectedDay, 'availability', 'availabilityBuild');
      }
    }
    if (index === this.currentDay.day && month === this.currentDay.month && year === this.currentDay.year) {
      this.currentDay['events'] = arrSortedDataByDate;
      this.currentDay['availability'] = arrSortedDataByDateAvailability;
      this.currentDay['date'] = this.createDateWithFormat(year, month, index);
      this.currentDay['fullDate'] = this.createDateWithFormat(year, month, index, 'YYYY-MM-DDTHH:mm');
      this.currentDay = this.enhanceDayView(this.currentDay);
      this.currentDay = this.enhanceDayViewUI(this.currentDay);
      if (this.enableAvailability) {
        this.currentDay = this.enhanceDayView(this.currentDay, 'availability', 'availabilityView');
        this.currentDay = this.enhanceDayViewUI(this.currentDay, 'availability', 'availabilityBuild');
      }
    }
  }
  getDayName(year, month, day) {
    let d = new Date(year, month, day);
    let index = d.getDay();
    let dayName = this.weekdaysObject[index];
    return dayName;
  }
  createDateWithFormat(year, month, day, format = 'YYYY-MM-DD') {
    // Use the moment() constructor to create a moment object with the given year, month, and day
    const date = moment().local().set({
      year: year,
      month: month, // Months in moment.js are zero-indexed (0-11)
      date: day,
    });

    const formattedDate = date.format(format);
    return formattedDate;
  }
  prepareListEvent(arrListCalendar: any[]) {
    let eventsId: string[] = [];
    for (let i = 0; i < arrListCalendar.length; i++) {
      arrListCalendar[i] = arrListCalendar[i].filter((itm) => {
        if (itm.month === this.currentMonth) {
          return true;
        } else {
          return false;
        }
      });
      for (let j = 0; j < arrListCalendar[i].length; j++) {
        arrListCalendar[i][j].events = arrListCalendar[i][j].events.filter((event) => {
          if (!eventsId.includes(event._id)) {
            eventsId.push(event._id);
            return true;
          } else {
            return false;
          }
        })
        arrListCalendar[i][j].events = arrListCalendar[i][j].events.sort((a: any, b: any) => {
          let aDate = new Date(a.startdate);
          let bDate = new Date(b.startdate);
          return aDate.getTime() - bDate.getTime();
        });
      }
    }
    return arrListCalendar;
  }
  prepareWeekListEvent(arrListCalendar: any[]) {
    let eventsId: string[] = [];
    for (let j = 0; j < arrListCalendar.length; j++) {
      arrListCalendar[j].events = arrListCalendar[j].events.filter((event) => {
        if (!eventsId.includes(event._id)) {
          eventsId.push(event._id);
          return true;
        } else {
          return false;
        }
      })
      arrListCalendar[j].events = arrListCalendar[j].events.sort((a: any, b: any) => {
        let aDate = new Date(a.startdate);
        let bDate = new Date(b.startdate);
        return aDate.getTime() - bDate.getTime();
      });
    }
    return arrListCalendar;
  }
  prepareViewEvent(eventsData: any[]) {
    let eventNewData = JSON.parse(JSON.stringify(eventsData));
    eventNewData = eventNewData.map(event => {
      let start = moment.utc(event.startdate).local();
      let end = moment.utc(event.enddate).local();
      event.startdate = start.format('YYYY-MM-DDTHH:mm');
      event.enddate = end.format('YYYY-MM-DDTHH:mm');
      let startHour = Number(formatDate(event.startdate, 'HH', 'en'));
      let startMinute = Number(formatDate(event.startdate, 'mm', 'en'));
      let endHour = Number(formatDate(event.enddate, 'HH', 'en'));
      let endMinute = Number(formatDate(event.enddate, 'mm', 'en'));
      // let startYear = Number(formatDate(event.startdate, 'YYYY', 'en'));
      // let startMonth = Number(formatDate(event.startdate, 'MM', 'en'));
      // let startDay = Number(formatDate(event.startdate, 'dd', 'en'));
      // let endYear = Number(formatDate(event.enddate, 'YYYY', 'en'));
      // let endMonth = Number(formatDate(event.enddate, 'MM', 'en'));
      // let endDay = Number(formatDate(event.enddate, 'dd', 'en'));
      event['arrayView'] = {};
      if (start.isSame(end, 'day')) {
        // if (startYear === endYear && startMonth === endMonth && startDay === endDay) {
        event['sameDay'] = true;
        let viewInfo = this.getEventDayView({ startHour, startMinute, endHour, endMinute });
        event['arrayView'][start.format('YYYY-MM-DD')] = viewInfo;
      } else {
        event['sameDay'] = false;
        for (let currentDate = moment(start); currentDate.isSameOrBefore(end, 'day'); currentDate.add(1, 'day')) {
          let currentStartHour = startHour;
          let currentEndHour = endHour;
          let currentStartMinute = startMinute;
          let currentEndMinute = endMinute;
          if (!start.isSame(currentDate, 'day')) {
            currentStartHour = 0;
            currentStartMinute = 0;
          }
          if (!end.isSame(currentDate, 'day')) {
            currentEndHour = 24;
            currentEndMinute = 0;
          }
          let viewInfo = this.getEventDayView({ startHour: currentStartHour, startMinute: currentStartMinute, endHour: currentEndHour, endMinute: currentEndMinute });
          event['arrayView'][currentDate.format('YYYY-MM-DD')] = viewInfo;
        }
      }
      return event;
    });
    return eventNewData;
  }
  getEventDayView(event: { startHour, startMinute, endHour, endMinute }) {
    let returnObj = { topMargin: '0', height: '0' };

    let topMargin = (event.startHour * 60) + (event.startHour * 2);

    if (event.startMinute >= 15) {
      topMargin = topMargin + event.startMinute;
    }
    returnObj['topMargin'] = (topMargin + 2) + 'px';
    let height = (event.endHour * 60) + (event.endHour * 2) - 4;
    if (event.endMinute >= 15) {
      height = height + event.endMinute;
    }
    height = height - topMargin;
    if (height <= 0) {
      height = 15;
    }
    returnObj['height'] = height + 'px';
    return returnObj;
  }
  getWeekData(inputDay: Day) {
    for (let week of this.arrCalendar) {
      for (let day of week) {
        if (day.day === inputDay.day && day.month === inputDay.month && day.year === inputDay.year) {
          return week;
        }
      }
    }
    return [];
  }
  getWeekIndex(inputDay: Day) {
    let index = 0;
    for (let week of this.arrCalendar) {
      for (let day of week) {
        if (day.day === inputDay.day && day.month === inputDay.month && day.year === inputDay.year) {
          return index;
        }
      }
      index = index + 1;
    }
    return index - 1;
  }

  getDaysInMonth(iMonth, iYear) {
    return 32 - new Date(iYear, iMonth, 32).getDate();
  }
  // getWeekNumber(year, monthNumber) {

  //   var firstOfMonth = new Date(year, monthNumber, 1);
  //   var lastOfMonth = new Date(year, monthNumber + 1, 0);

  //   var used = firstOfMonth.getDay() + lastOfMonth.getDate();

  //   return Math.ceil(used / 7);
  // }
  getWeekNumberViewable(year, month) {
    // Get the first day of the month
    let firstDay = new Date(year, month, 1);
    // Get the last day of the month
    let lastDay = new Date(year, month + 1, 0);
    // Calculate the number of days in the month
    let daysInMonth = lastDay.getDate();
    let weekday = firstDay.getDay();
    // Calculate the number of days from the previous month that need to be included
    let daysFromPreviousMonth = this.previosDays[weekday];
    // Calculate the total number of days to be displayed
    let totalDays = daysInMonth + daysFromPreviousMonth;
    // Calculate the number of weeks
    let numberOfWeeks = Math.floor(totalDays / 7);
    let remainingDays = totalDays - numberOfWeeks * 7;
    return { numberOfWeeks: numberOfWeeks, remainingDays: remainingDays };
  }
  getWeekNumber(year, month) {
    // Get the first day of the month
    let firstDay = new Date(year, month, 1);
    // Get the last day of the month
    let lastDay = new Date(year, month + 1, 0);
    // Calculate the number of days in the month
    let daysInMonth = lastDay.getDate();
    let weekday = firstDay.getDay();
    // Calculate the number of days from the previous month that need to be included
    let daysFromPreviousMonth = this.previosDays[weekday];
    // Calculate the total number of days to be displayed
    let totalDays = daysInMonth + daysFromPreviousMonth;
    // Calculate the number of weeks
    let numberOfWeeks = Math.ceil(totalDays / 7);
    return numberOfWeeks;
  }
  getWeekNumberByDate(day, month, year) {
    // Get the first day of the month
    let firstDay = new Date(year, month, 1);
    // Get the last day of the month
    let lastDay = new Date(year, month + 1, 0);
    let lastCurrentDay = new Date(year, month, day);
    // Calculate the number of days in the month
    let daysInMonth = lastDay.getDate();
    let currentDaysInMonth = lastCurrentDay.getDate();
    // Calculate the number of days from the previous month that need to be included
    let daysFromPreviousMonth = this.previosDays[firstDay.getDay()];
    // Calculate the total number of days to be displayed
    let totalDays = daysInMonth + daysFromPreviousMonth;
    let totalCurrentDays = currentDaysInMonth + daysFromPreviousMonth;
    // Calculate the number of weeks
    // let numberOfWeeks = Math.floor(totalDays / 7);
    let numberOfWeeksCurrent = Math.ceil(totalCurrentDays / 7);
    return { numberOfWeeksCurrent: numberOfWeeksCurrent - 1 };
  }
  previousButtonClick() {
    if (this.viewMode === 'month') {
      if (this.currentMonth === 0) {
        this.currentYear -= 1;
        this.currentMonth = 11;
      } else {
        this.currentMonth -= 1;
      }
      let currentMonth = this.today.getMonth();
      let currentYear = this.today.getFullYear();
      let currentDay = this.getDay(Number(formatDate(this.today, 'dd', 'en')), currentMonth, currentYear);
      if (this.currentMonth == currentMonth && this.currentYear == currentYear) {
        this.currentDay.day = currentDay.day;
        this.currentWeek = this.getWeekNumberByDate(currentDay.day, currentMonth, currentYear).numberOfWeeksCurrent;
      } else {
        this.currentDay.day = 1;
        this.currentWeek = 0;
      }
      this.currentDay.year = this.currentYear;
      this.currentDay.month = this.currentMonth;
      this.createCalendar();
    } else if (this.viewMode === 'week') {
      if (this.currentWeek === 0) {
        this.currentMonth -= 1;
        if (this.currentMonth <= -1) {
          this.currentMonth = 11;
          this.currentYear -= 1;
        }
        this.currentDay.year = this.currentYear;
        this.currentDay.month = this.currentMonth;
        let totalWeeks = this.getWeekNumberViewable(this.currentYear, this.currentMonth);
        this.currentWeek = (totalWeeks.numberOfWeeks - 1);
      } else {
        this.currentWeek -= 1;
      }
      this.createCalendar(false);
    } else {
      if (this.currentDay.day === 1) {
        this.currentMonth -= 1;
        if (this.currentMonth <= -1) {
          this.currentMonth = 11;
          this.currentYear -= 1;
        }
        this.currentDay.year = this.currentYear;
        this.currentDay.month = this.currentMonth;
        this.currentDay.day = this.daysInLastMonth;
        // let totalWeeks = this.getWeekNumberViewable(this.currentYear, this.currentMonth);
        // this.currentWeek = (totalWeeks.numberOfWeeks - 1);

        this.currentWeek = this.getWeekNumberByDate(this.currentDay.day, this.currentMonth, this.currentYear).numberOfWeeksCurrent;
      } else {
        this.currentDay.day -= 1;
        this.currentWeek = this.getWeekNumberByDate(this.currentDay.day, this.currentMonth, this.currentYear).numberOfWeeksCurrent;
      }
      this.createCalendar();
    }
    this.dayShift.emit({ currentDay: { month: this.currentMonth, year: this.currentYear, day: this.currentDay.day }, viewMode: this.viewMode, currentWeek: this.currentWeek, status: 'change' });
  }
  goToDayMonth(month, year) {
    this.viewMode = 'month';
    this.currentYear = year;
    this.currentMonth = month;
    this.createCalendar();
  }
  nextButtonClick() {
    if (this.viewMode === 'month') {
      if (this.currentMonth >= 11) {
        this.currentYear += 1;
        this.currentMonth = 0;
      } else {
        this.currentMonth += 1;
      }
      let currentMonth = this.today.getMonth();
      let currentYear = this.today.getFullYear();
      let currentDay = this.getDay(Number(formatDate(this.today, 'dd', 'en')), currentMonth, currentYear);
      if (this.currentMonth == currentMonth && this.currentYear == currentYear) {
        this.currentDay.day = currentDay.day;
        this.currentWeek = this.getWeekNumberByDate(currentDay.day, currentMonth, currentYear).numberOfWeeksCurrent;
      } else {
        this.currentDay.day = 1;
        this.currentWeek = 0;
      }
      this.currentDay.year = this.currentYear;
      this.currentDay.month = this.currentMonth;
      this.createCalendar();
    } else if (this.viewMode === 'week') {
      let totalWeeks = this.getWeekNumberViewable(this.currentYear, this.currentMonth);
      if (this.currentWeek >= (totalWeeks.numberOfWeeks - 1)) {
        this.currentMonth += 1;
        if (this.currentMonth >= 12) {
          this.currentMonth = 0;
          this.currentYear += 1;
        }
        this.currentDay.year = this.currentYear;
        this.currentDay.month = this.currentMonth;
        if (this.currentWeek > (totalWeeks.numberOfWeeks - 1)) {
          this.currentWeek = 1;
        } else {
          this.currentWeek = 0;
        }
      } else {
        this.currentWeek += 1;
      }
      this.createCalendar(false);
    } else {
      if (this.currentDay.day >= this.daysInMonth) {
        this.currentMonth += 1;
        if (this.currentMonth >= 12) {
          this.currentMonth = 0;
          this.currentYear += 1;
        }
        this.currentDay.year = this.currentYear;
        this.currentDay.month = this.currentMonth;
        this.currentDay.day = 1;
        this.currentWeek = 0;
      } else {
        this.currentDay.day += 1;
        this.currentWeek = this.getWeekNumberByDate(this.currentDay.day, this.currentMonth, this.currentYear).numberOfWeeksCurrent;
      }
      this.createCalendar();
    }
    this.dayShift.emit({ currentDay: { month: this.currentMonth, year: this.currentYear, day: this.currentDay.day }, viewMode: this.viewMode, currentWeek: this.currentWeek, status: 'change' });
  }

  selectDayCellItem(action, day) {
    // this.currentDay = day;
    this.selectedDay = JSON.parse(JSON.stringify(day));
    let selectedDay = JSON.parse(JSON.stringify(day));
    selectedDay['action'] = action;
    this.dayEvents.emit(selectedDay);
  }
  // Dialog test
  // TODO: return the selected value
  selectCellItemSmall(day) {
    // this.currentDay = day;
    if (this.isSmall) {
      this.selectedDay = JSON.parse(JSON.stringify(day));
      this.dayEvents.emit(day);
    }

  }
  selectCellItem(day) {
    // this.currentDay = day;
    this.selectedDay = JSON.parse(JSON.stringify(day));
    this.dayEvents.emit(day);
  }
  selectCellItemHours(day, hr, half: boolean = false) {
    // this.currentDay = day;
    if (half) {
      hr = hr + ':30';
    } else {
      hr = hr + ':00';
    }
    this.selectedDay = JSON.parse(JSON.stringify(day));
    let dayData = JSON.parse(JSON.stringify(day));
    dayData['hour'] = hr;
    this.dayEvents.emit(dayData);
  }
  selectEventItem(e, day, event) {
    if (this.eventsSelectable) {
      e.stopImmediatePropagation();
      e.preventDefault();
      let dayData = JSON.parse(JSON.stringify(day));
      dayData['event'] = event;
      this.dayEvent.emit(dayData);
    }
  }
  selectAvailabilityEventItem(e, day, event) {
    if (this.eventsAvlbSelectable) {
      e.stopImmediatePropagation();
      e.preventDefault();
      let dayData = JSON.parse(JSON.stringify(day));
      dayData['event'] = event;
      dayData['isAvailability'] = true;
      this.dayEvent.emit(dayData);
    }
  }
  selectAvailabilityEventDayItem(action, day, event) {
    if (this.eventsAvlbSelectable) {
      let dayData = JSON.parse(JSON.stringify(day));
      dayData['event'] = event;
      dayData['isAvailability'] = true;
      dayData['action'] = action;
      this.dayEvent.emit(dayData);
    }
  }
  sortByStartNumber(dataArray, startNumber) {
    const sortedArray = [...dataArray];

    // Find the index of the startNumber in the id property
    const startIndex = sortedArray.findIndex(obj => obj.id === startNumber);

    // Rearrange the array so that the startNumber is at the beginning
    const adjustedArray = [...sortedArray.slice(startIndex), ...sortedArray.slice(0, startIndex)];

    return adjustedArray;
  }
  changeLanguage() {
    if (!this.language) {
      this.language = 'en';
    }
    if (this.language === 'hu') {
      this.months = mounthsHU;
      this.weekdaysObject = mounthsHU;
      this.weekdays = this.sortByStartNumber(weekdayHU, this.startWeekDay);
    } else {
      this.months = mounthsEN;
      this.weekdaysObject = weekdayEN;
      this.weekdays = this.sortByStartNumber(weekdayEN, this.startWeekDay);
    }
    let idx = 0;
    for (let itm of this.weekdays) {
      this.previosDays[itm.id] = idx;
      idx++;
    }
  }

  onYearChange(event) {
    this.currentYear = Number(event.value);
    this.currentDay.year = Number(event.value);

    let currentMonth = this.today.getMonth();
    let currentYear = this.today.getFullYear();
    let currentDay = this.getDay(Number(formatDate(this.today, 'dd', 'en')), currentMonth, currentYear);
    if (this.currentMonth == currentMonth && this.currentYear == currentYear) {
      this.currentDay.day = currentDay.day;
      this.currentWeek = this.getWeekNumberByDate(currentDay.day, currentMonth, currentYear).numberOfWeeksCurrent;
    } else {
      this.currentDay.day = 1;
      this.currentWeek = 0;
    }
    this.createCalendar();
    this.dayShift.emit({ currentDay: { month: this.currentMonth, year: this.currentYear, day: this.currentDay.day }, viewMode: this.viewMode, currentWeek: this.currentWeek, status: 'change' });
  }

  onMonthChange(event) {
    this.currentMonth = Number(event.value);
    this.currentDay.month = Number(event.value);

    let currentMonth = this.today.getMonth();
    let currentYear = this.today.getFullYear();
    let currentDay = this.getDay(Number(formatDate(this.today, 'dd', 'en')), currentMonth, currentYear);
    if (this.currentMonth == currentMonth && this.currentYear == currentYear) {
      this.currentDay.day = currentDay.day;
      this.currentWeek = this.getWeekNumberByDate(currentDay.day, currentMonth, currentYear).numberOfWeeksCurrent;
    } else {
      this.currentDay.day = 1;
      this.currentWeek = 0;
    }
    this.createCalendar();
    this.dayShift.emit({ currentDay: { month: this.currentMonth, year: this.currentYear, day: this.currentDay.day }, viewMode: this.viewMode, currentWeek: this.currentWeek, status: 'change' });
  }

  creatActMonthYear(currentYear = this.currentYear, currentMonth = this.currentMonth) {
    const actDate = formatDate(
      new Date(currentYear, currentMonth),
      'yyyy MMMM',
      'en'
    );

    return actDate;
  }
  creatActFullDay() {
    const actDate = formatDate(
      this.getFullDay(),
      'yyyy MMMM dd  - EEEE',
      'en'
    );

    return actDate;
  }
  getFullDay() {
    return new Date(this.currentYear, this.currentMonth, this.currentDay.day);
  }

  createEvent(action) {
    const testMessage = this.getFullDay();
    this.newEvent.emit({ action: action, testMessage });
  }
  getRecurrenceDatas(recurObj, dtstartOriginal, dtendOriginal) {
    let response = { humantext: '', all: undefined };
    if (!recurObj) {
      return response;
    } else {
      let pattern = "";
      let count = 0;
      switch (recurObj["repeats"]) {
        case "minutely":
          pattern = "FREQ=MINUTELY;INTERVAL=" + recurObj["interval"];
          break;
        case "hourly":
          pattern = "FREQ=HOURLY;INTERVAL=" + recurObj["interval"];
          break;
        case "daily":
          pattern = "FREQ=DAILY;INTERVAL=" + recurObj["interval"];
          break;
        case "weekly":
          pattern = "FREQ=WEEKLY;INTERVAL=" + recurObj["interval"] + ";BYDAY=" + recurObj["byweekday"].toString();
          break;
        case "monthly":
          pattern = "FREQ=MONTHLY;INTERVAL=" + recurObj["interval"];
          if (recurObj["monthlyType"] && recurObj["monthlyType"] == "dayofmonth") {
            pattern += ";BYMONTHDAY=" + recurObj["bymonthday"].toString();
          } else {
            let bysetposSign = '+';
            if (recurObj["bysetpos"] < 0) {
              bysetposSign = '';
            }
            pattern += ";BYSETPOS=1;BYDAY=" + bysetposSign + recurObj["bysetpos"] + recurObj["byday"].toString();
          }
          break;
        case "yearly":
          pattern = "FREQ=YEARLY;"
          if (recurObj["yearlyType"] && recurObj["yearlyType"] == "dayofmonth") {
            pattern += "BYMONTH=" + recurObj["bymonth"] + ";BYMONTHDAY=" + recurObj["bymonthday"].toString();
          } else {
            let bysetposSign = '+';
            if (recurObj["bysetpos"] < 0) {
              bysetposSign = '';
            }
            pattern += "BYDAY=" + bysetposSign + recurObj["bysetpos"] + recurObj["byday"] + ";BYSETPOS=1;BYMONTH=" + recurObj["bymonth"].toString();
          }
          break;
        default:
          pattern = "";
      }
      if (recurObj["end"] && recurObj["end"] == "after") {
        pattern += ";COUNT=" + recurObj["count"];
        count = recurObj["count"];
      }
      if (recurObj["tzid"]) {
        pattern += ";TZID=" + recurObj["tzid"];
      } else {
        pattern += ";TZID=" + this.getUserTimeZone();
      }
      if (recurObj["end"] && recurObj["end"] == "date") {
        pattern += ";UNTIL=" + moment(recurObj["until"]).format('YYYYMMDDTHHmmss')
      }
      // console.log('pattern', pattern);
      let options = rrule.RRule.parseString(pattern);
      // options.dtstart = new Date(recurObj["startdate"]);
      // let startdate = moment(recurObj['startdate']).utc().toDate();
      let startdate = new Date(recurObj["startdate"]);
      const utcStartDate = new Date(startdate.getTime() - startdate.getTimezoneOffset() * 60000);
      const utcMoment = moment.utc(recurObj["startdate"]);
      const localMoment = utcMoment.local();
      const formattedLocalTime = localMoment.format('YYYY-MM-DD hh:mm A');
      options.dtstart = utcStartDate;
      // let dtstart = moment(recurObj['startdate']).utc().toDate();
      // options.dtstart = dtstart;
      if (count && count != 0) {
        options.count = count;
      } else {
        // options.until = new Date(recurObj["until"]);
        let untill = moment(recurObj['until']).utc().toDate();
        untill.setHours(dtendOriginal.getHours());
        untill.setMinutes(dtendOriginal.getMinutes());
        options.until = untill;
      }
      // if (recurObj["tzid"]) {
      //   options.tzid = recurObj["tzid"];
      // } else {
      //   options.tzid = this.getUserTimeZone();
      // }
      var rule = new rrule.RRule(options);
      // return rule.toText();
      var all = rule.all();
      response["tzid"] = recurObj["tzid"];
      response["localdate"] = utcStartDate;
      response["slocaldate"] = formattedLocalTime;
      response["humantext"] = rule.toText();
      // response["rule"] = rule;
      // response["all"] = all;
      response["all"] = all.map((occurrence: Date) => {
        occurrence = new Date(occurrence.getTime() + occurrence.getTimezoneOffset() * 60000);
        return occurrence;
      });
    }
    return response;
  }
  getUserTimeZone() {
    // Create a new date object with the current date and time
    const now = new Date();

    // Get the time zone from the date object using the Intl.DateTimeFormat API
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return timeZone;
  }
}
